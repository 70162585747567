import React from "react";
import {useNavigate} from "react-router-dom";
//local
import {Tag} from "components/Tag";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {color} from "constants/color.consts";

const JoiningOrg = ({organizatioName}) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="joining-organization__header">
      <div className="fz-16 mid-gray">{organizatioName}</div>
      <Tag
        text="APPLICATION_PENDING"
        needTranslation
        color={color.orange}
        uppercase
        addClass="ml-2 mr-4"
      />
      <ButtonTransparent name="SIGN_OUT" color="red" small onClick={logout} />
    </div>
  );
};

export default JoiningOrg;

import React from "react";
import {useTranslation} from "react-i18next";
//local
import PresentationCardWrapper from "../../PresentationCardWrapper";
import {IconSkullEmpty} from "components/icons";
import {InfoTooltip} from "components/InfoTooltip";
import {formattedNumber} from "helpers/number.formats";
import EmptyOrNoCompanyCreditData from "components/EmptyOrNoCompanyCreditData";
import {LockValue} from "components/LockValue";

const NonPaymentRecords = ({companyData, hasFirstUpdateData}) => {
  const {t} = useTranslation();
  const nonPaymentInfoPrivate = [
    ...(companyData?.Company?.companyCreditReport?.ropPrivateClaims ?? []),
    ...(companyData?.Company?.companyCreditReport?.ropPrivateClaimsApp ?? []),
  ];
  const nonPaymentInfoPublic =
    companyData?.Company?.companyCreditReport?.ropPublicClaims ?? [];

  const TableHead = ({withCreditor}) => (
    <div className="custom-table_head">
      <div className="custom-table_head__date">{t("DATE")}</div>
      <div className="custom-table_head__title">
        {withCreditor ? t("RECORD_OF_NON_PAYMENTS") : null}
      </div>
      <div className="custom-table_head__title value">{t("FINANCIAL_AMOUNT")}</div>
      <div className="custom-table_head__title">{t("TYPE_OF_NON_PAYMENTS")}</div>
    </div>
  );

  const TableRow = ({row, index, withCreditor}) => (
    <div className="custom-table_body__item" key={row.type + index}>
      <div className="custom-table_body__date">{row.createdDate}</div>
      <div className="custom-table_body__value">
        {withCreditor ? row.creditorName : null}
      </div>
      <div className="custom-table_body__value value" data-title={t("TOTAL_AMOUNT")}>
        {row.amount === null ? "-" : formattedNumber(Math.round(row.amount))}
      </div>
      <div className="custom-table_body__value">{row.typeText}</div>
    </div>
  );

  return (
    <PresentationCardWrapper
      icon={<IconSkullEmpty height={20} width={20} />}
      title={t("NON_PAYMENTS")}
      titleToolTip={t("NON_PAYMENTS_TOOLTIP")}
      addClass="non-payment"
    >
      <LockValue
        canPermissions={["READ_OTHER_DEFAULTED_PAYMENTS"]}
        noHideOurInfo
        fullSubcribeButton
        needMiniSibscribeButton
      >
        <div className="custom-table_wrapper">
          <div className="sub-heading __uppercase __bold discrete-text mb-1">
            {t("NON_PAYMENTS_PUBLIC")}
            <InfoTooltip tooltip="NON_PAYMENTS_PUBLIC_TOOLTIP" addClass="ml-2" />
          </div>
          <TableHead />
          {!!nonPaymentInfoPublic?.length ? (
            <div className="custom-table_body">
              {nonPaymentInfoPublic.map((item, i) => (
                <TableRow row={item} index={i} />
              ))}
            </div>
          ) : (
            <EmptyOrNoCompanyCreditData hasFirstUpdateData={hasFirstUpdateData} />
          )}

          <div className="sub-heading __uppercase __bold discrete-text mb-1 mt-4">
            {t("NON_PAYMENTS_PRIVATE")}
            <InfoTooltip tooltip="NON_PAYMENTS_PRIVATE_TOOLTIP" addClass="ml-2" />
          </div>
          <TableHead withCreditor />
          {!!nonPaymentInfoPrivate?.length ? (
            <div className="custom-table_body">
              {nonPaymentInfoPrivate.map((item, i) => (
                <TableRow row={item} index={i} withCreditor />
              ))}
            </div>
          ) : (
            <EmptyOrNoCompanyCreditData hasFirstUpdateData={hasFirstUpdateData} />
          )}
        </div>
      </LockValue>
    </PresentationCardWrapper>
  );
};

export default NonPaymentRecords;

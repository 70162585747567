import React from "react";

export const IconLinkByggnet = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M14.1517 0.00101662H0.000404358V22.8073H20.0003V5.07734C19.3708 4.52655 18.6779 4.05959 17.9629 3.43666C16.6863 2.32508 15.3386 1.01852 14.1517 0.00101662Z"
          fill="#004691"
        />
        <path
          d="M8.67915 5.00008V6.82794V10.3606L12.6312 6.81868H12.6203V5.00008H8.67915Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M1.53369 6.81868V10.3698H8.69938H13.0338L8.67915 6.82892V6.81868H1.53369Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M8.69943 2.09842H1.53374V5.02153H8.6792V5.02177L12.6201 4.9996L8.69943 2.09842Z"
          fill="#A6D5F3"
        />
        <path
          d="M8.9275 10.3696V12.73L13.037 12.7295L13.0331 10.3696H8.9275Z"
          fill="url(#paint2_linear)"
        />
        <path
          d="M1.53369 12.7578H5.43507V2.10402H1.53369V12.7578Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M13.0375 12.7301H8.9175V12.7518H1.53369V15.7831L8.97283 15.7843L13.0375 12.7301Z"
          fill="#A6D5F3"
        />
        <path d="M14.1513 0.0010066L20 5.07733H14.1513V0.0010066Z" fill="#A6D5F4" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="10.6553"
          y1="9.67156"
          x2="10.6553"
          y2="0.676163"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6D5F3" />
          <stop offset="0.199752" stopColor="#A6D5F3" />
          <stop offset="0.914043" stopColor="#252929" />
          <stop offset="1" stopColor="#1D1D1B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="8.90201"
          y1="8.59438"
          x2="0.92987"
          y2="8.59438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6D5F3" />
          <stop offset="0.199752" stopColor="#A6D5F3" />
          <stop offset="0.914043" stopColor="#252929" />
          <stop offset="1" stopColor="#1D1D1B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="10.9839"
          y1="11.6665"
          x2="10.8886"
          y2="8.46027"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6D5F3" />
          <stop offset="0.199752" stopColor="#A6D5F3" />
          <stop offset="0.914043" stopColor="#252929" />
          <stop offset="1" stopColor="#1D1D1B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="3.66933"
          y1="10.3708"
          x2="3.99149"
          y2="15.4933"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6D5F3" />
          <stop offset="0.199752" stopColor="#A6D5F3" />
          <stop offset="0.914043" stopColor="#252929" />
          <stop offset="1" stopColor="#1D1D1B" />
        </linearGradient>
        <clipPath id="clip0">
          <path
            d="M0 2.00101C0 0.896438 0.895431 0.00100708 2 0.00100708H18C19.1046 0.00100708 20 0.896438 20 2.00101V18.001C20 19.1056 19.1046 20.001 18 20.001H2C0.895431 20.001 0 19.1056 0 18.001V2.00101Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

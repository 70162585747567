import React from "react";
import cn from "classnames";
import GroupSelections from "./GroupSelections";
import {findSuppliersFilterTabs} from "constants/tabs.const";
import {useTranslation} from "react-i18next";

const SearchPreferenceGroupSelections = ({isEdit, values, setFieldValue}) => {
  const {t} = useTranslation();

  return (
    <div className={cn("more-setup-selections_wrapper", {_edit: isEdit})}>
      {findSuppliersFilterTabs.map(
        (item) =>
          item.visible && (
            <GroupSelections
              key={item.groupTitle}
              title={t(item.groupTitle)}
              name={item.groupName}
              setFieldValue={setFieldValue}
              selects={item.content.map((select) => ({
                list: select.array,
                name: select.label,
                mode: "multiple",
                subTitle: select.groupSubTitle,
                bottomElement: select.bottomElement,
                values: values?.[select.label],
              }))}
            />
          )
      )}
    </div>
  );
};

export default SearchPreferenceGroupSelections;

import React from "react";
// import {createArray, constTemplate} from "../../src/helpers/helper";
import {roles} from "constants/auth.const";
import {procurementStatuses, bulkProcurementOperations} from "constants/buyer.consts";
import {
  IconNumberHash,
  IconTextSize,
  IconRadioButton,
  IconSpotlights,
  IconSkullEmpty,
  IconSelfTimer,
} from "components/icons";
import {color} from "constants/color.consts";

const createArray = (lng) => Array.from(new Array(lng));

const constTemplate = ({name, number, withoutZero}) => {
  name = name.toString().toUpperCase();
  number += 1;
  number = number.toString();
  number = !withoutZero && number.length < 2 ? `0${number}` : number;
  return {
    label: `${name}_${number}`,
    value: `${name}_${number}`,
  };
};

export const availableInArray = createArray(23).map((_, number) =>
  constTemplate({name: "FIRST_SETUP_REGION", number})
);

export const entrepreneurOffersArray = createArray(94).map((_, number) =>
  constTemplate({name: "ENTREPRENEUR_OFFERS", number})
);

export const withinArray = createArray(7).map((_, number) =>
  constTemplate({name: "WITHIN", number})
);

export const realEstateCompanyTypeArray = createArray(5).map((_, number) =>
  constTemplate({name: "REALESTATE_COMPANY_TYPE", number})
);

export const realEstateServicesArray = createArray(3).map((_, number) =>
  constTemplate({name: "REALESTATE_SERVICES", number})
);

export const realEstatePropertyTypeArray = createArray(5).map((_, number) =>
  constTemplate({name: "REALESTATE_PROPERTY_TYPE", number})
);

export const consultingServicesArray = createArray(45).map((_, number) =>
  constTemplate({name: "CONSULTING_SERVICES", number})
);

export const salesRentalTypeArray = createArray(6).map((_, number) =>
  constTemplate({name: "SALESRENTAL_TYPE", number})
);

export const organizationsTypeArray = createArray(2).map((_, number) =>
  constTemplate({name: "ORGANIZATIONS_TYPE", number})
);

export const materialSupplierTypeArray = createArray(69).map((_, number) =>
  constTemplate({name: "MATERIALSUPPLIER_TYPE", number})
);

export const jobMinSizeArray = [
  {
    label: "FIRST_SETUP_SELECT_SIZE_MIN",
    value: 0,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_FIRST",
    value: 1,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_SECOND",
    value: 2,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_THIRD",
    value: 3,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_FOURTH",
    value: 4,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_FIFTH",
    value: 5,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_SIXTH",
    value: 6,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_SEVENTH",
    value: 7,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_EIGHTH",
    value: 8,
  },
];

export const jobMaxSizeArray = [
  {
    label: "FIRST_SETUP_SELECT_SIZE_MAX",
    value: 0,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_FIRST",
    value: 1,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_SECOND",
    value: 2,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_THIRD",
    value: 3,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_FOURTH",
    value: 4,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_FIFTH",
    value: 5,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_SIXTH",
    value: 6,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_SEVENTH",
    value: 7,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_EIGHTH",
    value: 8,
  },
  {
    label: "FIRST_SETUP_SELECT_SIZE_NINTH",
    value: 9,
  },
];

export const employeesArray = [
  {
    label: "FIRST_SETUP_SELECT_EMPLOYEES_00",
    value: "",
  },
  {
    label: "FIRST_SETUP_SELECT_EMPLOYEES_01",
    value: "FIRST_SETUP_SELECT_EMPLOYEES_01",
  },
  {
    label: "FIRST_SETUP_SELECT_EMPLOYEES_02",
    value: "FIRST_SETUP_SELECT_EMPLOYEES_02",
  },
  {
    label: "FIRST_SETUP_SELECT_EMPLOYEES_03",
    value: "FIRST_SETUP_SELECT_EMPLOYEES_03",
  },
  {
    label: "FIRST_SETUP_SELECT_EMPLOYEES_04",
    value: "FIRST_SETUP_SELECT_EMPLOYEES_04",
  },
  {
    label: "FIRST_SETUP_SELECT_EMPLOYEES_05",
    value: "FIRST_SETUP_SELECT_EMPLOYEES_05",
  },
  {
    label: "FIRST_SETUP_SELECT_EMPLOYEES_06",
    value: "FIRST_SETUP_SELECT_EMPLOYEES_06",
  },
  {
    label: "FIRST_SETUP_SELECT_EMPLOYEES_07",
    value: "FIRST_SETUP_SELECT_EMPLOYEES_07",
  },
];

// OVERVIEW TAB

export const memberOFOrganizationList = createArray(29).map((_, number) =>
  constTemplate({name: "MEMBER_OF_ORGANIZATION", number, withoutZero: true})
);

export const insurancesList = createArray(10).map((_, number) =>
  constTemplate({name: "INSURANCES", number, withoutZero: true})
);

export const unionAgreementOrganizationList = createArray(6).map((_, number) =>
  constTemplate({name: "UNION_AGREEMENT_ORGANIZATION", number, withoutZero: true})
);

export const localAgreementOrganizationList = createArray(8).map((_, number) =>
  constTemplate({name: "LOCAL_AGREEMENT_ORGANIZATION", number, withoutZero: true})
);

export const certificationsList = createArray(30).map((_, number) =>
  constTemplate({name: "CERTIFICATION", number, withoutZero: true})
);

export const role = [
  {
    label: "Make member",
    value: roles.MEMBER,
  },
  {
    label: "Make admin",
    value: roles.ADMIN,
  },
  {
    label: "Make owner",
    value: roles.OWNER,
  },
];

export const BLACKLIST = "blacklist";
export const FORCE_QUALIFICATION = "force-qualification";
export const organizationActionValue = {
  BLACKLIST: "blacklist",
  FORCE_QUALIFICATION: "force-qualification",
  HISTORY_INFO: "history-info",
};
export const organizationActions = [
  {
    label: (isInBlacklist) =>
      isInBlacklist ? "REMOVE_FROM_BLACKLIST" : "ADD_TO_BLACKLIST",
    value: organizationActionValue.BLACKLIST,
    visible: () => true,
    icon: <IconSkullEmpty width="20" height="20" color={color.blue} />,
  },
  {
    label: () => "BLACKLIST_HISTORY",
    value: organizationActionValue.HISTORY_INFO,
    visible: (isHide) => !isHide,
    icon: <IconSelfTimer color={color.blue} />,
  },
  {
    label: () => "QUALIFICATION_FORCE_LABEL",
    value: organizationActionValue.FORCE_QUALIFICATION,
    visible: () => true,
    icon: <IconSpotlights color={color.blue} />,
  },
];

export const orderingNameValues = [
  {label: "NAME", value: "NAME_default"},
  {label: "ASC", value: "NAME_ASC"},
  {label: "DESC", value: "NAME_DESC"},
];

export const orderingUpdatedValues = [
  {label: "LAST_CHANGE", value: "UPDATEDAT_default"},
  {label: "ASC_DATE", value: "UPDATEDAT_ASC"},
  {label: "DESC_DATE", value: "UPDATEDAT_DESC"},
];

export const orderingCreatedValues = [
  {label: "CREATED", value: "CREATEDAT_default"},
  {label: "CREATED_ASC", value: "CREATEDAT_ASC"},
  {label: "CREATED_DESC", value: "CREATEDAT_DESC"},
];

export const orderingCompanyValues = [
  {label: "COMPANY", value: "NAME_default"},
  {label: "ASC", value: "NAME_ASC"},
  {label: "DESC", value: "NAME_DESC"},
];

export const dropdownStatusColors = {
  [procurementStatuses.NO_STATUS]: "black",
  [procurementStatuses.SELECT_STATUS]: "discreteText",
  [procurementStatuses.RFP_DRAFT]: "blue",
  [procurementStatuses.RFP_OUT]: "green",
  [procurementStatuses.OFFERS_IN]: "green",
  [procurementStatuses.EVALUATING]: "orange",
  [procurementStatuses.CONTRACT_DRAFT]: "black",
  [procurementStatuses.CONTRACT]: "black",
  [procurementStatuses.STOPPED]: "red",
};

export const smartFormTypesSelect = [
  {
    label: "SMART_FORM_QUESTIONS_SELECT_YESNO",
    value: "boolean",
    icon: <IconRadioButton addClass="mr-2" />,
  },
  {
    label: "SMART_FORM_QUESTIONS_SELECT_STRING",
    value: "string",
    icon: <IconTextSize addClass="mr-2" />,
  },
  {
    label: "SMART_FORM_QUESTIONS_SELECT_NUMBER",
    value: "number",
    icon: <IconNumberHash addClass="mr-2" />,
  },
];

export const smartFormNumberTypesSelect = [
  // {label: "RFP_SMART_QUESTION_UNIT_NO_SELECT", value: null},
  {
    label: "RFP_SMART_QUESTION_UNIT_CONSTRUCTION_DAY",
    value: "RFP_SMART_QUESTION_UNIT_CONSTRUCTION_DAY",
  },
  {
    label: "RFP_SMART_QUESTION_UNIT_WORK_HOURS",
    value: "RFP_SMART_QUESTION_UNIT_WORK_HOURS",
  },
  {label: "RFP_SMART_QUESTION_UNIT_KR", value: "RFP_SMART_QUESTION_UNIT_KR"},
  {label: "RFP_SMART_QUESTION_UNIT_DAY", value: "RFP_SMART_QUESTION_UNIT_DAY"},
  {label: "RFP_SMART_QUESTION_UNIT_MONTH", value: "RFP_SMART_QUESTION_UNIT_MONTH"},
  {label: "RFP_SMART_QUESTION_UNIT_YEAR", value: "RFP_SMART_QUESTION_UNIT_YEAR"},
  {label: "RFP_SMART_QUESTION_UNIT_KG", value: "RFP_SMART_QUESTION_UNIT_KG"},
  {label: "RFP_SMART_QUESTION_UNIT_M", value: "RFP_SMART_QUESTION_UNIT_M"},
  {label: "RFP_SMART_QUESTION_UNIT_M2", value: "RFP_SMART_QUESTION_UNIT_M2"},
  {label: "RFP_SMART_QUESTION_UNIT_M3", value: "RFP_SMART_QUESTION_UNIT_M3"},
  {label: "RFP_SMART_QUESTION_UNIT_TONNE", value: "RFP_SMART_QUESTION_UNIT_TONNE"},
  {label: "RFP_SMART_QUESTION_UNIT_H", value: "RFP_SMART_QUESTION_UNIT_H"},
  {label: "RFP_SMART_QUESTION_UNIT_ITEM", value: "RFP_SMART_QUESTION_UNIT_ITEM"},
  {label: "RFP_SMART_QUESTION_UNIT_PERCENT", value: "RFP_SMART_QUESTION_UNIT_PERCENT"},
  {label: "RFP_SMART_QUESTION_UNIT_ITEMS", value: "RFP_SMART_QUESTION_UNIT_ITEMS"},
];

export const rolesSelect = [
  {label: "OWNER", value: roles.OWNER},
  {label: "ADMIN", value: roles.ADMIN},
  {label: "MEMBER", value: roles.MEMBER},
];

export const turnoverMiniSelect = [
  {label: "-", value: 0},
  {label: ">1 M", value: 1},
  {label: ">5 M", value: 5},
  {label: ">10 M", value: 10},
  {label: ">25 M", value: 25},
  {label: ">50 M", value: 50},
  {label: ">100 M", value: 100},
  {label: ">250 M", value: 250},
  {label: ">500 M", value: 500},
];

export const turnoverButtonSelect = [
  {label: "NO_FILTER", value: 0, rightLabel: ""},
  {label: "AT_LEAST", value: 1, rightLabel: "1M"},
  {label: "AT_LEAST", value: 5, rightLabel: "5M"},
  {label: "AT_LEAST", value: 10, rightLabel: "10M"},
  {label: "AT_LEAST", value: 25, rightLabel: "25M"},
  {label: "AT_LEAST", value: 50, rightLabel: "50M"},
  {label: "AT_LEAST", value: 100, rightLabel: "100M"},
  {label: "AT_LEAST", value: 250, rightLabel: "250M"},
  {label: "AT_LEAST", value: 500, rightLabel: "500M"},
];

export const creditRatingButtonSelect = [
  {label: "NO_FILTER", value: 0, rightLabel: ""},
  {label: "AT_LEAST", value: 80, rightLabel: "80"},
  {label: "AT_LEAST", value: 60, rightLabel: "60"},
  {label: "AT_LEAST", value: 42, rightLabel: "42"},
  {label: "AT_LEAST", value: 15, rightLabel: "15"},
];

export const bulkOperationsProcurementsSelectItems = Object?.keys(
  bulkProcurementOperations
);

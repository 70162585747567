import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import {inject, observer} from "mobx-react";
// locale
import {ReactNumberInput} from "components/ReactNumberInput";
import {Button} from "components/buttons/Button";
import {inviteStatuses} from "constants/invite.consts";
import {schema, initialValues} from "./formAttrs";

const InvitePhonePage = inject("store")(
  observer(({store: {auth, organization}, setPhone}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [isValidPhone, setIsValidPhone] = useState(false);

    const handleSubmit = (values) => {
      setLoading(true);
      setPhone(values.mobilePhone);
      auth
        .sendNumber(values.mobilePhone)
        .then(() => organization.setInviteStatus(inviteStatuses.codePage))
        .finally(() => setLoading(false));
    };

    return (
      <div className="invite-page_phone __wrapper">
        <div className="invite-page_phone __form">
          <p>{t("PHONE_NUMBER")}</p>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({values, isValid, setFieldValue}) => {
              return (
                <Form>
                  <ReactNumberInput
                    phone={values.mobilePhone}
                    setPhone={(value) => {
                      values.mobilePhone = value;
                      setFieldValue("mobilePhone", value);
                    }}
                    setIsValid={setIsValidPhone}
                  />
                  <p className="sms-extra-info mt-2">
                    {t("USE_A_NUMBER_THAT_CAN_RECIEVE_SMS")}
                  </p>
                  <div className="admin_authentication__form __actions df-row-jcc-center">
                    <Button
                      type="submit"
                      loading={loading}
                      addClass="mt-4"
                      disabled={
                        values.mobilePhone.length < 3 || !isValidPhone || !isValid
                      }
                      name={t("LOGIN_SEND_MESSAGE")}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  })
);

export default InvitePhonePage;

import React, {memo} from "react";

export const IconUserPerson = memo(({color}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99961 8.8C5.57321 8.8 3.59961 6.8264 3.59961 4.4C3.59961 1.9736 5.57321 0 7.99961 0C10.426 0 12.3996 1.9736 12.3996 4.4C12.3996 6.8264 10.426 8.8 7.99961 8.8ZM7.99961 0.8C6.01481 0.8 4.39961 2.4152 4.39961 4.4C4.39961 6.3848 6.01481 8 7.99961 8C9.98441 8 11.5996 6.3848 11.5996 4.4C11.5996 2.4152 9.98441 0.8 7.99961 0.8Z"
        fill={color || "#000"}
      />
      <path
        d="M14.4004 16H1.60039C0.938791 16 0.400391 15.4616 0.400391 14.8C0.400391 14.7456 0.411591 13.452 1.38039 12.16C1.94439 11.408 2.71639 10.8104 3.67559 10.3848C4.84679 9.86398 6.30199 9.59998 8.00039 9.59998C9.69879 9.59998 11.154 9.86398 12.3252 10.3848C13.2844 10.8112 14.0564 11.408 14.6204 12.16C15.5892 13.452 15.6004 14.7456 15.6004 14.8C15.6004 15.4616 15.062 16 14.4004 16ZM8.00039 10.4C5.21079 10.4 3.15239 11.1624 2.04759 12.6048C1.21959 13.6856 1.20119 14.7904 1.20039 14.8016C1.20039 15.0208 1.37959 15.2 1.60039 15.2H14.4004C14.6212 15.2 14.8004 15.0208 14.8004 14.8C14.8004 14.7904 14.782 13.6856 13.9532 12.6048C12.8476 11.1624 10.7892 10.4 8.00039 10.4Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

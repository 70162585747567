import React, {useEffect, useState, useCallback} from "react";
import Animate from "rc-animate";
import {inject, observer} from "mobx-react";
//local
import PresentationCardWrapper from "../components/PresentationCardWrapper";
import EvaluationCard from "../components/EvaluationCard";
import RatingObserver from "../components/OurEvaluationSection/RatingObserver";
import RatingsListing from "../components/OurEvaluationSection/RatingsListing";
import {ConfirmModal} from "components/ConfirmModal";
import {useTranslation} from "react-i18next";

const OurEvaluationSection = inject("store")(
  observer(({store: {auth, buyer, organization}}) => {
    const {projects} = buyer;
    const {organizationDetail} = organization;
    const {user, isStaff} = auth;
    const [showRatingDetail, setShowRatingDetail] = useState(false);
    const [comments, setComments] = useState([]);
    const {t} = useTranslation();
    const isOwnOrganization = user.ActiveOrganizationId === organizationDetail.id;

    const getEvaluations = useCallback(
      () =>
        organization
          .getOrganizationEvaluation(organizationDetail?.id)
          .then(({data}) => setComments(data)),
      [organization, organizationDetail.id]
    );

    const handleSendComment = (message, projectId) =>
      organization
        .createOrganizationEvaluation(organizationDetail?.id, message, projectId)
        .then(() => getEvaluations());

    const handleDeleteComment = (evaluatedId) =>
      ConfirmModal({
        title: t("WANT_DELETE_COMMENT"),
        text: t("CANT_DELETE_COMMENT"),
        type: "warning",
        onOk: () =>
          organization
            .removeOrganizationEvaluation(organizationDetail?.id, evaluatedId)
            .then(() => getEvaluations()),
      });

    useEffect(() => {
      !isOwnOrganization && organization.getListAllRatings(organizationDetail?.id);
      !isOwnOrganization && getEvaluations();
      !isOwnOrganization && buyer.getProjects(true, "all");
    }, [organization, organizationDetail.id, isOwnOrganization, getEvaluations, buyer]);

    return (
      <div className="our-evaluation-section_wrapper">
        <p className="info-chunk">{t("RATING_INFO")}</p>

        <PresentationCardWrapper title={t("OUR_EVALUATION")}>
          <p>{t("OUR_EVALUATION_DESCRIPTION")}</p>
        </PresentationCardWrapper>

        <div className="rating-observer_wrapper">
          <div className="rating-observer_head">
            <RatingObserver
              rating={{
                name: t("OVERALL"),
                score: organization.overallRating,
                description: t("OVERALL_QUESTION"),
              }}
              color="black"
            />
            <button
              className={`rating-observer_head__btn ${showRatingDetail && "__collapse"}`}
              onClick={() => setShowRatingDetail(!showRatingDetail)}
            >
              {showRatingDetail ? t("HIDE_DETAILED_RATINGS") : t("SHOW_DETAILED_RATINGS")}
            </button>
          </div>

          <div className="rating-observer_body__wrapper">
            <Animate transitionName="fade" transitionAppear>
              {showRatingDetail && <RatingsListing />}
            </Animate>
          </div>
        </div>

        <PresentationCardWrapper title={t("OUR_EVALUATION")}>
          <p>{t("OUR_EVALUATION_COMMENT")}</p>
        </PresentationCardWrapper>
        <EvaluationCard
          comments={comments}
          projects={projects ?? []}
          authUser={user}
          isStaff={isStaff}
          onSend={handleSendComment}
          onDelete={handleDeleteComment}
        />
      </div>
    );
  })
);

export default OurEvaluationSection;

import React from "react";
import {Select} from "antd";
import {useTranslation} from "react-i18next";
//local
import {Tag} from "components/Tag";
import {color as constColor} from "constants/color.consts";
import {IconChevronDownMini} from "components/icons";
import cn from "classnames";

const {Option} = Select;

const ActionSelect = ({
  list,
  iconsList,
  onChange,
  label,
  color = constColor.black,
  isRelativeCustomContent,
  customContent,
  addClass,
}) => {
  const {t} = useTranslation();

  const options = () =>
    list.map((item, i) => (
      <Option key={item} value={item} className="__uppercase fz-10 __bold">
        {iconsList?.[i]}
        {t(item)}
      </Option>
    ));

  return (
    <div
      className={cn("mini-select_wrapper", {[addClass]: !!addClass})}
      style={{position: isRelativeCustomContent && "relative"}}
    >
      <Select
        className="mini-select_select"
        bordered={false}
        onChange={onChange}
        suffixIcon={null}
        defaultValue={null}
        value={null}
        placeholder={
          <Tag
            text={label}
            color={color}
            suffixIcon={<IconChevronDownMini color={color} />}
            noBackground
            needTranslation
          />
        }
        popupClassName="mini-select_dropdown auto-width"
      >
        {options()}
      </Select>
      {customContent}
    </div>
  );
};

export default ActionSelect;

import React, {useState} from "react";
import {useTranslation} from "react-i18next";
//local
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {RichField} from "components/RichField";
import {IconPaperPencil} from "components/icons";

export const QualificationMessageModal = ({
  initialMessage,
  organizationName,
  onSave,
  onClose,
}) => {
  const {t} = useTranslation();

  const [message, setMessage] = useState(
    t(
      initialMessage ?? "SETTINGS_ORGANIZATION_OUR_SUPPLIERS_MESSAGE_MODAL_DESCRIPTION"
    ).format(organizationName)
  );

  return (
    <Modal
      onClose={onClose}
      width={720}
      whiteTitle
      boldTitle
      noCenterContent
      titleIcon={<IconPaperPencil addClass="mr-4" />}
      titleText="SETTINGS_ORGANIZATION_OUR_SUPPLIERS_MESSAGE_MODAL_TITLE"
    >
      <div className="pa-8">
        <p className="mb-8">
          {t("SETTINGS_ORGANIZATION_OUR_SUPPLIERS_MESSAGE_MODAL_MESSAGE")}
        </p>
        <RichField
          data={message}
          placeholder="RFP_DESCRIPTION"
          importType="html"
          exportType="html"
          onChange={setMessage}
        />
        <div className="df-row-jce-center mt-4">
          <ButtonTransparent
            name="CANCEL"
            medium
            addClass="mr-4"
            color="red"
            onClick={onClose}
          />
          <ButtonTransparent
            name="SAVE"
            medium
            nonTransparent
            disabled={false}
            onClick={() => onSave(message)}
          />
        </div>
      </div>
    </Modal>
  );
};

import React from "react";
import {useTranslation} from "react-i18next";
import {Select} from "antd";
//local
import {Tag} from "components/Tag";
import {dropdownStatusColors} from "constants/select.consts";
import {IconChevronDown} from "components/icons";
import {color} from "constants/color.consts";
import cn from "classnames";

const ProcurementStatusesSelect = ({
  list,
  multiple,
  onChange,
  disabled,
  defaultValue,
  selectedValue,
  addClass,
}) => {
  const {t} = useTranslation();
  const {Option} = Select;

  const options = () =>
    list?.map((status) => (
      <Option key={status} value={status}>
        <Tag
          text={status}
          color={dropdownStatusColors[status]}
          suffixIcon={
            <IconChevronDown
              color={
                [
                  "PROJECT_ITEM_STATUS_SELECT_STATUS",
                  "PROJECT_ITEM_STATUS_NO_STATUS",
                ].includes(status)
                  ? color[dropdownStatusColors[status]]
                  : color.white
              }
            />
          }
          noBackground={
            (multiple && !selectedValue?.includes(status)) ||
            [
              "PROJECT_ITEM_STATUS_SELECT_STATUS",
              "PROJECT_ITEM_STATUS_NO_STATUS",
            ].includes(status)
          }
          needTranslation
        />
      </Option>
    ));

  const tagRender = () => {
    const count =
      selectedValue?.length === list?.length ? "" : selectedValue?.length ?? "";
    return (
      <Tag
        text={`${count} ${t(
          count ? "PROJECT_ITEM_STATUS_STATUSES" : "PROJECT_ITEM_STATUS_NO_STATUS"
        )}`}
        suffixIcon={<IconChevronDown color={color.black} />}
        color={color.black}
        noBackground
      />
    );
  };

  return (
    <div className={cn("procurement-statuses-select_wrapper", {[addClass]: !!addClass})}>
      <Select
        className="procurement-statuses_select"
        bordered={false}
        tagRender={multiple ? tagRender : undefined}
        placeholder={
          multiple ? (
            <Tag
              text="PROJECT_ITEM_STATUS_NO_STATUS"
              suffixIcon={<IconChevronDown color={color.black} />}
              color={color.black}
              noBackground
              needTranslation
            />
          ) : undefined
        }
        showSearch={false}
        maxTagCount={1}
        onChange={onChange}
        mode={multiple ? "multiple" : undefined}
        suffixIcon={null}
        defaultValue={defaultValue}
        value={selectedValue}
        disabled={disabled}
        popupClassName="procurement-statuses_dropdown"
      >
        {options()}
      </Select>
    </div>
  );
};

export default ProcurementStatusesSelect;

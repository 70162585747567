import React, {memo} from "react";

export const IconSelfTimer = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C7.462 20 5.077 19.0121 3.282 17.2181C1.487 15.4241 0.5 13.038 0.5 10.501C0.5 9.01805 0.832 7.59705 1.488 6.27805C1.611 6.03105 1.911 5.93005 2.158 6.05305C2.405 6.17605 2.506 6.47605 2.383 6.72305C1.797 7.90205 1.5 9.17305 1.5 10.5C1.5 15.187 5.313 19 10 19C14.687 19 18.5 15.187 18.5 10.5C18.5 5.81305 14.687 2.00005 10 2.00005C9.747 2.00005 9.535 1.81205 9.504 1.56105C9.473 1.31005 9.634 1.07605 9.879 1.01505L13.879 0.0150492C14.147 -0.0519508 14.418 0.111049 14.485 0.379049C14.552 0.647049 14.389 0.918049 14.121 0.985049L12.613 1.36205C14.149 1.79905 15.557 2.62205 16.717 3.78205C18.511 5.57605 19.499 7.96205 19.499 10.5C19.499 13.038 18.511 15.423 16.717 17.217C14.923 19.011 12.537 19.9991 10 19.9991V20Z"
        fill={color || "#000"}
      />
      <path
        d="M10.0002 11.027C9.91221 11.027 9.82221 11.004 9.74221 10.955L4.74221 7.92798C4.50621 7.78498 4.43021 7.47798 4.57321 7.24098C4.71621 7.00398 5.02321 6.92898 5.26021 7.07198L10.2602 10.099C10.4962 10.242 10.5722 10.549 10.4292 10.786C10.3352 10.941 10.1702 11.027 10.0012 11.027H10.0002Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
//local
import {Header} from "components/Header/Header";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconBankIdLogo} from "components/icons";
import {onboardingModalNames} from "../../constants/modal.conts";
import {color} from "constants/color.consts";

const IdentifyOwner = inject("store")(
  observer(({store: {auth}}) => {
    const {t} = useTranslation();

    return (
      <div className="identify-owner__wrapper">
        <Header addClass="settings-page__header" type="language" />
        <div className="identify-owner__container">
          <div className="identify-owner__title">
            <h1 className="custom-title">{t("WHO_IS_THERE")}</h1>
            <p className="custom-text">{t("IDENTIFY_OWNER_TEXT")}</p>
          </div>
          <div className="identify-owner__form">
            <div className="btn-custom_wrap">
              <ButtonTransparent
                type="submit"
                name={t("IDENTIFY_USING")}
                nonTransparent
                iconRight={<IconBankIdLogo color={color.white} />}
                onClick={() => auth.updateAuthModalName(onboardingModalNames.identify)}
              />
            </div>
          </div>

          <div className="smaller">
            <p>{t("IDENTIFY_OWNER_DESCRIPTION_1")}</p>
            <p>{t("IDENTIFY_OWNER_DESCRIPTION_2")}</p>
          </div>
        </div>
      </div>
    );
  })
);

export default IdentifyOwner;

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {inject, observer} from "mobx-react";
//local
import {Layout} from "components/UI/layout";
import {Preloader} from "components/Preloader";
import FeedHeader from "./components/FeedHeader";
import FeedCreateAccount from "./components/FeedCreateAccount";
import FeedPost from "./components/FeedPost";
import {PaginationType} from "components/Pagination";

const FeedPage = inject("store")(
  observer(({store: {publicStore, auth, organization}}) => {
    const {feedId} = useParams();
    const {loading, posts: dataPosts} = publicStore;
    const [post, setPost] = useState(null);
    const {token} = auth;

    useEffect(
      () => {
        feedId &&
          publicStore
            .getCurrentPost(feedId.slice(feedId.indexOf("_") + 1), !token)
            .then((data) => setPost(data));
        !feedId && post && setPost(null);
      },
      // eslint-disable-next-line
      [feedId]
    );

    // eslint-disable-next-line
    useEffect(() => {
      publicStore.getPosts(null, !token);
    }, [publicStore, token]);

    const getNextPosts = (pageNumber) => publicStore.getPosts(pageNumber, !token);

    return (
      <Layout>
        <div className="feed">
          <FeedHeader />
          {!token && <FeedCreateAccount />}
          {token && <FeedPost isNew />}
          {loading && <Preloader addClass="preloader500" />}
          {post && <FeedPost post={post} addClass="specific-post-divider" />}
          {!loading &&
            dataPosts.posts?.length &&
            dataPosts.posts
              .filter((item) => item.id !== post?.id)
              .map((post) => <FeedPost key={post.id} post={post} />)}
          {dataPosts.totalItems > 10 && (
            <PaginationType
              totalItems={dataPosts.totalItems}
              currentPage={dataPosts.currentPage}
              countPerPage={10}
              onPageChange={(pageNumber) => organization.searchSuppliers(pageNumber)}
              getNextPage={getNextPosts}
            />
          )}
        </div>
      </Layout>
    );
  })
);

export default FeedPage;

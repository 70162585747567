import React, {memo} from "react";

export const IconSpotlights = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.581 15.066L13.45 7.87699L15.972 0.66499C16.051 0.43999 15.96 0.19099 15.755 0.0699901C15.55 -0.0510099 15.288 -0.0120099 15.129 0.16499L10.883 4.86799L6.88098 0.17599C6.72298 -0.0090099 6.45398 -0.0540099 6.24498 0.0709901C6.03598 0.19599 5.94698 0.45299 6.03398 0.67999L8.61098 7.38499L0.458977 16.414C0.158977 16.743 0.000976562 17.111 0.000976562 17.501C0.000976562 18.245 0.574977 18.909 1.61598 19.372C2.52798 19.778 3.73098 20.001 5.00098 20.001C6.27098 20.001 7.47398 19.778 8.38598 19.372C9.22198 19.001 9.75598 18.499 9.93398 17.931L11.234 14.213L12.053 16.345C12.163 16.703 12.482 17.159 13.328 17.521C14.05 17.83 14.999 18.001 16 18.001C17.001 18.001 17.95 17.831 18.672 17.521C19.828 17.026 20 16.356 20 16.001C20 15.775 19.93 15.422 19.581 15.067V15.066ZM14.209 2.67499L12.698 6.99599L11.537 5.63499L14.209 2.67499ZM12.316 8.08899L11.19 11.309L9.76398 7.59799L10.861 6.38299L12.316 8.08899ZM8.01398 3.04499L10.207 5.61599L9.36198 6.55199L8.01398 3.04499ZM7.97898 18.457C7.19198 18.807 6.13398 19 4.99998 19C3.86598 19 2.80798 18.807 2.02098 18.457C1.39098 18.177 0.999977 17.81 0.999977 17.5C0.999977 17.19 1.39098 16.823 2.02098 16.543C2.80798 16.193 3.86598 16 4.99998 16C6.13398 16 7.19198 16.193 7.97898 16.543C8.60898 16.823 8.99998 17.19 8.99998 17.5C8.99998 17.81 8.60898 18.177 7.97898 18.457ZM9.44098 16.309C9.17698 16.053 8.82198 15.823 8.38498 15.629C7.47298 15.223 6.26998 15 4.99998 15C4.25498 15 3.53298 15.077 2.87998 15.223L9.01198 8.43099L10.679 12.769L9.44098 16.309ZM13.067 8.96999L17.467 14.129C17.007 14.045 16.51 14 15.999 14C14.998 14 14.049 14.17 13.327 14.48C13.007 14.617 12.763 14.768 12.576 14.921L11.743 12.753L13.066 8.96999H13.067ZM16 17C14.139 17 13 16.353 13 16C13 15.647 14.139 15 16 15C17.861 15 19 15.647 19 16C19 16.353 17.861 17 16 17Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconSpotlights;

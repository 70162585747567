import React from "react";
import {useTranslation} from "react-i18next";
// local
import cn from "classnames";

export const Explanation = ({
  icon,
  text,
  width,
  fitWidth,
  center,
  textCenter,
  thinVPadding,
  addClass,
}) => {
  const {t} = useTranslation();

  return (
    <div
      className={cn(
        "explanation_wrapper",
        {[addClass]: !!addClass},
        {__center: !!center},
        {"__thin-vertical-padding": !!thinVPadding}
      )}
      style={{width: fitWidth ? "fit-content" : `${width}${+width < 101 ? "%" : "px"}`}}
    >
      {icon}
      <span
        className={`explanation_text${icon ? " ml-2" : ""}${
          textCenter ? " __center" : ""
        }`}
      >
        {t(text)}
      </span>
    </div>
  );
};

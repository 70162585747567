import React from "react";

export const IconLinkBotrygg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2" fill="#e9e9e9" />
      <path d="M10 2L18 7.36527H2L10 2Z" fill="#801716" />
      <rect x="4" y="8.56525" width="12" height="1.33474" fill="#444949" />
      <rect x="4" y="11.1" width="12" height="1.33474" fill="#444949" />
      <rect x="4" y="16" width="12" height="1.33474" fill="#444949" />
      <path d="M4 13.6348H16V14.9695H4V13.6348Z" fill="#444949" />
    </svg>
  );
};

import React from "react";
import {useTranslation} from "react-i18next";
//local
import {Modal} from "components/Modal";
import {
  IconNotificationCircle,
  IconHandOnRight,
  IconPlane,
  IconMailLetter,
} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {color} from "constants/color.consts";

export const PublishedModal = ({onClose, numberOrganizations, numberEmails}) => {
  const {t} = useTranslation();

  return (
    <Modal
      onClose={onClose}
      percentageWide="30%"
      titleIcon={<IconNotificationCircle color={color.black} className="mr-2" />}
      titleText="MODAL_PUBLISHED_TITLE"
    >
      <div className="modal-published">
        <div className="modal-published__info">
          <div className="modal-published__description">
            <IconPlane addClass="mr-1" />
            {t("MODAL_PUBLISHED_DESCRIPTION")}
            {` ${numberOrganizations + numberEmails} `}
            {t("MODAL_PUBLISHED_DESCRIPTION_2")}
          </div>
          {!!numberOrganizations && (
            <div className="modal-published__action">
              <IconHandOnRight color={color.green} addClass="mr-1" />
              {`${numberOrganizations} `}
              {t("MODAL_PUBLISHED_ACTION")}
            </div>
          )}
          {!!numberEmails && (
            <div className="modal-published__action">
              <IconMailLetter
                width="20"
                height="13"
                color={color.purple}
                addClass="mr-1"
              />
              {`${numberEmails} `}
              {t("MODAL_PUBLISHED_ACTION_2")}
            </div>
          )}
          <div className="modal-published__notification">
            {t("MODAL_PUBLISHED_NOTIFICATION")}
          </div>
          <ButtonTransparent
            name={"GOT_IT"}
            large
            uppercase
            bold
            fullWidth
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};

import React, {useState, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
//local
import FeedPostView from "./FeedPostView";
import FeedPostEdit from "./FeedPostEdit";
import {emptyPost} from "constants/posts.consts";
import {MAIN_PAGE} from "../../../../constants/routes.const";
import {showSuccess} from "helpers/notifications.helper";
import {ConfirmModal} from "components/ConfirmModal";
import cn from "classnames";

const FeedPost = inject("store")(
  observer(({store: {auth, clientStore, publicStore}, post, isNew, addClass}) => {
    const {myOrganizations, activeOrganizationId, token} = auth;
    const {language} = clientStore;
    const {
      loading,
      btnLoading,
      posts: {currentPage},
    } = publicStore;
    const [isEdit, setIsEdit] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const organizationName = useMemo(
      () =>
        myOrganizations?.find((organization) => organization.id === activeOrganizationId)
          ?.name ?? "",
      [myOrganizations, activeOrganizationId]
    );

    const isInterested = post?.OrganizationsInterestedBy?.some(
      (item) => item.id === activeOrganizationId
    );

    const isMyPost =
      activeOrganizationId && activeOrganizationId === post?.Organization?.id;

    const clickSave = async (post) => {
      await publicStore.saveCurrentPost(post, post.id);
      showSuccess();
      isNew && (await publicStore.getPosts());
      isNew && navigate("/feed", {replace: true});
    };

    const confirmDelete = () => {
      ConfirmModal({
        title: t("WANT_DELETE_POST"),
        text: t("CANT_RETURN"),
        type: "warning",
        onOk: clickDelete,
      });
    };

    const clickDelete = async () => {
      await publicStore.deletePost(post.id);
      showSuccess(t("DELETING_POST"));
      await publicStore.getPosts(currentPage);
    };

    const clickInterest = () => {
      if (!token) return navigate(MAIN_PAGE);
      publicStore.changePostStatus(post.id, isInterested);
      showSuccess();
    };

    return (
      <div className={cn("feed-post__wrapper", {[addClass]: !!addClass})}>
        {isNew ? (
          <FeedPostEdit
            post={emptyPost}
            organization={organizationName}
            language={language}
            loading={loading}
            savePost={clickSave}
          />
        ) : isEdit ? (
          <FeedPostEdit
            post={post}
            clickCancel={() => setIsEdit(false)}
            language={language}
            loading={btnLoading}
            savePost={clickSave}
          />
        ) : (
          <FeedPostView
            showInterest={clickInterest}
            editPost={() => setIsEdit(true)}
            deletePost={confirmDelete}
            post={post}
            interested={isInterested}
            myPost={isMyPost}
            language={language}
            hideInfo={!token}
          />
        )}
      </div>
    );
  })
);

export default FeedPost;

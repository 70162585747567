import React, {memo} from "react";

export const IconCrossGray = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7072 10.5L16.3532 4.85398C16.5482 4.65898 16.5482 4.34198 16.3532 4.14698C16.1582 3.95198 15.8412 3.95198 15.6462 4.14698L10.0002 9.79298L4.35423 4.14698C4.15923 3.95198 3.84223 3.95198 3.64723 4.14698C3.45223 4.34198 3.45223 4.65898 3.64723 4.85398L9.29323 10.5L3.64723 16.146C3.45223 16.341 3.45223 16.658 3.64723 16.853C3.74523 16.951 3.87323 16.999 4.00123 16.999C4.12923 16.999 4.25723 16.95 4.35523 16.853L10.0012 11.207L15.6472 16.853C15.7452 16.951 15.8732 16.999 16.0012 16.999C16.1292 16.999 16.2572 16.95 16.3552 16.853C16.5502 16.658 16.5502 16.341 16.3552 16.146L10.7092 10.5H10.7072Z"
        fill={color || "#D8D8D8"}
      />
    </svg>
  );
});

import React from "react";
import {DatePicker} from "formik-antd";
import {ErrorMessage} from "formik";
import {checkField} from "helpers/helper";
import {useTranslation} from "react-i18next";

const EditDatePecker = ({label, name, highlightIfEmpty, onChange, placeholder}) => {
  const {t} = useTranslation();

  return (
    <div className="editBlock_date-picker__item">
      {label ? <label className="editBlock_field__label">{label}</label> : null}
      <DatePicker
        className={`editBlock_date-picker__field${highlightIfEmpty ? " bg-yellow" : ""}`}
        name={name}
        placeholder={t(placeholder ?? "SELECT_DATE")}
        onChange={onChange}
      />
      {name && (
        <ErrorMessage name={name}>
          {(msg) => <p className="form-error">{checkField(msg)}</p>}
        </ErrorMessage>
      )}
    </div>
  );
};

export default EditDatePecker;

import React, {memo} from "react";
import {useTranslation} from "react-i18next";
// local
import {Button} from "../../../buttons/Button";

const Notification = memo(
  ({
    user,
    joinMessage,
    acceptJoinRequest,
    denyJoinRequest,
    organizationName,
    extraPersonPrice,
  }) => {
    const {t} = useTranslation();
    return (
      <div className="member_notification__item">
        <div className="df-row-jsb-center">
          <div className="member_notification__desc">
            <p>
              <strong>
                {user.email} {t("IS_REQUESTING_MEMBERSHIP")} {organizationName}
              </strong>
            </p>
          </div>
          <div className="member_notification__actions">
            <Button
              name={t("DENY_MEMBERSHIP")}
              onClick={denyJoinRequest}
              addClass="member_notification__btn"
            />
            <Button
              name={t("ACCEPT_MEMBERSHIP")}
              onClick={acceptJoinRequest}
              addClass="member_notification__btn__filled"
            />
          </div>
        </div>

        <p className="member_notification__message mb-2 mt-6">
          {`${t("EXTRA_USER_MEMBERSHIP_MESSAGE")} ${user.email}.`}
        </p>
        <div className="member_notification__message__wrapper">
          <span>{joinMessage ?? t("DIRECT_INVITE_EMPTY_MESSAGE")}</span>
        </div>
      </div>
    );
  }
);

export default Notification;

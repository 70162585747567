import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
// locale
import EmptyData from "components/EmptyData";
import {Preloader} from "components/Preloader";
import InviteUsersForm from "./InviteUsersForm";
import InviteUserList from "./InviteUserList";

const InviteUsers = inject("store")(
  observer(({store: {auth, organization, buyer}}) => {
    const {user} = auth;
    const {subscriptionRoles} = organization;
    const [fetching, setFetching] = useState(true);
    const [projectsList, setProjectsList] = useState([]);
    const {t} = useTranslation();
    const extraPersonPrice =
      user?.ActiveOrganization?.SubscribeUnit?.extraPersonsPrice?.replace(".00", "");

    useEffect(() => {
      organization.getInviteUsers().finally(() => setFetching(false));
      organization.getAvailableSubscriptionRoles(
        "SHOW_SETTINGS_INVITE_TAB_SUBSCRIPTION_ROLE"
      );
      buyer.getSimplifiedProjects().then(({data}) =>
        setProjectsList(
          data
            .filter((item) => !!item.name)
            .map((project) => ({
              value: project.id,
              label: project.name,
            }))
        )
      );
    }, [organization, buyer]);

    return (
      <div className="invite-user__wrapper">
        <h3 className="invite-user__title">{t("SEND_INVITE_TO_USERS_TITLE")}</h3>
        <InviteUsersForm
          organizationStore={organization}
          extraPersonPrice={extraPersonPrice ?? "-"}
          projectsList={projectsList}
          subscriptionRoles={subscriptionRoles}
        />

        <h3 className="invite-user__title">{t("SENT_INVITES")}</h3>
        {fetching ? (
          <div className="invite-user__table __wrapper __preloader">
            <Preloader addClass="preloader500" />
          </div>
        ) : (
          <div className="invite-user__table __wrapper">
            {organization?.inviteUsers?.length ? (
              <InviteUserList
                organizationStore={organization}
                projectsList={projectsList}
              />
            ) : (
              <EmptyData />
            )}
          </div>
        )}
      </div>
    );
  })
);

export default InviteUsers;

import React, {useCallback, useEffect, useState} from "react";
import {Select} from "antd";
import {inject, observer} from "mobx-react";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
//local
import {LANGUAGE} from "../constants/language.constants";
import {IconLangSwedish, IconLangEnglish, IconLangFinnish} from "components/icons";
import {getStorage, setStorage} from "helpers/storage";

export const Language = inject("store")(
  observer(({store: {clientStore}, position}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {language} = clientStore;
    const query = new URLSearchParams(window.location.search);
    const urlLanguage = query.get("language");

    const [localLanguage, setLocalLanguage] = useState(urlLanguage ?? language);
    const {Option} = Select;
    const {i18n} = useTranslation();
    const {t} = useTranslation();

    const iconLanguage = {
      en: <IconLangEnglish />,
      sv: <IconLangSwedish />,
      fi: <IconLangFinnish />,
    };

    const setLanguageGlobal = useCallback(
      (lng) => i18n.changeLanguage(lng).then(() => setLocalLanguage(lng)),
      [i18n]
    );

    const setLangauge = useCallback(
      (language) => {
        setLanguageGlobal(language);
        setStorage(LANGUAGE, language);
        clientStore.setLanguage(language);
      },
      [setLanguageGlobal, clientStore]
    );

    const handleChange = (lng) => {
      setLangauge(lng);
      urlLanguage && navigate(`${location.pathname}?language=${lng}`, {replace: true});
    };

    useEffect(() => {
      const lng = urlLanguage || getStorage(LANGUAGE) || language;
      setLangauge(lng);
    }, [urlLanguage, setLangauge, language]);

    return (
      <div className="language">
        {iconLanguage[localLanguage]}
        <Select
          className="language_select"
          value={localLanguage || t("LANGUAGE_SWEDISH")}
          onChange={handleChange}
          placement={position}
        >
          <Option value="sv">{t("LANGUAGE_SWEDISH")}</Option>
          <Option value="en">{t("LANGUAGE_ENGLISH")}</Option>
          <Option value="fi">{t("LANGUAGE_FINNISH")}</Option>
        </Select>
      </div>
    );
  })
);

import React from "react";
import {useTranslation} from "react-i18next";
// local
import {Tag} from "components/Tag";
import {VideoInstructionLink} from "components/VideoInstructionLink";
import {color} from "constants/color.consts";

const SubHeading = ({title, subTitle, icon, img, hasNew, hasBeta, videoLink}) => {
  const {t} = useTranslation();

  return (
    <div className="sub-heading">
      {img && <img src={img} alt="" />}
      {icon}
      <h4>
        {t(title)}
        {subTitle && <span>{subTitle}</span>}
      </h4>
      {hasNew && <span className="sub-heading__new-label">NEW!</span>}
      {hasBeta && (
        <Tag
          text="BETA"
          needTranslation
          color={color.red}
          uppercase
          small
          addClass="ml-2"
        />
      )}
      {videoLink && (
        <VideoInstructionLink
          label={videoLink.label}
          link={videoLink.link}
          addClass="ml-8"
        />
      )}
    </div>
  );
};

export default SubHeading;

import React, {useState, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {debounce} from "lodash";
// local
import RequestCard from "../components/RequestCard";
import RequestEdit from "../components/RequestEdit/RequestEdit";
import SmartFormQuestions from "../components/SmartForm/SmartFormQuestions";
import {hasUserPermission} from "helpers/helper";
import {roles} from "constants/auth.const";
import RequestDocuments from "components/requestCard/RequestDocuments";

export const Request = inject("store")(
  observer(({store: {auth, buyer}}) => {
    const {isNew, isEdit, currentRequest, isPublishedRequest} = buyer;
    const {clientRole, user} = auth;
    const [smartQuestions, setSmartQuestions] = useState(
      currentRequest.smartFormQuestions ?? []
    );
    const isAdminOwner = hasUserPermission([roles.ADMIN, roles.OWNER], clientRole);
    const hasRevisions = useMemo(
      () => !!currentRequest?.documents?.length,
      [currentRequest?.documents]
    );

    const changeSmartFormQuestions = debounce((value) => {
      setSmartQuestions(value);
      buyer.updateCurrentRequestInfo({smartFormQuestions: value});
    }, 1000);

    const answersListIds = currentRequest?.recipients?.registered
      ?.map((item) => item.Offer?.smartFormAnswers?.filter((answer) => !!answer?.value))
      .filter((item) => !!item)
      .flat()
      .map((item) => item?.questionId);

    return (
      <div>
        {isEdit || isNew ? <RequestEdit /> : <RequestCard />}
        <SmartFormQuestions
          isNew={isNew}
          isEdit={isEdit}
          permissions={user.permissions}
          isAdminOwner={isAdminOwner}
          titleHide={!(isEdit || isNew) && !currentRequest?.smartFormQuestions?.length}
          questions={smartQuestions}
          isHaveAnswers={answersListIds}
          onChange={changeSmartFormQuestions}
        />
        {hasRevisions && (
          <RequestDocuments
            revisions={currentRequest?.documents}
            editable={isNew || isEdit}
            columnTitles={{
              contracts: "CONTRACTDRAFT",
              documents: "DOCUMENTS",
              plans: "PLANS",
              links: "LINKS",
            }}
            showTabs={isPublishedRequest}
            standardAgreement={currentRequest.standardAgreement}
          />
        )}
      </div>
    );
  })
);

import React from "react";
import {color} from "constants/color.consts";
import {
  IconGroupWork,
  IconStar,
  IconRadar,
  IconPlane,
  IconBinoculars,
  IconHandOnRight,
  IconBullHorn,
} from "components/icons";
import {
  PROJECTS,
  CONTRACTS,
  OUR_BLACKLIST,
  FRAMEWORK_AGREEMENTS,
  OUR_FAVOURITES,
  INVITE_COMPANIES,
  PUBLIC_RFTS,
  OUR_QUALIFICATIONS,
  REQUESTS_TO_US,
  OUR_OFFERS,
  CONTRACTS_TO_US,
  REQUEST_TO_US,
  CONTRACT_TO_US,
} from "constants/routes.const";
import {sortProcurementsSelectItems} from "constants/buyer.consts";

// To match the label name and the field name being sent
export const selectFieldName = {
  NAME: "name",
  COMPANY: "Proposal.Organization.name",
  LAST_CHANGE: "updatedAt",
  UPDATEDAT: "updatedAt",
  CREATEDAT: "createdAt",
};

// default search direction settings for different fields
export const defaultDirectionsByField = {
  name: "ASC",
  createdAt: "DESC",
  updatedAt: "DESC",
};

export const defaultNameSearch = {
  name: "",
  field: "name",
  direction: "default",
};

export const defaultNameFieldSearch = {
  field: "name",
  direction: "default",
};

export const defaultCreatedFieldSearch = {
  field: "createdAt",
  direction: "default",
};

export const defaultUpdatedFieldSearch = {
  field: "updatedAt",
  direction: "default",
};

export const defaultRequestParamsSearch = {
  sort: sortProcurementsSelectItems[0].value,
};

export const subscriptionNameKeys = {
  SUBSCRIPTION_NAME_01: "SUBSCRIPTION_NAME_01",
  SUBSCRIPTION_NAME_02: "SUBSCRIPTION_NAME_02",
  SUBSCRIPTION_NAME_03: "SUBSCRIPTION_NAME_03",
  SUBSCRIPTION_NAME_04: "SUBSCRIPTION_NAME_04",
  SUBSCRIPTION_NAME_05: "SUBSCRIPTION_NAME_05",
  SUBSCRIPTION_NAME_06: "SUBSCRIPTION_NAME_06",
  SUBSCRIPTION_NAME_07: "SUBSCRIPTION_NAME_07",
};

export const subscriptionPriceType = {
  PRICE_PER_MONTH: "PRICE_PER_MONTH",
  PRICE_PER_USER_MONTH: "PRICE_PER_USER_MONTH",
};

export const subscriptionPriceTypeDescription = {
  PRICE_PER_MONTH: "PRICE_PER_MONTH_DESCRIPTION",
  PRICE_PER_USER_MONTH: "PRICE_PER_USER_MONTH_DESCRIPTION",
};

export const subscriptionPricePeriod = {
  PER_SIX_MONTH: "PRICE_PERIOD_PER_6_MONTH",
  PER_YEAR: "PRICE_PERIOD_PER_12_MONTH",
};

export const subscriptionPriceKey = {
  ANNUAL: "priceAnnual",
  SEMIANNUAL: "priceSemiannual",
};

export const subscriptionPriceKeys = {
  [subscriptionPricePeriod.PER_SIX_MONTH]: subscriptionPriceKey.SEMIANNUAL,
  [subscriptionPricePeriod.PER_YEAR]: subscriptionPriceKey.ANNUAL,
};

export const subscriptionPricePeriods = [
  {
    priceKey: subscriptionPriceKey.ANNUAL,
    periodKey: subscriptionPricePeriod.PER_YEAR,
  },
  {
    priceKey: subscriptionPriceKey.SEMIANNUAL,
    periodKey: subscriptionPricePeriod.PER_SIX_MONTH,
  },
];

export const buyerSubscriptions = [
  subscriptionNameKeys.SUBSCRIPTION_NAME_07,
  subscriptionNameKeys.SUBSCRIPTION_NAME_04,
  subscriptionNameKeys.SUBSCRIPTION_NAME_05,
];
export const sellerSubscriptions = [
  subscriptionNameKeys.SUBSCRIPTION_NAME_01,
  subscriptionNameKeys.SUBSCRIPTION_NAME_06,
];

export const allSubscriptions = [
  subscriptionNameKeys.SUBSCRIPTION_NAME_02,
  subscriptionNameKeys.SUBSCRIPTION_NAME_03,
];

export const subscriptionsData = {
  [subscriptionNameKeys.SUBSCRIPTION_NAME_01]: {
    descriptionKey: "SUBSCRIPTION_NAME_01_DESCRIPTION",
    color: color.black,
    colorClass: "__black",
    optionsCount: 20,
    subOptionsCount: 100,
    icon: <IconPlane />,
    isFake: false,
    isFree: true,
    isContactUs: false,
    isCampaign: false,
  },
  [subscriptionNameKeys.SUBSCRIPTION_NAME_02]: {
    descriptionKey: "SUBSCRIPTION_NAME_02_DESCRIPTION",
    color: color.black,
    colorClass: "__black",
    optionsCount: 20,
    subOptionsCount: 100,
    icon: <IconGroupWork />,
    isFake: false,
    isFree: false,
    isContactUs: false,
    isCampaign: false,
  },
  [subscriptionNameKeys.SUBSCRIPTION_NAME_03]: {
    descriptionKey: "SUBSCRIPTION_NAME_03_DESCRIPTION",
    color: color.blue,
    colorClass: "__blue",
    optionsCount: 20,
    subOptionsCount: 100,
    icon: <IconRadar />,
    isFake: false,
    isFree: false,
    isContactUs: false,
    isCampaign: false,
  },
  [subscriptionNameKeys.SUBSCRIPTION_NAME_04]: {
    descriptionKey: "SUBSCRIPTION_NAME_04_DESCRIPTION",
    color: color.blue,
    colorClass: "__blue",
    optionsCount: 20,
    subOptionsCount: 100,
    icon: <IconHandOnRight />,
    isFake: false,
    isFree: false,
    isContactUs: false,
    isCampaign: false,
  },
  [subscriptionNameKeys.SUBSCRIPTION_NAME_05]: {
    descriptionKey: "SUBSCRIPTION_NAME_05_DESCRIPTION",
    color: color.blue,
    colorClass: "__blue",
    optionsCount: 20,
    subOptionsCount: 100,
    icon: <IconStar width="20" height="21" />,
    isFake: false,
    isFree: false,
    isContactUs: true,
    isCampaign: false,
  },
  [subscriptionNameKeys.SUBSCRIPTION_NAME_06]: {
    descriptionKey: "SUBSCRIPTION_NAME_06_DESCRIPTION",
    color: color.blue,
    colorClass: "__blue",
    optionsCount: 20,
    subOptionsCount: 100,
    icon: <IconBinoculars height="21" width="20" />,
    isFake: false,
    isFree: false,
    isContactUs: false,
    isCampaign: false,
  },
  [subscriptionNameKeys.SUBSCRIPTION_NAME_07]: {
    descriptionKey: "SUBSCRIPTION_NAME_07_DESCRIPTION",
    color: color.blue,
    colorClass: "__blue",
    optionsCount: 20,
    subOptionsCount: 100,
    icon: <IconBullHorn />,
    isFake: false,
    isFree: true,
    isPricePerUser: false,
    isContactUs: false,
    isCampaign: false,
  },
};

export const ourAgreementsTypes = {
  UE2021: "UE-2021",
  RAMAVTAL: "RAMAVTAL",
};

export const initialAgreements = [
  {
    title: "UE 2021",
    type: ourAgreementsTypes.UE2021,
    checked: false,
    UpdatedUser: null,
    OrganizationAgreementItems: [],
    File: null,
  },
  {
    title: "RAMAVTAL",
    type: ourAgreementsTypes.RAMAVTAL,
    checked: false,
    UpdatedUser: null,
    OrganizationAgreementItems: [],
    File: null,
  },
];

export const pathPermissions = {
  [FRAMEWORK_AGREEMENTS]: "READ_ORGANIZATION_RAMAVTAL",
  [OUR_FAVOURITES]: "READ_OUR_SUPPLIERS",
  [PROJECTS]: "READ_PROJECTS",
  [CONTRACTS]: "READ_BUYER_CONTRACTS",
  [OUR_BLACKLIST]: "READ_OUR_BLACKLIST",
  [INVITE_COMPANIES]: "READ_INVITE_COMPANIES",
  [PUBLIC_RFTS]: "READ_PUBLIC_RFTS",
  [OUR_QUALIFICATIONS]: "READ_QUALIFICATIONS",
  [REQUESTS_TO_US]: "READ_SELLER_RFTS",
  [REQUEST_TO_US]: "READ_SELLER_RFTS",
  [OUR_OFFERS]: "READ_OFFERS",
  [CONTRACTS_TO_US]: "READ_SELLER_CONTRACTS",
  [CONTRACT_TO_US]: "READ_SELLER_CONTRACTS",
};

export const temporaryHiddenElements = {
  // fullscreenModalAD: false,
};

export const oneDay = 86400000;

export const fourDays = 345600000;

export const oneWeek = oneDay * 7;

export const qualificationStatus = {
  WAITING: "WAITING",
  SENT: "SENT",
  CONFIRMED: "CONFIRMED",
  REMOVED: "REMOVED",
};

export const externalApiTypes = {
  KOLLEKTIVAVTALSKOLLEN: "KOLLEKTIVAVTALSKOLLEN",
  BYGGINSTSEKTORN_COLLECTIVE: "BYGGINSTSEKTORN_COLLECTIVE",
  BYGGINSTSEKTORN_SUBSTITUTE: "BYGGINSTSEKTORN_SUBSTITUTE",
};

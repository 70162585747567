import React from "react";
import {useTranslation} from "react-i18next";
import {VideoInstructionLink} from "components/VideoInstructionLink";

export const ProjectStartGuide = ({step}) => {
  const stepClasses = (currentStep, stepNumber) => {
    if (stepNumber === currentStep) return "active";
    if (stepNumber < currentStep) return "completed";
    return "inactive";
  };
  const {t} = useTranslation();

  return (
    <>
      <div className="project-startup-guide">
        <h3>{t("STEPPED_PROJECT_GUIDE_TITLE")}</h3>
        <div className="stepped-project-guide">
          <div className={`step 1 ${stepClasses(step, 1)}`}>
            <span className="title">
              <span className="emoji">✨</span>
              1. {t("STEPPED_PROJECT_GUIDE_STEP_1_TITLE")}
            </span>
            <p className="instructions">{t("STEPPED_PROJECT_GUIDE_STEP_1_BODY")}</p>
          </div>

          <div className={`step 2 ${stepClasses(step, 2)}`}>
            <span className="title">
              <span className="emoji">🧩</span>
              2. {t("STEPPED_PROJECT_GUIDE_STEP_2_TITLE")}
            </span>
            <p className="instructions">{t("STEPPED_PROJECT_GUIDE_STEP_2_BODY")}</p>
            <VideoInstructionLink
              label="VIDEO_LINK_EMPTY_PROJECT_LABEL"
              link="VIDEO_LINK_EMPTY_PROJECT_LINK"
            />
          </div>

          <div className={`step 3 ${stepClasses(step, 3)}`}>
            <span className="title">
              <span className="emoji">🚀</span>
              3. {t("STEPPED_PROJECT_GUIDE_STEP_3_TITLE")}
            </span>
            <p className="instructions">{t("STEPPED_PROJECT_GUIDE_STEP_3_BODY")}</p>
          </div>
        </div>
      </div>
    </>
  );
};

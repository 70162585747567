import React from "react";
import {Modal} from "antd";
import {Trans} from "react-i18next";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {color as constColor} from "constants/color.consts";

const {confirm} = Modal;

export const ConfirmModal = ({
  title,
  text,
  onOk,
  onCancel = () => {},
  type,
  cancelText,
  okText,
  noCancelBefore,
  noCancelButton,
  noQuestionSymbol,
  addClass,
}) => {
  !noCancelBefore && onCancel && onCancel(true);
  const color = {
    info: constColor.link,
    warning: constColor.orange,
  };

  confirm({
    title: `${title}${noQuestionSymbol ? "" : "?"}`,
    okText: okText && <Trans>{okText}</Trans>,
    cancelText: <Trans>{cancelText ?? "CANCEL"}</Trans>,
    icon: <ExclamationCircleOutlined style={{color: color[type]}} />,
    content: text,
    keyboard: !noCancelButton,
    cancelButtonProps: noCancelButton && {style: {display: "none"}},
    onOk: () => onOk(),
    onCancel: () => onCancel(false),
    wrapClassName: addClass,
  });
};

import React from "react";
import EditSimpleInput from "../../EditComponents/EditSimpleInput";
import EditDatePicker from "components/EditDatePicker";
import ButtonDelete from "components/buttons/ButtonDelete";
// import EditRadioSwitch from "../../EditComponents/EditRadioSwitch";
import EditUploadImage from "../../EditComponents/EditUploadImage";
import {useTranslation} from "react-i18next";
import EditSelect from "components/selects/EditSelect";
import {insurancesList} from "constants/select.consts";
import {inject, observer} from "mobx-react";

const InsuranceForm = inject("store")(
  observer(({store: {organization}, index, remove, insurance, setFieldValue}) => {
    const {t} = useTranslation();
    const fileFieldName = `insurances.${index}.Files`;
    const uploadItem = insurance?.Files?.length ? insurance.Files[0] : null;

    return (
      <div className="editBlock_item df">
        <div className="editBlock_flex__wrap">
          <div className="editBlock_flex__wrap">
            <EditSimpleInput
              label={t("NAME_OF_INSURANCE")}
              name={`insurances.${index}.insuranceName`}
            />
            <EditSelect
              name={`insurances.${index}.insuranceCompany`}
              label={t("INSURANCE_COMPANY")}
              list={insurancesList}
              sorted
              bottomElement="INSURANCES_10"
            />
            <EditDatePicker
              label={t("VALID_UNTIL")}
              name={`insurances.${index}.valid`}
              disabledDate={(date) => date < new Date(new Date().setHours(0, 0, 0, 0))}
            />
            <EditUploadImage
              uploadFile={organization.insuranceUploadFile}
              fieldName={fileFieldName}
              item={uploadItem}
              setFieldValue={setFieldValue}
            />
          </div>
          {/**
                  <div className="editBlock_flex__wrap">
            <div className="editBlock_radio__wrapper">
              <span className="editBlock_radio__title">{t("MEETS")}</span>
              <div className="editBlock_radio__switch">
                {insurance &&
                  insurance.meets.length &&
                  insurance.meets.map((item, i) => (
                    <EditRadioSwitch
                      label={Object.keys(item)[0]}
                      name={`insurances[${index}]meets[${i}].${Object.keys(item)[0]}`}
                    />
                  ))}
              </div>
            </div>
          </div>
        */}
        </div>
        <ButtonDelete addClass="editBlock_btn__clean" onClick={() => remove(index)} />
      </div>
    );
  })
);

export default InsuranceForm;

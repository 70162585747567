import React from "react";
import {useNavigate} from "react-router-dom";
import {Dropdown} from "antd";
import {useTranslation} from "react-i18next";
// local
import Select from "../../selects/Select";
import {CustomBadge} from "components/CustomBadge";
import {TimeLeft} from "components/TimeLeft";
import {Language} from "components/Language";
import {Tag} from "components/Tag";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {VideoInstructionLink} from "components/VideoInstructionLink";
import {color} from "constants/color.consts";
import {
  IconEqualizer,
  IconBubblesMessage,
  IconMailLetter,
  IconChevronRight,
  IconRocketUp,
} from "components/icons";

const Suppliers = ({
  myOrganizations,
  messages,
  subscriptionName,
  onClick,
  sendSetActiveOrganization,
  activeOrganizationId,
}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const changeOrganization = (value) =>
    sendSetActiveOrganization({
      organizationId: value,
    });

  const readMessage = (message) =>
    navigate(message.link, {
      state: {messageProposalId: message.messageProposalId},
    });

  const items = messages.map((row) => ({
    key: `${row.id}-${row.organizationName}-${row.rfpTitle}`,
    label: (
      <div onClick={() => readMessage(row)} className="dropdown-messages__row">
        <TimeLeft date={row.updatedAt} addClass="dropdown-messages__row-time" />
        <div className="dropdown-messages__row-info">
          <IconMailLetter />
          <span className="dropdown-messages__row-info__organization">
            {row.organizationName}
          </span>
          <IconChevronRight width="10" height="10" />
          <span className="dropdown-messages__row-info__title">{row.rfpTitle}</span>
        </div>
      </div>
    ),
  }));

  return (
    <div className="header_profile__wrapper">
      <VideoInstructionLink
        label="VIDEO_LINK_HEADER_LABEL"
        link="VIDEO_LINK_HEADER_LINK"
        addClass="mr-4"
      />
      <div className="header_profile__divider mr-2"></div>
      {messages.length !== 0 && (
        <React.Fragment>
          <Dropdown
            menu={{items}}
            trigger={["click"]}
            placement="bottomRight"
            disabled={!messages?.length}
            overlayClassName="dropdown-messages__list"
          >
            <ButtonTransparent
              icon={<IconBubblesMessage addClass="mr-0 ml-2" />}
              noBorder
              addClass="pa-0 mr-1"
              disabledTransparent
            />
          </Dropdown>
          <CustomBadge count={messages?.length} addClass="badge-messages-count mr-4" />
          <div className="header_profile__divider mr-2"></div>
        </React.Fragment>
      )}
      {/* End chunk */}
      {subscriptionName && (
        <React.Fragment>
          <Tag
            text={t(subscriptionName)}
            color={color.discreteText}
            bold
            uppercase
            noBackground
            addClass="subscription-tag"
          />
          <ButtonTransparent
            name="UPGRADE_BUTTON"
            icon={<IconRocketUp />}
            addClass="mr-2 upgrade-button"
            color="purple"
            noBorder
            hoverable
            onClick={() => navigate({hash: "#your-subscription"})}
          />
        </React.Fragment>
      )}
      {myOrganizations?.length && (
        <Select
          addClass="header_profile__select"
          list={myOrganizations
            .filter((item) => item.id !== activeOrganizationId)
            .map(({id, name}) => ({value: id, label: name}))}
          placeholder={
            myOrganizations.find(
              (organization) => organization.id === activeOrganizationId
            )?.name
          }
          onChange={(value) => changeOrganization(value)}
          showArrow={false}
          disabled={myOrganizations?.length <= 1}
        />
      )}
      <div className="header_profile__divider ml-2 mr-4"></div>
      <Language position="bottomRight" />
      <button onClick={onClick} className="header_profile__image">
        <IconEqualizer />
      </button>
    </div>
  );
};

export default Suppliers;

import React from "react";

export const IconLinkNyab = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2" fill="#F0F0F0" />

      <path
        d="M15.8431 6.3832C15.7063 6.25467 15.4889 6.3988 15.5535 6.57519C15.8355 7.34159 15.9873 8.17089 15.9808 9.03677C15.9529 12.8822 12.776 16.0031 8.92745 15.9692C7.63995 15.9579 6.43584 15.5986 5.40453 14.9813C5.24306 14.8844 5.06112 15.0722 5.16232 15.2308C6.39978 17.1648 8.56466 18.4491 11.0304 18.4577C14.8613 18.4711 18.0123 15.3631 18.0456 11.5355C18.0634 9.50415 17.2129 7.67073 15.8431 6.38374V6.3832Z"
        fill="#075E6F"
      />
      <path
        d="M4.06525 11.4203C4.09324 7.57499 7.27008 4.45403 11.1186 4.48792C12.4062 4.49921 13.6102 4.85847 14.6416 5.47588C14.8031 5.57269 14.985 5.38499 14.8838 5.22634C13.6458 3.29289 11.4814 2.00859 9.01563 1.99998C5.18484 1.98654 2.03385 5.09457 1.99994 8.92219C1.98217 10.9535 2.83263 12.7869 4.20251 14.0739C4.33923 14.2025 4.55668 14.0583 4.49209 13.8819C4.21004 13.1156 4.05825 12.2863 4.06471 11.4203H4.06525Z"
        fill="#075E6F"
      />
    </svg>
  );
};

import React from "react";
// local
import {dateFormat} from "helpers/datetime";
import {IconHammerWrench} from "components/icons";

export const InviteSuppliersTableRow = ({data}) => {
  return (
    <div className="invite-suppliers_table-row_wrapper">
      <div className="invite-suppliers_table-row_email">{data.email}</div>
      <div className="invite-suppliers_table-row_company">
        {data.organizationName ? (
          <a className="link" href={data.organizationLink}>
            {data.organizationName}
          </a>
        ) : (
          data.companyName
        )}
      </div>
      <div className="invite-suppliers_table-row_sent">{`${dateFormat(data.sent)}`}</div>
      <div className="invite-suppliers_table-row_signup">
        {`${dateFormat(data.signedup)}`}
      </div>
      <div className="invite-suppliers_table-row_empty">
        {data.requestName ? (
          <React.Fragment>
            <span>Via</span>
            <IconHammerWrench />
            <a className="link" href={data.requestLink}>
              {data.requestName}
            </a>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span>{data.message}</span>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

import React, {memo} from "react";

export const IconBankIdLogo = memo(({color}) => {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00703 10.9639L7.93475 5.11649C7.56928 5.11649 6.94378 5.11649 6.94378 5.11649C6.47993 5.11649 5.88254 4.85645 5.70683 4.37853C5.65061 4.21689 5.51708 3.66167 6.28314 3.1205C6.55724 2.93074 6.73294 2.7199 6.76808 2.55825C6.80322 2.38958 6.76105 2.24199 6.64157 2.12954C6.4729 1.96789 6.14258 1.87653 5.72089 1.87653C5.01105 1.87653 4.51206 2.28416 4.46286 2.57934C4.42772 2.79721 4.59639 2.97291 4.74398 3.08536C5.18675 3.41568 5.29218 3.89359 5.01808 4.34339C4.73696 4.80725 4.12551 5.10946 3.4719 5.11649C3.4719 5.11649 2.82531 5.11649 2.45985 5.11649C2.37551 5.68576 0.998 14.4147 0.892578 15.1034H6.38153C6.43073 14.7942 6.68374 13.1426 7.02812 10.9639H7.00703Z"
        fill={color || "#479CBE"}
      />
      <path
        d="M14.3585 0H8.7711L8.02612 4.72992H8.97492C9.495 4.72992 9.98697 4.49096 10.1978 4.14659C10.2681 4.03414 10.2962 3.93574 10.2962 3.84438C10.2962 3.64759 10.1627 3.5 10.0291 3.40161C9.66367 3.12751 9.58636 2.83936 9.58636 2.63554C9.58636 2.59337 9.58637 2.55823 9.59339 2.52309C9.6707 2.0241 10.3454 1.48293 11.238 1.48293C11.7721 1.48293 12.1797 1.60944 12.4257 1.84136C12.6436 2.04518 12.7279 2.33333 12.6647 2.63554C12.5874 2.99398 12.2289 3.28916 12.0251 3.43675C11.484 3.81626 11.5542 4.14658 11.5894 4.24498C11.7018 4.54016 12.1305 4.72992 12.4609 4.72992H13.9016C13.9016 4.72992 13.9016 4.72992 13.9016 4.73695C15.8695 4.751 16.9237 5.65763 16.5934 7.76606C16.2842 9.72691 14.7801 10.5703 12.988 10.5843L12.2781 15.1104H13.3253C17.746 15.1104 21.3585 12.2711 22.0683 7.78715C22.9468 2.22088 19.4187 0 14.3585 0Z"
        fill={color || "#00A5C3"}
      />
      <path
        d="M14.3585 0H8.7711L8.02612 4.72992H8.97492C9.495 4.72992 9.98697 4.49096 10.1978 4.14659C10.2681 4.03414 10.2962 3.93574 10.2962 3.84438C10.2962 3.64759 10.1627 3.5 10.0291 3.40161C9.66367 3.12751 9.58636 2.83936 9.58636 2.63554C9.58636 2.59337 9.58637 2.55823 9.59339 2.52309C9.6707 2.0241 10.3454 1.48293 11.238 1.48293C11.7721 1.48293 12.1797 1.60944 12.4257 1.84136C12.6436 2.04518 12.7279 2.33333 12.6647 2.63554C12.5874 2.99398 12.2289 3.28916 12.0251 3.43675C11.484 3.81626 11.5542 4.14658 11.5894 4.24498C11.7018 4.54016 12.1305 4.72992 12.4609 4.72992H13.9016C13.9016 4.72992 13.9016 4.72992 13.9016 4.73695C15.8695 4.751 16.9237 5.65763 16.5934 7.76606C16.2842 9.72691 14.7801 10.5703 12.988 10.5843L12.2781 15.1104H13.3253C17.746 15.1104 21.3585 12.2711 22.0683 7.78715C22.9468 2.22088 19.4187 0 14.3585 0Z"
        fill={color || "#235971"}
      />
      <path
        d="M0.597389 17.1205H2.83936C3.79518 17.1205 4.02711 17.6054 3.95683 18.0482C3.9006 18.4066 3.65462 18.6737 3.23293 18.8494C3.76707 19.0532 3.97791 19.3695 3.9006 19.8685C3.80221 20.494 3.26104 20.9578 2.5512 20.9578H0L0.597389 17.1205ZM2.08032 18.7088C2.51606 18.7088 2.71988 18.4769 2.76205 18.2028C2.80422 17.9076 2.67068 17.7038 2.23494 17.7038H1.84839L1.69378 18.7088H2.08032ZM1.84136 20.3675C2.29116 20.3675 2.5512 20.1847 2.61446 19.8122C2.66365 19.489 2.48092 19.2992 2.04518 19.2992H1.60241L1.43374 20.3745H1.84136V20.3675Z"
        fill={color || "#235971"}
      />
      <path
        d="M7.04213 20.9859C6.4588 21.0281 6.17767 20.9648 6.03711 20.7118C5.72787 20.9016 5.3835 21 5.01804 21C4.35739 21 4.12547 20.6556 4.18872 20.2761C4.21683 20.0934 4.32225 19.9177 4.49093 19.7701C4.85639 19.4538 5.75599 19.4116 6.10739 19.1727C6.13551 18.9056 6.03008 18.8072 5.69976 18.8072C5.31322 18.8072 4.98992 18.9337 4.4347 19.3132L4.56824 18.4418C5.04615 18.0974 5.51 17.9357 6.04414 17.9357C6.72587 17.9357 7.33028 18.2169 7.21783 18.9618L7.0843 19.8052C7.0351 20.1004 7.04916 20.1918 7.37948 20.1988L7.04213 20.9859ZM6.03008 19.6646C5.72085 19.8614 5.14454 19.8263 5.08129 20.2339C5.05318 20.4237 5.17265 20.5642 5.36241 20.5642C5.54514 20.5642 5.77004 20.4869 5.95277 20.3604C5.93872 20.2901 5.94575 20.2199 5.96683 20.0863L6.03008 19.6646Z"
        fill={color || "#235971"}
      />
      <path
        d="M8.13163 17.9849H9.2983L9.23505 18.3715C9.60754 18.0552 9.88866 17.9357 10.2541 17.9357C10.9077 17.9357 11.2099 18.3363 11.1045 18.9899L10.8023 20.9508H9.63565L9.88866 19.3273C9.93786 19.0321 9.84649 18.8916 9.62159 18.8916C9.43886 18.8916 9.27019 18.9899 9.10854 19.2078L8.84147 20.9438H7.6748L8.13163 17.9849Z"
        fill={color || "#235971"}
      />
      <path
        d="M12.011 17.1205H13.1776L12.8824 19.004L13.9999 17.9849H15.4407L14.0069 19.25L15.1596 20.9508H13.6907L12.8051 19.5803H12.7911L12.5802 20.9508H11.4136L12.011 17.1205Z"
        fill={color || "#235971"}
      />
      <path
        d="M16.1436 17.1205H17.486L16.8956 20.9508H15.5532L16.1436 17.1205Z"
        fill={color || "#479CBE"}
      />
      <path
        d="M18.1396 17.1205H20.0583C21.5412 17.1205 21.9699 18.1958 21.8294 19.0884C21.6958 19.9598 21.0071 20.9508 19.7069 20.9508H17.5422L18.1396 17.1205ZM19.3836 20.0371C20.0372 20.0371 20.3956 19.7139 20.5011 19.0321C20.5784 18.5261 20.4238 18.0271 19.6999 18.0271H19.3414L19.0322 20.0371H19.3836Z"
        fill={color || "#479CBE"}
      />
    </svg>
  );
});

import React from "react";
import {useNavigate, useLocation} from "react-router-dom";
//local
import {toRequestId, toContractId, toContractToUsId} from "constants/routes.const";
import {dateTimeFormat} from "helpers/datetime";
import {ProjectLabel} from "components/ProjectLabel";
import {IconHourglass, IconLock} from "components/icons";
import {color} from "constants/color.consts";

const ContractTableItem = ({contract, isFinished, showContractNumber}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isBuyer = location.pathname === "/contracts";
  const proposal = contract.Offer?.Proposal;
  const project = proposal?.Request?.Project;
  const OrganizationName = isBuyer
    ? proposal.Organization.name
    : proposal.Request.Buyer.name;
  const contractId = isBuyer ? proposal.Request.id : proposal.id;

  const pathToBuyerContract = (id) => (isFinished ? toContractId(id) : toRequestId(id));
  const pathToSellerContract = (id) => toContractToUsId(id);

  const handleRedirectOnClick = () =>
    navigate(
      isBuyer ? pathToBuyerContract(contractId) : pathToSellerContract(contractId),
      {
        state: {
          from: "contracts",
          contractId: isBuyer ? contract.OfferId : contract.id,
        },
      }
    );

  return (
    <div
      className={`requests-card_body ${contract.isSigned ? "signed" : "not-signed"}`}
      onClick={handleRedirectOnClick}
    >
      <div className="requests-card_body__icon __wrapper icon-col">
        {contract.isSigned ? <IconLock /> : <IconHourglass color={color.black} />}
      </div>
      {showContractNumber && (
        <div className="requests-card_body__number __wrapper number-col">
          {contract.number}
        </div>
      )}
      <div className="requests-card_body__name __wrapper df-row-center name-col">
        <span class="truncate">{proposal?.Request?.name || "--"}</span>
      </div>
      <div className="requests-card_body__company __wrapper company-col fz-16">
        <span class="truncate">{OrganizationName}</span>
      </div>
      <div className="requests-card_body__created __wrapper date-col">
        {dateTimeFormat(contract.updatedAt)}
      </div>
      <div className="requests-card_body__project __wrapper df project-col">
        {project && <ProjectLabel name={project.name} code={project.reference} />}
      </div>
    </div>
  );
};

export default ContractTableItem;

import React from "react";
import {useTranslation} from "react-i18next";
// local
import {Modal} from "components/Modal";
import {AddableFormikField} from "components/AddableFormikField";
import {IconUsers, IconMailLetter} from "components/icons/index";
import {InitialsBadge} from "components/InitialsBadge";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import SelectionRow from "components/selects/SelectionRow";
import {color} from "constants/color.consts";

export const ModalRecipientMembers = ({
  organization,
  onClose,
  currentUser,
  onAddEmailRecipients,
  onChoiceNotificationMember,
  emailLoading,
}) => {
  const {t} = useTranslation();
  const isLastMember = organization.MembersToReceive?.length < 2;

  const MembersList = ({members}) => (
    <div className="recipients-tab__members-modal__content__list">
      {members.map((item) => (
        <div key={item.id} className="df-row-start  mt-2">
          <div key={item.id} className="df-row-center fs0">
            <CustomRadioSwitch
              checked={organization.MembersToReceive?.find(
                (member) => member.id === item.id
              )}
              small
              isActive={(checked) =>
                !(isLastMember && !checked) &&
                onChoiceNotificationMember(item.id, checked)
              }
            />
            <InitialsBadge
              firstName={item.firstName}
              lastName={item.lastName}
              isCurrentUser={item.id === currentUser?.id}
              addClass="mr-2 ml-2"
            />
            {item.firstName} {item.lastName}
          </div>
          {!!item.OrganizationMember.availableIn?.length && (
            <SelectionRow
              values={item.OrganizationMember?.availableIn.map((item) => ({
                value: item,
                label: item,
                color: "green",
              }))}
              transparent
              bold
              tiny
              nonStretch
              addClassItem="mb-1"
              addClass="ml-2"
            />
          )}
        </div>
      ))}
    </div>
  );

  return (
    <Modal
      titleIcon={<IconUsers addClass="mr-2" />}
      titleText={`${t("RECIPIENT_MEMBERS_MODAL_TITLE")} ${organization.name}?`}
      width="max"
      rounded
      placeBitLow
      style={{marginTop: "5vh"}}
      onClose={onClose}
    >
      <div className="recipients-tab__members-modal__content">
        <div className="mb-4">{t("RECIPIENT_MEMBERS_MODAL_SUBTITLE_01")}</div>
        <MembersList members={organization.members} />
        <div className="mb-2 mt-8 __bold">{`${t(
          "RECIPIENT_MEMBERS_MODAL_EMAIL_TITLE_01"
        )} ${organization.name} ${t("RECIPIENT_MEMBERS_MODAL_EMAIL_TITLE_02")}?`}</div>
        <div style={{width: "320px"}}>
          {t("RECIPIENT_MEMBERS_MODAL_EMAIL_DESCRIPTION")}
        </div>
        <div className="df mt-4">
          <IconMailLetter height="30" width="30" color={color.purple} addClass="mr-4" />
          <AddableFormikField
            type="email"
            allowEmpty
            small
            initialValue=""
            loading={emailLoading}
            placeholder="INVITE_SOMEONE_BY_EMAIL_02"
            addPermissions={["ADD_RECIPIENTS_IN_RFT"]}
            userPermissions={currentUser.permissions}
            add={(value) => {
              onAddEmailRecipients(value);
              onClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

import * as yup from "yup";
import {emailRequired} from "yup/defaultValudations";

export const schema = yup.object().shape({
  email: emailRequired(),
});

export const initialValues = {
  email: "",
  reference: "",
  isRepresent: false,
  isTerms: false,
};

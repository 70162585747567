import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
//local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {getFullName} from "helpers/helper";
import {JOINING_ORG} from "constants/routes.const";
import {showSuccess} from "helpers/notifications.helper";
import {roles} from "constants/auth.const";
import {useTranslation} from "react-i18next";

export const ModalRequest = inject("store")(
  observer(({store: {auth}}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const organization = auth.organizations.length && auth.organizations[0];
    const users = organization.Users.filter(
      (user) =>
        user.OrganizationMember.role === roles.OWNER ||
        user.OrganizationMember.role === roles.ADMIN
    ).map((user) => getFullName(user.firstName, user.lastName));
    const lastUser = users.pop();
    const usersRow = `${users?.join(", ") ?? ""}${
      users.length ? t("AND") : ""
    } ${lastUser}`;

    const sendMessageToEmail = (noMessage) =>
      auth.sendJoinEmail(organization?.id, noMessage ? null : message).then(() => {
        showSuccess();
        noMessage ? auth.updateAuthModalName() : navigate(JOINING_ORG);
      });

    return (
      <div className="modal-request">
        <h2>{`${t("MODAL_REQUEST_TITLE")} ${organization.name}`}</h2>
        <div className="modal-request__text">
          <span>{t("MODAL_REQUEST_TEXT_1")}</span>
          <b>{usersRow}</b>
          <span>{` ${t("MODAL_REQUEST_TEXT_2")} `}</span>
          <b>{organization.name}</b>
          <span>{t("MODAL_REQUEST_TEXT_3")}</span>
        </div>
        <div className="modal-request__textarea">
          <div>{t("MODAL_REQUEST_TEXTAREA_DESCRIPTION")}</div>
          <TextareaAutosize
            maxRows="4"
            minRows="2"
            className="textarea"
            name="messageText"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={t("MODAL_REQUEST_TEXTAREA_PLACEHOLDER")}
          />
        </div>

        {organization && (
          <ButtonTransparent
            large
            addClass="modal-request__button mb-4"
            nonTransparent
            onClick={() => sendMessageToEmail()}
            name={t("REQUEST_TO_JOIN")}
          />
        )}
        <ButtonTransparent
          large
          addClass="modal-request__button mb-16"
          color="red"
          onClick={() => sendMessageToEmail(true)}
          name={t("CANCEL")}
        />
      </div>
    );
  })
);

import React from "react";
import cn from "classnames";
import {Checkbox} from "antd";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {color as constColor} from "constants/color.consts";

const CustomCheckbox = ({
  initialButton,
  initialButtonIcon,
  initialButtonTooltip,
  onClickInitialButton,
  color,
  onChange,
  addClass,
  ...props
}) => {
  return initialButton ? (
    <ButtonTransparent
      addClass={cn("checkbox-initial-button", {[addClass]: !!addClass})}
      icon={initialButtonIcon}
      tooltip={initialButtonTooltip}
      onClick={onClickInitialButton}
      style={{borderColor: color ?? constColor.black}}
    />
  ) : (
    <Checkbox
      {...props}
      className={cn("checkbox", {[addClass]: !!addClass})}
      onChange={(e) => onChange(e.target.checked)}
    />
  );
};

export default CustomCheckbox;

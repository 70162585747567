import React from "react";
import ServicesOffered from "../components/ServicesComponents/ServicesOffered/ServicesOffered";

const ServicesSection = ({edit, setEdit}) => {
  return (
    <div className="services-section_wrapper">
      <ServicesOffered edit={edit} setEdit={setEdit} />
    </div>
  );
};

export default ServicesSection;

import React from "react";

export const IconLinkTreano = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.961792" width="20" height="20" rx="2" fill="#EFEFEF" />
      <path
        d="M11.5017 10.4239L7.78946 16.2019H9.90435L12.5636 12.0747L11.5017 10.4239Z"
        fill="black"
      />
      <path
        d="M11.5463 7.84719L13.4292 10.725L14.4286 9.16342L10.9663 3.79811L2.96179 16.2019H6.18321L11.5463 7.84719Z"
        fill="black"
      />
      <path d="M13.3667 13.3129L11.5017 16.2019H15.2228L13.3667 13.3129Z" fill="black" />
      <path
        d="M16.8647 16.2019H18.9618L15.2317 10.4127L14.2144 11.9967L16.8647 16.2019Z"
        fill="black"
      />
    </svg>
  );
};

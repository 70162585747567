import React, {memo} from "react";

export const IconArchive = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 16H8.5C8.224 16 8 15.776 8 15.5C8 15.224 8.224 15 8.5 15H11.5C11.776 15 12 15.224 12 15.5C12 15.776 11.776 16 11.5 16Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M19.557 11.383L16.859 5.21502C16.561 4.53302 15.744 3.99902 15 3.99902H5C4.256 3.99902 3.439 4.53302 3.141 5.21502L0.443 11.383C0.195 11.951 0 12.88 0 13.5V17.5C0 18.327 0.673 19 1.5 19H18.5C19.327 19 20 18.327 20 17.5V13.5C20 12.881 19.806 11.951 19.557 11.383ZM4.057 5.61702C4.198 5.29402 4.648 5.00002 5 5.00002H15C15.352 5.00002 15.802 5.29402 15.943 5.61702L18.641 11.785C18.671 11.854 18.701 11.933 18.73 12.018C18.655 12.006 18.578 12 18.5 12H1.5C1.422 12 1.345 12.006 1.27 12.018C1.299 11.933 1.328 11.854 1.359 11.785L4.057 5.61702ZM19 17.5C19 17.776 18.776 18 18.5 18H1.5C1.224 18 1 17.776 1 17.5V13.5C1 13.224 1.224 13 1.5 13H18.5C18.776 13 19 13.224 19 13.5V17.5Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M16.5 11H3.5C3.224 11 3 10.776 3 10.5C3 10.224 3.224 10 3.5 10H16.5C16.776 10 17 10.224 17 10.5C17 10.776 16.776 11 16.5 11Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M15.5 9H4.5C4.224 9 4 8.776 4 8.5C4 8.224 4.224 8 4.5 8H15.5C15.776 8 16 8.224 16 8.5C16 8.776 15.776 9 15.5 9Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M14.5 7H5.5C5.224 7 5 6.776 5 6.5C5 6.224 5.224 6 5.5 6H14.5C14.776 6 15 6.224 15 6.5C15 6.776 14.776 7 14.5 7Z"
        fill={color || "#1BA2CC"}
      />
    </svg>
  );
});

import React, {useState} from "react";
import {inject, observer} from "mobx-react";
//locale
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconCheck, IconCross, IconHand} from "components/icons";
import {NotInterestedMessageModal} from "components/modals/NotInterestedMessageModal";
import {InfoTooltip} from "components/InfoTooltip";
import {color} from "constants/color.consts";

const SingleRequestInterestStatus = inject("store")(
  observer(({store: {seller}}) => {
    const {request} = seller;
    const [loadingInterested, setLoadingInterested] = useState(false);
    const [loadingNotInterested, setLoadingNotInterested] = useState(false);
    const [isNotInterestedMessageModal, setIsNotInterestedMessageModal] = useState(false);

    // statuses
    const pressedStatusButtons = typeof request.interested === "boolean";
    const hasOffer = !!request.Offer?.id;
    const isInterested = !!request.interested;
    const isNotInterested = pressedStatusButtons && !request.interested;
    const isPublicForNotInterested =
      request.isPublic === false || request.Request?.isPublic === false;

    // status methods
    const onSubmitted = () => seller.setActiveRequestTab(1);
    const onInterested = () => {
      if (!isInterested) {
        setLoadingInterested(true);
        request.id
          ? seller
              .updateRequest({interested: true})
              .finally(() => setLoadingInterested(false))
          : seller
              .setInterestPublicRfp(request.Request.id, {interested: true})
              .finally(() => setLoadingInterested(false));
      }
    };
    const onNotInterested = () => {
      if (!isNotInterested) {
        setLoadingNotInterested(true);
        setIsNotInterestedMessageModal(true);
      }
    };

    const onConfirmNotInterested = (rejectMessage) =>
      !hasOffer && request.isPublic
        ? seller
            .setNotInterestPublicRfp(request.Request?.id)
            .finally(() => setLoadingNotInterested(false))
        : seller
            .updateRequest({interested: false, rejectMessage})
            .finally(() => setLoadingNotInterested(false));

    return (
      <div className="hd-content-block_header __interest-status">
        {!hasOffer && <InfoTooltip tooltip="REQUEST_TO_US_STATUSES_TOOLTIP" centerText />}
        {!hasOffer && (
          <>
            {(pressedStatusButtons || isPublicForNotInterested) && (
              <ButtonTransparent
                color="red"
                name="NOT_INTERESTED"
                nonTransparent={isNotInterested}
                icon={<IconCross color={isNotInterested ? color.white : color.red} />}
                loading={loadingNotInterested}
                onClick={isNotInterested ? undefined : onNotInterested}
              />
            )}
            <ButtonTransparent
              name="INTERESTED"
              color="green"
              nonTransparent={isInterested}
              icon={<IconHand color={isInterested ? color.white : color.green} />}
              loading={loadingInterested}
              onClick={isInterested ? undefined : onInterested}
            />
          </>
        )}

        {hasOffer && (
          <ButtonTransparent
            nonTransparent
            small
            name="OFFER_SUBMITTED"
            color="green"
            icon={<IconCheck color={color.white} />}
            onClick={onSubmitted}
          />
        )}
        {isNotInterestedMessageModal && (
          <NotInterestedMessageModal
            title="NOT_INTERESTED_MESSAGE_SELLER_MODAL_TITLE"
            rejectMessage={request.rejectMessage}
            onConfirm={onConfirmNotInterested}
            onClose={() => {
              setIsNotInterestedMessageModal(false);
              setLoadingNotInterested(false);
            }}
          />
        )}
      </div>
    );
  })
);

export default SingleRequestInterestStatus;

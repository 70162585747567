import React from "react";
import {useTranslation} from "react-i18next";
// local
import {ContractContactInfo} from "./ContractContactInfo";
import {ContractSignatures} from "components/contractProcess/ContractSignatures";
import cn from "classnames";

export const SignedContractSignatories = ({
  contractOwnPersons,
  contractOtherPersons,
  ownOrganizationName,
  otherOrganizationName,
  isSellerSide,
  contractId,
}) => {
  const {t} = useTranslation();

  const ownSign = isSellerSide ? "SellerSign" : "BuyerSign";
  const otherSign = isSellerSide ? "BuyerSign" : "SellerSign";

  return (
    <div className="contract-tab_signatures pt-8">
      <div className="contract-tab_signatures__title __uppercase">{t("SIGNATURES")}</div>
      <div
        className={cn("contract-tab_signatures__sides pt-8", {
          __reverse: isSellerSide,
        })}
      >
        <div className="contract-tab_signatures__own-side">
          <div className="contract-tab_signatures__title mb-8">
            {t("SIGN_FOR")} {ownOrganizationName}
          </div>
          {contractOwnPersons.map((person) => (
            <ContractContactInfo key={person.id} person={person[ownSign]} />
          ))}
        </div>
        <div className="contract-tab_signatures__own-side">
          <div className="contract-tab_signatures__title mb-8">
            {t("SIGN_FOR")} {otherOrganizationName}
          </div>
          {contractOtherPersons.map((person) => (
            <ContractContactInfo key={person.id} person={person[otherSign]} />
          ))}
        </div>
      </div>
      <ContractSignatures
        ownPersons={contractOwnPersons?.map((person) => person && person[ownSign])}
        otherPersons={contractOtherPersons?.map((person) => person && person[otherSign])}
        contractId={contractId}
        isSellerSide={isSellerSide}
      />
    </div>
  );
};

import React, {memo} from "react";

export const IconSearch = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={height || "40"}
      height={width || "39"}
      viewBox="0 0 39 40"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.737 38.324L25.851 25.356C28.529 22.554 30.001 18.89 30.001 15C30.001 10.994 28.441 7.226 25.607 4.394C22.773 1.562 19.007 0 15.001 0C10.995 0 7.22698 1.56 4.39498 4.394C1.56298 7.228 0.000976562 10.994 0.000976562 15C0.000976562 19.006 1.56098 22.774 4.39498 25.606C7.22898 28.438 10.995 30 15.001 30C18.453 30 21.725 28.842 24.377 26.71L36.263 39.676C36.461 39.892 36.729 40 37.001 40C37.243 40 37.485 39.914 37.677 39.738C38.085 39.364 38.111 38.732 37.739 38.326L37.737 38.324ZM1.99898 15C1.99898 7.832 7.83098 2 14.999 2C22.167 2 27.999 7.832 27.999 15C27.999 22.168 22.167 28 14.999 28C7.83098 28 1.99898 22.168 1.99898 15Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconLockFilled = memo(({width, height, color, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 7.5H14.5V6C14.5 3.519 12.481 1.5 10 1.5C7.519 1.5 5.5 3.519 5.5 6V7.5H5C4.173 7.5 3.5 8.173 3.5 9V17C3.5 17.827 4.173 18.5 5 18.5H15C15.827 18.5 16.5 17.827 16.5 17V9C16.5 8.173 15.827 7.5 15 7.5ZM6.5 6C6.5 4.07 8.07 2.5 10 2.5C11.93 2.5 13.5 4.07 13.5 6V7.5H6.5V6Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconCurrency = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "21"}
      viewBox="0 0 20 21"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 17.6281H1C0.724 17.6281 0.5 17.4041 0.5 17.1281V7.12805C0.5 6.85205 0.724 6.62805 1 6.62805H19C19.276 6.62805 19.5 6.85205 19.5 7.12805V17.1281C19.5 17.4041 19.276 17.6281 19 17.6281ZM1.5 16.6281H18.5V7.62805H1.5V16.6281Z"
        fill={color || "#000"}
      />
      <path
        d="M18 5.62805H2C1.724 5.62805 1.5 5.40405 1.5 5.12805C1.5 4.85205 1.724 4.62805 2 4.62805H18C18.276 4.62805 18.5 4.85205 18.5 5.12805C18.5 5.40405 18.276 5.62805 18 5.62805Z"
        fill={color || "#000"}
      />
      <path
        d="M17 3.62805H3C2.724 3.62805 2.5 3.40405 2.5 3.12805C2.5 2.85205 2.724 2.62805 3 2.62805H17C17.276 2.62805 17.5 2.85205 17.5 3.12805C17.5 3.40405 17.276 3.62805 17 3.62805Z"
        fill={color || "#000"}
      />
      <circle cx="10" cy="12.1281" r="2" stroke={color || "#000"} />
    </svg>
  );
});

export default IconCurrency;

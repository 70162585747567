import React from "react";
import {useTranslation} from "react-i18next";
// local
import QuickFact from "./QuickFact";

const QuickFacts = ({company}) => {
  const {t} = useTranslation();

  return (
    <div className="presentation-page__left-side__cards">
      <h5 className="__uppercase">{t("QUICK_FACTS")}</h5>
      <div className="presentation-page__quick-facts">
        <QuickFact
          title={t("F_TAX_SLIP")}
          isFact={company?.swedish_f_tax}
          canPermissions={["READ_OTHER_QUICK_FACTS"]}
        />
        <QuickFact
          title={t("VAT_REGISTERED")}
          isFact={company?.swedish_vat_registration}
          canPermissions={["READ_OTHER_QUICK_FACTS"]}
        />
        <QuickFact
          title={t("REGISTERED_EMPLOYER")}
          isFact={company?.employersRegistration}
          canPermissions={["READ_OTHER_QUICK_FACTS"]}
        />
      </div>
    </div>
  );
};

export default QuickFacts;

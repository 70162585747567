import React, {memo} from "react";
import cn from "classnames";

const ButtonLink = memo(({onClick, addClass, text}) => {
  return (
    <button
      className={cn("btn-custom__link", {[addClass]: !!addClass})}
      onClick={onClick}
      type="button"
    >
      {text}
    </button>
  );
});

export default ButtonLink;

import React from "react";
import {useTranslation} from "react-i18next";
//local
import {IconNotificationCircle} from "components/icons";
import {color} from "constants/color.consts";

const FrameworkAgreementsNullStateItem = ({title, description, attention}) => {
  const {t} = useTranslation();

  return (
    <div className="framework-agreements__null-state-item-wrapper">
      <div className="framework-agreements__null-state-item-title">
        <IconNotificationCircle color={color.black} addClass="mr-2" />
        {t(title)}
      </div>
      <div className="framework-agreements__null-state-item-description">
        {t(description)}
      </div>
      {attention && (
        <div className="framework-agreements__null-state-item-attention">
          {t(attention)}
        </div>
      )}
    </div>
  );
};

export default FrameworkAgreementsNullStateItem;

import React, {memo} from "react";

export const IconCart = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 20C6.897 20 6 19.103 6 18C6 16.897 6.897 16 8 16C9.103 16 10 16.897 10 18C10 19.103 9.103 20 8 20ZM8 17C7.449 17 7 17.449 7 18C7 18.551 7.449 19 8 19C8.551 19 9 18.551 9 18C9 17.449 8.551 17 8 17Z"
        fill={color || "#000"}
      />
      <path
        d="M15 20C13.897 20 13 19.103 13 18C13 16.897 13.897 16 15 16C16.103 16 17 16.897 17 18C17 19.103 16.103 20 15 20ZM15 17C14.449 17 14 17.449 14 18C14 18.551 14.449 19 15 19C15.551 19 16 18.551 16 18C16 17.449 15.551 17 15 17Z"
        fill={color || "#000"}
      />
      <path
        d="M17.539 4.467C17.288 4.17 16.909 4 16.5 4H4.257L4.158 3.404C4.027 2.617 3.299 2 2.5 2H1.5C1.224 2 1 2.224 1 2.5C1 2.776 1.224 3 1.5 3H2.5C2.807 3 3.121 3.266 3.171 3.569L4.842 13.596C4.973 14.383 5.701 15 6.5 15H16.5C16.776 15 17 14.776 17 14.5C17 14.224 16.776 14 16.5 14H6.5C6.193 14 5.879 13.734 5.829 13.431L5.582 11.951L15.547 11.084C16.322 11.017 17.03 10.363 17.158 9.595L17.829 5.568C17.896 5.164 17.791 4.762 17.54 4.466L17.539 4.467ZM16.842 5.404L16.171 9.431C16.118 9.747 15.78 10.06 15.46 10.088L5.417 10.961L4.423 4.999H16.499C16.616 4.999 16.714 5.039 16.775 5.112C16.836 5.185 16.86 5.288 16.841 5.403L16.842 5.404Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

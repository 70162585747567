import EditSelect from "components/selects/EditSelect";
import EditDatePicker from "components/EditDatePicker";
import ButtonDelete from "components/buttons/ButtonDelete";
import React from "react";
import {useTranslation} from "react-i18next";
import {memberOFOrganizationList} from "constants/select.consts";

const MemberForm = ({index, remove}) => {
  const {t} = useTranslation();

  return (
    <div className="editBlock_item">
      <div className="editBlock_flex__wrap">
        <EditSelect
          name={`members.${index}.organization`}
          label={t("ORGANIZATION")}
          sorted
          bottomElement="MEMBER_OF_ORGANIZATION_28"
          list={memberOFOrganizationList}
        />
        <EditDatePicker name={`members.${index}.since`} label={t("SINCE")} />

        <ButtonDelete addClass="editBlock_btn__clean" onClick={() => remove(index)} />
      </div>
    </div>
  );
};

export default MemberForm;

import React from "react";
import {inject, observer} from "mobx-react";
// local
import AnnualReport from "../components/FinancialComponents/AnnualReport/AnnualReport";
import NonPaymentRecords from "../components/FinancialComponents/NonPaymentRecords/NonPaymentRecords";
import UnpaidDebts from "../components/FinancialComponents/UnpaidDebts/UnpaidDebts";
import Signatories from "../components/FinancialComponents/Signatories";
import Management from "../components/FinancialComponents/Management/Management";
import CorporateStructure from "../components/FinancialComponents/CorporateStructure";
import BeneficialOwner from "../components/FinancialComponents/BeneficialOwner";

const FinancialSection = inject("store")(
  observer(({store: {organization}}) => {
    const {organizationDetail} = organization;
    const hasFirstUpdateData = !!organizationDetail.Company.updatedCreditSafeCredit;

    return (
      <div className="finance-section_wrapper">
        <CorporateStructure
          companyData={organizationDetail}
          hasFirstUpdateData={hasFirstUpdateData}
        />
        <AnnualReport
          companyData={organizationDetail}
          hasFirstUpdateData={hasFirstUpdateData}
        />
        <NonPaymentRecords
          companyData={organizationDetail}
          hasFirstUpdateData={hasFirstUpdateData}
        />
        <UnpaidDebts
          companyData={organizationDetail}
          hasFirstUpdateData={hasFirstUpdateData}
        />
        <div className="finance-section_half-block">
          <Signatories
            companyData={organizationDetail}
            hasFirstUpdateData={hasFirstUpdateData}
          />
          <Management
            companyData={organizationDetail}
            hasFirstUpdateData={hasFirstUpdateData}
          />
        </div>
        <BeneficialOwner
          companyData={organizationDetail}
          hasFirstUpdateData={hasFirstUpdateData}
        />
      </div>
    );
  })
);

export default FinancialSection;

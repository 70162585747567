import React, {memo} from "react";

export const IconDeleteClose = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.92898 3.92381C-0.974252 7.82703 -0.974252 14.1627 2.92898 18.0659C6.83221 21.9692 13.1679 21.9692 17.0711 18.0659C20.9743 14.1627 20.9743 7.82704 17.0711 3.92381C13.1679 0.020576 6.83221 0.0205757 2.92898 3.92381ZM14.2427 8.16645L11.4143 10.9949L14.2427 13.8233L12.8285 15.2375L10 12.4091L7.17162 15.2375L5.7574 13.8233L8.58583 10.9949L5.7574 8.16645L7.17162 6.75223L10 9.58066L12.8285 6.75223L14.2427 8.16645Z"
        fill={color || "#fff"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconDocumentPen = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color || "#000"}
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.5 20H1.5C0.673 20 0 19.327 0 18.5V2.5C0 1.673 0.673 1 1.5 1H14.5C15.327 1 16 1.673 16 2.5V4.5C16 4.776 15.776 5 15.5 5C15.224 5 15 4.776 15 4.5V2.5C15 2.224 14.776 2 14.5 2H1.5C1.224 2 1 2.224 1 2.5V18.5C1 18.776 1.224 19 1.5 19H14.5C14.776 19 15 18.776 15 18.5V14.5C15 14.224 15.224 14 15.5 14C15.776 14 16 14.224 16 14.5V18.5C16 19.327 15.327 20 14.5 20Z" />
      <path d="M10.5 5H3.5C3.224 5 3 4.776 3 4.5C3 4.224 3.224 4 3.5 4H10.5C10.776 4 11 4.224 11 4.5C11 4.776 10.776 5 10.5 5Z" />
      <path d="M12.5 7H3.5C3.224 7 3 6.776 3 6.5C3 6.224 3.224 6 3.5 6H12.5C12.776 6 13 6.224 13 6.5C13 6.776 12.776 7 12.5 7Z" />
      <path d="M11.5 9H3.5C3.224 9 3 8.776 3 8.5C3 8.224 3.224 8 3.5 8H11.5C11.776 8 12 8.224 12 8.5C12 8.776 11.776 9 11.5 9Z" />
      <path d="M8.5 11H3.5C3.224 11 3 10.776 3 10.5C3 10.224 3.224 10 3.5 10H8.5C8.776 10 9 10.224 9 10.5C9 10.776 8.776 11 8.5 11Z" />
      <path d="M8.50001 17C8.36601 17 8.23501 16.946 8.14001 16.847C8.01601 16.719 7.97001 16.534 8.01901 16.363L9.01901 12.863C9.04201 12.781 9.08601 12.707 9.14601 12.647L16.646 5.14698C16.841 4.95198 17.158 4.95198 17.353 5.14698L19.853 7.64698C20.047 7.84098 20.048 8.15498 19.856 8.35098L12.356 15.992C12.291 16.058 12.209 16.105 12.118 16.127L8.61801 16.986C8.57901 16.996 8.53901 17 8.49901 17H8.50001ZM9.94501 13.262L9.21701 15.809L11.742 15.189L18.795 8.00298L16.999 6.20698L9.94401 13.262H9.94501Z" />
      <path d="M6.5 17H3.5C3.224 17 3 16.776 3 16.5C3 16.224 3.224 16 3.5 16H6.5C6.776 16 7 16.224 7 16.5C7 16.776 6.776 17 6.5 17Z" />
    </svg>
  );
});

export default IconDocumentPen;

import React from "react";

export const IconLinkRocmore = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2" fill="#EE6F33" />
      <path
        d="M3.17495 15.9457C2.99103 15.8342 3.00007 16.0513 3.00007 12.0141C3.00007 8.74874 3.0061 8.31759 3.0453 8.2603C3.08751 8.20301 3.4855 7.95879 5.36691 6.8402C5.55083 6.73166 5.82821 6.56583 5.985 6.47236C6.34078 6.25829 8.56892 4.93166 9.47042 4.39498C9.87746 4.15377 10.1699 4 10.2182 4C10.3297 4 10.4564 4.06935 11.18 4.51859C11.5237 4.73266 11.8282 4.9407 11.8554 4.98291C11.9187 5.08241 11.8945 5.3206 11.8071 5.38995C11.7739 5.41709 11.2403 5.73367 10.6222 6.09548C10.0041 6.45729 9.27445 6.88241 9.00007 7.04523C8.52671 7.32261 7.89957 7.69045 6.9649 8.23618C5.1257 9.31256 5.24329 9.23417 5.20711 9.38794C5.18902 9.45126 5.1649 10.8834 5.14681 12.6141C5.11967 15.5266 5.11364 15.7286 5.06239 15.8161C4.95987 15.9879 4.89655 16 4.03424 16C3.35585 16 3.24731 15.991 3.17495 15.9457Z"
        fill="white"
      />
      <path
        d="M7.25735 15.9518C7.188 15.9246 7.13976 15.8734 7.09755 15.7769C7.04026 15.6442 7.04026 15.5779 7.04629 13.1447C7.05534 10.8894 7.06137 10.6392 7.1066 10.5578C7.14278 10.4884 7.35986 10.3467 7.99604 9.97286C8.988 9.38793 9.79906 8.90854 10.4624 8.51658C10.984 8.20602 11.1739 8.09447 12.8865 7.0814C14.0895 6.36984 14.1136 6.35778 14.3156 6.46029C14.3699 6.48743 14.59 6.62311 14.8041 6.75879C15.0212 6.89748 15.597 7.25326 16.0855 7.55477C16.5739 7.85627 17.0141 8.14572 17.0624 8.19698L17.1498 8.29346L17.1317 12.011C17.1106 16.0935 17.1227 15.8553 16.9297 15.9548C16.8573 15.9909 16.7006 16 16.0614 16C15.3136 16 15.2774 15.997 15.1991 15.9367C15.0212 15.798 15.0302 15.9668 15.0302 12.7377V9.77085L14.9639 9.63818C14.6714 9.06532 13.586 9.09245 13.2724 9.68039L13.2061 9.80401L13.182 12.7437C13.1548 15.9608 13.1639 15.8402 12.98 15.9487C12.9076 15.994 12.79 16 12.0965 16C11.6473 16 11.2614 15.9879 11.2192 15.9698C11.1769 15.9548 11.1136 15.8975 11.0805 15.8432C11.0232 15.7467 11.0202 15.6894 11.0202 13.404C11.0202 11.1035 11.0202 11.0613 10.9599 10.9859C10.9267 10.9437 10.8513 10.8925 10.794 10.8744C10.6161 10.8171 10.6553 10.793 9.64227 11.5407C9.42519 11.7035 9.22619 11.8663 9.19906 11.9055C9.15685 11.9658 9.15082 12.1769 9.15082 13.8291C9.15082 15.611 9.1478 15.6864 9.09051 15.7799C8.988 15.9518 9.00609 15.9487 7.80911 15.9819C7.4684 15.9909 7.33273 15.9849 7.25735 15.9518Z"
        fill="white"
      />
    </svg>
  );
};

import React from "react";
import {useTranslation} from "react-i18next";
// local
import {formattedNumber} from "helpers/number.formats";
import {SkeletonRectValue} from "components/SkeletonRectValue";
import {LockValue} from "components/LockValue";

const NonPayments = ({nonPaymentValue}) => {
  const {t} = useTranslation();

  return (
    <div className="presentation-page__left-side__cards">
      <LockValue
        canPermissions={["READ_OTHER_NON_PAYMENTS"]}
        needLabel
        needRightIcon={false}
        noHideOurInfo
        addClassIcon="mb-2"
      >
        <h5>{t("NON_PAYMENTS_LEFT_SIDE")}</h5>
      </LockValue>

      <SkeletonRectValue
        quantity={4}
        canPermissions={["READ_OTHER_NON_PAYMENTS"]}
        noHideOurInfo
      >
        <strong className="presentation-page__non-payment">
          {nonPaymentValue ? formattedNumber(parseInt(nonPaymentValue / 1000)) : "-"}
        </strong>
      </SkeletonRectValue>
    </div>
  );
};

export default NonPayments;

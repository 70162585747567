import React from "react";

export const IconLinkOljibe = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2" fill="#FFE664" />
      <path
        d="M1.64275 10C1.64275 7.91548 5.46988 6.15448 10 6.15448V13.8456C5.46988 13.8456 1.64275 12.0846 1.64275 10Z"
        fill="#FFE664"
      />
      <path
        d="M1.64275 10C1.64275 7.91548 5.46988 6.15448 10 6.15448V13.8456C5.46988 13.8456 1.64275 12.0846 1.64275 10ZM10 5.5C4.95313 5.5 1 7.47661 1 10C1 12.5234 4.95313 14.5 10 14.5C15.0466 14.5 19 12.5234 19 10C19 7.47661 15.0466 5.5 10 5.5Z"
        fill="#E41B13"
      />
      <path
        d="M9.99992 8.12982L14.8888 9.12549L14.8938 9.63596L9.99992 8.83824V8.12982Z"
        fill="#FFE664"
      />
      <path
        d="M9.99622 9.44376L14.8626 9.94343L14.8576 10.4544L9.99983 10.0828L9.99622 9.44376Z"
        fill="#FFE664"
      />
      <path
        d="M9.99992 10.657L14.863 10.8039L14.8579 11.3161L9.99992 11.3272V10.657Z"
        fill="#FFE664"
      />
      <path
        d="M9.99623 8.12982L5.1074 9.12549L5.10234 9.63596L9.99623 8.83824V8.12982Z"
        fill="#E41B13"
      />
      <path
        d="M9.99993 9.44376L5.13375 9.94343L5.13856 10.4544L9.99632 10.0828L9.99993 9.44376Z"
        fill="#E41B13"
      />
      <path
        d="M9.9962 10.657L5.13315 10.8039L5.13821 11.3161L9.9962 11.3272V10.657Z"
        fill="#E41B13"
      />
    </svg>
  );
};

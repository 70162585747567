import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import SellerSideProposal from "../components/SellerSideProposal";
import SmartFormAnswers from "../components/SmartForm/SmartFormAnswers";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {showSuccess} from "helpers/notifications.helper";
import arrowLeftIcon from "assets/images/svg/arrow-left.svg";
import {IconTrumbsUp, IconTrumbsDown, IconFeather} from "components/icons";
import {NotInterestedMessageModal} from "components/modals/NotInterestedMessageModal";
import {hasPermission} from "helpers/helper";
import {LockValue} from "components/LockValue";
import {color} from "constants/color.consts";

const ProposalCard = inject("store")(
  observer(({store: {buyer}, backToProposals}) => {
    const {currentRequest, currentOffer, offerIdWithContract} = buyer;
    const [isNotInterestedMessageModal, setIsNotInterestedMessageModal] = useState(false);
    const {t} = useTranslation();

    const isNeedShowAnswers = !!currentRequest.smartFormQuestions?.filter(
      (item) => item.type !== "heading"
    ).length;

    const changeEvaluate = (evaluate) => {
      // to prevent the same button from being pressed again
      if (
        currentOffer?.evaluate !== null &&
        (evaluate === evaluated || !evaluate === declined)
      )
        return;
      evaluate
        ? buyer
            .changeEvaluateStatus(currentOffer.id, {evaluate, rejectMessage: null})
            .then(() => showSuccess())
        : setIsNotInterestedMessageModal(true);
    };

    const evaluated = currentOffer?.evaluate === true;
    const declined = currentOffer?.evaluate === false;

    const onConfirmNotInterested = (rejectMessage) =>
      buyer
        .changeEvaluateStatus(currentOffer.id, {evaluate: false, rejectMessage})
        .then(() => showSuccess());

    const startContract = () => {
      if (!hasPermission(["CREATE_BUYER_CONTRACT"])) return;
      buyer.setInitialContract(true);
      buyer.setActiveRequestTab(3);
    };

    return (
      <div className="proposal-card">
        <div className="proposal-card_header __uppercase mb-6 ml-2">
          <div className="df">
            <img
              className="mr-2"
              src={arrowLeftIcon}
              alt={arrowLeftIcon}
              onClick={backToProposals}
            />
            {t("OFFERS")}
          </div>

          <div className="df">
            {!offerIdWithContract && (
              <LockValue
                canPermissions={["CREATE_BUYER_CONTRACT"]}
                needLabel
                needShowModalToSubscribe
              >
                <ButtonTransparent
                  addClass="create-agreement-button"
                  nonTransparent
                  small
                  name={t("ACCEPT_CREATE_AGREEMENT")}
                  type="button"
                  onClick={startContract}
                  icon={<IconFeather />}
                />
              </LockValue>
            )}
            <LockValue
              canPermissions={["ENABLE_RFT_OFFERS_EVALUATE_OFFER"]}
              needLabel
              needShowModalToSubscribe
            >
              <ButtonTransparent
                name={t("EVALUATE")}
                addClass="evaluating-button ml-8"
                small
                type="button"
                icon={<IconTrumbsUp color={evaluated && color.white} />}
                onClick={() =>
                  hasPermission(["ENABLE_RFT_OFFERS_EVALUATE_OFFER"]) &&
                  changeEvaluate(true)
                }
                style={{
                  borderColor: color.green,
                  color: evaluated ? color.white : color.green,
                  backgroundColor: evaluated ? color.green : color.white,
                }}
              />
            </LockValue>

            <LockValue
              canPermissions={["ENABLE_RFT_OFFERS_DECLINE_OFFER"]}
              needLabel
              needShowModalToSubscribe
            >
              <ButtonTransparent
                name={t("DECLINE")}
                addClass="no-thanks-button ml-2"
                small
                type="button"
                icon={<IconTrumbsDown color={declined && color.white} />}
                onClick={() =>
                  hasPermission(["ENABLE_RFT_OFFERS_EVALUATE_OFFER"]) &&
                  changeEvaluate(false)
                }
                style={{
                  borderColor: color.red,
                  color: declined ? color.white : color.red,
                  backgroundColor: declined ? color.red : color.white,
                }}
              />
            </LockValue>
          </div>
        </div>
        <SellerSideProposal />
        <SmartFormAnswers
          isNew={false}
          isEdit={false}
          titleHide={!isNeedShowAnswers}
          questions={currentRequest?.smartFormQuestions}
          answers={currentOffer?.smartFormAnswers ?? []}
        />
        {isNotInterestedMessageModal && (
          <NotInterestedMessageModal
            title="NOT_INTERESTED_MESSAGE_BUYER_MODAL_TITLE"
            subtitle={t("NOT_INTERESTED_MESSAGE_BUYER_MODAL_SUBTITLE")}
            rejectMessage={currentOffer?.rejectMessage}
            onConfirm={onConfirmNotInterested}
            onClose={() => setIsNotInterestedMessageModal(false)}
          />
        )}
      </div>
    );
  })
);

export default ProposalCard;

import React from "react";
import AvailableIn from "./AvailableIn";
import Employees from "./Employees";
import JobSizeMinMax from "./JobSizeMinMax";
import {useTranslation} from "react-i18next";
import {findSuppliersFilterTabs} from "constants/tabs.const";
import CompanyTypeItem from "./CompanyTypeItem";
import {availableInArray} from "constants/select.consts";

const SearchPreferences = ({searchPreference}) => {
  const {t} = useTranslation();
  const isVisible = (item) =>
    item.content.some(
      (val) => searchPreference?.[val.label] && searchPreference?.[val.label].length
    );

  const filledSuppliersFilterTabs = findSuppliersFilterTabs.map((item) => ({
    ...item,
    visible: isVisible(item),
  }));

  return (
    <>
      <div className="edit-our-services_wrapper _main-filters">
        <AvailableIn
          availableIn={
            searchPreference?.availableIn?.length === availableInArray.length
              ? ["FIRST_SETUP_REGION_00"]
              : searchPreference?.availableIn
          }
        />
        <div>
          <div className="blob-title">{t("FIRST_SETUP_SELECT_JOB_SIZE")}</div>
          <div className="blob-item">
            <JobSizeMinMax JobSizeMinMax={searchPreference?.jobSizeMin} noTitle />
            <div className="pl-1 pr-1 pb-12">-</div>
            <JobSizeMinMax JobSizeMinMax={searchPreference?.jobSizeMax} noTitle isMax />
          </div>
        </div>
        <Employees employees={searchPreference?.employees} />
      </div>
      <div className="edit-our-services_wrapper _sub-filters">
        {filledSuppliersFilterTabs.map(
          (item) =>
            item.visible && (
              <CompanyTypeItem
                key={item.groupTitle}
                title={t(item.groupTitle)}
                selects={item.content.map((value) => ({
                  ...value,
                  values: searchPreference?.[value.label],
                }))}
              />
            )
        )}
      </div>
    </>
  );
};

export default SearchPreferences;

import React from "react";
import {useTranslation} from "react-i18next";
//local
import {InfoTooltip} from "components/InfoTooltip";
import cn from "classnames";

export const LabelData = ({
  label,
  data,
  explanation,
  explanationInfo,
  additionalData,
  info,
  boldData,
  noTranslate,
  addClass,
}) => {
  const {t} = useTranslation();

  return (
    <div className={cn("label-data__wrapper", {[addClass]: !!addClass})}>
      <div className="label-data__label">
        {noTranslate ? label : t(label)}
        {info && <InfoTooltip tooltip={info} addClass="ml-4" />}
      </div>
      <div className={cn("label-data__data", {__bold: !!boldData})}>{data}</div>
      {explanation && (
        <div className="mt-1 fz-13 df">
          {t(explanation)}{" "}
          {explanationInfo && <InfoTooltip tooltip={explanationInfo} addClass="ml-2" />}
        </div>
      )}
      {additionalData}
    </div>
  );
};

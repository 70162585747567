import React, {useState, useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";
import {FieldArray, Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
// local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconPlus} from "components/icons/index";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import InsuranceForm from "./InsuranceForm";
import {showSuccess} from "helpers/notifications.helper";
import {schema, initialValues} from "./formAttrs";
import {Preloader} from "components/Preloader";

const InsuranceEdit = inject("store")(
  observer(({store: {auth, organization, buyer}, closeEdit}) => {
    const {t} = useTranslation();
    const {user} = auth;
    const location = useLocation();
    const insuranceRef = useRef();

    const [meets, setMeets] = useState([]);
    const [loadingRow, setLoadingRow] = useState(false);
    const isInsurances = !!organization.organizationDetail?.Insurances.length;
    const insurances = isInsurances ? organization.organizationDetail?.Insurances : [];
    const [loading, setLoading] = useState(false);
    const [showInsuranceModal, setShowInsuranceModal] = useState(
      user.ActiveOrganization?.userLastNoInsuranceNotificationDate
        ? new Date(
            user.ActiveOrganization?.userLastNoInsuranceNotificationDate
          ).getFullYear() !== new Date().getFullYear()
        : true
    );

    useEffect(() => {
      isInsurances && setLoadingRow(true);
      buyer.getAgreements().then((response) => {
        setMeets(response.data.map((val) => ({[val]: false})));
        setLoadingRow(false);
      });
    }, [buyer, isInsurances]);

    useEffect(() => {
      location.state?.editState?.insurance && insuranceRef.current.scrollIntoView();
    }, [location.state]);

    const insuranceForms = ({insurances, remove, setFieldValue}) =>
      insurances.map((insurance, i) => (
        <InsuranceForm
          key={i}
          index={i}
          remove={remove}
          insurance={insurance}
          setFieldValue={setFieldValue}
        />
      ));

    const setLastNoInsuranceNotificationDate = (checked) => {
      setShowInsuranceModal(checked);
      auth.setLastNoInsuranceNotificationDate({
        lastNoInsuranceNotificationDate: checked
          ? new Date("2050-01-01").toISOString()
          : new Date().toISOString(),
      });
    };

    const onSubmit = ({insurances}) => {
      setLoading(true);
      organization
        .insuranceEdit({insurances})
        .then((data) => {
          showSuccess("Organization insurances successfully updated.");
          organization.setOrganizationDetail({
            ...organization.organizationDetail,
            Insurances: data.existingInsurances.concat(data.newInsurances),
          });
          closeEdit();
        })
        .finally(() => setLoading(false));
    };

    return (
      <Formik
        initialValues={{insurances}}
        validationSchema={schema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({values, setFieldValue}) => (
          <Form ref={insuranceRef}>
            <FieldArray
              name="insurances"
              render={(arrayHelpers) => (
                <div className="edit-insurance_wrapper editBlock_wrapper">
                  <div className="editBlock_head">
                    <div className="editBlock_head__title df">
                      {t("INSURANCE")}
                      <CustomRadioSwitch
                        label={t("EMPTY_INSURANCE_ATTENTION_SWITCH")}
                        checked={showInsuranceModal}
                        small
                        mediumText
                        addWrapperClass="ml-4"
                        isActive={setLastNoInsuranceNotificationDate}
                      />
                    </div>
                    <div className="editBlock_head__actions">
                      <ButtonTransparent
                        name={t("SAVE_CHANGES")}
                        type="submit"
                        loading={loading}
                        addClass="editBlock_head__btn"
                      />
                      <ButtonTransparent
                        name={t("CANCEL")}
                        type="button"
                        addClass="editBlock_head__btn"
                        onClick={closeEdit}
                      />
                    </div>
                  </div>
                  <div className="editBlock_body">
                    {loadingRow ? (
                      <Preloader size="default" addClass="mt-0" />
                    ) : (
                      insuranceForms({
                        insurances: values.insurances,
                        remove: arrayHelpers.remove,
                        setFieldValue: setFieldValue,
                      })
                    )}
                    <div className="btn-custom_wrap">
                      <ButtonTransparent
                        name={t("ONE_MORE")}
                        type="button"
                        icon={<IconPlus />}
                        className="editBlock_head__btn"
                        onClick={() => {
                          arrayHelpers.push({...initialValues, meets: meets});
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            />
          </Form>
        )}
      </Formik>
    );
  })
);

export default InsuranceEdit;

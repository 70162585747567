import React, {memo} from "react";

export const IconCreditUpdateOld = memo(({addClass}) => {
  return (
    <svg
      width="194"
      height="10"
      viewBox="0 0 194 10"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14386_213980)">
        <rect width="194" height="10" fill="url(#paint0_linear_14386_213980)" />
        <path d="M8 5L5 5.24537e-07L11 0L8 5Z" fill="white" />
        <rect opacity="0.5" width="8" height="5" fill="white" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_14386_213980"
          x1="0"
          y1="10"
          x2="194"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA2E2E" />
          <stop offset="1" stopColor="#6BC069" />
        </linearGradient>
        <clipPath id="clip0_14386_213980">
          <path
            d="M0 3C0 1.34315 1.34315 0 3 0H191C192.657 0 194 1.34315 194 3V10H0V3Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

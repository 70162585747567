import React, {memo} from "react";

export const IconClose = memo(({height, width, color, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "19"}
      viewBox="0 0 20 19"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.707 9.5L19.353 0.853998C19.548 0.658998 19.548 0.341998 19.353 0.146998C19.158 -0.0480023 18.841 -0.0480023 18.646 0.146998L9.99998 8.793L1.35398 0.146998C1.15898 -0.0480023 0.841982 -0.0480023 0.646982 0.146998C0.451982 0.341998 0.451982 0.658998 0.646982 0.853998L9.29298 9.5L0.646982 18.146C0.451982 18.341 0.451982 18.658 0.646982 18.853C0.744982 18.951 0.872982 18.999 1.00098 18.999C1.12898 18.999 1.25698 18.95 1.35498 18.853L10.001 10.207L18.647 18.853C18.745 18.951 18.873 18.999 19.001 18.999C19.129 18.999 19.257 18.95 19.355 18.853C19.55 18.658 19.55 18.341 19.355 18.146L10.709 9.5H10.707Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

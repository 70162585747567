import * as yup from "yup";
import {stringRequired} from "../../../../yup/defaultValudations";

const commonSchemaObject = {
  name: stringRequired(),
  reference: yup.string().nullable(),
  location: yup.string().nullable(),
  buyer: yup.string().nullable(),
  availableIn: yup.array().required(),
  Members: yup.array(),
  daluxProjectId: yup.string().when("allowDalux", {
    is: true,
    then: stringRequired().nullable(),
    otherwise: yup.string().nullable(),
  }),
};

export const schema = yup.object().shape(commonSchemaObject);

export const schemaWDepartment = yup
  .object()
  .shape({...commonSchemaObject, DepartmentId: stringRequired()});

import React from "react";

const NotifierDot = ({addClass}) => {
  return (
    <div className={`notifier-dot ${addClass ?? ""}`}>
      <span className="static"></span>
      <span className="pulser"></span>
    </div>
  );
};

export default NotifierDot;

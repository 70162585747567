import React, {useCallback, useState, useEffect, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
//local
import {InputsCode} from "components/InputsCode";
import SimpleField from "components/SimpleField";
import {Button} from "../../buttons/Button";
import {schema} from "./formAttrs";
import {showSuccess, showWarning} from "helpers/notifications.helper";

export const ModalIdentifyEmailAddress = inject("store")(
  observer(({store: {auth}}) => {
    const {user, counter} = auth;
    const [code, setCode] = useState(["", "", "", ""]);
    const [isOtherEmail, setIsOtherEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const isCounter = useMemo(() => counter > 0, [counter]);

    const padTime = (time) => {
      return String(time).length === 1 ? `0${time}` : `${time}`;
    };

    const format = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes}:${padTime(seconds)}`;
    };

    useEffect(() => {
      const timer = isCounter
        ? setTimeout(() => auth.setCounter(counter - 1), 1000)
        : null;
      return () => timer && clearTimeout(timer);
    }, [counter, isCounter, auth]);

    const submitCode = async (e) => {
      e.preventDefault();
      auth.setCounter(300);
      const codeStr = code.join("");
      const result = await auth.checkConfirmationCode(codeStr);
      setCode(["", "", "", ""]);

      if (result) {
        showSuccess(t("EMAIL_CODE_CORRECT"));
        auth.checkToken();
        auth.updateAuthModalName();
      } else showWarning(t("EMAIL_CODE_INCORRECT"));
    };

    const handlerOnSubmit = async (values, {resetForm}) => {
      setLoading(true);
      auth
        .updateUser({...values, firstName: user.firstName, lastName: user.lastName}, true)
        .then(() => {
          resetForm();
          auth.sendConfirmationEmail();
          auth.checkToken();
          showSuccess(t("SUCCESSFULLY_SENT_NEW_CODE"));
          setIsOtherEmail(false);
        })
        .finally(() => setLoading(false));
    };

    const isValid = useCallback(() => code.every((x) => x.length === 1), [code])();

    return (
      <div className="modal-email-identify">
        {isOtherEmail ? (
          <Formik
            initialValues={{email: user.email}}
            onSubmit={handlerOnSubmit}
            validationSchema={schema}
          >
            <Form>
              <div className="invite-suppliers_form">
                <h2>{t("MODAL_EMAIL_IDENTIFY_NOEMAIL")}</h2>
                <p className="modal-email-identify__text">
                  {t("MODAL_EMAIL_IDENTIFY_TEXT")}
                </p>
                <SimpleField
                  placeholder={t("LOGIN_PLACEHOLDER_EMAIL")}
                  name="email"
                  errorName="email"
                  className="input"
                  addClass="modal-email-identify__input-email"
                  disabled={loading}
                />
                <Button
                  addClass="mt-16"
                  name={t("MODAL_EMAIL_IDENTIFY_SEND_CODE")}
                  type="submit"
                />
              </div>
            </Form>
          </Formik>
        ) : (
          <form onSubmit={submitCode}>
            <h2>{t("MODAL_EMAIL_IDENTIFY_TITLE")}</h2>
            <p className="modal-email-identify__text">
              {`${t("MODAL_EMAIL_IDENTIFY_TEXT_BEFORE")}${user.email}. 
              ${t("MODAL_EMAIL_IDENTIFY_TEXT_AFTER")}`}
            </p>
            <InputsCode disabled={counter > 0} code={code} setCode={setCode} />
            {isCounter && (
              <div className="modal-email-identify__text-next-code">
                {t("SEND_NEXT_CODE_LABEL")}
                {format(counter)}
              </div>
            )}
            <Button
              addClass="mt-8"
              name={t("SUBMIT_CODE")}
              disabled={!isValid}
              type="submit"
            />
            <p className="modal-email-identify__text mt-8">
              {t("MODAL_EMAIL_IDENTIFY_DESCRIPTION")}
            </p>
            <Button
              addClass="transparent-button mt-16"
              name={t("MODAL_EMAIL_IDENTIFY_NOEMAIL")}
              onClick={() => setIsOtherEmail(true)}
            />
          </form>
        )}
      </div>
    );
  })
);

import React from "react";
import RatingObserver from "./RatingObserver";
import {ratingTypes, ratingsList} from "constants/ourEvaluationPage.consts";
import AddRatingObserverItem from "./AddRatingObserverItem/AddRatingObserverItem";
import {inject, observer} from "mobx-react";

const RatingsListing = inject("store")(
  observer(({store: {organization}}) => {
    const {organizationDetail} = organization;

    return (
      <div className="rating-observer_body">
        {ratingsList.map((item) => (
          <RatingObserver
            key={item.type}
            rating={
              organization.ratings.find((rate) => rate.type === item.type) || item.initial
            }
            isEditable
            organizationId={organizationDetail?.id}
            setOrganizationRating={organization.setOrganizationRating}
            getListAllRatings={() =>
              organization.getListAllRatings(organizationDetail?.id)
            }
          />
        ))}
        {organization.ratings &&
          organization.ratings
            .filter((rate) => rate.type === ratingTypes.custom)
            .map((item) => (
              <RatingObserver
                key={item.id}
                rating={item}
                isEditable
                organizationId={organizationDetail?.id}
                setOrganizationRating={organization.setOrganizationRating}
                getListAllRatings={() =>
                  organization.getListAllRatings(organizationDetail?.id)
                }
              />
            ))}

        <AddRatingObserverItem
          organizationId={organizationDetail?.id}
          setOrganizationRating={organization.setOrganizationRating}
          getListAllRatings={() => organization.getListAllRatings(organizationDetail?.id)}
        />
      </div>
    );
  })
);

export default RatingsListing;

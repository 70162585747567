// ======================================
// Working with POSTS. Data
// ======================================

import {makeAutoObservable} from "mobx";
//local
import axiosInstance from "../axios";
import {directionReplacement} from "helpers/helper";
import {defaultCreatedFieldSearch} from "constants/user.consts";

class PublicStore {
  // STATE. Observable and computed part
  // ****** COMMON ******
  loading = false; // loading status for parts of the page
  btnLoading = false; // loading status for buttons
  commonSettings = null; // common application settings

  // ****** POSTS ******
  posts = {}; // posts
  currentPost = {}; // data of the current (edited) post
  // filter parameters for posts list
  postsFilterParams = defaultCreatedFieldSearch;

  // ****** REQUESTS ******
  currentRequest = {}; // object of the current (viewed) request

  constructor() {
    makeAutoObservable(this);
  }

  // ACTIONS
  // ****** WORKING WITH POSTS ******

  // getting all posts
  async getPosts(page, isUnautorized) {
    this.loading = true;
    // substituting the required sort value instead of the default value
    const direction = directionReplacement(this.postsFilterParams);
    const params = {...this.postsFilterParams, direction, page};
    try {
      const {data} = await axiosInstance.get(
        `${isUnautorized ? "public/" : ""}post/search`,
        {params}
      );
      this.loading = false;
      this.posts = data;
      this.currentPost = {};
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }

  // setting filters for an Posts request
  setPostsFilterParams(values) {
    this.postsFilterParams = values;
  }

  // getting one specific Post
  async getCurrentPost(id, isUnautorized) {
    this.loading = true;
    if (!id) {
      this.currentPost = {};
      return;
    }
    try {
      const {data} = await axiosInstance.get(
        `${isUnautorized ? "public/" : ""}post/${id}`
      );
      this.currentPost = data;
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // saving updated (new) information on Post after editing (creating)
  async saveCurrentPost(post, id) {
    // Post to edit
    this.btnLoading = true;
    if (id) {
      try {
        const {
          data: {post: newPost},
        } = await axiosInstance.post(`post/${id}`, post);
        this.btnLoading = false;
        this.posts = this.posts.map((item) => (item.id === id ? newPost : item));
        return newPost;
      } catch (e) {
        this.btnLoading = false;
        throw e;
      }
      // Post to create
    } else {
      try {
        const {data} = await axiosInstance.post("post/create", post);
        this.btnLoading = false;
        return data.post;
      } catch (e) {
        this.btnLoading = false;
        throw e;
      }
    }
  }

  // getting one specific Post
  async deletePost(id) {
    this.loading = true;
    try {
      await axiosInstance.delete(`post/${id}`);
      this.loading = false;
    } catch (e) {
      this.loading = false;
      throw new Error(e);
    }
  }

  // changing interested status of the Post
  async changePostStatus(id, status) {
    try {
      const {data} = await (status
        ? axiosInstance.delete(`post/${id}/interested`)
        : axiosInstance.post(`post/${id}/interested`));
      this.posts = this.posts.map((item) => (item.id === id ? data : item));
    } catch (e) {
      throw e;
    }
  }

  // ****** WORKING WITH PRF OFFERS LIST ******

  // getting one specific RFP (request)
  async getCurrentRequestInfo(id) {
    this.loading = true;
    try {
      const {data} = await axiosInstance.get(`public/rfp/buyer/${id}`, {id});
      this.currentRequest = data.rfp;
    } catch (e) {
      throw new Error(e);
    }
    this.loading = false;
  }

  // getting list of offers for specific RFP
  async getOffers(id) {
    const questions = this.currentRequest?.smartFormQuestions?.filter(
      (item) => item.type !== "heading"
    );

    return axiosInstance.get(`public/rfp/buyer/${id}/offers`).then(({data}) => {
      return data.map((item) => ({
        id: item.id,
        name: item.Proposal.Organization.name,
        offerValue: item.price,
        validUntil: item.validUntil,
        evaluate: item.evaluate,
        readed: item.readed,
        smartFormAnswers:
          item.smartFormAnswers && !!questions.length
            ? item.smartFormAnswers.map((item) => ({
                ...item,
                type: questions.find((question) => question.id === item.questionId)?.type,
              }))
            : item.smartFormAnswers,
      }));
    });
  }

  // getting list of offers for specific RFP
  async getCombinedOffers(id) {
    const questions = this.currentRequest?.smartFormQuestions?.filter(
      (item) => item.type !== "heading"
    );

    return axiosInstance.get(`public/rfp/buyer/${id}/combined-offers`).then(({data}) => {
      return {
        ...data,
        buyerOffers: data.buyerOffers.map((item) => ({
          ...item,
          name: item.Proposal.Organization.name,
          offerValue: item.price,
          smartFormAnswers:
            item.smartFormAnswers && !!questions.length
              ? item.smartFormAnswers.map((item) => ({
                  ...item,
                  type: questions.find((question) => question.id === item.questionId)
                    ?.type,
                }))
              : item.smartFormAnswers,
        })),
      };
    });
  }

  // download the offers xlsx file
  async downloadXLSX(id) {
    const {data} = await axiosInstance.get(`public/rfp/buyer/${id}/offers-xlsx`, {
      responseType: "blob",
    });
    return data;
  }

  // ****** WORKING WITH POSTS ******

  // getting all common settings
  async getCommonSettings() {
    try {
      const {data} = await axiosInstance.get("public/common-settings");
      this.commonSettings = data;
    } catch (e) {
      throw e;
    }
  }
}

const publicStore = new PublicStore();
export default publicStore;

import React from "react";
import {Formik} from "formik";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
//local
import {Header} from "components/Header/Header";
import {SettingsForm} from "./components/SettingsForm";
import {ID_IDENTIFY, MAIN_PAGE} from "constants/routes.const";
import {onboardingModalNames} from "constants/modal.conts";
import {schema, initialValues} from "./formAttrs";
import {InfoTooltip} from "components/InfoTooltip";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";

const OnboardingPage = inject("store")(
  observer(({store: {auth}}) => {
    const {t} = useTranslation();
    let navigate = useNavigate();

    const onSubmit = (values) => {
      auth.sendForm(values).then((data) => {
        if (data?.redirectHome) auth.checkToken().then(() => navigate(MAIN_PAGE));
        else if (data?.showListOfExistingOrganizations)
          auth.updateAuthModalName(onboardingModalNames.joining);
        else auth.checkToken().then(() => navigate(ID_IDENTIFY));
      });
    };

    const logout = () => {
      localStorage.clear();
      navigate("/");
      window.location.reload();
    };

    return (
      <div className="settings-page_wrapper">
        <Header addClass="settings-page__header" type="language" />

        <div className="settings-page__info">
          <div className="settings-page__title">
            <h1 className="custom-title">{t("LOGIN_HEADING")}</h1>
            <p className="custom-text">{t("LOGIN_TITLE")}</p>
          </div>
          <div className="settings-page__form">
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {({values, setFieldValue}) => (
                <SettingsForm values={values} setFieldValue={setFieldValue} />
              )}
            </Formik>
          </div>
        </div>

        <div className="settings-page__emergency-logout">
          <div className="info-text">
            <div className="mr-2">{t("INFO_TEXT_BAD_LOGINS")}</div>
            <InfoTooltip tooltip="INFO_BAD_LOGINS" />
          </div>
          <ButtonTransparent
            name={t("LOGOUT_EMERGENCY_LOG_OUT_OF_HANDYDAY")}
            onClick={logout}
            addClass="__danger"
          />
        </div>
      </div>
    );
  })
);

export default OnboardingPage;

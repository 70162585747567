import React from "react";
import {useNavigate} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {Header} from "components/Header/Header";
import {useTranslation} from "react-i18next";
import InviteOnboardingSteps from "./InviteOnboardingSteps";
import Modals from "components/modals";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {MAIN_PAGE} from "constants/routes.const";

const InviteOnboarding = inject("store")(
  observer(({store: {auth, organization}}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {modalName} = auth;

    if (modalName) return <Modals onClick={() => auth.updateAuthModalName()} />;

    return (
      <div className="inviteOnboarding_wrapper">
        <Header addClass="inviteOnboarding_header" type="language" />

        <p className="inviteOnboarding_intro custom-text">
          {t("LOGIN_TITLE_FOR_INVITED_1")}
        </p>
        <p className="inviteOnboarding_intro custom-text">
          {t("LOGIN_TITLE_FOR_INVITED_2_A")}
          <span style={{fontWeight: "bold"}}> {t("LOGIN_TITLE_FOR_INVITED_2_B")} </span>
          {t("LOGIN_TITLE_FOR_INVITED_2_C")}
        </p>
        <p className="inviteOnboarding_intro custom-text">
          {t("LOGIN_TITLE_FOR_INVITED_3")}
        </p>

        <div className="login-instead">
          {t("TOKEN_ONBOARDING_GOT_ACCOUNT_TEXT_1")}
          <ButtonTransparent name={t("LOGIN_HERE")} onClick={() => navigate(MAIN_PAGE)} />
          {t("TOKEN_ONBOARDING_GOT_ACCOUNT_TEXT_2")}
        </div>

        <p className="inviteOnboarding_intro custom-text smaller">
          {t("LOGIN_TITLE_FOR_INVITED_BANKID_INFO")}
        </p>

        <div className="inviteOnboarding_steps__wrapper">
          <InviteOnboardingSteps organization={organization} />
        </div>
      </div>
    );
  })
);

export default InviteOnboarding;

import React, {memo} from "react";

export const IconMenuCircle = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 20C6.962 20 4.577 19.012 2.782 17.218C0.987 15.424 0 13.038 0 10.501C0 7.963 0.988 5.578 2.782 3.783C4.576 1.988 6.962 1 9.5 1C12.038 1 14.423 1.988 16.218 3.783C18.013 5.578 19 7.963 19 10.501C19 13.039 18.012 15.424 16.218 17.218C14.424 19.012 12.038 20 9.5 20ZM9.5 2C4.813 2 1 5.813 1 10.5C1 15.187 4.813 19 9.5 19C14.187 19 18 15.187 18 10.5C18 5.813 14.187 2 9.5 2Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M14.5 8H4.5C4.224 8 4 7.776 4 7.5C4 7.224 4.224 7 4.5 7H14.5C14.776 7 15 7.224 15 7.5C15 7.776 14.776 8 14.5 8Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M14.5 11H4.5C4.224 11 4 10.776 4 10.5C4 10.224 4.224 10 4.5 10H14.5C14.776 10 15 10.224 15 10.5C15 10.776 14.776 11 14.5 11Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M14.5 14H4.5C4.224 14 4 13.776 4 13.5C4 13.224 4.224 13 4.5 13H14.5C14.776 13 15 13.224 15 13.5C15 13.776 14.776 14 14.5 14Z"
        fill={color || "#1BA2CC"}
      />
    </svg>
  );
});

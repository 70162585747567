import React, {memo} from "react";

export const IconEllipsis = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.5" stroke={color || "#1BA2CC"} />
      <circle cx="14" cy="10" r="0.5" fill="black" stroke={color || "#1BA2CC"} />
      <circle cx="10" cy="10" r="0.5" fill="black" stroke={color || "#1BA2CC"} />
      <circle cx="6" cy="10" r="0.5" fill="black" stroke={color || "#1BA2CC"} />
    </svg>
  );
});

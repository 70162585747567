import React, {memo} from "react";

export const IconEye = memo(({color, height, width, className}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.872 10.166C19.825 10.113 18.69 8.86101 16.916 7.59401C15.869 6.84601 14.816 6.25001 13.786 5.82101C12.481 5.27701 11.207 5.00101 10 5.00101C8.79301 5.00101 7.51901 5.27701 6.21401 5.82101C5.18401 6.25001 4.13101 6.84701 3.08401 7.59401C1.31001 8.86101 0.175006 10.114 0.128006 10.166C-0.0429941 10.356 -0.0429941 10.645 0.128006 10.835C0.175006 10.888 1.31001 12.14 3.08401 13.407C4.13101 14.155 5.18401 14.751 6.21401 15.18C7.51901 15.724 8.79301 16 10 16C11.207 16 12.481 15.724 13.786 15.18C14.816 14.751 15.869 14.154 16.916 13.407C18.69 12.14 19.825 10.887 19.872 10.835C20.043 10.645 20.043 10.356 19.872 10.166ZM12.574 6.43801C13.481 7.20101 14 8.31101 14 9.50001C14 11.706 12.206 13.5 10 13.5C7.79401 13.5 6.00001 11.706 6.00001 9.50001C6.00001 8.31201 6.51901 7.20101 7.42601 6.43801C8.24801 6.17001 9.11701 6.00001 10 6.00001C10.883 6.00001 11.752 6.17001 12.574 6.43801ZM16.317 12.606C14.784 13.698 12.444 15 10 15C7.55601 15 5.21601 13.698 3.68301 12.606C2.52601 11.782 1.64101 10.948 1.19401 10.5C1.64101 10.052 2.52601 9.21901 3.68301 8.39401C4.21301 8.01601 4.83901 7.61401 5.53301 7.24901C5.18601 7.93701 5.00001 8.70401 5.00001 9.50001C5.00001 12.257 7.24301 14.5 10 14.5C12.757 14.5 15 12.257 15 9.50001C15 8.70401 14.814 7.93701 14.467 7.24901C15.161 7.61401 15.787 8.01701 16.317 8.39401C17.474 9.21801 18.359 10.052 18.806 10.5C18.359 10.948 17.474 11.781 16.317 12.606Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

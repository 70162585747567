import React from "react";
import {useTranslation} from "react-i18next";
//local
import {IconBankIdLogo} from "components/icons";
import {dateTimeFormat} from "helpers/datetime";

export const ContractContactInfo = ({person}) => {
  const {t} = useTranslation();

  return (
    <div className="mb-4">
      <div>
        <span className="__bold">{person.name}</span>
        <span className="pl-3">{person.personalNumber}</span>
      </div>
      <div className="df-row-center mt-2">
        <IconBankIdLogo />
        <div className="pl-2 pr-2">{t("SIGN_WITH_BANKID")}</div>
        <span className="discrete-text">{dateTimeFormat(person.updatedAt)}</span>
      </div>
    </div>
  );
};

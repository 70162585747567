import React, {useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
// local
import {Modal} from "components/Modal";
import {IconHourglass, IconTrumbsDown, IconTrumbsUp} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import FormikField from "components/FormikField";

export const RftProposalInterestedStatusModal = ({
  interested,
  onButtonClick,
  onClose,
}) => {
  const {t} = useTranslation();
  const [isNotInterestedMessageBlock, setIsNotInterestedMessageBlock] = useState(false);
  const [interestedStatus, setInterestedStatus] = useState(interested);

  const disabledButton = useMemo(
    () => interestedStatus === false && isNotInterestedMessageBlock,
    [interestedStatus, isNotInterestedMessageBlock]
  );

  const handleClick = (value) => {
    setInterestedStatus(value);
    if (value === false) setIsNotInterestedMessageBlock(true);
    else {
      value !== interested && onButtonClick({interested: value});
      onClose();
    }
  };

  const onSubmit = async (values) => {
    if (interestedStatus !== interested && !!values.rejectMessage) {
      onButtonClick({interested: false, rejectMessage: values.rejectMessage});
      onClose();
    }
  };

  return (
    <Modal
      titleText="RFT_PROPOSAL_INTEREST_STATUS_MODAL_TITLE"
      noTitle
      customTitle
      noCenterContent
      boldTitle
      rounded
      style={{marginTop: "15vh", width: "fit-content"}}
      onClose={onClose}
    >
      <div className="pa-8">
        <span style={{whiteSpace: "pre-wrap"}}>
          {t("RFT_PROPOSAL_INTEREST_STATUS_MODAL_DESCRIPTION")}
        </span>

        <Formik initialValues={{rejectMessage: ""}} onSubmit={onSubmit}>
          {({values}) => (
            <Form>
              {isNotInterestedMessageBlock && (
                <FormikField
                  name="rejectMessage"
                  placeholder={t("REMOVE_QUALIFICATION_ANSWERS_MODAL_PLACEHOLDER")}
                  errorName="rejectMessage"
                  autoSize={{maxRows: 6}}
                  maxLength="1000"
                  rows={6}
                  addClass="mt-4 mb-0"
                  textarea
                  textareaValue={values.rejectMessage}
                />
              )}
              <div className="df mt-8">
                <ButtonTransparent
                  small
                  nowrap
                  color="black"
                  type="button"
                  name="RFT_PROPOSAL_INTEREST_STATUS_MODAL_NO_RESPONSE"
                  nonTransparent={interestedStatus === null}
                  disabled={disabledButton}
                  icon={<IconHourglass />}
                  onClick={() => handleClick(null)}
                />
                <ButtonTransparent
                  addClass="mr-2 ml-2"
                  small
                  nowrap
                  color="red"
                  type={disabledButton ? "submit" : "button"}
                  nonTransparent={interested === false}
                  name={
                    disabledButton
                      ? "SEND"
                      : "RFT_PROPOSAL_INTEREST_STATUS_MODAL_NOT_INTERESTED"
                  }
                  icon={<IconTrumbsDown />}
                  onClick={() => handleClick(false)}
                />
                <ButtonTransparent
                  small
                  nowrap
                  color="green"
                  type="button"
                  name="RFT_PROPOSAL_INTEREST_STATUS_MODAL_INTERESTED"
                  disabled={disabledButton}
                  nonTransparent={interestedStatus === true}
                  icon={<IconTrumbsUp />}
                  onClick={() => handleClick(true)}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

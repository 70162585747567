import React from "react";
import {Switch} from "antd";
import cn from "classnames";
import {InfoTooltip} from "components/InfoTooltip";

const CustomRadioSwitch = ({
  label,
  small,
  isActive,
  addClass,
  addWrapperClass,
  wrapperStyle,
  uppercase,
  mediumText,
  largeText,
  bold,
  redOnColor,
  checked,
  disabled,
  defaultChecked,
  isDisabledLabel,
  informationText,
  prefixLabelContent,
  prefixLabelDistance,
}) => {
  return (
    <div
      className={cn("custom-switch_wrapper", {
        [addWrapperClass]: !!addWrapperClass,
      })}
      style={wrapperStyle}
    >
      <Switch
        className={cn("selections-group_switch__check custom-switch-check", {
          [addClass]: !!addClass,
          "__red-on": !!redOnColor,
        })}
        size={small ? "small" : "default"}
        checked={checked}
        disabled={disabled}
        defaultChecked={defaultChecked}
        onChange={isActive}
      />
      {prefixLabelContent}
      {label && (
        <label
          className={cn("custom-switch_label", {
            "ml-2": !prefixLabelDistance, // Apply the ml-2 class if prefixLabelDistance is not provided
            __uppercase: !!uppercase,
            __small: !!small,
            __medium: !!mediumText,
            __large: !!largeText,
            __bold: !!bold || (!!small && !mediumText),
            __grey: isDisabledLabel,
          })}
          style={prefixLabelDistance ? {marginLeft: prefixLabelDistance} : {}}
        >
          {label}
        </label>
      )}
      {informationText && (
        <InfoTooltip tooltip={informationText} centerText addClass="ml-2" />
      )}
    </div>
  );
};

export default CustomRadioSwitch;

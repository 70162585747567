import React from "react";
// local
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {IconCross, IconCheck} from "components/icons";
import {roles} from "constants/auth.const";
import {color} from "constants/color.consts";

const AgreementCardSwitch = ({
  role,
  checked,
  onClick,
  activeLabel,
  inactiveLabel,
  inactiveMemberLabel,
}) => {
  const ownerLabel = checked ? activeLabel : inactiveLabel;
  const memberLabel = checked ? activeLabel : inactiveMemberLabel;
  const roleMember = role === roles.MEMBER;

  return (
    <React.Fragment>
      {roleMember ? (
        <div className="df-row-center">
          {checked ? (
            <IconCheck addClass="mr-2" color={color.green} />
          ) : (
            <IconCross addClass="mr-2" color={color.red} />
          )}
          {memberLabel}
        </div>
      ) : (
        <CustomRadioSwitch
          label={ownerLabel}
          checked={checked}
          mediumText
          isDisabledLabel={!checked}
          isActive={onClick}
        />
      )}
    </React.Fragment>
  );
};

export default AgreementCardSwitch;

import React from "react";

export const IconLinkDalux = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2" fill="white" />
      <rect x="5" y="9" width="10" height="8" fill="#8DC63F" />
      <path d="M3.43947 8.98214H1L10 3V4.64286L3.43947 8.98214Z" fill="#3E5867" />
      <path d="M16.5605 9H19L10 3.01786V4.66071L16.5605 9Z" fill="#3E5867" />
    </svg>
  );
};

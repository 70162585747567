import React, {memo} from "react";

export const IconBubbleText = memo(({color, addClass}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" className={addClass}>
      <path
        d="M0.500176 19C0.275176 19 0.0781761 18.85 0.0181761 18.633C-0.0418239 18.416 0.0501761 18.186 0.243176 18.071C1.93418 17.057 2.63518 15.582 2.88418 14.892C1.04618 13.485 0.000176137 11.538 0.000176137 9.49999C0.000176137 8.47099 0.258176 7.47399 0.768176 6.53599C1.25418 5.64199 1.94818 4.84099 2.82918 4.15499C4.61618 2.76499 6.98518 1.99899 9.50018 1.99899C12.0152 1.99899 14.3842 2.76499 16.1712 4.15499C17.0522 4.83999 17.7462 5.64099 18.2322 6.53599C18.7422 7.47299 19.0002 8.46999 19.0002 9.49999C19.0002 10.53 18.7422 11.526 18.2322 12.464C17.7462 13.358 17.0522 14.159 16.1712 14.845C14.3842 16.235 12.0152 17.001 9.50018 17.001C8.46718 17.001 7.45318 16.872 6.48418 16.616C6.05518 16.902 5.25318 17.409 4.29518 17.886C2.80718 18.626 1.53118 19.001 0.501176 19.001L0.500176 19ZM9.50018 2.99999C4.81318 2.99999 1.00018 5.91599 1.00018 9.49999C1.00018 11.315 2.00518 13.062 3.75618 14.292C3.92818 14.413 4.00618 14.628 3.95218 14.831C3.83518 15.267 3.43718 16.464 2.37218 17.619C3.67418 17.163 5.07618 16.372 6.11118 15.66C6.23418 15.575 6.38818 15.55 6.53218 15.591C7.48018 15.862 8.47918 16 9.50018 16C14.1872 16 18.0002 13.084 18.0002 9.49999C18.0002 5.91599 14.1872 2.99999 9.50018 2.99999Z"
        fill={color || "#000"}
      />
      <path
        d="M13.5 7H5.5C5.224 7 5 6.776 5 6.5C5 6.224 5.224 6 5.5 6H13.5C13.776 6 14 6.224 14 6.5C14 6.776 13.776 7 13.5 7Z"
        fill={color || "#000"}
      />
      <path
        d="M13.5 9H5.5C5.224 9 5 8.776 5 8.5C5 8.224 5.224 8 5.5 8H13.5C13.776 8 14 8.224 14 8.5C14 8.776 13.776 9 13.5 9Z"
        fill={color || "#000"}
      />
      <path
        d="M13.5 11H5.5C5.224 11 5 10.776 5 10.5C5 10.224 5.224 10 5.5 10H13.5C13.776 10 14 10.224 14 10.5C14 10.776 13.776 11 13.5 11Z"
        fill={color || "#000"}
      />
      <path
        d="M11.5 13H5.5C5.224 13 5 12.776 5 12.5C5 12.224 5.224 12 5.5 12H11.5C11.776 12 12 12.224 12 12.5C12 12.776 11.776 13 11.5 13Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
// local
import {ButtonTransparent} from "../../../buttons/ButtonTransparent";
import FormikField from "components/FormikField";
import {RadioGroup} from "components/RadioGroup";
import {schema, initialValues} from "./invoicingFormAttrs";
import {Modal} from "components/Modal";
import {LabelData} from "components/LabelData";
import {IconMailLetter} from "components/icons";
import {hasTranslation, createLanguageKeysArray, subscriptionPrice} from "helpers/helper";
import {subscriptionPriceType, subscriptionPricePeriods} from "constants/user.consts";
import CustomRadioSwitch from "components/CustomRadioSwitch";

export const SubscriptionModalOwner = ({
  currentSubscription,
  organization,
  username,
  email,
  reference,
  onClose,
  onClickSubmit,
}) => {
  const {t} = useTranslation();

  const [activePeriod, setActivePeriod] = useState(
    currentSubscription.pricePeriod ?? currentSubscription.pricePeriodDefault
  );
  const isSinglePeriod = useMemo(
    () =>
      subscriptionPricePeriods.filter(
        (item) => currentSubscription[item.priceKey] !== null
      ).length < 2,
    [currentSubscription]
  );

  const radioValues = useMemo(
    () =>
      subscriptionPricePeriods.map((item) => ({
        ...item,
        value: item.periodKey,
        label: `SUBSCRIPTION_TAB_MODAL_CONFIRM_${item.periodKey}_PRICE_EXPLANATION`,
        checked: item.periodKey === activePeriod,
      })),
    [activePeriod]
  );

  const subscriptionOptions = useMemo(
    () =>
      createLanguageKeysArray(
        currentSubscription.optionsCount,
        `${currentSubscription.name}_OPTION`
      ),
    [currentSubscription]
  );

  const AdditionalSubscriptionNameData = () => (
    <React.Fragment>
      <div className="fz-13 mt-2">{t(currentSubscription.descriptionKey)}</div>
      <ul className="pl-3 mt-4">
        {!!subscriptionOptions?.length &&
          subscriptionOptions
            .filter((item) => hasTranslation(item))
            .map((item) => (
              <li className="subscription__option" key={item}>
                <span className="truncate-me">{t(item)}</span>
              </li>
            ))}
      </ul>
    </React.Fragment>
  );

  const SubscriptionSinglePriceData = () => (
    <React.Fragment>
      <div className="mb-2">{`${subscriptionPrice(currentSubscription, activePeriod)} ${t(
        subscriptionPriceType[currentSubscription.priceType] ??
          subscriptionPriceType.PRICE_PER_MONTH
      )}`}</div>
    </React.Fragment>
  );

  const SubscriptionMultiPriceData = () => (
    <RadioGroup
      values={radioValues}
      direction="horizontal"
      resultValue={setActivePeriod}
      bottomData={subscriptionPricePeriods.map(
        (item) =>
          `${currentSubscription[item.priceKey]?.replace(".00", "")} ${t(
            subscriptionPriceType[currentSubscription.priceType] ??
              subscriptionPriceType.PRICE_PER_MONTH
          )}`
      )}
      addClassDataWrapper="pa-2"
      addClassItemDataWrapper={subscriptionPricePeriods.map((item) =>
        item.periodKey === activePeriod ? "border-link" : "border-hover"
      )}
      addClassBottomData="mt-1"
    />
  );

  const onSubmit = (values, {resetForm}) =>
    onClickSubmit({...values, pricePeriod: activePeriod}).then(() => {
      resetForm();
      onClose();
    });

  return (
    <Modal
      titleText={"SUBSCRIPTION_TAB_MODAL_CONFIRM_HEADING"}
      hugeTitle
      boldTitle
      titleIcon={null}
      noCloseButton
      noCloseOnEsc
      width={700}
      rounded
      whiteTitle
      addClass="subscriptions-modal"
      onClose={onClose}
    >
      <Formik
        initialValues={{...initialValues, email, reference}}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({values, setFieldValue}) => (
          <Form>
            <div className="df-row-jsb" style={{width: "100%"}}>
              <div className="mobile-column mr-8">
                <LabelData
                  label="SUBSCRIPTION_TAB_MODAL_CONFIRM_LABEL_01"
                  data={t(currentSubscription.name)}
                  boldData
                  additionalData={<AdditionalSubscriptionNameData />}
                  addClass="mb-8"
                />
                <LabelData
                  label="SUBSCRIPTION_TAB_MODAL_CONFIRM_LABEL_02"
                  data={
                    isSinglePeriod ? (
                      <SubscriptionSinglePriceData />
                    ) : (
                      <SubscriptionMultiPriceData />
                    )
                  }
                  explanation={`SUBSCRIPTION_TAB_MODAL_CONFIRM_${activePeriod}_PRICE_EXPLANATION`}
                  explanationInfo={`SUBSCRIPTION_TAB_MODAL_CONFIRM_${activePeriod}_PRICE_EXPLANATION_INFO`}
                  addClass="mb-8"
                />
              </div>
              <div className="mobile-column">
                <LabelData
                  label="SUBSCRIPTION_TAB_MODAL_CONFIRM_LABEL_03"
                  data={
                    <React.Fragment>
                      {organization.Company.companyName}
                      <div className="mt-1 fz-12">
                        {organization.Company.companyNumber}
                      </div>
                    </React.Fragment>
                  }
                  boldData
                  addClass="mb-4"
                />
                <FormikField
                  name="email"
                  label={t("SUBSCRIPTION_TAB_MODAL_CONFIRM_LABEL_05")}
                  labelInfo={t("SUBSCRIPTION_TAB_MODAL_CONFIRM_LABEL_05_INFO")}
                  labelDataMode
                  markUnfilled={!values.email}
                  width="230px"
                  placeholder={t("TYPE_TO_ADD_EMAIL")}
                  errorName="email"
                  className="input"
                  addClass="mb-4"
                  prefixIcon={<IconMailLetter />}
                />
                <FormikField
                  name="reference"
                  label={t("SUBSCRIPTION_TAB_MODAL_CONFIRM_LABEL_06")}
                  labelInfo={t("SUBSCRIPTION_TAB_MODAL_CONFIRM_LABEL_06_INFO")}
                  labelDataMode
                  width="230px"
                  placeholder={t("TYPE_TO_ADD_REFERENCE")}
                  className="input"
                  addClass="mb-4"
                />
                <FormikField
                  name="discountCode"
                  label={t("SUBSCRIPTION_TAB_MODAL_CONFIRM_LABEL_07")}
                  labelDataMode
                  labelInfo={t("SUBSCRIPTION_TAB_MODAL_CONFIRM_LABEL_07_INFO")}
                  width="230px"
                  placeholder={t("TYPE_TO_ADD_POSSIBLE_CODE")}
                  className="input"
                  addClass="mb-4"
                />
              </div>
            </div>
            <CustomRadioSwitch
              label={`${t("I_REPRESENT")} ${username} ${t("REPRESENT")} ${
                organization.Company.companyName
              }`}
              small
              mediumText
              checked={values.isRepresent}
              isActive={(checked) => setFieldValue("isRepresent", checked)}
            />
            <CustomRadioSwitch
              label={
                <React.Fragment>
                  {t("SUBSCRIPTION_TAB_MODAL_CONFIRM_SWITCH_02_01")}
                  <a
                    href="https://www.handyday.com/allmanna-villkor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("SUBSCRIPTION_TAB_MODAL_CONFIRM_SWITCH_02_02")}
                  </a>
                </React.Fragment>
              }
              small
              mediumText
              addWrapperClass="mb-6 mt-4"
              checked={values.isTerms}
              isActive={(checked) => setFieldValue("isTerms", checked)}
            />
            <div className="subscriptions-modal__buttons">
              <ButtonTransparent
                medium
                name={t("CANCEL")}
                onClick={onClose}
                color="red"
              />
              <ButtonTransparent
                type="submit"
                addClass="ml-4"
                name={t("SUBSCRIPTION_TAB_MODAL_SUBSCRIBE_BUTTON")}
                disabled={!(values.email && values.isRepresent && values.isTerms)}
                medium
                nonTransparent
              />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

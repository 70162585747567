export const color = {
  black: "#000000",
  blue: "#1ba2cc",
  link: "#1ba2cc",
  midGray: "#a9a9a9",
  lightGray: "#d8d8d8",
  lightYellow: "#ffff84",
  darkGray: "#3e3e3e",
  whiteGray: "#efefef",
  white: "#ffffff",
  gold: "#ffce54",
  dimmedOpaqueWhite: "rgba(255, 255, 255, 0.6)",
  offWhite: "#fafafa",
  discreteText: "#626262",
  red: "#ba2e2e",
  pink: "#F8EAEA",
  green: "#6bc069",
  whiteGreen: "#f0f9f0",
  orange: "#c17e2e",
  purple: "#ac4dc4",
  iconGradient: "linear-gradient(180deg, #1ba2cc 0%, #6bc069 100%)",
  linkOpaqueBg: "rgba(27, 162, 204, 0.05)",
  linkHover: "rgba(27, 162, 204, 0.1)",
  greenLightOpaque: "rgba(107, 192, 105, 0.1)",
  redLightOpaque: "rgba(186, 46, 46, 0.1)",
  blackLightOpaque: "rgba(0, 0, 0, 0.03)",
  searchBorder: "#e2e2e2",
};

import * as yup from "yup";
import {stringRequired} from "../../../../../yup/defaultValudations";

export const schema = yup.object().shape({
  CountryId: stringRequired(),
  companyNumber: stringRequired().when("CountryId", (CountryId, schema) =>
    CountryId === "752"
      ? // eslint-disable-next-line
        schema.matches(/^[\d]{6}\-[\d]{4}$/, "The field is not completely filled.")
      : schema
  ),
  companyName: stringRequired(),
  // subscription: stringRequired(),
});

export const initialValues = {
  companyNumber: "",
  companyName: "",
  CountryId: "752",
  // subscription: "SUBSCRIPTION_NAME_PRICE_01",
};

import React from "react";
import {useTranslation} from "react-i18next";
// local
import {formattedNumber} from "helpers/number.formats";

const UnpaidDebitItem = ({title, date, amount, total}) => {
  const {t} = useTranslation();
  const isValue = (value) => value === null || value === undefined;
  return (
    <div className="custom-table_body__item" key={title}>
      <div className="custom-table_body__date" data-title={t("DATE")}>
        {date}
      </div>
      <div className="custom-table_body__value" data-title={t("TYPE")}>
        {title}
      </div>
      <div className="custom-table_body__value value" data-title={t("TOTAL_AMOUNT")}>
        {isValue(total) ? "-" : formattedNumber(Math.round(+total))}
      </div>
      <div className="custom-table_body__value count" data-title={t("AMOUNT")}>
        {isValue(amount) ? "-" : +amount}
      </div>
    </div>
  );
};

export default UnpaidDebitItem;

import React, {memo} from "react";

export const IconFunnel = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={height || "20"}
      height={width || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.23 3.307C15.834 3.039 15.281 2.803 14.587 2.605C13.221 2.215 11.415 2 9.5 2C7.585 2 5.778 2.215 4.413 2.605C3.719 2.803 3.167 3.039 2.77 3.307C2.133 3.737 2 4.193 2 4.5V5C2 5.428 2.321 6.133 2.639 6.609L7.53 13.945C7.781 14.321 8.001 15.048 8.001 15.5V18.5C8.001 18.673 8.091 18.834 8.238 18.925C8.318 18.975 8.409 19 8.501 19C8.577 19 8.654 18.982 8.725 18.947L10.725 17.947C10.894 17.862 11.001 17.689 11.001 17.5V15.5C11.001 15.048 11.221 14.321 11.472 13.945L16.363 6.609C16.68 6.133 17.002 5.427 17.002 5V4.5C17.002 4.193 16.868 3.737 16.232 3.307H16.23ZM4.688 3.567C5.967 3.202 7.676 3 9.5 3C11.324 3 13.034 3.201 14.312 3.567C15.69 3.961 16 4.383 16 4.5C16 4.617 15.69 5.04 14.312 5.433C13.033 5.798 11.324 6 9.5 6C7.676 6 5.966 5.799 4.688 5.433C3.31 5.039 3 4.617 3 4.5C3 4.383 3.31 3.96 4.688 3.567ZM10.639 13.391C10.281 13.928 10 14.855 10 15.5V17.191L9 17.691V15.5C9 14.854 8.719 13.928 8.361 13.391L3.481 6.071C3.755 6.188 4.066 6.297 4.413 6.395C5.779 6.785 7.585 7 9.5 7C11.415 7 13.222 6.785 14.587 6.395C14.933 6.296 15.245 6.188 15.519 6.07L10.639 13.39V13.391Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React from "react";

export const IconLinkByggmastargruppen = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2" fill="#F18C00" />
      <path
        d="M10.12 14.24C11.56 14.24 12.52 13.12 12.52 11.84C12.52 10.72 11.56 9.44 10.12 9.44C8.68 9.44 7.88 10.72 7.88 11.84C7.88 13.12 8.68 14.24 10.12 14.24ZM5 4H7.88V8C8.84 6.88 10.28 6.88 10.6 6.88C12.68 6.88 15.24 8.32 15.24 11.84C15.24 15.2 13 16.96 10.6 16.96C9.16 16.96 8.2 16.32 7.88 15.68H7.72V16.64H5V4Z"
        fill="white"
      />
    </svg>
  );
};

import React, {useState} from "react";
import {inject, observer} from "mobx-react";
// local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconDownload} from "components/icons/index";
import {openNewTab} from "helpers/helper";
import {InfoTooltip} from "components/InfoTooltip";

const DownloadZip = inject("store")(
  observer(({store: {clientStore}, docs, disabled}) => {
    const [link, setLink] = useState();
    const [loading, setLoading] = useState(false);

    const getZipLink = async () => {
      setLoading(true);
      const files = docs.map((item) => {
        return {
          fileName: item.name || item.File?.name,
          key:
            item.s3responseRaw?.Key ||
            item.s3responseRaw?.key ||
            item.File?.s3responseRaw?.key ||
            item.File?.s3responseRaw?.Key,
        };
      });
      clientStore
        .downloadZIP(files)
        .then((data) => {
          setLoading(false);
          setLink(data.Location);
          openNewTab(data.Location);
        })
        .finally(() => setLoading(false));
    };

    return (
      <React.Fragment>
        <ButtonTransparent
          icon={<IconDownload />}
          name={".zip"}
          small
          disabled={disabled}
          onClick={() => (link ? openNewTab(link) : getZipLink())}
          loading={!link && loading}
          addClass="mr-1"
          style={{width: loading && "70px"}}
        />

        {disabled && <InfoTooltip tooltip="TOOLTIP_WAITING_FOR_ZIP_BUTTON" centerText />}
      </React.Fragment>
    );
  })
);

export default DownloadZip;

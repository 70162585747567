import React, {useState} from "react";
import {useTranslation} from "react-i18next";
// local
import {Modal} from "components/Modal";
import Divider from "components/Divider";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {Input} from "components/Input";

export const RevisionModal = ({revision, loading, onSave, onDelete, onClose}) => {
  const {t} = useTranslation();
  const [name, setName] = useState(revision.name);

  return (
    <Modal
      titleText={revision.name ?? "REVISION_MODAL_TITLE_NEW"}
      noTitle
      boldTitle
      width={380}
      rounded
      noCenterContent
      style={{marginTop: "15vh"}}
      onClose={onClose}
    >
      <div className="procurement-create-edit-modal__content">
        <Input
          value={name}
          fullWidth
          placeholder={t("REVISION_MODAL_INPUT_PLACEHOLDER")}
          className="input pl-2"
          onChange={(e) => setName(e.target.value)}
        />
        {!!revision.id && <Divider addClass="mt-4 mb-4" dotted />}
        {!!revision.id && (
          <ButtonTransparent
            name="REVISION_MODAL_DELETE_BUTTON"
            small
            color="red"
            loading={loading}
            onClick={onDelete}
          />
        )}
        <div className="df-row-jce-center mt-8">
          <ButtonTransparent
            name="CANCEL"
            color="red"
            onClick={onClose}
            disabled={loading}
            addClass="mr-4"
          />
          <ButtonTransparent
            disabled={!name}
            name="SAVE"
            loading={loading}
            onClick={() => onSave({name})}
          />
        </div>
      </div>
    </Modal>
  );
};

import React from "react";
// local
import {
  IconStandPhone,
  IconMailLetter,
  IconUserPerson,
  IconMapMarker,
  IconTags,
} from "components/icons";
import {InitialsBadge} from "components/InitialsBadge";
import {getFullName} from "helpers/helper";
import {color} from "constants/color.consts";
import cn from "classnames";

const ContactCard = ({
  contact,
  hasInitials,
  maxWidthFitContent,
  blackContent,
  addClass,
}) => {
  return (
    <div
      className={cn("contact-person_item", {
        "__fit-content": !!maxWidthFitContent,
        "__black-text": !!blackContent,
        [addClass]: !!addClass,
      })}
    >
      <section className="contact-person_item__name-and-position">
        <div className="contact-person_item__name">
          {hasInitials ? (
            <InitialsBadge
              firstName={contact.User?.firstName}
              lastName={contact.User?.lastName}
              addClass="mr-2"
            />
          ) : (
            <IconUserPerson color={blackContent ? color.black : color.darkGray} />
          )}
          <span>{getFullName(contact.User?.firstName, contact.User?.lastName)}</span>
        </div>
        {contact.position && (
          <div className="contact-person_item__position">
            <span>{contact.position}</span>
          </div>
        )}
      </section>
      {contact.email && (
        <div className="contact-person_item__mail">
          <IconMailLetter color={blackContent ? color.black : color.darkGray} />
          <span>{contact.email}</span>
        </div>
      )}
      {(contact.phone || contact.mobilePhone) && (
        <div className="contact-person_item__phone">
          <IconStandPhone color={blackContent ? color.black : color.darkGray} />
          <span>{contact.phone || contact.mobilePhone}</span>
        </div>
      )}
      {contact.workKinds && (
        <div className="contact-person_item__work-kinds">
          <IconTags color={blackContent ? color.black : color.darkGray} />
          <span>{contact.workKinds}</span>
        </div>
      )}
      {contact.location && (
        <div className="contact-person_item__location">
          <IconMapMarker color={blackContent ? color.black : color.darkGray} />
          <span>{contact.location}</span>
        </div>
      )}
    </div>
  );
};

export default ContactCard;

import React, {memo} from "react";

export const IconID06 = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      className={`${addClass ?? ""}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="1.11165" width="19" height="19" rx="1.5" stroke="black" />
      <path
        d="M9.79999 3.11165H12.3722C15.2547 3.11165 17.8 4.65871 17.8 10.6113C17.8 16.5642 15.2547 18.1116 12.3722 18.1116H9.79999V3.11165Z"
        fill={color || "#000"}
      />
      <path
        d="M8.79999 18.1116H2.94063C2.86302 18.1116 2.79999 18.0426 2.79999 17.9569V3.26643C2.79999 3.18067 2.86302 3.11165 2.94063 3.11165H8.79999V18.1116Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React from "react";
import SimpleField from "components/SimpleField";

const EditSimpleInput = ({label, name}) => {
  return (
    <div className="editBlock_field__item">
      {label && <label className="editBlock_field__label">{label}</label>}
      <SimpleField
        className="input edit-insurance_field__input"
        name={name}
        errorName={name}
      />
    </div>
  );
};

export default EditSimpleInput;

import React from "react";

export const IconRing5170 = ({height, width, addClass}) => {
  return (
    <svg
      width={width || "120"}
      height={height || "120"}
      viewBox="0 0 120 120"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60 6C60 2.68629 62.693 -0.0310848 65.9901 0.299735C74.953 1.19903 83.6257 4.10819 91.3499 8.84159C100.785 14.6232 108.437 22.9013 113.46 32.7606C118.484 42.6199 120.683 53.6763 119.815 64.7075C118.947 75.7388 115.045 86.3151 108.541 95.2671C102.037 104.219 93.1841 111.198 82.961 115.433C72.738 119.667 61.543 120.992 50.6139 119.261C39.6848 117.53 29.4473 112.811 21.0331 105.624C14.1445 99.7409 8.6977 92.3917 5.07273 84.1454C3.73923 81.1118 5.49143 77.7109 8.64295 76.6869V76.6869C11.7945 75.6629 15.1458 77.4094 16.5548 80.4087C19.4457 86.5628 23.6216 92.0541 28.8265 96.4995C35.5578 102.249 43.7479 106.024 52.4911 107.409C61.2344 108.794 70.1904 107.734 78.3688 104.346C86.5472 100.959 93.6296 95.3753 98.8328 88.2137C104.036 81.0521 107.157 72.591 107.852 63.766C108.547 54.9411 106.787 46.0959 102.768 38.2085C98.7495 30.321 92.6277 23.6986 85.0799 19.0733C79.2437 15.4969 72.7307 13.2222 65.9845 12.3745C62.6967 11.9614 60 9.31371 60 6V6Z"
        fill="#1BA2CC"
      />
      <path
        opacity="0.1"
        d="M60 6C60 2.68629 62.6931 -0.0311306 65.9903 0.299609C79.3845 1.64319 92.0044 7.4637 101.755 16.9124C112.948 27.7594 119.481 42.5364 119.97 58.1154C120.46 73.6943 114.868 88.8524 104.378 100.381C93.8878 111.909 79.3233 118.903 63.7674 119.882C48.2115 120.86 32.8853 115.747 21.0331 105.624C9.18092 95.5016 1.73311 81.164 0.26628 65.6465C-1.20055 50.129 3.42875 34.6497 13.1742 22.4854C21.6634 11.8892 33.4543 4.53285 46.5745 1.52114C49.8042 0.779765 52.8167 3.13822 53.232 6.4258C53.6474 9.71338 51.3036 12.6781 48.0935 13.5C38.0658 16.0674 29.0782 21.8265 22.5394 29.9883C14.743 39.7198 11.0396 52.1032 12.213 64.5172C13.3865 76.9312 19.3447 88.4013 28.8265 96.4995C38.3082 104.598 50.5692 108.688 63.0139 107.905C75.4586 107.122 87.1103 101.527 95.5023 92.3046C103.894 83.0819 108.368 70.9555 107.976 58.4923C107.585 46.0291 102.358 34.2075 93.4038 25.5299C85.8935 18.252 76.2551 13.6648 65.9847 12.3744C62.6968 11.9613 60 9.31371 60 6Z"
        fill="#1BA2CC"
      />
    </svg>
  );
};

import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useLocation} from "react-router-dom";
import {Switch} from "antd";
import {Form, Formik} from "formik";
import {inject, observer} from "mobx-react";
import * as qr from "querystring-es3";
// locale
import {schema, initialValues} from "./formAttrs";
import {Button} from "components/buttons/Button";
import FormikField from "components/FormikField";
import {MAIN_PAGE} from "constants/routes.const";
import {ButtonRed} from "components/buttons/ButtonRed";
import {TERMS_AND_CONDITIONS} from "../../../../constants/documents.consts";

const InviteOnBoardingPage = inject("store")(
  observer(({store: {organization, auth}}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [rejecting, setRejecting] = useState(false);
    const [ifChecked, setIfChecked] = useState(false);
    const location = useLocation();
    let navigate = useNavigate();
    const query = qr.parse(location.search.replace("?", ""));
    const joinRequestId = query["id"];

    const handleSubmit = async (value) => {
      try {
        setLoading(true);
        await auth.updateUser(value);
        await organization.acceptJoinRequest(joinRequestId);
        await auth.checkToken();
        navigate(MAIN_PAGE);
      } finally {
        setLoading(false);
      }
    };

    const handleReject = () => {
      setRejecting(true);
      organization
        .rejectJoinRequest(joinRequestId)
        .then(() => undefined)
        .catch(() => undefined)
        .finally(() => {
          localStorage.clear();
          navigate(MAIN_PAGE);
        });
    };

    return (
      <div className="invite-page_info __wrapper">
        <div className="invite-page_info __form">
          <Formik
            initialValues={{
              ...initialValues,
              email: query["email"],
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({dirty, isValid}) => (
              <Form>
                <FormikField
                  label={t("LOGIN_PLACEHOLDER_FIRST_NAME")}
                  name="firstName"
                  errorName="firstName"
                  placeholder={t("LOGIN_PLACEHOLDER_FIRST_NAME_")}
                />
                <FormikField
                  label={t("LOGIN_PLACEHOLDER_LAST_NAME")}
                  name="lastName"
                  errorName="lastName"
                  placeholder={t("LOGIN_PLACEHOLDER_LAST_NAME")}
                />
                <FormikField
                  type="email"
                  label={t("E_MAIL")}
                  name="email"
                  errorName="email"
                  placeholder={t("LOGIN_PLACEHOLDER_EMAIL")}
                />
                <FormikField
                  label={t("INVITE_ORGANIZATION_NUMBER")}
                  value={query["companyNumber"]}
                  disabled={true}
                />
                <FormikField
                  label={t("INVITE_ORGANIZATION_NAME")}
                  value={query["organizationName"]}
                  disabled={true}
                />
                <div className="invite-page_info __switch">
                  <Switch
                    className="custom-switch-check"
                    onChange={(value) => setIfChecked(value)}
                  />
                  <p>
                    {t("LOGIN_AGREE_FIRST")}
                    <a href={TERMS_AND_CONDITIONS} target="__blank">
                      {t("LOGIN_AGREE_LINK")}
                    </a>
                    {t("LOGIN_AGREE_LAST")}
                  </p>
                </div>
                <div className="invite-page_info __actions">
                  <Button
                    type="submit"
                    loading={loading}
                    name={t("ACCEPT")}
                    disabled={!dirty || !isValid || !ifChecked || rejecting}
                  />
                  <ButtonRed
                    onClick={handleReject}
                    type="button"
                    name={t("REJECT")}
                    loading={rejecting}
                    disabled={loading}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  })
);

export default InviteOnBoardingPage;

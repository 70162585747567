import React, {memo} from "react";

export const IconRefresh = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.854 8.646C19.659 8.451 19.342 8.451 19.147 8.646L17.998 9.795C17.947 7.735 17.12 5.805 15.657 4.343C14.146 2.832 12.137 2 10 2C7.863 2 5.854 2.832 4.343 4.343C2.832 5.854 2 7.863 2 10C2 12.137 2.832 14.146 4.343 15.657C5.854 17.168 7.863 18 10 18C12.974 18 15.686 16.365 17.077 13.734C17.206 13.49 17.113 13.187 16.869 13.058C16.625 12.929 16.322 13.022 16.193 13.266C14.976 15.569 12.603 17 10 17C6.14 17 3 13.86 3 10C3 6.14 6.14 3 10 3C13.789 3 16.885 6.027 16.997 9.789L15.854 8.646C15.659 8.451 15.342 8.451 15.147 8.646C14.952 8.841 14.952 9.158 15.147 9.353L17.147 11.353C17.245 11.451 17.373 11.499 17.501 11.499C17.629 11.499 17.757 11.45 17.855 11.353L19.855 9.353C20.05 9.158 20.05 8.841 19.855 8.646H19.854Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconRefresh;

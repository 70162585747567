import * as yup from "yup";
import {stringRequired} from "../../../../../yup/defaultValudations";

export const schema = yup.object().shape({
  licenses: yup.array().of(
    yup.object().shape({
      name: stringRequired(),
      validDate: stringRequired(),
    })
  ),
});

export const initialValues = {name: "", validDate: ""};

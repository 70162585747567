import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
//local
import {color} from "constants/color.consts";
import {toRequestToUsId} from "constants/routes.const";
import {dateFormat} from "helpers/datetime";
import {IconMapMarker} from "components/icons";
import {IconCheck} from "components/icons";
import {IconHand} from "components/icons";
import {IconCross} from "components/icons";
import {CustomTooltip} from "components/CustomTooltip";
import NotifierDot from "components/NotifierDot";
import {Tag} from "components/Tag";
import {procurementStatuses} from "constants/buyer.consts";
import {dropdownStatusColors} from "constants/select.consts";
import cn from "classnames";

const RequestsTableItem = ({request}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const path = toRequestToUsId(request.id);

  const projectName = request.Request?.ProjectItem?.Project?.name ?? t("NONE");
  const requestName = request.Request?.name ?? t("NONE");
  const requestBuyer = request.Request?.Buyer?.name ?? t("NONE");
  const requestLocation = request.Request?.location ?? t("NONE");
  const requestClosesOffer = request.Request?.closedAt
    ? dateFormat(request.Request.closedAt)
    : t("NONE");
  const interestStatus = request.interested
    ? t("INTERESTED")
    : t("NOT_INTERESTED_SHORT_VERSION");

  const procurementStatus = request?.Request?.ProjectItem?.status;
  const isStoppedProcurement = procurementStatus === procurementStatuses.STOPPED;

  const handleRedirectOnClick = () => navigate(path, {state: {contractId: null}});
  const hasOffer = !!request.Offer?.id;

  return (
    <CustomTooltip text={requestLocation} placement="left">
      <div
        className={cn("requests-to-us_body", {unread: !request.readed})}
        onClick={handleRedirectOnClick}
      >
        <div className="requests-to-us_body__project">
          <IconMapMarker />
          <p className="project-name">{projectName}</p>
        </div>
        <div className={`requests-to-us_body__name${isStoppedProcurement ? " red" : ""}`}>
          {!request.readed && <NotifierDot />}
          {requestName}
        </div>
        <div
          className={`requests-to-us_body__column __buyer${
            isStoppedProcurement ? " red" : ""
          }`}
        >
          {requestBuyer}
        </div>
        <div className="requests-to-us_body__column __date">
          {isStoppedProcurement ? (
            <Tag
              text={procurementStatus}
              color={dropdownStatusColors[procurementStatus]}
              needTranslation
              addClass="fz-10"
              bold
              uppercase
            />
          ) : (
            requestClosesOffer
          )}
        </div>
        <div
          className={cn("requests-to-us_body__response", {
            green: request.interested || hasOffer, // Apply "green" if interested or hasOffer is true
            red: !(request.interested || hasOffer), // Apply "red" only if neither is true
          })}
        >
          {/* Show interestStatus only if hasOffer is not truthy */}
          {request.readed && request.interested !== null && !hasOffer && (
            <div className="df-row-center">
              {request.interested ? (
                <IconHand color={color.green} addClass="mr-2" />
              ) : (
                <IconCross color={color.red} addClass="mr-2" />
              )}
              {interestStatus}
            </div>
          )}

          {/* Display this only if hasOffer is truthy */}
          {hasOffer && (
            <div className="df-row-center">
              <IconCheck color={color.green} addClass="mr-2" /> {t("OFFER_SUBMITTED")}
            </div>
          )}
        </div>
      </div>
    </CustomTooltip>
  );
};

export default RequestsTableItem;

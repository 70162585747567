import React from "react";

export const IconLinkYitsharepoint = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2" fill="#01A0CC" />
      <g clipPath="url(#clip0_12497_211606)">
        <path
          d="M9.49761 12.2676H11.4027V7.99977H9.49761V12.2676ZM5.81051 9.40529L5.57243 9.13699L6.5877 7.99977H8.72517L6.84827 10.0649C6.68713 10.2423 6.54498 10.3742 6.47353 10.5106C6.40758 10.2241 6.27018 9.92391 5.81051 9.40529ZM4.42651 12.2676V11.2846C4.42651 10.8753 4.34606 10.6432 4.0238 10.2795L2 7.99977H4.15645L5.52247 9.53261C5.96815 10.0376 6.26194 10.5288 6.26194 11.1292V12.2676H4.42651ZM16.327 7.99977C15.0948 7.99521 14.3126 8.93223 14.3126 9.992V12.2676H16.1468V10.2876C16.1468 9.93302 16.308 9.75559 16.6682 9.75559H18V7.99977H16.327ZM14.9384 8.16353C14.4266 8.47283 14.0331 9.15521 14.0331 9.78292C13.9856 9.75895 13.8862 9.75559 13.7725 9.75559H12.4597V7.99977H14.161C14.4299 7.9958 14.696 8.05186 14.9384 8.16353V8.16353ZM9.49761 12.2676H11.4027V7.99977H9.49761V12.2676Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_12497_211606">
          <rect width="16" height="4.26667" fill="white" transform="translate(2 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React, {useState} from "react";
//local
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import MultipleSelect from "components/selects/MultipleSelect";
import SelectionRow from "components/selects/SelectionRow";
import {rfpCategoriesFilterRow} from "constants/tabs.const";

export const ChooseCategoriesModal = ({
  initialCategories,
  onSelectChange,
  onOk,
  onClose,
}) => {
  const [categories, setCategories] = useState(initialCategories);

  const categorySelections = Object.entries(categories)
    .map((item) => item[1].map((a) => ({label: a, value: [item[0], a]})))
    .flat();

  const onRemoveSelection = (value) => {
    setCategories({
      ...categories,
      [value[0]]: categories[value[0]].filter((item) => item !== value[1]),
    });
  };

  return (
    <Modal
      onClose={onClose}
      width={610}
      noTitle
      boldTitle
      titleText="MODAL_CHOOSE_CATEGORIES_TITLE"
      noCenterContent
    >
      <div className="pa-8">
        <div className="df-row-center mt-2">
          {rfpCategoriesFilterRow.map((selection) => (
            <MultipleSelect
              key={selection.label}
              value={categories?.[selection.label]}
              list={selection.array}
              customSelectAllElement={selection.selectAll}
              tiny
              addClass="mr-0"
              uppercaseButton
              notShowingSelections
              needSearch={selection.isNeedSearch}
              color={selection.color}
              bottomElement={selection.bottomElement}
              onChange={(value) =>
                setCategories({
                  ...categories,
                  [selection.label]: value,
                })
              }
              title={selection.title}
              titleInButton
            />
          ))}
        </div>
        <SelectionRow
          values={categorySelections.map((item) => ({
            value: item.value,
            label: item.label,
            color: "blue",
          }))}
          tiny
          addClass="mt-4 mb-2"
          onRemove={onRemoveSelection}
        />
        <div className="df-row-jce-center mt-6">
          <ButtonTransparent
            name="CANCEL"
            addClass="mr-4"
            color="red"
            onClick={onClose}
          />
          <ButtonTransparent
            name="SAVE"
            onClick={() => {
              onOk(categories);
              onClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

import React from "react";
import {inject, observer} from "mobx-react";
import ContentLoader from "react-content-loader";
//local
import {hasPermission, createArray} from "helpers/helper";
import {color} from "constants/color.consts";
import cn from "classnames";

export const SkeletonBase = inject("store")(
  observer(
    ({
      store: {auth, organization},
      canPermissions,
      noHideOurInfo,
      noAnimate = true,
      quantity = 1,
      addClass,
      addClassItem,
      children,
      width,
      height,
      svg,
    }) => {
      const {
        user: {permissions},
        activeOrganizationId,
      } = auth;
      const {organizationDetail, loading} = organization;
      const myOrganization = organizationDetail?.id === activeOrganizationId;

      return (
        <React.Fragment>
          {(!loading && noHideOurInfo && myOrganization) ||
          hasPermission(canPermissions, permissions) ? (
            children
          ) : (
            <div className={cn("df", {[addClass]: !!addClass})}>
              {createArray(quantity).map(() => (
                <ContentLoader
                  width={width}
                  height={height}
                  viewBox={`0 0 ${width} ${height}`}
                  animate={!noAnimate}
                  backgroundColor={color.offWhite}
                  foregroundColor={noAnimate ? color.offWhite : "#dbdbdb"}
                  className={addClassItem}
                  // {...props}
                >
                  {svg}
                </ContentLoader>
              ))}
            </div>
          )}
        </React.Fragment>
      );
    }
  )
);

import React, {memo} from "react";

export const IconChatSingle = memo(({color}) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.500176 17.5C0.275176 17.5 0.0781761 17.35 0.0181761 17.133C-0.0418239 16.916 0.0501761 16.686 0.243176 16.571C1.93418 15.557 2.63518 14.082 2.88418 13.392C1.04618 11.985 0.000176137 10.038 0.000176137 8.00002C0.000176137 6.97102 0.258176 5.97402 0.768176 5.03602C1.25418 4.14202 1.94818 3.34102 2.82918 2.65502C4.61618 1.26502 6.98518 0.499023 9.50018 0.499023C12.0152 0.499023 14.3842 1.26502 16.1712 2.65502C17.0522 3.34002 17.7462 4.14102 18.2322 5.03602C18.7422 5.97302 19.0002 6.97002 19.0002 8.00002C19.0002 9.03002 18.7422 10.026 18.2322 10.964C17.7462 11.858 17.0522 12.659 16.1712 13.345C14.3842 14.735 12.0152 15.501 9.50018 15.501C8.46718 15.501 7.45318 15.372 6.48418 15.116C6.05518 15.402 5.25318 15.909 4.29518 16.386C2.80718 17.126 1.53118 17.501 0.501176 17.501L0.500176 17.5ZM9.50018 1.50002C4.81318 1.50002 1.00018 4.41602 1.00018 8.00002C1.00018 9.81502 2.00518 11.562 3.75618 12.792C3.92818 12.913 4.00618 13.128 3.95218 13.331C3.83518 13.767 3.43718 14.964 2.37218 16.119C3.67418 15.663 5.07618 14.872 6.11118 14.16C6.23418 14.075 6.38818 14.05 6.53218 14.091C7.48018 14.362 8.47918 14.5 9.50018 14.5C14.1872 14.5 18.0002 11.584 18.0002 8.00002C18.0002 4.41602 14.1872 1.50002 9.50018 1.50002Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconBag = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.733 10.136C15.017 8.933 14.107 7.926 13.375 7.118C12.836 6.522 12.282 5.91 12.084 5.485C12.415 5.292 12.64 5.067 12.854 4.854C13.049 4.659 13.049 4.342 12.854 4.147C12.683 3.976 12.42 3.955 12.226 4.083C12.448 3.419 12.896 2.58 13.801 1.901C13.994 1.757 14.056 1.495 13.949 1.279C13.923 1.226 13.296 -0.0100021 12.001 -0.0100021C11.191 -0.0100021 10.938 0.499998 10.803 0.772998C10.695 0.990998 10.691 0.999998 10.501 0.999998C10.123 0.999998 9.692 0.780998 9.235 0.548998C8.693 0.273998 8.133 -0.0100021 7.516 -0.0100021H7.496C6.326 0.00199786 5.265 1.03 5.148 1.147C5.045 1.25 4.993 1.391 5.003 1.536C5.013 1.681 5.086 1.814 5.202 1.901C5.938 2.453 6.463 3.173 6.762 4.042C6.767 4.056 6.771 4.069 6.776 4.083C6.582 3.956 6.319 3.978 6.149 4.148C5.954 4.343 5.954 4.66 6.149 4.855C6.362 5.068 6.587 5.293 6.919 5.487C6.722 5.911 6.167 6.523 5.627 7.12C4.895 7.929 3.984 8.935 3.269 10.138C2.417 11.571 2.002 12.999 2.002 14.502C2.002 16.548 2.757 18.038 4.245 18.931C5.463 19.662 7.134 20.002 9.502 20.002C11.87 20.002 13.541 19.662 14.759 18.931C16.247 18.038 17.002 16.548 17.002 14.502C17.002 12.999 16.588 11.571 15.735 10.138L15.733 10.136ZM6.278 1.501C6.621 1.253 7.087 0.993998 7.505 0.989998H7.515C7.893 0.989998 8.324 1.209 8.781 1.441C9.323 1.716 9.883 2 10.5 2C11.31 2 11.563 1.49 11.698 1.217C11.806 0.998998 11.81 0.989998 12 0.989998C12.367 0.989998 12.647 1.208 12.825 1.407C11.446 2.636 11.113 4.155 11.033 4.844C10.674 4.942 10.19 5.001 9.5 5.001C8.81 5.001 8.326 4.942 7.967 4.844C7.889 4.172 7.57 2.713 6.278 1.502V1.501ZM9.5 19C4.944 19 3 17.654 3 14.5C3 11.508 4.946 9.359 6.366 7.789C7.056 7.027 7.606 6.419 7.852 5.842C8.276 5.94 8.81 6 9.5 6C10.19 6 10.724 5.94 11.148 5.842C11.394 6.419 11.944 7.027 12.633 7.789C14.054 9.359 15.999 11.508 15.999 14.5C15.999 17.654 14.055 19 9.499 19H9.5Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconBag;

import {inject, observer} from "mobx-react";
import React, {useEffect} from "react";
import {Preloader} from "components/Preloader";

const BankIdVerification = inject("store")(
  observer(({store: {auth}}) => {
    useEffect(() => {
      const checkStatusInterval = setInterval(() => {
        auth.getBankIdVerify(); // request
      }, 2000);
      return () => clearInterval(checkStatusInterval);
    }, [auth]);
    return <Preloader addClass="preloader500" />;
  })
);

export default BankIdVerification;

import React from "react";
import {useTranslation} from "react-i18next";
// local
import {ButtonTransparent} from "../../../buttons/ButtonTransparent";
import {Modal} from "components/Modal";
import {IconNotificationCircle} from "components/icons";
import {color} from "constants/color.consts";

export const ModalOwnerInvite = ({users, extraPersonPrice, onAccept, onClose}) => {
  const {t} = useTranslation();

  return (
    <Modal
      titleIcon={<IconNotificationCircle color={color.orange} className="mr-4" />}
      titleText="INVITES_TAB_MODAL_CONFIRM_TITLE"
      boldTitle
      noCloseButton
      noCloseOnEsc
      width="min"
      rounded
      whiteTitle
      onClose={onClose}
    >
      <div className="pb-8 pl-8 pr-8">
        <span className="invite-user__modal-confirm__description">
          {`${t("INVITES_TAB_MODAL_DESCRIPTION_01")}`}

          {/* Keeping this variant if we want it back */}
          {/* 
            {`${t("INVITES_TAB_MODAL_DESCRIPTION_01")}
          ${extraPersonPrice} ${t(
            "KR_MONTH"
          )}`}
          */}
        </span>
        <div className="df mt-8">
          <ButtonTransparent name="CANCEL" medium onClick={onClose} color="red" />
          <ButtonTransparent
            type={onAccept ? "button" : "submit"}
            onClick={onAccept}
            addClass="ml-4"
            name={`${t("CONFIRM")} ${users.length} ${t(
              "INVITES_TAB_MODAL_CONFIRM_BUTTON"
            )}`}
            medium
            nowrap
            nonTransparent
          />
        </div>
      </div>
    </Modal>
  );
};

import React, {useState} from "react";
import {Upload} from "antd";
import {useTranslation} from "react-i18next";
// local
import {ButtonTransparent} from "./buttons/ButtonTransparent";
import {showSuccess} from "helpers/notifications.helper";
import {beforeUpload, beforeUploadCustom} from "helpers/uploader";
import UploadedFilesList from "./UploadedFilesList";
import {ConfirmModal} from "components/ConfirmModal";
import cn from "classnames";

// filesButtonView types: "cross", "delete"

const UploadMultiple = ({
  files,
  viewMode,
  uploadRequest,
  removeRequest,
  setFieldValue,
  fieldName,
  isFilesRowView,
  filesButtonView,
  isFileIconDownload,
  customFileTypes,
  customFileSize = 5120,
  miniLoadButton,
  miniLoadFiles,
  wrapperClassName,
  uploadButtonClass,
}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [currentFiles, setCurrentFiles] = useState(files);

  const setFilesFieldValue = (newFile) => {
    if (currentFiles?.length) {
      setCurrentFiles([newFile, ...currentFiles]);
      setFieldValue && setFieldValue(fieldName, [newFile, ...currentFiles]);
    } else {
      setCurrentFiles([newFile]);
      setFieldValue && setFieldValue(fieldName, [newFile]);
    }
  };

  const removeFile = (index) => {
    const resultFiles = currentFiles.filter((item, i) => index !== i);
    setCurrentFiles(resultFiles);
    setFieldValue && setFieldValue(fieldName, resultFiles);
    removeRequest && removeRequest(currentFiles[index].id);
  };

  const onRemove = (index) =>
    ConfirmModal({
      title: t("WANT_DELETE_FILE"),
      text: t("CANT_DELETE_FILE"),
      type: "warning",
      onOk: () => removeFile(index),
    });

  const customRequest = ({file, onError}) => {
    setLoading(true);

    uploadRequest(file)
      .then((data) => {
        showSuccess(`${file.name} file uploaded successfully`);
        setFilesFieldValue(data);
      })
      .catch(onError)
      .finally(() => setLoading(false));
  };

  const uploadProps = {
    name: "file",
    beforeUpload: (file) =>
      customFileTypes
        ? beforeUploadCustom(file, customFileTypes, customFileSize)
        : beforeUpload(file),
    customRequest,
    showUploadList: false,
  };

  return (
    <div
      className={cn("editBlock_upload__item", {
        "__row-view": isFilesRowView,
        [wrapperClassName]: !!wrapperClassName,
      })}
    >
      {!!currentFiles && (
        <UploadedFilesList
          files={currentFiles}
          viewMode={viewMode}
          removeFile={onRemove}
          rowView={isFilesRowView}
          buttonView={filesButtonView}
          isIconDownload={isFileIconDownload}
          miniLoadFiles={miniLoadFiles}
        />
      )}

      {!viewMode && (
        <Upload {...uploadProps}>
          <ButtonTransparent
            name={loading ? "LOADING" : "UPLOAD"}
            addClass={cn("editBlock_upload__btn", {
              "mb-2": isFilesRowView,
              [uploadButtonClass]: !!uploadButtonClass,
            })}
            loading={loading}
            small={miniLoadButton}
            clickable
            nowrap
          />
        </Upload>
      )}
    </div>
  );
};

export default UploadMultiple;

import React from "react";
import {Tabs} from "antd";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
// local
import FilterTabItem from "./FilterTabItem";
import {findSuppliersFilterTabs} from "constants/tabs.const";

const FilterTabs = inject("store")(
  observer(({store: {organization}, params, onChange, onSearch}) => {
    const {activeFilterTab} = organization;
    const {t} = useTranslation();

    const changeTab = (tab) => {
      onChange(
        "tab",
        findSuppliersFilterTabs.find((item) => item.id === +tab)?.filterKey
      );
    };

    return (
      <div className="find-suppliers_tabs__wrapper">
        <Tabs
          activeKey={activeFilterTab}
          onChange={changeTab}
          items={findSuppliersFilterTabs.map(
            (item) =>
              item.visible && {
                label: t(item.title),
                key: item.id,
                disabled: item.disabled,
                children: (
                  <FilterTabItem
                    content={item.content}
                    params={params}
                    onChange={onChange}
                  />
                ),
              }
          )}
        ></Tabs>
      </div>
    );
  })
);

export default FilterTabs;

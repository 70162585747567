import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
//local
import {toOrganizationId} from "constants/routes.const";
import {dateFormat} from "helpers/datetime";
import {IconUE2021, IconSkullEmpty} from "components/icons";
import {ourAgreementsTypes} from "constants/user.consts";
import {InitialsBadge} from "components/InitialsBadge";
import UploadMultiple from "components/UploadMultiple";
import {getFullName, hasNoLegacySupplierQualification} from "helpers/helper";
import {color} from "constants/color.consts";

const FrameworkAgreementsTableItem = ({organization, currentUser}) => {
  const {t} = useTranslation();

  const isUE2021 = !!organization.OurAgreements?.find(
    (item) => item?.type === ourAgreementsTypes.UE2021
  );

  const ramavtalItems = organization.OurAgreements?.find(
    (item) => item?.type === ourAgreementsTypes.RAMAVTAL
  )?.OrganizationAgreementItems;

  return (
    <div className="requests-card_body__wrapper">
      {ramavtalItems &&
        ramavtalItems.map((item, i) => (
          <div key={item.id} className="requests-card_body">
            <div className="requests-card_body__name">
              {i === 0 ? (
                <div className="requests-card_body__name __title">
                  {hasNoLegacySupplierQualification(
                    true,
                    organization.isFavorite,
                    !!organization.SupplierQualifications?.length,
                    currentUser?.ActiveOrganization?.enableSuppliersQualification
                  )}
                  <Link
                    to={toOrganizationId(organization.id)}
                    state={{from: "FrameworkAgreements"}}
                    className={organization.isFavorite ? "ml-2" : ""}
                  >
                    {organization.name}
                  </Link>
                  {organization.isBlackList && (
                    <IconSkullEmpty width="20" height="20" addClass="ml-2" />
                  )}
                  {isUE2021 && <IconUE2021 addClass="ml-2" />}
                </div>
              ) : (
                <div className="requests-card_body__name __title"></div>
              )}
              <div className="requests-card_body__name __ramavtal">
                <div className="df-row-center mb-2">
                  {!!item.name ? (
                    <span>{item.name}</span>
                  ) : (
                    <span style={{color: color.midGray}}>{t("NO_NAME")}</span>
                  )}
                  <InitialsBadge
                    firstName={item.AssignedUser?.firstName}
                    lastName={item.AssignedUser?.lastName}
                    isCurrentUser={item.AssignedUserId === currentUser.id}
                    addClass="mr-2 ml-4"
                  />
                  <span className="mr-4">
                    {getFullName(
                      item.AssignedUser?.firstName,
                      item.AssignedUser?.lastName
                    )}
                  </span>
                </div>
                <UploadMultiple
                  fieldName="Files"
                  viewMode
                  isFilesRowView
                  isFileIconDownload
                  miniLoadButton
                  miniLoadFiles
                  filesButtonView="delete"
                  files={item.Files}
                />
              </div>
            </div>
            <div className="requests-card_body__column">
              {item.documentNumber && `# ${item.documentNumber}`}
            </div>
            <div className="requests-card_body__column">{dateFormat(item.startAt)}</div>
            <div className="requests-card_body__column">{dateFormat(item.endAt)}</div>
          </div>
        ))}
    </div>
  );
};

export default FrameworkAgreementsTableItem;

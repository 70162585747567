import React from "react";
import TableManagement from "./TableManagement";
import PresentationCardWrapper from "../../PresentationCardWrapper";
import EmptyOrNoCompanyCreditData from "components/EmptyOrNoCompanyCreditData";
import {useTranslation} from "react-i18next";
import {IconUsers} from "components/icons";
import {LockValue} from "components/LockValue";

const Management = ({companyData, hasFirstUpdateData}) => {
  const managementInfo = companyData?.Company?.companyCreditReport?.directors;
  const {t} = useTranslation();
  return (
    <PresentationCardWrapper icon={<IconUsers />} title={t("MANAGEMENT")}>
      <LockValue
        canPermissions={["READ_OTHER_MANAGEMENT"]}
        noHideOurInfo
        fullSubcribeButton
        needMiniSibscribeButton
      >
        {managementInfo?.length ? (
          <TableManagement tableClass="management-table_wrap" members={managementInfo} />
        ) : (
          <EmptyOrNoCompanyCreditData hasFirstUpdateData={hasFirstUpdateData} />
        )}
      </LockValue>
    </PresentationCardWrapper>
  );
};

export default Management;

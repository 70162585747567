import React from "react";
import {Select} from "antd";
import {orderingCompanyValues} from "constants/select.consts";
import {changeSelectValue, changeFilterParams} from "helpers/helper";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import ButtonSelect from "components/selects/ButtonSelect";
import HandyPointerBlob from "components/HandyPointerBlob";
import {turnoverButtonSelect, creditRatingButtonSelect} from "constants/select.consts";
import {
  IconSpeedometer,
  IconCommunity,
  IconFirstAddKit,
  IconTabletPen,
  IconRefresh,
} from "components/icons/index";
import {color} from "constants/color.consts";

const {Option} = Select;

const OrganizationCardHead = inject("store")(
  observer(({store: {buyer}, onChangeOrder, onSelectChange, total, added}) => {
    const {rfpRecipientsFilterParams} = buyer;
    const {t} = useTranslation();

    const options = orderingCompanyValues.map(({value, label}, i) => (
      <Option key={i} value={value}>
        {t(label)}
      </Option>
    ));

    return (
      <div className="organization-card_head">
        <div className="organization-card_head__name __wrapper">
          <div className="organization-card_head__name __select">
            <Select
              onChange={(value) => onChangeOrder(changeFilterParams(value))}
              value={changeSelectValue(orderingCompanyValues, rfpRecipientsFilterParams)}
            >
              {options}
            </Select>
          </div>
        </div>
        <div className="organization-card_head__indicator __wrapper">
          <div className="organization-card_head__indicator __rating __uppercase">
            {t("OUR_RATING")}
          </div>

          <div className="organization-card_head__indicator __loading turnover __uppercase">
            <ButtonSelect
              list={turnoverButtonSelect}
              value={+(rfpRecipientsFilterParams.minTurnover ?? 0)}
              buttonName={
                <div className="lh-11">
                  {+(rfpRecipientsFilterParams.minTurnover ?? 0)}
                  <div className="fz-8">M</div>
                </div>
              }
              buttonIcon={<IconRefresh color={color.blue} />}
              buttonTooltip="YEARLY_TURNOVER"
              buttonHoverableLink
              small
              addWrapperClass="mla"
              dropdownClassName="__turnover"
              onChange={({value}) => onSelectChange("minTurnover", value)}
            />
          </div>
          <div className="organization-card_head__indicator __status">
            <ButtonSelect
              list={creditRatingButtonSelect}
              value={+(rfpRecipientsFilterParams.minCreditRating ?? 0)}
              buttonName={+(rfpRecipientsFilterParams.minCreditRating ?? 0)}
              buttonIcon={<IconSpeedometer color={color.blue} />}
              buttonTooltip="CREDIT_RATING"
              buttonHoverableLink
              small
              addWrapperClass="mla"
              dropdownClassName="__credit-rating"
              onChange={({value}) => onSelectChange("minCreditRating", value)}
            />
          </div>
          <div className="organization-card_head__indicator __sub-status">
            <ButtonTransparent
              icon={<IconCommunity color={color.blue} />}
              tooltip="HAS_COLL_AGREEMENT"
              iconButton
              hoverableLink
              nonTransparent={!!rfpRecipientsFilterParams.collectiveUnionAgreement}
              onClick={() =>
                onSelectChange(
                  "collectiveUnionAgreement",
                  !rfpRecipientsFilterParams.collectiveUnionAgreement
                )
              }
            />
          </div>
          <div className="organization-card_head__indicator __sub-status">
            <ButtonTransparent
              icon={<IconFirstAddKit color={color.blue} />}
              tooltip="HAS_INSURANCE"
              iconButton
              hoverableLink
              nonTransparent={!!rfpRecipientsFilterParams.insurance}
              onClick={() =>
                onSelectChange("insurance", !rfpRecipientsFilterParams.insurance)
              }
            />
          </div>
          <div className="organization-card_head__indicator __sub-status">
            <ButtonTransparent
              icon={<IconTabletPen color={color.blue} />}
              tooltip="HAS_FTAX_VAT_IS_REGISTER"
              iconButton
              hoverableLink
              nonTransparent={!!rfpRecipientsFilterParams.FVREmployer}
              onClick={() =>
                onSelectChange("FVREmployer", !rfpRecipientsFilterParams.FVREmployer)
              }
            />
          </div>
          <HandyPointerBlob
            showPointer
            text="NEW_FEATURE_SEARCHFILTERS_TEXT"
            forcePosition="absolute"
            topPos={48}
            rightPos={209}
          />
          <div className="organization-card_head__actions fz-12 __text-right">
            {t("QUANTITY_OF_ADDED")?.format(added, total)}
          </div>
        </div>
      </div>
    );
  })
);

export default OrganizationCardHead;

import React from "react";
import {useTranslation} from "react-i18next";
//local
import {ClosableTags} from "components/ClosableTags";
import {EmailSuggestCompanyModal} from "components/modals/EmailSuggestCompanyModal";
import {
  IconHandOnRight,
  IconMailLetter,
  IconFunnel,
  IconSkullEmpty,
  IconUE2021,
  IconRamavtal,
} from "components/icons";
import {color} from "constants/color.consts";
import ButtonDelete from "components/buttons/ButtonDelete";
import EmailRecipientsBouncedStatus from "../EmailRecipientsBouncedStatus";
import {InitialsBadge} from "components/InitialsBadge";
import {hasNoLegacySupplierQualification} from "helpers/helper";
import {ourAgreementsTypes} from "constants/user.consts";

// type: "filter" | "organization" | "email"
export const RecipientsEditRow = ({
  type,
  organization,
  currentUser,
  dontRemove,
  isPublishedRequest,
  remove,
  onTagClick,
  isFavorite,
  isBlacklisted,
  isSuggestModal, // for email type row
  isShowInfoLabel, // for organization, if modal did not open
  selectedModalOrganization, // for email type row
  onConvertEmailToOrganization, // for email type row
  closeSuggestModal, // for email type row
}) => {
  const {t} = useTranslation();

  const typeColor = {
    filter: "link",
    organization: "green",
    email: "purple",
  };

  const ourAgreements =
    organization.Organization?.OurAgreements ?? organization?.OurAgreements;
  const isUE2021 = !!ourAgreements?.find(
    (item) => item?.type === ourAgreementsTypes.UE2021
  );
  const isRamavtal = !!ourAgreements?.find(
    (item) => item?.type === ourAgreementsTypes.RAMAVTAL
  );

  const membersToReceiveIds =
    organization.MembersToReceive?.map((member) => member.id) ?? [];
  const members = membersToReceiveIds.length
    ? organization.Organization?.Users?.filter((member) =>
        membersToReceiveIds.includes(member.id)
      )
    : organization.Organization?.Users?.filter(
        (member) => !!member.OrganizationMember?.isRfpEmailNotifications
      );

  const disableRemove = (tag) =>
    tag instanceof Object
      ? !dontRemove?.some((item) => item.id === tag.id)
      : !dontRemove?.includes(tag);

  return (
    <div className="recipients-tab_table-row_wrapper">
      {type === "filter" && (
        <IconFunnel width="20" color={color[typeColor[type]]} className="mr-4" />
      )}
      {type === "organization" && (
        <IconHandOnRight width="20" color={color[typeColor[type]]} className="mr-4" />
      )}
      {type === "email" && (
        <IconMailLetter
          width="20"
          height="13"
          color={color[typeColor[type]]}
          className="mr-4"
        />
      )}
      <div
        className="recipients-tab_table-row_organization"
        onClick={() => (type === "organization" ? onTagClick(organization) : null)}
      >
        <ClosableTags
          tags={[
            type === "email"
              ? organization
              : {
                  ...organization,
                  fullName: organization.name,
                },
          ]}
          field={type === "email" ? "email" : "fullName"}
          index={type === "email" ? "email" : "id"}
          small
          tagClass="recipients-tab_table-row_organization_tag"
          color={typeColor[type]}
          dontRemove={false}
          isRelativeCustomContent
          customContent={
            type === "email" &&
            isSuggestModal && (
              <EmailSuggestCompanyModal
                organization={selectedModalOrganization}
                onClose={closeSuggestModal}
                onAdd={() => {
                  onConvertEmailToOrganization(selectedModalOrganization);
                  closeSuggestModal();
                }}
              />
            )
          }
        />
        {hasNoLegacySupplierQualification(
          true,
          isFavorite,
          !!organization.Organization?.SupplierQualifications?.length,
          currentUser?.ActiveOrganization?.enableSuppliersQualification
        )}
        {isBlacklisted && <IconSkullEmpty height={20} width={20} />}
        {isUE2021 && <IconUE2021 />}
        {isRamavtal && <IconRamavtal />}
        {!!members?.length &&
          members.map(
            (member, index) =>
              index < 3 && (
                <InitialsBadge
                  key={member.id}
                  firstName={member.firstName}
                  lastName={member.lastName}
                  addClass="mr-2"
                />
              )
          )}
        {!!members?.length && members.length > 3 && (
          <span className="fz-12">{`+${members.length - 3} ${t("OTHERS")}`}</span>
        )}
        {type === "organization" && isShowInfoLabel && (
          <span className="recipients-tab_table-row_organization_info">
            {t("RECIPIENTS_REGISTERED_OPEN_MODAL")}
          </span>
        )}
        {type === "email" && organization.isBounced && <EmailRecipientsBouncedStatus />}
      </div>
      {(!isPublishedRequest || disableRemove(organization)) && (
        <ButtonDelete
          activeOnHover
          onClick={() => remove(type === "email" ? organization : organization.id)}
        />
      )}
    </div>
  );
};

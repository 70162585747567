import React, {memo} from "react";

export const IconLifebuoy = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.071 2.93709C15.182 1.04809 12.671 0.00808716 10 0.00808716C7.329 0.00808716 4.818 1.04809 2.929 2.93709C1.04 4.82609 0 7.33709 0 10.0081C0 12.6791 1.04 15.1901 2.929 17.0791C4.818 18.9681 7.329 20.0081 10 20.0081C12.671 20.0081 15.182 18.9681 17.071 17.0791C18.96 15.1901 20 12.6791 20 10.0081C20 7.33709 18.96 4.82609 17.071 2.93709V2.93709ZM10 15.0081C7.243 15.0081 5 12.7651 5 10.0081C5 7.25109 7.243 5.00809 10 5.00809C12.757 5.00809 15 7.25109 15 10.0081C15 12.7651 12.757 15.0081 10 15.0081ZM1 10.0081C1 9.67009 1.019 9.33609 1.056 9.00809H4.084C4.029 9.33309 4 9.66709 4 10.0081C4 10.3491 4.029 10.6831 4.084 11.0081H1.056C1.02 10.6801 1 10.3461 1 10.0081V10.0081ZM15.916 9.00809H18.944C18.98 9.33609 19 9.67009 19 10.0081C19 10.3461 18.981 10.6801 18.944 11.0081H15.916C15.971 10.6831 16 10.3491 16 10.0081C16 9.66709 15.971 9.33309 15.916 9.00809V9.00809ZM18.776 8.00809H15.657C15.053 6.30609 13.702 4.95509 12 4.35109V1.23209C15.36 1.99709 18.01 4.64809 18.776 8.00809ZM11 1.06409V4.09209C10.675 4.03709 10.341 4.00809 10 4.00809C9.659 4.00809 9.325 4.03709 9 4.09209V1.06409C9.328 1.02809 9.662 1.00809 10 1.00809C10.338 1.00809 10.672 1.02709 11 1.06409V1.06409ZM8 1.23209V4.35109C6.298 4.95509 4.947 6.30609 4.343 8.00809H1.224C1.989 4.64809 4.64 1.99809 8 1.23209V1.23209ZM1.224 12.0081H4.343C4.947 13.7101 6.298 15.0611 8 15.6651V18.7841C4.64 18.0191 1.99 15.3681 1.224 12.0081V12.0081ZM9 18.9521V15.9241C9.325 15.9791 9.659 16.0081 10 16.0081C10.341 16.0081 10.675 15.9791 11 15.9241V18.9521C10.672 18.9891 10.338 19.0081 10 19.0081C9.662 19.0081 9.328 18.9891 9 18.9521ZM12 18.7841V15.6651C13.702 15.0611 15.053 13.7101 15.657 12.0081H18.776C18.011 15.3681 15.36 18.0181 12 18.7841Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconStar = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "30"}
      height={height || "31"}
      viewBox="0 0 30 31"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.25 28.2581C23.127 28.2581 23.004 28.2281 22.8915 28.1666L15 23.8631L7.10852 28.1666C6.85652 28.3046 6.54902 28.2851 6.31502 28.1186C6.08102 27.9521 5.96252 27.6671 6.01052 27.3836L7.44752 18.7646L1.72052 13.0376C1.52252 12.8396 1.45052 12.5456 1.53602 12.2786C1.62152 12.0116 1.84952 11.8136 2.12702 11.7671L10.7505 10.3301L14.3295 3.17209C14.457 2.91859 14.7165 2.75809 15 2.75809C15.2835 2.75809 15.5445 2.91859 15.6705 3.17209L19.2495 10.3301L27.873 11.7671C28.1505 11.8136 28.3785 12.0101 28.464 12.2786C28.5495 12.5471 28.479 12.8396 28.2795 13.0376L22.5525 18.7646L23.9895 27.3836C24.036 27.6671 23.9175 27.9521 23.685 28.1186C23.556 28.2101 23.403 28.2581 23.25 28.2581ZM15 22.2581C15.123 22.2581 15.2475 22.2881 15.3585 22.3496L22.257 26.1116L21.0105 18.6311C20.97 18.3926 21.048 18.1481 21.2205 17.9771L26.19 13.0076L18.627 11.7476C18.39 11.7086 18.1875 11.5571 18.0795 11.3426L15 5.18359L11.9205 11.3426C11.8125 11.5571 11.61 11.7071 11.373 11.7476L3.81002 13.0076L8.77952 17.9771C8.95052 18.1481 9.02852 18.3911 8.98952 18.6311L7.74302 26.1116L14.6415 22.3496C14.754 22.2881 14.877 22.2581 15 22.2581Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React from "react";
import {useTranslation} from "react-i18next";
// local
import PresentationCardWrapper from "../PresentationCardWrapper";
import EmptyOrNoCompanyCreditData from "components/EmptyOrNoCompanyCreditData";
import {IconUsers} from "components/icons";
import {LockValue} from "components/LockValue";

const Signatories = ({companyData, hasFirstUpdateData}) => {
  const signatoryInfo = companyData?.Company?.basic?.companySign;
  const {t} = useTranslation();

  return (
    <PresentationCardWrapper icon={<IconUsers />} title={t("SIGNATORIES")}>
      <LockValue
        canPermissions={["READ_OTHER_SIGNATORIES"]}
        noHideOurInfo
        fullSubcribeButton
        needMiniSibscribeButton
      >
        {signatoryInfo ? (
          <p>{signatoryInfo}</p>
        ) : (
          <EmptyOrNoCompanyCreditData hasFirstUpdateData={hasFirstUpdateData} />
        )}
      </LockValue>
    </PresentationCardWrapper>
  );
};

export default Signatories;

import React from "react";

const SaveSearch = ({user}) => {
  return (
    <div className="first-setup-header">
      <div className="first-setup-header__info">
        <p>{user?.ActiveOrganization?.name}</p>
        <p>{user?.email}</p>
      </div>
    </div>
  );
};

export default SaveSearch;

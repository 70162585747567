import React, {memo} from "react";

export const IconChevronDownMini = memo(({color, style, addClass}) => {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      className={addClass}
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.250488 3.42084C0.250488 3.35684 0.274988 3.29284 0.323488 3.24384C0.420988 3.14634 0.579488 3.14634 0.676988 3.24384L4.99999 6.56684L9.32299 3.24384C9.42049 3.14634 9.57899 3.14634 9.67649 3.24384C9.77399 3.34134 9.77399 3.49984 9.67649 3.59734L5.17649 8.09734C5.07899 8.19484 4.92049 8.19484 4.82299 8.09734L0.322988 3.59734C0.273988 3.54834 0.250488 3.48484 0.250488 3.42084Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

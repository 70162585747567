import React, {memo} from "react";

export const IconSpeedometer = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 15C8.673 15 8 14.327 8 13.5C8 12.931 8.84 8.327 9.008 7.41C9.052 7.173 9.258 7 9.5 7C9.742 7 9.948 7.172 9.992 7.41C10.16 8.327 11 12.931 11 13.5C11 14.327 10.327 15 9.5 15ZM9.5 10.334C9.217 11.953 9.001 13.28 9 13.501C9 13.777 9.224 14.001 9.5 14.001C9.776 14.001 10 13.777 10 13.501C9.999 13.28 9.783 11.953 9.5 10.335V10.334Z"
        fill={color || "#000"}
      />
      <path
        d="M16.218 5.783C14.424 3.989 12.038 3 9.49998 3C6.96198 3 4.57698 3.988 2.78298 5.783C0.988977 7.578 0.000976562 9.963 0.000976562 12.5C0.000976562 14.389 0.553976 16.214 1.60098 17.778C1.69398 17.917 1.84998 18 2.01698 18H16.986C17.153 18 17.309 17.917 17.402 17.778C18.449 16.214 19.002 14.389 19.002 12.5C19.002 9.962 18.014 7.577 16.22 5.783H16.218ZM16.712 17H2.28698C1.53198 15.793 1.09698 14.423 1.01398 13H1.49898C1.77498 13 1.99898 12.776 1.99898 12.5C1.99898 12.224 1.77498 12 1.49898 12H1.01398C1.26398 7.71 4.70898 4.265 8.99898 4.015V4.5C8.99898 4.776 9.22298 5 9.49898 5C9.77498 5 9.99898 4.776 9.99898 4.5V4.015C14.289 4.265 17.734 7.71 17.984 12H17.499C17.223 12 16.999 12.224 16.999 12.5C16.999 12.776 17.223 13 17.499 13H17.984C17.901 14.423 17.466 15.793 16.711 17H16.712Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

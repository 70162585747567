import React, {memo} from "react";

export const IconCompanyGroupParent = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0167 5C8.63869 5 7.51669 3.878 7.51669 2.5C7.51669 1.122 8.63869 0 10.0167 0C11.3947 0 12.5167 1.122 12.5167 2.5C12.5167 3.878 11.3947 5 10.0167 5ZM10.0167 1C9.18969 1 8.51669 1.673 8.51669 2.5C8.51669 3.327 9.18969 4 10.0167 4C10.8437 4 11.5167 3.327 11.5167 2.5C11.5167 1.673 10.8437 1 10.0167 1Z"
        fill={color || "#000"}
      />
      <path
        d="M14.1127 11.303C14.4077 11.598 14.8067 11.767 15.2077 11.767C15.5697 11.767 15.9057 11.631 16.1517 11.385C16.6917 10.845 16.6547 9.931 16.0697 9.346L13.5777 6.854C13.0907 6.367 12.2047 6 11.5167 6H8.51668C7.82868 6 6.94268 6.367 6.45568 6.854L3.96368 9.346C3.68968 9.62 3.52568 9.978 3.50268 10.352C3.47868 10.744 3.61668 11.12 3.88168 11.385C4.12768 11.631 4.46368 11.767 4.82568 11.767C5.22668 11.767 5.62568 11.598 5.92068 11.303L7.74568 9.478L7.96268 10.347L4.86968 13.44C4.39268 13.916 4.49168 14.329 4.55568 14.484C4.61968 14.639 4.84268 15 5.51668 15H7.51668V18.5C7.51668 19.327 8.18968 20 9.01668 20C9.40068 20 9.75068 19.855 10.0167 19.617C10.2827 19.855 10.6327 20 11.0167 20C11.8437 20 12.5167 19.327 12.5167 18.5V15H14.5167C15.1907 15 15.4127 14.639 15.4767 14.484C15.5407 14.329 15.6387 13.916 15.1627 13.44L12.0697 10.347L12.2867 9.478L14.1117 11.303H14.1127ZM9.01668 19C8.74068 19 8.51668 18.776 8.51668 18.5V15H9.51668V18.5C9.51668 18.776 9.29268 19 9.01668 19ZM11.5167 18.5C11.5167 18.776 11.2927 19 11.0167 19C10.7407 19 10.5167 18.776 10.5167 18.5V15H11.5167V18.5ZM11.1627 10.854L14.3087 14H5.72268L8.86868 10.854C8.99268 10.73 9.04268 10.55 9.00068 10.379L8.50068 8.379C8.45668 8.204 8.32268 8.066 8.14868 8.018C7.97468 7.97 7.78868 8.019 7.66168 8.146L5.21268 10.595C5.10368 10.704 4.96268 10.766 4.82468 10.766C4.75768 10.766 4.66168 10.751 4.58768 10.677C4.50168 10.591 4.49568 10.474 4.49968 10.412C4.50768 10.283 4.56968 10.152 4.66968 10.052L7.16168 7.56C7.45468 7.267 8.10068 6.999 8.51568 6.999H11.5157C11.9307 6.999 12.5757 7.266 12.8697 7.56L15.3617 10.052C15.5537 10.244 15.5907 10.53 15.4437 10.677C15.3697 10.751 15.2747 10.766 15.2067 10.766C15.0687 10.766 14.9277 10.704 14.8187 10.595L12.3697 8.146C12.2427 8.019 12.0567 7.97 11.8827 8.018C11.7087 8.066 11.5747 8.204 11.5307 8.379L11.0307 10.379C10.9877 10.549 11.0377 10.73 11.1627 10.854Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconRules = memo(({color}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 3.99493H9V3.49493C9 2.66793 8.327 1.99493 7.5 1.99493H5.5C4.673 1.99493 4 2.66793 4 3.49493V3.99493H1.5C0.673 3.99493 0 4.66793 0 5.49493V7.49493C0 8.32193 0.673 8.99493 1.5 8.99493H4V19.4949C4 20.3219 4.673 20.9949 5.5 20.9949H7.5C8.327 20.9949 9 20.3219 9 19.4949V8.99493H17.5C18.327 8.99493 19 8.32193 19 7.49493V5.49493C19 4.66793 18.327 3.99493 17.5 3.99493ZM5 3.49493C5 3.21893 5.224 2.99493 5.5 2.99493H7.5C7.776 2.99493 8 3.21893 8 3.49493V3.99493H5V3.49493ZM8 10.9949H7.5C7.224 10.9949 7 11.2189 7 11.4949C7 11.7709 7.224 11.9949 7.5 11.9949H8V13.9949H7.5C7.224 13.9949 7 14.2189 7 14.4949C7 14.7709 7.224 14.9949 7.5 14.9949H8V16.9949H7.5C7.224 16.9949 7 17.2189 7 17.4949C7 17.7709 7.224 17.9949 7.5 17.9949H8V19.4949C8 19.7709 7.776 19.9949 7.5 19.9949H5.5C5.224 19.9949 5 19.7709 5 19.4949V8.99493H8V10.9949ZM18 7.49493C18 7.77093 17.776 7.99493 17.5 7.99493H16V7.49493C16 7.21893 15.776 6.99493 15.5 6.99493C15.224 6.99493 15 7.21893 15 7.49493V7.99493H13V7.49493C13 7.21893 12.776 6.99493 12.5 6.99493C12.224 6.99493 12 7.21893 12 7.49493V7.99493H10V7.49493C10 7.21893 9.776 6.99493 9.5 6.99493C9.224 6.99493 9 7.21893 9 7.49493V7.99493H7V7.49493C7 7.21893 6.776 6.99493 6.5 6.99493C6.224 6.99493 6 7.21893 6 7.49493V7.99493H4V7.49493C4 7.21893 3.776 6.99493 3.5 6.99493C3.224 6.99493 3 7.21893 3 7.49493V7.99493H1.5C1.224 7.99493 1 7.77093 1 7.49493V5.49493C1 5.21893 1.224 4.99493 1.5 4.99493H17.5C17.776 4.99493 18 5.21893 18 5.49493V7.49493Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

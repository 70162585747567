import React, {memo} from "react";

export const IconHourglass = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass ?? ""}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 3.49998V2.99998C16 2.60798 15.759 2.26698 15.283 1.98598C14.939 1.78298 14.462 1.60398 13.864 1.45498C12.692 1.16198 11.142 1.00098 9.5 1.00098C7.858 1.00098 6.308 1.16198 5.136 1.45498C4.538 1.60398 4.061 1.78298 3.717 1.98598C3.241 2.26698 3 2.60798 3 2.99998V3.49998C3 6.13098 4.593 8.49898 7 9.50198V11.499C4.593 12.502 3 14.87 3 17.501V18.001C3 18.393 3.241 18.734 3.717 19.015C4.061 19.218 4.538 19.397 5.136 19.546C6.308 19.839 7.858 20 9.5 20C11.142 20 12.692 19.839 13.864 19.546C14.462 19.397 14.939 19.218 15.283 19.015C15.759 18.734 16 18.392 16 18.001V17.501C16 14.87 14.407 12.502 12 11.499V9.50198C14.407 8.49898 16 6.13098 16 3.49998ZM5.612 2.36898C6.678 2.13098 8.059 1.99998 9.5 1.99998C10.941 1.99998 12.323 2.13098 13.388 2.36898C14.538 2.62598 14.886 2.90298 14.977 2.99998C14.886 3.09698 14.538 3.37398 13.388 3.63098C12.322 3.86898 10.941 3.99998 9.5 3.99998C8.059 3.99998 6.677 3.86898 5.612 3.63098C4.462 3.37398 4.114 3.09698 4.023 2.99998C4.114 2.90298 4.462 2.62598 5.612 2.36898ZM11.333 12.313C13.526 13.088 15 15.173 15 17.5V17.973C14.958 18.03 14.676 18.343 13.388 18.63C12.322 18.868 10.941 18.999 9.5 18.999C8.059 18.999 6.677 18.868 5.612 18.63C4.325 18.342 4.042 18.03 4 17.973V17.5C4 15.173 5.473 13.088 7.667 12.313C7.867 12.242 8 12.054 8 11.842V9.15898C8 8.94698 7.866 8.75798 7.667 8.68798C5.691 7.98898 4.299 6.22698 4.043 4.18198C4.343 4.31698 4.709 4.43898 5.136 4.54598C6.308 4.83898 7.858 4.99998 9.5 4.99998C11.142 4.99998 12.692 4.83898 13.864 4.54598C14.292 4.43898 14.658 4.31698 14.957 4.18198C14.701 6.22698 13.31 7.98898 11.333 8.68798C11.133 8.75898 11 8.94798 11 9.15898V11.842C11 12.054 11.134 12.243 11.333 12.313Z"
        fill={color || "#C17E2E"}
      />
      <path
        d="M13.667 16.5291C11.405 15.7301 9.99999 13.8031 9.99999 11.5001V8.47511C10.6 8.41611 11.178 8.25011 11.723 7.98011C11.97 7.85711 12.071 7.55711 11.948 7.31011C11.825 7.06311 11.525 6.96211 11.278 7.08511C10.723 7.36111 10.125 7.50111 9.49999 7.50111C8.87499 7.50111 8.27699 7.36111 7.72199 7.08511C7.47499 6.96211 7.17499 7.06311 7.05199 7.31011C6.92899 7.55711 7.02999 7.85711 7.27699 7.98011C7.82199 8.25111 8.39899 8.41611 8.99999 8.47511V11.5001C8.99999 13.8021 7.59499 15.7291 5.33299 16.5291C5.12299 16.6031 4.98699 16.8081 5.00099 17.0301C5.01499 17.2521 5.17299 17.4391 5.39099 17.4881C6.37299 17.7081 7.86799 18.0001 9.49999 18.0001C11.132 18.0001 12.627 17.7081 13.609 17.4881C13.827 17.4391 13.986 17.2521 13.999 17.0301C14.012 16.8081 13.877 16.6031 13.667 16.5291ZM9.49999 17.0001C8.62299 17.0001 7.79899 16.9131 7.06299 16.7951C7.72799 16.3741 8.30699 15.8431 8.77399 15.2201C9.06699 14.8291 9.30999 14.4081 9.49999 13.9661C9.68999 14.4081 9.93299 14.8291 10.226 15.2201C10.692 15.8421 11.271 16.3731 11.937 16.7951C11.201 16.9121 10.377 17.0001 9.49999 17.0001Z"
        fill={color || "#C17E2E"}
      />
    </svg>
  );
});

export default IconHourglass;

import React, {useCallback, useEffect} from "react";
import {inject, observer} from "mobx-react";
import QRCode from "qrcode.react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
//local
import {Preloader} from "components/Preloader";
import {getMessage} from "helpers/helper";
import {showError, showSuccess} from "helpers/notifications.helper";
import {verificationStatuses} from "constants/seller.consts";
import {SAVE_SEARCH} from "constants/routes.const";
import BankIdVerification from "./components/BankIdVerification";

export const ModalIdentifyOwner = inject("store")(
  observer(({store: {auth, organization}}) => {
    const {activeOrganizationId, qrCodeData} = auth;
    const {t} = useTranslation();
    let navigate = useNavigate();

    const checkBankIdStatus = useCallback(() => {
      switch (auth.bankIdVerifyData.status) {
        case verificationStatuses.failed:
          showError(t("VERIFICATION_FAILED"));
          return getMessage(auth.bankIdVerifyData.userMessage);
        case verificationStatuses.complete:
          showSuccess(t("VERIFICATION_COMPLETED"));
          auth.updateAuthModalName(null);
          organization.setOrganizationOnboardingStatus(activeOrganizationId, {
            bankId: true,
          });
          auth.checkToken().then(() => navigate(SAVE_SEARCH));
          return;
        case verificationStatuses.pending:
          return <BankIdVerification />;
        default:
          return <Preloader addClass="preloader500" />;
      }
    }, [auth, t, organization, activeOrganizationId, navigate]);

    useEffect(() => {
      auth.getBankIdData();
    }, [auth]);

    return (
      <div className="modal-auth modal-identify_wrapper">
        <div className="modal-identify_image">
          {auth?.qrCodeData && <QRCode value={auth?.qrCodeData} size={180} />}
        </div>
        <p className="modal-identify_text">{t("MODAL_IDENTIFY_TEXT")}</p>
        {qrCodeData && (
          <a
            href={`${qrCodeData}&redirect=${window.location.origin}/onboarding`}
            className="modal-identify_link"
          >
            {t("OPEN_BANK_ID_LINK")}
          </a>
        )}
        <div className="modal-identify_spin">{checkBankIdStatus()}</div>
      </div>
    );
  })
);

import React, {useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
//local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import SellerSideProposalDescription from "../RequestView/SellerSideProposalDescription";
import SimpleField from "components/SimpleField";
import {DatePicker} from "formik-antd";
import {IconDocumentPen} from "components/icons";
import {dateTimeFormat} from "helpers/datetime";
import LoadDocsColumn from "components/LoadDocsColumn";
import {ConfirmModal} from "components/ConfirmModal";
import {offerSchema} from "./formAttrs";
import {showSuccess} from "helpers/notifications.helper";
import cn from "classnames";

const SellerSideProposalEdit = ({
  offer,
  request,
  loading,
  onSave,
  onCancel,
  onAddFile,
  onRemoveFile,
}) => {
  const {t} = useTranslation();
  const [uploadLoading, setUploadLoading] = useState(false);

  const offerFiles = useMemo(
    () => offer?.OfferFiles?.filter((item) => !!item.File).map((item) => item.File),
    [offer?.OfferFiles]
  );

  const addFileHandler = (e) => {
    setUploadLoading(true);
    const data = new FormData();
    data.append("file", e.file.originFileObj);
    onAddFile(data).finally(() => setUploadLoading(false));
  };

  const removeFileHandler = (id) => {
    ConfirmModal({
      title: t(id ? "WANT_DELETE_FILE" : "WANT_DELETE_ALL_FILES"),
      text: t(id ? "CANT_DELETE_FILE" : "CANT_DELETE_ALL_FILES"),
      type: "warning",
      onOk: () => removeDocument(id),
    });
  };

  const removeDocument = (id) => {
    onRemoveFile(id);
    showSuccess(t(id ? "FILE_DELETED" : "ALL_FILES_DELETED"));
  };

  return (
    <Formik
      initialValues={{
        price: offer?.price,
        validUntil: offer?.validUntil,
      }}
      validationSchema={offerSchema}
      onSubmit={onSave}
    >
      {({values}) => (
        <Form>
          <div className="main-info">
            <div className="main-info_block">
              <div className="main-info_block_header">{t("OFFER_DATE")}</div>
              <div className="main-info_block_date">
                {dateTimeFormat(offer?.createdAt)}
              </div>
            </div>
            <div className="main-info_block">
              <div className="main-info_block_header">{t("VALID_TILL")}</div>
              <DatePicker
                name="validUntil"
                className={cn("editBlock_date-picker__field wdthp-6", {
                  "bg-yellow": !values.validUntil,
                })}
                placeholder={t("SELECT_DATE")}
              />
            </div>
          </div>
          <div className="main-info">
            <div className="main-info_block">
              <div className="main-info_block_header">{t("OFFER_FROM")}</div>
              <div className="main-info_block_name">
                {offer?.Proposal.Organization.name}
              </div>
            </div>
            <div className="main-info_block">
              <div className="main-info_block_header">{t("TOTAL_OFFER")}</div>
              <SimpleField
                name="price"
                suffixLabel={
                  <span className="__bold ml-2 green">{` ${request.CurrencyCode}`}</span>
                }
                type="number"
                placeholder={t("WRITE_YOUR_PRICE_HERE")}
                errorName="price"
                highlightIfEmpty={!values.price}
                addClass="current-our-offer_edit __field df-row-center wdthp-6 pr-0"
              />
            </div>
          </div>
          <div className="df-row-jce-center mt-8">
            <ButtonTransparent
              type="submit"
              name="SAVE_UPDATE"
              disabled={uploadLoading || !(!!values.price && !!values.validUntil)}
              loading={loading}
              medium
              addClass="mr-8"
              nonTransparent
            />
            <ButtonTransparent
              name="CANCEL"
              disabled={uploadLoading || loading}
              onClick={onCancel}
              medium
            />
          </div>

          <SellerSideProposalDescription offer={offer} />

          <LoadDocsColumn
            title="DOCUMENTS"
            docPlaceholder={"PLACEHOLDER_SELLER_DOCUMENT_COLUMN"}
            titleIcon={<IconDocumentPen />}
            button={"ADD_DOCUMENT"}
            editable
            docs={offerFiles}
            fullWidth
            addFile={addFileHandler}
            removeFile={removeFileHandler}
            canRemoveAllFiles
            addClass="mt-8"
            loadColumnTitle="DOCUMENTS"
            loading={uploadLoading || loading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SellerSideProposalEdit;

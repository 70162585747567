import React, {memo} from "react";

export const IconSignal = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "15"}
      height={height || "15"}
      viewBox="0 0 15 15"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.875 14.25H0.375C0.168 14.25 0 14.082 0 13.875V11.625C0 11.418 0.168 11.25 0.375 11.25H1.875C2.082 11.25 2.25 11.418 2.25 11.625V13.875C2.25 14.082 2.082 14.25 1.875 14.25ZM0.75 13.5H1.5V12H0.75V13.5Z"
        fill={color || "#000"}
      />
      <path
        d="M4.875 14.25H3.375C3.168 14.25 3 14.082 3 13.875V10.125C3 9.918 3.168 9.75 3.375 9.75H4.875C5.082 9.75 5.25 9.918 5.25 10.125V13.875C5.25 14.082 5.082 14.25 4.875 14.25ZM3.75 13.5H4.5V10.5H3.75V13.5Z"
        fill={color || "#000"}
      />
      <path
        d="M7.875 14.25H6.375C6.168 14.25 6 14.082 6 13.875V7.875C6 7.668 6.168 7.5 6.375 7.5H7.875C8.082 7.5 8.25 7.668 8.25 7.875V13.875C8.25 14.082 8.082 14.25 7.875 14.25ZM6.75 13.5H7.5V8.25H6.75V13.5Z"
        fill={color || "#000"}
      />
      <path
        d="M10.875 14.25H9.375C9.168 14.25 9 14.082 9 13.875V4.875C9 4.668 9.168 4.5 9.375 4.5H10.875C11.082 4.5 11.25 4.668 11.25 4.875V13.875C11.25 14.082 11.082 14.25 10.875 14.25ZM9.75 13.5H10.5V5.25H9.75V13.5Z"
        fill={color || "#000"}
      />
      <path
        d="M13.875 14.25H12.375C12.168 14.25 12 14.082 12 13.875V1.125C12 0.918 12.168 0.75 12.375 0.75H13.875C14.082 0.75 14.25 0.918 14.25 1.125V13.875C14.25 14.082 14.082 14.25 13.875 14.25ZM12.75 13.5H13.5V1.5H12.75V13.5Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React from "react";
import {useTranslation} from "react-i18next";
// local
import {openNewTab} from "helpers/helper";
import {IconDownload} from "components/icons";

const OverviewManualCardView = ({
  validDate,
  sinceDate,
  meets,
  firstParameter,
  secondParameter,
  downloadFiles,
}) => {
  const {t} = useTranslation();

  return (
    <div className="insurance-card">
      <p className="insurance-card__first-parameter __manual-data">{t(firstParameter)}</p>
      {secondParameter && (
        <p className="insurance-card__second-parameter __manual-data">
          {t(secondParameter)}
        </p>
      )}
      {validDate && (
        <p className="insurance-card__data __manual-data">
          <span>{t("VALID_TIL")}:</span> {validDate}
        </p>
      )}
      {sinceDate && (
        <p className="insurance-card__data __manual-data">
          <span>{t("SINCE")}:</span> {sinceDate}
        </p>
      )}
      {meets && (
        <p className="insurance-card__meets __manual-data">
          <span>{t("MEETS")}:</span>{" "}
          {meets
            .filter((item) => Object.values(item)[0])
            .map((item) => Object.keys(item)[0])
            .join(", ")}
        </p>
      )}

      {downloadFiles && (
        <div className="insurance-card__download-btn-wrapper">
          {downloadFiles.map((file) => (
            <button
              key={file?.url}
              className="insurance-card__download"
              onClick={() => openNewTab(file?.url)}
            >
              <IconDownload />
              {file.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default OverviewManualCardView;

import React from "react";
import {inject, observer} from "mobx-react";
// local
import {OrganizationCard} from "./OrganizationCard";
import OrganizationCardHead from "./OrganizationCardHead";
import TableTitleResult from "components/Table/TableTitleResult";
import {Preloader} from "components/Preloader";
import cn from "classnames";

const Organizations = inject("store")(
  observer(
    ({
      store: {buyer, organization, auth},
      filteredRecipients,
      addedRecipients,
      onActionClick,
      onChangeOrder,
      onSelectChange,
    }) => {
      const {user} = auth;

      const {favoritesOrganization, blacklistOrganization} = organization;
      const {newPublishedRFPRecipients} = buyer;
      const isResults = !!filteredRecipients?.length;
      const modalAddedRecipientsCount =
        addedRecipients?.length - newPublishedRFPRecipients.notRemoveRegistered?.length;

      return filteredRecipients ? (
        <div className={cn("organizations", {__results: isResults})}>
          <TableTitleResult
            title={"RESULTS"}
            totalItems={filteredRecipients?.length ?? 0}
          />
          <OrganizationCardHead
            onChangeOrder={onChangeOrder}
            total={filteredRecipients?.length ?? 0}
            added={modalAddedRecipientsCount}
            onSelectChange={onSelectChange}
          />
          <div className="organizations__suppliers">
            {isResults &&
              filteredRecipients.map((organization) => (
                <OrganizationCard
                  key={organization.id}
                  cantBeRemoved={newPublishedRFPRecipients.notRemoveRegistered
                    ?.map((item) => item.id)
                    .includes(organization.id)}
                  isAlreadyInRecipients={addedRecipients
                    ?.map((item) => item.id ?? item.SellerId)
                    .includes(organization.id)}
                  organization={organization}
                  favorites={favoritesOrganization}
                  blacklisted={blacklistOrganization}
                  onActionClick={onActionClick}
                  showTitleIcon
                  hasSuppliersQualifications={
                    !!organization.SupplierQualifications?.length
                  }
                  userPermissions={user.permissions}
                  isEnableSuppliersQualification={
                    user.ActiveOrganization?.enableSuppliersQualification
                  }
                />
              ))}
          </div>
        </div>
      ) : (
        <Preloader addClass="maa" />
      );
    }
  )
);

export default Organizations;

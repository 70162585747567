import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
// local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconLifebuoy, IconCog} from "components/icons";
import SelectionRow from "components/selects/SelectionRow";
import {InfoTooltip} from "components/InfoTooltip";
import {availableInArray} from "constants/select.consts";
import {InitialsBadge} from "components/InitialsBadge";
import {Tag} from "components/Tag";
import {roles} from "constants/auth.const";
import {hasUserPermission} from "helpers/helper";

export const Member = ({
  userInfo,
  role,
  subscriptionRole,
  limitProjectNames,
  index,
  isChecked,
  isSettingButton,
  availableIn,
  ownMember,
  onChange,
}) => {
  const {t} = useTranslation();

  const isNotMember = hasUserPermission([roles.ADMIN, roles.OWNER], role);

  const showProjectLimitationsTags = useMemo(
    () =>
      !!subscriptionRole?.SubscribeRolePermissions?.find(
        (perm) =>
          perm.PermissionId === "SHOW_SETTINGS_USER_SETTINGS_MODAL_PROJECT_LIMITATIONS"
      ),
    [subscriptionRole?.SubscribeRolePermissions]
  );

  return (
    <div className="account_member__item">
      {index === 0 && (
        <div className="account_member__item-head">
          <div className="account_member__item-head__name"></div>
          <div className="account_member__item-head__regions">
            {t("ORGANIZATION_TAB_MEMBERS_HEAD_REGIONS")}
            <InfoTooltip
              tooltip="ORGANIZATION_TAB_MEMBERS_HEAD_REGIONS_TOOLTIP"
              centerText
              addClass="ml-2 mr-7"
              addWrapperClass="account_member__item-head__regions-tooltip"
            />
          </div>
        </div>
      )}
      <div className="df-row-center">
        <div className="account_member__item__name">
          <InitialsBadge
            firstName={userInfo.firstName}
            lastName={userInfo.lastName}
            large
            isCurrentUser={ownMember}
            addClass="mr-2"
          />
          <div>
            <div className="df-row-center">
              <h3 className="account_member__item__name__full">{`${userInfo.firstName} ${userInfo.lastName}`}</h3>
              {isNotMember && (
                <Tag
                  text={role}
                  color="discreteText"
                  needTranslation
                  uppercase
                  bold
                  small
                  addClass="ml-2 mr-0"
                />
              )}
              {!!subscriptionRole && (
                <Tag
                  text={subscriptionRole?.name}
                  color="discreteText"
                  needTranslation
                  uppercase
                  bold
                  small
                  noBackground
                  addClass="ml-2 mr-0"
                />
              )}
              {showProjectLimitationsTags &&
                limitProjectNames
                  .slice(0, 2)
                  .map((name, i) => (
                    <Tag
                      key={`${name}_${i}`}
                      text={name}
                      color="purple"
                      uppercase
                      bold
                      small
                      noBackground
                      addClass={i ? "" : "ml-4"}
                    />
                  ))}
              {showProjectLimitationsTags && limitProjectNames.length > 2 && (
                <span className="fz-12">{`+${limitProjectNames.length - 2}`}</span>
              )}
              {isChecked && <IconLifebuoy addClass="ml-2" />}
            </div>
            <p className="account_member__item__name__email">{userInfo.email}</p>
          </div>
        </div>
        <div className="account_member__item__regions">
          {!!availableIn?.length && (
            <SelectionRow
              values={availableIn.map((item) => ({
                value: item,
                label: item,
                color: "green",
              }))}
              canSelectAllValue={{
                count: availableInArray.length,
                item: {
                  label: "FIRST_SETUP_REGION_00",
                  value: "FIRST_SETUP_REGION_00",
                  color: "green",
                },
              }}
              small
              nonStretch
              maxItems={1}
              addClassItem="mb-0"
            />
          )}
          {isSettingButton ? (
            <ButtonTransparent
              addClass="pr-0 pl-0 ml-4"
              icon={<IconCog />}
              noBorder
              tiny
              onClick={onChange}
            />
          ) : (
            <div className="df ml-4">
              <IconCog width="16" height="16" color="#d8d8d8" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
// local
import {Modal} from "components/Modal";

export const ModalAdvertisementMain = ({links, timeout, type, onClose}) => {
  const {t} = useTranslation();
  const [isCloseButton, setShowCloseButton] = useState(false);
  const [currentLink] = useState(links[Math.floor(Math.random() * links.length)]);

  useEffect(() => {
    setTimeout(() => setShowCloseButton(true), timeout);
  }, [links.length, timeout]);

  const advertisementTitle = (
    <span>
      {t("ADVERTISEMENT_VIDEO_MODAL_SUPPORT_LABEL_1")}
      <a
        href={`mailto:sales@handyday.com?subject=${t(
          "ADVERTISEMENT_VIDEO_MODAL_SUPPORT_SUBJECT_1"
        )}`}
      >
        sales@handyday.com
      </a>
    </span>
  );

  return (
    <Modal
      titleIcon={null}
      customTitle={advertisementTitle}
      closeButtonText="ADVERTISEMENT_VIDEO_MODAL_CLOSE_TEXT"
      noCloseButton={!isCloseButton}
      transparentTitle
      noCloseOnEsc
      width={1280}
      rounded
      onClose={onClose}
      addClass="advertisement-video-modal"
    >
      <React.Fragment>
        {type === "image" && (
          <React.Fragment>
            {currentLink.link ? (
              <a href={currentLink.link} target="__blank" onClick={onClose}>
                <img
                  src={currentLink.AdvertisementImageFile?.File?.url}
                  alt="advertisement"
                />
              </a>
            ) : (
              <img
                src={currentLink.AdvertisementImageFile?.File?.url}
                alt="advertisement"
              />
            )}
          </React.Fragment>
        )}
        {(type === "video" || type === "vimeo") && (
          <iframe
            src={currentLink.linkDesktop}
            title="Vimeo video player"
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </React.Fragment>
    </Modal>
  );
};

import React from "react";
//local
import {SkeletonBase} from "components/SkeletonBase";

export const SkeletonRectValue = ({children, ...props}) => {
  return (
    <SkeletonBase
      width={16}
      height={20}
      addClassItem="mr-1"
      svg={<rect x="0.5" width="15" height="20" rx="3" />}
      {...props}
    >
      {children}
    </SkeletonBase>
  );
};

import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
//local
import {TextBadge} from "components/TextBadge";
import {toRequestToUsId} from "constants/routes.const";
import {dateFormat} from "helpers/datetime";
import {IconCheckMini} from "components/icons";
import {color} from "constants/color.consts";

const OurOfferTableItem = ({offer}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const projectName = offer.Proposal.Request.ProjectItem?.Project?.name;

  const handleRedirectOnClick = () => {
    const path = toRequestToUsId(offer.ProposalId);
    navigate(path, {state: {from: "offers", offerId: offer.id}});
  };

  return (
    <div className="requests-card_body" onClick={handleRedirectOnClick}>
      <div className="requests-card_body__name __wrapper">
        {offer.Proposal.Request.Buyer.name}
      </div>
      <div className="requests-card_body__project __wrapper df-row-center">
        {!!projectName ? (
          <TextBadge color="purple" text={projectName} addClass={"__bold"} />
        ) : (
          "-"
        )}
      </div>
      <div className="requests-card_body__column __wrapper title">
        {offer.Proposal.Request.name}
      </div>
      <div className="requests-card_body__column __wrapper date">
        {dateFormat(offer.Proposal.createdAt)}
      </div>
      <div className="requests-card_body__status __wrapper df-row-center">
        <span className="df-row-center mr-4">
          <IconCheckMini
            addClass="mr-1"
            color={color[offer.readed ? "green" : "discreteText"]}
          />
          {offer.readed ? (
            <span className="status-read">{t("READ")}</span>
          ) : (
            <span className="status-submitted">{t("OFFER_STATUS_SUBMITTED")}</span>
          )}
        </span>
        {offer.evaluate === true && (
          <TextBadge text={t("EVALUATING")} color="green" className="ml-2" />
        )}
        {offer.evaluate === false && (
          <TextBadge text={t("DECLINED")} color="red" className="ml-2" />
        )}
      </div>
    </div>
  );
};

export default OurOfferTableItem;

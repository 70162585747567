import React, {memo} from "react";

export const IconChevronRight = memo(({color, height, width}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00001 20C4.87201 20 4.74401 19.951 4.64601 19.854C4.45101 19.659 4.45101 19.342 4.64601 19.147L13.292 10.501L4.64601 1.85499C4.45101 1.65999 4.45101 1.34299 4.64601 1.14799C4.84101 0.95299 5.15801 0.95299 5.35301 1.14799L14.353 10.148C14.548 10.343 14.548 10.66 14.353 10.855L5.35301 19.855C5.25501 19.953 5.12701 20.001 4.99901 20.001L5.00001 20Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconHand = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "18"}
      height={height || "20"}
      viewBox="0 0 18 20"
      className={addClass}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9991 4C15.6351 4 15.2931 4.098 14.9991 4.269V3C14.9991 1.897 14.1021 1 12.9991 1C12.5871 1 12.2051 1.125 11.8861 1.339C11.6121 0.56 10.8701 0 9.99911 0C9.12811 0 8.38511 0.56 8.11211 1.339C7.79411 1.125 7.41111 1 6.99911 1C5.89611 1 4.99911 1.897 4.99911 3V10.373L3.65311 8.04C3.39211 7.565 2.96611 7.227 2.45411 7.087C1.95511 6.951 1.43611 7.023 0.992107 7.289C0.085107 7.833 -0.260893 9.063 0.222107 10.031C0.252107 10.092 0.890107 11.399 2.88211 15.381C3.82011 17.256 4.84911 18.597 5.94111 19.365C6.79811 19.968 7.39011 19.999 7.50011 19.999H12.5001C13.3511 19.999 14.1421 19.722 14.8521 19.177C15.5191 18.664 16.0981 17.922 16.5721 16.973C17.5061 15.105 18.0001 12.521 18.0001 9.499V5.999C18.0001 4.896 17.1031 3.999 16.0001 3.999L15.9991 4ZM16.9991 9.5C16.9991 12.367 16.5421 14.796 15.6771 16.526C15.1131 17.654 14.1031 19 12.4991 19H7.50911C7.47011 18.997 7.04911 18.95 6.41411 18.475C5.78111 18.001 4.80911 17.003 3.77611 14.935C1.74911 10.881 1.12611 9.604 1.12011 9.592C1.11911 9.59 1.11911 9.589 1.11811 9.588C0.867107 9.085 1.04511 8.426 1.50711 8.149C1.71511 8.024 1.95811 7.991 2.19211 8.054C2.44111 8.122 2.65011 8.29 2.77911 8.526C2.78111 8.529 2.78311 8.533 2.78511 8.536L4.34611 11.241C4.66511 11.824 5.02411 12.069 5.41311 11.97C5.80311 11.871 6.00011 11.481 6.00011 10.813V3C6.00011 2.449 6.44911 2 7.00011 2C7.55111 2 8.00011 2.449 8.00011 3V9.5C8.00011 9.776 8.22411 10 8.50011 10C8.77611 10 9.00011 9.776 9.00011 9.5V2C9.00011 1.449 9.44911 1 10.0001 1C10.5511 1 11.0001 1.449 11.0001 2V9.5C11.0001 9.776 11.2241 10 11.5001 10C11.7761 10 12.0001 9.776 12.0001 9.5V3C12.0001 2.449 12.4491 2 13.0001 2C13.5511 2 14.0001 2.449 14.0001 3V10.5C14.0001 10.776 14.2241 11 14.5001 11C14.7761 11 15.0001 10.776 15.0001 10.5V6C15.0001 5.449 15.4491 5 16.0001 5C16.5511 5 17.0001 5.449 17.0001 6V9.5H16.9991Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

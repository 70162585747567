import React, {useEffect, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import {ONBOARDING, INVITE_ONBOARDING} from "../constants/routes.const";
import {IconClose, IconHandyDay} from "components/icons";
import {contractModalNames} from "constants/modal.conts";
import cn from "classnames";

export const Modal = inject("store")(
  observer(
    ({
      store: {auth},
      titleIcon = <IconHandyDay />,
      suffixTitleIcon,
      titleText,
      closeButtonText,
      customTitle,
      noTitle,
      noTitleHeader,
      children,
      style,
      width,
      percentageWide,
      boldTitle,
      whiteTitle,
      largeTitle,
      hugeTitle,
      tightTitle,
      transparentTitle,
      rounded,
      darked,
      placeBitLow,
      placeBitHigh,
      noCloseButton,
      noCloseOnEsc,
      noCenterContent,
      contentClassName,
      customFooter,
      transparentFooter,
      needAdditionalInnerActions,
      onClick,
      onClose,
      addClass,
      addBaseClass,
    }) => {
      const keyEventName = "keydown";
      const {t} = useTranslation();
      const clickListener = (func) => document.addEventListener(keyEventName, func);
      const clickListenerRemove = (f) => document.removeEventListener(keyEventName, f);

      const styles = {
        width: percentageWide
          ? percentageWide
          : width === "min"
          ? "min-content"
          : width === "max"
          ? "max-content"
          : `${width}px`,
        minWidth: percentageWide ? `${width}px` : "none",
        ...style,
      };

      const paths = useMemo(() => [ONBOARDING, INVITE_ONBOARDING], []);
      // inverted noCloseButton for onboarding paths
      const hasCloseButton = useMemo(
        () => (paths.includes(window.location.pathname) ? noCloseButton : !noCloseButton),
        [noCloseButton, paths]
      );

      useEffect(() => {
        const closeOnEsc = (event) => {
          if (event.keyCode === 27 && window.location.pathname !== ONBOARDING) {
            // if the modal window with BankID signing is closed
            auth.modalName === contractModalNames.identifyContractPerson &&
              auth.getBankIdCancelOnContract();
            auth.updateAuthModalName();
          }
          event.keyCode === 27 && onClose && onClose();
        };

        !noCloseOnEsc && clickListener(closeOnEsc);
        return () => clickListenerRemove(closeOnEsc);
      }, [auth, noCloseOnEsc, onClose]);

      return (
        <div
          onClick={(e) => (needAdditionalInnerActions ? e.stopPropagation() : undefined)}
          style={{cursor: "auto"}}
        >
          <div
            className={cn("modal-ent", {
              "__place-low": !!placeBitLow,
              __darked: !!darked,
              "__place-high": !!placeBitHigh,
              "__transparent-title": !!transparentTitle,
              [addBaseClass]: !!addBaseClass,
            })}
          >
            <div
              className={cn("modal-ent__wrapper", {[addClass]: !!addClass})}
              style={styles}
            >
              {!noTitleHeader && (
                <div
                  className={cn(
                    "modal-ent__header",
                    {__bold: !!boldTitle},
                    {"__white-bg": !!whiteTitle},
                    {"__large-title": !!largeTitle},
                    {"__tight-title": !!tightTitle},
                    {"__huge-title": !!hugeTitle},
                    {__rounded: !!rounded}
                  )}
                >
                  {!customTitle && !noTitle && titleIcon}
                  {t(titleText)}
                  {customTitle}
                  {suffixTitleIcon}
                  {hasCloseButton && (
                    <button
                      onClick={onClick ?? onClose}
                      className="modal-ent__close-modal"
                    >
                      {t(closeButtonText)}
                      <IconClose addClass="ml-2" />
                    </button>
                  )}
                </div>
              )}
              <div
                className={cn("modal-ent__content", {
                  [contentClassName]: !!contentClassName,
                  "__left-text-content": !!noCenterContent,
                  __rounded: !!rounded,
                })}
              >
                {children}
              </div>
              {customFooter && (
                <div
                  className={cn(
                    "modal-ent__footer",
                    {"__transparent-footer": !!transparentFooter},
                    {__rounded: !!rounded}
                  )}
                >
                  {customFooter}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  )
);

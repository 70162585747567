import React from "react";
import {useTranslation} from "react-i18next";
//local
import {IconBullHorn} from "components/icons";
import {IconHand} from "components/icons";
import {color} from "constants/color.consts";

const FeedHeader = () => {
  const {t} = useTranslation();

  return (
    <div className="feed-header__wrapper">
      <div className="feed-header__title">
        <div className="feed-header__title__main-wrapper">
          <IconBullHorn size="40" />
          <h3 className="custom-title __uppercase">{t("SAY_SOMETHING_PAGE_TITLE")}</h3>
        </div>
        <span className="feed-header__title__sub-text sub-title">
          {t("SAY_SOMETHING_PAGE_SUBTITLE")}
        </span>
      </div>

      <div className="feed-header__description">
        <div className="feed-header__description__item-wrapper">
          <span className="feed-header__description__item-target">
            {t("SAY_SOMETHING_DESCRIPTION_1_1")}
          </span>
          <div className="feed-header__description__item-maintext">
            <span className="feed-header__description__item-maintext__fake-button __buyer">
              <IconBullHorn size="16" color={color.link} />
              {t("SAY_SOMETHING_DESCRIPTION_1_2_1")}
            </span>
            {t("SAY_SOMETHING_DESCRIPTION_1_2_2")}
          </div>
        </div>
        <div className="feed-header__description__item-wrapper">
          <span className="feed-header__description__item-target">
            {t("SAY_SOMETHING_DESCRIPTION_2_1")}
          </span>
          <div className="feed-header__description__item-maintext">
            <span className="feed-header__description__item-maintext__fake-button __seller">
              <IconHand width="14.4" height="16" color={color.green} />
              {t("SAY_SOMETHING_DESCRIPTION_2_2_1")}
            </span>
            {t("SAY_SOMETHING_DESCRIPTION_2_2_2")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedHeader;

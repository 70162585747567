import React from "react";
import {Select as AntFormikSelect} from "formik-antd";
import {useTranslation} from "react-i18next";
import {ErrorMessage} from "formik";
// local
import {checkField} from "helpers/helper";
import {InfoTooltip} from "components/InfoTooltip";
import cn from "classnames";

const {Option} = AntFormikSelect;

const FormikSelect = ({
  name,
  title,
  label,
  tooltip,
  list,
  addClass,
  showArrow,
  disabled,
  bigSelect,
  placeholder,
  fullWidth,
  showSearch,
  onChange,
  isDefault,
}) => {
  const {t} = useTranslation();

  const options =
    list.length &&
    list.map(({value, label}, i) => (
      <Option key={label + i} value={value}>
        {t(label)}
      </Option>
    ));

  return (
    <div className={addClass}>
      {title && <p className="selection__title">{t(title)}</p>}
      {label && (
        <label className="label df">
          {t(label)}
          {tooltip && <InfoTooltip tooltip={tooltip} centerText addClass="ml-2 mr-2" />}
        </label>
      )}
      <div
        className={cn("multi-select__wrapper", {
          "__big-select": !!bigSelect,
          "__full-width": !!fullWidth,
        })}
      >
        <AntFormikSelect
          showAction={"focus"}
          placeholder={placeholder}
          showArrow={showArrow}
          onChange={onChange}
          showSearch={showSearch}
          disabled={disabled}
          name={name}
          filterOption={(input, option) =>
            (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
          }
          defaultValue={isDefault && list[0].value}
        >
          {options}
        </AntFormikSelect>
        {name && (
          <ErrorMessage name={name}>
            {(msg) => <p className="form-error">{checkField(msg)}</p>}
          </ErrorMessage>
        )}
      </div>
    </div>
  );
};
export default FormikSelect;

import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
// local
import {Modal} from "components/Modal";
import Organizations from "pages/OurRequests/components/RecipientsEdit/Organizations";
import MultipleSelect from "components/selects/MultipleSelect";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import SelectionRow from "components/selects/SelectionRow";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {ColorRow} from "components/ColorRow";
import {IconFunnel, IconCheckMark, IconCross} from "components/icons";
import {rfpRecipientsFilterRow} from "constants/tabs.const";
import {color} from "constants/color.consts";

export const ModalRecipientsFilter = ({
  onClose,
  filteredRecipients,
  addedRecipients,
  filterParams,
  filterRowItems,
  onSelectChange,
  onChangeOrder,
  onActionClick,
  onRemoveSelection,
}) => {
  const {t} = useTranslation();

  useEffect(() => {
    filterParams.availableIn?.length < 2 &&
      !!filterParams.localSuppliers &&
      onSelectChange("localSuppliers", false);
  }, [filterParams.availableIn, filterParams.localSuppliers, onSelectChange]);

  return (
    <Modal
      noTitleHeader
      rounded
      onClose={onClose}
      addClass="recipients-tab__filtered-recipients-modal"
    >
      <ColorRow color="green" title="FIND_TYPE_REGION_LABEL">
        <IconFunnel
          height="30"
          width="30"
          color={color.green}
          addClass="recipients-tab_row-icon ma-4"
        />
        <div className="recipients-tab__filtered-recipients-modal__color-row-content">
          <div className="df-row-center mt-2">
            {rfpRecipientsFilterRow.map((selection) => (
              <MultipleSelect
                key={selection.label}
                value={filterParams?.[selection.label]}
                list={selection.array}
                customSelectAllElement={selection.selectAll}
                tiny
                addClass="mr-2"
                uppercaseButton
                notShowingSelections
                needSearch={selection.isNeedSearch}
                color={selection.color}
                bottomElement={selection.bottomElement}
                onChange={(value) => onSelectChange(selection.label, value)}
                title={selection.title}
                titleInButton
              />
            ))}
            <CustomRadioSwitch
              label={
                <div className="df-row-center">
                  <IconCheckMark height={15} width={15} addClass="mr-1" />
                  {t("FILTERROW_OURSUPPLIERS_LABEL")}
                </div>
              }
              checked={filterParams?.favorite}
              uppercase
              small
              addClass="ml-8"
              isActive={(checked) => onSelectChange("favorite", checked)}
            />
            <ButtonTransparent
              addClass="recipients-tab__filtered-recipients-modal__done-button"
              nonTransparent
              name={t("DONE")}
              icon={<IconCross color={color.white} width="20" height="20" />}
              onClick={onClose}
            />
          </div>

          <SelectionRow
            values={filterRowItems.map((item) => ({
              value: item[1],
              label: item[1],
              color: item[1].includes("REGION") ? "blue" : "green",
            }))}
            tiny
            addClass="mt-2 mb-2"
            onRemove={onRemoveSelection}
            suffixContent={
              !!filterParams.availableIn?.length ? (
                <CustomRadioSwitch
                  checked={
                    !!filterParams.availableIn?.length
                      ? filterParams.localSuppliers
                      : false
                  }
                  uppercase
                  label={t("FIRST_SETUP_SELECT_LOCAL_SUPPLIERS")}
                  small
                  addWrapperClass="ml-2 mba"
                  wrapperStyle={{marginTop: "3px"}}
                  informationText="FIRST_SETUP_SELECT_LOCAL_SUPPLIERS_TOOLTIP"
                  isActive={() =>
                    onSelectChange("localSuppliers", !filterParams.localSuppliers)
                  }
                />
              ) : null
            }
          />
        </div>
      </ColorRow>
      <div className="recipients-tab__filtered-recipients-modal__content_main">
        <div className="recipients-tab__filtered-recipients-modal__content_strip"></div>
        <Organizations
          filteredRecipients={filteredRecipients}
          addedRecipients={addedRecipients}
          onChangeOrder={onChangeOrder}
          onSelectChange={onSelectChange}
          onActionClick={onActionClick}
        />
      </div>
      <div className="recipients-tab__filtered-recipients-modal__bottom-gradient"></div>
    </Modal>
  );
};

import * as yup from "yup";
import {stringRequired} from "yup/defaultValudations";

export const schema = yup.object().shape({
  name: stringRequired(),
  startedAt: yup.date().min(new Date()).required(),
  location: stringRequired(),
  description: stringRequired(),
  locationLat: yup.number(),
  locationLng: yup.number(),
});

import React from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
// local
import PresentationPageLogo from "./PresentationPageLogo";
import PresentationPageLogoEdit from "./PresentationPageLogoEdit";
import {getDomain, openNewTab} from "helpers/helper";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import IconLaptopPhone from "components/icons/IconLaptopPhone";
import {color} from "constants/color.consts";

const PresentationPageHeaderLeft = inject("store")(
  observer(({store: {organization}, edit, setEdit}) => {
    const {t} = useTranslation();

    if (edit.logo) {
      const closeEdit = () => setEdit({...edit, logo: false});

      return (
        <div className="presentation-page-header__left-side presentation-header-edit">
          <PresentationPageLogoEdit />
          <ButtonTransparent
            type="button"
            name={t("CLOSE")}
            addClass="editBlock_head__btn"
            onClick={closeEdit}
          />
        </div>
      );
    }

    const domain = getDomain(organization?.organizationDetail?.url);
    const openEdit = () => setEdit({...edit, logo: true});

    return (
      <div className="presentation-page-header__left-side">
        <PresentationPageLogo
          logotype={organization?.organizationDetail.logotype}
          isActive={organization?.organizationDetail.isActive}
          openEdit={openEdit}
        />
        {domain ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            onClick={() => openNewTab(organization?.organizationDetail.url)}
            className="presentation-page-header__page-link"
            rel="noopener noreferrer"
          >
            <IconLaptopPhone />
            {domain}
          </a>
        ) : (
          <p className="presentation-page-header__page-link __no-link">
            <IconLaptopPhone color={color.midGray} />
            {t("NO_WEB_PAGE")}
          </p>
        )}
      </div>
    );
  })
);

export default PresentationPageHeaderLeft;

import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {inject, observer} from "mobx-react";
// local
import {Modal} from "components/Modal";
import {NEW_REQUEST} from "constants/routes.const";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import ProjectsSelect from "components/selects/ProjectsSelect";
import Select from "components/selects/Select";
import {IconFileAdd, IconCopy, IconChevronDown} from "components/icons/index";
import {color} from "constants/color.consts";

export const RFPCreateModal = inject("store")(
  observer(({store: {auth, buyer}, projects, project, procurement, onClose}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {user} = auth;
    const [loadingCopy, setLoadingCopy] = useState(false);

    const getRFPList = (project) =>
      project?.ProjectItems?.map((item) => ({
        id: item.id,
        value: item.Request.id,
        label: item.Request.name,
      })) ?? [];

    const [selectedProject, SetSelectedProject] = useState(
      projects.find((item) => item.id === project.id) ?? projects[0]
    );

    const [rfpList, setRfpList] = useState(getRFPList(selectedProject));
    const [selectedRFP, SetSelectedRFP] = useState(rfpList[0]);

    const toNewRFP = () =>
      navigate(NEW_REQUEST, {
        state: {
          requestId: "draft-request",
          project,
          projectItemId: procurement.id,
          rfpName: procurement.name,
          ContactPersonId: procurement.UserId,
        },
      });

    const createFromScratch = () =>
      buyer.getCurrentRequestInfo("draft-request").then(toNewRFP);

    const createFromCopy = async () => {
      setLoadingCopy(true);
      await buyer.getCurrentRequestInfo("copy-request", selectedRFP.value);
      buyer
        .duplicateRFPDocuments(selectedRFP.value)
        .then((data) => {
          buyer.updateCurrentRequestInfo({documents: data}, false);
          toNewRFP();
        })
        .finally(() => setLoadingCopy(false));
    };

    const onChangeProject = (id) => {
      const currentProject = projects.find((item) => item.id === id);
      SetSelectedProject(currentProject);
      setRfpList(getRFPList(currentProject));
      SetSelectedRFP(getRFPList(currentProject)[0]);
    };

    const onChangeRFP = (value) =>
      SetSelectedRFP(rfpList.find((item) => item.value === value));

    return (
      <Modal
        noTitle
        boldTitle
        titleText="CREAT_RFP"
        rounded
        placeBitLow
        style={{marginTop: "15vh"}}
        percentageWide="45%"
        width="630"
        onClose={onClose}
      >
        <div className="rfp-create-modal__content">
          <div className="rfp-create-modal_description">
            {t("CREATE_RFP_MODAL_DESCRIPTION_01")}
          </div>
          <div className="rfp-create-modal_description mb-8">
            {t("CREATE_RFP_MODAL_DESCRIPTION_02")}
          </div>
          <div className="df">
            <div className="rfp-create-modal_create-block mr-8">
              <span className="rfp-create-modal_create-block_title">
                {t("CREATE_RFP_MODAL_TITLE_01")}
              </span>
              <div className="rfp-create-modal_create-block_data">
                <ButtonTransparent
                  name="CREATE_RFP_MODAL_BUTTON_CREATE"
                  icon={<IconFileAdd />}
                  nonTransparent
                  fullWidth
                  color="green"
                  addClass="rfp-create-modal_create-block_button"
                  onClick={createFromScratch}
                />
              </div>
            </div>
            <div className="rfp-create-modal_create-block">
              <span className="rfp-create-modal_create-block_title">
                {t("CREATE_RFP_MODAL_TITLE_02")}
              </span>
              <div className="rfp-create-modal_create-block_data">
                <ProjectsSelect
                  list={projects}
                  onChange={onChangeProject}
                  highlightMyProjectsId={user.id}
                  noBorder
                  fullWidth
                  large
                  customIcon={<IconChevronDown color={color.purple} />}
                  defaultValue={
                    projects.find((item) => item.id === project.id)?.id ??
                    projects[0]?.id ??
                    null
                  }
                />
                <Select
                  name="rfp-select"
                  list={rfpList}
                  showArrow
                  shadow
                  large
                  fullWidth
                  suffixIcon={<IconChevronDown />}
                  value={selectedRFP?.value}
                  onChange={onChangeRFP}
                  addClass="mt-2 mb-4"
                />
                <ButtonTransparent
                  name="CREATE_RFP_MODAL_BUTTON_COPY"
                  icon={<IconCopy color={color.white} />}
                  loading={loadingCopy}
                  nonTransparent
                  fullWidth
                  onClick={createFromCopy}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  })
);

import axios from "axios";
import {showError} from "helpers/notifications.helper";
import {getStorage} from "helpers/storage";
import {errorException} from "constants/error.consts";

let baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({baseURL});

axiosInstance.interceptors.request.use((config) => {
  const Authorization = `jwt ${getStorage("token")}`;
  const apikey = process.env.REACT_APP_API_KEY;
  config.headers = {Authorization, apikey};
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response && error.message === "Network Error")
      return Promise.reject(error);
    if (!error.response && error.message) return Promise.reject(showError(error.message));
    const {
      response: {status, data},
    } = error;

    if (status && status === 401) {
      localStorage.clear();
      window.location.pathname = "/";
    }

    if (
      data &&
      data.error &&
      typeof data.error === "string" &&
      !errorException.includes(data.error)
    ) {
      showError(data.error);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

import React, {memo} from "react";
import {useTranslation} from "react-i18next";
//local
import {IconFunnel, IconCross} from "components/icons";
import {Input} from "components/Input";
import {ButtonTransparent} from "../buttons/ButtonTransparent";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {VideoInstructionLink} from "components/VideoInstructionLink";
import {color} from "constants/color.consts";

export const DecorativeSearch = memo(
  ({
    name,
    resetEvent,
    icon,
    changeCheck,
    isFilterVisible,
    defaultChecked,
    searchFocus,
    searchOnChange,
    searchPlaceholder,
    searchLabel,
    hideEntireFilter,
    showSearch,
    videoLinkPrefix,
  }) => {
    const {t} = useTranslation();
    return (
      <div className="decorative-search">
        {icon}
        <h3 className="custom-title __uppercase">{t(name)}</h3>
        {videoLinkPrefix && (
          <VideoInstructionLink
            label={`VIDEO_LINK_${videoLinkPrefix}_LABEL`}
            link={`VIDEO_LINK_${videoLinkPrefix}_LINK`}
            addClass="ml-8 mr-4"
          />
        )}
        {showSearch && !hideEntireFilter && (
          <div className="find-suppliers__inp-name">
            {
              //<label>{searchLabel}</label>
            }

            <Input
              focus={searchFocus}
              small
              onChange={searchOnChange}
              placeholder={searchPlaceholder}
            />
          </div>
        )}
        {resetEvent && !hideEntireFilter ? (
          <div className="decorative-search__buttons">
            {isFilterVisible && (
              <ButtonTransparent
                name={t("CLEAR_FILTERS_BUTTON")}
                onClick={resetEvent}
                icon={<IconCross color={color.link} width="7" height="7" />}
                addClass="__uppercase"
                tiny
              />
            )}
            <CustomRadioSwitch
              defaultChecked={defaultChecked}
              addClass="ml-8 mr-2"
              isActive={changeCheck}
            />
            <IconFunnel />
          </div>
        ) : null}
      </div>
    );
  }
);

import React from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import {Layout} from "components/UI/layout";
import RfpView from "./RfpView";
import ContactCard from "components/ContactCard";
import cn from "classnames";

export const JoinOrganization = inject("store")(
  observer(({store: {auth}}) => {
    const {t} = useTranslation();
    const {joinRequest, user} = auth;
    const hasRfp = !!user.rfpRequests?.length;
    const name = joinRequest[0]?.name ?? t("DIRECT_INVITE_EMPTY_MESSAGE");
    const message =
      joinRequest[0]?.OrganizationJoinRequest?.joinMessage ??
      t("DIRECT_INVITE_EMPTY_MESSAGE");

    return (
      <Layout>
        <div className="joining-organization">
          {hasRfp && <RfpView rfp={user.rfpRequests[0]} />}
          <div className={cn("organization-info__wrapper", {"has-rfp": hasRfp})}>
            {hasRfp && (
              <div className="submit-offer-info">
                <p className="__bold">{t("JOINING_ORGANIZATION_OFFER_TITLE_01")}</p>
                <p className="mb-0">
                  {t("JOINING_ORGANIZATION_OFFER_DESC_01A")} {name}{" "}
                  {t("JOINING_ORGANIZATION_OFFER_DESC_01B")}
                </p>
                {/* hiding this for now */}
                {/* 
                <p className="__bold">{t("JOINING_ORGANIZATION_OFFER_TITLE_02")}</p>
                <p className="mb-0">{t("JOINING_ORGANIZATION_OFFER_DESC_02")}</p>
                */}
              </div>
            )}
            <div className="organization-info">
              <div>{t("YOUR_REQUEST")}</div>
              <div className="orange __bold fz-24 mt-2 mb-4">{name}</div>

              <p className="__bold">
                {t("JOINING_ORGANIZATION_CONTACTS_TITLE_01A")} {name}{" "}
                {t("JOINING_ORGANIZATION_CONTACTS_TITLE_01B")}
              </p>

              <div className={cn("organization-info__contacts", {"has-rfp": hasRfp})}>
                {joinRequest[0]?.Users?.map((user) => (
                  <ContactCard
                    key={user.id}
                    contact={{...user, User: user}}
                    hasInitials
                    maxWidthFitContent
                    blackContent
                    addClass="organization-info__contact-card"
                  />
                ))}
              </div>
              <p className="__bold mb-2 mt-2">{t("JOINING_ORGANIZATION_MESSAGE")}</p>
              <div className="organization-info__message">{message}</div>
              <p className="__bold mt-4 mb-0">
                {t("JOINING_ORGANIZATION_DESC_TITLE_01")}
              </p>
              <p className="__bold mb-4 mt-0">
                {t("JOINING_ORGANIZATION_DESC_TITLE_02")}
              </p>
              <p className="mb-4">{t("JOINING_ORGANIZATION_DESC_01")}</p>
              {hasRfp && (
                <p className="mb-4">
                  {`${t("JOINING_ORGANIZATION_DESC_02")} ${
                    user.rfpRequests[0].ContactPerson.email
                  }`}
                </p>
              )}
              <p className="mb-0">{t("JOINING_ORGANIZATION_DESC_03")}</p>
            </div>
          </div>
        </div>
      </Layout>
    );
  })
);

import React, {forwardRef} from "react";
import {useTranslation} from "react-i18next";
import cn from "classnames";
import {Preloader} from "../Preloader";
import {CustomTooltip} from "components/CustomTooltip";

export const ButtonTransparent = forwardRef((props, ref) => {
  const {
    name,
    additionalName,
    type,
    onClick,
    onIconClick,
    img,
    icon,
    imgRight,
    iconRight,
    addClass,
    disabled,
    loading,
    style,
    nonTransparent,
    large,
    medium,
    small,
    tiny,
    noBorder,
    rounded,
    circular,
    uppercase,
    bold,
    color,
    fullWidth,
    hoverable,
    hoverableLink,
    clickable,
    nowrap,
    disabledTransparent,
    showOnHover,
    iconButton,
    tooltip,
    disabledTooltip,
    tooltipPlacement,
  } = props;

  const {t} = useTranslation();
  const styles = {
    background: disabled && !disabledTransparent && !loading && "#eaeaea",
    color: disabled && "#cbcbcb",
    borderColor: disabled && "#A9A9A9",
    ...style,
  };

  const ImgIconComponent = ({children}) =>
    onIconClick ? (
      <div className="df cpointer" onClick={onIconClick}>
        {children}
      </div>
    ) : (
      <React.Fragment>{children}</React.Fragment>
    );

  const CommonButton = () => (
    <React.Fragment>
      {(img || icon) && (
        <ImgIconComponent>{(img && <img src={img} alt="" />) || icon}</ImgIconComponent>
      )}
      {!!(name || additionalName) && (
        <span>
          {t(name)} {additionalName}
        </span>
      )}
      {(imgRight || iconRight) && (
        <ImgIconComponent>
          {(imgRight && <img src={imgRight} alt="" />) || iconRight}
        </ImgIconComponent>
      )}
    </React.Fragment>
  );

  return (
    <button
      className={cn(
        "btn-custom_main btn-transparent",
        {[addClass]: !!addClass},
        {"__full-bg": nonTransparent},
        {"__center-loader": loading},
        {__large: large},
        {__medium: medium},
        {__small: small},
        {__tiny: tiny},
        {__rounded: rounded},
        {__circular: circular},
        {__uppercase: uppercase},
        {__bold: bold},
        {[`__${color}`]: !!color},
        {"__non-clickable": !(!!onClick || type === "submit" || clickable)},
        {"__full-width": fullWidth},
        {"__no-border": noBorder},
        {"__show-on-hover": showOnHover},
        {__hoverable: !!hoverable},
        {"__hoverable-link": !!hoverableLink},
        {"__icon-button": iconButton},
        {"__no-wrap": !!nowrap},
        {"__image-right": imgRight || iconRight}
      )}
      onClick={onClick}
      style={styles}
      type={type || "button"}
      disabled={disabled || loading}
      ref={ref}
    >
      {loading ? (
        <Preloader size="default" addClass="mt-1" />
      ) : tooltip ? (
        <CustomTooltip text={tooltip} placement={tooltipPlacement}>
          <span style={{height: "inherit"}} className="df-row-center">
            <CommonButton />
          </span>
        </CustomTooltip>
      ) : disabledTooltip && disabled ? (
        <CustomTooltip text={disabledTooltip} placement={tooltipPlacement}>
          <span style={{height: "inherit"}} className="df-row-center">
            <CommonButton />
          </span>
        </CustomTooltip>
      ) : (
        <CommonButton />
      )}
    </button>
  );
});

import React from "react";
import {PROJECTS, PUBLIC_RFTS} from "constants/routes.const";
import {Link} from "react-router-dom";

const Logo = ({activeLogo, isBuyer}) => {
  return (
    <div className="header__logo-wrapper">
      {activeLogo ? (
        <Link to={isBuyer ? PROJECTS : PUBLIC_RFTS}>
          <img src={activeLogo} alt="" height="41" />
        </Link>
      ) : (
        <Link to={isBuyer ? PROJECTS : PUBLIC_RFTS} className="header__logo" />
      )}
    </div>
  );
};

export default Logo;

import React, {memo} from "react";

export const IconCopy = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 4H14V1.5C14 0.673 13.327 0 12.5 0H5C4.867 0 4.74 0.053 4.646 0.146L1.146 3.646C1.052 3.74 1 3.867 1 4V14.5C1 15.327 1.673 16 2.5 16H5V18.5C5 19.327 5.673 20 6.5 20H16.5C17.327 20 18 19.327 18 18.5V5.5C18 4.673 17.327 4 16.5 4ZM5 1.207V3.5C5 3.776 4.776 4 4.5 4H2.207L5 1.207ZM2.5 15C2.224 15 2 14.776 2 14.5V5H4.5C5.327 5 6 4.327 6 3.5V1H12.5C12.776 1 13 1.224 13 1.5V4H9C8.867 4 8.74 4.053 8.646 4.146L5.146 7.646C5.052 7.74 5 7.867 5 8V15H2.5ZM9 5.207V7.5C9 7.776 8.776 8 8.5 8H6.207L9 5.207ZM17 18.5C17 18.776 16.776 19 16.5 19H6.5C6.224 19 6 18.776 6 18.5V9H8.5C9.327 9 10 8.327 10 7.5V5H16.5C16.776 5 17 5.224 17 5.5V18.5Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React from "react";
import {useTranslation} from "react-i18next";

export const InviteSuppliersTableHead = ({list}) => {
  const {t} = useTranslation();
  return (
    <div className="invite-suppliers_table-head_wrapper">
      {list.map((column) => (
        <div
          key={column.name}
          className="invite-suppliers_table-head_column"
          style={{width: column.width}}
        >
          {column.icon}
          <div className="invite-suppliers_table-head_column_label">
            {t(column.label)}
          </div>
        </div>
      ))}
    </div>
  );
};

import * as yup from "yup";
import {stringRequired} from "../../../../../yup/defaultValudations";

export const schema = yup.object().shape({
  insurances: yup.array().of(
    yup.object().shape({
      insuranceName: stringRequired(),
      insuranceCompany: stringRequired(),
      valid: stringRequired().nullable(),
    })
  ),
});

export const initialValues = {
  insuranceName: "",
  insuranceCompany: "",
  valid: "",
};

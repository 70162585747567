import React, {useState, useCallback} from "react";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
//local
import {ReactNumberInput} from "components/ReactNumberInput";
import {Language} from "components/Language";
import {Button} from "components/buttons/Button";
import {InputsCode} from "components/InputsCode";
import {MAIN_PAGE} from "constants/routes.const";
import {useTranslation} from "react-i18next";
import {IconBankIdLogo} from "components/icons";
import Logo from "components/Header/components/Logo";
import {InfoStrip} from "components/InfoStrip";
import {color} from "constants/color.consts";

const MainPage = inject("store")(
  observer(({store: {auth, publicStore}}) => {
    const {commonSettings} = publicStore;
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [phone, setPhone] = useState("");
    const [loginStep, setLoginStep] = useState(1);
    const [code, setCode] = useState(["", "", "", ""]);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");

    const submitNumber = useCallback(
      () => phone.length && auth.sendNumber(phone, token).then(() => setLoginStep(2)),
      [auth, phone, token]
    );

    const isValidCode = useCallback(() => code.every((x) => x.length === 1), [code])();

    const submitCode = (e) => {
      e.preventDefault();
      const codeStr = code.join("");
      auth
        .checkCode(phone, codeStr, auth.request_id)
        .then((data) => data && data.token && navigate(MAIN_PAGE));
    };

    return (
      <div className="main-page__wrapper">
        {commonSettings?.infoStrip?.active && (
          <InfoStrip color={color.green} text={commonSettings?.infoStrip?.text} />
        )}
        <div
          className="main-page__content"
          style={{marginTop: commonSettings?.infoStrip?.active ? "6vh" : "none"}}
        >
          {loginStep === 1 && (
            <React.Fragment>
              <div className="top">
                <div className="logo-language">
                  <Logo />
                  <Language position="bottomRight" />
                </div>
                <p>{t("LOGIN_PAGE_DESCRIPTION")}</p>
                <div className="main-page__content__form">
                  <ReactNumberInput
                    autoFocus
                    phone={phone}
                    setPhone={setPhone}
                    onEnterKeyPress={submitNumber}
                    setIsValid={setIsValidPhone}
                  />
                </div>
                <p className="sms-extra-info mt-2">
                  {t("USE_A_NUMBER_THAT_CAN_RECIEVE_SMS")}
                </p>
                <Button
                  onClick={submitNumber}
                  name={t("LOGIN_SEND_MESSAGE")}
                  disabled={!phone.length || !isValidPhone}
                />
              </div>

              <div className="bottom">
                <h4 className="mb-4">{t("LOGIN_PAGE_VIDEO_DESCRIPTION")}</h4>
                <iframe
                  src="https://player.vimeo.com/video/657058643"
                  title="Vimeo video player"
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  className="mb-8"
                ></iframe>
                <div className="instructions">
                  <div className="column">
                    <h4>{t("LOGIN_PAGE_INFO_COL1_HEADER")}</h4>
                    <ol>
                      <li>{t("LOGIN_PAGE_INFO_COL1_ITEM_1")}</li>
                      <li>{t("LOGIN_PAGE_INFO_COL1_ITEM_2")}</li>
                      <li>
                        {t("LOGIN_PAGE_INFO_COL1_ITEM_3")} <IconBankIdLogo />
                      </li>

                      <li>{t("LOGIN_PAGE_INFO_COL1_ITEM_4")}</li>
                    </ol>
                  </div>
                  <div className="column">
                    <h4>{t("LOGIN_PAGE_INFO_COL2_HEADER")}</h4>
                    <ol>
                      <li>{t("LOGIN_PAGE_INFO_COL2_ITEM_1")}</li>
                      <li>{t("LOGIN_PAGE_INFO_COL2_ITEM_2")}</li>
                    </ol>
                  </div>
                  <div className="column">
                    <h4>{t("LOGIN_PAGE_INFO_COL3_HEADER")}</h4>
                    <p>{t("LOGIN_PAGE_INFO_COL3_ITEM_1")}</p>
                    <p>{t("LOGIN_PAGE_INFO_COL3_ITEM_2")}</p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {loginStep === 2 && (
            <React.Fragment>
              <form onSubmit={submitCode} className="main-page__content__auth-code">
                <h2>{t("AUTH_CODE_MODAL_TITLE")}</h2>
                <p>{t("AUTH_CODE_MODAL_TEXT")}</p>
                <InputsCode code={code} setCode={setCode} />
                <Button name={t("SIGN_IN")} disabled={!isValidCode} type="submit" />
              </form>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  })
);

export default MainPage;

import React, {memo} from "react";

export const IconRadar = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 19.5C7.462 19.5 5.077 18.512 3.282 16.718C1.487 14.924 0.5 12.538 0.5 10.001C0.5 7.463 1.488 5.078 3.282 3.283C5.076 1.488 7.462 0.5 10 0.5C12.538 0.5 14.923 1.488 16.718 3.283C18.513 5.078 19.5 7.463 19.5 10.001C19.5 12.539 18.512 14.924 16.718 16.718C14.924 18.512 12.538 19.5 10 19.5ZM10 1.5C5.313 1.5 1.5 5.313 1.5 10C1.5 14.687 5.313 18.5 10 18.5C14.687 18.5 18.5 14.687 18.5 10C18.5 5.313 14.687 1.5 10 1.5Z"
        fill={color || "#000"}
      />
      <path
        d="M9.99996 16.5C8.45296 16.5 6.95496 15.947 5.77996 14.944C4.61896 13.952 3.84096 12.581 3.58896 11.082C3.54296 10.81 3.72696 10.552 3.99896 10.506C4.27096 10.46 4.52896 10.644 4.57496 10.916C5.02096 13.571 7.30196 15.499 9.99896 15.499C13.032 15.499 15.499 13.032 15.499 9.99902C15.499 9.72302 15.723 9.49902 15.999 9.49902C16.275 9.49902 16.499 9.72302 16.499 9.99902C16.499 13.583 13.583 16.499 9.99896 16.499L9.99996 16.5Z"
        fill={color || "#000"}
      />
      <path
        d="M11 13.329C10.794 13.329 10.601 13.201 10.529 12.996C10.437 12.736 10.573 12.45 10.834 12.358C11.831 12.006 12.501 11.058 12.501 10C12.501 9.724 12.725 9.5 13.001 9.5C13.277 9.5 13.501 9.724 13.501 10C13.501 11.481 12.563 12.807 11.168 13.301C11.113 13.32 11.057 13.33 11.001 13.33L11 13.329Z"
        fill={color || "#000"}
      />
      <path
        d="M13.224 3.553C12.977 3.43 12.677 3.53 12.553 3.777L12.452 3.979C11.676 3.662 10.853 3.5 9.99996 3.5C8.45296 3.5 6.95496 4.053 5.77996 5.056C4.61896 6.048 3.84096 7.419 3.58896 8.918C3.54296 9.19 3.72696 9.448 3.99896 9.494C4.02696 9.499 4.05496 9.501 4.08196 9.501C4.32196 9.501 4.53396 9.328 4.57396 9.084C5.01896 6.429 7.30096 4.501 9.99796 4.501C10.694 4.501 11.365 4.627 12.001 4.877L11.1 6.678C10.747 6.56 10.378 6.501 9.99696 6.501C8.06696 6.501 6.49696 8.071 6.49696 10.001C6.49696 11.482 7.43496 12.808 8.82996 13.302C8.88496 13.321 8.94096 13.331 8.99696 13.331C9.20296 13.331 9.39596 13.203 9.46796 12.998C9.55996 12.738 9.42396 12.452 9.16296 12.36C8.16596 12.008 7.49596 11.06 7.49596 10.002C7.49596 8.624 8.61796 7.502 9.99596 7.502C10.218 7.502 10.435 7.53 10.645 7.587L10.181 8.514C10.12 8.506 10.059 8.502 9.99596 8.502C9.16896 8.502 8.49596 9.175 8.49596 10.002C8.49596 10.829 9.16896 11.502 9.99596 11.502C10.823 11.502 11.496 10.829 11.496 10.002C11.496 9.598 11.335 9.232 11.075 8.962L11.784 7.543C11.784 7.543 11.784 7.542 11.785 7.542C11.786 7.542 11.785 7.541 11.785 7.541L13.126 4.859V4.858V4.857L13.442 4.225C13.565 3.978 13.465 3.678 13.218 3.554L13.224 3.553ZM9.99996 10.5C9.72396 10.5 9.49996 10.276 9.49996 10C9.49996 9.724 9.72396 9.5 9.99996 9.5C10.276 9.5 10.5 9.724 10.5 10C10.5 10.276 10.276 10.5 9.99996 10.5Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React from "react";
import MultipleSelect from "components/selects/MultipleSelect";

const FilterTabItem = ({content, params, onChange}) => {
  return (
    <div className="find-suppliers_tab__item">
      <div className="first-setup_selection__item-wrap">
        {content.map((selection) => (
          <MultipleSelect
            key={selection.label}
            value={params?.[selection.label]}
            list={selection.array}
            small
            needSearch
            bottomElement={selection.bottomElement}
            onChange={(value) => onChange(selection.label, value)}
            title={selection.label}
          />
        ))}
      </div>
    </div>
  );
};

export default FilterTabItem;

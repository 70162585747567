import React, {memo} from "react";

export const ButtonRed = memo(({name, onClick, type, disabled}) => {
  const styles = {
    background: disabled ? "#D8D8D8" : "indianred",
    color: disabled ? "#A9A9A9" : "#FFFFFF",
  };

  return (
    <button
      style={styles}
      type={type}
      className="button-red"
      onClick={onClick}
      disabled={disabled}
    >
      {name}
    </button>
  );
});

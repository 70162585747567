import React, {memo} from "react";

export const IconHandOnUp = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 8C16.588 8 16.206 8.125 15.887 8.339C15.613 7.56 14.871 7 14 7C13.588 7 13.206 7.125 12.887 7.339C12.613 6.56 11.871 6 11 6C10.636 6 10.294 6.098 10 6.269V3C10 1.897 9.103 1 8 1C6.897 1 6 1.897 6 3V10.373L4.654 8.04C4.393 7.565 3.967 7.227 3.455 7.087C2.956 6.951 2.437 7.023 1.993 7.289C1.086 7.833 0.74 9.063 1.223 10.031C1.253 10.092 1.891 11.399 3.883 15.381C4.821 17.256 5.85 18.597 6.942 19.365C7.799 19.968 8.391 19.999 8.501 19.999H13.501C14.349 19.999 15.133 19.754 15.832 19.269C16.508 18.801 17.091 18.117 17.566 17.236C18.505 15.493 19.001 12.99 19.001 9.999C19.001 8.896 18.104 7.999 17.001 7.999L17 8ZM16.685 16.763C16.136 17.784 15.137 19 13.5 19H8.51C8.471 18.997 8.05 18.95 7.415 18.475C6.782 18.001 5.81 17.003 4.777 14.935C2.75 10.881 2.127 9.604 2.121 9.592C2.12 9.59 2.12 9.589 2.119 9.588C1.868 9.085 2.046 8.426 2.508 8.149C2.716 8.024 2.959 7.991 3.193 8.054C3.442 8.122 3.651 8.29 3.78 8.526C3.782 8.529 3.784 8.533 3.786 8.536L5.347 11.241C5.666 11.824 6.025 12.069 6.414 11.97C6.804 11.871 7.001 11.481 7.001 10.813V3.001C7.001 2.45 7.45 2.001 8.001 2.001C8.552 2.001 9.001 2.45 9.001 3.001V9.501C9.001 9.777 9.225 10.001 9.501 10.001C9.777 10.001 10.001 9.777 10.001 9.501V8.001C10.001 7.45 10.45 7.001 11.001 7.001C11.552 7.001 12.001 7.45 12.001 8.001V9.501C12.001 9.777 12.225 10.001 12.501 10.001C12.777 10.001 13.001 9.777 13.001 9.501V9.001C13.001 8.45 13.45 8.001 14.001 8.001C14.552 8.001 15.001 8.45 15.001 9.001V10.501C15.001 10.777 15.225 11.001 15.501 11.001C15.777 11.001 16.001 10.777 16.001 10.501V10.001C16.001 9.45 16.45 9.001 17.001 9.001C17.552 9.001 18.001 9.45 18.001 10.001C18.001 12.827 17.546 15.166 16.686 16.764L16.685 16.763Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconHandOnUp;

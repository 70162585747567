import React, {memo} from "react";

export const IconChevronDown = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      className={addClass}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-7.08003e-06 6.07007C-7.08003e-06 5.94207 0.0489929 5.81407 0.145993 5.71607C0.340993 5.52107 0.657993 5.52107 0.852993 5.71607L9.49899 14.3621L18.145 5.71607C18.34 5.52107 18.657 5.52107 18.852 5.71607C19.047 5.91107 19.047 6.22807 18.852 6.42307L9.85199 15.4231C9.65699 15.6181 9.33999 15.6181 9.14499 15.4231L0.144993 6.42307C0.0469929 6.32507 -0.00100708 6.19707 -0.00100708 6.06907L-7.08003e-06 6.07007Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import {Button} from "components/buttons/Button";
import {onboardingModalNames} from "constants/modal.conts";

export const ModalExists = inject("store")(
  observer(({store: {auth}}) => {
    const {t} = useTranslation();
    const organization = auth.organizations.length && auth.organizations[0];

    return (
      <div className="modal-exists">
        <h2>{t("HANG_ON")}</h2>
        <p className="modal-exists__text">{t("MODAL_EXISTS_TEXT_1")}</p>
        <p className="modal-exists__text">
          {t("MODAL_EXISTS_TEXT_2")}
          <br /> {t("MODAL_EXISTS_TEXT_3")}
        </p>
        <div className="modal-exists__btns">
          {organization && (
            <Button
              onClick={() => auth.updateAuthModalName(onboardingModalNames.exists)}
              name={t("REQUEST_TO_JOIN")}
            />
          )}
        </div>
        <p>{t("ORG_EXISTS_SUPPORT_1")}</p>
        <p>{t("ORG_EXISTS_SUPPORT_2")}</p>
      </div>
    );
  })
);

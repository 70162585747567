import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
// local
import {OrganizationCard} from "components/organizationCard/OrganizationCard";
import {PaginationType} from "components/Pagination";
import {Preloader} from "components/Preloader";
import HandyPointerBlob from "components/HandyPointerBlob";
import OrganizationCardHead from "components/organizationCard/OrganizationCardHead";
import TableTitleResult from "components/Table/TableTitleResult";
import SubscriptionUpgrageLocalModal from "components/modals/SubscriptionUpgrageLocalModal";
import {hasPermission} from "helpers/helper";

import cn from "classnames";

const Organizations = inject("store")(
  observer(({store: {auth, organization}, onChangeSort}) => {
    const {
      filterParams,
      suppliers: {totalItems, currentPage, searchPreferences},
      loading,
    } = organization;
    const {
      user: {permissions},
    } = auth;
    const navigate = useNavigate();
    const [isHistory, setShowHistory] = useState(false);
    const isResults = !!searchPreferences?.length;
    const SubscriptionUpgrageLocalModalPermissions = [
      "READ_TABLE_CREDIT_RATING",
      "READ_TABLE_TURNOVER",
      "READ_TABLE_COLLECTIVE_UNION_AGREEMENTS",
      "READ_TABLE_INSURANCE",
      "READ_TABLE_TAX_VAT_EMPLOYERS",
    ];

    const updateUrlParams = (search) => navigate({search});

    const handlePageChange = (pageNumber) => {
      // Separate conversion for correct search string generation
      const urlParams = Object.entries(filterParams).reduce(
        (obj, item) => ({
          ...obj,
          [`${item[0]}${Array.isArray(item[1]) ? "[]" : ""}`]: item[1],
        }),
        {}
      );

      organization.setFilterParams({...filterParams, page: pageNumber});
      updateUrlParams(`?${new URLSearchParams({...urlParams, page: pageNumber})}`);
      organization.searchSuppliers();
    };

    return (
      <div className={cn("organizations", {__results: isResults})}>
        {loading && <Preloader addClass="preloader250" />}
        {!loading && searchPreferences && (
          <React.Fragment>
            <TableTitleResult title={"RESULTS"} />

            <HandyPointerBlob
              showNewsBadge
              showPointer
              text="NEW_FEATURE_SEARCHFILTERS_TEXT"
              forcePosition="absolute"
              topPos={35}
              rightPos={29}
            />

            <div className="organizations__suppliers">
              <OrganizationCardHead
                onChangeSort={onChangeSort}
                isHistory={isHistory}
                onChangeShowHistory={() => setShowHistory(!isHistory)}
              />
              {isResults &&
                searchPreferences.map((organization) => (
                  <OrganizationCard
                    key={organization.id}
                    organization={organization.Organization}
                    isHistory={isHistory}
                    showTitleIcon
                    place="common"
                  />
                ))}
              {!hasPermission(SubscriptionUpgrageLocalModalPermissions, permissions) && (
                <SubscriptionUpgrageLocalModal
                  canPermissions={SubscriptionUpgrageLocalModalPermissions}
                />
              )}
            </div>
            {totalItems > 20 && (
              <PaginationType
                totalItems={totalItems}
                currentPage={currentPage}
                countPerPage={20}
                onPageChange={handlePageChange}
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  })
);

export default Organizations;

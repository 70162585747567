import React, {useState, useEffect} from "react";
import {inject, observer} from "mobx-react";
import {useLocation} from "react-router-dom";
//local
import {Layout} from "components/UI/layout";
import FindOrganizationsFilter from "components/Filters/FindOrganizationsFilter";
import {Tabs} from "components/Tabs";
import {
  IconFavoriteDone,
  IconCheckMark,
  IconSpotlights,
  IconBinoculars,
} from "components/icons";
import {ourSuppliersPageTabs, tabTitles} from "constants/tabs.const";
import {Tag} from "components/Tag";
import FavoritesSection from "./Sections/FavoritesSection";
import QualifyingSection from "./Sections/QualifyingSection";
import MonitoringSection from "./Sections/MonitoringSection";
import {CustomBadge} from "components/CustomBadge";
import {VideoInstructionLink} from "components/VideoInstructionLink";
import {InfoTooltip} from "components/InfoTooltip";
import {color} from "constants/color.consts";

const OurSuppliersPage = inject("store")(
  observer(({store: {auth, organization}}) => {
    const {user} = auth;
    const location = useLocation();

    const [externalSorting, setExternalSorting] = useState(null);
    const [activeTab, setActiveTab] = useState(
      ourSuppliersPageTabs.find((tab) => tab.hash === location.pathname)?.id ?? 0
    );
    const [externalUpdate, setExternalUpdate] = useState(true);

    const customTabParams = {
      0: {favorite: true},
      1: {monitoringSuppliers: true},
      2: {qualification: true},
    };

    const handleChangeTab = (tabId) => {
      setActiveTab(tabId);
      organization.setFilterParams({
        ...customTabParams[tabId],
      });
      organization.searchSuppliers();
    };

    useEffect(() => {
      ourSuppliersPageTabs[activeTab].title === tabTitles.QUALIFYING_TAB &&
        externalUpdate &&
        setExternalUpdate(false);
    }, [externalUpdate, activeTab]);

    return (
      <Layout>
        <div className="our-suppliers content-block_wrapper">
          <div className="find-suppliers__search-select">
            <FindOrganizationsFilter
              title="MY_SUPPLIERS"
              titleIcon={<IconCheckMark height="40" width="40" />}
              customParams={customTabParams[activeTab]}
              hideEntireFilter={!!activeTab}
              externalSorting={externalSorting}
            />
          </div>
          <div className="tabs-wrapper">
            <Tabs
              tabs={ourSuppliersPageTabs}
              leftContent={{
                [tabTitles.OUR_SUPPLIERS_TAB]: <IconFavoriteDone addClass="mr-1 mb-1" />,
                [tabTitles.MONITORING_TAB]: <IconBinoculars addClass="mr-1 mb-1" />,
                [tabTitles.QUALIFYING_TAB]: <IconSpotlights addClass="mr-1 mb-1" />,
              }}
              rightContent={{
                [tabTitles.MONITORING_TAB]: (
                  <React.Fragment>
                    <InfoTooltip
                      tooltip="MONITORING_TAB_INFO_TOOLTIP"
                      centerText
                      addIconWrapperClass="monitoring-tab-tooltip"
                    />
                    <Tag
                      text="NEW_FEATURE_NEWS"
                      needTranslation
                      noBackground
                      color={color.red}
                      uppercase
                      small
                      addClass="monitoring-tab-tag"
                    />
                  </React.Fragment>
                ),
                [tabTitles.QUALIFYING_TAB]: (
                  <CustomBadge
                    count={user.unreadBuyerQualificationsCount}
                    addClass="qualifying-tab-counter"
                  />
                ),
              }}
              active={activeTab}
              setActive={handleChangeTab}
              addClass="mb-4 df-row-center"
            />
            {ourSuppliersPageTabs[activeTab].title === tabTitles.QUALIFYING_TAB && (
              <div className="info">
                <VideoInstructionLink
                  label={`VIDEO_LINK_SUPPLIER_QUALIFICATION_LABEL`}
                  link={`VIDEO_LINK_SUPPLIER_QUALIFICATION_LINK`}
                  addClass="ml-8 mr-4"
                />
              </div>
            )}
          </div>
          {ourSuppliersPageTabs[activeTab].title === tabTitles.OUR_SUPPLIERS_TAB && (
            <FavoritesSection
              onChangeSort={(name, value) => setExternalSorting({name, value})}
            />
          )}

          {ourSuppliersPageTabs[activeTab].title === tabTitles.MONITORING_TAB && (
            <MonitoringSection
              onChangeSort={(name, value) => setExternalSorting({name, value})}
            />
          )}

          {ourSuppliersPageTabs[activeTab].title === tabTitles.QUALIFYING_TAB && (
            <QualifyingSection
              onChangeSort={(name, value) => setExternalSorting({name, value})}
            />
          )}
        </div>
      </Layout>
    );
  })
);

export default OurSuppliersPage;

import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
//local
import {IconList, IconAsterisk} from "components/icons";
import SmartFormAnswerItemEdit from "./SmartFormAnswerItemEdit";
import SmartFormItemView from "./SmartFormItemView";
import cn from "classnames";
import {color} from "constants/color.consts";

const SmartFormAnswers = ({
  isNew,
  isEdit,
  titleHide,
  questions,
  answers,
  isSellerSide,
  isRequiredFilled,
  onChange,
}) => {
  const {t} = useTranslation();

  const onChangeRowValues = (answer) =>
    onChange(answers.map((item) => (item.id === answer.id ? answer : item)));

  // checking the completeness of mandatory answers
  useEffect(() => {
    const checkRequiredFilled = (resultAnswers) => {
      const requiredQuestionsIds = questions
        .filter((item) => item.type !== "heading" && !!item.required)
        .map((item) => item.id);
      isRequiredFilled(
        !resultAnswers?.filter(
          (item) => requiredQuestionsIds.includes(item.questionId) && !item.value
        ).length
      );
    };
    isRequiredFilled && checkRequiredFilled(answers);
  }, [answers, isRequiredFilled, questions]);

  return (
    <div
      className={cn("request-smart-form-block", {
        "__top-border": !titleHide && !(isEdit || isNew),
      })}
    >
      {!titleHide && (
        <div
          className={cn("request-smart-form-block__heading", {
            "mb-4": isSellerSide && (isEdit || isNew),
          })}
        >
          <IconList addClass="mr-4" />
          {isEdit || isNew
            ? t("SMART_FORM_ANSWERS_TITLE_EDIT")
            : t("SMART_FORM_ANSWERS_TITLE_VIEW")}
          {(isEdit || isNew) && (
            <div className="request-smart-form-block__heading__mandatory-tag">
              {t("MANDATORY")}
              <IconAsterisk addClass="ml-2" color={color.red} />
            </div>
          )}
        </div>
      )}
      {isEdit || isNew ? (
        <div className="smart-form-edit __answers">
          <Formik enableReinitialize initialValues={questions}>
            {({values, handleSubmit}) => (
              <Form>
                {questions?.map((item) => (
                  <SmartFormAnswerItemEdit
                    key={item.id}
                    question={item}
                    answer={answers.find((answer) => answer.questionId === item.id)}
                    onChange={onChangeRowValues}
                  />
                ))}
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className="smart-form-view">
          {questions?.map((item) => (
            <SmartFormItemView
              key={item.id}
              question={item}
              answer={answers.find((answer) => answer.questionId === item.id)}
              isAnswerSide
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SmartFormAnswers;

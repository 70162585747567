import React from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Form, Formik} from "formik";
// local
import {IconLaptopPhone} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import SimpleField from "components/SimpleField";
import {schema} from "./formAttrs";

const PresentationPageHeaderMainEdit = inject("store")(
  observer(({store: {organization}, edit, setEdit}) => {
    const {organizationDetail} = organization;
    const handleCloseEdit = () => setEdit({...edit, main: false});

    const {t} = useTranslation();

    const onSubmit = (values) =>
      organization.nameDescriptionUrlEdit(values).then(() => {
        organization.setOrganizationDetail({...organizationDetail, ...values});
        handleCloseEdit();
      });

    const InitialValues = {
      name: organizationDetail?.name || "",
      description: organizationDetail?.description || "",
      url: organizationDetail?.url || "",
    };

    return (
      <div className="presentation-page-header__right-side presentation-header-edit">
        <Formik
          initialValues={InitialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="presentation-page-header__titles presentation-header-edit">
              <div className="presentation-page-header__about-org presentation-header-edit">
                <div className="presentation-page-header__name presentation-header-edit">
                  <SimpleField
                    name="name"
                    placeholder={t("ORG_NAME")}
                    errorName="name"
                    className="input presentation-page-header__input-name presentation-header-edit"
                  />
                </div>
              </div>
              <div className="presentation-page-header__actions presentation-header-edit">
                <ButtonTransparent
                  type="submit"
                  name={t("SAVE_CHANGES")}
                  addClass="editBlock_head__btn"
                />
                <ButtonTransparent
                  type="button"
                  name={t("CANCEL")}
                  addClass="editBlock_head__btn"
                  onClick={handleCloseEdit}
                />
              </div>
            </div>
            <SimpleField
              name="description"
              placeholder={t("ORG_DESCRIPTION")}
              as="textarea"
              className="presentation-page-header__input-description presentation-header-edit"
            />
            <div className="editBlock_header__input-site">
              <IconLaptopPhone />
              <SimpleField
                name="url"
                placeholder={t("ORG_URL")}
                errorName="url"
                className="input presentation-page-header__input-site presentation-header-edit"
              />
            </div>
          </Form>
        </Formik>
      </div>
    );
  })
);

export default PresentationPageHeaderMainEdit;

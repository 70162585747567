import React, {memo} from "react";

export const IconFirstAid = memo(({color}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 16.175H8.5C8.224 16.175 8 15.951 8 15.675V14.175H6.5C6.224 14.175 6 13.951 6 13.675V10.675C6 10.399 6.224 10.175 6.5 10.175H8V8.67505C8 8.39905 8.224 8.17505 8.5 8.17505H11.5C11.776 8.17505 12 8.39905 12 8.67505V10.175H13.5C13.776 10.175 14 10.399 14 10.675V13.675C14 13.951 13.776 14.175 13.5 14.175H12V15.675C12 15.951 11.776 16.175 11.5 16.175ZM9 15.175H11V13.675C11 13.399 11.224 13.175 11.5 13.175H13V11.175H11.5C11.224 11.175 11 10.951 11 10.675V9.17505H9V10.675C9 10.951 8.776 11.175 8.5 11.175H7V13.175H8.5C8.776 13.175 9 13.399 9 13.675V15.175Z"
        fill={color || "#000"}
      />
      <path
        d="M18.5 5.17505H14V3.67505C14 2.84805 13.327 2.17505 12.5 2.17505H7.5C6.673 2.17505 6 2.84805 6 3.67505V5.17505H1.5C0.673 5.17505 0 5.84805 0 6.67505V17.675C0 18.502 0.673 19.175 1.5 19.175H18.5C19.327 19.175 20 18.502 20 17.675V6.67505C20 5.84805 19.327 5.17505 18.5 5.17505ZM7 3.67505C7 3.39905 7.224 3.17505 7.5 3.17505H12.5C12.776 3.17505 13 3.39905 13 3.67505V5.17505H7V3.67505ZM19 17.675C19 17.951 18.776 18.175 18.5 18.175H1.5C1.224 18.175 1 17.951 1 17.675V6.67505C1 6.39905 1.224 6.17505 1.5 6.17505H18.5C18.776 6.17505 19 6.39905 19 6.67505V17.675Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
//local
import SimpleField from "components/SimpleField";
import {TitleIcon} from "components/TitleIcon";
import {IconBag, IconFeather} from "components/icons";
import {RichField} from "components/RichField";
import {schema} from "./formAttrs";
import {color} from "constants/color.consts";

export const ContractCreateInfo = inject("store")(
  observer(({store: {buyer}}) => {
    const {currentRequest, currentOffer} = buyer;
    const {t} = useTranslation();
    const startPrice = currentRequest?.contractPrice ?? currentOffer?.price ?? 0;

    useEffect(() => {
      buyer.updateCurrentRequestInfo({
        contractPrice: startPrice,
        contractDescription: currentRequest?.contractDescription ?? "",
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOffer]);

    const handleChange = (e) =>
      buyer.updateCurrentRequestInfo({[e.target.name]: e.target.value});

    const handleChangeAdditions = (data) =>
      buyer.updateCurrentRequestInfo({contractDescription: data});

    return (
      <div className="contract-tab__contract-edit-block">
        <Formik initialValues={{contractPrice: startPrice}} validationSchema={schema}>
          <Form onChange={handleChange}>
            <TitleIcon title={t("PRICE")} icon={<IconBag />} />
            <div className="df">
              <SimpleField
                name="contractPrice"
                autoFocus
                placeholder={t("PRICE_PLACEHOLDER")}
                errorName="contractPrice"
                className="input price"
              />
              <span className="price-desc">{currentRequest.CurrencyCode}</span>
            </div>
          </Form>
        </Formik>
        <TitleIcon
          title={t("ADDITIONS_ADJUSTMENTS")}
          addClass="mt-12"
          icon={<IconFeather color={color.black} />}
        />
        <RichField
          data={currentRequest?.contractDescription ?? ""}
          placeholder="ADDITIONS_ADJUSTMENTS_DESCRIPTION"
          importType="html"
          exportType="html"
          onChange={handleChangeAdditions}
        />
      </div>
    );
  })
);

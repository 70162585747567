import React from "react";
import {inject, observer} from "mobx-react";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
//local
import {Select} from "antd";
import {orderingCreatedValues, orderingNameValues} from "constants/select.consts";
import {changeSelectValue, changeFilterParams} from "helpers/helper";
import ProjectsSelect from "components/selects/ProjectsSelect";

const {Option} = Select;

const ContractsTableHead = inject("store")(
  observer(
    ({
      store: {buyer, seller},
      projects,
      externalSorting,
      onChangeProject,
      showContractNumber,
    }) => {
      const {t} = useTranslation();
      const navigate = useNavigate();
      const location = useLocation();
      const {contractFilterParams: buyerContractFilterParams} = buyer;
      const isBuyer = location.pathname === "/contracts";
      const allProjectItem = {id: null, name: t("ALL_PROJECTS"), isArchived: false};

      const onChangeOrder = (value) => {
        const params = changeFilterParams(value);
        updateUrlParams(
          `?${new URLSearchParams({...buyerContractFilterParams, ...params})}`
        );
        if (isBuyer) {
          buyer.setContractFilterParams({...buyerContractFilterParams, ...params});
          buyer.getContracts();
        } else {
          seller.setContractFilterParams(params);
          seller.getContracts();
        }
      };

      const updateUrlParams = (search) => navigate({search});

      const options = (values) =>
        values.map(({value, label}, i) => (
          <Option key={i} value={value}>
            {t(label)}
          </Option>
        ));

      return (
        <div className="requests-card_head">
          <div className="requests-card_head__icon __wrapper icon-col"></div>
          {showContractNumber && (
            <div className="requests-card_head__number __wrapper number-col">
              {/* {t("CONTRACT_NUMBER_TABLE_HEADER")} */}
            </div>
          )}

          <div className="requests-card_head__name __wrapper name-col">
            <div className="requests-card_head__name __select mr-2">
              <Select
                onChange={onChangeOrder}
                value={changeSelectValue(
                  orderingNameValues,
                  isBuyer ? buyer.contractFilterParams : seller.contractFilterParams
                )}
              >
                {options(orderingNameValues)}
              </Select>
            </div>
          </div>
          <div className="requests-card_head__column __wrapper company-col">
            {t(isBuyer ? "SUPPLIER" : "BUYER")}
          </div>
          <div className="requests-card_head__column __wrapper date-col">
            <div className="requests-card_head__name __select">
              <Select
                onChange={onChangeOrder}
                value={changeSelectValue(
                  orderingCreatedValues,
                  isBuyer ? buyer.contractFilterParams : seller.contractFilterParams
                )}
              >
                {options(orderingCreatedValues)}
              </Select>
            </div>
          </div>
          <div className="requests-card_head__column __wrapper project-col">
            {!!projects.length && (
              <ProjectsSelect
                list={[allProjectItem, ...projects]}
                onChange={onChangeProject}
                noBorder
                addClass="contracts-project-label"
                defaultValue={allProjectItem.id}
              />
            )}
          </div>
        </div>
      );
    }
  )
);

export default ContractsTableHead;

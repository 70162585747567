import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {useNavigate, useLocation} from "react-router-dom";
//local
import {ORGANIZATION_USER_INVITE_CHOICE} from "../constants/routes.const";
import OrganizationUserInvite from "../pages/OrganizationUserInvite/OrganizationUserInvite";
import {inviteStatuses} from "../constants/invite.consts";
import {Preloader} from "../components/Preloader";
import * as qr from "querystring-es3";

export const InviteRoute = inject("store")(
  observer(({store: {auth, organization}, component: Component, path}) => {
    let navigate = useNavigate();
    const location = useLocation();
    const query = qr.parse(location.search.replace("?", ""));
    const {organizationId, token} = auth;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(true);
      auth
        .checkToken()
        .catch(() => console.log())
        .finally(() => setLoading(false));
    }, [auth, token]);

    if (loading) return <Preloader />;

    if (token) {
      if (organizationId && path !== ORGANIZATION_USER_INVITE_CHOICE) {
        navigate({
          pathname: ORGANIZATION_USER_INVITE_CHOICE,
          search: `?id=${query["id"]}`,
        });
        return null;
      } else if (!organizationId) {
        organization.setInviteStatus(inviteStatuses.onBoardingPage);
        return <Component />;
      } else {
        return <Component />;
      }
    } else {
      return <OrganizationUserInvite />;
    }
  })
);

import React, {memo} from "react";

export const IconRightOn = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0711 2.92893C13.1678 -0.974297 6.83216 -0.974297 2.92893 2.92893C-0.974297 6.83216 -0.974297 13.1678 2.92893 17.0711C6.83216 20.9743 13.1678 20.9743 17.0711 17.0711C20.9743 13.1678 20.9743 6.83216 17.0711 2.92893ZM11.5858 11.4142L8.75736 14.2427L7.34315 12.8284L10.1716 10L7.34315 7.17159L8.75736 5.75737L11.5858 8.5858L13 10L11.5858 11.4142Z"
        fill={color || "#1BA2CC"}
      />
    </svg>
  );
});

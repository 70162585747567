import React, {useCallback, useEffect} from "react";
import {inject, observer} from "mobx-react";
import QRCode from "qrcode.react";
import {useTranslation} from "react-i18next";
//local
import {Preloader} from "components/Preloader";
import {showError} from "helpers/notifications.helper";
import {getMessage} from "helpers/helper";
import {verificationStatuses} from "constants/seller.consts";
import BankIdVerification from "./components/BankIdVerification";

export const ModalIdentifyContractPerson = inject("store")(
  observer(({store: {auth, buyer}}) => {
    const {t} = useTranslation();
    const {qrCodeData} = auth;
    const {currentRequest} = buyer;

    const checkBankIdStatus = useCallback(() => {
      if (auth.bankIdVerifyData?.id) {
        return;
      }
      switch (auth.bankIdVerifyData.status) {
        case verificationStatuses.failed:
          auth.updateAuthModalName(null);
          return auth.bankIdVerifyData.userMessage
            ? getMessage(auth.bankIdVerifyData.userMessage)
            : showError(t("VERIFICATION_FAILED"));
        case verificationStatuses.pending:
          return <BankIdVerification />;
        default:
          return <Preloader addClass="preloader500" />;
      }
    }, [auth, t]);

    useEffect(() => {
      auth.getBankIdDataOnContract();
    }, [auth]);

    return (
      <div className="modal-auth modal-identify_wrapper">
        <div className="modal-identify_image">
          {auth?.qrCodeData && <QRCode value={auth?.qrCodeData} size={180} />}
        </div>
        <p className={`modal-identify_text ${qrCodeData ? "mb-2" : "mb-12"}`}>
          {t("MODAL_OPEN_BANKID_APP")}
        </p>
        {qrCodeData && (
          <a
            href={`${qrCodeData}&redirect=${window.location.origin}/requests/${currentRequest.id}`}
            className="modal-identify_link mb-12"
          >
            {t("OPEN_BANK_ID_LINK")}
          </a>
        )}
        <div className="modal-identify_spin">{checkBankIdStatus()}</div>
      </div>
    );
  })
);

import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";
//locale
import {Layout} from "components/UI/layout";
import {Preloader} from "components/Preloader";
import EmptyData from "components/EmptyData";
import RequestsToUsHead from "./components/RequestsToUsHead";
import RequestsTableHead from "./components/RequestsTableHead";
import RequestsTableItem from "./components/RequestsTableItem";

const RequestsToUsPage = inject("store")(
  observer(({store: {seller}}) => {
    const {requests, loading} = seller;

    useEffect(() => {
      const fetchData = async () => await seller.getRequests();
      fetchData();
    }, [seller]);

    return (
      <Layout>
        <div className="requests-to-us">
          <RequestsToUsHead />
          {loading && <Preloader addClass="preloader500" />}
          {!loading && (
            <div className="requests-to-us content-block_wrapper">
              <RequestsTableHead />

              {requests?.length ? (
                requests?.map((item) => (
                  <RequestsTableItem key={item.id} request={item} />
                ))
              ) : (
                <EmptyData addClass={`empty-data pt-4`} />
              )}
            </div>
          )}
        </div>
      </Layout>
    );
  })
);

export default RequestsToUsPage;

import React from "react";
import {inject, observer} from "mobx-react";
import {OrganizationCard} from "components/organizationCard/OrganizationCard";
import {Preloader} from "components/Preloader";
import OrganizationCardHead from "components/organizationCard/OrganizationCardHead";
import EmptyMonitoringSection from "../components/EmptyMonitoringSection";
import DisabledMonitoringSection from "../components/DisabledMonitoringSection";
import {PaginationType} from "components/Pagination";

const MonitoringSection = inject("store")(
  observer(({store: {auth, organization, clientStore}, onChangeSort}) => {
    const {
      suppliers: {totalItems, currentPage, searchPreferences},
      loading,
    } = organization;
    const {user, activeOrganization} = auth;

    const isMonitoringEnabled = activeOrganization.allowMonitorSuppliers;

    const handleChange = async (companyId, organizatioId, checked) => {
      checked
        ? await clientStore.setUserMonitoredCompany(companyId)
        : await clientStore.removeUserMonitoredCompany(companyId);

      organization.searchSuppliers(currentPage, true);
    };

    return isMonitoringEnabled ? (
      <div className="our-suppliers__organizations-table">
        <OrganizationCardHead
          place="monitoring"
          specificData={{monitoringCompanyCount: totalItems, user}}
          onChangeSort={onChangeSort}
        />
        {loading && <Preloader addClass="preloader100" />}
        {!loading && (
          <React.Fragment>
            {!!searchPreferences?.length ? (
              searchPreferences.map((s) => {
                if (s.Organization) {
                  return (
                    <OrganizationCard
                      key={s.Organization.id}
                      organization={s.Organization}
                      place="monitoring"
                      changeMonitorSuppliers={(checked) =>
                        handleChange(s.Organization.CompanyId, s.Organization.id, checked)
                      }
                    />
                  );
                }
                return null;
              })
            ) : (
              <EmptyMonitoringSection />
            )}
          </React.Fragment>
        )}

        {totalItems > 20 && (
          <PaginationType
            totalItems={totalItems}
            currentPage={currentPage}
            countPerPage={20}
            onPageChange={(pageNumber) => organization.searchSuppliers(pageNumber)}
            addClass="mt-8"
          />
        )}
      </div>
    ) : (
      <DisabledMonitoringSection />
    );
  })
);

export default MonitoringSection;

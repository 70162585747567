import React from "react";
import {useTranslation} from "react-i18next";
// local
import AgreementCardSwitch from "./components/AgreementCardSwitch";
import {getFullName} from "helpers/helper";
import {dateFormat} from "helpers/datetime";
import UploadMultiple from "components/UploadMultiple";
import {ourAgreementsTypes} from "constants/user.consts";
import {roles} from "constants/auth.const";

const AgreementCardUE2021 = ({
  agreement,
  role,
  ourOrganizationName,
  organizationName,
  onUpdate,
  onLoadFile,
  onRemoveFile,
}) => {
  const {t} = useTranslation();

  const user = agreement.UpdatedUser;
  const userName = getFullName(user?.firstName, user?.lastName);
  const baseLabel = `${agreement.type} ${t("WITH")} ${organizationName}`;
  const activeLabel = `${ourOrganizationName} ${t(
    "OUR_AGREEMENTS_SWITCH_LABEL_2"
  )} ${baseLabel}`;
  const inactiveLabel = `${t("OUR_AGREEMENTS_SWITCH_LABEL_1")} ${ourOrganizationName} ${t(
    "OUR_AGREEMENTS_SWITCH_LABEL_2"
  )} ${baseLabel}`;
  const inactiveMemberLabel = `${ourOrganizationName} ${t(
    "OUR_AGREEMENTS_SWITCH_LABEL_3"
  )} ${baseLabel}`;
  const roleNotMember = role === roles.OWNER || role === roles.ADMIN;
  const hasFiles = !!agreement.Files?.length;

  return (
    <div className="agreement-card mb-8">
      <h3 className="agreement-card__title">{agreement.title}</h3>
      <div className="agreement-card__body">
        <div className="agreement-card__body__row mb-2">
          <AgreementCardSwitch
            role={role}
            checked={agreement.checked}
            onClick={(checked) => onUpdate({type: agreement.type, checked})}
            activeLabel={activeLabel}
            inactiveLabel={inactiveLabel}
            inactiveMemberLabel={inactiveMemberLabel}
          />
        </div>
        {agreement.checked && roleNotMember && (
          <UploadMultiple
            uploadRequest={(file) =>
              onLoadFile(file, ourAgreementsTypes.UE2021, agreement.id)
            }
            removeRequest={(id) =>
              onRemoveFile(ourAgreementsTypes.UE2021, agreement.id, id)
            }
            customFileTypes={["PNG", "JPG", "PDF", "XLS"]}
            isFilesRowView
            isFileIconDownload
            miniLoadButton
            wrapperClassName={`mb-0 pt-2 mr-0${
              !hasFiles ? " absolute-upload-button" : ""
            }`}
            filesButtonView="delete"
            files={agreement.Files}
          />
        )}
        {agreement.updatedAt && agreement.UpdatedUser && roleNotMember && (
          <div
            className={`agreement-card__body__updated-description${
              hasFiles ? " mt-2" : ""
            }`}
          >
            {`${t("OUR_AGREEMENTS_UPDATED_DESCRIPTION")} ${userName} ${t(
              "ON-DATE"
            )} ${dateFormat(agreement.updatedAt)}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default AgreementCardUE2021;

import React from "react";
//local
import {IconFactory} from "components/icons";
import cn from "classnames";

export const TextCard = ({text, hoverColor, iconColor, transparent, addClass}) => {
  return (
    <div
      className={cn(
        "text-card__wrapper",
        {[addClass]: !!addClass},
        {__transparent: !!transparent}
      )}
    >
      <IconFactory color={iconColor} addClass="mr-2" />
      <span className={cn({[hoverColor]: !!hoverColor})}>{text}</span>
    </div>
  );
};

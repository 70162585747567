export const fileType = {
  CONTRACT: "CONTRACT",
  CONTRACTDRAFT: "CONTRACT",
  DOCUMENTS: "DOCUMENT",
  PLANS: "PLAN",
};

export const docType = {
  CONTRACT: "contracts",
  CONTRACTDRAFT: "contracts",
  DOCUMENTS: "documents",
  PLANS: "plans",
};

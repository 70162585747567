import React from "react";
import {useTranslation} from "react-i18next";
// local
import {InfoTooltip} from "components/InfoTooltip";

const EmailRecipientsBouncedStatus = () => {
  const {t} = useTranslation();

  return (
    <div className="df-row-center ml-2">
      <span className="__uppercase red fz-10 __bold mr-1 lh-20">
        {t("RECIPIENTS_NOT_REGISTERED_BOUNCED")}
      </span>
      <InfoTooltip tooltip="RECIPIENTS_NOT_REGISTERED_BOUNCED_TOOLTIP" centerText />
    </div>
  );
};

export default EmailRecipientsBouncedStatus;

import React from "react";
import {inject, observer} from "mobx-react";
//local
import {Modal} from "../Modal";
import {ModalRequest} from "./components/ModalRequest";
import {ModalExists} from "./components/ModalExists";
import {
  onboardingModalNames,
  contractModalNames,
  identifyModalNames,
} from "constants/modal.conts";
import {ModalOrganizationInput} from "./components/ModalOrganizationInput";
import {ModalIdentifyOwner} from "./components/ModalIdentifyOwner";
import {ModalIdentifyContractPerson} from "./components/ModalIdentifyContractPerson";
import {ModalIdentifyEmailAddress} from "./ModalIdentifyEmailAddress";

const Modals = inject("store")(
  observer(({store: {auth}, onClick}) => {
    return (
      <Modal onClick={onClick}>
        {auth.modalName === onboardingModalNames.joining && <ModalExists />}
        {auth.modalName === onboardingModalNames.exists && <ModalRequest />}
        {auth.modalName === onboardingModalNames.orgName && <ModalOrganizationInput />}
        {auth.modalName === onboardingModalNames.identify && <ModalIdentifyOwner />}

        {auth.modalName === contractModalNames.identifyContractPerson && (
          <ModalIdentifyContractPerson />
        )}
        {auth.modalName === identifyModalNames.identifyEmailAddress && (
          <ModalIdentifyEmailAddress />
        )}
      </Modal>
    );
  })
);

export default Modals;

import React from "react";
import {useTranslation} from "react-i18next";
// local
import {CustomTooltip} from "components/CustomTooltip";
import {
  IconBriefcase,
  IconOutbox,
  IconContract,
  IconReply,
  IconSignal,
} from "components/icons";
import {color} from "constants/color.consts";
import cn from "classnames";

export const OrganizationStats = ({
  projectsCount,
  rfpsCount,
  receivedOffersCount,
  contractsCount,
  contractsSum,
  addClass,
}) => {
  const {t} = useTranslation();
  const isGrayIcon =
    !projectsCount && !rfpsCount && !receivedOffersCount && !contractsCount;
  const iconColor = isGrayIcon ? color.lightGray : color.black;

  const statsData = [
    {
      tooltip: "ORGANIZATION_STATS_PROJECTS_TOOLTIP",
      count: projectsCount,
      icon: <IconBriefcase color={iconColor} width={11} height={12} />,
    },
    {
      tooltip: "ORGANIZATION_STATS_RFP_TOOLTIP",
      count: rfpsCount,
      icon: <IconOutbox color={iconColor} width={11} height={12} />,
    },
    {
      tooltip: "ORGANIZATION_STATS_OFFERS_TOOLTIP",
      count: receivedOffersCount,
      icon: <IconReply color={iconColor} width={11} height={12} />,
    },
    {
      tooltip: "ORGANIZATION_STATS_CONTRACTS_TOOLTIP",
      count: contractsCount,
      icon: <IconContract color={iconColor} width={11} height={12} />,
    },
    {
      customTooltip: `(${contractsSum} SEK) ${t(
        "ORGANIZATION_STATS_CONTRACTS_SUM_TOOLTIP"
      )}`,
      count: (
        <div style={{marginBottom: "-4px"}}>
          {`${contractsSum ? Math.round(contractsSum / 1000) : 0} K`}
          <span className={`ml-1 ${isGrayIcon ? "light-gray" : "discrete-text"}`}>
            SEK
          </span>
        </div>
      ),
      icon: <IconSignal color={iconColor} addClass="ml-2" />,
    },
  ];

  return (
    <div
      className={cn("organization-card_body__name  __stats", {[addClass]: !!addClass})}
    >
      {statsData.map((item) => (
        <CustomTooltip
          key={item.tooltip}
          text={item.customTooltip ?? t(item.tooltip)}
          noWrapText
        >
          <div className="df-row-center" key={item.tooltip}>
            {item.icon}
            <span className={`pr-2 fz-10 __bold ${isGrayIcon ? "light-gray" : ""}`}>
              {item.count ?? 0}
            </span>
          </div>
        </CustomTooltip>
      ))}
    </div>
  );
};

import * as yup from "yup";
import {stringRequired} from "../../../../../../yup/defaultValudations";

export const schema = yup.object().shape({
  unionAgreements: yup.array().of(
    yup.object().shape({
      organizationType: yup.string(),
      organizationName: stringRequired(),
    })
  ),
});

export const initialValues = {
  organizationType: "",
  organizationName: "",
  localAgreement: false,
};

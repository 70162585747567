import React, {memo} from "react";

export const IconDcWast = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13910_201630)">
        <rect
          x="0.25"
          y="0.25"
          width="19.5"
          height="19.5"
          rx="1.75"
          fill="white"
          stroke="#6B492A"
          stroke-width="0.5"
        />
        <path
          d="M4.23254 9.26316V3.68293L3.51878 3.60205V3.0629H4.8336C5.08404 3.0629 5.39083 3.05092 5.75397 3.02696C5.83536 3.02097 5.95432 3.01498 6.11084 3.00899C6.26737 3.003 6.41137 3 6.54285 3C7.68862 3 8.55264 3.28455 9.13492 3.85366C9.71719 4.42276 10.0083 5.23149 10.0083 6.27985C10.0083 7.01669 9.85493 7.66068 9.54814 8.21181C9.24135 8.76294 8.8156 9.18528 8.2709 9.47882C7.73245 9.77236 7.11574 9.91913 6.42076 9.91913C6.2705 9.91913 6.03884 9.91314 5.72579 9.90116C5.45657 9.88318 5.16543 9.8742 4.85238 9.8742H3.5V9.40693L4.23254 9.26316ZM5.39709 9.21823C5.65379 9.31408 5.96997 9.362 6.34563 9.362C6.45833 9.362 6.54599 9.35901 6.6086 9.35302C7.02182 9.33504 7.39122 9.21224 7.7168 8.9846C8.04863 8.75096 8.30846 8.42448 8.49629 8.00513C8.68412 7.58579 8.77804 7.09756 8.77804 6.54044C8.77804 5.56397 8.56829 4.82713 8.14881 4.32991C7.73558 3.8267 7.14391 3.5751 6.37381 3.5751C5.9731 3.5751 5.64753 3.60505 5.39709 3.66496V9.21823Z"
          fill="#6B492A"
        />
        <path
          d="M14.5747 3C14.894 3 15.1852 3.02396 15.4481 3.07189C15.7111 3.11981 15.9835 3.17972 16.2652 3.2516L16.4906 3.30552L16.4061 4.72529H15.7581L15.4763 3.94352C15.3261 3.8297 15.1601 3.74882 14.9786 3.7009C14.8033 3.64698 14.5528 3.62003 14.2273 3.62003C13.8078 3.62003 13.429 3.73385 13.0909 3.96149C12.759 4.18913 12.4961 4.51262 12.302 4.93196C12.1079 5.34531 12.0109 5.82756 12.0109 6.37869C12.0109 6.92383 12.0985 7.43004 12.2738 7.8973C12.4554 8.35858 12.7183 8.73 13.0627 9.01155C13.4133 9.28712 13.8328 9.4249 14.3212 9.4249C14.9974 9.4249 15.4419 9.27214 15.6548 8.96662L15.9553 8.2208H16.5V9.58665C16.3998 9.59264 16.2339 9.63158 16.0022 9.70347C15.708 9.79332 15.4168 9.86521 15.1288 9.91913C14.8471 9.97304 14.5184 10 14.1427 10C13.4853 10 12.8999 9.86222 12.3865 9.58665C11.8731 9.31108 11.4724 8.92469 11.1844 8.42747C10.8964 7.92426 10.7524 7.34617 10.7524 6.6932C10.7524 5.98032 10.9246 5.34232 11.2689 4.7792C11.6133 4.21609 12.0766 3.77878 12.6589 3.46727C13.2474 3.15576 13.886 3 14.5747 3Z"
          fill="#6B492A"
        />
        <path
          d="M3.5 12.654V12.2873H4.91702V12.6479L4.50925 12.709L5.21266 15.6553L5.34009 16.2115L5.47262 15.6797L5.97724 13.2225L5.84981 12.709L5.47772 12.654V12.2873H6.92533V12.6479L6.50736 12.709L7.23116 16.2482L7.3484 15.6186L7.96516 12.709L7.55228 12.654V12.2873H8.84698V12.654L8.47998 12.709L7.42995 16.9756H6.85906L6.20152 14.176L5.54908 16.9756H5.00368L3.82112 12.709L3.5 12.654Z"
          fill="#6B492A"
        />
        <path
          d="M10.3284 13.5098C10.6581 13.5098 10.9129 13.5974 11.093 13.7726C11.2731 13.9478 11.3632 14.2412 11.3632 14.6528V16.6088H11.6792V16.8533C11.618 16.8981 11.5382 16.9328 11.4396 16.9572C11.3411 16.9857 11.2442 17 11.1491 17C11.0268 17 10.9384 16.9715 10.884 16.9144C10.8297 16.8533 10.8008 16.7555 10.7974 16.621C10.6105 16.8737 10.3471 17 10.0073 17C9.834 17 9.67939 16.9613 9.54346 16.8839C9.40754 16.8024 9.30219 16.6903 9.22743 16.5477C9.15267 16.4051 9.1153 16.2441 9.1153 16.0648C9.1153 15.7958 9.19855 15.5738 9.36506 15.3985C9.53157 15.2233 9.73885 15.097 9.98692 15.0196C10.235 14.9381 10.4847 14.8973 10.7362 14.8973V14.7139C10.7362 14.4368 10.6835 14.2433 10.5782 14.1333C10.4729 14.0232 10.3165 13.9682 10.1093 13.9682C9.99711 13.9682 9.86968 13.9906 9.72696 14.0355C9.58424 14.0803 9.46021 14.1373 9.35486 14.2066L9.23763 13.8704C9.35317 13.7563 9.51798 13.6687 9.73206 13.6076C9.94954 13.5424 10.1483 13.5098 10.3284 13.5098ZM10.7362 15.2641C10.4813 15.2641 10.252 15.3191 10.0481 15.4291C9.84759 15.5391 9.74735 15.7143 9.74735 15.9548C9.74735 16.35 9.92235 16.5477 10.2724 16.5477C10.3539 16.5477 10.4372 16.5273 10.5221 16.4866C10.6105 16.4458 10.6801 16.3969 10.7311 16.3399L10.7362 15.2641ZM9.69638 12.8374C9.59443 12.8374 9.50778 12.8028 9.43642 12.7335C9.36846 12.6642 9.33448 12.5725 9.33448 12.4584C9.33448 12.328 9.37695 12.22 9.46191 12.1345C9.55026 12.0448 9.6488 12 9.75754 12C9.87308 12 9.96313 12.0346 10.0277 12.1039C10.0923 12.1732 10.1245 12.2689 10.1245 12.3912C10.1245 12.5257 10.0804 12.6337 9.99202 12.7152C9.90366 12.7967 9.80512 12.8374 9.69638 12.8374ZM10.9044 12.8374C10.8025 12.8374 10.7175 12.8028 10.6496 12.7335C10.5816 12.6642 10.5476 12.5705 10.5476 12.4523C10.5476 12.326 10.5918 12.22 10.6801 12.1345C10.7685 12.0448 10.8636 12 10.9656 12C11.0845 12 11.1746 12.0346 11.2357 12.1039C11.3003 12.1732 11.3326 12.2669 11.3326 12.3851C11.3326 12.5236 11.2901 12.6337 11.2052 12.7152C11.1202 12.7967 11.02 12.8374 10.9044 12.8374Z"
          fill="#6B492A"
        />
        <path
          d="M12.4297 16.0098L12.5571 16.3643C12.6489 16.4621 12.7339 16.5334 12.812 16.5782C12.8902 16.619 12.987 16.6394 13.1025 16.6394C13.2691 16.6394 13.3999 16.5986 13.495 16.5171C13.5902 16.4315 13.6378 16.3073 13.6378 16.1443C13.6344 16.022 13.5868 15.9222 13.495 15.8447C13.4067 15.7673 13.2674 15.6777 13.0771 15.5758L12.9496 15.5086C12.6948 15.3619 12.496 15.205 12.3533 15.0379C12.2105 14.8708 12.1392 14.665 12.1392 14.4205C12.1392 14.2168 12.1935 14.0436 12.3023 13.901C12.411 13.7584 12.5487 13.6524 12.7152 13.5831C12.8851 13.5139 13.0584 13.4792 13.2351 13.4792C13.3846 13.4792 13.5358 13.4996 13.6887 13.5403C13.8416 13.577 13.964 13.6218 14.0557 13.6748V14.3839H13.7652L13.648 14.0355C13.529 13.8847 13.3931 13.8093 13.2402 13.8093C13.0941 13.8093 12.9751 13.846 12.8834 13.9193C12.795 13.9886 12.7508 14.0945 12.7508 14.2372C12.7508 14.3431 12.7797 14.4368 12.8375 14.5183C12.8987 14.5958 12.9666 14.6589 13.0414 14.7078C13.1195 14.7567 13.2538 14.8362 13.4441 14.9462C13.6208 15.044 13.7601 15.1316 13.862 15.209C13.9674 15.2865 14.0557 15.3863 14.1271 15.5086C14.1985 15.6267 14.2341 15.7734 14.2341 15.9487C14.2341 16.291 14.1237 16.5518 13.9028 16.7311C13.6819 16.9063 13.3999 16.9939 13.0567 16.9939C12.9004 16.9939 12.7389 16.9694 12.5724 16.9205C12.4093 16.8757 12.2666 16.8126 12.1443 16.7311V16.0098H12.4297Z"
          fill="#6B492A"
        />
        <path
          d="M15.6131 13.5831H16.449V14.0171H15.6284V15.3802C15.6284 15.694 15.6335 15.9242 15.6437 16.0709C15.6573 16.2176 15.6827 16.3276 15.7201 16.401C15.7609 16.4703 15.8272 16.5049 15.9189 16.5049C15.9903 16.5049 16.0769 16.4906 16.1789 16.4621C16.2842 16.4336 16.3573 16.403 16.3981 16.3704L16.5 16.6638C16.415 16.7657 16.2876 16.8472 16.1177 16.9083C15.9512 16.9654 15.7915 16.9939 15.6386 16.9939C15.4211 16.9939 15.2631 16.9185 15.1645 16.7677C15.066 16.617 15.0167 16.3786 15.0167 16.0526V14.0171H14.6395V13.7543C14.7992 13.7054 14.9046 13.6687 14.9555 13.6443C15.0099 13.6157 15.0575 13.5628 15.0983 13.4853C15.1492 13.3794 15.2189 13.1451 15.3073 12.7824H15.6131V13.5831Z"
          fill="#6B492A"
        />
      </g>
      <defs>
        <clipPath id="clip0_13910_201630">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

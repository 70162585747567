import React, {memo} from "react";

export const IconOutbox = memo(({color, height, width, className}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.5 9.382L15.894 4.171C15.566 3.514 14.734 3 14 3H11.5C11.224 3 11 3.224 11 3.5C11 3.776 11.224 4 11.5 4H14C14.362 4 14.838 4.295 15 4.618L17.606 9.829C17.634 9.884 17.661 9.946 17.687 10.012C17.626 10.004 17.564 10 17.5 10H12.5C12.224 10 12 10.224 12 10.5C12 11.878 10.878 13 9.5 13C8.122 13 7 11.878 7 10.5C7 10.224 6.776 10 6.5 10H1.5C1.437 10 1.375 10.004 1.313 10.012C1.339 9.946 1.366 9.884 1.394 9.829L4 4.618C4.162 4.295 4.638 4 5 4H7.5C7.776 4 8 3.776 8 3.5C8 3.224 7.776 3 7.5 3H5C4.266 3 3.434 3.514 3.106 4.171L0.5 9.382C0.22 9.943 0 10.873 0 11.5V15.5C0 16.327 0.673 17 1.5 17H17.5C18.327 17 19 16.327 19 15.5V11.5C19 10.873 18.78 9.943 18.5 9.382ZM18 15.5C18 15.776 17.776 16 17.5 16H1.5C1.224 16 1 15.776 1 15.5V11.5C1 11.224 1.224 11 1.5 11H6.036C6.279 12.694 7.74 14 9.5 14C11.26 14 12.721 12.694 12.964 11H17.5C17.776 11 18 11.224 18 11.5V15.5Z"
        fill={color || "#000"}
      />
      <path
        d="M11.854 5.64601L9.854 3.64601C9.659 3.45101 9.342 3.45101 9.147 3.64601L7.147 5.64601C6.952 5.84101 6.952 6.15801 7.147 6.35301C7.342 6.54801 7.659 6.54801 7.854 6.35301L9 5.20701V9.50001C9 9.77601 9.224 10 9.5 10C9.776 10 10 9.77601 10 9.50001V5.20701L11.146 6.35301C11.244 6.45101 11.372 6.49901 11.5 6.49901C11.628 6.49901 11.756 6.45001 11.854 6.35301C12.049 6.15801 12.049 5.84101 11.854 5.64601Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconOutbox;

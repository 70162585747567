import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
//local
import {Modal} from "components/Modal";
import {IconFunnel} from "components/icons";
import MultipleSelect from "components/selects/MultipleSelect";
import SelectionRow from "components/selects/SelectionRow";
import {rfpRecipientsFilterRow} from "constants/tabs.const";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {showSuccess} from "helpers/notifications.helper";
import {color} from "constants/color.consts";

export const SendSuppliersModal = ({
  exceptionIds,
  rfpRecipientsFilterParamsInitial,
  onSetRfpRecipientsFilterParams,
  onGetCompaniesForFilteredRecipients,
  onClose,
  onSend,
}) => {
  const {t} = useTranslation();
  const [rfpRecipientsFilterParams, setRfpRecipientsFilterParams] = useState(
    rfpRecipientsFilterParamsInitial
  );
  const [filteredRecipients, setFilteredRecipients] = useState(null);

  const sendRecipientsIds = useMemo(
    () =>
      filteredRecipients
        ?.map((item) => item.id)
        .filter((item) => !exceptionIds.includes(item)),
    [exceptionIds, filteredRecipients]
  );

  const filterRowItems = useMemo(
    () =>
      Object.entries(rfpRecipientsFilterParams)
        .filter((item) => Array.isArray(item[1]))
        .map((item) => item[1].map((value) => [item[0], value]))
        .flat()
        .sort((a, b) => (a[0] > b[0] ? -1 : 1)),
    [rfpRecipientsFilterParams]
  );

  const onSelectChange = (name, value) => {
    const params = {...rfpRecipientsFilterParams, [name]: value};
    if (!value || (Array.isArray(value) && !value.length)) delete params[name];
    onSetRfpRecipientsFilterParams(params);
    setRfpRecipientsFilterParams(params);
    Object.keys(params).length > 2
      ? onGetCompaniesForFilteredRecipients().then(setFilteredRecipients)
      : setFilteredRecipients(null);
  };

  const handleRemoveFilterValue = (value) => {
    const deletedName = filterRowItems.find((item) => item[1] === value)?.[0];
    const deletedValue = filterRowItems
      .filter((item) => item[0] === deletedName && item[1] !== value)
      .map((item) => item[1]);
    deletedName && onSelectChange(deletedName, deletedValue);
  };

  return (
    <Modal
      onClose={onClose}
      width={600}
      boldTitle
      noTitle
      noCenterContent
      titleText={`MODAL_SEND_SUPPLIERS_TITLE_0${true ? "1" : "2"}`}
    >
      <div className="pa-8">
        <div className="mb-4">{t("MODAL_SEND_SUPPLIERS_DESCRIPTION_01")}</div>
        <div className="mb-8">{t("MODAL_SEND_SUPPLIERS_DESCRIPTION_02")}</div>
        <div className="df mb-4">
          <IconFunnel height="30" width="30" color={color.green} addClass="mr-2" />
          <div className="df-row-center">
            {rfpRecipientsFilterRow.map((selection) => (
              <MultipleSelect
                key={selection.label}
                value={rfpRecipientsFilterParams?.[selection.label]}
                list={selection.array}
                customSelectAllElement={selection.selectAll}
                tiny
                addClass="mr-0"
                uppercaseButton
                notShowingSelections
                noMultipleChange
                needSearch={selection.isNeedSearch}
                color={selection.color}
                bottomElement={selection.bottomElement}
                onChange={(value) => onSelectChange(selection.label, value)}
                title={selection.title}
                titleInButton
              />
            ))}
          </div>
        </div>
        {!!filterRowItems.length && (
          <SelectionRow
            values={filterRowItems.map((item) => ({
              value: item[1],
              label: item[1],
              color: item[1].includes("REGION") ? "blue" : "green",
            }))}
            tiny
            onRemove={handleRemoveFilterValue}
          />
        )}
        {sendRecipientsIds && !!filterRowItems.length && (
          <div className="fz-16 __italic mt-4">{`${sendRecipientsIds?.length} ${t(
            "MODAL_SEND_SUPPLIERS_INFO"
          )}`}</div>
        )}
        <div className="df-row-jsb mt-8">
          <ButtonTransparent
            name={"CANCEL"}
            color="red"
            addClass="mr-4"
            fullWidth
            onClick={onClose}
          />
          <ButtonTransparent
            name={"MODAL_SEND_SUPPLIERS_OK"}
            fullWidth
            nonTransparent
            onClick={() =>
              onSend(sendRecipientsIds, rfpRecipientsFilterParams).then(() => {
                showSuccess(t("SUCCESSFULLY_SUBMITTED"));
                onClose();
              })
            }
            disabled={!sendRecipientsIds?.length}
          />
        </div>
      </div>
    </Modal>
  );
};

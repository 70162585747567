import React, {useState, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {useParams, useNavigate} from "react-router-dom";
//local
import {toProjectId} from "constants/routes.const";
import {CustomTooltip} from "components/CustomTooltip";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {OffersSwitchOffModal} from "../RequestEdit/OffersSwitchOffModal";
import {RemindersModal} from "../RequestEdit/RemindersModal";
import {PublishedModal} from "./PublishedModal";
import {ContractNumber} from "components/contractProcess/ContractNumber";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {dateFormat, dateTimeFormat} from "helpers/datetime";
import {saveDocument} from "helpers/helper";
import {SuggestionSendSuppliersModal} from "./SuggestionSendSuppliersModal";
import {SendSuppliersModal} from "./SendSuppliersModal";
import {Tag} from "components/Tag";
import {ProjectLabel} from "components/ProjectLabel";
import {procurementStatuses} from "constants/buyer.consts";
import {defaultNameFieldSearch} from "constants/user.consts";
import {
  IconPrint,
  IconEdit,
  IconMapMarker,
  IconChevronLeft,
  IconLock,
  IconCheck,
  IconEyeCrossed,
  IconBell,
  IconCog,
  IconBullHorn,
} from "components/icons";
import {color} from "constants/color.consts";
import {rfpStatuses} from "constants/buyer.consts";
import {hasUserPermission, hasPermission} from "helpers/helper";
import {LockValue} from "components/LockValue";
import {roles} from "constants/auth.const";
import cn from "classnames";

const RequestHeadView = inject("store")(
  observer(({store: {buyer, organization, auth, clientStore}}) => {
    const {
      isContract,
      currentRequest,
      activeRequestTab,
      offerIdWithContract,
      isFinishedContract,
      currentContract,
      rfpRecipientsFilterParams,
      newPublishedRFPRecipients,
    } = buyer;
    const {blacklistOrganization} = organization;
    const {clientRole, user} = auth;
    const navigate = useNavigate();
    const {requestId} = useParams();
    const {t} = useTranslation();
    const [isDownload, setIsDownload] = useState(false);
    const [isSuggestionSendSuppliersdModal, setIsSuggestionSendSuppliersModal] =
      useState(false);
    const [isSendSuppliersModal, setIsSendSuppliersModal] = useState(false);
    const isAboveNameRequest = activeRequestTab === 3 && !isFinishedContract;
    const isPassedDate = currentRequest?.closedAt
      ? new Date().getTime() > new Date(currentRequest?.closedAt).getTime()
      : true;
    const isShowContractNumber = user?.ActiveOrganization?.showContractNumber;
    const isClosedForOffers = currentRequest?.hardDateCloseOffers;
    const exceptionOrganizationIds = [
      ...blacklistOrganization?.map((item) => item.id),
      ...currentRequest.recipients?.registered?.map((item) => item.id),
    ];

    const rfpStatus = useMemo(
      () =>
        currentRequest.Project?.ProjectItem?.status === procurementStatuses.STOPPED
          ? rfpStatuses.STOPPED
          : currentRequest?.status ?? rfpStatuses.DRAFT,
      [currentRequest]
    );
    const isDraft = useMemo(() => rfpStatus === rfpStatuses.DRAFT, [rfpStatus]);
    const isRemoved = useMemo(() => rfpStatus === rfpStatuses.REMOVED, [rfpStatus]);
    const isStopped = useMemo(() => rfpStatus === rfpStatuses.STOPPED, [rfpStatus]);
    const statusName = {
      DRAFT: t("RFT_STATUS_CREATED"),
      PUBLISHED: t("RFT_STATUS_PUBLISHED"),
      REMOVED: t("RFT_STATUS_REMOVED"),
      STOPPED: t("RFT_STATUS_STOPPED"),
    };

    // toggles row
    const [isHideOffersSwitchOffModal, setHideOffersSwitchOffModal] = useState(false);
    const [isHardDateCloseOffersSwitchOffModal, setHardDateCloseOffersSwitchOffModal] =
      useState(false);
    const [isRemindersModal, setRemindersModal] = useState(false);
    const [hardDateCloseOffers, setHardDateCloseOffers] = useState(
      currentRequest?.hardDateCloseOffers
    );
    const [isPublic, setIsPublic] = useState(currentRequest?.isPublic);
    const [reminders, setReminders] = useState(currentRequest?.reminders);
    const [offersHidden, setOffersHidden] = useState(currentRequest?.offersHidden);
    // hide offers toggle
    const isOffersHideInfo =
      currentRequest.status === rfpStatuses.PUBLISHED &&
      !hasUserPermission([roles.ADMIN, roles.OWNER], clientRole);

    const isEditNewRecipients =
      newPublishedRFPRecipients.numberOrganizations +
      newPublishedRFPRecipients.numberEmails;

    const canEdit = hasPermission(["EDIT_RFT"]);
    const LockEditRft = ({addClass, addClassIcon, children}) => (
      <LockValue
        addClassWrapper={addClass}
        addClassIcon={addClassIcon}
        canPermissions={["EDIT_RFT"]}
        needLabel
        needShowModalToSubscribe
      >
        {children}
      </LockValue>
    );

    const updateCurrentRequest = (data) => {
      buyer.updateCurrentRequestInfo(data);
      buyer.saveCurrentRequest(currentRequest.id, false, true);
    };
    const handleChangeHardDateCloseOffers = (checked) => {
      setHardDateCloseOffers(checked);
      updateCurrentRequest({hardDateCloseOffers: checked});
    };
    const handleChangeReminders = (checked) => {
      setReminders(checked);
      updateCurrentRequest({reminders: checked});
    };
    const handleChangeOffersHidden = (checked) => {
      setOffersHidden(checked);
      updateCurrentRequest({offersHidden: checked});
    };
    const handleChangePublicStatus = (checked) => {
      setIsPublic(checked);
      updateCurrentRequest({isPublic: checked});
    };

    const clickEdit = async () => {
      await buyer.getCurrentRequestInfo(requestId);
      buyer.setActiveRequestTab(activeRequestTab === 1 ? 1 : 0);
      buyer.setEditStatus(true);
    };

    const downloadPDF = async () => {
      setIsDownload(true);
      const fileName = `contract-${currentContract.id}.pdf`;
      const data = await clientStore.downloadPDF(currentContract.id, "buyer");
      saveDocument(data, "application/pdf", fileName);
      setIsDownload(false);
    };

    const handleSendSuggestionModal = () => {
      setIsSuggestionSendSuppliersModal(false);
      setIsSendSuppliersModal(true);
    };

    const handleSendSuppliersModal = async (organizationIds, params) => {
      const categories = Object.entries(params)
        .filter((item) => item[0] !== "availableIn")
        .filter((item) => typeof item[1] !== "string")
        .map((item) => item[1])
        .flat();
      await buyer.sendRfptoNewSuppliers(currentRequest.id, {
        organizationIds,
        categories,
        locations: params.availableIn ?? [],
      });
      buyer.setRfpRecipientsFilterParams(defaultNameFieldSearch);
    };

    return (
      <React.Fragment>
        <div className="view-header _wrapper">
          <div className="view-header_left-side">
            <div className="df">
              {currentRequest.Project && (
                <React.Fragment>
                  <ButtonTransparent
                    icon={<IconChevronLeft color={color.purple} />}
                    noBorder
                    small
                    addClass="view-header__back-button"
                    onClick={() => navigate(toProjectId(currentRequest.Project.id))}
                  />
                  <ProjectLabel
                    name={currentRequest.Project.name}
                    code={currentRequest.Project.reference}
                    wideName
                    width="400"
                    addClass="mr-8 mt-1"
                  />
                </React.Fragment>
              )}
              {currentRequest.Project?.location && (
                <React.Fragment>
                  <IconMapMarker addClass="mr-1 mt-1" color={color.blue} />
                  <span className="view-header__address">
                    {currentRequest.Project.location}
                  </span>
                </React.Fragment>
              )}
            </div>
            {isAboveNameRequest ? (
              isContract ? (
                <span className="view-header__above-title">
                  {t("CONTRACT_FOR_SIGNATURE")}
                </span>
              ) : (
                <span className="view-header__above-title">{t("CREATE_CONTRACT")}</span>
              )
            ) : null}
            <h3
              className={cn("view-header__title", {
                "pb-2": !isAboveNameRequest,
                "on-contract-tab": isAboveNameRequest,
                "draft-status": isDraft,
                "removed-status": isRemoved || isStopped,
              })}
            >
              {currentRequest.name}
            </h3>
          </div>
          {activeRequestTab !== 3 && !isFinishedContract && (
            <div className="view-header_right-side">
              <div className="view-header__status-row">
                <span
                  className={cn("status __uppercase __bold", rfpStatus?.toLowerCase())}
                >
                  {statusName[rfpStatus]}
                </span>
                {!isStopped && (
                  <span className="ml-3">{dateTimeFormat(currentRequest.updatedAt)}</span>
                )}
                <LockEditRft>
                  <CustomRadioSwitch
                    name="isPublic"
                    checked={isPublic}
                    uppercase
                    small
                    disabled={isStopped}
                    addWrapperClass="ml-4 mr-1"
                    isActive={(checked) => canEdit && handleChangePublicStatus(checked)}
                  />
                  <IconBullHorn />
                </LockEditRft>
              </div>
              {!offerIdWithContract && !isRemoved && (
                <div className="view-header__toggles-row">
                  <span
                    className={isClosedForOffers && isPassedDate ? "__closed-offer" : ""}
                  >
                    {isClosedForOffers
                      ? isPassedDate
                        ? t("CLOSED_FOR_OFFERS_HARD")
                        : t("CLOSES_FOR_OFFERS_HARD")
                      : t("CLOSES_SOFT")}
                  </span>
                  <span className="ml-1 mr-2">{dateFormat(currentRequest.closedAt)}</span>
                  <LockEditRft addClassIcon="ml-0">
                    <CustomRadioSwitch
                      name="hardDateCloseOffers"
                      checked={hardDateCloseOffers}
                      uppercase
                      small
                      redOnColor={isClosedForOffers && isPassedDate}
                      addWrapperClass="mr-1"
                      isActive={(checked) =>
                        canEdit
                          ? checked
                            ? handleChangeHardDateCloseOffers(checked)
                            : setHardDateCloseOffersSwitchOffModal(true)
                          : undefined
                      }
                      disabled={isStopped}
                    />
                    <CustomTooltip text="CREATE_RFP_HARD_DATE_CLOSE_OFFERS_LABEL">
                      <div className="mt-1">
                        <IconLock />
                      </div>
                    </CustomTooltip>
                  </LockEditRft>

                  <LockEditRft addClassIcon="ml-0">
                    <CustomRadioSwitch
                      name="reminders"
                      checked={reminders}
                      uppercase
                      small
                      addWrapperClass="mr-1 ml-8"
                      isActive={(checked) => canEdit && handleChangeReminders(checked)}
                      disabled={isStopped}
                    />
                    <CustomTooltip text="CREATE_RFP_REMEMBER_LABEL">
                      <div className="mt-1">
                        <IconBell />
                      </div>
                    </CustomTooltip>
                  </LockEditRft>

                  <LockEditRft addClassIcon="ml-0">
                    <ButtonTransparent
                      icon={<IconCog />}
                      noBorder
                      tiny
                      addClass="pl-0 pr-0 ml-2"
                      onClick={() => canEdit && setRemindersModal(true)}
                    />
                  </LockEditRft>

                  <LockEditRft addClass="mr-1" addClassIcon="ml-0">
                    {isOffersHideInfo ? (
                      <IconCheck addClass="mr-1 ml-2" color={color.green} />
                    ) : (
                      <CustomRadioSwitch
                        name="offersHidden"
                        checked={offersHidden}
                        uppercase
                        small
                        addWrapperClass="mr-1 ml-2"
                        isActive={(checked) =>
                          canEdit
                            ? checked
                              ? handleChangeOffersHidden(checked)
                              : setHideOffersSwitchOffModal(true)
                            : undefined
                        }
                      />
                    )}
                    <CustomTooltip text="CREATE_RFP_HIDE_OFFERS_LABEL">
                      <div className="mt-1 mr-1">
                        <IconEyeCrossed />
                      </div>
                    </CustomTooltip>
                  </LockEditRft>
                </div>
              )}
              <div className="view-header__button-row">
                <Tag text={currentRequest.CurrencyCode} miniSelectView />

                <LockEditRft addClass="mr-1" addClassIcon="ml-0">
                  {!offerIdWithContract && !isRemoved && (
                    <ButtonTransparent
                      addClass="view-header__status-row__button ml-6"
                      name="EDIT"
                      small
                      type="link"
                      icon={<IconEdit addClass="mr-2" />}
                      onClick={() => canEdit && clickEdit()}
                      disabled={isStopped}
                    />
                  )}
                </LockEditRft>
              </div>
            </div>
          )}
          {isContract && isShowContractNumber && activeRequestTab === 3 && (
            <ContractNumber
              number={currentContract?.number}
              addClass="mt-11"
              style={!isFinishedContract ? {marginRight: "160px"} : {}}
            />
          )}
          {isFinishedContract && (
            <div className="view-header__button-row mt-7">
              <ButtonTransparent
                addClass="view-header__button-row__button ml-4"
                name={t("PRINT")}
                small
                icon={<IconPrint />}
                loading={isDownload}
                onClick={downloadPDF}
              />
              {/* <ButtonTransparent
                          addClass="view-header__button-row__button ml-4"
                          name=".zip"
                          icon={<IconDownload />}
                          onClick={downloadZIP}
                        /> */}
            </div>
          )}
        </div>
        {!!isEditNewRecipients && (
          <PublishedModal
            numberOrganizations={newPublishedRFPRecipients.numberOrganizations}
            numberEmails={newPublishedRFPRecipients.numberEmails}
            onClose={() => buyer.setNewPublishedRFPRecipients({})}
          />
        )}
        {isSuggestionSendSuppliersdModal && (
          <SuggestionSendSuppliersModal
            onClose={() => setIsSuggestionSendSuppliersModal(false)}
            onSend={handleSendSuggestionModal}
          />
        )}
        {isSendSuppliersModal && (
          <SendSuppliersModal
            exceptionIds={exceptionOrganizationIds}
            rfpRecipientsFilterParamsInitial={rfpRecipientsFilterParams}
            onSetRfpRecipientsFilterParams={(params) =>
              buyer.setRfpRecipientsFilterParams(params)
            }
            onGetCompaniesForFilteredRecipients={() =>
              buyer.getCompaniesForFilteredRecipients()
            }
            onClose={() => setIsSendSuppliersModal(false)}
            onSend={handleSendSuppliersModal}
          />
        )}

        {isHardDateCloseOffersSwitchOffModal && (
          <OffersSwitchOffModal
            title="MODAL_HARD_DATE_SWITCH_TITLE"
            description="MODAL_HARD_DATE_CLOSE_OFFERS_SWITCH_DESCRIPTION"
            onOk={() => handleChangeHardDateCloseOffers(false)}
            onClose={() => setHardDateCloseOffersSwitchOffModal(false)}
          />
        )}
        {isRemindersModal && (
          <RemindersModal
            reminderDays={currentRequest?.reminderDays ?? []}
            closedAt={currentRequest?.closedAt}
            onOk={async (days) => await updateCurrentRequest({reminderDays: days})}
            onClose={() => setRemindersModal(false)}
          />
        )}
        {isHideOffersSwitchOffModal && (
          <OffersSwitchOffModal
            title="MODAL_HIDE_OFFERS_SWITCH_TITLE"
            description="MODAL_HIDE_OFFERS_SWITCH_DESCRIPTION"
            needAttention
            onOk={() => handleChangeOffersHidden(false)}
            onClose={() => setHideOffersSwitchOffModal(false)}
          />
        )}
      </React.Fragment>
    );
  })
);

export default RequestHeadView;

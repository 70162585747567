import React from "react";
// local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {Modal} from "components/Modal";

export const ModalAbout = ({onClick}) => {
  return (
    <Modal
      titleIcon={null}
      titleText="APP_START_PAGE_HEADER"
      suffixTitleIcon=" 🎉"
      hugeTitle
      noCloseOnEsc
      width={680}
      rounded
      onClick={onClick}
    >
      <div className="pa-5 df-column-center">
        <iframe
          src="https://player.vimeo.com/video/657058643?h=a5e5d1834d"
          title="Vimeo video player"
          width="640"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
        <ButtonTransparent
          onClick={onClick}
          addClass="mt-8 mb-4"
          name="DONE"
          medium
          nonTransparent
        />
      </div>
    </Modal>
  );
};

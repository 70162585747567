import React from "react";
import {Tag} from "antd";
import cn from "classnames";
import {IconCloseRound} from "components/icons";

// color: "blue" | "red" | "green" | "purple" | "orange" | "link"
export const ClosableTags = ({
  tags,
  suffixData,
  addClass,
  tagClass,
  close,
  field,
  index,
  small,
  color,
  hoverable,
  onClick,
  dontRemove,
  isRelativeCustomContent,
  customContent,
}) => {
  const handleClose = (tag) => {
    close(
      field
        ? tags.filter((item) => item[index] !== tag[index])
        : tags.filter((item) => item !== tag)
    );
  };

  const disableRemove = (tag) =>
    tag instanceof Object
      ? !dontRemove.some((item) => item.id === tag.id)
      : !dontRemove.includes(tag);

  return (
    <div
      className={cn(
        "closable-tags_wrapper",
        {[addClass]: !!addClass},
        {__small: !!small}
      )}
      // style={{position: isRelativeCustomContent && "relative"}}
    >
      {tags?.length
        ? tags.map((tag, i) => (
            <React.Fragment key={index ? `${tag[index]}-${i}` : `${tag}-${i}`}>
              <Tag
                key={index ? `${tag[index]}-${i}` : `${tag}-${i}`}
                className={cn("closable-tags_tag", {
                  "pr-3": !close || !disableRemove(tag),
                  "mr-2": field !== "email",
                  __strikethrough: field === "email" && !!tag.isBounced,
                  [tagClass]: !!tagClass,
                  [color]: !!color,
                  __small: !!small,
                  __hoverable: hoverable,
                  __clickable: !!onClick,
                })}
                closable={close ? disableRemove(tag) : false}
                onClose={() => (handleClose ? handleClose(tag) : false)}
                onClick={() => (onClick ? onClick(tag) : false)}
              >
                {field ? tag[field] : tag}
                {suffixData}
                {close && disableRemove(tag) && <IconCloseRound />}
              </Tag>
              <div style={{position: isRelativeCustomContent && "relative"}}>
                {customContent}
              </div>
            </React.Fragment>
          ))
        : null}
    </div>
  );
};

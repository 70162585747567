import React from "react";
import {useTranslation} from "react-i18next";
//local
import {budgetSymbolsAndColors} from "constants/buyer.consts";
import {IconLock} from "components/icons/index";
import {dateFormat} from "helpers/datetime";
import {color} from "constants/color.consts";

const ProposalItemBudget = ({
  adjustmentValues,
  price,
  budgetValue,
  language,
  isHidedInfo,
  offersClosedAt,
}) => {
  const {t} = useTranslation();

  const priceAdjustments = adjustmentValues ?? [];
  const isPriceAdjustments = !!priceAdjustments?.length;
  const adjustmentOfferValue = isPriceAdjustments
    ? price + priceAdjustments.reduce((sum, item) => sum + item.value, 0)
    : price;

  // allows or blocks the display of the value depending on isHidedInfo
  const showValue = (value, hideIcon) =>
    isHidedInfo ? (
      <IconLock
        width="14"
        height="14"
        addClass="proposals-tab_body__row__icon-lock"
        color={color[hideIcon ? "white" : "discreteText"]}
      />
    ) : (
      value
    );

  const choice = (value) => (!value ? "equally" : value > 0 ? "more" : "less");

  return (
    <React.Fragment>
      <div
        className="proposals-tab_body__row __bold"
        style={isHidedInfo ? {color: color.discreteText} : {}}
      >
        {showValue(price?.toLocaleString(language ?? "sv"))}
        {isHidedInfo && (
          <i>{`${t("CREATE_RFP_HIDE_OFFERS_UNLOCK_DATE")} ${dateFormat(
            new Date(offersClosedAt).setDate(new Date(offersClosedAt).getDate() + 1)
          )}`}</i>
        )}
      </div>
      <div className="proposals-tab_body__row __bold">
        {showValue(adjustmentOfferValue?.toLocaleString(language ?? "sv"), true)}
      </div>
      <div className="proposals-tab_body__row __italic">
        {!!+budgetValue
          ? (+budgetValue).toLocaleString(language ?? "sv")
          : t("RFP_PROPOSALS_LIST_BUDGET_COLUMN_NO_BUDGET_PLACEHOLDER")}
      </div>
      <div
        className="proposals-tab_body__row __bold"
        style={{
          color:
            budgetSymbolsAndColors[choice(adjustmentOfferValue - +budgetValue)].color,
        }}
      >
        {!!+budgetValue &&
          budgetSymbolsAndColors[choice(adjustmentOfferValue - +budgetValue)].symbol}{" "}
        {!!+budgetValue &&
          Math.abs(+budgetValue - adjustmentOfferValue).toLocaleString(language ?? "sv")}
      </div>
    </React.Fragment>
  );
};

export default ProposalItemBudget;

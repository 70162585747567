import React from "react";
// locale
import FormikSelect from "components/selects/FormikSelect";
import {
  availableInArray,
  employeesArray,
  jobMinSizeArray,
  jobMaxSizeArray,
} from "constants/select.consts";
import MultipleSelect from "../selects/MultipleSelect";
import {useTranslation} from "react-i18next";
import {IconMapMarker} from "components/icons/index";
// import {color} from "constants/color.consts";
// import {IconBag, IconUsers} from "components/icons/index";

const SearchPreferenceFirstSetupSelections = ({
  values,
  setFieldValue,
  isFromOnboarding,
}) => {
  const {t} = useTranslation();
  return (
    <div className="first-setup_selection__wrapper">
      {isFromOnboarding && (
        <h4 className="topic">
          <IconMapMarker />
          <span className="title">{t("WHERE_DO_YOU_WORK")}</span>
        </h4>
      )}

      <div className="first-setup_selection__item-wrap">
        <MultipleSelect
          list={availableInArray}
          customSelectAllElement="FIRST_SETUP_REGION_00"
          title={!isFromOnboarding && "availableIn"}
          value={values.availableIn}
          onChange={(value) => {
            setFieldValue("availableIn", value);
          }}
        />
      </div>

      {/*{isFromOnboarding && (
        <h4 className="topic">
          <IconBag color={color.black} />
          <span className="title">{t("HOW_BIG_JOBS")}</span>
        </h4>
      )}*/}

      {!isFromOnboarding && (
        <div className="first-setup_selection__item-wrap __with-dash">
          <div className="blob-title">{t("FIRST_SETUP_SELECT_JOB_SIZE")}</div>
          <div className="blob-item">
            <FormikSelect
              list={jobMinSizeArray}
              name="jobSizeMin"
              addClass="selection first-setup_selection__item"
            />
            <div className="pl-1 pr-1 pb-2">-</div>
            <FormikSelect
              list={jobMaxSizeArray}
              addClass="selection first-setup_selection__item"
              name="jobSizeMax"
            />
          </div>
        </div>
      )}

      {/*{isFromOnboarding && (
        <h4 className="topic">
          <IconUsers color={color.black} />
          <span className="title">{t("HOW_MANY_IN_COMPANY")}</span>
        </h4>
      )}*/}

      {!isFromOnboarding && (
        <div className="first-setup_selection__item-wrap">
          <FormikSelect
            list={employeesArray}
            addClass="selection first-setup_selection__item"
            name="employees"
            isDefault
            title={"FIRST_SETUP_SELECT_EMPLOYEES"}
          />
        </div>
      )}
    </div>
  );
};

export default SearchPreferenceFirstSetupSelections;

import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import i18next from "i18next";
// local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {Modal} from "components/Modal";
import {showSuccess} from "helpers/notifications.helper";
import {
  IconCreditUpdateFresh,
  IconCreditUpdateGettingOlder,
  IconCreditUpdateOld,
  IconCreditUpdateNoData,
  IconRefresh,
  IconMailLetter,
  IconPhone,
} from "components/icons";
import {oneDay} from "constants/user.consts";
import {color} from "constants/color.consts";

const CreditUpdate = inject("store")(
  observer(({store: {organization, auth}}) => {
    const {organizationDetail} = organization;
    const {user} = auth;
    const {t} = useTranslation();

    const [isCreditsUpdateModal, setCreditsUpdateModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const language = i18next.language;
    const dateOptions = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    };

    const updatedDate = organizationDetail.Company.updatedCreditSafeCredit;
    const passedDays = Math.floor(
      (Date.now() - Date.parse(new Date(updatedDate))) / oneDay
    );
    const lastUpdateDate = updatedDate
      ? new Date(updatedDate).toLocaleString(language, dateOptions)
      : null;
    const currentYear = new Date().getFullYear();
    const nextMonth = new Date().getMonth() + 1;
    const newMonthStartDate = new Date(currentYear, nextMonth, 1).toLocaleString(
      language,
      dateOptions
    );
    const monthlyCredits = user.ActiveOrganization.creditsUpdateLimit;
    const [usedCredits, setUsedCredits] = useState(
      monthlyCredits - user.ActiveOrganization.creditsUpdateUsed
    );
    const isOutCredits = !monthlyCredits || monthlyCredits < usedCredits;
    const isFilledButton = passedDays >= 30 || isOutCredits;

    const handleCreditUpdate = () => {
      setLoading(true);
      organization
        .getNewCreditRating(organizationDetail?.id)
        .then(() => {
          showSuccess();
          setUsedCredits(usedCredits - 1);
          organization
            .getOrganizationDetail(organizationDetail?.id, true)
            .finally(() => setLoading(false));
        })
        .catch(() => setLoading(false));
    };

    return (
      <div className="presentation-page__left-side__credit-update">
        {updatedDate ? (
          <React.Fragment>
            {passedDays < 30 && <IconCreditUpdateFresh />}
            {passedDays >= 30 && passedDays <= 90 && <IconCreditUpdateGettingOlder />}
            {passedDays > 90 && <IconCreditUpdateOld />}
          </React.Fragment>
        ) : (
          <IconCreditUpdateNoData />
        )}

        <span className="presentation-page__left-side__credit-update__updated">
          {`${t("UPDATED")} ${updatedDate ? lastUpdateDate : ""}`}
          {!updatedDate && <span>{t("NEVER")}</span>}
        </span>
        <ButtonTransparent
          icon={<IconRefresh color={isFilledButton ? color.white : color.blue} />}
          name={t(updatedDate ? "CREDIT_UPDATE_BUTTON" : "CREDIT_GET_BUTTON")}
          nonTransparent={isFilledButton}
          small
          nowrap
          onClick={handleCreditUpdate}
          loading={loading}
          disabled={isOutCredits}
          addClass="ml-1 mr-1"
        />
        <span className="presentation-page__left-side__credit-update__info-labels mt-4 mb-1">
          {t("CREDIT_UPDATE_USED")}
          <span>{usedCredits >= 0 ? usedCredits : 0}</span>
        </span>
        <span className="presentation-page__left-side__credit-update__info-labels mb-4">
          {`${monthlyCredits} ${t("CREDIT_UPDATE_NEW_DATE")}`}
          <span>{newMonthStartDate}</span>
        </span>
        {isOutCredits && (
          <span
            className="presentation-page__left-side__credit-update__link"
            onClick={() => setCreditsUpdateModal(true)}
          >
            {t("CREDIT_NEED_UPDATES_LINK")}
          </span>
        )}
        {isCreditsUpdateModal && (
          <Modal onClose={() => setCreditsUpdateModal(false)} width={320} noTitleHeader>
            <div className="pa-8">
              <div>
                <div className="fz-16 __bold">{t("CREDIT_NEED_UPDATES_MODAL_TITLE")}</div>
                <div className="fz-16 mt-4 mb-2">
                  {t("CREDIT_NEED_UPDATES_MODAL_DESCRIPTION")}
                </div>
                <div className="df-row-center">
                  <IconMailLetter height="13" width="20" addClass="mr-2" />
                  <a href={t("MAIL_TO_SUPPORT_LINK")}>{t("MAIL_TO_SUPPORT")}</a>
                </div>
                <div className="df-row-center mt-3 mb-2">
                  <IconPhone addClass="mr-2" />
                  <span>{t("SUPPORT_PHONE_NUMBER")}</span>
                </div>
              </div>
              <ButtonTransparent
                name="OK"
                medium
                uppercase
                nonTransparent
                style={{marginLeft: "auto"}}
                onClick={() => setCreditsUpdateModal(false)}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  })
);

export default CreditUpdate;

import React from "react";
import {useTranslation} from "react-i18next";
//local
// import Select from "components/selects/Select";
import {
  availableInArray,
  // employeesArray,
  // jobMinSizeArray,
  // jobMaxSizeArray,
} from "constants/select.consts";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import MultipleSelect from "components/selects/MultipleSelect";

export const FirstSetupSelections = ({onChange, params, tab}) => {
  const {t} = useTranslation();

  return (
    <div className="first-setup_selection__wrapper">
      <div className="first-setup_selection__item-wrap mr-2">
        <MultipleSelect
          title={"availableIn"}
          value={params.availableIn}
          list={availableInArray}
          small
          color="green"
          onChange={(value) => onChange("availableIn", value)}
          customSuffixSelectedRowcontent={
            !!params.availableIn?.length ? (
              <CustomRadioSwitch
                checked={!!params.availableIn?.length ? params.localSuppliers : false}
                uppercase
                label={t("FIRST_SETUP_SELECT_LOCAL_SUPPLIERS")}
                small
                addWrapperClass="ml-2 mba"
                wrapperStyle={{marginTop: "6px"}}
                informationText="FIRST_SETUP_SELECT_LOCAL_SUPPLIERS_TOOLTIP"
                isActive={() => onChange("localSuppliers", !params.localSuppliers)}
              />
            ) : null
          }
        />
      </div>

      {/*{`${tab}` === "0" && (
        <div className="first-setup_selection__item-wrap __with-dash">
          <div className="blob-title">{t("FIRST_SETUP_SELECT_JOB_SIZE")}</div>
          <div className="blob-item">
            <Select
              list={jobMinSizeArray}
              addClass="selection first-setup_selection__item"
              value={params.jobSizeMin}
              small
              showArrow
              minWidth
              color="green"
              fillSelectedItem
              onChange={(value) => onChange("jobSizeMin", value)}
              placeholder={"FIRST_SETUP_SELECT_SIZE_MIN"}
            />
            <div className="blob-interval">-</div>
            <Select
              list={jobMaxSizeArray}
              addClass="selection first-setup_selection__item"
              value={params.jobSizeMax}
              small
              showArrow
              minWidth
              color="green"
              fillSelectedItem
              onChange={(value) => onChange("jobSizeMax", value)}
              placeholder={"FIRST_SETUP_SELECT_SIZE_MAX"}
            />
          </div>
        </div>
      )}
      {(`${tab}` === "0" || `${tab}` === "1" || `${tab}` === "3") && (
        <div className="first-setup_selection__item-wrap">
          <Select
            list={employeesArray}
            addClass="selection first-setup_selection__item"
            value={params.employees}
            small
            showArrow
            minWidth
            color="green"
            fillSelectedItem
            onChange={(value) => onChange("employees", value)}
            placeholder={"FIRST_SETUP_SELECT_TYPES"}
            title={"FIRST_SETUP_SELECT_EMPLOYEES"}
          />
        </div>
      )}*/}
    </div>
  );
};

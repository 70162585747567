import React, {memo} from "react";

export const IconCalendar = memo(({color, addClass}) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      className={addClass ?? ""}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 2.63501H16.5V2.13501C16.5 1.85901 16.276 1.63501 16 1.63501C15.724 1.63501 15.5 1.85901 15.5 2.13501V2.63501H5.5V2.13501C5.5 1.85901 5.276 1.63501 5 1.63501C4.724 1.63501 4.5 1.85901 4.5 2.13501V2.63501H2C1.173 2.63501 0.5 3.30801 0.5 4.13501V18.135C0.5 18.962 1.173 19.635 2 19.635H19C19.827 19.635 20.5 18.962 20.5 18.135V4.13501C20.5 3.30801 19.827 2.63501 19 2.63501ZM2 3.63501H4.5V5.13501C4.5 5.41101 4.724 5.63501 5 5.63501C5.276 5.63501 5.5 5.41101 5.5 5.13501V3.63501H15.5V5.13501C15.5 5.41101 15.724 5.63501 16 5.63501C16.276 5.63501 16.5 5.41101 16.5 5.13501V3.63501H19C19.276 3.63501 19.5 3.85901 19.5 4.13501V6.63501H1.5V4.13501C1.5 3.85901 1.724 3.63501 2 3.63501ZM19 18.635H2C1.724 18.635 1.5 18.411 1.5 18.135V7.63501H19.5V18.135C19.5 18.411 19.276 18.635 19 18.635Z"
        fill={color || "#000"}
      />
      <path
        d="M8 10.635H7C6.724 10.635 6.5 10.411 6.5 10.135C6.5 9.85901 6.724 9.63501 7 9.63501H8C8.276 9.63501 8.5 9.85901 8.5 10.135C8.5 10.411 8.276 10.635 8 10.635Z"
        fill={color || "#000"}
      />
      <path
        d="M11 10.635H10C9.724 10.635 9.5 10.411 9.5 10.135C9.5 9.85901 9.724 9.63501 10 9.63501H11C11.276 9.63501 11.5 9.85901 11.5 10.135C11.5 10.411 11.276 10.635 11 10.635Z"
        fill={color || "#000"}
      />
      <path
        d="M14 10.635H13C12.724 10.635 12.5 10.411 12.5 10.135C12.5 9.85901 12.724 9.63501 13 9.63501H14C14.276 9.63501 14.5 9.85901 14.5 10.135C14.5 10.411 14.276 10.635 14 10.635Z"
        fill={color || "#000"}
      />
      <path
        d="M17 10.635H16C15.724 10.635 15.5 10.411 15.5 10.135C15.5 9.85901 15.724 9.63501 16 9.63501H17C17.276 9.63501 17.5 9.85901 17.5 10.135C17.5 10.411 17.276 10.635 17 10.635Z"
        fill={color || "#000"}
      />
      <path
        d="M5 12.635H4C3.724 12.635 3.5 12.411 3.5 12.135C3.5 11.859 3.724 11.635 4 11.635H5C5.276 11.635 5.5 11.859 5.5 12.135C5.5 12.411 5.276 12.635 5 12.635Z"
        fill={color || "#000"}
      />
      <path
        d="M8 12.635H7C6.724 12.635 6.5 12.411 6.5 12.135C6.5 11.859 6.724 11.635 7 11.635H8C8.276 11.635 8.5 11.859 8.5 12.135C8.5 12.411 8.276 12.635 8 12.635Z"
        fill={color || "#000"}
      />
      <path
        d="M11 12.635H10C9.724 12.635 9.5 12.411 9.5 12.135C9.5 11.859 9.724 11.635 10 11.635H11C11.276 11.635 11.5 11.859 11.5 12.135C11.5 12.411 11.276 12.635 11 12.635Z"
        fill={color || "#000"}
      />
      <path
        d="M14 12.635H13C12.724 12.635 12.5 12.411 12.5 12.135C12.5 11.859 12.724 11.635 13 11.635H14C14.276 11.635 14.5 11.859 14.5 12.135C14.5 12.411 14.276 12.635 14 12.635Z"
        fill={color || "#000"}
      />
      <path
        d="M17 12.635H16C15.724 12.635 15.5 12.411 15.5 12.135C15.5 11.859 15.724 11.635 16 11.635H17C17.276 11.635 17.5 11.859 17.5 12.135C17.5 12.411 17.276 12.635 17 12.635Z"
        fill={color || "#000"}
      />
      <path
        d="M5 14.635H4C3.724 14.635 3.5 14.411 3.5 14.135C3.5 13.859 3.724 13.635 4 13.635H5C5.276 13.635 5.5 13.859 5.5 14.135C5.5 14.411 5.276 14.635 5 14.635Z"
        fill={color || "#000"}
      />
      <path
        d="M8 14.635H7C6.724 14.635 6.5 14.411 6.5 14.135C6.5 13.859 6.724 13.635 7 13.635H8C8.276 13.635 8.5 13.859 8.5 14.135C8.5 14.411 8.276 14.635 8 14.635Z"
        fill={color || "#000"}
      />
      <path
        d="M11 14.635H10C9.724 14.635 9.5 14.411 9.5 14.135C9.5 13.859 9.724 13.635 10 13.635H11C11.276 13.635 11.5 13.859 11.5 14.135C11.5 14.411 11.276 14.635 11 14.635Z"
        fill={color || "#000"}
      />
      <path
        d="M14 14.635H13C12.724 14.635 12.5 14.411 12.5 14.135C12.5 13.859 12.724 13.635 13 13.635H14C14.276 13.635 14.5 13.859 14.5 14.135C14.5 14.411 14.276 14.635 14 14.635Z"
        fill={color || "#000"}
      />
      <path
        d="M17 14.635H16C15.724 14.635 15.5 14.411 15.5 14.135C15.5 13.859 15.724 13.635 16 13.635H17C17.276 13.635 17.5 13.859 17.5 14.135C17.5 14.411 17.276 14.635 17 14.635Z"
        fill={color || "#000"}
      />
      <path
        d="M5 16.635H4C3.724 16.635 3.5 16.411 3.5 16.135C3.5 15.859 3.724 15.635 4 15.635H5C5.276 15.635 5.5 15.859 5.5 16.135C5.5 16.411 5.276 16.635 5 16.635Z"
        fill={color || "#000"}
      />
      <path
        d="M8 16.635H7C6.724 16.635 6.5 16.411 6.5 16.135C6.5 15.859 6.724 15.635 7 15.635H8C8.276 15.635 8.5 15.859 8.5 16.135C8.5 16.411 8.276 16.635 8 16.635Z"
        fill={color || "#000"}
      />
      <path
        d="M11 16.635H10C9.724 16.635 9.5 16.411 9.5 16.135C9.5 15.859 9.724 15.635 10 15.635H11C11.276 15.635 11.5 15.859 11.5 16.135C11.5 16.411 11.276 16.635 11 16.635Z"
        fill={color || "#000"}
      />
      <path
        d="M14 16.635H13C12.724 16.635 12.5 16.411 12.5 16.135C12.5 15.859 12.724 15.635 13 15.635H14C14.276 15.635 14.5 15.859 14.5 16.135C14.5 16.411 14.276 16.635 14 16.635Z"
        fill={color || "#000"}
      />
      <path
        d="M17 16.635H16C15.724 16.635 15.5 16.411 15.5 16.135C15.5 15.859 15.724 15.635 16 15.635H17C17.276 15.635 17.5 15.859 17.5 16.135C17.5 16.411 17.276 16.635 17 16.635Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

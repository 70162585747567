import {showWarning} from "./notifications.helper";
import {Upload} from "antd";

export const beforeUpload = (file) => {
  const isPdfOrPng = file.type === "image/png" || file.type === "application/pdf";
  !isPdfOrPng && showWarning("FILE_UPLOAD_WRONG_FORMAT_PDF_PNG");

  const isLt5M = file.size / 1024 / 1024 < 5;
  !isLt5M && showWarning("FILE_UPLOAD_TOO_LARGE_SIZE_5MB");

  return (isPdfOrPng && isLt5M) || Upload.LIST_IGNORE;
};

// size - file size in KB, minSize - file size in B
export const beforeUploadCustom = (file, formats, size, minSize = 0) => {
  const fileExtension = /\.\w+$/.exec(file.name)[0].slice(1).toUpperCase();
  const isSuitableType = formats.includes(fileExtension);
  !isSuitableType &&
    showWarning(
      `You can only upload files in the following formats: ${formats.join(", ")}`
    );

  const isLessThan = file.size / 1024 < size;
  !isLessThan && showWarning(`File must be smaller than ${size}KB!`);
  const isMoreThan = file.size > minSize;
  !isMoreThan && showWarning(`File must be bigger than ${minSize}B!`);

  return (isSuitableType && isLessThan && isMoreThan) || Upload.LIST_IGNORE;
};

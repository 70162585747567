import React, {memo} from "react";

export const IconAsterisk = memo(({color, addClass}) => {
  return (
    <svg
      className={addClass}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="9.5" y="2" width="1" height="16" rx="0.5" fill={color || "#A9A9A9"} />
      <rect
        x="2"
        y="10.5"
        width="1"
        height="16"
        rx="0.5"
        transform="rotate(-90 2 10.5)"
        fill={color || "#A9A9A9"}
      />
      <rect
        x="3.98962"
        y="4.69666"
        width="1"
        height="16"
        rx="0.5"
        transform="rotate(-45 3.98962 4.69666)"
        fill={color || "#A9A9A9"}
      />
      <rect
        x="4.69666"
        y="16.0104"
        width="1"
        height="16"
        rx="0.5"
        transform="rotate(-135 4.69666 16.0104)"
        fill={color || "#A9A9A9"}
      />
    </svg>
  );
});

import React from "react";

export const IconLinkWebforum = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 0H2C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0Z"
        fill="#C4262A"
      />
      <path
        d="M13.0142 16.9617C13.0075 16.9384 12.9843 16.7991 12.9611 16.6531C12.8217 15.6711 12.3506 14.918 11.6705 14.5862C11.1961 14.354 11.2492 14.3573 8.0874 14.3307C5.90435 14.3141 5.201 14.3009 5.0318 14.2644C4.60714 14.1781 4.29527 13.9558 4.11612 13.6108C4.03318 13.4515 4.02322 13.3951 4.01327 13.0202L4 12.6055L4.23224 11.6932C4.36163 11.1922 4.78961 9.49685 5.18442 7.92758C5.65221 6.05972 5.9309 5.01133 5.99061 4.8919C6.16977 4.53027 6.53803 4.23831 7.02573 4.07574C7.19825 4.01602 7.40063 4.01271 10.3866 4.00275C12.6326 3.99612 13.5616 4.00275 13.5616 4.0293C13.5616 4.08901 13.4289 4.32789 13.253 4.5933C12.7919 5.29002 12.2677 5.78104 11.7004 6.05972C11.2326 6.29196 11.206 6.29528 9.68984 6.31519C8.37935 6.33509 8.31963 6.33841 8.23337 6.40476C8.18361 6.44126 8.11394 6.50761 8.08408 6.55406C8.01772 6.64364 6.76032 11.5837 6.76032 11.7529C6.76032 11.8126 6.7935 11.8955 6.84658 11.9553L6.93284 12.0581H9.26849C11.3022 12.0581 11.6207 12.0515 11.7302 12.0083C11.8895 11.9387 11.9824 11.8524 12.0487 11.6998C12.0786 11.6334 12.5397 9.87507 13.0772 7.79488L14.0493 4.01271L14.9086 4.00275C15.8939 3.9928 16.0698 4.00607 16.2224 4.1056C16.4082 4.23167 16.4812 4.37434 16.4812 4.61653C16.4812 4.84877 16.4845 4.83218 15.2503 9.61961C14.0526 14.2577 13.8336 15.1004 13.5914 16.0526C13.4654 16.5469 13.3426 16.9583 13.3194 16.9749C13.253 17.0147 13.0308 17.0048 13.0142 16.9617Z"
        fill="white"
      />
    </svg>
  );
};

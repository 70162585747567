import React, {memo} from "react";

export const IconFeedback = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.103 0.895994C18.541 0.333994 17.794 0.0249939 17 0.0249939C16.206 0.0249939 15.458 0.333994 14.897 0.895994L5.147 10.646C5.095 10.698 5.056 10.76 5.031 10.829L3.031 16.329C2.965 16.512 3.01 16.716 3.147 16.853C3.242 16.949 3.37 17 3.501 17C3.558 17 3.616 16.99 3.672 16.97L9.172 14.97C9.241 14.945 9.303 14.905 9.355 14.854L19.105 5.10399C19.667 4.54199 19.976 3.79499 19.976 2.99999C19.976 2.20499 19.667 1.45799 19.105 0.895994H19.103ZM8.725 14.068L4.336 15.664L5.932 11.275L14 3.20699L16.793 5.99999L8.725 14.068ZM18.397 4.39599L17.5 5.29199L14.707 2.49899L15.604 1.60299C15.977 1.22999 16.473 1.02499 17 1.02499C17.527 1.02499 18.023 1.22999 18.397 1.60299C18.77 1.97599 18.975 2.47199 18.975 2.99899C18.975 3.52599 18.77 4.02199 18.397 4.39499V4.39599Z"
        fill={color || "#000"}
      />
      <path
        d="M17.5 20H1.5C0.673 20 0 19.327 0 18.5V2.5C0 1.673 0.673 1 1.5 1H11.5C11.776 1 12 1.224 12 1.5C12 1.776 11.776 2 11.5 2H1.5C1.224 2 1 2.224 1 2.5V18.5C1 18.776 1.224 19 1.5 19H17.5C17.776 19 18 18.776 18 18.5V8.5C18 8.224 18.224 8 18.5 8C18.776 8 19 8.224 19 8.5V18.5C19 19.327 18.327 20 17.5 20Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

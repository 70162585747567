import React, {memo} from "react";

export const IconLicense = memo(({color}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 18.78H1.5C0.673 18.78 0 18.107 0 17.28V4.28003C0 3.45303 0.673 2.78003 1.5 2.78003H18.5C19.327 2.78003 20 3.45303 20 4.28003V17.28C20 18.107 19.327 18.78 18.5 18.78ZM1.5 3.78003C1.224 3.78003 1 4.00403 1 4.28003V17.28C1 17.556 1.224 17.78 1.5 17.78H18.5C18.776 17.78 19 17.556 19 17.28V4.28003C19 4.00403 18.776 3.78003 18.5 3.78003H1.5Z"
        fill={color || "#000"}
      />
      <path
        d="M9.5 6.78003H3.5C3.224 6.78003 3 6.55603 3 6.28003C3 6.00403 3.224 5.78003 3.5 5.78003H9.5C9.776 5.78003 10 6.00403 10 6.28003C10 6.55603 9.776 6.78003 9.5 6.78003Z"
        fill={color || "#000"}
      />
      <path
        d="M9.5 9.78003H3.5C3.224 9.78003 3 9.55603 3 9.28003C3 9.00403 3.224 8.78003 3.5 8.78003H9.5C9.776 8.78003 10 9.00403 10 9.28003C10 9.55603 9.776 9.78003 9.5 9.78003Z"
        fill={color || "#000"}
      />
      <path
        d="M9.5 11.78H3.5C3.224 11.78 3 11.556 3 11.28C3 11.004 3.224 10.78 3.5 10.78H9.5C9.776 10.78 10 11.004 10 11.28C10 11.556 9.776 11.78 9.5 11.78Z"
        fill={color || "#000"}
      />
      <path
        d="M9.5 13.78H3.5C3.224 13.78 3 13.556 3 13.28C3 13.004 3.224 12.78 3.5 12.78H9.5C9.776 12.78 10 13.004 10 13.28C10 13.556 9.776 13.78 9.5 13.78Z"
        fill={color || "#000"}
      />
      <path
        d="M8.5 15.78H3.5C3.224 15.78 3 15.556 3 15.28C3 15.004 3.224 14.78 3.5 14.78H8.5C8.776 14.78 9 15.004 9 15.28C9 15.556 8.776 15.78 8.5 15.78Z"
        fill={color || "#000"}
      />
      <path
        d="M17.9431 7.32404L17.1301 6.73304L16.8191 5.77704H15.8141L15.0011 5.18604L14.1881 5.77704H13.1831L12.8721 6.73304L12.0591 7.32404L12.3701 8.28004L12.0591 9.23604L12.8721 9.82703L13.0041 10.233C13.0021 10.249 13.0021 10.264 13.0021 10.28V15.28C13.0021 15.482 13.1241 15.665 13.3111 15.742C13.4981 15.819 13.7131 15.777 13.8561 15.634L15.0021 14.488L16.1481 15.634C16.2441 15.73 16.3711 15.781 16.5021 15.78C16.5661 15.78 16.6311 15.768 16.6931 15.742C16.8801 15.665 17.0021 15.482 17.0021 15.28V10.28C17.0021 10.264 17.0011 10.248 17.0001 10.233L17.1321 9.82703L17.9451 9.23604L17.6341 8.28004L17.9451 7.32404H17.9431ZM13.2331 7.70604L13.7211 7.35104L13.9081 6.77704H14.5121L15.0001 6.42204L15.4881 6.77704H16.0921L16.2791 7.35104L16.7671 7.70604L16.5801 8.28004L16.7671 8.85404L16.2791 9.20903L16.0921 9.78304H15.4881L15.0001 10.138L14.5121 9.78304H13.9081L13.7211 9.20903L13.2331 8.85404L13.4201 8.28004L13.2331 7.70604ZM15.3541 13.426C15.1591 13.231 14.8421 13.231 14.6471 13.426L14.0011 14.072V10.782H14.1881L15.0011 11.373L15.8141 10.782H16.0011V14.072L15.3551 13.426H15.3541Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";
import {useTranslation} from "react-i18next";
//local
import {IconBag, IconFeather} from "components/icons";
import {RichField} from "components/RichField";
import {TitleIcon} from "components/TitleIcon";
import {color} from "constants/color.consts";

export const ContractViewInfo = memo(({price, additions, currencyCode}) => {
  const {t} = useTranslation();

  return (
    <div className="contract-tab__contract-view-block mb-8 mt-12">
      <TitleIcon title={t("PRICE")} icon={<IconBag />} />
      <div className="contract-tab__contract-view-block__price-value">
        {price || "0"}
        <div className="contract-tab__contract-view-block__price-denomination">
          {currencyCode}
        </div>
      </div>

      <TitleIcon
        title={t("ADDITIONS_ADJUSTMENTS")}
        addClass="mt-12"
        icon={<IconFeather color={color.black} />}
      />
      <RichField
        data={additions}
        placeholder="NO_ADDITIONS_PLACEHOLDER"
        viewMode
        importType="html"
      />
    </div>
  );
});

import React, {memo} from "react";

export const IconDotFilled = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="2" fill={color || "#000"} />
    </svg>
  );
});

import React, {memo} from "react";

export const IconDocumentList = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 20H3.5C2.673 20 2 19.327 2 18.5V2.5C2 1.673 2.673 1 3.5 1H16.5C17.327 1 18 1.673 18 2.5V18.5C18 19.327 17.327 20 16.5 20ZM3.5 2C3.224 2 3 2.224 3 2.5V18.5C3 18.776 3.224 19 3.5 19H16.5C16.776 19 17 18.776 17 18.5V2.5C17 2.224 16.776 2 16.5 2H3.5Z"
        fill={color || "#000"}
      />
      <path
        d="M12.5 5H5.5C5.224 5 5 4.776 5 4.5C5 4.224 5.224 4 5.5 4H12.5C12.776 4 13 4.224 13 4.5C13 4.776 12.776 5 12.5 5Z"
        fill={color || "#000"}
      />
      <path
        d="M14.5 7H5.5C5.224 7 5 6.776 5 6.5C5 6.224 5.224 6 5.5 6H14.5C14.776 6 15 6.224 15 6.5C15 6.776 14.776 7 14.5 7Z"
        fill={color || "#000"}
      />
      <path
        d="M14.5 9H5.5C5.224 9 5 8.776 5 8.5C5 8.224 5.224 8 5.5 8H14.5C14.776 8 15 8.224 15 8.5C15 8.776 14.776 9 14.5 9Z"
        fill={color || "#000"}
      />
      <path
        d="M10.5 11H5.5C5.224 11 5 10.776 5 10.5C5 10.224 5.224 10 5.5 10H10.5C10.776 10 11 10.224 11 10.5C11 10.776 10.776 11 10.5 11Z"
        fill={color || "#000"}
      />
      <path
        d="M14.5 15H5.5C5.224 15 5 14.776 5 14.5C5 14.224 5.224 14 5.5 14H14.5C14.776 14 15 14.224 15 14.5C15 14.776 14.776 15 14.5 15Z"
        fill={color || "#000"}
      />
      <path
        d="M12.5 17H5.5C5.224 17 5 16.776 5 16.5C5 16.224 5.224 16 5.5 16H12.5C12.776 16 13 16.224 13 16.5C13 16.776 12.776 17 12.5 17Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

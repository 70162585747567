import React, {memo} from "react";

export const IconTextSize = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.95988 16.303L5.67588 13.307C5.67488 13.306 5.67488 13.304 5.67388 13.303L3.95988 9.303C3.88088 9.119 3.69988 9 3.49988 9C3.29988 9 3.11888 9.119 3.03988 9.303L1.32588 13.303C1.32488 13.304 1.32488 13.306 1.32388 13.307L0.0398821 16.303C-0.0691179 16.557 0.0488821 16.851 0.302882 16.96C0.556882 17.069 0.850882 16.951 0.959882 16.697L2.11588 14H4.88488L6.04088 16.697C6.12188 16.887 6.30688 17 6.50088 17C6.56688 17 6.63388 16.987 6.69788 16.959C6.95188 16.85 7.06888 16.556 6.96088 16.302L6.95988 16.303ZM2.54388 13L3.49988 10.769L4.45588 13H2.54388Z"
        fill={color || "#6BC069"}
      />
      <path
        d="M19.9621 16.308L17.8791 11.308C17.8781 11.307 17.8781 11.305 17.8771 11.304L14.9621 4.308C14.8841 4.122 14.7021 4 14.5001 4C14.2981 4 14.1161 4.121 14.0381 4.308L11.1231 11.304C11.1221 11.305 11.1221 11.307 11.1211 11.308L9.03806 16.308C8.93206 16.563 9.05206 16.856 9.30706 16.962C9.56207 17.068 9.85507 16.948 9.96107 16.693L11.9161 12.001H17.0831L19.0381 16.693C19.1181 16.885 19.3041 17.001 19.5001 17.001C19.5641 17.001 19.6291 16.989 19.6921 16.962C19.9471 16.856 20.0671 16.563 19.9611 16.308H19.9621ZM12.3331 11L14.5001 5.8L16.6671 11H12.3341H12.3331Z"
        fill={color || "#6BC069"}
      />
    </svg>
  );
});

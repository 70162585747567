import React, {memo} from "react";

export const IconFolder = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 12 12"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5714 5.08929H10.125V3.56786C10.125 3.33081 9.93348 3.13929 9.69643 3.13929H5.47768L3.9067 1.63661C3.88671 1.6179 3.86042 1.60738 3.83304 1.60715H0.428571C0.191518 1.60715 0 1.79867 0 2.03572V9.96429C0 10.2013 0.191518 10.3929 0.428571 10.3929H9.77679C9.95089 10.3929 10.1089 10.2871 10.1746 10.125L11.9692 5.67858C11.9893 5.62768 12 5.57277 12 5.51786C12 5.28081 11.8085 5.08929 11.5714 5.08929ZM0.964286 2.57143H3.48884L5.09063 4.10358H9.16071V5.08929H2.33036C2.15625 5.08929 1.99821 5.19509 1.93259 5.35715L0.964286 7.75715V2.57143ZM9.47277 9.42858H1.27232L2.6558 6H10.8576L9.47277 9.42858Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {useEffect, useState, useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Form, Formik} from "formik";
import {isEqual} from "lodash";
import {useNavigate} from "react-router-dom";
// local
import {Modal} from "components/Modal";
import {showSuccess, showError} from "helpers/notifications.helper";
import {getFullName} from "helpers/helper";
import {emptyProject, noOffice} from "constants/buyer.consts";
import {IconArchive} from "components/icons/index";
import {ProjectLabel} from "components/ProjectLabel";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import MultipleSelect from "components/selects/MultipleSelect";
import SimpleField from "components/SimpleField";
import FormikField from "components/FormikField";
import EditSelect from "components/selects/EditSelect";
import {
  schema,
  schemaWDepartment,
} from "pages/OurProjects/components/ProjectEdit/formAttrs";
import {DropdownEllipsis} from "components/DropdownEllipsis";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {availableInArray} from "constants/select.consts";
import {Preloader} from "components/Preloader";

export const ProjectModal = inject("store")(
  observer(({store: {auth, buyer, organization, clientStore}, projectId, onClose}) => {
    const {user} = auth;
    const {organizationMembersSelectList, projectDropdownDepartments} = organization;
    const {language} = clientStore;
    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [daluxProjectList, setDaluxProjectList] = useState(null);
    const [loadingSave, setLoadingSave] = useState(false);
    const {t} = useTranslation();

    const isNewProject = projectId === "new-project";
    const activeOrganization = user.ActiveOrganization;
    const isShowDepartments = activeOrganization?.useDepartmentsForProjects;
    const isShowDaluxPossibility =
      activeOrganization?.allowDalux && activeOrganization?.enableDaluxIntegration;
    const isGetDaluxProjectList =
      !!activeOrganization?.daluxApiKey && !!activeOrganization?.daluxBaseURL;

    const activeMenu = [
      {
        label: "ARCHIVE_PROJECT",
        onClick: () => changeProjectStatus(true),
        icon: <IconArchive />,
      },
    ];

    const archivedMenu = [
      {
        label: "ACTIVATE_PROJECT",
        onClick: () => changeProjectStatus(false),
        icon: <IconArchive />,
      },
    ];

    const title = (
      <React.Fragment>
        <ProjectLabel
          name={project?.name}
          code={project?.reference}
          isEditing={true}
          wideName
          width="400"
        />
        {!!project?.id && (
          <DropdownEllipsis
            list={project.isArchived ? archivedMenu : activeMenu}
            loading={loadingSave}
            addClass="ml-2"
          />
        )}
      </React.Fragment>
    );

    const currentUser = useMemo(
      () =>
        isNewProject
          ? organizationMembersSelectList.find((item) => item?.value === user.id)
          : undefined,
      [organizationMembersSelectList, isNewProject, user.id]
    );

    const isProjectData = useMemo(
      () =>
        !loading &&
        (isNewProject ? !!project?.Members?.length : !!project) &&
        (isShowDaluxPossibility ? !!daluxProjectList : true),
      [isNewProject, loading, project, daluxProjectList, isShowDaluxPossibility]
    );

    const getProject = useCallback(() => {
      setLoading(true);
      if (isNewProject) {
        setProject({
          ...emptyProject,
        });
        setLoading(false);
      } else
        buyer
          .getProject(projectId, true)
          .then((data) =>
            setProject({...data, DepartmentId: data.DepartmentId ?? noOffice})
          )
          .finally(() => setLoading(false));
    }, [isNewProject, buyer, projectId]);

    const changeProjectStatus = async (status) => {
      setLoadingSave(true);
      await buyer.updateProject({id: project.id, isArchived: status});
      showSuccess(t("SHOW_UPDATED_STATUS"));
      setLoadingSave(false);
      onClose(project.Members.map((a) => a.value).includes(user.id) ? 0 : 1);
    };

    const onSubmit = async (values) => {
      setLoadingSave(true);
      const isChanged = !isEqual(project, values);
      const Members = values.Members.map((a) => a.value);
      const payload = {
        ...values,
        DepartmentId: values.DepartmentId === noOffice ? null : values.DepartmentId,
        Members,
      };

      try {
        if (isNewProject) {
          await buyer
            .createProject(payload)
            .then((data) => navigate(`${data.id}`, {state: {isNewProject: true}}));
        } else {
          isChanged &&
            (await buyer.updateProject({
              ...payload,
              id: project.id,
            }));
        }
        isChanged &&
          showSuccess(t(`SUCCESSFULLY_${isNewProject ? "CREATED" : "UPDATED"}`));
        onClose(isNewProject ? (Members.includes(user.id) ? 0 : 1) : isChanged);
      } catch (e) {
        showError(
          e.response.data.error,
          undefined,
          undefined,
          undefined,
          e.response.data.users
            .map((item) => getFullName(item.firstName, item.lastName))
            .join(", ")
        );
      } finally {
        setLoadingSave(false);
      }
    };

    const handleChange = (e) => {
      setProject({
        ...project,
        name: e.target?.form[0]?.value ?? project.name,
        reference: e.target?.form[1]?.value ?? project.reference,
        locationLat: e?.coordinates?.lat,
        locationLng: e?.coordinates?.lng,
      });
    };

    useEffect(() => {
      organization.getOrganizationDepartmentLabels();
    }, [organization]);

    useEffect(() => {
      isShowDaluxPossibility &&
        isGetDaluxProjectList &&
        buyer
          .getDaluxProjects()
          .then((data) =>
            setDaluxProjectList(
              data
                .filter((item) => item.available)
                .map((item) => ({label: item.projectName, value: item.projectId}))
            )
          );
    }, [buyer, isShowDaluxPossibility, isGetDaluxProjectList]);

    useEffect(() => {
      getProject();
      return () => setProject(null);
    }, [getProject]);

    useEffect(() => {
      !!currentUser &&
        !project?.Members?.length &&
        setProject({
          ...project,
          Members: [currentUser],
        });
    }, [currentUser, project, user.id]);

    return (
      <Modal
        customTitle={title}
        width={520}
        percentageWide="40%"
        rounded
        placeBitLow
        noCenterContent
        style={{marginTop: "15vh"}}
        onClose={onClose}
        addClass="project-create-edit-modal"
      >
        <div className="project-create-edit-modal__content">
          {!isProjectData && <Preloader addClass="preloader250" />}
          {isProjectData && (
            <Formik
              initialValues={project}
              validationSchema={isShowDepartments ? schemaWDepartment : schema}
              onSubmit={onSubmit}
            >
              {({values, setFieldValue, isValid}) => (
                <Form onChange={handleChange}>
                  <div className="project-edit-block__fields">
                    <div className="project-edit-block__row-fields">
                      <SimpleField
                        name="name"
                        addClass="left-col"
                        placeholder={t("NAME_PROJECT_PLACEHOLDER")}
                        label={t("NAME")}
                        highlightIfEmpty={!values.name}
                        errorName="name"
                        className="input"
                      />
                      <SimpleField
                        name="reference"
                        addClass="right-col"
                        placeholder={t("REFERENCE_PLACEHOLDER")}
                        label={t("REFERENCE_LABEL")}
                        className="input"
                      />
                    </div>
                    <div
                      className={`project-edit-block__row-fields${
                        isShowDepartments ? "" : " re-order-fields"
                      }`}
                    >
                      {isShowDepartments && (
                        <EditSelect
                          name="DepartmentId"
                          list={projectDropdownDepartments}
                          defaultValue={project.DepartmentId}
                          placeholder={t("SELECT_DEPARTMENT_LABEL_PLACEHOLDER")}
                          highlightIfEmpty={!values.DepartmentId}
                          showPlaceholderArrow
                          addClass="left-col department-select"
                          label={t("DEPARTMENT_LABEL_PROJECT")}
                        />
                      )}
                      <FormikField
                        name="location"
                        label={t("LOCATION")}
                        placeholder={t("ADDRESS_PLACEHOLDER")}
                        errorName="location"
                        small
                        defaultValue={project.location}
                        withGoogleMapsAutocomplete
                        withGoogleMapsModal
                        language={language}
                        initialLocation={
                          project.locationLat &&
                          project.locationLng && {
                            lat: +project.locationLat ?? null,
                            lng: +project.locationLng ?? null,
                          }
                        }
                        onChangeCoordinates={({lat, lng, location}) => {
                          setFieldValue("location", location);
                          setFieldValue("locationLat", lat);
                          setFieldValue("locationLng", lng);
                          handleChange({coordinates: {lat, lng, location}});
                        }}
                        addClass="right-col mb-0"
                        className="input"
                      />
                      <SimpleField
                        name="buyer"
                        addClass={`left-col${isShowDepartments ? " mt-4" : ""}`}
                        placeholder={t("BUYER_PLACEHOLDER")}
                        label={t("BUYER_PROJECT")}
                        className="input"
                      />
                      {isShowDaluxPossibility && (
                        <div
                          className={`${
                            isShowDepartments ? "right-col" : "left-col"
                          } dalux-wrapper`}
                        >
                          <CustomRadioSwitch
                            label={t("PROJECT_DALUX_SWITCH_LABEL")}
                            checked={values?.allowDalux}
                            mediumText
                            small
                            addWrapperClass="mb-1"
                            isActive={(checked) => setFieldValue("allowDalux", checked)}
                          />
                          <EditSelect
                            name="daluxProjectId"
                            list={daluxProjectList}
                            defaultValue={project.daluxProjectId}
                            placeholder={t("PROJECT_DALUX_SELECT_PLACEHOLDER")}
                            showPlaceholderArrow
                            highlightIfEmpty={
                              !!values?.allowDalux && !values.daluxProjectId
                            }
                            addClass="department-select"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <MultipleSelect
                    value={values.availableIn}
                    list={availableInArray}
                    small
                    color="green"
                    highlightIfEmpty={!values?.availableIn?.length}
                    onChange={(value) => setFieldValue("availableIn", value)}
                    title="AVAILABLEIN_PROJECT"
                  />
                  <MultipleSelect
                    name="Members"
                    value={values.Members.filter((member) =>
                      organizationMembersSelectList
                        .map((user) => user.value)
                        .includes(member.value)
                    )}
                    list={organizationMembersSelectList}
                    small
                    realLabels
                    onChange={(value) => setFieldValue("Members", value)}
                    title="TEAM"
                  />
                  <CustomRadioSwitch
                    label={t("PROJECT_SECRET_LABEL")}
                    mediumText
                    addWrapperClass="mb-2"
                    checked={values.isPrivate}
                    isActive={(checked) => setFieldValue("isPrivate", checked)}
                  />
                  <div className="df-row-jce-center ">
                    <ButtonTransparent
                      name="CANCEL"
                      color="red"
                      onClick={onClose}
                      addClass="mr-4"
                    />
                    <ButtonTransparent
                      type="submit"
                      loading={loadingSave}
                      disabled={!values.availableIn?.length || !isValid}
                      name="SAVE"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </Modal>
    );
  })
);

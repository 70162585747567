import React, {memo} from "react";

export const IconUserPlus = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass ?? ""}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 11C6.467 11 4 8.533 4 5.5C4 2.467 6.467 0 9.5 0C12.533 0 15 2.467 15 5.5C15 8.533 12.533 11 9.5 11ZM9.5 1C7.019 1 5 3.019 5 5.5C5 7.981 7.019 10 9.5 10C11.981 10 14 7.981 14 5.5C14 3.019 11.981 1 9.5 1Z"
        fill={color || "#000"}
      />
      <path
        d="M11.5 20H1.5C0.673 20 0 19.327 0 18.5C0 18.432 0.0140001 16.815 1.225 15.2C1.93 14.26 2.895 13.513 4.094 12.981C5.558 12.33 7.377 12 9.5 12C9.851 12 10.198 12.011 10.531 12.031C10.807 12.048 11.016 12.286 10.999 12.561C10.982 12.836 10.744 13.047 10.469 13.029C10.156 13.01 9.83 13 9.5 13C6.013 13 3.44 13.953 2.059 15.756C1.024 17.107 1.001 18.488 1 18.502C1 18.776 1.224 19 1.5 19H11.5C11.776 19 12 19.224 12 19.5C12 19.776 11.776 20 11.5 20Z"
        fill={color || "#000"}
      />
      <path
        d="M15.5 20C13.019 20 11 17.981 11 15.5C11 13.019 13.019 11 15.5 11C17.981 11 20 13.019 20 15.5C20 17.981 17.981 20 15.5 20ZM15.5 12C13.57 12 12 13.57 12 15.5C12 17.43 13.57 19 15.5 19C17.43 19 19 17.43 19 15.5C19 13.57 17.43 12 15.5 12Z"
        fill={color || "#000"}
      />
      <path
        d="M17.5 15H16V13.5C16 13.224 15.776 13 15.5 13C15.224 13 15 13.224 15 13.5V15H13.5C13.224 15 13 15.224 13 15.5C13 15.776 13.224 16 13.5 16H15V17.5C15 17.776 15.224 18 15.5 18C15.776 18 16 17.776 16 17.5V16H17.5C17.776 16 18 15.776 18 15.5C18 15.224 17.776 15 17.5 15Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconSkullEmpty = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={height || "40"}
      height={width || "40"}
      viewBox="0 0 40 40"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29 40C28.448 40 28 39.552 28 39V33C28 30.352 29.618 27.74 31.684 27.052C32.918 26.64 34 24.748 34 23V19C34 10.728 27.272 4 19 4C10.728 4 4 10.728 4 19V23C4 24.746 5.082 26.64 6.316 27.052C8.382 27.74 10 30.354 10 33V39C10 39.552 9.552 40 9 40C8.448 40 8 39.552 8 39V33C8 31.254 6.918 29.36 5.684 28.948C3.618 28.26 2 25.646 2 23V19C2 14.46 3.768 10.19 6.98 6.98C10.192 3.77 14.46 2 19 2C23.54 2 27.81 3.768 31.02 6.98C34.23 10.192 36 14.46 36 19V23C36 25.648 34.382 28.26 32.316 28.948C31.082 29.36 30 31.252 30 33V39C30 39.552 29.552 40 29 40Z"
        fill={color || "#000"}
      />
      <path
        d="M13 40C12.448 40 12 39.552 12 39V35C12 34.448 12.448 34 13 34C13.552 34 14 34.448 14 35V39C14 39.552 13.552 40 13 40Z"
        fill={color || "#000"}
      />
      <path
        d="M17 40C16.448 40 16 39.552 16 39V35C16 34.448 16.448 34 17 34C17.552 34 18 34.448 18 35V39C18 39.552 17.552 40 17 40Z"
        fill={color || "#000"}
      />
      <path
        d="M21 40C20.448 40 20 39.552 20 39V35C20 34.448 20.448 34 21 34C21.552 34 22 34.448 22 35V39C22 39.552 21.552 40 21 40Z"
        fill={color || "#000"}
      />
      <path
        d="M25 40C24.448 40 24 39.552 24 39V35C24 34.448 24.448 34 25 34C25.552 34 26 34.448 26 35V39C26 39.552 25.552 40 25 40Z"
        fill={color || "#000"}
      />
      <path
        d="M25 26C22.244 26 20 23.756 20 21C20 18.244 22.244 16 25 16C27.756 16 30 18.244 30 21C30 23.756 27.756 26 25 26ZM25 18C23.346 18 22 19.346 22 21C22 22.654 23.346 24 25 24C26.654 24 28 22.654 28 21C28 19.346 26.654 18 25 18Z"
        fill={color || "#000"}
      />
      <path
        d="M13 26C10.244 26 8 23.756 8 21C8 18.244 10.244 16 13 16C15.756 16 18 18.244 18 21C18 23.756 15.756 26 13 26ZM13 18C11.346 18 10 19.346 10 21C10 22.654 11.346 24 13 24C14.654 24 16 22.654 16 21C16 19.346 14.654 18 13 18Z"
        fill={color || "#000"}
      />
      <path
        d="M20.8762 31.572C20.6602 31.572 20.4282 31.53 20.1842 31.45C19.8882 31.352 19.4582 31.296 19.0002 31.296C18.5422 31.296 18.1122 31.352 17.8162 31.45C17.5722 31.532 17.3402 31.572 17.1242 31.572C16.5882 31.572 16.1402 31.324 15.8962 30.89C15.6762 30.5 15.5442 29.818 16.1722 28.772L17.1142 27.202C17.5682 26.444 18.2562 26.01 19.0002 26.01C19.7442 26.01 20.4322 26.444 20.8862 27.202L21.8282 28.772C22.4562 29.818 22.3242 30.502 22.1042 30.89C21.8582 31.324 21.4102 31.572 20.8762 31.572ZM19.0002 29.294C19.2882 29.294 19.5702 29.31 19.8402 29.342L19.1722 28.228C19.0982 28.104 19.0342 28.046 19.0002 28.02C18.9662 28.046 18.9022 28.104 18.8282 28.228L18.1602 29.342C18.4282 29.31 18.7122 29.294 19.0002 29.294Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

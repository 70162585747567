import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import {OrganizationCard} from "components/organizationCard/OrganizationCard";
import {Preloader} from "components/Preloader";
import OrganizationCardHead from "components/organizationCard/OrganizationCardHead";
import {QualificationAnswersModal} from "components/modals/QualificationAnswersModal";
import {RemoveQualificationAnswersModal} from "components/modals/RemoveQualificationAnswersModal";
import EmptyData from "components/EmptyData";
import {hasUserPermission} from "helpers/helper";
import {roles} from "constants/auth.const";
import {PaginationType} from "components/Pagination";

const QualifyingSection = inject("store")(
  observer(({store: {auth, organization, clientStore}, onChangeSort}) => {
    const {
      suppliers: {totalItems, currentPage, searchPreferences},
      loading,
    } = organization;
    const {clientRole} = auth;

    const [qualification, setQualification] = useState(null);
    const [removeQualificationAnswersIdModal, setRemoveQualificationAnswersIdModal] =
      useState(null);

    const isOwner = hasUserPermission([roles.OWNER], clientRole);

    const handleShowQualificationAnswersModal = (id) => {
      clientStore.getQualificationAnswers(id, "buyer").then(({data}) =>
        setQualification({
          ...data,
          questions: data.questions.map((question) => ({
            ...question,
            files: question.files?.map((file) => ({...file, ...file.File})),
          })),
          answers: data.answers
            ? data.answers.map((answer) => ({
                ...answer,
                files: answer.files?.map((file) => ({...file, ...file.File})),
              }))
            : [],
        })
      );
    };

    const handleRemoveQualificationAnswers = (id, rejectMessage) =>
      clientStore.removeQualificationAnswers(id, {rejectMessage}).then(() => {
        setRemoveQualificationAnswersIdModal(null);
        setQualification(null);
        organization.searchSuppliers();
      });

    const handleConfirmQualificationAnswers = (id) =>
      clientStore.confirmQualificationAnswers(id).then(() => {
        setQualification(null);
        organization.searchSuppliers();
      });

    return (
      <div className="our-suppliers__organizations-table">
        <OrganizationCardHead place="qualifying" onChangeSort={onChangeSort} />
        {loading && <Preloader addClass="preloader100" />}
        {!loading && (
          <React.Fragment>
            {!!searchPreferences?.length ? (
              searchPreferences.map((s) => {
                if (s.Organization) {
                  return (
                    <OrganizationCard
                      key={s.Organization.id}
                      organization={s.Organization}
                      place="qualifying"
                      removeQualificationAnswers={(id, needMessage) =>
                        needMessage
                          ? setRemoveQualificationAnswersIdModal(id)
                          : handleRemoveQualificationAnswers(id)
                      }
                      confirmQualificationAnswers={handleConfirmQualificationAnswers}
                      showQualificationAnswers={handleShowQualificationAnswersModal}
                    />
                  );
                }
                return null;
              })
            ) : (
              <EmptyData />
            )}
          </React.Fragment>
        )}

        {totalItems > 20 && (
          <PaginationType
            totalItems={totalItems}
            currentPage={currentPage}
            countPerPage={20}
            onPageChange={(pageNumber) => organization.searchSuppliers(pageNumber)}
            addClass="mt-8"
          />
        )}

        {!!removeQualificationAnswersIdModal && (
          <RemoveQualificationAnswersModal
            onConfirm={(message) =>
              handleRemoveQualificationAnswers(removeQualificationAnswersIdModal, message)
            }
            onClose={() => setRemoveQualificationAnswersIdModal(null)}
          />
        )}

        {qualification && (
          <QualificationAnswersModal
            initialQualification={qualification}
            isAdminOwner={isOwner}
            buyerSide
            removeQualificationAnswers={setRemoveQualificationAnswersIdModal}
            confirmQualificationAnswers={handleConfirmQualificationAnswers}
            onClose={({needUpdateAnswers}) => setQualification(null)}
          />
        )}
      </div>
    );
  })
);

export default QualifyingSection;

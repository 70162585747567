import React from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//locale
import {RichField} from "components/RichField";
import SmartFormQuestions from "pages/OurRequests/components/SmartForm/SmartFormQuestions";
import {TitleIcon} from "components/TitleIcon";
import {IconDocumentList, IconLock} from "components/icons";
import RequestDocuments from "components/requestCard/RequestDocuments";

const RequestSection = inject("store")(
  observer(({store: {auth, seller}}) => {
    const {request} = seller;
    const {user} = auth;
    const {t} = useTranslation();
    const questions = request?.Request?.smartFormQuestions;
    const isNeedShowQuestions = !!questions?.filter((item) => item.type !== "heading")
      .length;
    const hasThirdPartyContract =
      !!request?.Request?.hasContract && !request.Offer?.Contract?.id;

    return (
      <div className="request-to-us_single __wrapper">
        <div className="current-request_text __wrapper">
          <TitleIcon
            title={t("DESCRIPTION")}
            addClass="ml-4 mt-4"
            icon={<IconDocumentList />}
          />
          <RichField data={request?.Request?.description} viewMode importType="html" />
        </div>
        {hasThirdPartyContract ? (
          <div className="mb-8 df-row-center __top-border mt-8 pt-8">
            <IconLock addClass="mr-2" />
            {t("RFP_HIDE_DOCUMENTS_IF_HAS_CONTRACT")}
          </div>
        ) : (
          <RequestDocuments
            revisions={request?.Request?.documents}
            editable={false}
            columnTitles={{
              contracts: "CONTRACTDRAFT",
              documents: "DOCUMENTS",
              plans: "PLANS",
              links: "LINKS",
            }}
            showTabs
            needZipButton
            standardAgreement={request?.Request?.standardAgreement}
          />
        )}
        {isNeedShowQuestions && (
          <SmartFormQuestions
            questions={questions ?? []}
            permissions={user.permissions}
            topBorder
            addClass="mt-2"
          />
        )}
      </div>
    );
  })
);

export default RequestSection;

import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
//local
import PresentationCardWrapper from "../../PresentationCardWrapper";
import {IconUsers} from "components/icons";
import {LockValue} from "components/LockValue";

const Employees = ({organization}) => {
  const {t} = useTranslation();

  // Get the most recent profit/loss sheet dates
  const profitLossDates = useMemo(() => {
    const creditReport = organization?.Company?.companyCreditReport;
    if (!!creditReport?.accountProfitLossSheet?.length) {
      const sortedSheet = creditReport.accountProfitLossSheet?.sort(
        (a, b) => new Date(b?.dateFrom) - new Date(a?.dateFrom)
      );
      const mostRecent = sortedSheet[0];
      return {
        dateFrom: mostRecent?.dateFrom,
        dateTo: mostRecent?.dateTo,
      };
    }
    return null;
  }, [organization?.Company?.companyCreditReport]);

  return (
    <PresentationCardWrapper icon={<IconUsers />} title={t("OVERVIEW_TAB_EMPLOYEES")}>
      <LockValue
        canPermissions={["READ_OTHER_EMPLOYEES"]}
        noHideOurInfo
        fullSubcribeButton
        needMiniSibscribeButton
      >
        <span className="mr-3 fz-12 discrete-text">
          {t("ANNUAL_REPORT_NUMBER_EMPLOYEES")}
        </span>
        <span>{organization?.Company?.basic?.nrEmployees ?? "-"}</span>
        <div className="mt-2 fz-12 mid-gray ws-br-spaces">
          {t("ANNUAL_REPORT_NUMBER_EMPLOYEES_DESCRIPTION")}{" "}
          {!!profitLossDates &&
            `${t("ANNUAL_REPORT_NUMBER_EMPLOYEES_PERIOD_PREFIX")} ${
              profitLossDates.dateFrom
            } - ${profitLossDates.dateTo}`}
        </div>
      </LockValue>
    </PresentationCardWrapper>
  );
};

export default Employees;

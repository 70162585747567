import React, {memo} from "react";
import cn from "classnames";
import {IconCrossGray} from "components/icons";

const ButtonCross = memo(({addClass, onClick}) => {
  return (
    <button
      className={cn("btn-custom__cross", {[addClass]: !!addClass})}
      onClick={onClick}
      type="button"
    >
      <IconCrossGray />
    </button>
  );
});

export default ButtonCross;

export const ratingTypes = {
  economy: "1",
  delivery: "2",
  handling: "3",
  service: "4",
  administrations: "5",
  exit: "6",
  aftermarket: "7",
  environment: "8",
  custom: "custom",
};

const initialEnvironment = {
  id: "",
  type: ratingTypes.environment,
  name: "ENVIRONMENT",
  description: "ENVIRONMENT_QUESTION",
  score: "",
  RatedOrganizationId: "",
  OrganizationId: "",
};

const initialEconomy = {
  id: "",
  type: ratingTypes.economy,
  name: "ECONOMY",
  description: "ECONOMY_QUESTION",
  score: "",
  RatedOrganizationId: "",
  OrganizationId: "",
};

const initialDeliveryReliability = {
  id: "",
  type: ratingTypes.delivery,
  name: "DELIVERY_ON_RELIABILITY",
  description: "DELIVERY_ON_RELIABILITY_QUESTION",
  score: "",
  RatedOrganizationId: "",
  OrganizationId: "",
};

const initialHandlingOfDisordersAndDeviations = {
  id: "",
  type: ratingTypes.handling,
  name: "HANDLING_OF_DISORDERS_DEVIATIONS",
  description: "HANDLING_OF_DISORDERS_DEVIATIONS_QUESTION",
  score: "",
  RatedOrganizationId: "",
  OrganizationId: "",
};

const initialServiceAndCooperation = {
  id: "",
  type: ratingTypes.service,
  name: "SERVICE_&_COOPERATION",
  description: "SERVICE_&_COOPERATION_QUESTION",
  score: "",
  RatedOrganizationId: "",
  OrganizationId: "",
};

const initialAdministration = {
  id: "",
  type: ratingTypes.administrations,
  name: "ADMINISTRATION",
  description: "ADMINISTRATION_QUESTION",
  score: "",
  RatedOrganizationId: "",
  OrganizationId: "",
};

const initialExit = {
  id: "",
  type: ratingTypes.exit,
  name: "EXIT",
  description: "EXIT_QUESTION",
  score: "",
  RatedOrganizationId: "",
  OrganizationId: "",
};

const initialAftermarket = {
  id: "",
  type: ratingTypes.aftermarket,
  name: "AFTERMARKET",
  description: "AFTERMARKET_QUESTION",
  score: "",
  RatedOrganizationId: "",
  OrganizationId: "",
};

export const ratingsList = [
  {
    type: ratingTypes.environment,
    initial: initialEnvironment,
  },
  {
    type: ratingTypes.economy,
    initial: initialEconomy,
  },
  {
    type: ratingTypes.delivery,
    initial: initialDeliveryReliability,
  },
  {
    type: ratingTypes.handling,
    initial: initialHandlingOfDisordersAndDeviations,
  },
  {
    type: ratingTypes.service,
    initial: initialServiceAndCooperation,
  },
  {
    type: ratingTypes.administrations,
    initial: initialAdministration,
  },
  {
    type: ratingTypes.exit,
    initial: initialExit,
  },
  {
    type: ratingTypes.aftermarket,
    initial: initialAftermarket,
  },
];

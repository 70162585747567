import React, {useMemo, useState, useCallback} from "react";
//local
import {OrganizationStats} from "components/organizationCard/OrganizationStats";
import {ORGANIZATIONS} from "constants/routes.const";
import {
  IconSkullEmpty,
  IconPlus,
  IconDelete,
  IconHandOnRight,
  IconUE2021,
  IconRamavtal,
  IconDotFilled,
  IconLockFilled,
} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import CreditRatingScore from "components/CreditRatingScore";
import CustomRatingStars from "components/CustomRatingStars";
import {LockValue} from "components/LockValue";
import {color} from "constants/color.consts";
import {ourAgreementsTypes} from "constants/user.consts";
import {toThousands} from "helpers/number.formats";
import {hasNoLegacySupplierQualification, hasPermission} from "helpers/helper";
import cn from "classnames";

const OK_ICON = 1;
const X_ICON = 2;

export const OrganizationCard = ({
  organization,
  onActionClick,
  showTitleIcon,
  isAlreadyInRecipients,
  cantBeRemoved,
  favorites,
  blacklisted,
  hasSuppliersQualifications,
  userPermissions,
  isEnableSuppliersQualification,
}) => {
  const [isDisabledActionButton, setDisableActionButton] = useState(false);
  const isFavorite = useMemo(
    () => !!favorites?.length && favorites.some((x) => x.id === organization.id),
    [favorites, organization.id]
  );
  const isBlackList = useMemo(
    () => !!blacklisted?.length && blacklisted.some((x) => x.id === organization.id),
    [blacklisted, organization.id]
  );
  const isUE2021 = !!organization.OurAgreements?.find(
    (item) => item?.type === ourAgreementsTypes.UE2021
  );
  const isRamavtal = !!organization.OurAgreements?.find(
    (item) => item?.type === ourAgreementsTypes.RAMAVTAL
  );

  const hasUnqualifiedFavoriteButton = useCallback(
    () =>
      hasNoLegacySupplierQualification(
        showTitleIcon,
        isFavorite,
        hasSuppliersQualifications,
        isEnableSuppliersQualification,
        true
      ),
    [
      showTitleIcon,
      isFavorite,
      hasSuppliersQualifications,
      isEnableSuppliersQualification,
    ]
  );

  const company = organization?.Company;
  const companyTurnover = useMemo(
    () =>
      company?.turnover
        ? toThousands(company?.turnover) < 1
          ? "<1"
          : toThousands(company?.turnover)
        : "-",
    [company?.turnover]
  );
  const unionAgreementIcon =
    organization.UnionAgreements?.length > 0 ||
    company?.CompanyUnionAgreements?.length > 0
      ? OK_ICON
      : X_ICON;
  const insuranceIcon = organization.Insurances?.length > 0 ? OK_ICON : X_ICON;
  const taxVatIcon =
    company?.swedish_f_tax &&
    company?.swedish_vat_registration &&
    company?.employersRegistration
      ? OK_ICON
      : X_ICON;
  const stats = organization.OrganizationStat;

  const handleAddRemoveRecipients = () => {
    if (!isAlreadyInRecipients) {
      setDisableActionButton(true);
      onActionClick({
        id: organization.id,
        name: organization.name,
        membersCount: organization.membersCount,
        membersCountToReceive: organization.membersCountToReceive,
      }).finally(() => setDisableActionButton(false));
    } else
      onActionClick({
        id: organization.id,
        name: organization.name,
        membersCount: organization.membersCount,
        membersCountToReceive: organization.membersCountToReceive,
      });
  };

  const handleStopClick = (e) => e.preventDefault();

  return (
    <div className="organization-card_body">
      <div className="organization-card_body__name __wrapper">
        <a
          href={`${window.location.origin}${ORGANIZATIONS}/${organization.id}`}
          target="_blank"
          className="organization-card_body__name __title mr-0 link"
          rel="noopener noreferrer"
        >
          {isAlreadyInRecipients && (
            <IconHandOnRight addClass="mr-1" color={color.green} />
          )}
          <div
            className={cn("", {
              "__green-badge": isAlreadyInRecipients,
              __strikethrough: isBlackList,
            })}
          >
            {organization.name}
          </div>
        </a>
        <div style={{height: "20px"}}>
          {hasUnqualifiedFavoriteButton()}
          {showTitleIcon && isBlackList && (
            <IconSkullEmpty height="20" width="20" addClass="ml-2" />
          )}
          {isUE2021 && <IconUE2021 addClass="ml-2" />}
          {isRamavtal && <IconRamavtal addClass="ml-2" />}
        </div>
        {!!stats && (
          <OrganizationStats
            projectsCount={stats.projectsCount}
            rfpsCount={stats.rfpsCount}
            receivedOffersCount={stats.receivedOffersCount}
            contractsCount={stats.contractsCount}
            addClass="mla"
          />
        )}
      </div>
      <div className="organization-card_body__indicator __wrapper">
        <div className="organization-card_body__indicator __rating">
          <CustomRatingStars value={organization.overallScore ?? 0} isDisabled />
        </div>

        <div className="organization-card_body__indicator __loading turnover">
          <LockValue
            canPermissions={["READ_TABLE_TURNOVER"]}
            addClassIcon="mr-4"
            customLockIcon={<IconDotFilled color={color.purple} />}
          >
            {companyTurnover}
          </LockValue>
        </div>
        <div className="organization-card_body__indicator __status">
          <LockValue
            canPermissions={["READ_TABLE_CREDIT_RATING"]}
            customLockIcon={<IconDotFilled color={color.purple} />}
          >
            <CreditRatingScore
              creditRating={company?.creditRating}
              noCreditRatingSymbol="?"
              smallSize
            />
          </LockValue>
        </div>
        <div className="organization-card_body__indicator __sub-status">
          <LockValue
            canPermissions={["READ_TABLE_COLLECTIVE_UNION_AGREEMENTS"]}
            customLockIcon={<IconDotFilled color={color.purple} />}
          >
            <span className={`indicator_sub-status __${unionAgreementIcon}`} />
          </LockValue>
        </div>
        <div className="organization-card_body__indicator __sub-status">
          <LockValue
            canPermissions={["READ_TABLE_INSURANCE"]}
            customLockIcon={<IconDotFilled color={color.purple} />}
          >
            <span className={`indicator_sub-status insurance __${insuranceIcon}`} />
          </LockValue>
        </div>
        <div className="organization-card_body__indicator __sub-status">
          <LockValue
            canPermissions={["READ_TABLE_TAX_VAT_EMPLOYERS"]}
            customLockIcon={<IconDotFilled color={color.purple} />}
          >
            <span className={`indicator_sub-status __${taxVatIcon}`} />
          </LockValue>
        </div>
      </div>
      <div className="organization-card_head__actions">
        {!cantBeRemoved && !isBlackList && (
          <LockValue
            canPermissions={["ADD_RECIPIENTS_IN_RFT"]}
            needLabel
            needRightIcon={false}
            needShowModalToSubscribe
          >
            <ButtonTransparent
              small
              icon={
                hasPermission(["ADD_RECIPIENTS_IN_RFT"], userPermissions) ? (
                  isAlreadyInRecipients ? (
                    <IconDelete />
                  ) : (
                    <IconPlus />
                  )
                ) : (
                  <IconLockFilled color={color.purple} />
                )
              }
              name={isAlreadyInRecipients ? "REMOVE_2" : "ADD"}
              addClass="organization-card_body__action-button"
              disabled={isDisabledActionButton}
              onClick={
                hasPermission(["ADD_RECIPIENTS_IN_RFT"], userPermissions)
                  ? handleAddRemoveRecipients
                  : handleStopClick
              }
            />
          </LockValue>
        )}
      </div>
    </div>
  );
};

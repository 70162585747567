export const verificationStatuses = {
  failed: "failed",
  complete: "complete",
  pending: "pending",
};

export const emptyPublicRequest = {
  id: null,
  readed: true,
  interested: null,
  emailSent: null,
  rejectMessage: null,
  SellerId: null,
  RequestId: null,
  UserAssigneeId: null,
  Offer: null,
  MessagesStat: {
    recentUpdatedMessageAt: null,
    unreadMessageCount: 0,
  },
};

import React, {memo} from "react";

export const IconPhone = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      className={addClass}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8936 8.89702C16.8526 8.89702 14.9656 8.52202 14.3776 7.93402C14.0166 7.57302 13.9316 7.12102 13.8626 6.75702C13.7776 6.30902 13.7266 6.17602 13.5306 6.09102C12.6286 5.70302 11.3346 5.48102 9.97956 5.48102C8.63956 5.48102 7.35957 5.70002 6.46757 6.08102C6.27357 6.16402 6.22357 6.29702 6.14056 6.74402C6.07257 7.10902 5.98857 7.56302 5.62857 7.92302C5.30057 8.25102 4.61357 8.47702 4.09557 8.60802C3.42757 8.77702 2.71156 8.87502 2.13256 8.87502C1.46856 8.87502 1.01956 8.74902 0.760565 8.48902C0.369565 8.09802 0.119565 7.56302 0.0755649 7.02202C0.0385649 6.56602 0.126565 5.89002 0.755565 5.26002C1.77756 4.23802 3.15157 3.44102 4.84157 2.89202C6.39557 2.38602 8.16357 2.11902 9.95557 2.11902C11.7596 2.11902 13.5426 2.38902 15.1116 2.90102C16.8166 3.45702 18.2046 4.26202 19.2356 5.29402C20.2856 6.34402 20.0256 7.73702 19.2476 8.51502C18.9906 8.77202 18.5476 8.89702 17.8936 8.89702ZM9.97956 4.48102C11.4866 4.48102 12.8876 4.72702 13.9256 5.17202C14.6386 5.47802 14.7586 6.11002 14.8456 6.57002C14.8976 6.84502 14.9426 7.08302 15.0856 7.22602C15.3376 7.47802 16.7916 7.89702 17.8946 7.89702C18.3756 7.89702 18.5276 7.81502 18.5466 7.80302C18.8566 7.48902 19.2446 6.71702 18.5296 6.00102C16.7246 4.19602 13.5196 3.11902 9.95557 3.11902C6.42057 3.11902 3.24656 4.18402 1.46256 5.96702C1.17456 6.25502 1.04256 6.58302 1.07156 6.94102C1.09656 7.24302 1.24156 7.55502 1.46156 7.77702C1.48056 7.78902 1.63456 7.87502 2.13156 7.87502C3.22956 7.87502 4.67256 7.46402 4.92057 7.21602C5.06156 7.07502 5.10557 6.83702 5.15657 6.56202C5.24257 6.10002 5.35956 5.46702 6.07357 5.16202C7.09957 4.72302 8.48657 4.48202 9.97857 4.48202L9.97956 4.48102Z"
        fill={color || "#000"}
      />
      <path
        d="M16.4993 18H3.4993C2.8283 18 2.2093 17.736 1.7563 17.257C1.3033 16.778 1.0743 16.145 1.1113 15.475C1.1153 15.398 1.2293 13.574 2.3813 11.736C3.0633 10.648 3.9673 9.781 5.0673 9.159C6.4283 8.39 8.0873 8 9.9993 8C11.9113 8 13.5703 8.39 14.9313 9.159C16.0323 9.781 16.9363 10.648 17.6173 11.736C18.7693 13.575 18.8833 15.399 18.8873 15.475C18.9243 16.145 18.6953 16.778 18.2423 17.257C17.7893 17.736 17.1703 18 16.4993 18ZM9.9993 9C6.8823 9 4.6113 10.088 3.2503 12.233C2.2203 13.856 2.1113 15.515 2.1103 15.532C2.0883 15.924 2.2213 16.293 2.4833 16.57C2.7453 16.847 3.1063 17 3.5003 17H16.5003C16.8933 17 17.2543 16.847 17.5173 16.57C17.7803 16.293 17.9123 15.924 17.8903 15.531C17.8893 15.515 17.7793 13.856 16.7503 12.233C15.3883 10.088 13.1173 9 10.0013 9H9.9993Z"
        fill={color || "#000"}
      />
      <path
        d="M10 16C8.346 16 7 14.654 7 13C7 11.346 8.346 10 10 10C11.654 10 13 11.346 13 13C13 14.654 11.654 16 10 16ZM10 11C8.897 11 8 11.897 8 13C8 14.103 8.897 15 10 15C11.103 15 12 14.103 12 13C12 11.897 11.103 11 10 11Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

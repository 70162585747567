import React from "react";
import {useTranslation} from "react-i18next";
import ToggleSupplierMonitoring from "assets/images/gif/toggle-supplier-monitoring.gif";
import ToggleSupplierMonitoringCover from "assets/images/svg/circular-illustration-cover-1-1.svg";

const EmptyMonitoringSection = () => {
  const {t} = useTranslation();
  return (
    <div className="monitoring empty">
      <div className="illustration">
        <img src={ToggleSupplierMonitoring} alt="" className="illustration-bg" />
        <img src={ToggleSupplierMonitoringCover} alt="" className="illustration-cover" />
      </div>
      <div className="instruction">
        <div className="heading">{t("MONITORING_TAB_EMPTY_INSTRUCTIONS_HEADING")}</div>
        <div className="text">{t("MONITORING_TAB_EMPTY_INSTRUCTIONS")}</div>
      </div>
    </div>
  );
};

export default EmptyMonitoringSection;

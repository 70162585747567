import React from "react";
import {Select} from "antd";
import {useTranslation} from "react-i18next";
// local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {LockValue} from "components/LockValue";
import {InfoTooltip} from "components/InfoTooltip";
import {IconEdit} from "components/icons";
import {hasPermission} from "helpers/helper";

const {Option} = Select;

const PresentationCardWrapper = ({
  title,
  subTitle,
  edit,
  setEdit,
  editName,
  children,
  addClass,
  img,
  icon,
  titleToolTip,
  isActive,
  select,
  selectOptions,
  selectOnChange,
  defaultValue,
  considerPermissons,
  editPermissions,
}) => {
  const {t} = useTranslation();

  const ButtonEdit = () => (
    <ButtonTransparent icon={<IconEdit />} noBorder addClass="pa-0" onClick={openEdit} />
  );
  const openEdit = () =>
    editPermissions
      ? hasPermission(["EDIT_OUR_PROFILE_MAIN_INFO"]) &&
        setEdit({...edit, [editName]: true})
      : setEdit({...edit, [editName]: true});

  return (
    <div className={`presentation-card ${addClass ?? ""}`}>
      {title || isActive ? (
        <div className="presentation-card__header">
          <div className="presentation-card__title">
            <LockValue
              canPermissions={considerPermissons}
              needLabel
              noHideOurInfo
              addClassIcon="mb-1"
            >
              {img && <img src={img} alt="" />}
              {icon}
              <h4>
                {title && title}
                {subTitle && <span>{subTitle}</span>}
              </h4>
            </LockValue>

            {titleToolTip && <InfoTooltip tooltip={titleToolTip} addClass="ml-2" />}

            {select && selectOptions && (
              <LockValue
                canPermissions={considerPermissons}
                justHide
                noHideOurInfo
                addClassIcon="mb-1"
              >
                {selectOptions.length > 1 && (
                  <Select
                    className="presentation-card__select"
                    onChange={selectOnChange}
                    defaultValue={defaultValue}
                  >
                    <Option value="all">{t("ALL")}</Option>
                    {selectOptions}
                  </Select>
                )}
                {selectOptions.length === 1 && (
                  <span className="ml-4">
                    {selectOptions[0].props?.children?.join("")}
                  </span>
                )}
              </LockValue>
            )}
          </div>
          {isActive &&
            (editPermissions ? (
              <LockValue
                canPermissions={editPermissions}
                needLabel
                needShowModalToSubscribe
                addClassIcon="ml-0"
              >
                <ButtonEdit />
              </LockValue>
            ) : (
              <ButtonEdit />
            ))}
        </div>
      ) : (
        ""
      )}
      <div className="presentation-card__content">{children}</div>
    </div>
  );
};

export default PresentationCardWrapper;

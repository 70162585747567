import * as yup from "yup";
import {stringRequired} from "./../../../../../yup/defaultValudations";

export const schema = yup.object().shape({
  firstName: stringRequired(),
  lastName: stringRequired(),
  mobilePhone: stringRequired(),
});

export const initialValues = {firstName: "", lastName: "", email: "", mobilePhone: ""};

import React, {useState} from "react";
import {Upload} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import {IconPlus} from "components/icons/index";
import {showWarning} from "helpers/notifications.helper";
import {inject, observer} from "mobx-react";
import ButtonDelete from "components/buttons/ButtonDelete";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
  if (!isJpgOrPng) showWarning("FILE_UPLOAD_WRONG_FORMAT_JPG_JPEG_PNG");
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) showWarning("Image size must be smaller than 5MB");
  return isJpgOrPng && isLt2M;
}

const PresentationPageLogoEdit = inject("store")(
  observer(({store: {organization}}) => {
    const [loading, setLoading] = useState();
    const logotype = organization?.organizationDetail?.logotype;
    const handleChange = (info) => {
      if (info.file.status === "uploading") {
        setLoading(true);
        return;
      }
    };

    const customRequest = ({file, headers, onError, onProgress, withCredentials}) => {
      organization
        .loadOrganizationLogo(file, {
          withCredentials,
          headers,
          onUploadProgress: ({total, loaded}) => {
            onProgress({percent: Math.round((loaded / total) * 100).toFixed(2)}, file);
          },
        })
        .then((aws) => {
          organization.setOrganizationDetail({
            ...organization?.organizationDetail,
            logotype: {...aws.uploadResponse},
          });
          setLoading(false);
        })
        .catch(onError);
    };

    const uploadButton = (
      <div>
        {loading ? (
          <LoadingOutlined />
        ) : (
          <ButtonTransparent
            name="ADD_YOUR_LOGO_BUTTON_TEXT"
            type="button"
            small
            icon={<IconPlus />}
          />
        )}
      </div>
    );

    const handleDeleteLogo = () =>
      organization.deleteOrganizationLogo().then((aws) => {
        organization.setOrganizationDetail({
          ...organization?.organizationDetail,
          logotype: {},
        });
      });

    return (
      <div className="presentation-page-header__logo">
        <ImgCrop minZoom={0.2} cropperProps={{restrictPosition: false}}>
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            customRequest={customRequest}
            disabled={!organization?.organizationDetail?.isActive}
          >
            {logotype?.Location && !loading ? (
              <img src={logotype.Location} alt="Logo" style={{width: "100%"}} />
            ) : (
              <>{organization?.organizationDetail?.isActive && uploadButton}</>
            )}
          </Upload>
        </ImgCrop>
        {logotype?.Location && <ButtonDelete onClick={handleDeleteLogo} />}
      </div>
    );
  })
);

export default PresentationPageLogoEdit;

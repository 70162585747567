import React, {memo} from "react";

export const IconUnlock = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 1C12.519 1 10.5 3.019 10.5 5.5V8H3C2.173 8 1.5 8.673 1.5 9.5V17.5C1.5 18.327 2.173 19 3 19H13C13.827 19 14.5 18.327 14.5 17.5V9.5C14.5 8.673 13.827 8 13 8H11.5V5.5C11.5 3.57 13.07 2 15 2C16.93 2 18.5 3.57 18.5 5.5V6.5C18.5 6.776 18.724 7 19 7C19.276 7 19.5 6.776 19.5 6.5V5.5C19.5 3.019 17.481 1 15 1ZM13 9C13.276 9 13.5 9.224 13.5 9.5V17.5C13.5 17.776 13.276 18 13 18H3C2.724 18 2.5 17.776 2.5 17.5V9.5C2.5 9.224 2.724 9 3 9H13Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

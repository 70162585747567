import React, {memo} from "react";

export const IconFileAdd = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.854 5.646L13.354 1.146C13.26 1.052 13.133 1 13 1H3.5C2.673 1 2 1.673 2 2.5V18.5C2 19.327 2.673 20 3.5 20H16.5C17.327 20 18 19.327 18 18.5V6C18 5.867 17.947 5.74 17.854 5.646ZM16.793 6H13.5C13.224 6 13 5.776 13 5.5V2.207L16.793 6ZM16.5 19H3.5C3.224 19 3 18.776 3 18.5V2.5C3 2.224 3.224 2 3.5 2H12V5.5C12 6.327 12.673 7 13.5 7H17V18.5C17 18.776 16.776 19 16.5 19Z"
        fill={color || "#000"}
      />
      <path
        d="M11.5 13H9V10.5C9 10.224 8.776 10 8.5 10C8.224 10 8 10.224 8 10.5V13H5.5C5.224 13 5 13.224 5 13.5C5 13.776 5.224 14 5.5 14H8V16.5C8 16.776 8.224 17 8.5 17C8.776 17 9 16.776 9 16.5V14H11.5C11.776 14 12 13.776 12 13.5C12 13.224 11.776 13 11.5 13Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React from "react";

export const IconRing3150 = ({height, width, addClass}) => {
  return (
    <svg
      width={width || "120"}
      height={height || "120"}
      viewBox="0 0 120 120"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60 6C60 2.68629 62.6929 -0.0310717 65.9901 0.299751C71.8145 0.884152 77.533 2.31889 82.961 4.56723C90.2405 7.58251 96.8549 12.0021 102.426 17.5736C107.998 23.1451 112.417 29.7595 115.433 37.039C118.448 44.3185 120 52.1207 120 60C120 67.8793 118.448 75.6815 115.433 82.961C112.417 90.2406 107.998 96.8549 102.426 102.426C96.8549 107.998 90.2405 112.417 82.961 115.433C77.533 117.681 71.8145 119.116 65.99 119.7C62.6929 120.031 60 117.314 60 114V114C60 110.686 62.6966 108.039 65.9844 107.625C70.2316 107.092 74.3974 105.991 78.3688 104.346C84.1924 101.934 89.4839 98.3983 93.9411 93.9411C98.3983 89.4839 101.934 84.1924 104.346 78.3688C106.758 72.5452 108 66.3035 108 60C108 53.6966 106.758 47.4548 104.346 41.6312C101.934 35.8076 98.3983 30.5161 93.9411 26.0589C89.4839 21.6017 84.1924 18.066 78.3688 15.6538C74.3974 14.0088 70.2316 12.9082 65.9844 12.3745C62.6966 11.9614 60 9.31371 60 6V6Z"
        fill="#AC4DC4"
      />
      <path
        opacity="0.1"
        d="M60 6C60 2.68629 62.6931 -0.0311306 65.9903 0.299609C79.3845 1.64319 92.0044 7.4637 101.755 16.9124C112.948 27.7594 119.481 42.5364 119.97 58.1154C120.46 73.6943 114.868 88.8524 104.378 100.381C93.8878 111.909 79.3233 118.903 63.7674 119.882C48.2115 120.86 32.8853 115.747 21.0331 105.624C9.18092 95.5016 1.73311 81.164 0.26628 65.6465C-1.20055 50.129 3.42875 34.6497 13.1742 22.4854C21.6634 11.8892 33.4543 4.53285 46.5745 1.52114C49.8042 0.779765 52.8167 3.13822 53.232 6.4258C53.6474 9.71338 51.3036 12.6781 48.0935 13.5C38.0658 16.0674 29.0782 21.8265 22.5394 29.9883C14.743 39.7198 11.0396 52.1032 12.213 64.5172C13.3865 76.9312 19.3447 88.4013 28.8265 96.4995C38.3082 104.598 50.5692 108.688 63.0139 107.905C75.4586 107.122 87.1103 101.527 95.5023 92.3046C103.894 83.0819 108.368 70.9555 107.976 58.4923C107.585 46.0291 102.358 34.2075 93.4038 25.5299C85.8935 18.252 76.2551 13.6648 65.9847 12.3744C62.6968 11.9613 60 9.31371 60 6Z"
        fill="#AC4DC4"
      />
    </svg>
  );
};

import React from "react";

export const IconLinkBluebeam = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0379639 2.00098C0.0379639 0.896408 0.933394 0.000976562 2.03796 0.000976562H18.038C19.1425 0.000976562 20.038 0.896407 20.038 2.00098V18.001C20.038 19.1055 19.1425 20.001 18.038 20.001H2.03796C0.933395 20.001 0.0379639 19.1055 0.0379639 18.001V2.00098Z"
        fill="#EFEFEF"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M7.32609 2.00098V4.64504H15.2586V15.2213H4.68236V2.00098H2.03796V17.8653H17.9027V2.00098H7.32609ZM7.32609 12.5772H12.6145V7.2891H7.32609V12.5772Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React, {useEffect} from "react";
import ButtonDelete from "components/buttons/ButtonDelete";
import UploadMultiple from "components/UploadMultiple";
// import {useTranslation} from "react-i18next";
import AgreementsSelect from "./AgreementsSelect";
import EditSelect from "components/selects/EditSelect";
import {CollectiveUnionAgreementsOrganizationTypes} from "constants/presentationPage.consts";
import {inject, observer} from "mobx-react";

const CollectiveUnionAgreementsForm = inject("store")(
  observer(({store: {organization}, index, remove, unionAgreement, setFieldValue}) => {
    // const {t} = useTranslation();
    const fileFieldName = `unionAgreements.${index}.Files`;
    const organizationFieldName = `unionAgreements.${index}.organizationName`;
    const localAgreementName = `unionAgreements.${index}.localAgreement`;
    const organizationTypeName = `unionAgreements.${index}.organizationType`;

    const handleChangeOrganizationType = (value) => {
      setFieldValue(
        organizationFieldName,
        value === "collective"
          ? "LOCAL_AGREEMENT_ORGANIZATION_NAME_DEFAULT"
          : "COLLECTIVE_AGREEMENT_ORGANIZATION_NAME_DEFAULT"
      );
      setFieldValue(localAgreementName, value === "locale");
    };

    useEffect(() => {
      setFieldValue(
        organizationTypeName,
        unionAgreement.localAgreement ? "locale" : "collective"
      );
    }, [organizationTypeName, unionAgreement.localAgreement, setFieldValue]);

    return (
      <div className="editBlock_item">
        <div className="editBlock_flex__wrap without-labels">
          <EditSelect
            list={CollectiveUnionAgreementsOrganizationTypes}
            // label={t("TYPE")}
            name={organizationTypeName}
            onChange={handleChangeOrganizationType}
          />

          <AgreementsSelect
            values={unionAgreement}
            // label={t("ORGANIZATION")}
            name={organizationFieldName}
            value
          />

          <UploadMultiple
            uploadRequest={organization.unionAgreementUploadFile}
            fieldName={fileFieldName}
            files={unionAgreement.Files}
            setFieldValue={setFieldValue}
          />

          <ButtonDelete addClass="editBlock_btn__clean" onClick={() => remove(index)} />
        </div>
      </div>
    );
  })
);

export default CollectiveUnionAgreementsForm;

import React, {useState, useEffect} from "react";
import {inject, observer} from "mobx-react";
// local
import ContactPersons from "../components/OverviewComponents/Contact/ContactPersons";
import Insurance from "../components/OverviewComponents/Insurance/Insurance";
import MemberOf from "../components/OverviewComponents/MemberOf/MemberOf";
import CollectiveUnionAgreements from "../components/OverviewComponents/CollectiveUnionAgreements/CollectiveUnionAgreements";
import ID06Cards from "../components/OverviewComponents/ID06Cards/ID06Cards";
import LicensesAndCertificates from "../components/OverviewComponents/LicensesAndCertificates/LicensesAndCertificates";
import Employees from "../components/OverviewComponents/Employees/Employees";
import Payroll from "../components/OverviewComponents/Payroll/Payroll";
import EstimatedEmployees from "../components/OverviewComponents/EstimatedEmployees/EstimatedEmployees";
import Policies from "../components/OverviewComponents/Policies/Policies";
import {hasPermission} from "helpers/helper";

const OverviewSection = inject("store")(
  observer(({store: {auth, organization}, edit, setEdit}) => {
    const {organizationDetail} = organization;
    const {
      user: {permissions},
      activeOrganizationId,
    } = auth;
    const [data, setData] = useState(null);
    const [loadingID06, setLoadingID06] = useState(false);
    const companyNumber = organizationDetail?.Company?.companyNumber;
    const myOrganization = organizationDetail?.id === activeOrganizationId;
    const hasFirstUpdateData = !!organizationDetail?.Company?.updatedCreditSafeCredit;

    useEffect(() => {
      if (
        companyNumber &&
        permissions &&
        (hasPermission(["READ_OTHER_ID06"], permissions) || myOrganization)
      ) {
        setLoadingID06(true);
        organization
          .getID06OrganizationData(companyNumber)
          .then(setData)
          .finally(() => setLoadingID06(false));
      }
    }, [companyNumber, organization, permissions, myOrganization]);

    return (
      <div className="overview-section_wrapper">
        <ContactPersons edit={edit} setEdit={setEdit} />
        <CollectiveUnionAgreements edit={edit} setEdit={setEdit} />
        <MemberOf edit={edit} setEdit={setEdit} />
        <div className="overview-section_presentation-row">
          <Payroll
            organization={organizationDetail}
            hasFirstUpdateData={hasFirstUpdateData}
          />
          <EstimatedEmployees organization={organizationDetail} />
        </div>
        <div className="overview-section_presentation-row">
          {/* special case: hide entire ID06 section w/o correct subscription permissions */}
          {hasPermission(["READ_OTHER_ID06"], permissions) || myOrganization ? (
            <ID06Cards companyData={data} loading={loadingID06} />
          ) : (
            <div className="presentation-card">
              {/* output an empty card to keep the grid alive */}
            </div>
          )}

          <Employees organization={organizationDetail} />
        </div>
        <LicensesAndCertificates edit={edit} setEdit={setEdit} />
        <Policies edit={edit} setEdit={setEdit} />
        <Insurance edit={edit} setEdit={setEdit} />
      </div>
    );
  })
);

export default OverviewSection;

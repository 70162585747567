import React, {memo} from "react";

export const IconChevronLeft = memo(({color, height, width}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox={`0 0 ${width ?? "20"} ${height ?? "20"}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 20C14.128 20 14.256 19.951 14.354 19.854C14.549 19.659 14.549 19.342 14.354 19.147L5.70798 10.501L14.354 1.855C14.549 1.66 14.549 1.343 14.354 1.148C14.159 0.952997 13.842 0.952997 13.647 1.148L4.64698 10.148C4.45198 10.343 4.45198 10.66 4.64698 10.855L13.647 19.855C13.745 19.953 13.873 20.001 14.001 20.001L14 20Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

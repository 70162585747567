import React, {memo} from "react";

export const IconContractNumber = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "21"}
      viewBox="0 0 20 21"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.854 4.68869L13.354 0.188694C13.26 0.0946941 13.133 0.0426941 13 0.0426941H3.5C2.673 0.0426941 2 0.715694 2 1.54269V18.5427C2 19.3697 2.673 20.0427 3.5 20.0427H16.5C17.327 20.0427 18 19.3697 18 18.5427V5.04269C18 4.90969 17.947 4.78269 17.854 4.68869ZM16.793 5.04269H13.5C13.224 5.04269 13 4.81869 13 4.54269V1.24969L16.793 5.04269ZM16.5 19.0427H3.5C3.224 19.0427 3 18.8187 3 18.5427V1.54269C3 1.26669 3.224 1.04269 3.5 1.04269H12V4.54269C12 5.36969 12.673 6.04269 13.5 6.04269H17V18.5427C17 18.8187 16.776 19.0427 16.5 19.0427Z"
        fill={color || "#000"}
      />
      <path
        d="M10.8567 11.8477H9.67167L9.36667 13.4077H10.5467L10.8567 11.8477ZM12.8117 11.1277L12.7367 11.5427C12.72 11.646 12.6783 11.7227 12.6117 11.7727C12.545 11.8227 12.4417 11.8477 12.3017 11.8477H11.6617L11.3517 13.4077H12.2017C12.305 13.4077 12.3783 13.4394 12.4217 13.5027C12.4683 13.5627 12.48 13.666 12.4567 13.8127L12.4117 14.1277H11.2467L10.8367 16.2227H10.3967C10.35 16.2227 10.305 16.2127 10.2617 16.1927C10.2217 16.1694 10.1883 16.1394 10.1617 16.1027C10.135 16.0627 10.115 16.0177 10.1017 15.9677C10.0917 15.9177 10.0933 15.8627 10.1067 15.8027L10.4417 14.1277H9.26167L8.92667 15.8527C8.9 15.986 8.845 16.081 8.76167 16.1377C8.67833 16.1944 8.58333 16.2227 8.47667 16.2227H8.04667L8.45667 14.1277H7.76667C7.66667 14.1277 7.59333 14.0977 7.54667 14.0377C7.5 13.9777 7.48833 13.8727 7.51167 13.7227L7.55667 13.4077H8.56167L8.86667 11.8477H7.73167L7.80167 11.4377C7.81833 11.331 7.86 11.2527 7.92667 11.2027C7.99333 11.1527 8.09667 11.1277 8.23667 11.1277H8.97167L9.31667 9.38769C9.33667 9.28103 9.38667 9.19769 9.46667 9.13769C9.54667 9.07436 9.64 9.04269 9.74667 9.04269H10.1867L9.77667 11.1277H10.9617L11.3667 9.04269H11.8017C11.9083 9.04269 11.9917 9.07603 12.0517 9.14269C12.1117 9.20936 12.1317 9.29603 12.1117 9.40269L11.7667 11.1277H12.8117Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconContractNumber;

import React from "react";
import {useTranslation} from "react-i18next";
// local
import ButtonDelete from "../buttons/ButtonDelete";
import {CustomTooltip} from "components/CustomTooltip";
import cn from "classnames";

const SelectionRow = ({
  values,
  onRemove,
  small,
  tiny,
  suffixContent,
  transparent,
  nonStretch,
  bold,
  canSelectAllValue,
  maxItems,
  addClass,
  addClassItem,
}) => {
  const {t} = useTranslation();

  const isSelectAllValue = canSelectAllValue && values.length === canSelectAllValue.count;

  const rowItem = (item, i) => (
    <div
      key={`${item}${i}`}
      className={cn(
        "multiselect-custom_item",
        {__small: small, __tiny: !!tiny},
        {"__no-remove-button": !onRemove},
        {[`__${item.color}`]: item.color},
        {__transparent: !!transparent},
        {__bold: !!bold},
        {"mr-0": isSelectAllValue || maxItems === 1},
        {[addClassItem]: !!addClassItem}
      )}
    >
      <span
        className={!!onRemove || (maxItems === 1 && !isSelectAllValue) ? "mr-2" : "mr-1"}
      >
        {t(item.label)}
      </span>
      {maxItems === 1 && maxItems < values.length && !isSelectAllValue && (
        <CustomTooltip
          text={values
            .filter((_, i) => i > maxItems - 1)
            .map((item) => t(item.label))
            .join(", ")}
        >
          <span className="mr-1">{`+${values.length - maxItems}`}</span>
        </CustomTooltip>
      )}
      {!!onRemove && <ButtonDelete isWhite onClick={() => onRemove(item.value)} />}
    </div>
  );

  return (
    <div
      className={cn("multiselect-custom_list __wrapper", {
        [addClass]: !!addClass,
        "__non-stretch": !!nonStretch,
      })}
    >
      <div className="multiselect-custom_list __inner-wrapper">
        {isSelectAllValue
          ? rowItem(canSelectAllValue.item, 0)
          : values.map((item, i) =>
              !!maxItems ? i < maxItems && rowItem(item, i) : rowItem(item, i)
            )}

        {!!maxItems && maxItems !== 1 && maxItems < values.length && (
          <CustomTooltip
            text={values
              .filter((_, i) => i > maxItems - 1)
              .map((item) => t(item.label))
              .join(", ")}
          >
            <span className="fz-12">{`+${values.length - maxItems} ${t("OTHERS")}`}</span>
          </CustomTooltip>
        )}
        {suffixContent}
      </div>
    </div>
  );
};

export default SelectionRow;

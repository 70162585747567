import React from "react";
import {Spin} from "antd";
import {useTranslation} from "react-i18next";
//local
import PresentationCardWrapper from "../../PresentationCardWrapper";
import {IconID06} from "components/icons";
import {LockValue} from "components/LockValue";

const ID06Cards = ({companyData, loading}) => {
  const {t} = useTranslation();

  return (
    <PresentationCardWrapper addClass="id06-section" icon={<IconID06 />} title="ID06">
      <LockValue
        canPermissions={["READ_OTHER_ID06"]}
        noHideOurInfo
        fullSubcribeButton
        needMiniSibscribeButton
      >
        {loading ? (
          <span className="id06-section__label">
            {t("OVERVIEW_TAB_ID06_STATUS_1")}
            <Spin className="ml-3" size="default" />
          </span>
        ) : (
          <React.Fragment>
            {companyData?.company_status !== "Ej anslutet" &&
              companyData?.company_status !== "Anslutet" && (
                <span className="id06-section__label">
                  {t("OVERVIEW_TAB_ID06_STATUS_4")}
                </span>
              )}
            {companyData?.company_status === "Anslutet" && companyData?.active_cards && (
              <React.Fragment>
                <span className="id06-section__label mr-3">
                  {t("OVERVIEW_TAB_ID06_STATUS_2")}
                </span>
                <span className="id06-section__value">{companyData?.active_cards}</span>
              </React.Fragment>
            )}
            {companyData?.company_status === "Ej anslutet" &&
              companyData?.active_cards && (
                <React.Fragment>
                  <div className="id06-section__value mr-3">
                    {companyData?.company_status}
                  </div>
                  <span className="id06-section__value">{companyData?.active_cards}</span>
                </React.Fragment>
              )}
          </React.Fragment>
        )}
      </LockValue>
    </PresentationCardWrapper>
  );
};

export default ID06Cards;

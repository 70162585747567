import React from "react";
import {useTranslation} from "react-i18next";

const CompanyTypeItem = ({title, selects}) => {
  const {t} = useTranslation();

  return (
    <div className="edit-our-services_item entrepreneur-filters">
      <p className="edit-our-services_title">{title}</p>
      {selects.map(
        (subData) =>
          !!subData.values?.length && (
            <div className="edit-our-services_item__inner-wrapper" key={subData}>
              <p className="edit-our-services_sub-title">{t(subData.groupSubTitle)}</p>
              <div className="edit-our-services_selected__wrapper">
                {subData.values.map((item) => (
                  <span key={item} className="edit-our-services_selected__item">
                    {t(item)}
                  </span>
                ))}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default CompanyTypeItem;

import React from "react";
//local
import {IconChatSingle} from "components/icons";
import {useTranslation} from "react-i18next";

const OurOrderMessage = ({request}) => {
  const {t} = useTranslation();

  return (
    <div className="current-our-offer_message-text __wrapper">
      <h3 className="current-our-offer_message-text __title">
        <IconChatSingle /> {t("MESSAGE_TO_BUYER")}
      </h3>
      <p>{request?.Offer?.message}</p>
    </div>
  );
};

export default OurOrderMessage;

import React, {memo} from "react";
//local
import {ProfileSettings} from "../../ProfileSettings";
import ButtonCloseDrawer from "../../buttons/ButtonCloseDrawer";

export const SettingsHeader = memo(({closeDrawer, ifDrawer}) => {
  return (
    <div className="modal-settings__header">
      <ButtonCloseDrawer
        closeDrawer={closeDrawer}
        ifOpen={ifDrawer}
        addClass="modal-settings__close"
      />
      <ProfileSettings />
    </div>
  );
});

import React from "react";
import {useTranslation} from "react-i18next";
//local
import {
  IconNumberHash,
  IconTextSize,
  IconRadioButton,
  IconAsterisk,
} from "components/icons";
import {color} from "constants/color.consts";
import cn from "classnames";

const SmartFormItemView = ({question, answer, isAnswerSide}) => {
  const {t} = useTranslation();

  return (
    <div className="question-answer-item">
      {question.type === "heading" ? (
        <div className="__uppercase __bold __answer- question">{question.label}</div>
      ) : (
        <div
          className={cn("__answer-question", {__short: isAnswerSide})}
          style={{color: color.midGray}}
        >
          <span className="question-and-type">
            <span className="question">
              {question.label}
              {question.type !== "heading" && question.required && (
                <IconAsterisk addClass="ml-3 mandatory" />
              )}
            </span>
          </span>
        </div>
      )}
      {isAnswerSide ? (
        <div className="answer-presentation">
          {question.type === "boolean" ? t(answer?.value?.toUpperCase()) : answer?.value}{" "}
          {question.type === "number" && t(question.measurementUnit)}
        </div>
      ) : (
        <React.Fragment>
          <div className="question-type">
            {question.type === "number" && !!question.measurementUnit && (
              <span className="answer-in-unit">{t(question.measurementUnit)}</span>
            )}
            {question.type === "boolean" && (
              <span className="answer-type">
                <IconRadioButton addClass="icon" />{" "}
                {t("SMART_FORM_QUESTIONS_SELECT_YESNO")}
              </span>
            )}
            {question.type === "string" && (
              <span className="answer-type">
                <IconTextSize addClass="icon" /> {t("SMART_FORM_QUESTIONS_SELECT_STRING")}
              </span>
            )}
            {question.type === "number" && (
              <span className="answer-type">
                <IconNumberHash addClass="icon" />{" "}
                {t("SMART_FORM_QUESTIONS_SELECT_NUMBER")}
              </span>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default SmartFormItemView;

import React, {memo} from "react";

export const IconPulse = memo(({color, height, width, className}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.496 16.925C12.385 16.925 12.117 16.888 11.944 16.556C11.886 16.445 11.84 16.305 11.802 16.127L9.44198 5.11401L7.24598 13.167C7.06898 13.815 6.70098 13.932 6.49698 13.943C6.29298 13.954 5.91398 13.881 5.66498 13.257L4.50098 10.346L4.33698 10.757C4.05798 11.454 3.25198 12 2.50098 12H0.500977C0.224977 12 0.000976562 11.776 0.000976562 11.5C0.000976562 11.224 0.224977 11 0.500977 11H2.50098C2.84698 11 3.27998 10.707 3.40798 10.386L3.66498 9.74301C3.90798 9.13601 4.29298 9.04601 4.50098 9.04601C4.70898 9.04601 5.09398 9.13701 5.33698 9.74301L6.41198 12.429L8.75598 3.83301C8.93098 3.19101 9.25598 3.06401 9.49998 3.07101C9.74398 3.07801 10.061 3.22201 10.2 3.87301L12.485 14.535L13.815 7.88301C13.948 7.21901 14.306 7.08201 14.508 7.06001C14.71 7.03801 15.089 7.09201 15.364 7.71101L16.552 10.383C16.696 10.706 17.148 11 17.501 11H19.501C19.777 11 20.001 11.224 20.001 11.5C20.001 11.776 19.777 12 19.501 12H17.501C16.758 12 15.94 11.468 15.638 10.789L14.683 8.64001L13.188 16.117C13.152 16.295 13.108 16.436 13.051 16.548C12.881 16.886 12.609 16.924 12.501 16.925C12.5 16.925 12.499 16.925 12.498 16.925H12.496Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

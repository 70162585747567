import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
//local
import {Modal} from "components/Modal";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {IconCog} from "components/icons";
import {color} from "constants/color.consts";

export const MonitorSuppliersFilterModal = inject("store")(
  observer(({store: {nsi}, initialFilters, isAdminOwner, onChange, onClose}) => {
    const {t} = useTranslation();
    const [filters, setFilters] = useState(initialFilters);
    const [allFilters, setAllFilters] = useState([]);

    const handleChangeFilter = (code, value) => {
      const preparedFilters = value
        ? [...filters, code]
        : filters.filter((item) => item !== code);
      setFilters(preparedFilters);
      onChange(preparedFilters);
    };

    useEffect(() => {
      nsi.getAllMonitorSuppliersFilters().then(setAllFilters);
    }, [nsi]);

    return (
      <Modal
        onClose={onClose}
        width={820}
        boldTitle
        noCenterContent
        titleIcon={<IconCog color={color.black} addClass="mr-2" />}
        titleText="SETTINGS_ORGANIZATION_MONITOR_SUPPLIERS_MODAL_TITLE"
        suffixTitleIcon={
          <span className="mid-gray __normal ml-4">
            {t("SETTINGS_ORGANIZATION_MONITOR_SUPPLIERS_MODAL_STAT")?.format(
              filters?.length ?? 0,
              allFilters?.length ?? 0
            )}
          </span>
        }
        addClass="monitor-suppliers-filters-modal__wrapper"
      >
        <div className="monitor-suppliers-filters-modal__filters">
          {!!allFilters?.length &&
            allFilters.map((filter, index) => (
              <CustomRadioSwitch
                key={`${filter}_${index}`}
                checked={filters.includes(filter)}
                mediumText
                label={t(`MONITORING_${filter}_LABEL`)}
                small
                disabled={!isAdminOwner}
                isActive={(checked) => handleChangeFilter(filter, checked)}
              />
            ))}
        </div>
      </Modal>
    );
  })
);

import React, {memo} from "react";

export const IconReply = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "12"}
      height={height || "13"}
      viewBox="0 0 12 13"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2575 9.38135C9.91014 8.55995 9.41274 7.82195 8.77914 7.18835C8.14554 6.55475 7.40754 6.05735 6.58614 5.70995C5.73534 5.34995 4.83174 5.16755 3.90054 5.16755H1.92474L4.41234 2.67995C4.52934 2.56295 4.52934 2.37275 4.41234 2.25575C4.29534 2.13875 4.10514 2.13875 3.98814 2.25575L0.988141 5.25575C0.871141 5.37275 0.871141 5.56295 0.988141 5.67995L3.98814 8.67995C4.04694 8.73875 4.12374 8.76755 4.20054 8.76755C4.27734 8.76755 4.35414 8.73815 4.41294 8.67995C4.52994 8.56295 4.52994 8.37275 4.41294 8.25575L1.92534 5.76815H3.90114C7.37514 5.76815 10.2011 8.59415 10.2011 12.0681C10.2011 12.2337 10.3355 12.3681 10.5011 12.3681C10.6667 12.3681 10.8011 12.2337 10.8011 12.0681C10.8011 11.1369 10.6187 10.2333 10.2587 9.38255L10.2575 9.38135Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

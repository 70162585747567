import React, {memo} from "react";

export const IconCheckMini = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={height || "10"}
      height={width || "10"}
      viewBox="0 0 10 10"
      fill="none"
      className={addClass}
    >
      <path
        d="M1 6L3 8L9 2"
        stroke={color || "#000"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default IconCheckMini;

import React from "react";
import PresentationCardWrapper from "../../PresentationCardWrapper";
import UnpaidDebitItem from "./UnpaidDebitItem";
import {useTranslation} from "react-i18next";
import {LockValue} from "components/LockValue";
import EmptyOrNoCompanyCreditData from "components/EmptyOrNoCompanyCreditData";

const UnpaidDebts = ({companyData, hasFirstUpdateData}) => {
  const {t} = useTranslation();
  const companyBasic = companyData?.Company?.basic;
  const isEmpty =
    !companyBasic?.kfDebtNrAmal &&
    !companyBasic?.kfDebtSumAmal &&
    !companyBasic?.kfDebtNrEmal &&
    !companyBasic?.kfDebtSumEmal;

  return (
    <PresentationCardWrapper
      title={t("UNPAID_DEBTS")}
      titleToolTip={t("UNPAID_DEBTS_TOOLTIP")}
      addClass="unpaid-debdts"
    >
      <LockValue
        canPermissions={["READ_OTHER_UNPAID_DEBTS"]}
        noHideOurInfo
        fullSubcribeButton
        needMiniSibscribeButton
      >
        <div className={`custom-table_wrap`}>
          <div className="custom-table_head">
            <div className="custom-table_head__date">{t("DATE")}</div>
            <div className="custom-table_head__title">{t("TYPE")}</div>
            <div className="custom-table_head__title value">{t("TOTAL_AMOUNT")}</div>
            <div className="custom-table_head__title count">{t("AMOUNT")}</div>
          </div>
          <div className="custom-table_body">
            {isEmpty ? (
              <EmptyOrNoCompanyCreditData hasFirstUpdateData={hasFirstUpdateData} />
            ) : (
              <React.Fragment>
                <UnpaidDebitItem
                  title={t("PRIVATE_DEBTS")}
                  date={companyBasic?.kfDebtDate}
                  amount={companyBasic?.kfDebtNrAmal}
                  total={companyBasic?.kfDebtSumAmal}
                />
                <UnpaidDebitItem
                  title={t("GOVERNMENT_DEBTS")}
                  date={companyBasic?.kfDebtDate}
                  amount={companyBasic?.kfDebtNrEmal}
                  total={companyBasic?.kfDebtSumEmal}
                />
                <UnpaidDebitItem
                  title={t("TOTAL")}
                  amount={
                    (parseFloat(companyBasic?.kfDebtNrAmal) || 0) +
                    (parseFloat(companyBasic?.kfDebtNrEmal) || 0)
                  }
                  total={
                    (parseFloat(companyBasic?.kfDebtSumAmal) || 0) +
                    (parseFloat(companyBasic?.kfDebtSumEmal) || 0)
                  }
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </LockValue>
    </PresentationCardWrapper>
  );
};

export default UnpaidDebts;

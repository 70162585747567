// ======================================
// Working with data outside of the procurement process. Data
// ======================================

//local
import axiosInstance from "../axios";

class NsiStore {
  async getCurrencies() {
    try {
      const {data} = await axiosInstance.get("references/currencies");
      return data.map((currency) => currency.code);
    } catch (error) {
      throw error;
    }
  }

  async getCountries() {
    try {
      const {data} = await axiosInstance.get("references/countries");
      return data.map((country) => ({label: country.name, value: country.id}));
    } catch (error) {
      throw error;
    }
  }

  async getAllMonitorSuppliersFilters() {
    try {
      const {data} = await axiosInstance.get("references/monitor-suppliers-categories");
      return data;
    } catch (error) {
      throw error;
    }
  }
}

const nsi = new NsiStore();
export default nsi;

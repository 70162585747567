import React from "react";
import {useTranslation} from "react-i18next";
//local
import FormikField from "components/FormikField";
import {color} from "constants/color.consts";
import {IconAsterisk, IconNumberHash, IconTextSize} from "components/icons";
import {RadioGroup} from "components/RadioGroup";

const SmartFormAnswerItemEdit = ({question, answer, onChange}) => {
  const {t} = useTranslation();

  const radioValues = [
    {
      value: "Yes",
      label: "YES",
      checked: answer?.value === "Yes",
    },
    {
      value: "No",
      label: "NO",
      checked: answer?.value === "No",
      className: "ml-8",
    },
  ];

  return (
    <div className="question-answer-item">
      {question.type === "heading" ? (
        <div className="__uppercase __bold __answer-question __heading">
          {question.label}
        </div>
      ) : (
        <div className="__short __answer-question">{question.label}</div>
      )}
      {question.type !== "heading" && (
        <div className="mandatory-wrapper">
          {question.required && <IconAsterisk color={color.red} />}
        </div>
      )}
      <span className="answer-inputs">
        {question.type === "string" && (
          <FormikField
            name="name"
            small
            fullWidth
            placeholder={t("SMART_FORM_ANSWERS_ROW_PLACEHOLDER")}
            errorName="name"
            className="input"
            suffixIcon={<IconTextSize color={color.discreteText} />}
            value={answer?.value}
            markUnfilled={question.required && !answer?.value}
            onChange={(e) => onChange({...answer, value: e.target.value})}
          />
        )}
        {question.type === "boolean" && (
          <RadioGroup
            values={radioValues}
            direction="horizontal"
            large
            addClassRadio="pl-0"
            markUnfilled={question.required && !answer?.value}
            resultValue={(value) => onChange({...answer, value})}
          />
        )}
        {question.type === "number" && (
          <FormikField
            name="name"
            small
            placeholder={t("SMART_FORM_ANSWERS_ROW_PLACEHOLDER")}
            errorName="name"
            className="input"
            type="number"
            value={answer?.value}
            markUnfilled={question.required && !answer?.value}
            suffixIcon={
              <div className="df-row-center">
                <div className="measurement-label">{t(question.measurementUnit)}</div>
                <IconNumberHash color={color.discreteText} />
              </div>
            }
            onChange={(e) => onChange({...answer, value: e.target.value})}
          />
        )}
      </span>
    </div>
  );
};

export default SmartFormAnswerItemEdit;

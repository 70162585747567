import React, {useEffect, useState, useMemo, useCallback} from "react";
import {organizationActionValue, organizationActions} from "constants/select.consts";
import ReportModal from "./ReportModal/ReportModal";
import CommentModal from "components/modals/CommentModal";
import HistoryInfo from "components/HistoryInfo";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {showWarning} from "helpers/notifications.helper";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconWarning} from "components/icons";
import {DropdownEllipsis} from "components/DropdownEllipsis";
import {historyBlacklistTableColumns} from "constants/table.consts";
import {color} from "constants/color.consts";

const PresentationPageHeaderDropdown = inject("store")(
  observer(({store: {auth, organization, clientStore}}) => {
    const {t} = useTranslation();
    const {organizationDetail} = organization;
    const [visibleReportModal, setVisibleReportModal] = useState(false);
    const [showCommentModal, setCommentModal] = useState(false);
    const [showHistoryModal, setHistoryModal] = useState(false);

    useEffect(() => {
      organization.getBlackList();
    }, [organization]);

    const sendRequest = useCallback(
      (value) => {
        if (value === organizationActionValue.BLACKLIST) {
          if (!auth.isStaff) showWarning(t("BLACKLIST_PERMISSION_TEXT"));
          else setCommentModal(true);
        }

        if (value === organizationActionValue.HISTORY_INFO) setHistoryModal(true);

        if (value === organizationActionValue.FORCE_QUALIFICATION)
          clientStore
            .startQualification(organizationDetail.id)
            .then(() => organization.getOrganizationDetail(organizationDetail.id, true));
      },
      [auth.isStaff, organization, t, clientStore, organizationDetail.id]
    );

    const isInBlacklist = organization.blacklistOrganization?.some(
      ({id}) => id === organizationDetail.id
    );

    const organizationActionsMenu = useMemo(
      () =>
        organizationActions
          .map((row) => ({
            ...row,
            label: row.label(isInBlacklist),
            onClick: () => sendRequest(row.value),
          }))
          .filter((row) =>
            row.visible(
              isInBlacklist || !organizationDetail?.BlacklistHistoryEvents.length
            )
          ),
      [isInBlacklist, sendRequest, organizationDetail]
    );

    const handleChangeBlacklistHistoryComment = (historyId, values) => {
      organization
        .changeBlacklistHistoryComment(
          historyId,
          values,
          organizationDetail?.BlacklistHistoryEvents
        )
        .then((BlacklistHistoryEvents) =>
          organization.setOrganizationDetail({BlacklistHistoryEvents})
        );
    };

    return (
      <React.Fragment>
        <ButtonTransparent
          icon={
            <IconWarning width="20" height="20" color={color.orange} addClass="mr-0" />
          }
          noBorder
          tooltip="REPORT_THIS_PAGE"
          onClick={() => setVisibleReportModal(true)}
          addClass="mr-4"
        />
        <DropdownEllipsis
          addClass="__rotate-on-hover pos-r"
          needCogIcon
          list={organizationActionsMenu}
        />
        {showCommentModal && (
          <CommentModal
            onConfirm={(comment) =>
              organization
                .addToBlackList(organizationDetail.id, {comment})
                .then(() => organization.getFavorites())
            }
            onClose={() => setCommentModal(false)}
            placeholder={`${t(
              isInBlacklist
                ? "BLACKLIST_COMMENT_PLACEHOLDER_REMOVE"
                : "BLACKLIST_COMMENT_PLACEHOLDER_ADD"
            )}\n\n${t("BLACKLIST_COMMENT_PLACEHOLDER_COMMON")}`}
          />
        )}
        {showHistoryModal && (
          <HistoryInfo
            data={organizationDetail?.BlacklistHistoryEvents}
            columns={historyBlacklistTableColumns}
            onChangeComment={handleChangeBlacklistHistoryComment}
            showOnlyModal
            onCloseModal={setHistoryModal}
          />
        )}
        <ReportModal visible={visibleReportModal} setVisible={setVisibleReportModal} />
      </React.Fragment>
    );
  })
);

export default PresentationPageHeaderDropdown;

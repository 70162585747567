export const TOKEN = "token";
export const googleMapsApiKey = "AIzaSyCIPAaUGJG8JA0n8WtDaLZlASE7WbjCgAQ";

export const roles = {
  ADMIN: "admin",
  OWNER: "owner",
  MEMBER: "member",
};

export const subscriptionRoles = {
  BUYER: "USER_ROLE_SUB_05_BUYER",
  VIEWER: "USER_ROLE_SUB_05_VIEWER",
  TENDERER: "USER_ROLE_SUB_05_TENDERER",
  CUSTOMER: "USER_ROLE_SUB_05_CUSTOMER",
};

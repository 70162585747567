import React, {useState, useEffect, useMemo, useRef} from "react";
import {inject, observer} from "mobx-react";
import {Select as AntdSelect} from "antd";
import {useTranslation} from "react-i18next";
//local
import Select from "components/selects/Select";
import {InfoTooltip} from "components/InfoTooltip";
import {LockValue} from "components/LockValue";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {color} from "constants/color.consts";
import {dateFormat} from "helpers/datetime";
import {showSuccess} from "helpers/notifications.helper";
import {
  IconChevronDownMini,
  IconRefresh,
  IconCamera,
  IconDownload,
} from "components/icons";
import {hasPermission, hasPermissions, openNewTab} from "helpers/helper";
import cn from "classnames";

const {Option} = AntdSelect;

const FinancialSnapshotSelect = inject("store")(
  observer(({store: {auth, organization}, companyNumber, initialSnapshots, addClass}) => {
    const {
      user: {ActiveOrganization},
    } = auth;
    const {t} = useTranslation();
    const snapshotButtonRef = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const [snapshots, setSnapshots] = useState(initialSnapshots ?? []);
    const [loading, setLoading] = useState(false);

    const hasSnapshots = useMemo(() => !!snapshots.length, [snapshots]);
    const iconColor = useMemo(
      () => (hasSnapshots ? color.white : color.blue),
      [hasSnapshots]
    );
    const canCreateSnapshots = useMemo(
      () =>
        ActiveOrganization.creditsUpdateLimit - ActiveOrganization.creditsUpdateUsed > 0,
      [ActiveOrganization]
    );
    const needGetSnapshots = useMemo(
      () =>
        hasPermission(["ENABLE_COMPANY_CREDITSAFE_SNAPSHOTS"]) &&
        !!companyNumber &&
        !initialSnapshots,
      [companyNumber, initialSnapshots]
    );

    const options = snapshots.map((item, i) => (
      <Option key={item.id} value={item.id}>
        <button
          key={item.File?.url}
          className="financial-snapshot-select-dropdown-download-button"
          onClick={() => openNewTab(item.File?.url)}
        >
          <IconDownload height="16" width="16" />
          {`${dateFormat(item.File.createdAt)}.pdf`}
        </button>
      </Option>
    ));

    const openSelect = () =>
      hasSnapshots
        ? setIsOpen(!isOpen)
        : hasPermissions([
            "ENABLE_COMPANY_CREDITSAFE_SNAPSHOTS",
            "CREATE_RFT_OFFERS_OFFER_SNAPSHOT",
          ]) && addSnapshot();

    const backdropListener = (e) => {
      if (snapshotButtonRef.current && isOpen) {
        const select = document.querySelector(
          ".financial-snapshot-select-dropdown.ant-select-dropdown"
        );
        select && !select.contains(e.target) && setIsOpen(false);
      }
    };

    const addSnapshot = async () => {
      try {
        setLoading(true);
        const snapshot = await organization.createOrganizationSnapshot(companyNumber);
        showSuccess(t("FINANCIAL_SNAPSHOT_SUCCESS_CREATING"));
        setSnapshots((prevState) => [snapshot, ...prevState]);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      needGetSnapshots &&
        organization.getOrganizationSnapshots(companyNumber).then(setSnapshots);
    }, [needGetSnapshots, organization, companyNumber]);

    useEffect(() => {
      document.body.addEventListener("click", backdropListener);
      return () => {
        document.body.removeEventListener("click", backdropListener);
      };
    });

    return (
      <div
        className={cn("financial-snapshot-select", {[addClass]: !!addClass})}
        onClick={(e) => e.stopPropagation()}
      >
        <LockValue
          canPermissions={["ENABLE_COMPANY_CREDITSAFE_SNAPSHOTS"]}
          needAllPermissions
          needLabel
          needShowModalToSubscribe
        >
          <LockValue
            canPermissions={["CREATE_RFT_OFFERS_OFFER_SNAPSHOT"]}
            revertLogic={
              hasSnapshots && !hasPermission(["CREATE_RFT_OFFERS_OFFER_SNAPSHOT"])
            }
            additionalShowCondition={!companyNumber}
            needAllPermissions
            needLabel
            needShowModalToSubscribe
          >
            <ButtonTransparent
              ref={snapshotButtonRef}
              name={
                hasSnapshots
                  ? dateFormat(snapshots[0].File.createdAt)
                  : "FINANCIAL_SNAPSHOT_SELECT_BUTTON"
              }
              onClick={openSelect}
              small
              icon={<IconCamera color={iconColor} />}
              iconRight={<IconChevronDownMini addClass="mr-0 ml-2" color={iconColor} />}
              nonTransparent={hasSnapshots}
              disabled={!companyNumber | loading}
            />
          </LockValue>

          <Select
            open={isOpen}
            placement="bottomRight"
            onChange={() => setIsOpen(false)}
            addDropdownClass="financial-snapshot-select-dropdown"
            customBottomRow={
              <LockValue
                canPermissions={["CREATE_RFT_OFFERS_OFFER_SNAPSHOT"]}
                needAllPermissions
                needLabel
                addClassIcon="ml-0 mr-2"
                needShowModalToSubscribe
              >
                <ButtonTransparent
                  name="FINANCIAL_SNAPSHOT_SELECT_CREATE_BUTTON"
                  icon={<IconRefresh height="16" width="16" color={color.white} />}
                  nonTransparent
                  small
                  onClick={() =>
                    hasPermission(["CREATE_RFT_OFFERS_OFFER_SNAPSHOT"]) && addSnapshot()
                  }
                  disabledTooltip="FINANCIAL_SNAPSHOT_SELECT_CREATE_BUTTON_TOOLTIP"
                  disabled={loading || !canCreateSnapshots}
                />
              </LockValue>
            }
          >
            {options}
          </Select>
        </LockValue>
        {!hasSnapshots && (
          <InfoTooltip
            tooltip={
              companyNumber
                ? "FINANCIAL_SNAPSHOT_TOOLTIP"
                : "FINANCIAL_SNAPSHOT_WO_COMPANY_NUMBERTOOLTIP"
            }
            centerText
            addClass="ml-1"
          />
        )}
      </div>
    );
  })
);

export default FinancialSnapshotSelect;

import React, {memo} from "react";

export const IconFavoriteDone = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.92842 17.0716C4.81789 18.96 7.32842 20 10 20C12.6716 20 15.1832 18.96 17.0716 17.0716C18.96 15.1832 20 12.6726 20 10.0011C20 7.32947 18.9611 4.81895 17.0716 2.92947C15.1821 1.04 12.6716 0 10 0C7.32842 0 4.81684 1.04 2.92842 2.92947C1.04 4.81895 0 7.32947 0 10.0011C0 12.6716 1.03895 15.1832 2.92842 17.0716ZM7.43274 13.854C7.53939 13.9507 7.6786 13.9997 7.81789 14C7.81752 14 7.81716 14 7.81679 14H7.81898C7.81861 14 7.81825 14 7.81789 14C7.95718 13.9997 8.09638 13.9517 8.20303 13.854L15.8404 6.85333C16.0532 6.65831 16.0532 6.34128 15.8404 6.14626C15.6277 5.95125 15.2818 5.95125 15.0691 6.14626L7.81788 12.7929L4.93095 10.1466C4.71819 9.95162 4.37232 9.95162 4.15957 10.1466C3.94681 10.3417 3.94681 10.6587 4.15957 10.8537L7.43274 13.854Z"
        fill={color || "#6BC069"}
      />
    </svg>
  );
});

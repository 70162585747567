import React, {useEffect, useState, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//locale
import {IconDocumentPen} from "components/icons";
import OurOrderTopInfo from "../components/componentsOurOfferTab/OurOrderTopInfo";
import OurOrderMessage from "../components/componentsOurOfferTab/OurOrderMessage";
import EditOurOffers from "../components/componentsOurOfferTab/EditOurOffers/EditOurOffers";
import LoadDocsColumn from "components/LoadDocsColumn";
import SmartFormAnswers from "pages/OurRequests/components/SmartForm/SmartFormAnswers";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";
import {IconEdit} from "components/icons";
import {Preloader} from "components/Preloader";
import Confetti from "components/Confetti";

const OurOfferSection = inject("store")(
  observer(({store: {auth, clientStore, seller}}) => {
    const {t} = useTranslation();
    const {language} = clientStore;
    const {user} = auth;
    const {request, offerFiles} = seller;
    const [edit, setEdit] = useState(false);
    const create = useMemo(() => !request?.Offer?.id, [request?.Offer]);
    const openEdit = () => hasPermission(["READ_SELLER_RFTS"]) && setEdit(true);

    const createdAt = useMemo(() => request?.Offer?.createdAt, [request?.Offer]);
    const currentTime = useMemo(() => new Date(), []);
    const offerCreationTime = useMemo(
      () => (createdAt ? new Date(createdAt) : null),
      [createdAt]
    );
    const timeDifference = useMemo(
      () => (offerCreationTime ? currentTime - offerCreationTime : null),
      [offerCreationTime, currentTime]
    );
    const showConfetti = useMemo(
      () => timeDifference !== null && timeDifference <= 5000,
      [timeDifference]
    ); // 5 seconds in milliseconds

    useEffect(() => {
      if (!!request?.Offer?.id && request.Offer?.id !== seller.currentOfferId) {
        seller.getOffer(seller.currentOfferId);
      }
    }, [seller, seller.currentOfferId, request]);

    if (edit || create)
      return <EditOurOffers create={create} edit={edit} setEdit={setEdit} />;

    return (
      <>
        {!seller.offerLoading ? (
          <div className="request-to-us_single __wrapper">
            <Confetti trigger={showConfetti} />
            <div className="df-row">
              <OurOrderTopInfo request={request} language={language} />
              <div className="current-our-offer_edit-btn __wrapper">
                <LockValue
                  canPermissions={["READ_SELLER_RFTS"]}
                  needLabel
                  needShowModalToSubscribe
                  addClassIcon="ml-1"
                >
                  <ButtonTransparent
                    icon={<IconEdit />}
                    name={"EDIT_OFFER"}
                    noBorder
                    addClass="pa-0"
                    onClick={openEdit}
                  />
                </LockValue>
              </div>
            </div>
            <div className="current-our-offer_sub-info __wrapper">
              <OurOrderMessage request={request} />
              <div className="current-our-offer_documents load-docs_wrapper __wrapper">
                <div className="load-docs_column-load">
                  <LoadDocsColumn
                    title={t("TENDER_DOCUMENT")}
                    titleIcon={<IconDocumentPen />}
                    button={"ADD_DOCUMENT"}
                    docs={offerFiles}
                    fullWidth
                    needAuthorInfo
                    currentUser={user}
                  />
                </div>
              </div>
            </div>
            <SmartFormAnswers
              isNew={false}
              isEdit={false}
              isSellerSide
              titleHide={!request?.Request?.smartFormQuestions?.length}
              questions={request?.Request?.smartFormQuestions}
              answers={request?.Offer?.smartFormAnswers ?? []}
            />
          </div>
        ) : (
          <Preloader />
        )}
      </>
    );
  })
);

export default OurOfferSection;

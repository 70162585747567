import React from "react";

export const IconLinkDropbox = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.00101C0 0.896438 0.895431 0.00100708 2 0.00100708H18C19.1046 0.00100708 20 0.896438 20 2.00101V18.001C20 19.1056 19.1046 20.001 18 20.001H2C0.895431 20.001 0 19.1056 0 18.001V2.00101Z"
        fill="#0062FF"
      />
      <path
        d="M10.0095 6.1941L6.33053 8.48629L10.0095 10.7785L6.33053 13.0707L2.65156 10.7661L6.33053 8.4739L2.65156 6.1941L6.33053 3.9019L10.0095 6.1941ZM6.31151 13.8079L9.99048 11.5157L13.6695 13.8079L9.99048 16.1001L6.31151 13.8079ZM10.0095 10.7661L13.6885 8.4739L10.0095 6.1941L13.6695 3.9019L17.3484 6.1941L13.6695 8.48629L17.3484 10.7785L13.6695 13.0707L10.0095 10.7661Z"
        fill="white"
      />
    </svg>
  );
};

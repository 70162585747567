import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import * as qr from "querystring-es3";
// locale
import {Button} from "components/buttons/Button";
import {ButtonRed} from "components/buttons/ButtonRed";
import UserInviteLayout from "../UserInviteLayout";
import {MAIN_PAGE} from "constants/routes.const";

const InviteChoicePage = inject("store")(
  observer(({store: {organization}}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [rejecting, setRejecting] = useState(false);
    const location = useLocation();
    const query = qr.parse(location.search.replace("?", ""));
    const joinRequestId = query["id"];
    const navigate = useNavigate();

    const handleAccept = () => {
      setLoading(true);
      organization
        .acceptJoinRequest(joinRequestId)
        .then(() => navigate(MAIN_PAGE))
        .finally(() => setLoading(false));
    };

    const handleReject = () => {
      setRejecting(true);
      organization
        .rejectJoinRequest(joinRequestId)
        .then(() => {
          navigate(MAIN_PAGE);
        })
        .finally(() => setRejecting(false));
    };

    return (
      <UserInviteLayout>
        <div className="invite-page_choice __form">
          <h3>{t("INVITE_YOU_WERE_INVITED")}</h3>
          <div className="invite-page_choice __actions">
            <Button
              loading={loading}
              disabled={rejecting}
              name={t("ACCEPT")}
              onClick={handleAccept}
            />
            <ButtonRed
              loading={rejecting}
              disabled={loading}
              name={t("REJECT")}
              onClick={handleReject}
            />
          </div>
        </div>
      </UserInviteLayout>
    );
  })
);

export default InviteChoicePage;

import React, {useState} from "react";
import {useTranslation} from "react-i18next";
//local
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import CustomRadioSwitch from "components/CustomRadioSwitch";

export const LastSignatureModal = ({organizationName, onOk, onClose}) => {
  const {t} = useTranslation();
  const [finalizeSignatures, setFinalizeSignatures] = useState(false);
  const [cantAddMoreSignatures, setCantAddMoreSignatures] = useState(false);

  return (
    <Modal
      onClose={onClose}
      width={600}
      noTitle
      boldTitle
      noCenterContent
      titleText={"CONTRACT_LAST_SIGNATURE_MODAL_TITLE"}
    >
      <div className="pa-8">
        <div>
          <div>{`${t(
            "CONTRACT_LAST_SIGNATURE_MODAL_DESCRIPTION_01"
          )} ${organizationName}.`}</div>
          <div className="mt-2">{`${t(
            "CONTRACT_LAST_SIGNATURE_MODAL_DESCRIPTION_02"
          )} ${organizationName}.`}</div>
          <CustomRadioSwitch
            label={t("CONTRACT_LAST_SIGNATURE_MODAL_SWITCH_01")}
            checked={finalizeSignatures}
            mediumText
            addWrapperClass="mb-2 mt-4"
            isActive={setFinalizeSignatures}
          />
          <CustomRadioSwitch
            label={t("CONTRACT_LAST_SIGNATURE_MODAL_SWITCH_02")}
            mediumText
            checked={cantAddMoreSignatures}
            isActive={setCantAddMoreSignatures}
          />
        </div>
        <div className="df-row-jce-center mt-4">
          <ButtonTransparent
            name="CONTRACT_LAST_SIGNATURE_MODAL_CANCEL"
            addClass="mr-4"
            color="green"
            onClick={onClose}
          />
          <ButtonTransparent
            name="CONTRACT_LAST_SIGNATURE_MODAL_OK"
            disabled={!(finalizeSignatures && cantAddMoreSignatures)}
            onClick={() => {
              onOk();
              onClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

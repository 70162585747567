import React, {memo} from "react";

export const IconTrashCan = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
    >
      <path
        d="M15.5 2H12V1.5C12 0.673 11.327 0 10.5 0H8.5C7.673 0 7 0.673 7 1.5V2H3.5C2.673 2 2 2.673 2 3.5V4.5C2 5.152 2.418 5.708 3 5.914V18.5C3 19.327 3.673 20 4.5 20H14.5C15.327 20 16 19.327 16 18.5V5.914C16.582 5.708 17 5.152 17 4.5V3.5C17 2.673 16.327 2 15.5 2ZM8 1.5C8 1.224 8.224 1 8.5 1H10.5C10.776 1 11 1.224 11 1.5V2H8V1.5ZM14.5 19H4.5C4.224 19 4 18.776 4 18.5V6H15V18.5C15 18.776 14.776 19 14.5 19ZM16 4.5C16 4.776 15.776 5 15.5 5H3.5C3.224 5 3 4.776 3 4.5V3.5C3 3.224 3.224 3 3.5 3H15.5C15.776 3 16 3.224 16 3.5V4.5Z"
        fill={color || "#BA2E2E"}
      />
      <path
        d="M12.5 7C12.224 7 12 7.224 12 7.5V17.5C12 17.776 12.224 18 12.5 18C12.776 18 13 17.776 13 17.5V7.5C13 7.224 12.776 7 12.5 7Z"
        fill={color || "#BA2E2E"}
      />
      <path
        d="M9.5 7C9.224 7 9 7.224 9 7.5V17.5C9 17.776 9.224 18 9.5 18C9.776 18 10 17.776 10 17.5V7.5C10 7.224 9.776 7 9.5 7Z"
        fill={color || "#BA2E2E"}
      />
      <path
        d="M6.5 7C6.224 7 6 7.224 6 7.5V17.5C6 17.776 6.224 18 6.5 18C6.776 18 7 17.776 7 17.5V7.5C7 7.224 6.776 7 6.5 7Z"
        fill={color || "#BA2E2E"}
      />
    </svg>
  );
});

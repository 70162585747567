import React from "react";
import {useTranslation} from "react-i18next";
//local
import {LocalModal} from "components/LocalModal";
import {IconHandOnRight} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";

export const EmailSuggestCompanyModal = ({onAdd, organization, onClose}) => {
  const {t} = useTranslation();

  return (
    <LocalModal onClose={onClose} className={"email-suggest-company-modal__wrapper"}>
      <div className="email-suggest-company-modal__left-line"></div>
      <div className="email-suggest-company-modal__inner">
        <span className="mb-3 __one-row">
          {t("EMAIL_SUGGEST_COMPANY_MODAL_TITLE_QUESTION")}
          <b>{organization.name}?</b>
        </span>
        <span className="__one-row">
          <span className="__uppercase __bold">{organization.name}</span>
          {t("EMAIL_SUGGEST_COMPANY_MODAL_DESCRIPTION")}
        </span>
        <span>{organization.company}</span>
        <span>Orgnr {organization.companyNumber}</span>

        <div className="df mt-2 mb-2">
          <ButtonTransparent
            icon={<IconHandOnRight />}
            nonTransparent
            color="green"
            addClass="mr-2"
            onClick={onAdd}
            name={`${t("EMAIL_SUGGEST_COMPANY_MODAL_BUTTON_1")}${organization.name} ${t(
              "EMAIL_SUGGEST_COMPANY_MODAL_BUTTON_2"
            )}`}
          />
          <ButtonTransparent onClick={onClose} name="NO_IGNORE_BUTTON" />
        </div>
        <div className="email-suggest-company-modal__inner_description">
          <div className="__bold">{t("EMAIL_SUGGEST_COMPANY_MODAL_QUESTION_1")}</div>
          <div className="mb-3">{t("EMAIL_SUGGEST_COMPANY_MODAL_ANSWER_1")}</div>
          <div className="__bold __one-row">{`${t(
            "EMAIL_SUGGEST_COMPANY_MODAL_QUESTION_2"
          )}${organization.email}`}</div>
          <div className="__one-row">{`${t("EMAIL_SUGGEST_COMPANY_MODAL_ANSWER_2_1")}${
            organization.name
          }${t("EMAIL_SUGGEST_COMPANY_MODAL_ANSWER_2_2")}`}</div>
        </div>
      </div>
    </LocalModal>
  );
};

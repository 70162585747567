import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
// local
import {IconDocumentPen, IconBriefcase} from "components/icons";
import LoadDocsColumn from "components/LoadDocsColumn";
import {SignedContractSignatories} from "components/contractProcess/SignedContractSignatories";
import {ContractSignatories} from "components/contractProcess/ContractSignatories";
import {hasUserPermission, saveDocument} from "helpers/helper";
import {roles} from "constants/auth.const";
import {contractModalNames} from "constants/modal.conts";
import {contractSellerDocumentTabs} from "constants/tabs.const";
import {ContractViewInfo} from "components/contractProcess/ContractViewInfo";
import {TitleIcon} from "components/TitleIcon";
import {Tabs} from "components/Tabs";
import RequestDocuments from "components/requestCard/RequestDocuments";

const ContractSection = inject("store")(
  observer(
    ({store: {buyer, clientStore, seller, organization: organizationStore, auth}}) => {
      const {organizationName, bankIdVerifyData, clientRole, user} = auth;
      const {language} = clientStore;
      const {organizationMembersSelectList} = organizationStore;
      const {
        request,
        currentContract,
        contractOwnPersons,
        contractBuyerPersons,
        isFinishedOwnSignatures,
        isFinishedOtherSignatures,
        isFinishedContract,
      } = seller;
      const [activeTab, setActiveTab] = useState(0);
      const {t} = useTranslation();

      const contractId = currentContract?.id ?? null;

      const canDeleteSignatory =
        contractOwnPersons?.length > 1 &&
        (hasUserPermission([roles.ADMIN, roles.OWNER], clientRole) ||
          user.id === request.UserAssigneeId);

      useEffect(() => {
        if (contractId && !isFinishedOtherSignatures) {
          const checkSellerSign = setInterval(() => {
            seller.getContract(contractId, true);
          }, 3000);
          return () => clearInterval(checkSellerSign);
        }
      }, [seller, isFinishedOtherSignatures, contractId]);

      useEffect(() => {
        bankIdVerifyData?.id &&
          seller.getContract(contractId).then(() => auth.setBankIdVerifyData({}));
      }, [bankIdVerifyData, seller, contractId, auth]);

      const onAddSignatory = (values) =>
        seller.addSignatoryOnContract(contractId, values.ContactPersonId);

      const onDeleteSignatory = (id) => seller.removeSignatoryOnContract(contractId, id);

      const onSubmit = (values) => {
        auth.setSignContractSideData(contractId, "seller", values.UserId);
        auth.updateAuthModalName(contractModalNames.identifyContractPerson);
      };

      const loadPDFWatermarkContract = async (url) => {
        let fileName = `contract-${contractId}.pdf`;
        const data = await seller.downloadPdfWatermarkContract(contractId, url);
        if (!data) return;
        saveDocument(data, "application/pdf", fileName, t("FILE_DOWNLOADED"));
      };

      return (
        <div className="contract-tab_wrapper">
          <ContractViewInfo
            price={currentContract?.price?.toLocaleString(language ?? "sv")}
            currencyCode={request.Request?.CurrencyCode}
            additions={currentContract?.additions}
          />
          <div className="contract-tab_documents-tabs-title">
            <TitleIcon
              title={t("DOCUMENTS")}
              addClass="documents-title"
              icon={<IconBriefcase />}
            />
            <Tabs
              tabs={contractSellerDocumentTabs}
              active={activeTab}
              setActive={(id) => setActiveTab(id)}
            />
          </div>
          {activeTab === 0 && (
            <RequestDocuments
              revisions={request.Request?.documents}
              editable={false}
              columnTitles={{
                contracts: "CONTRACT",
                documents: "DOCUMENTS",
                plans: "PLANS",
                links: "LINKS",
              }}
              customLoadDocument={{
                contracts:
                  isFinishedContract &&
                  !currentContract?.isDocumentsReady &&
                  loadPDFWatermarkContract,
              }}
              standardAgreement={request.Request?.standardAgreement}
              needZipButton
              allRevisionsView
            />
          )}
          {activeTab === 1 && (
            <div className="contract-tab_documents">
              <LoadDocsColumn
                title={"DOCUMENTS"}
                titleIcon={<IconDocumentPen />}
                needZipButton
                docs={request?.Offer?.OfferFiles ?? []}
              />
            </div>
          )}

          {!isFinishedContract && (
            <ContractSignatories
              contractOwnPersons={contractOwnPersons}
              contractOtherPersons={contractBuyerPersons}
              isFinishedOwnSignatures={isFinishedOwnSignatures}
              isFinishedOtherSignatures={isFinishedOtherSignatures}
              ownOrganizationName={organizationName}
              otherOrganizationName={request.Request?.Buyer?.name}
              membersList={organizationMembersSelectList.filter(
                (member) =>
                  !contractOwnPersons
                    ?.map((person) => person?.UserId)
                    .includes(member.value)
              )}
              isSellerSide
              currentUser={user}
              canDeleteMember={canDeleteSignatory}
              onSubmit={onSubmit}
              onAddSignatory={onAddSignatory}
              onDeleteSignatory={onDeleteSignatory}
              onSendMessage={() => seller.setActiveRequestTab(3)}
            />
          )}

          {isFinishedContract && (
            <SignedContractSignatories
              contractOwnPersons={contractOwnPersons}
              contractOtherPersons={contractBuyerPersons}
              ownOrganizationName={organizationName}
              otherOrganizationName={request.Request?.Buyer?.name}
              contractId={currentContract?.id}
              isSellerSide
            />
          )}
        </div>
      );
    }
  )
);

export default ContractSection;

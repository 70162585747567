import React, {memo} from "react";

export const IconPaperClip = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 20C7.019 20 5 17.981 5 15.5V3.5C5 1.57 6.57 0 8.5 0C10.43 0 12 1.57 12 3.5V14.5C12 15.878 10.878 17 9.5 17C8.122 17 7 15.878 7 14.5V8.5C7 8.224 7.224 8 7.5 8C7.776 8 8 8.224 8 8.5V14.5C8 15.327 8.673 16 9.5 16C10.327 16 11 15.327 11 14.5V3.5C11 2.122 9.878 1 8.5 1C7.122 1 6 2.122 6 3.5V15.5C6 17.43 7.57 19 9.5 19C11.43 19 13 17.43 13 15.5V8.5C13 8.224 13.224 8 13.5 8C13.776 8 14 8.224 14 8.5V15.5C14 17.981 11.981 20 9.5 20Z"
        fill={color || "#1BA2CC"}
      />
    </svg>
  );
});

import React from "react";
import {useTranslation} from "react-i18next";
// local
import AddSupplierInfoGraphicSe from "assets/images/png/AddSupplierInfoGraphic_se.png";
import AddSupplierInfoGraphicEn from "assets/images/png/AddSupplierInfoGraphic_en.png";
import AddExternalSupplierInfoGraphicEn from "assets/images/png/AddExternalSupplierInfoGraphic_en.png";
import AddExternalSupplierInfoGraphicSe from "assets/images/png/AddExternalSupplierInfoGraphic_se.png";
import {VideoInstructionLink} from "components/VideoInstructionLink";

export const RecipientsEditEmptyCard = ({language}) => {
  const {t} = useTranslation();

  const AddSupplierInfoGraphic = {
    en: AddSupplierInfoGraphicEn,
    sv: AddSupplierInfoGraphicSe,
    fi: AddSupplierInfoGraphicSe,
  };

  const AddExternalSupplierInfoGraphic = {
    en: AddExternalSupplierInfoGraphicEn,
    sv: AddExternalSupplierInfoGraphicSe,
    fi: AddExternalSupplierInfoGraphicSe,
  };

  return (
    <section className="instructions-block">
      <div className="mr-2">
        <div className="df-row-center mt-8 mb-4">
          <h3 className="recipients-tab_instructions-title __bold __uppercase mb-0">
            {t("INVITING_COMPANIES_EXPLAINER_CHOOSE_RECIPIENTS_TITLE")}
          </h3>
          <VideoInstructionLink
            color="black"
            label="VIDEO_LINK_CHOOSE_RECIPIENTS_LABEL"
            link="VIDEO_LINK_CHOOSE_RECIPIENTS_LINK"
            addClass="ml-8"
          />
        </div>

        <div className="recipients-tab_instructions">
          <div className="recipients-tab_instructions_description infographic">
            <img src={AddSupplierInfoGraphic[language]} alt="Instructions" />{" "}
            <div className="df-row-center result mt-8">
              <h4>{t("INVITING_COMPANIES_EXPLAINER_TITLE")}</h4>
              <ul>
                <li>{t("INVITING_COMPANIES_HD_RESULT_1")}</li>
                <li>{t("INVITING_COMPANIES_HD_RESULT_2")}</li>
                <li>{t("INVITING_COMPANIES_HD_RESULT_3")}</li>
              </ul>

              <h4>{t("INVITING_COMPANIES_EXAMPLEMAIL_HD_TITLE")}</h4>
              <ul>
                <li>
                  <a
                    href="/static/email-examples/email-supplier-in-handyday.png"
                    target="blank"
                  >
                    {t("INVITING_COMPANIES_EMAILEXAMLE_RESULT_2")}
                  </a>
                  &nbsp;&nbsp;{t("OPENS_IN_A_NEW_TAB")}
                </li>
                <li>{t("INVITING_COMPANIES_EMAILEXAMLE_HD_RESULT_1")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3 className="recipients-tab_instructions-title mt-8 mb-4 __bold __uppercase">
          {t("INVITING_COMPANIES_EXPLAINER_CHOOSE_EXTERNAL_RECIPIENTS_TITLE")}
        </h3>
        <div className="recipients-tab_instructions_description infographic">
          <img src={AddExternalSupplierInfoGraphic[language]} alt="Instructions" />
          <div className="df-row-center result mt-8">
            <h4>{t("INVITING_COMPANIES_EXPLAINER_TITLE")}</h4>
            <ul>
              <li>{t("INVITING_COMPANIES_RESULT_1")}</li>
              <li>{t("INVITING_COMPANIES_RESULT_2")}</li>
              <li>{t("INVITING_COMPANIES_RESULT_3")}</li>
            </ul>

            <h4>{t("INVITING_COMPANIES_EXAMPLEMAIL_TITLE")}</h4>
            <ul>
              <li>
                <a
                  href="/static/email-examples/email-supplier-not-in-handyday.png"
                  target="blank"
                >
                  {t("INVITING_COMPANIES_EMAILEXAMLE_RESULT_2")}
                </a>
                &nbsp;&nbsp;{t("OPENS_IN_A_NEW_TAB")}
              </li>
              <li>{t("INVITING_COMPANIES_EMAILEXAMLE_RESULT_1")}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

import React from "react";
import {useTranslation} from "react-i18next";
//local
import cn from "classnames";
import {Collapse} from "antd";
import {IconChevronDownMini} from "components/icons";
// import {color} from "constants/color.consts";

const {Panel} = Collapse;

const CustomCollapse = ({
  title,
  bold,
  uppercase,
  noTranslate,
  addClass,
  isActive,
  onChange,
  ...props
}) => {
  const {t} = useTranslation();

  return (
    <Collapse
      ghost
      expandIcon={() => (
        <IconChevronDownMini
          addClass="mr-1"
          style={{transform: isActive ? "" : "rotate(270deg)"}}
        />
      )}
      className={cn("custom-collapse", {
        [addClass]: !!addClass,
        __bold: !!bold,
        __uppercase: !!uppercase,
      })}
      onChange={onChange}
      {...props}
    >
      <Panel header={noTranslate ? title : t(title)} isActive={isActive} />
    </Collapse>
  );
};

export default CustomCollapse;

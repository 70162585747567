import React, {useState} from "react";
import {FieldArray, Form, Formik} from "formik";
// locale
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {ModalOwnerInvite} from "../components/ModalOwnerInvite";
import {IconPlus} from "components/icons";
import {showSuccess} from "helpers/notifications.helper";
import InviteUsersFields from "./InviteUsersFields";
import {color} from "constants/color.consts";
import {schema, initialValues} from "./formAttrs";

const InviteUsersForm = ({
  organizationStore,
  extraPersonPrice,
  projectsList,
  subscriptionRoles,
}) => {
  const [loading, setLoading] = useState(false);
  const [isModalOwner, setIsModalOwner] = useState(false);

  const handlerOnSubmit = (values, {resetForm}) => {
    setLoading(true);
    setIsModalOwner(false);
    const payload = values.invites.map((invite) => ({
      ...invite,
      limitProjectIds: invite.limitProjectIds.map((item) => item.value),
    }));
    organizationStore
      .sendInviteUsers(payload)
      .then(() => showSuccess())
      .finally(() => setLoading(false));
    resetForm();
  };

  const inviteUsersFields = (invites, remove) =>
    invites.map((_, i) => (
      <InviteUsersFields
        key={i}
        index={i}
        remove={remove}
        loading={loading}
        projectsList={projectsList}
        subscriptionRoles={subscriptionRoles}
      />
    ));

  return (
    <div className="invite-user_form__wrapper">
      <Formik
        initialValues={{invites: [initialValues]}}
        validationSchema={schema}
        onSubmit={handlerOnSubmit}
      >
        {({values, validateForm, submitForm}) => (
          <Form>
            <FieldArray
              name="invites"
              render={(arrayHelpers) => (
                <div className="invite-user_form__inner-wrapper">
                  {inviteUsersFields(values.invites, arrayHelpers.remove)}
                  <div className="btn-custom_wrap">
                    <ButtonTransparent
                      disabled={loading}
                      name="ONE_MORE_ROW"
                      addClass="invite-suppliers__btn-send"
                      type="button"
                      icon={<IconPlus color={loading && color.midGray} />}
                      onClick={() => {
                        arrayHelpers.push(initialValues);
                      }}
                    />
                    <ButtonTransparent
                      loading={loading}
                      name="SEND_INVITES"
                      onClick={() =>
                        validateForm().then(({invites}) =>
                          invites?.length ? submitForm() : setIsModalOwner(true)
                        )
                      }
                      nonTransparent={true}
                      addClass="invite-suppliers__btn-send"
                    />
                  </div>
                </div>
              )}
            />
            {isModalOwner && (
              <ModalOwnerInvite
                onClose={() => setIsModalOwner(false)}
                users={values.invites}
                extraPersonPrice={extraPersonPrice}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InviteUsersForm;

import React from "react";
import {Select} from "formik-antd";
import {ErrorMessage} from "formik";
import {checkField} from "helpers/helper";
import {useTranslation} from "react-i18next";
import {
  unionAgreementOrganizationList,
  localAgreementOrganizationList,
} from "constants/select.consts";

const AgreementsSelect = ({label, name, values}) => {
  const {t} = useTranslation();
  const {Option} = Select;

  const optionsUnion = () =>
    unionAgreementOrganizationList.map((item, i) => (
      <Option key={`UNION_KEY_${i}`} value={item.value}>
        {t(item.label)}
      </Option>
    ));

  const optionsLocal = () =>
    localAgreementOrganizationList.map((item, i) => (
      <Option key={`LOCAL_KEY_${i}`} value={item.value}>
        {t(item.label)}
      </Option>
    ));

  const getOptions = () => {
    if (values?.organizationType === "collective") {
      return optionsUnion();
    }
    return optionsLocal();
  };

  return (
    <div className="editBlock_select__item">
      {label && <label className="editBlock_field__label">{label}</label>}

      <Select
        className="editBlock_select__field"
        placeholder="UYFTYGGYUGYGUGGUGJKGJGJ"
        value={t(values?.organizationName)}
        name={name}
      >
        {getOptions()}
      </Select>
      {name && (
        <ErrorMessage name={name}>
          {(msg) => <p className="form-error">{checkField(msg)}</p>}
        </ErrorMessage>
      )}
    </div>
  );
};

export default AgreementsSelect;

import React from "react";
import {Tabs} from "antd";
import {useTranslation} from "react-i18next";

export const SubscriptionTabs = ({tabs, active, setActive, children}) => {
  const {t} = useTranslation();

  return (
    <Tabs
      activeKey={active}
      onChange={setActive}
      centered
      items={tabs.map((item) => ({
        label: (
          <React.Fragment>
            <div>{t(item.title)}</div>
            <div className="fz-12 __normal mid-gray">{t(item.description)}</div>
          </React.Fragment>
        ),
        key: item.id,
        disabled: item.disabled,
      }))}
      className="subscriptions__tabs"
    ></Tabs>
  );
};

import React, {memo} from "react";

export const IconFeather = memo(({color}) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.71108 15C4.69408 15 4.67708 14.999 4.65908 14.997C4.38608 14.969 4.18708 14.726 4.21208 14.453C4.45108 11.933 5.27408 9.667 6.66108 7.719C7.79908 6.12 9.31008 4.737 11.1541 3.609C14.3441 1.657 17.6781 1 18.7101 1C18.9861 1 19.2101 1.224 19.2101 1.5C19.2101 2.444 18.3331 3.321 17.9581 3.696C17.3471 4.307 16.2461 4.689 15.1811 5.059C15.1501 5.07 15.1191 5.081 15.0881 5.091C15.7701 5.057 16.3571 5.052 16.6451 5.052C16.7101 5.052 16.7461 5.052 16.7461 5.052C16.9321 5.054 17.1011 5.158 17.1861 5.323C17.2711 5.488 17.2581 5.686 17.1511 5.839C16.3511 6.984 15.3751 7.876 14.1681 8.566C13.5891 8.897 12.9511 9.051 12.1591 9.051C11.6971 9.051 11.2331 8.999 10.7841 8.949C10.3511 8.901 9.90308 8.851 9.47508 8.851C8.70108 8.851 8.11208 9.013 7.56608 9.377C7.54908 9.389 7.53108 9.399 7.51208 9.408C6.78708 9.77 6.16008 10.759 5.69708 12.267C5.33008 13.463 5.20708 14.545 5.20608 14.556C5.17808 14.811 4.96108 15.001 4.71008 15.001L4.71108 15ZM18.0151 2.068C16.7101 2.268 14.1641 2.94 11.6771 4.462C10.3011 5.304 8.76008 6.53 7.52008 8.256C8.09608 7.981 8.73108 7.851 9.47608 7.851C9.96008 7.851 10.4361 7.904 10.8961 7.955C11.3381 8.004 11.7551 8.051 12.1611 8.051C12.7721 8.051 13.2521 7.939 13.6741 7.698C14.4631 7.247 15.1241 6.718 15.7021 6.069C14.7741 6.098 13.4251 6.179 12.5771 6.405C12.3761 6.459 12.1631 6.382 12.0421 6.213C11.9211 6.044 11.9171 5.818 12.0321 5.645C12.5091 4.93 13.6481 4.534 14.8551 4.115C15.8131 3.782 16.8041 3.438 17.2531 2.989C17.4111 2.831 17.7851 2.457 18.0161 2.068H18.0151Z"
        fill={color || "#fff"}
      />
      <path
        d="M7.7776 16.925C7.7716 16.919 7.2096 16.284 7.2096 15.5C7.2096 15.14 7.0166 14.799 6.6656 14.539C6.4436 14.375 6.1306 14.422 5.9666 14.644C5.8026 14.866 5.8496 15.179 6.0716 15.343C6.1856 15.427 6.2106 15.489 6.2106 15.5C6.2106 15.517 6.1566 15.632 5.9016 15.76C5.5966 15.913 5.1626 16 4.7106 16C4.2586 16 3.8256 15.912 3.5196 15.76C3.2636 15.632 3.2106 15.517 3.2106 15.5C3.2106 15.492 3.2266 15.446 3.3056 15.378C3.5146 15.197 3.5376 14.882 3.3566 14.673C3.1756 14.464 2.8606 14.441 2.6516 14.622C2.2876 14.937 2.2106 15.273 2.2106 15.5C2.2106 16.285 1.6466 16.92 1.6426 16.925C1.0996 17.518 1.0996 18.482 1.6426 19.075C1.7296 19.17 2.5526 20 4.7106 20C6.8686 20 7.6916 19.17 7.7786 19.075C8.3216 18.482 8.3216 17.518 7.7786 16.925H7.7776ZM7.0406 18.399C7.0346 18.405 6.4136 19 4.7096 19C3.0536 19 2.4166 18.434 2.3736 18.394C2.1836 18.18 2.1856 17.812 2.3786 17.601C2.4006 17.577 2.7446 17.196 2.9826 16.608C3.0116 16.624 3.0406 16.64 3.0716 16.655C3.5176 16.878 4.0986 17.001 4.7096 17.001C5.3206 17.001 5.9016 16.878 6.3476 16.655C6.3776 16.64 6.4076 16.624 6.4366 16.608C6.6746 17.196 7.0186 17.577 7.0406 17.601C7.2356 17.814 7.2356 18.187 7.0406 18.4V18.399Z"
        fill={color || "#fff"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconArrowIn = memo(({color, onClick, height, width, addClass}) => {
  return (
    <svg
      width={width || "12"}
      height={height || "12"}
      viewBox="0 0 12 12"
      fill="none"
      onClick={onClick}
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 2.27676L4 9.97364C4 10.2045 4.24375 10.3334 4.41016 10.1904L8.87266 6.342C9.00039 6.23184 9.00039 6.01973 8.87266 5.9084L4.41016 2.05996C4.24375 1.917 4 2.0459 4 2.27676Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconArrowIn;

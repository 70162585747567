import React, {memo} from "react";

export const IconRadioButton = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={addClass}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="7.5" stroke={color || "#6BC069"} />
      <circle cx="10" cy="10" r="4" fill={color || "#6BC069"} />
    </svg>
  );
});

import React, {useState} from "react";
import {FieldArray, Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
// local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import ContactFields from "./ContactFields";
import {showSuccess} from "helpers/notifications.helper";
import MultipleSelect from "components/selects/MultipleSelect";
import {getFullName} from "helpers/helper";
import {schema, initialValues} from "./formAttrs";

const ContactsEdit = inject("store")(
  observer(({store: {organization}, members, contacts: contactsInitial, closeEdit}) => {
    const [loading, setLoading] = useState(false);
    const [contacts, setContacts] = useState(
      !!contactsInitial?.length
        ? contactsInitial.map((contact) => ({
            ...contact,
            name: getFullName(contact.User?.firstName, contact.User?.lastName),
            position: contact.position ?? "",
            workKinds: contact.workKinds ?? "",
            location: contact.location ?? "",
          }))
        : [initialValues]
    );
    const {organizationDetail} = organization;
    const {t} = useTranslation();

    const addContact = (values) => {
      setContacts([
        ...contacts,
        ...values.map((item) => ({
          ...initialValues,
          UserId: item,
          name: members.find((member) => member.value === item)?.label,
          phone: members.find((member) => member.value === item)?.User?.mobilePhone,
          email: members.find((member) => member.value === item)?.User?.email,
        })),
      ]);
    };

    const contactsFields = (contacts) => {
      return contacts.map((contact, i) => (
        <ContactFields
          key={i}
          index={i}
          remove={(i) => setContacts(contacts.filter((item, index) => index !== i))}
          isShowDeleteButton={contacts.length > 1}
        />
      ));
    };

    const onSubmit = (values) => {
      setLoading(true);
      organization
        .updateOrganizationContacts(values.contacts)
        .then((updatedContacts) => {
          showSuccess("Organization contacts successfully updated.");
          organization.setOrganizationDetail({
            ...organizationDetail,
            Contacts: updatedContacts,
          });
          closeEdit();
        })
        .finally(() => setLoading(false));
    };

    return (
      <Formik
        enableReinitialize
        initialValues={{contacts}}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({values}) => (
          <Form>
            <FieldArray
              name="contacts"
              render={() => (
                <div className="edit-insurance_wrapper editBlock_wrapper">
                  <div className="editBlock_head">
                    <div className="df-row-center">
                      <div className="editBlock_head__title mr-8">
                        {t("CONTACT_PERSONS")}
                      </div>
                      <MultipleSelect
                        list={members.filter(
                          (item) =>
                            !contacts.map((value) => value.UserId).includes(item.value)
                        )}
                        small
                        onChange={addContact}
                        titleInButton
                        title="ADD_SOMEONE"
                      />
                    </div>
                    <div className="editBlock_head__actions">
                      <ButtonTransparent
                        type="submit"
                        small
                        loading={loading}
                        name={t("SAVE_CHANGES")}
                        addClass="editBlock_head__btn"
                      />
                      <ButtonTransparent
                        name={t("CANCEL")}
                        small
                        type="button"
                        addClass="editBlock_head__btn"
                        onClick={closeEdit}
                      />
                    </div>
                  </div>
                  <div className="editBlock_body __flex">
                    {contactsFields(values.contacts)}
                  </div>
                </div>
              )}
            />
          </Form>
        )}
      </Formik>
    );
  })
);

export default ContactsEdit;

import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
//local
import {Input} from "../../Input";
import {Button} from "../../buttons/Button";
import {createNewOrganization} from "helpers/helper";

export const ModalOrganizationInput = inject("store")(
  observer(({store: {auth, organization}}) => {
    let navigate = useNavigate();
    const [name, setName] = useState("");

    const changeName = (e) => {
      setName(e.target.value);
    };

    const onSubmit = createNewOrganization(organization, auth, navigate, name);

    return (
      <div className="organization-input">
        <p>Give you Handy Day organisation a name</p>
        <Input value={name} onChange={changeName} placeholder="Type a name here" />
        <Button
          isLoading={organization.btnLoading}
          disabled={organization.btnLoading}
          onClick={onSubmit}
          name="Continue"
        />
      </div>
    );
  })
);

import React from "react";
import {Select} from "antd";
import {useTranslation} from "react-i18next";
//local
import {orderingNameValues} from "constants/select.consts";
import {changeSelectValue, changeFilterParams} from "helpers/helper";

const {Option} = Select;

const FrameworkAgreementsTableHead = ({filterParams, onChangeSort}) => {
  const {t} = useTranslation();

  const onChangeOrder = (value) => {
    const params = changeFilterParams(value);
    onChangeSort("direction", params);
  };

  const options = (values) =>
    values.map(({value, label}, i) => (
      <Option key={i} value={value}>
        {t(label)}
      </Option>
    ));

  return (
    <div className="requests-card_head">
      <div className="requests-card_head__name __wrapper __select mr-2">
        <Select
          onChange={onChangeOrder}
          value={changeSelectValue(orderingNameValues, filterParams)}
        >
          {options(orderingNameValues)}
        </Select>
      </div>
      <div className="requests-card_head__column">{t("CONTRACT_NUMBER")}</div>
      <div className="requests-card_head__column">{t("START_DATE")}</div>
      <div className="requests-card_head__column">{t("END_DATE")}</div>
    </div>
  );
};

export default FrameworkAgreementsTableHead;

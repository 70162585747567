import React, {memo} from "react";

export const IconGroupWork = memo(({color}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 7.24701C12.996 7.08101 12.962 6.33801 12.649 5.58801C12.435 5.07401 12.135 4.66001 11.758 4.35901C11.662 4.28201 11.56 4.21301 11.455 4.15101C11.793 3.79301 12 3.31101 12 2.78101C12 1.67801 11.103 0.781006 10 0.781006C8.897 0.781006 8 1.67801 8 2.78101C8 3.31101 8.208 3.79301 8.545 4.15101C8.44 4.21301 8.339 4.28201 8.242 4.35901C7.865 4.66101 7.565 5.07501 7.351 5.58801C7.004 6.42101 7 7.24601 7 7.28001C7 7.55601 7.224 7.78001 7.5 7.78001H12.5C12.5 7.78001 12.5 7.78001 12.501 7.78001C12.777 7.78001 13.001 7.55601 13.001 7.28001C13.001 7.26901 13.001 7.25801 13 7.24701ZM9 2.78001C9 2.22901 9.449 1.78001 10 1.78001C10.551 1.78001 11 2.22901 11 2.78001C11 3.33101 10.551 3.78001 10 3.78001C9.449 3.78001 9 3.33101 9 2.78001ZM8.05 6.78001C8.088 6.54601 8.156 6.25701 8.274 5.97201C8.608 5.17001 9.173 4.78001 10 4.78001C11.429 4.78001 11.837 6.04801 11.953 6.78001H8.049H8.05Z"
        fill={color || "#000"}
      />
      <path
        d="M6 18.247C5.996 18.081 5.962 17.338 5.649 16.588C5.435 16.074 5.135 15.661 4.758 15.359C4.662 15.282 4.56 15.213 4.455 15.151C4.793 14.793 5 14.311 5 13.781C5 12.678 4.103 11.781 3 11.781C1.897 11.781 1 12.678 1 13.781C1 14.311 1.208 14.793 1.545 15.151C1.44 15.213 1.339 15.282 1.242 15.359C0.865 15.661 0.565 16.075 0.351 16.588C0.00400001 17.421 0 18.246 0 18.28C0 18.556 0.224 18.78 0.5 18.78H5.5C5.5 18.78 5.5 18.78 5.501 18.78C5.777 18.78 6.001 18.556 6.001 18.28C6.001 18.269 6.001 18.258 6 18.247ZM2 13.78C2 13.229 2.449 12.78 3 12.78C3.551 12.78 4 13.229 4 13.78C4 14.331 3.551 14.78 3 14.78C2.449 14.78 2 14.331 2 13.78ZM1.05 17.78C1.088 17.546 1.156 17.257 1.274 16.972C1.608 16.17 2.173 15.78 3 15.78C4.429 15.78 4.837 17.048 4.953 17.78H1.049H1.05Z"
        fill={color || "#000"}
      />
      <path
        d="M20 18.247C19.996 18.081 19.962 17.338 19.649 16.588C19.435 16.074 19.135 15.661 18.758 15.359C18.662 15.282 18.56 15.213 18.455 15.151C18.793 14.793 19 14.311 19 13.781C19 12.678 18.103 11.781 17 11.781C15.897 11.781 15 12.678 15 13.781C15 14.311 15.208 14.793 15.545 15.151C15.44 15.213 15.339 15.282 15.242 15.359C14.865 15.661 14.565 16.075 14.351 16.588C14.004 17.421 14 18.246 14 18.28C14 18.556 14.224 18.78 14.5 18.78H19.5C19.5 18.78 19.5 18.78 19.501 18.78C19.777 18.78 20.001 18.556 20.001 18.28C20.001 18.269 20.001 18.258 20 18.247ZM16 13.78C16 13.229 16.449 12.78 17 12.78C17.551 12.78 18 13.229 18 13.78C18 14.331 17.551 14.78 17 14.78C16.449 14.78 16 14.331 16 13.78ZM15.05 17.78C15.088 17.546 15.156 17.257 15.274 16.972C15.608 16.17 16.173 15.78 17 15.78C18.429 15.78 18.837 17.048 18.953 17.78H15.049H15.05Z"
        fill={color || "#000"}
      />
      <path
        d="M10.0001 20.7801C8.70009 20.7801 7.40909 20.4611 6.26609 19.8571C6.02209 19.7281 5.9291 19.4251 6.05809 19.1811C6.18709 18.9371 6.4901 18.8441 6.7341 18.9731C7.7331 19.5011 8.86209 19.7801 10.0001 19.7801C11.1381 19.7801 12.2671 19.5011 13.2661 18.9731C13.5101 18.8441 13.8131 18.9371 13.9421 19.1811C14.0711 19.4251 13.9781 19.7281 13.7341 19.8571C12.5921 20.4611 11.3011 20.7801 10.0001 20.7801Z"
        fill={color || "#000"}
      />
      <path
        d="M17.2299 11.28C17.0109 11.28 16.8089 11.135 16.7479 10.913C16.2839 9.23301 15.2129 7.79301 13.7329 6.85701C13.4999 6.70901 13.4299 6.40101 13.5769 6.16701C13.7239 5.93301 14.0329 5.86401 14.2669 6.01101C15.9589 7.08001 17.1819 8.72601 17.7119 10.646C17.7849 10.912 17.6289 11.188 17.3629 11.261C17.3189 11.273 17.2739 11.279 17.2299 11.279V11.28Z"
        fill={color || "#000"}
      />
      <path
        d="M2.76999 11.28C2.72599 11.28 2.68099 11.274 2.63699 11.262C2.37099 11.189 2.21499 10.913 2.28799 10.647C2.81799 8.72699 4.04199 7.08099 5.73299 6.01199C5.96599 5.86499 6.27499 5.93399 6.42299 6.16799C6.57099 6.40199 6.50099 6.70999 6.26699 6.85799C4.78599 7.79299 3.71499 9.23399 3.25199 10.914C3.19099 11.136 2.98999 11.281 2.76999 11.281V11.28Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconContract = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "21"}
      viewBox="0 0 20 21"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.854 5.146L13.354 0.646C13.26 0.552 13.133 0.5 13 0.5H3.5C2.673 0.5 2 1.173 2 2V18C2 18.827 2.673 19.5 3.5 19.5H16.5C17.327 19.5 18 18.827 18 18V5.5C18 5.367 17.947 5.24 17.854 5.146ZM16.793 5.5H13.5C13.224 5.5 13 5.276 13 5V1.707L16.793 5.5ZM16.5 18.5H3.5C3.224 18.5 3 18.276 3 18V2C3 1.724 3.224 1.5 3.5 1.5H12V5C12 5.827 12.673 6.5 13.5 6.5H17V18C17 18.276 16.776 18.5 16.5 18.5Z"
        fill={color || "#000"}
      />
      <path
        d="M12 11.586V10.5C12 9.397 11.103 8.5 10 8.5C8.897 8.5 8 9.397 8 10.5V11.586C7.418 11.792 7 12.348 7 13V15C7 15.827 7.673 16.5 8.5 16.5H11.5C12.327 16.5 13 15.827 13 15V13C13 12.348 12.582 11.792 12 11.586ZM10 9.5C10.551 9.5 11 9.949 11 10.5V11.5H9V10.5C9 9.949 9.449 9.5 10 9.5ZM12 15C12 15.276 11.776 15.5 11.5 15.5H8.5C8.224 15.5 8 15.276 8 15V13C8 12.724 8.224 12.5 8.5 12.5H11.5C11.776 12.5 12 12.724 12 13V15Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconContract;

import React, {memo} from "react";

export const IconScale = memo(({color, addClass, size}) => {
  return (
    <svg
      width={size || "20"}
      height={size || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.985 13.379L15.985 1.37901C15.928 1.15201 15.724 1.00001 15.5 1.00001C15.496 1.00001 15.493 1.00001 15.489 1.00001H3.511C3.482 0.999006 3.453 1.00101 3.423 1.00601C3.39 1.01101 3.357 1.02001 3.326 1.03101C3.24 1.06301 3.168 1.11601 3.113 1.18301C3.082 1.22001 3.057 1.26201 3.038 1.30801C3.027 1.33401 3.019 1.36101 3.012 1.38801L0.0139982 13.378C-0.0060018 13.459 -0.0050018 13.542 0.0149982 13.62H0.0139982C0.0299982 13.683 0.182998 14.254 0.684998 14.828C1.152 15.362 2.02 15.999 3.499 15.999C4.978 15.999 5.845 15.362 6.313 14.828C6.815 14.254 6.969 13.683 6.984 13.62H6.983C7.003 13.541 7.004 13.458 6.984 13.378L4.139 1.99901H14.858L12.013 13.378C11.993 13.459 11.994 13.542 12.014 13.62H12.013C12.029 13.683 12.182 14.254 12.684 14.828C13.151 15.362 14.019 15.999 15.498 15.999C16.977 15.999 17.844 15.362 18.312 14.828C18.814 14.254 18.968 13.683 18.983 13.62H18.982C19.002 13.541 19.003 13.458 18.983 13.378L18.985 13.379ZM3.5 15C2.286 15 1.635 14.458 1.302 14H5.699C5.366 14.458 4.715 15 3.501 15H3.5ZM5.86 13H1.141L3.501 3.56201L5.861 13H5.86ZM15.5 3.56201L17.86 13H13.141L15.501 3.56201H15.5ZM15.5 15C14.286 15 13.635 14.458 13.302 14H17.699C17.366 14.458 16.715 15 15.501 15H15.5Z"
        fill={color || "#000"}
      />
      <path
        d="M15.5 18H10.04L10.999 5.538C11.004 5.472 10.996 5.405 10.975 5.342L9.975 2.342C9.907 2.138 9.716 2 9.501 2C9.286 2 9.095 2.138 9.027 2.342L8.027 5.342C8.006 5.405 7.998 5.472 8.003 5.538L8.962 18H3.502C3.226 18 3.002 18.224 3.002 18.5C3.002 18.776 3.226 19 3.502 19H15.502C15.778 19 16.002 18.776 16.002 18.5C16.002 18.224 15.778 18 15.502 18H15.5ZM9.5 4.081L9.994 5.562L9.5 11.98L9.006 5.562L9.5 4.081Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React from "react";
import {SettingsTabs, SettingsHeader} from "./components";

const Settings = ({closeDrawer, ifDrawer}) => {
  return (
    <div className="modal-settings">
      <div className="modal-settings__wrapper">
        <SettingsHeader closeDrawer={closeDrawer} ifDrawer={ifDrawer} />
        <SettingsTabs ifDrawer={ifDrawer} />
      </div>
    </div>
  );
};

export default Settings;

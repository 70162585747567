import React from "react";
import EditSimpleInput from "../../EditComponents/EditSimpleInput";
import ButtonDelete from "components/buttons/ButtonDelete";
import UploadMultiple from "components/UploadMultiple";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";

const PoliciesForm = inject("store")(
  observer(({store: {organization}, index, remove, policy, setFieldValue}) => {
    const {t} = useTranslation();
    const fileFieldName = `policies.${index}.Files`;

    return (
      <div className="editBlock_item">
        <div className="editBlock_flex__wrap">
          <EditSimpleInput label={t("NAME")} name={`policies.${index}.name`} />
          <UploadMultiple
            uploadRequest={organization.policyUploadFile}
            fieldName={fileFieldName}
            files={policy.Files}
            setFieldValue={setFieldValue}
          />
          <ButtonDelete addClass="editBlock_btn__clean" onClick={() => remove(index)} />
        </div>
      </div>
    );
  })
);

export default PoliciesForm;

import React from "react";
import {useTranslation} from "react-i18next";

export const ContractSignatures = ({
  ownPersons,
  otherPersons,
  contractId,
  isSellerSide,
}) => {
  const {t} = useTranslation();

  return (
    <div className="contract-tab_done-signatures mt-4 pt-8">
      {!isSellerSide &&
        !!ownPersons &&
        ownPersons.map((person, index) => (
          <div className="df-column" key={`${index}-${person?.id}-own`}>
            <span className="contract-tab_done-signatures__title">
              {`${t("SIGNATURE_BY")} ${person?.id}, ${person?.personalNumber}`}
            </span>
            <span className="contract-tab_done-signatures__value">{person?.id}</span>
          </div>
        ))}
      {!!otherPersons &&
        otherPersons.map((person, index) => (
          <div className="df-column" key={`${index}-${person?.id}-other`}>
            <span className="contract-tab_done-signatures__title">
              {`${t("SIGNATURE_BY")} ${person?.name}, ${person?.personalNumber}`}
            </span>
            <span className="contract-tab_done-signatures__value">{person?.id}</span>
          </div>
        ))}

      {isSellerSide &&
        !!ownPersons &&
        ownPersons.map((person, index) => (
          <div className="df-column" key={`${index}-${person?.id}-sown`}>
            <span className="contract-tab_done-signatures__title">
              {`${t("SIGNATURE_BY")} ${person?.name}, ${person?.personalNumber}`}
            </span>
            <span className="contract-tab_done-signatures__value">{person?.id}</span>
          </div>
        ))}

      <span className="contract-tab_done-signatures__title">{t("CONTRACT_ID")}</span>
      <span className="contract-tab_done-signatures__value">{contractId}</span>
    </div>
  );
};

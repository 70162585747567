import React, {useRef, useState, useEffect} from "react";
import {Form, Formik} from "formik";
import {Switch} from "antd";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
// locale
import FormikSelect from "components/selects/FormikSelect";
import {showSuccess} from "helpers/notifications.helper";
import {FieldInput} from "components/Field";
import {steps} from "../../../constants/inviteOrganizationConsts";
import {MAIN_PAGE} from "constants/routes.const";
import {Button} from "components/buttons/Button";
import {schema, initialValues} from "./formAttrs";
// import EditSelect from "components/selects/EditSelect";
import {onboardingModalNames} from "constants/modal.conts";
import {TERMS_AND_CONDITIONS} from "constants/documents.consts";

const InviteOrganizationInfo = inject("store")(
  observer(({store: {auth, organization, nsi}}) => {
    const [loading, setLoading] = useState(false);
    const [ifChecked, setIfChecked] = useState(false);
    const [countries, setCountries] = useState([]);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const formRef = useRef();
    const current = organization.invitationStep;

    const prev = () => organization.setInvitationStep(0);

    const handleClickDone = () => formRef.current && formRef.current.handleSubmit();

    const handleSubmit = (values) => {
      setLoading(true);
      organization
        .invitationOnboarding(values)
        .then((data) => {
          if (data.company?.Organizations?.length) {
            auth.setToken(data.token);
            auth.setOrganizations(data.company?.Organizations);
            auth.updateAuthModalName(onboardingModalNames.joining);
          } else {
            auth.setToken(data.token);
            showSuccess(t("SUCCESSFULLY_SUBMITTED"));
            auth.checkToken().then(() => navigate(MAIN_PAGE));
          }
        })
        .finally(() => setLoading(false));
    };

    const soldCheckbox = (value) => setIfChecked(value);

    useEffect(() => {
      nsi.getCountries().then(setCountries);
    }, [nsi]);

    return (
      <div className="inviteOnboarding_form__wrapper">
        <h6 className="custom-title __uppercase">{t("COMPANY_INFO")}</h6>

        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({isValid, dirty, setFieldValue, values}) => (
            <Form className="settings-form">
              <FormikSelect
                label="LOGIN_ORG_COUNTRY"
                list={countries}
                name="CountryId"
                showSearch
                bigSelect
                fullWidth
                tooltip="LOGIN_ORG_COUNTRY_TOOLTIP"
                onChange={(id) => {
                  id === "752" &&
                    // eslint-disable-next-line
                    !values.companyNumber.match(/^[\d]{6}\-[\d]{4}$/) &&
                    setFieldValue("companyNumber", "");
                  setFieldValue("CountryId", id);
                }}
              />
              <FieldInput
                name="companyNumber"
                mask={values.CountryId === "752" ? "999999-9999" : undefined}
                maskChar={values.CountryId === "752" ? "x" : undefined}
                placeholder={values.CountryId === "752" ? "xxxxxx-xxxx" : ""}
                label={"LOGIN_ORG_NUMBER"}
                errorName="companyNumber"
                onChange={(e) => {
                  const value = e.target.value || "";
                  setFieldValue("companyNumber", value);
                }}
              />

              <FieldInput
                name="companyName"
                placeholder={"LOGIN_PLACEHOLDER_COMPANY_NAME"}
                label={"LOGIN_COMPANY_NAME"}
                errorName="companyName"
              />

              {/*<EditSelect
                name="subscription"
                list={subscriptionArray}
                label={t("LOGIN_SUBSCRIPTION")}
                addClass="subscription-select"
              /> */}

              <div className="custom-control custom-switch">
                <Switch className="custom-switch-check" onChange={soldCheckbox} />
                <p>
                  {t("LOGIN_AGREE_FIRST")}
                  <a href={TERMS_AND_CONDITIONS} target="__blank">
                    {t("LOGIN_AGREE_LINK")}
                  </a>
                  {t("LOGIN_AGREE_LAST")}
                </p>
              </div>
              <div className="inviteOnboarding_btn__wrapper">
                {current > 0 && (
                  <Button
                    style={{margin: "0 8px"}}
                    onClick={() => prev()}
                    disabled={loading}
                    className="inviteOnboarding_btn"
                    name={t("PREVIOUS")}
                  />
                )}
                {current === steps.length - 1 && (
                  <Button
                    type="primary"
                    onClick={() => handleClickDone()}
                    loading={loading}
                    disabled={!dirty || !isValid || !ifChecked}
                    className="inviteOnboarding_btn"
                    name={t("DONE")}
                  />
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  })
);

export default InviteOrganizationInfo;

import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
// locale
import {UserExistsOrganizationModal} from "components/modals/UserExistsOrganizationModal";
import {showSuccess} from "helpers/notifications.helper";
import {setStorage} from "helpers/storage";
import SectionFormField from "components/Settings/sections/components/SectionFormField";
import {ReactNumberInput} from "components/ReactNumberInput";
import {Button} from "components/buttons/Button";
import {schema, initialValues} from "./formAttrs";
// import {onboardingModalNames} from "constants/modal.conts";
import {MAIN_PAGE} from "constants/routes.const";

const InviteUserInfo = inject("store")(
  observer(({store: {organization, auth}}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [modalInfo, setModalInfo] = useState(null);
    const formRef = useRef();
    const navigate = useNavigate();
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    const email = query.get("email") ? query.get("email").replace(" ", "+") : null;
    const [isValidPhone, setIsValidPhone] = useState(false);

    const current = organization.invitationStep;

    const next = () => formRef.current && formRef.current.handleSubmit();

    const handleSubmit = (values) => {
      setLoading(true);
      organization
        .sendInviteVerificationCode(values, token)
        .then((data) => {
          if (data?.token) {
            showSuccess(t("NUMBER_SUBMITTED"));
            // если сразу его логинить, то он логинится под своей компанией! Верно?
            auth.setToken(data.token);
            navigate(MAIN_PAGE);
            // organization.setInvitationStep(current + 2);

            // if (data.company?.Organizations?.length) {
            // auth.setToken(data.token);
            // navigate(MAIN_PAGE);
            // auth.setOrganizations(data.company?.Organizations);
            // auth.updateAuthModalName(onboardingModalNames.joining);
            // } else {
            // auth.setToken(data.token);
            // showSuccess(t("SUCCESSFULLY_SUBMITTED"));
            // auth.checkToken().then(() => navigate(SAVE_SEARCH));
            // }
          } else {
            showSuccess(t("SUCCESSFULLY_SUBMITTED"));
            setStorage("invitation_user", JSON.stringify(values));
            organization.setInvitationStep(current + 1);
          }
        })
        .catch((error) => setModalInfo(error.response.data))
        .finally(() => setLoading(false));
    };

    const getInitialValues = () => ({
      ...initialValues,
      ...organization.invitationUserInfo,
      email,
      token,
    });

    return (
      <div className="inviteOnboarding_form__wrapper inviteOnboarding_form__one">
        <h6 className="custom-title __uppercase">{t("LOGIN_YOUR_INFO")}</h6>
        <Formik
          innerRef={formRef}
          initialValues={getInitialValues()}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({values, isValid, dirty, setFieldValue}) => {
            return (
              <Form className="settings-form">
                <SectionFormField
                  type="text"
                  name="firstName"
                  placeholder={t("LOGIN_PLACEHOLDER_FIRST_NAME")}
                  label={"LOGIN_PLACEHOLDER_FIRST_NAME"}
                  errorName="firstName"
                  className="input"
                  disabled={loading}
                />
                <SectionFormField
                  name="lastName"
                  placeholder={t("LOGIN_PLACEHOLDER_LAST_NAME")}
                  label={"LOGIN_LAST_NAME"}
                  errorName="lastName"
                  className="input"
                  disabled={loading}
                />
                <SectionFormField
                  type="email"
                  name="email"
                  placeholder={t("LOGIN_PLACEHOLDER_EMAIL")}
                  label="E-mail"
                  errorName="email"
                  className="input"
                />
                <div className="field">
                  <label className="label">{t("LOGIN_PHONE")}</label>
                  <ReactNumberInput
                    phone={values.mobilePhone}
                    setPhone={(value) => {
                      values.mobilePhone = value;
                      setFieldValue("mobilePhone", value);
                    }}
                    setIsValid={setIsValidPhone}
                  />
                  <p className="sms-extra-info mt-2">
                    {t("USE_A_NUMBER_THAT_CAN_RECIEVE_SMS")}
                  </p>
                </div>

                <div className="inviteOnboarding_btn__wrapper">
                  {!current && (
                    <Button
                      type="submit"
                      onClick={() => next()}
                      loading={loading}
                      disabled={!dirty || !isValid || !isValidPhone}
                      className="inviteOnboarding_btn"
                      name={t("NEXT")}
                    />
                  )}
                </div>
                {modalInfo && (
                  <UserExistsOrganizationModal
                    info={{
                      ...modalInfo,
                      mobilePhone: values.mobilePhone,
                      email: values.email,
                    }}
                    onClose={() => setModalInfo(null)}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  })
);

export default InviteUserInfo;

import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import {IconPhone, IconDocumentList} from "components/icons";
import {InitialsBadge} from "components/InitialsBadge";
import {RichField} from "components/RichField";
import {TitleIcon} from "components/TitleIcon";
import {getFullName} from "helpers/helper";

const RequestCard = inject("store")(
  observer(({store: {buyer, organization: organizationStore}}) => {
    const {currentRequest} = buyer;
    const {organizationMembers} = organizationStore;
    const [currentBuyer, setCurrentBuyer] = useState({});
    const {t} = useTranslation();

    const buyerFullName = getFullName(
      currentBuyer?.User?.firstName,
      currentBuyer?.User?.lastName
    );

    const buyerPhone = currentBuyer?.User?.mobilePhone ?? "none";

    useEffect(() => {
      setCurrentBuyer(
        [
          ...organizationMembers.owner,
          ...organizationMembers.admin,
          ...organizationMembers.member,
        ].find((val) => val.UserId === currentRequest?.ContactPersonId)
      );
    }, [organizationMembers, currentRequest, currentBuyer]);

    return (
      <div className="request-view-block">
        <TitleIcon
          title={t("DESCRIPTION")}
          addClass="ml-4 mt-4"
          icon={<IconDocumentList />}
        />
        <RichField
          data={currentRequest.description || "---"}
          viewMode
          importType="html"
        />
        <div className="request-view-block_contacts pt-8">
          <span className="request-view-block_contacts_title __uppercase mr-8">
            {t("CONTACT")}:
          </span>
          <InitialsBadge firstName={buyerFullName} addClass="mr-2" />
          <span className="mr-4">{buyerFullName || "Not found"}</span>
          <IconPhone height="15" width="15" />
          <span className="ml-2">{buyerPhone}</span>
        </div>
      </div>
    );
  })
);

export default RequestCard;

import React, {memo} from "react";

export const IconRocketUp = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 9C12.122 9 11 7.878 11 6.5C11 5.122 12.122 4 13.5 4C14.878 4 16 5.122 16 6.5C16 7.878 14.878 9 13.5 9ZM13.5 5C12.673 5 12 5.673 12 6.5C12 7.327 12.673 8 13.5 8C14.327 8 15 7.327 15 6.5C15 5.673 14.327 5 13.5 5Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M0.499906 20C0.369906 20 0.241906 19.949 0.145906 19.854C0.00890554 19.717 -0.0370945 19.512 0.0299055 19.33C1.56891 15.099 3.18691 12.953 4.83791 12.953C5.38291 12.953 5.89191 13.187 6.35291 13.647C7.08091 14.375 7.10691 15.1 7.00091 15.58C6.66691 17.088 4.53691 18.565 0.670906 19.971C0.614906 19.991 0.556906 20.001 0.499906 20.001V20ZM4.83891 13.952C4.34391 13.952 3.76091 14.386 3.15391 15.207C2.55691 16.014 1.95691 17.164 1.36591 18.634C2.70091 18.097 3.77391 17.552 4.56491 17.009C5.63191 16.276 5.94791 15.709 6.02491 15.363C6.10191 15.017 5.97791 14.686 5.64591 14.354C5.37591 14.084 5.11191 13.953 4.83791 13.953L4.83891 13.952Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M19.5 -7.08003e-06C16.905 -7.08003e-06 14.593 0.439993 12.626 1.30799C11.005 2.02399 9.61703 3.02799 8.50003 4.29399C8.29203 4.52999 8.09903 4.76899 7.92003 5.00899C7.05803 5.06599 6.17603 5.40899 5.29603 6.02899C4.55103 6.55299 3.80303 7.27899 3.07103 8.18599C1.84103 9.70999 1.08503 11.213 1.05303 11.276C0.944035 11.495 1.00903 11.761 1.20703 11.904C1.29503 11.968 1.39803 11.999 1.50003 11.999C1.62803 11.999 1.75603 11.95 1.85303 11.854C1.85803 11.849 2.33603 11.379 3.15103 10.918C3.82303 10.538 4.85903 10.091 6.11203 10.012C6.42903 10.823 7.33603 11.793 7.77103 12.228C8.20603 12.663 9.17603 13.57 9.98703 13.887C9.90803 15.14 9.46103 16.176 9.08103 16.848C8.61903 17.664 8.15003 18.141 8.14603 18.145C7.97303 18.318 7.95103 18.592 8.09403 18.791C8.19103 18.925 8.34303 18.999 8.50003 18.999C8.57604 18.999 8.65203 18.982 8.72303 18.946C8.78603 18.914 10.289 18.158 11.813 16.928C12.72 16.196 13.446 15.448 13.97 14.703C14.59 13.822 14.932 12.941 14.99 12.079C15.229 11.901 15.468 11.708 15.705 11.499C16.971 10.382 17.975 8.99399 18.691 7.37299C19.559 5.40699 19.999 3.09399 19.999 0.498993V-0.00100708H19.499L19.5 -7.08003e-06ZM3.10703 9.80799C4.06003 8.42499 5.51703 6.70999 7.15903 6.17099C6.51003 7.30799 6.17403 8.35199 6.05303 9.01399C4.89403 9.08499 3.89503 9.42199 3.10703 9.80799ZM10.193 16.89C10.579 16.102 10.916 15.104 10.986 13.946C11.647 13.825 12.692 13.489 13.828 12.841C13.288 14.48 11.574 15.937 10.192 16.89H10.193ZM15.044 10.75C12.964 12.585 10.81 13 10.5 13C10.498 13 10.293 12.996 9.74103 12.607C9.35603 12.336 8.90703 11.95 8.47903 11.522C8.05103 11.094 7.66503 10.645 7.39403 10.26C7.00503 9.70799 7.00103 9.50299 7.00103 9.50099C7.00103 9.19099 7.41603 7.03699 9.25103 4.95699C11.447 2.46899 14.808 1.10799 18.995 1.00699C18.894 5.19399 17.534 8.55499 15.045 10.751L15.044 10.75Z"
        fill={color || "#1BA2CC"}
      />
    </svg>
  );
});

import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
//local
import {IconLock} from "components/icons";
import {Logout} from "../components/Logout";
import SectionFormField from "../components/SectionFormField";
import {ButtonTransparent} from "../../../buttons/ButtonTransparent";
import {color} from "constants/color.consts";
import {schema} from "./formAttrs";

export const Account = inject("store")(
  observer(({store: {auth}}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);

    const handleSubmit = (values) => {
      setLoading(true);
      auth
        .updateUser(values)
        .then(auth.updateUserData)
        .finally(() => setLoading(false));
    };

    return (
      <div className="settings-account__wrapper">
        <Formik
          initialValues={{
            firstName: auth.user.firstName,
            lastName: auth.user.lastName,
            email: auth.user.email,
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          <div className="settings-account_form__wrapper">
            <Form className="account__form">
              <h4>{t("YOUR_INFO")}</h4>

              <div className="settings-account_form__number">
                <label>{t("ACCOUNT_MODAL_YOUR_NUMBER")}</label>
                <p>{t("ACCOUNT_MODAL_CHANGE_PHONE_TEXT")}</p>
                <div className="form_number__wrapper">
                  <input
                    name="mobilePhone"
                    type="text"
                    value={auth.user.mobilePhone}
                    disabled="disabled"
                  />
                  <IconLock color={color.black} />
                </div>
              </div>

              <SectionFormField
                label={t("ACCOUNT_MODAL_FIRST_NAME")}
                type="text"
                name="firstName"
                errorName="firstName"
                className={"input"}
              />
              <SectionFormField
                label={t("ACCOUNT_MODAL_LAST_NAME")}
                type="text"
                name="lastName"
                errorName="lastName"
                className={"input"}
              />
              <SectionFormField
                label={t("ACCOUNT_MODAL_E_MAIL")}
                type="text"
                name="email"
                errorName="email"
                className={"input"}
              />
              <ButtonTransparent
                type="submit"
                name={t("SAVE")}
                addClass="save-btn"
                loading={loading}
              />
            </Form>
          </div>
        </Formik>
        <Logout />
      </div>
    );
  })
);

import React from "react";
import {Select} from "formik-antd";
import {ErrorMessage} from "formik";
import {useTranslation} from "react-i18next";
//local
import {checkField} from "helpers/helper";
import {InitialsBadge} from "components/InitialsBadge";
import cn from "classnames";

const EditSelect = ({
  label,
  name,
  list,
  placeholder,
  onChange,
  ownValue,
  defaultValue,
  prefixIcon,
  placeholderPrefixIcon,
  placeholderColorBlue,
  initials,
  bottomElement,
  sorted,
  small,
  highlightIfEmpty,
  showPlaceholderArrow,
  addClass,
  ...props
}) => {
  const {t} = useTranslation();
  const {Option} = Select;

  const sortedList = sorted
    ? list?.sort((a, b) => (t(a.label) > t(b.label) ? 1 : -1))
    : list;
  const finalList = bottomElement
    ? [
        ...sortedList.filter((item) => item.value !== bottomElement),
        {value: bottomElement, label: bottomElement},
      ]
    : sortedList;

  const options = () =>
    finalList?.map((item, i) => (
      <Option key={i} value={item.value}>
        {prefixIcon ?? null}
        {initials && (
          <InitialsBadge
            firstName={item.label}
            isCurrentUser={item.value === ownValue}
            addClass="mr-3 ml-1"
          />
        )}
        {t(item.label)}
      </Option>
    ));

  return (
    <div className={cn("editBlock_select__item", {[addClass]: !!addClass})}>
      {label ? <label className="editBlock_field__label">{label}</label> : null}

      <Select
        className={cn("editBlock_select__field", {
          initials: !!initials,
          __small: !!small,
          "__placeholder-prefix-icon": !!placeholderPrefixIcon,
          "__placeholder-color-blue": !!placeholderColorBlue,
          "bg-yellow": !!highlightIfEmpty,
          "show-placeholder-arrow": !!showPlaceholderArrow,
        })}
        placeholder={
          <React.Fragment>
            {placeholderPrefixIcon}
            {t(placeholder)}
          </React.Fragment>
        }
        name={name}
        onChange={onChange}
        defaultValue={defaultValue}
        popupClassName={cn("editBlock_select_dropdown", {initials: !!initials})}
        {...props}
      >
        {options()}
      </Select>
      {name && (
        <ErrorMessage name={name}>
          {(msg) => <p className="form-error">{checkField(msg)}</p>}
        </ErrorMessage>
      )}
    </div>
  );
};

export default EditSelect;

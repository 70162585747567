import React from "react";
import {useTranslation} from "react-i18next";
// local
import {dateTimeFormat, dateFormat} from "helpers/datetime";
import HistoryInfo from "components/HistoryInfo";
import {historyOfferAmountTableColumns} from "constants/table.consts";

const OurOrderTopInfo = ({request, language}) => {
  const {t} = useTranslation();

  const createdAt = request?.Offer?.createdAt;
  const validUntil = request?.Offer?.validUntil;
  const offerPrice = request?.Offer?.price
    ? request.Offer.price.toLocaleString(language ?? "sv")
    : 0;

  return (
    <div className="current-our-offer_info __wrapper">
      <div className="current-our-offer_info __item">
        <h6>
          {t("TOTAL_PRICE")}
          {request?.Request?.CurrencyCode}
        </h6>
        <div>
          <span>{offerPrice}</span>
          <span className="currency">{request?.Request?.CurrencyCode}</span>
          <HistoryInfo
            data={request?.Offer?.OfferPriceHistoryEvents}
            columns={historyOfferAmountTableColumns}
            showAmount
          />
        </div>
      </div>
      <div className="current-our-offer_info __item">
        <h6>{t("SUBMITTED")}</h6>
        {dateTimeFormat(createdAt)}
      </div>
      <div className="current-our-offer_info __item">
        <h6>{t("VALID_UNTIL")}</h6>
        <p>{dateFormat(validUntil)}</p>
      </div>
    </div>
  );
};

export default OurOrderTopInfo;

import React from "react";

export const IconLinkGbj = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2" fill="#EFEFEF" />
      <path
        d="M8.13483 9.51416C7.61624 9.68753 6.66038 9.90155 5.76567 9.90155C5.65818 9.90155 5.55326 9.89961 5.45134 9.8951V8.40016C5.55927 8.41565 5.67105 8.42361 5.78605 8.42361C6.04009 8.42361 6.27396 8.40296 6.36558 8.36231V7.17991H5.45049V5.75273H8.13483V9.51416Z"
        fill="black"
      />
      <path
        d="M10.3262 2.9205V9.90991C9.62935 9.90023 9.11098 9.85291 8.78549 9.78946V3.06139C9.10003 2.99987 9.69222 2.94416 10.3262 2.9205Z"
        fill="black"
      />
      <path
        d="M15.9655 9.91878C15.8128 9.9347 15.6525 9.94244 15.4849 9.94244C15.1088 9.94244 14.6106 9.89125 14.275 9.77918L14.4681 8.30103C14.6919 8.36233 14.9766 8.41331 15.2715 8.41331C15.5577 8.41331 15.796 8.35071 15.9655 8.17821V9.91878Z"
        fill="black"
      />
      <path
        d="M16.1835 2.95954H18.0156V7.20021C18.0156 8.86227 17.3541 9.65663 16.1835 9.87689V2.95954Z"
        fill="black"
      />
      <path
        d="M13.3498 9.30006C12.8436 9.68574 11.9955 9.90944 10.5442 9.9118V8.54722C10.6744 8.56615 10.8345 8.56615 11.0619 8.56615C11.6719 8.56615 12.1905 8.32158 12.1905 7.70984C12.1905 7.12864 11.6618 6.90451 10.9703 6.90451H10.5442V5.59972H10.8991C11.682 5.59972 12.0583 5.3244 12.0583 4.88603C12.0583 4.48852 11.7532 4.23363 11.1329 4.23363C10.8371 4.23363 10.6656 4.25299 10.5442 4.27257V2.91207C10.656 2.9097 10.768 2.90841 10.8787 2.90841C11.8549 2.90841 12.4448 2.97982 12.9734 3.24483C13.5124 3.49972 13.8988 3.98907 13.8988 4.66168C13.8988 5.23255 13.5836 5.79331 12.7803 6.09918V6.11962C13.6141 6.35407 14.1226 6.97592 14.1226 7.79136C14.1226 8.44376 13.8074 8.95354 13.3498 9.30006Z"
        fill="black"
      />
      <path
        d="M5.45135 2.90662C5.60434 2.89436 5.7601 2.88813 5.91823 2.88813C6.84341 2.88813 7.54502 3.05117 7.90097 3.22454L7.53493 4.7027C7.14851 4.52933 6.68078 4.40694 5.90794 4.40694C5.75131 4.40694 5.59854 4.41834 5.45135 4.44178V2.90662Z"
        fill="black"
      />
      <path
        d="M5.23344 9.87665C4.21407 9.78889 3.48285 9.47183 2.96062 8.97388C2.31994 8.36214 1.98438 7.47529 1.98438 6.4968C1.99339 4.38131 3.38201 3.18794 5.23344 2.93778V4.50564C4.46703 4.7259 3.91648 5.32323 3.91648 6.39485C3.91648 7.42517 4.44644 8.10789 5.23344 8.33762V9.87665Z"
        fill="black"
      />
      <path
        d="M3.08749 14.0708C3.08749 14.1414 3.09419 14.2053 3.10833 14.262C3.17927 14.5457 3.42684 14.7725 3.7379 14.7725C4.19755 14.7725 4.48034 14.4182 4.48034 13.7942C4.48034 13.2553 4.23997 12.8301 3.7379 12.8301C3.44792 12.8301 3.17927 13.0499 3.10833 13.3618C3.09419 13.4254 3.08749 13.4893 3.08749 13.5602V14.0708ZM2.01241 10.5047H3.08749V12.4896H3.10113C3.30653 12.192 3.6672 12.0005 4.14819 12.0005C4.97546 12.0005 5.57651 12.6881 5.56956 13.7517C5.56956 14.9996 4.78446 15.6233 3.99266 15.6233C3.58931 15.6233 3.19341 15.4743 2.95329 15.063H2.93866L2.89649 15.5452H1.98438C1.99827 15.3184 2.01241 14.9001 2.01241 14.5102V10.5047Z"
        fill="#86A315"
      />
      <path
        d="M6.70108 12.0784L7.21729 13.7871C7.27385 13.9855 7.3443 14.2479 7.38671 14.4321H7.4083C7.45791 14.2409 7.51422 13.9855 7.56383 13.7798L7.98801 12.0784H9.14074L8.33479 14.3613C7.83942 15.7437 7.50727 16.2898 7.11807 16.6372C6.75069 16.963 6.35454 17.0836 6.0787 17.1119L5.85247 16.2045C5.98667 16.1761 6.15634 16.1125 6.31907 16.0202C6.4818 15.9352 6.66561 15.7581 6.76458 15.5878C6.79981 15.5311 6.82114 15.4744 6.82114 15.4318C6.82114 15.3893 6.8142 15.3396 6.77872 15.2545L5.50594 12.0784H6.70108Z"
        fill="#86A315"
      />
      <path
        d="M11.4461 13.5672C11.4461 13.5033 11.4389 13.4324 11.425 13.3685C11.3471 13.085 11.1419 12.8935 10.845 12.8935C10.4489 12.8935 10.1378 13.2482 10.1378 13.8791C10.1378 14.3966 10.3923 14.8007 10.8378 14.8007C11.1209 14.8007 11.3401 14.6162 11.4106 14.3682C11.4389 14.2832 11.4461 14.1698 11.4461 14.0775V13.5672ZM12.5212 15.1198C12.5212 15.7862 12.3867 16.3321 11.9908 16.6795C11.6157 17.0055 11.0995 17.1047 10.5905 17.1047C10.1306 17.1047 9.65707 17.0127 9.34601 16.8426L9.55785 16.02C9.77713 16.1478 10.1517 16.2823 10.5622 16.2823C11.0715 16.2823 11.4602 16.013 11.4602 15.3679V15.1481H11.4461C11.2412 15.4318 10.9085 15.5947 10.5126 15.5947C9.65707 15.5947 9.04858 14.8999 9.04858 13.9074C9.04858 12.7871 9.76299 12.0784 10.6188 12.0784C11.0926 12.0784 11.3895 12.284 11.5661 12.5745H11.5803L11.6157 12.1562H12.5492C12.5353 12.3832 12.5212 12.674 12.5212 13.1915V15.1198Z"
        fill="#86A315"
      />
      <path
        d="M15.2367 13.5672C15.2367 13.5033 15.2295 13.4324 15.2154 13.3685C15.1375 13.085 14.9326 12.8935 14.6354 12.8935C14.2395 12.8935 13.9282 13.2482 13.9282 13.8791C13.9282 14.3966 14.183 14.8007 14.6285 14.8007C14.9113 14.8007 15.1308 14.6162 15.2012 14.3682C15.2295 14.2832 15.2367 14.1698 15.2367 14.0775V13.5672ZM16.3116 15.1198C16.3116 15.7862 16.1771 16.3321 15.7812 16.6795C15.4061 17.0055 14.8902 17.1047 14.3809 17.1047C13.9213 17.1047 13.4475 17.0127 13.1364 16.8426L13.3485 16.02C13.5678 16.1478 13.9423 16.2823 14.3526 16.2823C14.8619 16.2823 15.2509 16.013 15.2509 15.3679V15.1481H15.2367C15.0318 15.4318 14.6994 15.5947 14.303 15.5947C13.4475 15.5947 12.8392 14.8999 12.8392 13.9074C12.8392 12.7871 13.5534 12.0784 14.4094 12.0784C14.883 12.0784 15.1802 12.284 15.3568 12.5745H15.3709L15.4061 12.1562H16.3398C16.3257 12.3832 16.3116 12.674 16.3116 13.1915V15.1198Z"
        fill="#86A315"
      />
      <path
        d="M17.3722 15.7012C17.0115 15.7012 16.7498 15.4247 16.7498 15.0487C16.7498 14.659 17.0115 14.3894 17.3863 14.3894C17.7542 14.3894 18.0087 14.652 18.0156 15.0487C18.0156 15.4247 17.7611 15.7012 17.3794 15.7012H17.3722Z"
        fill="#86A315"
      />
    </svg>
  );
};

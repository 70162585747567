import React, {useState} from "react";
import {useTranslation} from "react-i18next";
// local
import {Modal} from "components/Modal";
import ButtonDelete from "components/buttons/ButtonDelete";
import {Input} from "components/Input";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";

export const DepartmentLabelModal = ({
  department,
  loading,
  onSave,
  onRemove,
  onClose,
}) => {
  const [name, setName] = useState(department?.name ?? "");
  const {t} = useTranslation();

  return (
    <Modal
      noTitleHeader
      width={320}
      rounded
      style={{marginTop: "20vh"}}
      onClose={onClose}
    >
      <div className="procurement-create-edit-modal__content">
        <div className="df-row-center">
          <Input
            label={t("NAME")}
            fullWidth
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={t("DEPARTMENT_NAME_PLACEHOLDER")}
          />
          {!!department?.id && (
            <ButtonDelete addClass="mt-6 ml-1" onClick={onRemove} activeOnHover />
          )}
        </div>
        <div className="df-row-jce-center mt-8">
          <ButtonTransparent
            name={"CANCEL"}
            color="red"
            onClick={onClose}
            disabled={loading}
            addClass="mr-4"
          />
          <ButtonTransparent
            loading={loading}
            disabled={!name}
            nonTransparent
            name={"SAVE"}
            onClick={() => onSave(name)}
          />
        </div>
      </div>
    </Modal>
  );
};

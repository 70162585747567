import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {DatePicker} from "antd";
import {InputNumber} from "antd";
//local
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {CustomTooltip} from "components/CustomTooltip";
import {IconCalendar, IconTrashCan} from "components/icons";
import {getWeek} from "helpers/datetime";
import {oneWeek} from "constants/user.consts";
import moment from "moment";
import {color} from "constants/color.consts";

export const ProcurementTimelineDateModal = ({
  data,
  datesSteps,
  titleName,
  onSave,
  onClose,
}) => {
  const {t} = useTranslation();

  const stepTooltip = useMemo(
    () => t(data.tooltip)[0] + t(data.tooltip).toLowerCase().slice(1),
    [data, t]
  );
  const dateIndex = useMemo(
    () => datesSteps.findIndex((item) => item.key === data.key),
    [datesSteps, data]
  );
  const [quickSelectValue, setQuickSelectValue] = useState(1);
  const isShowQuickSelect = useMemo(
    () => !!datesSteps.filter((item, i) => i > dateIndex && !!item.value).length,
    [dateIndex, datesSteps]
  );

  return (
    <Modal
      onClose={onClose}
      width={"min"}
      noCenterContent
      boldTitle
      titleIcon={<IconCalendar addClass="mr-2" />}
      titleText={`${titleName} - ${stepTooltip}`}
      addClass="procurement-timeline-date-modal__wrapper"
    >
      <div>
        <DatePicker
          open={true}
          className="vhidden"
          picker="week"
          defaultValue={data.value ? moment(data.value) : undefined}
          onChange={(value) => onSave({[data.key]: value})}
        />
        <div style={{marginLeft: "360px", width: isShowQuickSelect ? "460px" : "min"}}>
          {isShowQuickSelect && (
            <React.Fragment>
              <div className="__bold mb-2">
                {t("PROCUREMENT_TIMELINE_DATE_MODAL_INPUT_TITLE")}
              </div>
              <div className="df-row-center">
                <span className="pretext">
                  {t("PROCUREMENT_TIMELINE_DATE_MODAL_INPUT_QUICK_SELECT_PRETEXT")}{" "}
                  <span style={{fontWeight: "bold"}}>{stepTooltip}</span>
                </span>
                <InputNumber
                  defaultValue={quickSelectValue}
                  controls
                  min={1}
                  max={53}
                  onChange={setQuickSelectValue}
                />
                <div className="ml-2">
                  {t("PROCUREMENT_TIMELINE_DATE_MODAL_INPUT_LABEL")}
                </div>
              </div>
              <div className="df-row-center mt-4">
                {datesSteps.map((date, index, array) => (
                  <CustomTooltip
                    text={date.tooltip}
                    placement="bottom"
                    color={
                      date.titleColor === "white" ? undefined : color[date.titleColor]
                    }
                  >
                    <div
                      className={`procurement-timeline-date-modal__timeline-step ${
                        array.map((item) => item.key).indexOf(data.key) >= index
                          ? "__disabled"
                          : ""
                      } ${!!date.value ? "cpointer clickable" : ""}`}
                      key={index}
                      onClick={() =>
                        !!date.value
                          ? onSave({
                              [data.key]: new Date(
                                new Date(date.value) - oneWeek * quickSelectValue
                              ),
                            })
                          : undefined
                      }
                    >
                      {date.icon}
                      {date.value
                        ? `${t("WEEK_ABBREVIATED")} ${getWeek(date.value)}`
                        : ""}
                    </div>
                  </CustomTooltip>
                ))}
              </div>
            </React.Fragment>
          )}

          <ButtonTransparent
            onClick={() => onSave({[data.key]: null})}
            icon={<IconTrashCan />}
            addClass={isShowQuickSelect ? "mt-14" : ""}
            disabled={!data.value}
            name="PROCUREMENT_TIMELINE_DATE_MODAL_BUTTON"
            color="red"
            nowrap
            small
          />
        </div>
      </div>
    </Modal>
  );
};

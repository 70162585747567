import React, {memo} from "react";

export const IconTabletPen = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 20H2.5C1.673 20 1 19.327 1 18.5V4.5C1 3.673 1.673 3 2.5 3H3.5C3.776 3 4 3.224 4 3.5C4 3.776 3.776 4 3.5 4H2.5C2.224 4 2 4.224 2 4.5V18.5C2 18.776 2.224 19 2.5 19H16.5C16.776 19 17 18.776 17 18.5V4.5C17 4.224 16.776 4 16.5 4H15.5C15.224 4 15 3.776 15 3.5C15 3.224 15.224 3 15.5 3H16.5C17.327 3 18 3.673 18 4.5V18.5C18 19.327 17.327 20 16.5 20Z"
        fill={color || "#000"}
      />
      <path
        d="M13.501 5C13.501 5 13.501 5 13.5 5H5.5C5.224 5 5 4.776 5 4.5C5 3.495 5.453 2.714 6.276 2.303C6.551 2.165 6.823 2.09 7.04 2.049C7.253 0.885 8.275 0 9.499 0C10.723 0 11.745 0.885 11.958 2.049C12.176 2.09 12.447 2.165 12.722 2.303C13.538 2.711 13.99 3.481 13.998 4.473C13.999 4.482 13.999 4.491 13.999 4.5C13.999 4.776 13.775 5 13.499 5H13.501ZM6.06 4H12.94C12.844 3.644 12.633 3.383 12.302 3.21C11.913 3.007 11.502 3 11.497 3C11.221 3 11 2.776 11 2.5C11 1.673 10.327 1 9.5 1C8.673 1 8 1.673 8 2.5C8 2.776 7.776 3 7.5 3C7.499 3 7.087 3.007 6.698 3.21C6.367 3.383 6.156 3.643 6.06 4Z"
        fill={color || "#000"}
      />
      <path
        d="M9.5 3C9.368 3 9.239 2.947 9.147 2.853C9.055 2.759 9 2.631 9 2.5C9 2.369 9.053 2.239 9.147 2.147C9.24 2.054 9.369 2 9.5 2C9.631 2 9.761 2.053 9.853 2.147C9.946 2.24 10 2.369 10 2.5C10 2.631 9.947 2.76 9.853 2.853C9.76 2.946 9.631 3 9.5 3Z"
        fill={color || "#000"}
      />
      <path
        d="M4.99978 17C4.86878 17 4.74078 16.948 4.64578 16.854C4.52778 16.736 4.47578 16.566 4.50878 16.402L5.00878 13.902C5.02778 13.805 5.07578 13.716 5.14578 13.646L11.6458 7.14601C11.8408 6.95101 12.1578 6.95101 12.3528 7.14601L14.3528 9.14601C14.5478 9.34101 14.5478 9.65801 14.3528 9.85301L7.85278 16.353C7.78278 16.423 7.69378 16.47 7.59678 16.49L5.09678 16.99C5.06378 16.997 5.03178 17 4.99878 17H4.99978ZM5.96078 14.247L5.63778 15.863L7.25378 15.54L13.2928 9.50101L11.9998 8.20801L5.96078 14.247Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

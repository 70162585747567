import React, {memo} from "react";

export const IconChevronUp = memo(({color}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-7.08003e-06 15.0701C-7.08003e-06 15.1981 0.0489929 15.3261 0.145993 15.4241C0.340993 15.6191 0.657993 15.6191 0.852993 15.4241L9.49899 6.77805L18.145 15.4241C18.34 15.6191 18.657 15.6191 18.852 15.4241C19.047 15.2291 19.047 14.9121 18.852 14.7171L9.85199 5.71705C9.65699 5.52205 9.33999 5.52205 9.14499 5.71705L0.144993 14.7171C0.0469929 14.8151 -0.00100708 14.9431 -0.00100708 15.0711L-7.08003e-06 15.0701Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

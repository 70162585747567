import React, {memo, useState} from "react";
import {debounce} from "lodash";
//local
import {formattedNumber} from "helpers/number.formats";
import cn from "classnames";

export const Input = memo(
  ({
    name,
    label,
    addClass,
    onChange,
    onDebounceChange,
    onSubmit,
    value,
    boldText,
    uppercaseText,
    defaultValue,
    type,
    focus,
    small,
    tiny,
    onKeyUp,
    onKeyDown,
    maxLength,
    width,
    fullWidth,
    flatView,
    alignRight,
    placeholder,
    pattern,
    autoFocus,
    disabled,
    readOnly,
    fontSize,
    realNumbers, // allows you to enter only natural numbers and substitute 0 instead of an empty string
    digitsDivider,
    customHeight,
    ...props
  }) => {
    const dependsOnType = (value) =>
      realNumbers
        ? +value
            .replace(/^[0-]+/, "")
            .replaceAll(" ", "")
            .replace(/\D+/, "")
        : value;

    const [innerValue, setInnerValue] = useState(
      digitsDivider
        ? formattedNumber(dependsOnType(defaultValue)).toString().trim()
        : dependsOnType(defaultValue)
    );
    const [currentKey, setCurrentKey] = useState(null);

    const changeDigitsValue = (target, value, isSpaceBackspace, isSpaceDelete) => {
      setInnerValue(digitsDivider ? formattedNumber(value).toString().trim() : value);
      const difference =
        formattedNumber(value).toString().trim().length - target.value.length;

      const cursorValue = target.selectionStart + difference;
      const preciseValue = isSpaceBackspace
        ? cursorValue - 1
        : isSpaceDelete
        ? cursorValue + 1
        : cursorValue;
      const isNullValue = target.selectionStart === 0 ? 0 : preciseValue;
      setTimeout(() => target.setSelectionRange(isNullValue, isNullValue), 1);
    };

    const handleChange = (e) => {
      const backspace = "Backspace";
      const deleteButton = "Delete";

      const outerValue = dependsOnType(e.target?.value);
      if (!onDebounceChange) {
        if (realNumbers) {
          const isSpaceBackspace =
            currentKey === backspace &&
            e.target.value.length &&
            innerValue[e.target.selectionStart] === " ";
          const isSpaceDelete =
            currentKey === deleteButton &&
            e.target.value.length &&
            innerValue[e.target.selectionStart] === " ";

          const value = dependsOnType(
            isSpaceBackspace
              ? `${innerValue.slice(0, e.target.selectionStart - 1)}${innerValue.slice(
                  e.target.selectionStart
                )}`
              : isSpaceDelete
              ? `${innerValue.slice(0, e.target.selectionStart)}${innerValue.slice(
                  e.target.selectionStart + 2
                )}`
              : e.target?.value
          );
          changeDigitsValue(e.target, value, isSpaceBackspace, isSpaceDelete);
          onChange(value);
        } else onChange(e);
      } else {
        if (realNumbers) handleDebounceChange(outerValue);
        else onChange(e);
      }
    };

    const onCustomKeyDown = (e) => setCurrentKey(e.key);

    const handleDebounceChange = debounce((value) => onDebounceChange(value), 800);

    return (
      <div
        className={cn("form standard-input", {
          __small: !!small,
          __tiny: !!tiny,
          __bold: !!boldText,
          __uppercase: !!uppercaseText,
          "__flat-view": !!flatView,
          "__full-width": !!fullWidth,
        })}
      >
        {label ? <label className="label">{label}</label> : null}
        <input
          className={cn("input", {[addClass]: !!addClass}, {disabled: !!disabled})}
          onChange={handleChange}
          onSubmit={onSubmit}
          onKeyUp={onKeyUp}
          onKeyDown={digitsDivider ? onCustomKeyDown : onKeyDown}
          value={realNumbers && !onDebounceChange ? innerValue : value}
          defaultValue={defaultValue}
          type={type}
          ref={focus}
          autoFocus={autoFocus}
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          pattern={pattern}
          disabled={disabled}
          readOnly={readOnly}
          style={{
            fontSize: fontSize && `${fontSize}px`,
            height: customHeight && `${customHeight}px`,
            width: width,
            textAlign: alignRight && "right",
          }}
          {...props}
        />
      </div>
    );
  }
);

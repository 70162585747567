import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
//local
import {showSuccess} from "helpers/notifications.helper";
import {schema, initialValues} from "./formAttrs";
import SimpleField from "components/SimpleField";
import {RadioGroup} from "components/RadioGroup";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {EmailExistsCompanyModal} from "pages/OurRequests/components/RequestEdit/EmailExistsCompanyModal";
import {EmailExistsCompaniesModal} from "pages/OurRequests/components/RequestEdit/EmailExistsCompaniesModal";
import {IconPlus} from "components/icons";
import {color} from "constants/color.consts";

const InviteForm = ({buyerStore, organizationStore}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isEmailExistsCompanyModal, setIsEmailExistsCompanyModal] = useState(null);
  const [isEmailExistsCompaniesModal, setIsEmailExistsCompaniesModal] = useState(null);

  const radioValues = [
    {
      value: "invitingSupplier",
      label: "INVITE_COMPANIES_RADIO_LABEL_01",
      checked: true,
      disabled: loading,
    },
    {
      value: "invitingCustomer",
      label: "INVITE_COMPANIES_RADIO_LABEL_02",
      checked: false,
      disabled: loading,
      className: "ml-8",
    },
  ];

  const handlerOnSubmit = (values, {resetForm}) => {
    setLoading(true);
    buyerStore
      .checkEmailRecepient(values.email.trim())
      .then(async (data) => {
        setLoading(false);
        if (data.status === "Not found")
          organizationStore
            .sendInvite({
              ...values,
              email: values.email.trim(),
              message: values.message ? values.message : t("DIRECT_INVITE_EMPTY_MESSAGE"),
              invitingCustomer: isCustomer,
            })
            .then(() => showSuccess("Successfully submitted"))
            .finally(() => {
              resetForm();
              setLoading(false);
            });
        if (data.status === "Several organizations found")
          setIsEmailExistsCompaniesModal(values.email.trim());
        if (data.id) {
          await organizationStore.getBlackList();
          await organizationStore.getFavorites();
          setIsEmailExistsCompanyModal({
            email: values.email.trim(),
            id: data.id,
            organization: data.name,
            company: data.Company?.companyName,
            membersCount: data.membersCount ?? "--",
            actionText: "EMAIL_EXISTS_MODAL_ACTION_3",
            alreadyFavorite: organizationStore.favoritesOrganization
              .map((item) => item.id)
              .includes(data.id),
            alreadyBlacklist: organizationStore.blacklistOrganization
              .map((item) => item.id)
              .includes(data.id),
          });
        } else return;
      })
      .finally(() => {
        resetForm();
        setLoading(false);
      });
  };

  const addToFavorites = () =>
    organizationStore.addToFavorite(isEmailExistsCompanyModal.id);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handlerOnSubmit}
      validationSchema={schema}
    >
      <Form>
        <div className="invite-suppliers_form">
          <RadioGroup
            values={radioValues}
            direction="horizontal"
            boldLabels
            resultValue={(value) => setIsCustomer(value === "invitingCustomer")}
          />
          <div className="invite-suppliers_form__item">
            <SimpleField
              placeholder={t("WHERE_DO_WE_SEND_THIS_INVITATION")}
              label={t("E_MAIL")}
              name="email"
              errorName="email"
              className="input"
              disabled={loading}
            />
            <SimpleField
              placeholder={t("WHICH_COMPANY_ARE_YOU_INVITING")}
              label={t("COMPANY")}
              name="companyName"
              errorName="companyName"
              className="input"
              disabled={loading}
            />
          </div>
          <div className="invite-suppliers_form__item">
            <SimpleField
              placeholder={t("PERSONAL_MESSAGE_PLACEHOLDER")}
              label={t("PERSONAL_MESSAGE")}
              as="textarea"
              name="message"
              errorName="message"
              className="input"
              disabled={loading}
              maxLength={1000}
            />
          </div>
          <div className="btn-custom_wrap">
            <ButtonTransparent
              loading={loading}
              name={t("SEND_INVITES")}
              type="submit"
              nonTransparent={true}
              icon={<IconPlus color={color.white} />}
              addClass="invite-suppliers__btn-send"
            />
          </div>
        </div>
        {isEmailExistsCompanyModal && (
          <EmailExistsCompanyModal
            data={isEmailExistsCompanyModal}
            onAdd={addToFavorites}
            onClose={() => setIsEmailExistsCompanyModal(null)}
          />
        )}
        {isEmailExistsCompaniesModal && (
          <EmailExistsCompaniesModal
            email={isEmailExistsCompaniesModal}
            isFromCompanyInvite
            onClose={() => setIsEmailExistsCompaniesModal(null)}
          />
        )}
      </Form>
    </Formik>
  );
};

export default InviteForm;

import React, {useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
//local
import cn from "classnames";

const SubscriptionUpgrageCustomLocalModal = inject("store")(
  observer(
    ({
      store: {auth},
      canPermissions,
      prefixLinkCustomContent,
      suffixLinkCustomContent,
      needDirectPurchase,
      addClass,
      children,
    }) => {
      const navigate = useNavigate();
      const {subscribedPermissions} = auth;

      const permittedSubscription = useMemo(
        () =>
          !!canPermissions && subscribedPermissions[canPermissions[0]]
            ? subscribedPermissions[canPermissions[0]][0]
            : null,
        [canPermissions, subscribedPermissions]
      );

      return (
        <div
          className={cn("df-column", {
            [addClass]: !!addClass,
          })}
        >
          <div>{prefixLinkCustomContent}</div>
          <div
            onClick={() =>
              needDirectPurchase
                ? navigate(
                    {hash: "#your-subscription"},
                    {state: {purchasedSubscription: permittedSubscription}}
                  )
                : navigate({hash: "#your-subscription"})
            }
          >
            {children}
          </div>

          <div>{suffixLinkCustomContent}</div>
        </div>
      );
    }
  )
);

export default SubscriptionUpgrageCustomLocalModal;

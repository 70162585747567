import React from "react";
import {useTranslation} from "react-i18next";
import {jobMaxSizeArray} from "constants/select.consts";
import {jobMinSizeArray} from "constants/select.consts";

const JobSizeMinMax = ({JobSizeMinMax, isMax, noTitle}) => {
  const {t} = useTranslation();
  const jobSizeMinMaxObj = isMax
    ? jobMaxSizeArray.find((item) => item.value === JobSizeMinMax)
    : jobMinSizeArray.find((item) => item.value === JobSizeMinMax);

  return (
    <div className={`edit-our-services_item ${isMax ? "__max" : "__min"}`}>
      {!noTitle && (
        <p className="edit-our-services_title">{t("FIRST_SETUP_SELECT_JOB_SIZE_MAX")}</p>
      )}
      <div className="edit-our-services_selected__wrapper">
        {JobSizeMinMax ? (
          <span className="edit-our-services_selected__item">
            {t(jobSizeMinMaxObj?.label)}
          </span>
        ) : (
          <span className="edit-our-services_selected__item">
            {t("FIRST_SETUP_SELECT_SIZE")}
          </span>
        )}
      </div>
    </div>
  );
};

export default JobSizeMinMax;

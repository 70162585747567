export const MAIN_PAGE = "/";
export const ORGANIZATIONS = "/organizations";
export const MAIN_SETTINGS = "/settings";
export const INVITE_COMPANIES = "/invite-company";
export const OUR_FAVOURITES = "/our-suppliers/:ourSuppliersTab";
export const OUR_FAVOURITES_SUPPLIERS = "/our-suppliers/suppliers";
export const OUR_FAVOURITES_MONITORED = "/our-suppliers/monitored";
export const OUR_FAVOURITES_QUALIFYING = "/our-suppliers/qualifying";
export const FRAMEWORK_AGREEMENTS = "/framework-agreements";
export const OUR_BLACKLIST = "/our-blacklist";
export const OUR_PROFILE = "/our-profile";
export const ORGANIZATION_ID = "/organizations/:organizationId";
export const REQUESTS = "/requests";
export const REQUEST = "/requests/:requestId";
export const REQUEST_OFFER = "/requests/:requestId/offers/:offerId";
export const CONTRACTS = "/contracts";
export const CONTRACT = "/contracts/:contractId";
export const PROJECTS = "/projects";
export const PROJECT = "/projects/:projectId";
export const REQUESTS_TO_US = "/requests-to-us";
export const REQUEST_TO_US = "/requests-to-us/:requestId";
export const OUR_OFFERS = "/our-offers";
export const OUR_QUALIFICATIONS = "/our-qualifications";
export const CONTRACTS_TO_US = "/contracts-to-us";
export const CONTRACT_TO_US = "/contracts-to-us/:contractId";
export const PUBLIC_RFTS = "/public-rfts";
export const PUBLIC_RFT = "/public-rfts/:publicRftId";
export const ONBOARDING = "/onboarding";
export const ID_IDENTIFY = "/identify-owner";
export const INVITE_ONBOARDING = "/invite-onboarding";
export const SAVE_SEARCH = "/save-search-preference";
export const JOINING_ORG = "/joining-organization";
// export const STRIPE_PAGE = "/stripe";
export const PRESENTATION_PAGE = "/presentation-page";
export const NEW_REQUEST = "/requests/draft-request";
export const toOrganizationId = (id) => `/organizations/${id}`;
export const toRequestId = (id) => `/requests/${id}`;
export const toRequestToUsId = (id) => `/requests-to-us/${id}`;
export const toContractId = (id) => `/contracts/${id}`;
export const toContractToUsId = (id) => `/contracts-to-us/${id}`;
export const toFeedId = (id) => `/feed/${id}`;
export const toProjectId = (id) => `/projects/${id}`;
export const toPublicRftId = (id) => `/public-rfts/${id}`;
export const ORGANIZATION_USER_INVITE = "/accept-organization-invite";
export const ORGANIZATION_USER_INVITE_CHOICE = "/accept-organization-invite/choice";
// PUBLUC ZONE
export const FEEDS = "/feed";
export const FEED = "/feed/:feedId";
export const RFP_OFFERS = "/public/requests/:requestId/offers";

import React, {useState, useEffect, useMemo} from "react";
import {useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
// local
import MessageCard from "../components/MessageCard";

export const Messages = inject("store")(
  observer(({store: {buyer}}) => {
    const {currentRequest} = buyer;
    const location = useLocation();
    const [viewIdMessage, setViewIdMessage] = useState(null);
    const [viewAllMessage, setViewAllMessage] = useState(false);

    const recipients = useMemo(
      () => [
        ...(currentRequest.recipients?.registered ?? []),
        ...(currentRequest.recipients?.public ?? []),
      ],
      [currentRequest.recipients]
    );

    const expandClick = (id) =>
      viewIdMessage === id ? setViewIdMessage(null) : setViewIdMessage(id);

    // setting open message panel if user click on specific notification
    useEffect(() => {
      setViewIdMessage(
        location?.state?.messageProposalId ||
          location?.search?.replace("?messageProposalId=", "")
      );
    }, [location]);

    return (
      <React.Fragment>
        <MessageCard
          expand={viewAllMessage}
          onExpandClick={() => setViewAllMessage(!viewAllMessage)}
        />
        {recipients.map((recipient) => (
          <MessageCard
            key={recipient.id}
            expand={viewIdMessage}
            user={recipient}
            onExpandClick={expandClick}
          />
        ))}
        {currentRequest.recipients?.notRegistered?.map((recipient) => (
          <MessageCard
            key={recipient.id ?? recipient.email}
            user={recipient}
            isRegistered={false}
          />
        ))}
      </React.Fragment>
    );
  })
);

import React, {useEffect, useState, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
//local
// import {VideoInstructionLink} from "components/VideoInstructionLink";
import {InfoTooltip} from "components/InfoTooltip";
import {Layout} from "components/UI/layout";
import {IconList} from "components/icons";
import QualificationsTableHead from "./components/QualificationsTableHead";
import {Preloader} from "components/Preloader";
import {defaultNameFieldSearch} from "constants/user.consts";
import QualificationsTableItem from "./components/QualificationsTableItem";
import {QualificationAnswersModal} from "components/modals/QualificationAnswersModal";
import EmptyData from "components/EmptyData";
import {hasUserPermission} from "helpers/helper";
import {roles} from "constants/auth.const";
import {changeFilterParams} from "helpers/helper";
import {color} from "constants/color.consts";

const OurQualificationsPage = inject("store")(
  observer(({store: {auth, clientStore}}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {clientRole} = auth;

    const [qualifications, setQualifications] = useState([]);
    const [qualificationFilterParams, setQualificationFilterParams] =
      useState(defaultNameFieldSearch);
    const [loading, setLoading] = useState(true);
    const [qualification, setQualification] = useState(null);
    const [loadingQualificationId, setLoadingQualificationId] = useState(null);

    const isAdminOwner = hasUserPermission([roles.ADMIN, roles.OWNER], clientRole);

    const getQualificationAnswersList = useCallback(() => {
      setLoading(true);
      clientStore
        .getQualificationAnswersList(qualificationFilterParams)
        .then(({data}) => setQualifications(data))
        .finally(() => setLoading(false));
    }, [clientStore, qualificationFilterParams]);

    const onChangeOrder = (value) => {
      const params = changeFilterParams(value);
      const filterParams = {...qualificationFilterParams, ...params};
      updateUrlParams(`?${new URLSearchParams(filterParams)}`);
      setQualificationFilterParams((state) => ({...state, ...params}));
    };

    const updateUrlParams = (search) => navigate({search});

    const handleShowQualificationAnswersModal = (id) => {
      setLoadingQualificationId(id);
      clientStore
        .getQualificationAnswers(id, "seller")
        .then(({data}) =>
          setQualification({
            ...data,
            questions: data.questions.map((question) => ({
              ...question,
              files: question.files?.map((file) => ({...file, ...file.File})),
            })),
            answers: data.answers
              ? data.answers.map((answer) => ({
                  ...answer,
                  files: answer.files?.map((file) => ({...file, ...file.File})),
                }))
              : [],
          })
        )
        .finally(() => setLoadingQualificationId(null));
    };

    useEffect(() => {
      getQualificationAnswersList();
    }, [getQualificationAnswersList]);

    return (
      <Layout>
        <div className="our-qualifications">
          <div className="content-block_header">
            <IconList color={color.black} />
            <h3 className="content-block_title __uppercase">
              {t("OUR_SUPPLIER_QUALIFICATIONS")}
            </h3>
            <InfoTooltip
              tooltip="OUR_SUPPLIER_QUALIFICATIONS_PAGE_INFO_TOOLTIP"
              centerText
              addIconWrapperClass="ml-4"
            />
          </div>
        </div>
        {loading && <Preloader addClass="preloader500" />}
        {!loading && (
          <div className="our-qualifications content-block_wrapper">
            <QualificationsTableHead
              onChangeOrder={onChangeOrder}
              qualificationFilterParams={qualificationFilterParams}
            />
            {qualifications.length ? (
              qualifications.map((qualification) => (
                <QualificationsTableItem
                  key={qualification.id}
                  qualification={qualification}
                  loadingId={loadingQualificationId}
                  showQualificationAnswers={handleShowQualificationAnswersModal}
                />
              ))
            ) : (
              <EmptyData addClass={`empty-data pt-4`} />
            )}
          </div>
        )}
        {qualification && (
          <QualificationAnswersModal
            initialQualification={qualification}
            isAdminOwner={isAdminOwner}
            onClose={({needUpdateAnswers}) => {
              needUpdateAnswers && getQualificationAnswersList();
              setQualification(null);
            }}
          />
        )}
      </Layout>
    );
  })
);

export default OurQualificationsPage;

import React, {memo} from "react";

export const IconDownOn = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.5" stroke={color || "#AC4DC4"} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9018 14.5608C19.6038 13.1933 20 11.6429 20 10C20 8.3571 19.6038 6.80673 18.9018 5.43922C18.4575 4.57186 17.8781 3.756 17.1638 3.02293C17.1024 2.95986 17.0401 2.89759 16.9771 2.83615C16.2433 2.12121 15.4267 1.54151 14.5585 1.09705C13.1915 0.395741 11.642 0 10 0C8.3634 0 6.81863 0.393152 5.45497 1.09016C4.54445 1.55453 3.6904 2.16745 2.92892 2.92893C2.16502 3.69283 1.55062 4.5499 1.08572 5.46367C0.391483 6.82519 0 8.36688 0 10C0 11.632 0.390965 13.1728 1.08435 14.5336C1.5494 15.4484 2.16426 16.3064 2.92892 17.0711C3.69036 17.8325 4.54438 18.4454 5.45485 18.9098C6.81853 19.6068 8.36335 20 10 20C12.7151 20 15.1776 18.9179 16.9795 17.1615C17.0417 17.1008 17.1032 17.0393 17.1638 16.9771C17.8781 16.244 18.4575 15.4281 18.9018 14.5608ZM18.9018 14.5608C20.364 11.7062 20.364 8.29382 18.9018 5.43922C18.4441 4.54774 17.8564 3.73396 17.1638 3.02293C17.1332 2.99144 17.1022 2.96011 17.0711 2.92893C17.0399 2.89776 17.0085 2.86683 16.9771 2.83615C16.2654 2.14295 15.4509 1.55488 14.5585 1.09705C11.7096 -0.361371 8.30559 -0.363665 5.45497 1.09016C3.57761 2.04975 2.04353 3.58525 1.08572 5.46367C-0.359458 8.30423 -0.359919 11.6927 1.08435 14.5336C2.04204 16.4132 3.57664 17.9497 5.45485 18.9098C9.17521 20.8072 13.8382 20.2245 16.9795 17.1615C17.0102 17.1316 17.0407 17.1014 17.0711 17.0711C17.1022 17.0399 17.1332 17.0086 17.1638 16.9771C17.8564 16.266 18.4441 15.4523 18.9018 14.5608ZM5.75733 8.75736L8.58576 11.5858L9.99997 13L11.4142 11.5858L14.2426 8.75736L12.8284 7.34315L9.99997 10.1716L7.17154 7.34315L5.75733 8.75736Z"
        fill={color || "#AC4DC4"}
      />
    </svg>
  );
});

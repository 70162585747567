import AuthStore from "./auth.store";
import OrganizationsStore from "./organizations.store";
import BuyerStore from "./buyer.store";
import SellerStore from "./seller.store";
import StripeStore from "./stripe.store";
import ClientStore from "./client.store";
import PublicStore from "./public.store";
import NsiStore from "./nsi.store";

class RootStore {
  constructor() {
    this.auth = AuthStore; // Authorization and user interaction
    this.organization = OrganizationsStore; // Working with the data of the user's organization
    this.buyer = BuyerStore; // Working with data on the buyer side (REQUEST OFFERS)
    this.seller = SellerStore; // Working with data on the seller side (MAKE OFFERS)
    this.stripe = StripeStore; // Working with paid places in the app
    this.clientStore = ClientStore; // Working with data outside of the procurement process
    this.publicStore = PublicStore; // Working with upcoming assignments
    this.nsi = NsiStore; // Working with references
  }
}

const rootStore = new RootStore();
export default rootStore;

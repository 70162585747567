import React, {memo} from "react";

export const IconUE2021 = memo(({height, width, color, addClass}) => {
  return (
    <svg
      width={height || "20"}
      height={width || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2Z"
        fill={color || "#000"}
      />
      <path
        d="M15.3932 14.0068C15.3428 14.0571 15.2887 14.0639 15.2311 14.0274C15.177 13.9909 15.15 13.9315 15.15 13.8493V13.3493C15.15 13.089 15.2329 12.8836 15.3986 12.7329L15.9446 12.226C16.1068 12.0753 16.2923 12 16.5013 12H16.8419C16.9248 12 16.9968 12.0388 17.0581 12.1164C17.1194 12.1941 17.15 12.2854 17.15 12.3904V16.6096C17.15 16.7146 17.1194 16.8059 17.0581 16.8836C16.9968 16.9612 16.9248 17 16.8419 17H16.5013C16.4185 17 16.3464 16.9612 16.2851 16.8836C16.2239 16.8059 16.1932 16.7146 16.1932 16.6096V13.2808H16.1824L15.3932 14.0068Z"
        fill="white"
      />
      <path
        d="M15.3932 14.0068C15.3428 14.0571 15.2887 14.0639 15.2311 14.0274C15.177 13.9909 15.15 13.9315 15.15 13.8493V13.3493C15.15 13.089 15.2329 12.8836 15.3986 12.7329L15.9446 12.226C16.1068 12.0753 16.2923 12 16.5013 12H16.8419C16.9248 12 16.9968 12.0388 17.0581 12.1164C17.1194 12.1941 17.15 12.2854 17.15 12.3904V16.6096C17.15 16.7146 17.1194 16.8059 17.0581 16.8836C16.9968 16.9612 16.9248 17 16.8419 17H16.5013C16.4185 17 16.3464 16.9612 16.2851 16.8836C16.2239 16.8059 16.1932 16.7146 16.1932 16.6096V13.2808H16.1824L15.3932 14.0068Z"
        fill="white"
      />
      <path
        d="M11.492 17C11.4 17 11.32 16.9617 11.252 16.8851C11.184 16.8086 11.15 16.7185 11.15 16.6149V16.4595C11.15 16.2072 11.24 16.0023 11.42 15.8446C12.092 15.25 12.536 14.7973 12.752 14.4865C12.972 14.1757 13.082 13.8716 13.082 13.5743C13.082 13.1599 12.878 12.9527 12.47 12.9527C12.222 12.9527 11.918 13.0293 11.558 13.1824C11.482 13.214 11.41 13.205 11.342 13.1554C11.274 13.1059 11.24 13.036 11.24 12.9459V12.6622C11.24 12.545 11.27 12.4414 11.33 12.3514C11.39 12.2568 11.47 12.1937 11.57 12.1622C11.934 12.0541 12.284 12 12.62 12C13.104 12 13.476 12.1306 13.736 12.3919C14 12.6486 14.132 13.0068 14.132 13.4662C14.132 13.8626 14.02 14.241 13.796 14.6014C13.576 14.9572 13.144 15.4414 12.5 16.0541C12.496 16.0586 12.494 16.0631 12.494 16.0676C12.494 16.0721 12.496 16.0743 12.5 16.0743H13.814C13.906 16.0743 13.984 16.1126 14.048 16.1892C14.116 16.2658 14.15 16.3559 14.15 16.4595V16.6149C14.15 16.7185 14.116 16.8086 14.048 16.8851C13.984 16.9617 13.906 17 13.814 17H11.492Z"
        fill="white"
      />
      <path
        d="M9.25689 13.2267C9.12816 12.9822 8.92586 12.86 8.64999 12.86C8.37413 12.86 8.16953 12.9822 8.0362 13.2267C7.90747 13.4711 7.8431 13.8956 7.8431 14.5C7.8431 15.1044 7.90747 15.5289 8.0362 15.7733C8.16953 16.0178 8.37413 16.14 8.64999 16.14C8.92586 16.14 9.12816 16.0178 9.25689 15.7733C9.39022 15.5289 9.45689 15.1044 9.45689 14.5C9.45689 13.8956 9.39022 13.4711 9.25689 13.2267ZM10.1534 16.4133C9.82701 16.8044 9.32586 17 8.64999 17C7.97413 17 7.47068 16.8044 7.13965 16.4133C6.81321 16.0222 6.64999 15.3844 6.64999 14.5C6.64999 13.6156 6.81321 12.9778 7.13965 12.5867C7.47068 12.1956 7.97413 12 8.64999 12C9.32586 12 9.82701 12.1956 10.1534 12.5867C10.4845 12.9778 10.65 13.6156 10.65 14.5C10.65 15.3844 10.4845 16.0222 10.1534 16.4133Z"
        fill="white"
      />
      <path
        d="M3.24199 17C3.14999 17 3.06999 16.9617 3.00199 16.8851C2.93399 16.8086 2.89999 16.7185 2.89999 16.6149V16.4595C2.89999 16.2072 2.98999 16.0023 3.16999 15.8446C3.84199 15.25 4.28599 14.7973 4.50199 14.4865C4.72199 14.1757 4.83199 13.8716 4.83199 13.5743C4.83199 13.1599 4.62799 12.9527 4.21999 12.9527C3.97199 12.9527 3.66799 13.0293 3.30799 13.1824C3.23199 13.214 3.15999 13.205 3.09199 13.1554C3.02399 13.1059 2.98999 13.036 2.98999 12.9459V12.6622C2.98999 12.545 3.01999 12.4414 3.07999 12.3514C3.13999 12.2568 3.21999 12.1937 3.31999 12.1622C3.68399 12.0541 4.03399 12 4.36999 12C4.85399 12 5.22599 12.1306 5.48599 12.3919C5.74999 12.6486 5.88199 13.0068 5.88199 13.4662C5.88199 13.8626 5.76999 14.241 5.54599 14.6014C5.32599 14.9572 4.89399 15.4414 4.24999 16.0541C4.24599 16.0586 4.24399 16.0631 4.24399 16.0676C4.24399 16.0721 4.24599 16.0743 4.24999 16.0743H5.56399C5.65599 16.0743 5.73399 16.1126 5.79799 16.1892C5.86599 16.2658 5.89999 16.3559 5.89999 16.4595V16.6149C5.89999 16.7185 5.86599 16.8086 5.79799 16.8851C5.73399 16.9617 5.65599 17 5.56399 17H3.24199Z"
        fill="white"
      />
      <path
        d="M11.8459 9.90541C11.6964 9.90541 11.5664 9.8518 11.456 9.74459C11.352 9.63739 11.3 9.51126 11.3 9.36622V3.53919C11.3 3.39414 11.352 3.26802 11.456 3.16081C11.5664 3.0536 11.6964 3 11.8459 3H15.2969C15.4463 3 15.5731 3.0536 15.677 3.16081C15.7875 3.26802 15.8428 3.39414 15.8428 3.53919V3.75676C15.8428 3.9018 15.7875 4.02793 15.677 4.13514C15.5731 4.24234 15.4463 4.29595 15.2969 4.29595H13.0352C12.9832 4.29595 12.9572 4.32117 12.9572 4.37162V5.62027C12.9572 5.67703 12.9832 5.70541 13.0352 5.70541H15.1019C15.2514 5.70541 15.3781 5.75901 15.4821 5.86622C15.5926 5.97342 15.6478 6.09955 15.6478 6.24459V6.38649C15.6478 6.53153 15.5926 6.65766 15.4821 6.76486C15.3781 6.87207 15.2514 6.92568 15.1019 6.92568H13.0352C12.9832 6.92568 12.9572 6.9509 12.9572 7.00135V8.53378C12.9572 8.58423 12.9832 8.60946 13.0352 8.60946H15.2969C15.4463 8.60946 15.5731 8.66306 15.677 8.77027C15.7875 8.87748 15.8428 9.0036 15.8428 9.14865V9.36622C15.8428 9.51126 15.7875 9.63739 15.677 9.74459C15.5731 9.8518 15.4463 9.90541 15.2969 9.90541H11.8459Z"
        fill="white"
      />
      <path
        d="M9.75913 7.39865C9.75913 9.13288 8.84927 10 7.02956 10C5.20984 10 4.29999 9.13288 4.29999 7.39865V3.53919C4.29999 3.39414 4.35523 3.26802 4.46571 3.16081C4.57619 3.0536 4.70617 3 4.85565 3H5.42106C5.57054 3 5.70052 3.0536 5.811 3.16081C5.92148 3.26802 5.97672 3.39414 5.97672 3.53919V7.51216C5.97672 7.94099 6.06121 8.24685 6.23018 8.42973C6.39916 8.61261 6.67211 8.70405 7.04905 8.70405C7.426 8.70405 7.69895 8.61261 7.86793 8.42973C8.0369 8.24685 8.12139 7.94099 8.12139 7.51216V3.53919C8.12139 3.39414 8.17663 3.26802 8.28711 3.16081C8.39759 3.0536 8.52757 3 8.67705 3H9.20347C9.35294 3 9.48292 3.0536 9.5934 3.16081C9.70389 3.26802 9.75913 3.39414 9.75913 3.53919V7.39865Z"
        fill="white"
      />
    </svg>
  );
});

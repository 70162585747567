import React, {memo} from "react";

export const IconPlusGray = memo(({color}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.994873C4.48 0.994873 0 5.47487 0 10.9949C0 16.5149 4.48 20.9949 10 20.9949C15.52 20.9949 20 16.5149 20 10.9949C20 5.47487 15.52 0.994873 10 0.994873ZM15 11.9949H11V15.9949H9V11.9949H5V9.99487H9V5.99487H11V9.99487H15V11.9949Z"
        fill={color || "#D8D8D8"}
      />
    </svg>
  );
});

import React from "react";
import {Dropdown} from "antd";
import {useTranslation} from "react-i18next";
// local
import {IconEllipsis, IconCog} from "components/icons";
import {ButtonTransparent} from "./buttons/ButtonTransparent";
import {Preloader} from "components/Preloader";
import {hasPermission} from "helpers/helper";
import {LockValue} from "components/LockValue";
import cn from "classnames";

export const DropdownEllipsis = ({
  list,
  loading,
  buttonColor,
  tightMenu,
  thinMenu,
  colorMenu,
  needCogIcon,
  addClass,
}) => {
  const {t} = useTranslation();

  const DropdownRow = ({rowIcon, rowLabel, onClick}) => (
    <div
      className={cn(
        "dropdown-ellipsis__row",
        {"thin-row": !!thinMenu},
        {"tight-row": !!tightMenu}
      )}
      onClick={onClick}
    >
      {rowIcon}
      <span style={colorMenu && {color: colorMenu}}>{t(rowLabel)}</span>
    </div>
  );

  const items = list
    .filter((item) => !item.hide)
    .map((row) => ({
      key: row.label,
      label: row.permissions ? (
        <LockValue canPermissions={row.permissions} needLabel needShowModalToSubscribe>
          <DropdownRow
            rowIcon={row.icon}
            rowLabel={row.label}
            onClick={() => hasPermission(row.permissions) && row.onClick()}
          />
        </LockValue>
      ) : (
        <DropdownRow rowIcon={row.icon} rowLabel={row.label} onClick={row.onClick} />
      ),
    }));

  return (
    <Dropdown
      menu={{items}}
      trigger={["click"]}
      overlayClassName="dropdown-ellipsis__list"
    >
      {loading ? (
        <Preloader size="small" addClass="ml-5" />
      ) : (
        <ButtonTransparent
          addClass={cn({[addClass]: !!addClass})}
          icon={
            needCogIcon ? (
              <IconCog color={buttonColor} />
            ) : (
              <IconEllipsis color={buttonColor} />
            )
          }
          tiny
          noBorder
        />
      )}
    </Dropdown>
  );
};

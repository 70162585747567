import * as yup from "yup";
import {stringRequired} from "../../../../../yup/defaultValudations";

export const schema = yup.object().shape({
  members: yup.array().of(
    yup.object().shape({
      organization: stringRequired(),
      since: yup.string().required("Fyll i det här fältet"),
    })
  ),
});
export const initialValues = {organization: "", since: ""};

import React from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import Animate from "rc-animate";
import cn from "classnames";
import {Switch} from "antd";
// locale
import MultipleSelect from "../selects/MultipleSelect";

const GroupSelections = inject("store")(
  observer(({store: {organization}, title, name, selects, setFieldValue, disabled}) => {
    const {searchPreferencesGroupSwitches} = organization;
    const groupSelector = searchPreferencesGroupSwitches[name];
    const {t} = useTranslation();

    const handlerOnChange = () =>
      !disabled ? organization.setSearchPreferencesGroupSwitches(name) : undefined;

    return (
      <div className={cn("selections-group_wrapper", {__open: groupSelector?.isActive})}>
        <div className="selections-group_switch">
          <Switch
            className="selections-group_switch__check custom-switch-check"
            onChange={handlerOnChange}
            checked={groupSelector?.isActive}
            disabled={disabled}
          />
          <label
            className={cn("selections-group_switch__label", {
              _checked: groupSelector?.isActive,
              _disabled: disabled,
            })}
            onClick={handlerOnChange}
          >
            {t(title)}
          </label>
        </div>
        <Animate transitionName="fade" transitionAppear>
          {groupSelector?.isActive && (
            <div className="selections-group_inner-wrapper">
              {selects?.length &&
                selects.map((item, i) => (
                  <MultipleSelect
                    key={i}
                    list={item.list}
                    title={item.subTitle}
                    value={item.values}
                    bottomElement={item.bottomElement}
                    onChange={(value) => {
                      setFieldValue(item.name, value);
                    }}
                  />
                ))}
            </div>
          )}
        </Animate>
      </div>
    );
  })
);

export default GroupSelections;

import React, {useRef, Fragment} from "react";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
//local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import EditDatePicker from "components/EditDatePicker";
import FormikField from "components/FormikField";
// import {TextCard} from "components/TextCard";
import {IconBullHorn} from "components/icons/index";
import {schema} from "./formAttrs";
import {color} from "constants/color.consts";

const FeedPostEdit = ({post, organization, clickCancel, language, savePost, loading}) => {
  const {t} = useTranslation();
  const postFormRef = useRef();

  const onSubmit = async (values) => {
    postFormRef.current.handleReset();
    savePost({
      ...values,
      locationLat: values.locationLat ?? 0,
      locationLng: values.locationLng ?? 0,
    });
    clickCancel();
  };

  return (
    <Fragment>
      <div className="feed-post-edit__header">
        {/* 
        <span>{t("SAY_SOMETHING_AS")}</span>
        <TextCard text={post?.Organization?.name ?? organization} addClass="ml-2" />
        */}
      </div>
      <Formik
        initialValues={post}
        validationSchema={schema}
        onSubmit={onSubmit}
        innerRef={postFormRef}
      >
        {({values, setFieldValue}) => (
          <Form>
            <div className="feed-post-edit__main-info">
              <FormikField
                name="name"
                // autoFocus
                autoResize={{minWidth: "225px", maxWidth: "40vw"}}
                placeholder={t("SAY_SOMETHING_NAME_PLACEHOLDER")}
                errorName="name"
                className="input"
              />
              <EditDatePicker
                name="startedAt"
                placeholder="START_MONTH"
                months
                addClass="ml-2"
              />
              <FormikField
                name="location"
                placeholder={t("LOCATION")}
                errorName="location"
                className="input"
                withGoogleMapsAutocomplete
                withGoogleMapsModal
                mapPlaceCenter
                language={language}
                onChangeCoordinates={({lat, lng, location}) => {
                  setFieldValue("location", location);
                  setFieldValue("locationLat", lat);
                  setFieldValue("locationLng", lng);
                }}
              />
            </div>
            <FormikField
              name="description"
              placeholder={t("SAY_SOMETHING_DESCRIPTION_PLACEHOLDER")}
              errorName="description"
              addClass="feed-post-edit__description"
              autoSize={{maxRows: 6}}
              maxLength="1000"
              textarea
              textareaValue={values.description}
            />
            <div className="df">
              {post?.name && (
                <ButtonTransparent
                  name={t("CANCEL")}
                  addClass="__danger mr-4"
                  onClick={clickCancel}
                />
              )}
              <ButtonTransparent
                type="submit"
                name={t("SAY_SOMETHING_NOW")}
                loading={loading}
                icon={<IconBullHorn color={color.white} />}
                nonTransparent
              />
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default FeedPostEdit;

import React, {memo, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Editor} from "react-draft-wysiwyg";
import {EditorState, convertToRaw, convertFromRaw, ContentState} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {draftToMarkdown, markdownToDraft} from "markdown-draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import cn from "classnames";
// local
import {toolbar} from "constants/richText.consts";

export const RichField = memo(
  ({data, onChange, placeholder, importType, exportType, viewMode, addClass}) => {
    const {t} = useTranslation();

    const formattedData = (data) => {
      if (importType === "markdown") return convertFromRaw(markdownToDraft(data));
      if (importType === "html") {
        const contentBlock = htmlToDraft(data);
        return ContentState.createFromBlockArray(contentBlock.contentBlocks);
      } else convertFromRaw(data);
    };
    const [editorState, setEditorState] = React.useState(() =>
      data ? EditorState.createWithContent(formattedData(data)) : undefined
    );

    useEffect(() => {
      !editorState &&
        data &&
        setEditorState(EditorState.createWithContent(formattedData(data)));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, editorState]);

    const onEditorStateChange = (editorState) => {
      setEditorState(editorState);
      if (exportType === "markdown")
        onChange(draftToMarkdown(convertToRaw(editorState.getCurrentContent())));
      if (exportType === "html")
        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      else onChange(convertToRaw(editorState.getCurrentContent()));
    };

    return (
      <div
        className={cn(
          "rich-field__wrapper",
          {[addClass]: !!addClass},
          {view_mode: viewMode}
        )}
      >
        <Editor
          editorState={editorState}
          placeholder={t(placeholder || "NO_TEXT")}
          toolbar={toolbar}
          toolbarHidden={viewMode}
          readOnly={viewMode}
          toolbarClassName="rich-field__toolbar"
          wrapperClassName="rich-field__inner-wrapper"
          editorClassName={cn("rich-field__editor", {view_mode: viewMode})}
          onEditorStateChange={onEditorStateChange}
        />
      </div>
    );
  }
);

import React, {useState} from "react";
// local
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {InitialsBadge} from "components/InitialsBadge";
import {IconBinoculars} from "components/icons";

const MonitorSuppliers = ({active, changedPerson, currentUser, onChange}) => {
  const [status, setStatus] = useState(active);

  const handleChange = (checked) => {
    setStatus(checked);
    onChange(checked);
  };

  return (
    <div className="presentation-page-header__monitor-suppliers">
      {status && (
        <InitialsBadge
          firstName={changedPerson?.firstName}
          lastName={changedPerson?.lastName}
          isCurrentUser={changedPerson.id === currentUser?.id}
          addClass="mr-2"
        />
      )}
      <IconBinoculars height="20" width="21" addClass="mr-2" />
      <CustomRadioSwitch
        checked={status}
        small
        isActive={handleChange}
        informationText={status ? undefined : "ORGANIZATION_HEADER_MONITORING_TOOLTIP"}
      />
    </div>
  );
};

export default MonitorSuppliers;

import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
//local
import {toOrganizationId} from "constants/routes.const";
import {TextCard} from "components/TextCard";
import {TimeLeft} from "components/TimeLeft";
import {copyToClipboard} from "helpers/helper";
import {monthYearFormat} from "helpers/datetime";
import {DropdownEllipsis} from "components/DropdownEllipsis";
import {IconMapMarker, IconCalendar, IconHand} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {ShareButton} from "components/buttons/ShareButton";
import {color} from "constants/color.consts";
import cn from "classnames";

// const baseURL = process.env.REACT_APP_API_URL;

const FeedPostView = ({
  post,
  showInterest,
  editPost,
  deletePost,
  language,
  interested,
  myPost,
  hideInfo,
}) => {
  const {t} = useTranslation();
  const realURL = `${window.location.origin}/feed/`;

  const menu = [
    {label: "EDIT_POST", onClick: editPost},
    {label: "DELETE_POST", onClick: deletePost},
  ];

  const copyLink = () =>
    copyToClipboard(
      // `${baseURL ? baseURL : prodURL}feed/${post.name.replace(/\s/g, "-")}_${post.id}`,
      `${realURL}${post.name.replace(/\s/g, "-")}_${post.id}`,
      true
    );

  const textCard = (text) => (
    <TextCard
      text={text}
      iconColor={!hideInfo && color.link}
      addClass={cn("mr-2", {"hide-info": hideInfo})}
    />
  );

  return (
    <Fragment>
      <div className="feed-post-view__header">
        {hideInfo ? (
          <React.Fragment>{textCard(t("AUTHOR_HIDDEN"))}</React.Fragment>
        ) : (
          <Link to={toOrganizationId(post.Organization.id)}>
            {textCard(post.Organization.name)}
          </Link>
        )}
        <TimeLeft date={post.updatedAt} />
        {myPost && (
          <DropdownEllipsis
            list={menu}
            buttonColor={color.black}
            thinMenu
            addClass="ml-2"
            colorMenu={color.black}
          />
        )}
        <ShareButton addClass="feed-post-view__share-button" onClickCopy={copyLink} />
      </div>
      <div className="feed-post-view__main-info">
        <h3 className="feed-post-view__main-info__title">{post.name}</h3>
        <div className="df-row-center">
          <IconMapMarker addClass="ml-8 mr-2" />
          <span
            className={cn("feed-post-view__main-info__location", {"hide-info": hideInfo})}
          >
            {hideInfo ? t("LOCATION_HIDDEN") : post.location}
          </span>
        </div>
        <div className="df-row-center">
          <IconCalendar className="ml-4 mr-2" />
          <span className="feed-post-view__main-info__date">
            {monthYearFormat(post.startedAt, language)}
          </span>
        </div>
      </div>
      <div className="feed-post-view__description">{post.description}</div>
      <div className="feed-post-view__reaction">
        {!myPost && (
          <ButtonTransparent
            name={hideInfo ? "LOG_IN_SHOW_INTEREST" : "SHOW_INTEREST"}
            icon={<IconHand />}
            small
            onClick={showInterest}
            addClass={`${interested ? "__success-fill" : "__success"}`}
          />
        )}
        {!myPost && (
          <div className="feed-post-view__reaction__description">
            {t("SHOW_INTEREST_DESCRIPTION")}
          </div>
        )}
        <div className="df-row-center">
          <div className="feed-post-view__reaction__hand-badge">
            <IconHand color={color.white} />
          </div>
          {myPost ? (
            <div className="feed-post-view__reaction__companies">
              {post?.OrganizationsInterestedBy.length ? (
                post?.OrganizationsInterestedBy?.map((org) => (
                  <Link key={org.id} to={toOrganizationId(org.id)} className="mr-3">
                    {org.name}
                  </Link>
                ))
              ) : (
                <div>{t("NO_INTERESTED_COMPANIES")}</div>
              )}
            </div>
          ) : (
            <div className="feed-post-view__reaction__companies">
              {post?.OrganizationsInterestedBy?.length
                ? t("SOME_COMPANIES_INTERESTED")
                : `${t("NO_INTERESTED_COMPANIES")}. ${t("SHOW_INTEREST_FIRST")}`}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default FeedPostView;

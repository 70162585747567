import React, {memo} from "react";

export const IconCheck = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      xmlns="http://www.w3.org/2000/svg"
      className={addClass}
    >
      <path
        d="M5.50025 15.5C5.37225 15.5 5.24425 15.451 5.14625 15.354L0.14625 10.354C-0.04875 10.159 -0.04875 9.84201 0.14625 9.64701C0.34125 9.45201 0.65825 9.45201 0.85325 9.64701L5.49925 14.293L19.1453 0.647013C19.3403 0.452013 19.6572 0.452013 19.8522 0.647013C20.0472 0.842013 20.0472 1.15901 19.8522 1.35401L5.85225 15.354C5.75425 15.452 5.62625 15.5 5.49825 15.5H5.50025Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

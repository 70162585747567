import React, {useState} from "react";
import {inject, observer} from "mobx-react";
//local
import {Layout} from "components/UI/layout";
import FindOrganizationsFilter from "components/Filters/FindOrganizationsFilter";
import FrameworkAgreementsTableHead from "./components/FrameworkAgreementsTableHead";
import FrameworkAgreementsTableItem from "./components/FrameworkAgreementsTableItem";
import FrameworkAgreementsNullStateItem from "./components/FrameworkAgreementsNullStateItem";
import FrameworkAgreementsNullStateFrame from "assets/images/svg/framework-agreements-null-state.svg";
import FrameworkAgreementsNullStateContent from "assets/images/gif/framework-agreements-null-state-content.gif";
import {IconRamavtal} from "components/icons";
import {Preloader} from "components/Preloader";
import EmptyData from "components/EmptyData";

const FrameworkAgreementsPage = inject("store")(
  observer(({store: {auth, organization}}) => {
    const {user} = auth;
    const {
      filterParams,
      suppliers: {searchPreferences},
      loading,
    } = organization;
    const [externalSorting, setExternalSorting] = useState(null);
    const isNoFrameworkAgreementsFilter =
      Object.values(filterParams).filter((item) => !!item).length === 1;

    const nullStateItems = [
      {
        title: "FRAMEWORK_AGREEMENTS_NULL_STATE_TITLE_01",
        description: "FRAMEWORK_AGREEMENTS_NULL_STATE_DESCRIPTION_01",
        attention: null,
      },
      {
        title: "FRAMEWORK_AGREEMENTS_NULL_STATE_TITLE_02",
        description: "FRAMEWORK_AGREEMENTS_NULL_STATE_DESCRIPTION_02",
        attention: "FRAMEWORK_AGREEMENTS_NULL_STATE_ATTENTION_02",
      },
      {
        title: "FRAMEWORK_AGREEMENTS_NULL_STATE_TITLE_03",
        description: "FRAMEWORK_AGREEMENTS_NULL_STATE_DESCRIPTION_03",
        attention: null,
      },
      {
        title: "FRAMEWORK_AGREEMENTS_NULL_STATE_TITLE_04",
        description: "FRAMEWORK_AGREEMENTS_NULL_STATE_DESCRIPTION_04",
        attention: null,
      },
    ];

    return (
      <Layout>
        <div className="framework-agreements content-block_wrapper">
          {searchPreferences &&
            !searchPreferences.length &&
            isNoFrameworkAgreementsFilter && (
              <div className="framework-agreements__null-state-wrapper">
                <div className="framework-agreements__null-state-left-side">
                  {nullStateItems.map((item, i) => (
                    <FrameworkAgreementsNullStateItem key={i} {...item} />
                  ))}
                </div>
                <div className="framework-agreements__null-state-right-side">
                  <img
                    src={FrameworkAgreementsNullStateContent}
                    className="framework-agreements__null-state-content"
                    alt=""
                  />
                  <img
                    src={FrameworkAgreementsNullStateFrame}
                    className="framework-agreements__null-state-frame"
                    alt=""
                  />
                </div>
              </div>
            )}
          {(!searchPreferences ||
            !!searchPreferences?.length ||
            !isNoFrameworkAgreementsFilter) && (
            <React.Fragment>
              <div className="find-suppliers__search-select">
                <FindOrganizationsFilter
                  title="RAMAVTAL"
                  titleIcon={<IconRamavtal height="40" width="40" />}
                  customParams={{}}
                  externalSorting={externalSorting}
                  // videoLinkPrefix="FRAMEWORK_AGREEMENTS"
                  customUrlSearch="organization/ramavtal/search"
                />
              </div>
              <div className="framework-agreements__organizations-table">
                {loading && <Preloader addClass="preloader100" />}
                {!loading && (
                  <FrameworkAgreementsTableHead
                    filterParams={filterParams}
                    onChangeSort={(name, value) => setExternalSorting({name, value})}
                  />
                )}

                {!loading && (
                  <React.Fragment>
                    {!!searchPreferences?.length ? (
                      searchPreferences.map((s) =>
                        s.Organization ? (
                          <FrameworkAgreementsTableItem
                            key={s.Organization.id}
                            organization={s.Organization}
                            currentUser={user}
                          />
                        ) : null
                      )
                    ) : (
                      <EmptyData />
                    )}
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </Layout>
    );
  })
);

export default FrameworkAgreementsPage;

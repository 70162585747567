import React from "react";
import {ErrorMessage, Field} from "formik";
//local
import {checkField} from "helpers/helper";
import cn from "classnames";

const SimpleField = ({
  errorName,
  label,
  suffixLabel,
  small,
  addClass,
  className,
  highlightIfEmpty,
  hideValues,
  ...props
}) => {
  return (
    <div className={cn("form", {[addClass]: !!addClass})}>
      {label ? <label className="label">{label}</label> : null}
      <Field
        className={cn({
          [className]: !!className,
          __small: !!small,
          "bg-yellow": !!highlightIfEmpty,
        })}
        {...props}
      />
      {suffixLabel ? <label className="suffix-label">{suffixLabel}</label> : null}
      {errorName && (
        <ErrorMessage name={errorName}>
          {(msg) => <p className="form-error">{checkField(msg)}</p>}
        </ErrorMessage>
      )}
    </div>
  );
};

export default SimpleField;

import React from "react";
import {Tag as AntdTag} from "antd";
import {useTranslation} from "react-i18next";
import cn from "classnames";
// local
import {color as tagColor} from "constants/color.consts";

export const Tag = ({
  text,
  bold,
  needTranslation,
  large,
  medium,
  small,
  uppercase,
  color,
  noBackground,
  prefixIcon,
  suffixIcon,
  miniSelectView,
  mediumSelectView,
  addClass,
  ...props
}) => {
  const {t} = useTranslation();

  return (
    (!!text || !!prefixIcon || !!suffixIcon) && (
      <AntdTag
        className={cn(
          "custom-tag",
          {__large: !!large},
          {__medium: !!medium},
          {__small: !!small},
          {__uppercase: !!uppercase},
          {__bold: !!bold},
          {"__no-background": !!noBackground},
          {"__mini-select-view": !!miniSelectView},
          {"__medium-select-view": !!mediumSelectView},
          {[addClass]: !!addClass}
        )}
        color={!noBackground && (tagColor[color] ?? color)}
        style={{
          color: noBackground && (tagColor[color] ?? color),
          borderColor: tagColor[color] ?? color,
        }}
        {...props}
      >
        {prefixIcon}
        {needTranslation ? t(text) : text}
        {suffixIcon}
      </AntdTag>
    )
  );
};

import React, {useState, useEffect, useCallback, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import {
  IconDocumentDalux,
  IconFile,
  IconNotificationCircle,
  IconFolder,
  IconArrowIn,
  IconArrowOut,
} from "components/icons";
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {Preloader} from "components/Preloader";
import {Table} from "antd";

const AddDaluxLinkModal = inject("store")(
  observer(({store: {buyer}, revisionId}) => {
    const {currentRequest} = buyer;
    const [loading, setLoading] = useState(true);
    const [daluxProjectName, setDaluxProjectName] = useState("");
    const [daluxProjectId, setDaluxProjectId] = useState(null);
    const [foldersFilesList, setFolderFilesList] = useState([]);
    const [selectedLinks, setSelectedLinks] = useState([]);
    const {t} = useTranslation();

    const revisionLinks = useMemo(
      () => currentRequest?.documents?.find((item) => item.id === revisionId).links ?? [],
      [currentRequest, revisionId]
    );

    const columns = [
      {
        title: t("NAME"),
        dataIndex: "name",
        key: "name",
        render: (_, row) => (
          <div className="df-row-center">
            {!!row.folderName ? (
              <IconFolder width="12" height="12" addClass="ml-1 mr-1" />
            ) : (
              <IconFile width="12" height="12" addClass="ml-1 mr-1" />
            )}
            <span>{row.name}</span>
          </div>
        ),
      },
      {
        title: t("ADD_MODAL_DALUX_TABLE_VERSION_COLUMN"),
        dataIndex: "version",
        key: "version",
        width: "132px",
      },
      {
        title: t("ADD_MODAL_DALUX_TABLE_CHANGED_COLUMN"),
        dataIndex: "lastModified",
        key: "lastModified",
        width: "132px",
      },
    ];

    // rowSelection objects indicates the need for row selection
    const rowSelection = {
      getCheckboxProps: (record) => {
        return {
          disabled: !(record.children || record.fileId), //disable folder checkbox without files
        };
      },
      onSelect: (record, selected, selectedRows) => {
        const rows = selectedRows
          .filter((row) => !row.folderName)
          .map(
            (row) =>
              `daluxFile/${row.fileName}/${daluxProjectId}/${row.fileAreaId}/${row.fileId}`
          );
        setSelectedLinks(rows);
      },
    };

    const createTreeList = useCallback((list, parentId) => {
      if (!list.length) return;
      const childrenList = list.filter((item) => item.parentFolderId === parentId);
      const innerList = list.filter((item) => item.parentFolderId !== parentId);
      return childrenList.map((item) => ({
        ...item,
        children: [...item.children, createTreeList(innerList, item.folderId)].flat()
          .length
          ? [...item.children, createTreeList(innerList, item.folderId)].flat()
          : null,
      }));
    }, []);

    const getDaluxFilesFolders = useCallback(
      async (daluxProjectId, fileAreaId, rootFolderName) => {
        const files = (
          await buyer.getDaluxProjectFileAreaFiles(daluxProjectId, fileAreaId)
        ).map((file) => ({...file, name: file.fileName, key: file.fileId}));
        const folders = (
          await buyer.getDaluxProjectFileAreaFolders(daluxProjectId, fileAreaId)
        ).map((folder) => ({
          ...folder,
          name: folder.folderName,
          key: `${fileAreaId}_${folder.folderId}`,
        }));
        const filesFolders = folders.slice(1).map((folder) => ({
          ...folder,
          children: files.filter((file) => file.folderId === folder.folderId),
        }));
        const finalList = createTreeList(filesFolders, folders[0].folderId);
        const rootFolder = {
          ...folders[0],
          key: fileAreaId,
          name: rootFolderName,
          folderName: rootFolderName,
          children: finalList,
        };
        return rootFolder;
      },
      [buyer, createTreeList]
    );

    const getDaluxInfo = useCallback(async () => {
      const daluxProjectId = currentRequest.Project.daluxProjectId;
      setDaluxProjectId(daluxProjectId);
      const data = await buyer.getDaluxProject(daluxProjectId);
      setDaluxProjectName(data.projectName);
      const areas = await buyer.getDaluxProjectFileAreas(daluxProjectId);
      const fillFoldersFileList = areas.map((area) => {
        return new Promise(async (resolve, reject) => {
          try {
            const folder = await getDaluxFilesFolders(
              daluxProjectId,
              area.fileAreaId,
              area.fileAreaName
            );
            resolve(folder);
          } catch (error) {
            reject(error);
          }
        });
      });

      const list = await Promise.all(fillFoldersFileList);
      setFolderFilesList(list);
    }, [buyer, currentRequest.Project, getDaluxFilesFolders]);

    const customExpandIcon = ({expanded, record, onExpand}) => {
      if (!record.children?.length) return <div className="wdth-18"></div>;
      if (expanded)
        return (
          <IconArrowOut addClass="mr-1 cpointer" onClick={(e) => onExpand(record, e)} />
        );
      else
        return (
          <IconArrowIn addClass="mr-1 cpointer" onClick={(e) => onExpand(record, e)} />
        );
    };

    const handleSubmit = () => {
      const addedLinks = selectedLinks
        .filter((item) => !revisionLinks.map((link) => link.url).includes(item))
        .map((url) => ({url}));
      buyer.updateCurrentRequestInfo({
        documents: currentRequest.documents.map((item) =>
          item.id === revisionId
            ? {
                ...item,
                links: [...addedLinks, ...revisionLinks],
              }
            : item
        ),
      });
      buyer.setAddDaluxLinkModalStatus(false);
    };

    useEffect(() => {
      getDaluxInfo().finally(() => setLoading(false));
    }, [getDaluxInfo]);

    return (
      !!daluxProjectName && (
        <Modal
          titleIcon={<IconDocumentDalux addClass="mr-4" />}
          titleText={daluxProjectName}
          whiteTitle
          percentageWide="85%"
          noCenterContent
          noCloseButton
          noCloseOnEsc
          rounded
          onClose={() => buyer.setAddDaluxLinkModalStatus(false)}
        >
          <div className="add-dalux-link-modal__content">
            <div className="add-dalux-link-modal__file-list">
              {loading ? (
                <Preloader addClass="preloader250" />
              ) : (
                <Table
                  columns={columns}
                  rowSelection={{
                    ...rowSelection,
                    checkStrictly: false,
                    hideSelectAll: true,
                  }}
                  expandIcon={(props) => customExpandIcon(props)}
                  dataSource={foldersFilesList}
                  pagination={false}
                  rowClassName={(_, index) => (index % 2 === 1 ? "colored" : "")}
                  scroll={{y: "50vh"}}
                />
              )}
            </div>

            <div className="df-row-jce-center mt-4 ml-1">
              <IconNotificationCircle color="black" />
              <div className="info-blob ml-2">{t("ADD_MODAL_DALUX_INFO_BLOB")}</div>
              <ButtonTransparent
                name="CANCEL"
                color="red"
                medium
                onClick={() => buyer.setAddDaluxLinkModalStatus(false)}
                addClass="mr-4"
              />
              <ButtonTransparent
                name="ADD_SELECTED"
                medium
                nonTransparent
                onClick={handleSubmit}
                disabled={!selectedLinks.length}
              />
            </div>
          </div>
        </Modal>
      )
    );
  })
);

export default AddDaluxLinkModal;

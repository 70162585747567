import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
//local
// import {VideoInstructionLink} from "components/VideoInstructionLink";
import {TextCard} from "components/TextCard";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {ProjectLabel} from "components/ProjectLabel";
import {IconChevronLeft, IconCog, IconMapMarker} from "components/icons";
import {hasPermission} from "helpers/helper";
import {LockValue} from "components/LockValue";
import {color} from "constants/color.consts";

const ProjectHeadView = ({project, editProject}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const canEdit = hasPermission(["EDIT_PROJECT"]);

  return (
    <div className="project-view-header _wrapper">
      <div className="df-row-center mb-2">
        <ButtonTransparent
          icon={<IconChevronLeft color={color.blue} />}
          noBorder
          uppercase
          bold
          small
          addClass="back-button pl-0"
          name="PROJECTS"
          onClick={() => navigate("/projects")}
        />
        {/*
        <VideoInstructionLink
          label="VIDEO_LINK_SINGLE_PROJECT_LABEL"
          link="VIDEO_LINK_SINGLE_PROJECT_LINK"
          addClass="ml-20"
        />
        */}
      </div>

      <div className="name-row">
        <div className="responsive-wrap">
          <div className="breakdown">
            <ProjectLabel
              name={project.name}
              code={project.reference}
              huge
              wideName
              addClass="mr-2"
            />
          </div>
          <div className="breakdown">
            <LockValue
              canPermissions={["EDIT_PROJECT"]}
              needLabel
              needShowModalToSubscribe
              addClassWrapper="mr-4"
            >
              <ButtonTransparent
                icon={<IconCog addClass="mr-0" />}
                noBorder
                tiny
                addClass={`pr-0 pl-0 ${canEdit ? "mr-4" : ""}`}
                onClick={() => canEdit && editProject(project.id)}
              />
            </LockValue>
            {!!project.availableIn?.length &&
              project.availableIn.map((place) => (
                <div key={place} className="region-badge">
                  {t(place)}
                </div>
              ))}
            <IconMapMarker addClass="ml-2 mr-2" />
            <span className="location-label">{project.location}</span>
            <TextCard text={project.buyer} transparent addClass={"ml-4"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectHeadView;

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
//local
import {DecorativeSearch} from "components/Filters/DecorativeSearch";
import {inject, observer} from "mobx-react";
import {Header} from "components/Header/Header";
import {Footer} from "components/Footer";
import SearchPreferenceFirstSetupSelections from "components/SearchPrefereces/SearchPreferenceFirstSetupSelections";
import SearchPreferenceGroupSelections from "components/SearchPrefereces/SearchPreferenceGroupSelections";
import {initialValues, schema} from "components/SearchPrefereces/formAttrs";
import {Form, Formik} from "formik";
import {catchFieldErrors} from "helpers/formik.helpers";
// import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {Button} from "components/buttons/Button";
import {MAIN_PAGE} from "constants/routes.const";
import {IconFactory} from "components/icons/index";
import {color} from "constants/color.consts";

const SaveSearchPage = inject("store")(
  observer(({store: {auth, organization}}) => {
    const {activeOrganizationId} = auth;
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
      if (!auth.organizationId) {
        auth.getUserData().then(({data: {user}}) => {
          auth.updateUserData(user);
        });
      }
    }, [auth.organizationId, auth]);

    const onSubmit = (values, {setFieldError}) => {
      setLoading(true);
      organization
        .saveSearch({...values, notSearchable: false})
        .then(() =>
          organization
            .setOrganizationOnboardingStatus(activeOrganizationId, {
              orgSettings: true,
            })
            .then(() => navigate(MAIN_PAGE))
            .catch((e) => catchFieldErrors(e, setFieldError))
        )
        .catch((e) => catchFieldErrors(e, setFieldError))
        .finally(() => setLoading(false));
    };

    return (
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
        {({values, setFieldValue}) => {
          return (
            <Form>
              <div className="main-content_wrapper">
                <Header type="saveSearch" />
                <div className="firstSetup layout-content_wrapper">
                  <div className="firstSetup__body">
                    <div className="top-section">
                      <DecorativeSearch name={"FIRST_SETUP_SEARCH"} />
                      <p className="info-chunk firstSetup__text">
                        {t("FIRST_SETUP_TITLE")}
                      </p>
                      <h4 className="topic">
                        <IconFactory color={color.black} />
                        <span className="title">{t("WHAT_KIND_OF_COMPANY")}</span>
                      </h4>
                    </div>

                    <SearchPreferenceGroupSelections
                      values={values}
                      setFieldValue={setFieldValue}
                    />

                    <SearchPreferenceFirstSetupSelections
                      values={values}
                      setFieldValue={setFieldValue}
                      isFromOnboarding
                    />

                    <div className="firstSetup__btn">
                      <Button
                        type="submit"
                        disabled={
                          !(
                            values.availableIn?.length &&
                            (values.entrepreneurWithin?.length ||
                              values.entrepreneurOffers?.length ||
                              values.realEstateCompanyType?.length ||
                              values.realEstateServices?.length ||
                              values.materialSupplierType?.length ||
                              values.consultingServices?.length ||
                              values.salesRentalType?.length ||
                              values.organizationsType?.length)
                          )
                        }
                        loading={loading}
                        name={"FIRST_SETUP_SAVE_SETTINGS"}
                      />
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  })
);

export default SaveSearchPage;

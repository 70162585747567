import React from "react";
//local
import {LocalModal} from "components/LocalModal";
import {LockValue} from "components/LockValue";

const SubscriptionUpgrageLocalModal = ({canPermissions}) => {
  return (
    <LocalModal className={"subscription-upgrade-modal-local__wrapper"}>
      <LockValue
        canPermissions={canPermissions}
        needAllPermissions
        fullSubcribeButton
        fullSubscribeVerticalPosition
        fullSubcribeButtonClassName="subscription-upgrade-modal__locked"
      />
    </LocalModal>
  );
};

export default SubscriptionUpgrageLocalModal;

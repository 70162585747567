import React, {useState} from "react";
import {inject, observer} from "mobx-react";
// locale
import {inviteStatuses} from "constants/invite.consts";
import InvitePhonePage from "./components/InvitePhonePage/InvitePhonePage";
import InviteCodePage from "./components/InviteCodePage/InviteCodePage";
import InviteOnBoardingPage from "./components/InviteOnBoardingPage/InviteOnBoardingPage";
import UserInviteLayout from "./components/UserInviteLayout";

const OrganizationUserInvite = inject("store")(
  observer(({store: {organization}}) => {
    const [phone, setPhone] = useState("");

    const getInvitePage = () => {
      switch (organization.invitesStatus) {
        case inviteStatuses.codePage:
          return <InviteCodePage phone={phone} />;
        case inviteStatuses.onBoardingPage:
          return <InviteOnBoardingPage />;
        default:
          return <InvitePhonePage setPhone={setPhone} />;
      }
    };

    return <UserInviteLayout>{getInvitePage()}</UserInviteLayout>;
  })
);

export default OrganizationUserInvite;

import * as yup from "yup";

export const schema = yup.object().shape({});

export const initialValues = {
  notSearchable: false,
  availableIn: [],
  jobSizeMin: 0,
  jobSizeMax: 0,
  employees: "",
  entrepreneurWithin: [],
  entrepreneurOffers: [],
  realEstateCompanyType: [],
  realEstateServices: [],
  realEstatePropertyType: [],
  consultingServices: [],
  salesRentalType: [],
  organizationsType: [],
};

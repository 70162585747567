import React from "react";
import {IconPlane, IconPaperPencil} from "components/icons";

export const inviteStatuses = {
  phonePage: "phonePage",
  codePage: "codePage",
  onBoardingPage: "onBoardingPage",
};

export const inviteCounters = [
  {
    icon: <IconPlane />,
    value: null,
    description: "SENT",
  },
  {
    icon: <IconPaperPencil />,
    value: null,
    description: "SIGNED_UP",
  },
];

export const inviteTableHead = [
  {
    name: "email",
    label: "E_MAIL",
    width: "25%",
  },
  {
    name: "company",
    label: "COMPANY",
    width: "20%",
  },
  {
    name: "sent",
    label: "SENT",
    icon: <IconPlane />,
    width: "11%",
    isDate: true,
  },
  {
    name: "signedup",
    label: "SIGNED_UP",
    icon: <IconPaperPencil />,
    width: "11%",
    isDate: true,
  },
  {
    name: "",
    label: " ",
    width: "33%",
  },
];

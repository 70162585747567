import React, {memo} from "react";
import {color as handyColor} from "constants/color.consts";

export const IconUreadStatus = memo(({color, addClass}) => {
  return (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.5" cy="5" r="4.5" fill={color || handyColor.red} />
    </svg>
  );
});

import React, {memo} from "react";

export const IconPaperPencil = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1029 0.896024C18.5409 0.334024 17.7939 0.0250244 16.9999 0.0250244C16.2059 0.0250244 15.4579 0.334024 14.8969 0.896024L5.14693 10.646C5.09493 10.698 5.05593 10.76 5.03093 10.829L3.03093 16.329C2.96493 16.512 3.00993 16.716 3.14693 16.853C3.24193 16.949 3.36993 17 3.50093 17C3.55793 17 3.61593 16.99 3.67193 16.97L9.17193 14.97C9.24093 14.945 9.30293 14.905 9.35493 14.854L19.1049 5.10402C19.6669 4.54202 19.9759 3.79502 19.9759 3.00002C19.9759 2.20502 19.6669 1.45802 19.1049 0.896024H19.1029ZM8.72493 14.068L4.33593 15.664L5.93193 11.275L13.9999 3.20702L16.7929 6.00002L8.72493 14.068ZM18.3969 4.39602L17.4999 5.29202L14.7069 2.49902L15.6039 1.60302C15.9769 1.23002 16.4729 1.02502 16.9999 1.02502C17.5269 1.02502 18.0229 1.23002 18.3969 1.60302C18.7699 1.97602 18.9749 2.47202 18.9749 2.99902C18.9749 3.52602 18.7699 4.02202 18.3969 4.39502V4.39602Z"
        fill={color || "#000"}
      />
      <path
        d="M17.5 20H1.5C0.673 20 0 19.327 0 18.5V2.5C0 1.673 0.673 1 1.5 1H11.5C11.776 1 12 1.224 12 1.5C12 1.776 11.776 2 11.5 2H1.5C1.224 2 1 2.224 1 2.5V18.5C1 18.776 1.224 19 1.5 19H17.5C17.776 19 18 18.776 18 18.5V8.5C18 8.224 18.224 8 18.5 8C18.776 8 19 8.224 19 8.5V18.5C19 19.327 18.327 20 17.5 20Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

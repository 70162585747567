import React from "react";
import {useTranslation} from "react-i18next";
// local
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconFirstAddKit} from "components/icons";

export const ModalEmptyInsuranceAttention = ({onOk, onClose}) => {
  const {t} = useTranslation();

  return (
    <Modal
      titleIcon={<IconFirstAddKit width="40" height="40" addClass="mr-4" />}
      titleText={t("EMPTY_INSURANCE_ATTENTION_MODAL_TITLE")}
      width={520}
      rounded
      noCloseButton
      whiteTitle
      boldTitle
      noCenterContent
      onClose={onClose}
    >
      <div className="pl-8 pr-8 pb-8">
        <div className="mb-2">{t("EMPTY_INSURANCE_ATTENTION_MODAL_DESCRIPTION")}</div>
        <div>
          <p>{t("EMPTY_INSURANCE_ATTENTION_MODAL_POINT_01")}</p>
          <p> {t("EMPTY_INSURANCE_ATTENTION_MODAL_POINT_02")}</p>
        </div>
        <div className="df-row-jce-center mt-4">
          <ButtonTransparent
            name={"DO_LATER"}
            color="red"
            onClick={onClose}
            addClass="mr-4"
          />
          <ButtonTransparent nonTransparent name={"DO_NOW"} onClick={onOk} />
        </div>
      </div>
    </Modal>
  );
};

import React, {memo} from "react";

export const IconWrench = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "12"}
      height={height || "13"}
      viewBox="0 0 12 13"
      fill="none"
      className={addClass ?? ""}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.05 12.5078C9.6888 12.5078 9.3486 12.3686 9.0918 12.1148C9.0912 12.1142 9.0906 12.1136 9.0906 12.1136L4.4646 7.4882C4.0932 7.6286 3.7026 7.6994 3.3 7.6994C1.4802 7.6994 0 6.2192 0 4.3994C0 3.926 0.0984 3.4688 0.2916 3.041C0.3324 2.951 0.414 2.8874 0.5112 2.8694C0.6084 2.8514 0.7074 2.8826 0.777 2.9522L2.5242 4.6994H3.6V3.6236L1.8486 1.8722C1.779 1.8026 1.7478 1.703 1.7658 1.6064C1.7838 1.5098 1.8474 1.4276 1.9374 1.3868C2.3532 1.199 2.8242 1.0994 3.3006 1.0994C5.1204 1.0994 6.6006 2.5796 6.6006 4.3994C6.6006 4.7972 6.5286 5.1866 6.3864 5.561L11.0088 10.1834C11.2668 10.4384 11.4096 10.7786 11.4114 11.141C11.4132 11.5034 11.2728 11.8448 11.0178 12.1034C10.761 12.3638 10.4172 12.5072 10.05 12.5072V12.5078ZM9.5136 11.6888C9.657 11.8304 9.8478 11.9084 10.05 11.9084C10.2552 11.9084 10.4472 11.8286 10.5906 11.6828C10.734 11.537 10.812 11.3468 10.8114 11.1446C10.8108 10.9424 10.731 10.7528 10.5864 10.6106C10.5858 10.61 10.5852 10.6094 10.5852 10.6094L5.82 5.8442C5.7318 5.756 5.7072 5.6222 5.7588 5.5082C5.919 5.1536 6 4.781 6 4.4C6 2.9114 4.7886 1.7 3.3 1.7C3.0648 1.7 2.832 1.7294 2.61 1.7858L4.1124 3.2882C4.1688 3.3446 4.2 3.4208 4.2 3.5006V5.0006C4.2 5.1662 4.0656 5.3006 3.9 5.3006H2.4C2.3202 5.3006 2.244 5.2688 2.1876 5.213L0.6876 3.713C0.6288 3.9356 0.5994 4.1654 0.5994 4.4006C0.5994 5.8892 1.8108 7.1006 3.2994 7.1006C3.687 7.1006 4.0608 7.0202 4.4106 6.8624C4.5246 6.8108 4.6578 6.8354 4.7466 6.9236L9.513 11.6894L9.5136 11.6888Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

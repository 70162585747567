import React, {useEffect, useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {useNavigate, useLocation} from "react-router-dom";
import debounce from "lodash.debounce";
//local
// import {VideoInstructionLink} from "components/VideoInstructionLink";
import {Layout} from "components/UI/layout";
import {Explanation} from "components/Explanation";
import {IconContract} from "components/icons";
import {Input} from "components/Input";
import ContractsTableHead from "./components/ContractsTableHead";
import {Preloader} from "components/Preloader";
import {defaultCreatedFieldSearch} from "constants/user.consts";
import ContractTableItem from "./components/ContractTableItem";
import EmptyData from "components/EmptyData";
import {color} from "constants/color.consts";

const ContractsPage = inject("store")(
  observer(({store: {auth, buyer, seller}}) => {
    const {
      loading: loadingBuyer,
      contracts: contractsBuyer,
      contractFilterParams: buyerContractFilterParams,
    } = buyer;
    const {loading: loadingSeller, contracts: contractsSeller} = seller;
    const {user} = auth;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const isShowContractNumber = user?.ActiveOrganization?.showContractNumber;
    const [selectedProjectId, SetSelectedProjectId] = useState(null);
    const isBuyer = location.pathname === "/contracts";
    const contracts = isBuyer ? contractsBuyer : contractsSeller;
    const loading = isBuyer ? loadingBuyer : loadingSeller;
    const filterContracts = useMemo(
      () =>
        selectedProjectId && contracts.length
          ? contracts.filter((item) =>
              selectedProjectId
                ? item.Offer?.Proposal?.Request?.Project?.id === selectedProjectId
                : item
            )
          : contracts,
      [contracts, selectedProjectId]
    );
    const projects = useMemo(
      () =>
        contracts.length
          ? contracts
              .filter((item) => item.Offer?.Proposal?.Request?.Project)
              ?.map((item) => item.Offer?.Proposal?.Request?.Project)
              .reduce(
                (rezProjects, item) =>
                  rezProjects.find((val) => val.id === item.id)
                    ? [...rezProjects]
                    : [...rezProjects, item],
                []
              )
          : [],
      [contracts]
    );

    const updateUrlParams = (search) => navigate({search});

    const searchOnChange = debounce((e) => {
      const params = {
        ...buyerContractFilterParams,
        name: e.target.value.length ? e.target.value : undefined,
      };
      updateUrlParams(`?${new URLSearchParams(params)}`);
      buyer.setContractFilterParams(params);
      buyer.getContracts();
    }, 400);

    useEffect(() => {
      isBuyer ? buyer.getContracts() : seller.getContracts();
      return () => buyer.setContractFilterParams(defaultCreatedFieldSearch);
    }, [seller, buyer, isBuyer]);

    return (
      <Layout>
        <div className="our-contracts">
          <div className="content-block_header">
            <IconContract color={color.black} />
            <h3 className="content-block_title __uppercase">{t("OUR_CONTRACTS")}</h3>
            {/*
            <VideoInstructionLink
              label={`VIDEO_LINK_CONTRACTS_${isBuyer ? "BUYER" : "SELLER"}_LABEL`}
              link={`VIDEO_LINK_CONTRACTS_${isBuyer ? "BUYER" : "SELLER"}_LINK`}
              addClass="ml-8"
            />
            */}
            {isBuyer && (
              <div className="find-suppliers__inp-name">
                <Input
                  small
                  onChange={searchOnChange}
                  placeholder={t("FIND_SUPPLIERS_INPUT")}
                />
              </div>
            )}
          </div>
        </div>
        {loading && <Preloader addClass="preloader500" />}
        {!loading && (
          <div className="our-contracts content-block_wrapper">
            {contracts.length ? (
              <>
                <ContractsTableHead
                  projects={projects}
                  showContractNumber={isShowContractNumber}
                  onChangeProject={(value) => SetSelectedProjectId(value)}
                />
                {filterContracts.length ? (
                  filterContracts.map((contract) => (
                    <ContractTableItem
                      key={contract.id}
                      contract={contract}
                      showContractNumber={isShowContractNumber}
                      isFinished={!!contract.isSigned}
                    />
                  ))
                ) : (
                  <EmptyData addClass={`empty-data pt-4`} />
                )}
              </>
            ) : (
              <Explanation text="BEAVER_SAYS_BUYER_HAS_NO_CONTRACTS" fitWidth center />
            )}
          </div>
        )}
      </Layout>
    );
  })
);

export default ContractsPage;

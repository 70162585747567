import React from "react";
//local
import CreditRating from "./CreditRating";
import Turnover from "./Turnover";
import NonPayments from "./NonPayments";
import QuickFacts from "./QuickFacts";

const LeftSide = ({organization}) => {
  const company = organization?.Company;

  return (
    <div className="presentation-page__left-side">
      <CreditRating creditRating={company?.creditRating} />
      <Turnover value={company?.turnover} />
      <NonPayments nonPaymentValue={company?.basic?.sumAnm} />
      <QuickFacts company={company} />
    </div>
  );
};

export default LeftSide;

import React, {useState, useEffect, useRef} from "react";
import {MultiSelect} from "react-multi-select-component";
import {useTranslation} from "react-i18next";
import {toJS} from "mobx";
// local
import {ButtonTransparent} from "../buttons/ButtonTransparent";
import ButtonDelete from "../buttons/ButtonDelete";
import {InitialsBadge} from "components/InitialsBadge";
import {hasPermission} from "helpers/helper";
import {IconPlus, IconClose} from "components/icons/index";
import {color as constColor} from "constants/color.consts";
import cn from "classnames";

const MultipleSelect = ({
  list,
  onChange,
  title,
  titleInButton,
  blackTitle,
  tinyTitle,
  uppercaseButton,
  value,
  color,
  small,
  tiny,
  realLabels,
  noMultipleChange,
  notShowingSelections,
  customSelectAllElement,
  suffixTitleContent,
  selectAllElement,
  bottomElement,
  highlightIfEmpty,
  needSearch,
  needPermissions,
  initialsSelectionMode,
  currentUserId,
  disableRegions,
  disableCategories,
  requestParam,
  customSuffixSelectedRowcontent,
  addClass,
  addButtonSelectClass,
}) => {
  const {t} = useTranslation();
  const [selectedValue, setSelectedValue] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const addToFiltersRef = useRef();
  const randomNumber = Math.floor(Math.random() * 100);
  const multiSelectClassName = `id-${randomNumber}`;

  const disableMaxSelection =
    requestParam === "availableIn" ? disableRegions : disableCategories;

  const options =
    list &&
    list
      .map(({value, label}) => ({value, label: t(label)}))
      .sort((a, b) => (a.label > b.label ? 1 : -1));

  const withCustomSelectAllElement = customSelectAllElement
    ? [{value: customSelectAllElement, label: t(customSelectAllElement)}, ...options]
    : options;

  const finalList = bottomElement
    ? [
        ...withCustomSelectAllElement.filter((item) => item.value !== bottomElement),
        {value: bottomElement, label: t(bottomElement)},
      ]
    : withCustomSelectAllElement;

  const handlerRemove = (value) =>
    realLabels
      ? onChange(selectedValue.filter((x) => x.value !== value))
      : onChange(selectedValue.filter((x) => x.value !== value).map(({value}) => value));

  const openSelect = () =>
    (!needPermissions || hasPermission(needPermissions)) && setIsOpen(!isOpen);

  const backdropListener = (e) => {
    if (addToFiltersRef.current && isOpen) {
      const select = addToFiltersRef.current.parentNode.querySelector(
        ".panel-content .select-panel"
      );
      select && !select.contains(e.target) && setIsOpen(false);
    }
  };

  const changeSelect = (values) => {
    if (!!needPermissions && !hasPermission(needPermissions)) return;
    if (customSelectAllElement) {
      if (
        values.map(({value}) => value).includes(customSelectAllElement) &&
        !selectedValue.map(({value}) => value).includes(customSelectAllElement)
      )
        onChange([customSelectAllElement]);
      else
        onChange(
          values.map(({value}) => value).filter((item) => item !== customSelectAllElement)
        );
    } else realLabels ? onChange(values) : onChange(values.map(({value}) => value));
  };

  useEffect(() => {
    if (customSelectAllElement && value && value.length === list.length)
      setSelectedValue([{value: customSelectAllElement, label: customSelectAllElement}]);
    else
      realLabels
        ? setSelectedValue(toJS(value))
        : setSelectedValue(
            (toJS(value) && toJS(value).map((item) => ({value: item, label: item}))) || []
          );
  }, [value, customSelectAllElement, list.length, realLabels]);

  useEffect(() => {
    document.body.addEventListener("click", backdropListener);
    return () => {
      document.body.removeEventListener("click", backdropListener);
    };
  });

  return (
    <div
      className={cn("multiselect-custom_wrapper", {
        __tiny: !!tiny,
        [addClass]: !!addClass,
      })}
    >
      {title && !titleInButton && (
        <div
          className={cn("multiselect-custom_title __wrapper", {
            __small: !!small,
            __tiny: !!tiny || !!tinyTitle,
            __black: !!blackTitle,
          })}
        >
          <p>{t(title.toUpperCase())}</p>
          {suffixTitleContent}
        </div>
      )}
      <div className="multiselect-custom_inner-wrapper">
        <div className="multiselect-custom_btn __wrapper">
          <ButtonTransparent
            ref={addToFiltersRef}
            name={t(titleInButton ? title : "FIRST_SETUP_SELECT_ADD")}
            onClick={openSelect}
            icon={<IconPlus />}
            small={small}
            rounded
            tiny={tiny}
            uppercase={uppercaseButton}
            color={color}
            disabled={
              (noMultipleChange && selectedValue.length > 0) || disableMaxSelection
            }
            addClass={cn("", {
              [addButtonSelectClass]: !!addButtonSelectClass,
              [multiSelectClassName]: !!multiSelectClassName,
              "bg-yellow": !!highlightIfEmpty,
            })}
          />
          <MultiSelect
            options={finalList}
            value={selectedValue}
            onChange={changeSelect}
            labelledBy={"Select"}
            hasSelectAll={!!selectAllElement}
            selectAllLabel={t(selectAllElement)}
            overrideStrings={{search: t("DROPDOWN_SEARCH_PLACEHOLDER")}}
            className={cn(
              "multiselect-custom_select",
              {[multiSelectClassName]: !!multiSelectClassName},
              {"fucking-first-element": !!customSelectAllElement},
              {"with-search-row": !!needSearch}
            )}
            disableSearch={!needSearch}
            ClearIcon={<IconClose width={14} height={13} color={constColor.darkGray} />}
            ClearSelectedIcon={false}
            isOpen={isOpen}
          />
        </div>
        {!notShowingSelections && (
          <div
            className={cn("multiselect-custom_list __wrapper", {
              df: !!initialsSelectionMode,
            })}
          >
            <div
              className={cn("multiselect-custom_list __inner-wrapper", {
                "df-row-center": !!initialsSelectionMode,
              })}
            >
              {selectedValue.map((item) =>
                initialsSelectionMode ? (
                  <InitialsBadge
                    key={item.value}
                    firstName={
                      list.find((val) => val.value === item.value)?.label.split(" ")[0]
                    }
                    lastName={
                      list.find((val) => val.value === item.value)?.label.split(" ")[1]
                    }
                    isCurrentUser={
                      list.find((val) => val.value === item.value)?.value ===
                      currentUserId
                    }
                    needTooltip
                    needClearClick
                    onClick={() => handlerRemove(item.value)}
                    addClass="mr-1"
                  />
                ) : (
                  <div
                    key={item.value}
                    className={cn(
                      "multiselect-custom_item",
                      {__small: small, __tiny: !!tiny},
                      {[`__${color}`]: color}
                    )}
                  >
                    <span className="mr-1">
                      {realLabels
                        ? list.find((val) => val.value === item.value)?.label
                        : t(item.label)}
                    </span>
                    <ButtonDelete isWhite onClick={() => handlerRemove(item.value)} />
                  </div>
                )
              )}
              {customSuffixSelectedRowcontent}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultipleSelect;

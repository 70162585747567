import React from "react";
import {trimString} from "helpers/helper";
import ButtonCross from "components/buttons/ButtonCross";
import ButtonDelete from "components/buttons/ButtonDelete";
import {IconDownload} from "components/icons/index";
import cn from "classnames";

// buttonView types: "cross", "delete"

const UploadedFilesList = ({
  files,
  viewMode,
  removeFile,
  rowView,
  buttonView = "cross",
  isIconDownload,
  miniLoadFiles,
}) => {
  const mapData = () => {
    return (
      <div
        className={cn("editBlock_upload__links", {
          "__row-view": !!rowView,
        })}
      >
        {files.map((file, i) => {
          const fileName = file?.name;
          return (
            <div key={file?.url} className="mb-2">
              <a
                className={cn("editBlock_upload__link", {
                  "mr-2": viewMode,
                  "__mini-load-files": miniLoadFiles,
                })}
                target="_blank"
                rel="noopener noreferrer"
                href={file?.url || "#"}
              >
                {isIconDownload && <IconDownload />}
                {trimString(fileName)}
              </a>
              {buttonView === "cross" && !viewMode && (
                <ButtonCross
                  addClass={`mr-${rowView ? 2 : 1}`}
                  onClick={() => removeFile(i)}
                />
              )}
              {buttonView === "delete" && !viewMode && (
                <ButtonDelete
                  addClass={`mr-${rowView ? 2 : 1}`}
                  onClick={() => removeFile(i)}
                  activeOnHover
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return <div>{files && mapData()}</div>;
};

export default UploadedFilesList;

import * as yup from "yup";
import {stringRequired} from "yup/defaultValudations";
import {errorValidations} from "yup/errorValidations";

export const schema = yup.object().shape({
  name: stringRequired(),
  endAt: yup
    .date()
    .when("startAt", (startAt, schema) =>
      startAt ? schema.min(startAt, errorValidations.earlyDate) : schema
    ),
});

import React from "react";

export const IconLinkGoogleDrive = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.00101C0 0.896438 0.895431 0.00100708 2 0.00100708H18C19.1046 0.00100708 20 0.896438 20 2.00101V18.001C20 19.1056 19.1046 20.001 18 20.001H2C0.895431 20.001 0 19.1056 0 18.001V2.00101Z"
        fill="#EFEFEF"
      />
      <path
        d="M3.20962 15.0417L3.91523 16.2453C4.06186 16.4987 4.27262 16.6978 4.52005 16.8426L7.04009 12.5349H2C2 12.8155 2.07331 13.096 2.21993 13.3494L3.20962 15.0417Z"
        fill="#0066DA"
      />
      <path
        d="M10 7.46707L7.47995 3.15938C7.23253 3.30417 7.02176 3.50327 6.87514 3.75666L2.21993 11.7205C2.07601 11.9684 2.00019 12.2492 2 12.5349H7.04009L10 7.46707Z"
        fill="#00AC47"
      />
      <path
        d="M15.4799 16.8426C15.7274 16.6978 15.9381 16.4987 16.0848 16.2453L16.378 15.7476L17.7801 13.3494C17.9267 13.096 18 12.8155 18 12.5349H12.9595L14.0321 14.6164L15.4799 16.8426Z"
        fill="#EA4335"
      />
      <path
        d="M10 7.46707L12.52 3.15938C12.2726 3.01458 11.9885 2.94218 11.6953 2.94218H8.30469C8.01145 2.94218 7.72737 3.02363 7.47995 3.15938L10 7.46707Z"
        fill="#00832D"
      />
      <path
        d="M12.9599 12.5349H7.0401L4.52005 16.8426C4.76747 16.9874 5.05155 17.0598 5.34479 17.0598H14.6552C14.9485 17.0598 15.2325 16.9784 15.48 16.8426L12.9599 12.5349Z"
        fill="#2684FC"
      />
      <path
        d="M15.4525 7.73856L13.1249 3.75666C12.9782 3.50327 12.7675 3.30417 12.52 3.15938L10 7.46707L12.9599 12.5349H17.9908C17.9908 12.2544 17.9175 11.9739 17.7709 11.7205L15.4525 7.73856Z"
        fill="#FFBA00"
      />
    </svg>
  );
};

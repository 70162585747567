import React, {memo} from "react";

export const IconLaptopPhone = memo(({color}) => {
  return (
    <svg
      width="15"
      height="16"
      fill="none"
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color || "#000"}
        d="M8.625 11H2.625C2.00475 11 1.5 10.4953 1.5 9.875V3.875C1.5 3.25475 2.00475 2.75 2.625 2.75H12.375C12.9952 2.75 13.5 3.25475 13.5 3.875V4.625C13.5 4.832 13.332 5 13.125 5C12.918 5 12.75 4.832 12.75 4.625V3.875C12.75 3.668 12.582 3.5 12.375 3.5H2.625C2.418 3.5 2.25 3.668 2.25 3.875V9.875C2.25 10.082 2.418 10.25 2.625 10.25H8.625C8.832 10.25 9 10.418 9 10.625C9 10.832 8.832 11 8.625 11Z"
      />
      <path
        fill={color || "#000"}
        d="M8.625 14H1.125C0.50475 14 0 13.4952 0 12.875V12.125C0 11.918 0.168 11.75 0.375 11.75H8.625C8.832 11.75 9 11.918 9 12.125C9 12.332 8.832 12.5 8.625 12.5H0.75V12.875C0.75 13.082 0.918 13.25 1.125 13.25H8.625C8.832 13.25 9 13.418 9 13.625C9 13.832 8.832 14 8.625 14Z"
      />
      <path
        fill={color || "#000"}
        d="M13.875 14H10.875C10.2548 14 9.75 13.4953 9.75 12.875V6.875C9.75 6.25475 10.2548 5.75 10.875 5.75H13.875C14.4953 5.75 15 6.25475 15 6.875V12.875C15 13.4953 14.4953 14 13.875 14ZM10.875 6.5C10.668 6.5 10.5 6.668 10.5 6.875V12.875C10.5 13.082 10.668 13.25 10.875 13.25H13.875C14.082 13.25 14.25 13.082 14.25 12.875V6.875C14.25 6.668 14.082 6.5 13.875 6.5H10.875Z"
      />
      <path
        fill={color || "#000"}
        d="M12.375 12.5C12.276 12.5 12.18 12.4602 12.1102 12.3897C12.0405 12.3192 12 12.2233 12 12.125C12 12.0267 12.0397 11.9293 12.1102 11.8603C12.18 11.7905 12.2767 11.75 12.375 11.75C12.4733 11.75 12.5708 11.7898 12.6398 11.8603C12.7095 11.93 12.75 12.0267 12.75 12.125C12.75 12.2233 12.7103 12.3207 12.6398 12.3897C12.57 12.4595 12.4733 12.5 12.375 12.5Z"
      />
    </svg>
  );
});

export default IconLaptopPhone;

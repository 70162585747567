import React, {useMemo} from "react";
import TimeAgo from "react-timeago";
import {inject, observer} from "mobx-react";
import sv from "react-timeago/lib/language-strings/sv";
import fi from "react-timeago/lib/language-strings/sv";
import en from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
//local
import cn from "classnames";

export const TimeLeft = inject("store")(
  observer(({store: {clientStore}, date, addClass, hasShortLabels, ...props}) => {
    const {language} = clientStore;

    const shortSV = {
      ...sv,
      prefixAgo: null,
      prefixFromNow: null,
      suffixAgo: null,
      suffixFromNow: null,
      minute: "en minut",
      minutes: "%d minuter",
      hour: "en timme",
      hours: "%d timmar",
      month: "en månad",
    };
    const selectedSV = hasShortLabels ? shortSV : sv;

    const shortEN = {
      ...en,
      suffixAgo: null,
      suffixFromNow: null,
      minute: "a minute",
      minutes: "%d minutes",
      hour: "an hour",
      hours: "%d hours",
      month: "a month",
    };
    const selectedEN = hasShortLabels ? shortEN : en;

    const shortFI = {
      ...fi,
      prefixAgo: null,
      prefixFromNow: null,
      suffixAgo: null,
      suffixFromNow: null,
      minute: "yksi minuutti",
      minutes: "%d minuutit",
      hour: "yksi tunti",
      hours: "%d tunnit",
      month: "yksi kuukausi",
    };
    const selectedFI = hasShortLabels ? shortFI : fi;

    const selectedLanguage = useMemo(
      () => ({
        en: selectedEN,
        sv: selectedSV,
        fi: selectedFI,
        [undefined]: selectedSV,
        [null]: selectedSV,
      }),
      [selectedEN, selectedSV, selectedFI]
    );

    const formatter = useMemo(
      () => buildFormatter(selectedLanguage[language]),
      [language, selectedLanguage]
    );

    return (
      <TimeAgo
        date={date}
        formatter={formatter}
        className={cn("time-left", {[addClass]: !!addClass})}
        {...props}
      />
    );
  })
);

import React, {useState} from "react";
import {useTranslation} from "react-i18next";
// local
import {Modal} from "components/Modal";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";

export const PublicPFPPublishModal = ({onSave, onClose}) => {
  const {t} = useTranslation();
  const [verifyToggle, setVerifyToggle] = useState(false);

  return (
    <Modal
      titleText="PUBLISH_OPEN_RFT_CONFIRMATION_POPUP_HEADER"
      noTitle
      boldTitle
      width={500}
      rounded
      darked
      noCenterContent
      style={{marginTop: "20vh"}}
      onClose={onClose}
    >
      <div className="procurement-create-edit-modal__content">
        <p>{t("PUBLISH_OPEN_RFT_CONFIRMATION_POPUP_PARAGRAPH_1")}</p>
        <p>{t("PUBLISH_OPEN_RFT_CONFIRMATION_POPUP_PARAGRAPH_2")}</p>
        <CustomRadioSwitch
          label={t("PUBLISH_OPEN_RFT_CONFIRMATION_POPUP_TOGGLETEXT")}
          mediumText
          checked={verifyToggle}
          small
          addWrapperClass="mt-4"
          isActive={setVerifyToggle}
        />
        <div className="df-row-jce-center mt-8">
          <ButtonTransparent
            name={"PUBLISH_OPEN_RFT_CONFIRMATION_POPUP_BACKBUTTON"}
            onClick={onClose}
            addClass="mr-4"
          />
          <ButtonTransparent
            disabled={!verifyToggle}
            name={"PUBLISH_OPEN_RFT_CONFIRMATION_POPUP_PUBLISHBUTTON"}
            onClick={onSave}
          />
        </div>
      </div>
    </Modal>
  );
};

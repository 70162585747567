import React, {memo} from "react";

export const IconTruck = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.794 10.422L19.79 10.401C19.79 10.4 19.79 10.399 19.789 10.398L19.39 8.401C19.39 8.4 19.39 8.399 19.389 8.398L19.186 7.383C19.031 6.608 18.29 6 17.5 6H13.5C13.325 6 13.157 6.031 13 6.086V5.5C13 4.673 12.327 4 11.5 4H1.5C0.673 4 0 4.673 0 5.5V13.5C0 14.327 0.673 15 1.5 15H3C3 16.103 3.897 17 5 17C6.103 17 7 16.103 7 15H12.086C12.292 15.582 12.848 16 13.5 16H14.269C14.615 16.597 15.261 17 16 17C16.739 17 17.385 16.597 17.731 16H18.5C19.327 16 20 15.327 20 14.5V12.5C20 11.921 19.908 10.989 19.794 10.421V10.422ZM18.69 10.001H16V9.001H18.49L18.69 10.001ZM5 16.001C4.449 16.001 4 15.552 4 15.001C4 14.45 4.449 14.001 5 14.001C5.551 14.001 6 14.45 6 15.001C6 15.552 5.551 16.001 5 16.001ZM6.731 14.001C6.385 13.404 5.739 13.001 5 13.001C4.261 13.001 3.615 13.404 3.269 14.001H1.5C1.224 14.001 1 13.777 1 13.501V5.501C1 5.225 1.224 5.001 1.5 5.001H11.5C11.776 5.001 12 5.225 12 5.501V14.001H6.731ZM16 16.001C15.449 16.001 15 15.552 15 15.001C15 14.45 15.449 14.001 16 14.001C16.551 14.001 17 14.45 17 15.001C17 15.552 16.551 16.001 16 16.001ZM18.5 15.001H18C18 13.898 17.103 13.001 16 13.001C14.897 13.001 14 13.898 14 15.001H13.5C13.224 15.001 13 14.777 13 14.501V7.501C13 7.225 13.224 7.001 13.5 7.001H17.5C17.814 7.001 18.144 7.271 18.206 7.58L18.29 8.001H15.5C15.224 8.001 15 8.225 15 8.501V10.501C15 10.777 15.224 11.001 15.5 11.001H18.878C18.923 11.314 18.96 11.676 18.981 12.001H18.5C18.224 12.001 18 12.225 18 12.501C18 12.777 18.224 13.001 18.5 13.001H19V14.501C19 14.777 18.776 15.001 18.5 15.001Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconIsConnection = memo(({color, height, width, className}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 15.5C9.868 15.5 9.739 15.447 9.647 15.353C9.555 15.259 9.5 15.131 9.5 15C9.5 14.868 9.553 14.739 9.647 14.647C9.741 14.555 9.869 14.5 10 14.5C10.131 14.5 10.261 14.553 10.353 14.647C10.446 14.74 10.5 14.869 10.5 15C10.5 15.131 10.447 15.26 10.353 15.353C10.26 15.446 10.131 15.5 10 15.5Z"
        fill={color || "#000"}
      />
      <path
        d="M10 17.5C9.868 17.5 9.739 17.447 9.647 17.353C9.555 17.259 9.5 17.131 9.5 17C9.5 16.868 9.553 16.739 9.647 16.647C9.741 16.555 9.869 16.5 10 16.5C10.131 16.5 10.261 16.553 10.353 16.647C10.446 16.74 10.5 16.869 10.5 17C10.5 17.131 10.447 17.26 10.353 17.353C10.26 17.446 10.131 17.5 10 17.5Z"
        fill={color || "#000"}
      />
      <path
        d="M10 5.5C9.868 5.5 9.739 5.447 9.647 5.353C9.555 5.259 9.5 5.131 9.5 5C9.5 4.868 9.553 4.739 9.647 4.647C9.741 4.555 9.869 4.5 10 4.5C10.131 4.5 10.261 4.553 10.353 4.647C10.446 4.74 10.5 4.869 10.5 5C10.5 5.131 10.447 5.26 10.353 5.353C10.26 5.446 10.131 5.5 10 5.5Z"
        fill={color || "#000"}
      />
      <path
        d="M10 3.5C9.868 3.5 9.739 3.447 9.647 3.353C9.555 3.259 9.5 3.131 9.5 3C9.5 2.868 9.553 2.739 9.647 2.647C9.741 2.555 9.869 2.5 10 2.5C10.131 2.5 10.261 2.553 10.353 2.647C10.446 2.74 10.5 2.869 10.5 3C10.5 3.131 10.447 3.26 10.353 3.353C10.26 3.446 10.131 3.5 10 3.5Z"
        fill={color || "#000"}
      />
      <path
        d="M10 9.5C9.868 9.5 9.739 9.447 9.647 9.353C9.555 9.259 9.5 9.131 9.5 9C9.5 8.868 9.553 8.739 9.647 8.647C9.741 8.555 9.869 8.5 10 8.5C10.131 8.5 10.261 8.553 10.353 8.647C10.446 8.74 10.5 8.869 10.5 9C10.5 9.131 10.447 9.26 10.353 9.353C10.26 9.446 10.131 9.5 10 9.5Z"
        fill={color || "#000"}
      />
      <path
        d="M10 7.5C9.868 7.5 9.739 7.447 9.647 7.353C9.555 7.259 9.5 7.131 9.5 7C9.5 6.868 9.553 6.739 9.647 6.647C9.741 6.555 9.869 6.5 10 6.5C10.131 6.5 10.261 6.553 10.353 6.647C10.446 6.74 10.5 6.869 10.5 7C10.5 7.131 10.447 7.26 10.353 7.353C10.26 7.446 10.131 7.5 10 7.5Z"
        fill={color || "#000"}
      />
      <path
        d="M10 13.5C9.868 13.5 9.739 13.447 9.647 13.353C9.555 13.259 9.5 13.131 9.5 13C9.5 12.868 9.553 12.739 9.647 12.647C9.741 12.555 9.869 12.5 10 12.5C10.131 12.5 10.261 12.553 10.353 12.647C10.446 12.74 10.5 12.869 10.5 13C10.5 13.131 10.447 13.26 10.353 13.353C10.26 13.446 10.131 13.5 10 13.5Z"
        fill={color || "#000"}
      />
      <path
        d="M10 11.5C9.868 11.5 9.739 11.447 9.647 11.353C9.555 11.259 9.5 11.131 9.5 11C9.5 10.868 9.553 10.739 9.647 10.647C9.741 10.555 9.869 10.5 10 10.5C10.131 10.5 10.261 10.553 10.353 10.647C10.446 10.74 10.5 10.869 10.5 11C10.5 11.131 10.447 11.26 10.353 11.353C10.26 11.446 10.131 11.5 10 11.5Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconIsConnection;

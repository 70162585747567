import React, {memo} from "react";

export const IconCompanyGroup = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 12.55V11C17.5 10.173 16.827 9.5 16 9.5H10.5V7.45C11.64 7.218 12.5 6.208 12.5 5C12.5 3.622 11.378 2.5 10 2.5C8.622 2.5 7.5 3.622 7.5 5C7.5 6.207 8.36 7.217 9.5 7.45V9.5H4C3.173 9.5 2.5 10.173 2.5 11L2.5 12.55C1.36 12.782 0.5 13.792 0.5 15C0.5 16.378 1.622 17.5 3 17.5C4.378 17.5 5.5 16.378 5.5 15C5.5 13.793 4.64 12.783 3.5 12.55L3.5 11C3.5 10.724 3.724 10.5 4 10.5H9.5V12.55C8.36 12.782 7.5 13.792 7.5 15C7.5 16.378 8.622 17.5 10 17.5C11.378 17.5 12.5 16.378 12.5 15C12.5 13.793 11.64 12.783 10.5 12.55V10.5H16C16.276 10.5 16.5 10.724 16.5 11V12.55C15.36 12.782 14.5 13.792 14.5 15C14.5 16.378 15.622 17.5 17 17.5C18.378 17.5 19.5 16.378 19.5 15C19.5 13.793 18.64 12.783 17.5 12.55ZM4.5 15C4.5 15.827 3.827 16.5 3 16.5C2.173 16.5 1.5 15.827 1.5 15C1.5 14.173 2.173 13.5 3 13.5C3.827 13.5 4.5 14.173 4.5 15ZM11.5 15C11.5 15.827 10.827 16.5 10 16.5C9.173 16.5 8.5 15.827 8.5 15C8.5 14.173 9.173 13.5 10 13.5C10.827 13.5 11.5 14.173 11.5 15ZM17 16.5C16.173 16.5 15.5 15.827 15.5 15C15.5 14.173 16.173 13.5 17 13.5C17.827 13.5 18.5 14.173 18.5 15C18.5 15.827 17.827 16.5 17 16.5Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import * as yup from "yup";
import {stringRequired} from "../../../../../yup/defaultValudations";

export const schema = yup.object().shape({
  policies: yup.array().of(
    yup.object().shape({
      name: stringRequired(),
    })
  ),
});

export const initialValues = {name: ""};

import React from "react";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
//local
import {Modal} from "components/Modal";
import FormikField from "components/FormikField";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";

const CommentModal = ({
  message = "",
  placeholder = "",
  confirmButtonName = "SAVE",
  onConfirm,
  onClose,
}) => {
  const {t} = useTranslation();

  const onSubmit = async (values) => {
    onConfirm(values.message);
    onClose();
  };

  return (
    <Modal
      noTitleHeader
      width={380}
      onClose={onClose}
      addBaseClass="comment-modal__base"
      addClass="comment-modal"
    >
      <div className="pa-8" onClick={(e) => e.stopPropagation()}>
        <Formik initialValues={{message}} onSubmit={onSubmit}>
          {({values}) => (
            <Form>
              <FormikField
                name="message"
                label={t("COMMENT_MODAL_TEXT_FIELD_LABEL")}
                placeholder={t(placeholder)}
                errorName="message"
                autoSize={{maxRows: 6}}
                maxLength="1000"
                rows={6}
                autoFocus
                addClass="comment-modal__text-field"
                textarea
                textareaValue={values.message}
              />
              <div className="df-row-jce-center ">
                <ButtonTransparent
                  name={"CANCEL"}
                  color="red"
                  medium
                  onClick={onClose}
                  addClass="mr-4"
                />
                <ButtonTransparent
                  type="submit"
                  medium
                  name={confirmButtonName}
                  nonTransparent
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default CommentModal;

import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {FieldArray, Form, Formik} from "formik";
// locale
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconPlus} from "components/icons/index";
import {showSuccess} from "helpers/notifications.helper";
import PoliciesForm from "./PoliciesForm";
import {schema, initialValues} from "./formAttrs";

const PoliciesEdit = inject("store")(
  observer(({store: {organization}, closeEdit}) => {
    const {t} = useTranslation();
    const {organizationDetail} = organization;
    const policies = (organizationDetail?.Licenses?.length &&
      organizationDetail?.Policies) || [initialValues];
    const [loading, setLoading] = useState(false);

    const policiesForms = ({policies, remove, setFieldValue}) =>
      policies.map((policy, i) => (
        <PoliciesForm
          key={i}
          index={i}
          remove={remove}
          policy={policy}
          setFieldValue={setFieldValue}
        />
      ));

    const onSubmit = (values) => {
      setLoading(true);
      const {policies} = values;

      organization
        .policyEdit(values)
        .then(() => {
          showSuccess("Policies successfully updated.");
          organization.setOrganizationDetail({
            Policies: policies,
          });
          closeEdit();
        })
        .finally(() => setLoading(false));
    };

    return (
      <Formik initialValues={{policies}} validationSchema={schema} onSubmit={onSubmit}>
        {({values, setFieldValue}) => (
          <Form>
            <FieldArray
              name="policies"
              render={(arrayHelpers) => (
                <div className="edit-insurance_wrapper editBlock_wrapper">
                  <div className="editBlock_head">
                    <div className="editBlock_head__title">{t("POLICIES")}</div>
                    <div className="editBlock_head__actions">
                      <ButtonTransparent
                        name={t("SAVE_CHANGES")}
                        type="submit"
                        loading={loading}
                        addClass="editBlock_head__btn"
                      />
                      <ButtonTransparent
                        name={t("CANCEL")}
                        addClass="editBlock_head__btn"
                        onClick={closeEdit}
                      />
                    </div>
                  </div>
                  <div className="editBlock_body">
                    {policiesForms({
                      policies: values.policies,
                      remove: arrayHelpers.remove,
                      setFieldValue: setFieldValue,
                    })}

                    <div className="btn-custom_wrap">
                      <ButtonTransparent
                        name={t("ONE_MORE")}
                        icon={<IconPlus />}
                        className="editBlock_head__btn"
                        onClick={() => {
                          arrayHelpers.push(initialValues);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            />
          </Form>
        )}
      </Formik>
    );
  })
);

export default PoliciesEdit;

import React from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {InviteSuppliers} from "./components/InviteSuppliers";
import {Layout} from "components/UI/layout";
import {IconPlane} from "components/icons/index";
import {Explanation} from "components/Explanation";

const InviteCompanies = inject("store")(
  observer(() => {
    const {t} = useTranslation();

    return (
      <Layout>
        <div className="our-suppliers content-block_wrapper">
          <div className="content-block_header">
            <Explanation
              text="INVITE_COMPANIES_BEAVER_TEXT"
              width={330}
              textCenter
              thinVPadding
              addClass="our-suppliers_explanation"
            />
            <IconPlane />
            <h3 className="content-block_title __uppercase">
              {t("SIDE_INVITE_COMPANIES")}
            </h3>
          </div>
          <InviteSuppliers />
        </div>
      </Layout>
    );
  })
);

export default InviteCompanies;

import React, {useState, useEffect} from "react";
import {ErrorMessage} from "formik";
import {useTranslation} from "react-i18next";
import {Autocomplete, LoadScript} from "@react-google-maps/api";
// local
import MapsModal from "components/modals/MapsModal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconMapMarker} from "components/icons/index";
import {InfoTooltip} from "components/InfoTooltip";
import {checkField} from "helpers/helper";
import {Input} from "formik-antd";
import {googleMapsApiKey} from "constants/auth.const";
import cn from "classnames";

const {TextArea} = Input;

const fieldLibraries = ["places"];

const FormikField = ({
  errorName,
  label,
  labelDataMode,
  labelDescription,
  labelInfo,
  language,
  pretendIcon,
  prefixIcon,
  suffixIcon,
  addClass,
  small,
  tiny,
  uppercaseText,
  boldText,
  textarea,
  maxLength,
  width,
  height,
  fullWidth,
  autoResize,
  textareaValue,
  onChange,
  onChangeCoordinates,
  withGoogleMapsAutocomplete,
  withGoogleMapsModal,
  initialLocation,
  mapPlaceCenter,
  isOpenModal,
  markUnfilled,
  modal,
  ...props
}) => {
  const {t} = useTranslation();
  const [value, setValue] = useState("");
  const [location, setLocation] = useState(initialLocation ?? null);
  const [showMapsModal, setShowMapsModal] = useState(false);
  const [mapLanguage] = useState(language);
  const [counter, setCounter] = useState(textareaValue?.length ?? 0);

  const handleChange = (e) => setValue(e.target.value);
  const handleChangeArea = (e) => {
    e?.target && setCounter(e.target.value.length);
  };
  const counterLabel = t("CHARACTERS");
  const counterValue = `${counter}/${maxLength} `;

  const googleMapsButton = (
    <ButtonTransparent
      noBorder
      addClass="pr-0 pl-0"
      onClick={() => setShowMapsModal(true)}
      icon={<IconMapMarker />}
    />
  );

  const onPlaceChanged = () => {
    const place = value.getPlace();
    const coordinates = place.geometry.location;
    onChangeCoordinates({
      lat: coordinates.lat(),
      lng: coordinates.lng(),
      location: place.formatted_address,
    });
    setLocation({lat: coordinates.lat(), lng: coordinates.lng()});
  };

  // clear counter after clearing field
  useEffect(() => {
    counter && !textareaValue && setCounter(0);
  }, [counter, textareaValue]);

  return (
    <div
      className={cn("form-field_wrapper", {
        [addClass]: !!addClass,
        "resize-field": !!autoResize,
        "location-input": !!withGoogleMapsModal,
        "location-input-autocomplete": !!withGoogleMapsAutocomplete,
        __small: !!small,
        __tiny: !!tiny,
        __uppercase: !!uppercaseText,
        __bold: !!boldText,
        "__full-width": fullWidth,
        "__map-bottom-center": !!mapPlaceCenter,
        "__mark-unfilled": !!markUnfilled,
      })}
      style={autoResize ? {width: "auto", maxWidth: autoResize.maxWidth} : {width: width}}
    >
      {label ? (
        <label className={labelDataMode ? "label-data-mode" : ""}>
          {labelInfo ? (
            <div className="df">
              {label}
              {labelInfo && <InfoTooltip tooltip={labelInfo} addClass="ml-4" />}
            </div>
          ) : (
            label
          )}

          {labelDescription && (
            <div className="label-description">{labelDescription}</div>
          )}
        </label>
      ) : null}
      {pretendIcon}
      {textarea && (
        <React.Fragment>
          {maxLength && (
            <div className={cn("textarea-counter", {"__with-label": !!label})}>
              {counterValue}
              {counterLabel}
            </div>
          )}
          <TextArea maxLength={maxLength} onChange={handleChangeArea} {...props} />
        </React.Fragment>
      )}
      {!textarea && withGoogleMapsAutocomplete && (
        <LoadScript
          id="script-loader"
          googleMapsApiKey={googleMapsApiKey}
          libraries={fieldLibraries}
          region={language}
          language={language}
          loadingElement={<span></span>}
        >
          <Autocomplete
            onLoad={(value) => setValue(value)}
            onPlaceChanged={onPlaceChanged}
          >
            <Input
              prefix={withGoogleMapsModal ? googleMapsButton : <IconMapMarker />}
              suffix={suffixIcon}
              {...props}
            />
          </Autocomplete>
        </LoadScript>
      )}
      {!textarea && !withGoogleMapsAutocomplete && (
        <React.Fragment>
          <Input
            prefix={withGoogleMapsModal ? googleMapsButton : prefixIcon}
            suffix={suffixIcon}
            onChange={onChange ?? handleChange}
            style={{height: height}}
            {...props}
          />
          {autoResize && (
            <div className="aux-field" style={{...autoResize}}>
              {value}
              {prefixIcon ? "area" : ""}
              {suffixIcon ? "area" : ""}
            </div>
          )}
        </React.Fragment>
      )}
      {isOpenModal && modal}
      {withGoogleMapsModal && showMapsModal && (
        <MapsModal
          language={mapLanguage}
          onClose={() => setShowMapsModal(false)}
          initialLocation={location}
          onMapClick={({coordinates, location}) => {
            setLocation(coordinates);
            onChangeCoordinates({lat: coordinates.lat, lng: coordinates.lng, location});
          }}
        />
      )}
      {errorName && (
        <ErrorMessage name={errorName}>
          {(msg) => <p className="form-error">{checkField(msg)}</p>}
        </ErrorMessage>
      )}
    </div>
  );
};

export default FormikField;

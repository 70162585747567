import React from "react";
import {useTranslation} from "react-i18next";
// locale
import SubPageHeader from "components/subPageSegments/SubPageHeader";
import SubPageFooter from "components/subPageSegments/SubPageFooter";

const UserInviteLayout = ({children}) => {
  const {t} = useTranslation();
  return (
    <div className="invite-page__wrapper">
      <SubPageHeader />
      <h2 className="invite-page__title">{t("INVITE_ORGANIZATION_USER_INVITE")}</h2>
      <div className="invite-page__content">{children}</div>
      <SubPageFooter />
    </div>
  );
};

export default UserInviteLayout;

import React from "react";
import {useTranslation} from "react-i18next";
import {
  IconRingNoData,
  IconRingSpecial,
  IconRing120,
  IconRing2130,
  IconRing3150,
  IconRing5170,
  IconRing71100,
} from "components/icons";
import cn from "classnames";
import {InfoTooltip} from "./InfoTooltip";

const CreditRatingScore = ({
  creditRating,
  smallSize,
  noCreditRatingSymbol = "-",
  addClass,
}) => {
  const specialCreditRatings = [-1, -11, -15, -20, -29, -34, -49, -52, -88, -89, -91];

  const isSpecialCreditRating = specialCreditRatings.includes(creditRating);
  const isNoCreditRating =
    !creditRating || ((creditRating < 1 || creditRating > 100) && !isSpecialCreditRating);
  const props = {height: smallSize && "20", width: smallSize && "20"};
  const {t} = useTranslation();

  return (
    <>
      <div
        className={cn("credit-rating-score", {
          __small: !!smallSize,
          [addClass]: !!addClass,
        })}
      >
        <>
          {isNoCreditRating && <IconRingNoData {...props} />}
          {!isNoCreditRating && isSpecialCreditRating && <IconRingSpecial {...props} />}
          {!isNoCreditRating && (
            <React.Fragment>
              {1 <= creditRating && creditRating < 15 && <IconRing120 {...props} />}
              {15 <= creditRating && creditRating < 40 && <IconRing2130 {...props} />}
              {40 <= creditRating && creditRating < 60 && <IconRing3150 {...props} />}
              {60 <= creditRating && creditRating < 80 && <IconRing5170 {...props} />}
              {80 <= creditRating && <IconRing71100 {...props} />}
            </React.Fragment>
          )}
        </>

        <div className={cn("credit-rating-score__count", {__small: !!smallSize})}>
          {isNoCreditRating ? (
            noCreditRatingSymbol
          ) : isSpecialCreditRating ? (
            smallSize ? (
              <span className="special">!</span>
            ) : (
              <span className="credit-rating-span">
                <span className="special">
                  {/* Bankrupcy */}
                  {creditRating === -1 && t("SPECIAL_CREDIT_SCORE_MINUS_1")}
                  {/* Liquidation */}
                  {creditRating === -11 && t("SPECIAL_CREDIT_SCORE_MINUS_11")}
                  {/* Reconstruction */}
                  {creditRating === -15 && t("SPECIAL_CREDIT_SCORE_MINUS_15")}
                  {/* Debt relief */}
                  {creditRating === -20 && t("SPECIAL_CREDIT_SCORE_MINUS_20")}
                  {/* Bankruptcy application */}
                  {creditRating === -29 && t("SPECIAL_CREDIT_SCORE_MINUS_29")}
                  {/* Debt recovery */}
                  {creditRating === -34 && t("SPECIAL_CREDIT_SCORE_MINUS_34")}
                  {/* CEO missing */}
                  {creditRating === -49 && t("SPECIAL_CREDIT_SCORE_MINUS_49")}
                  {/* Auditor missing */}
                  {creditRating === -52 && t("SPECIAL_CREDIT_SCORE_MINUS_52")}
                  {/* Distraint on board member */}
                  {creditRating === -88 && t("SPECIAL_CREDIT_SCORE_MINUS_88")}
                  {/* Bankruptcy info on owner */}
                  {creditRating === -89 && t("SPECIAL_CREDIT_SCORE_MINUS_89")}
                  {/* No rating for this type of company */}
                  {creditRating === -91 && t("SPECIAL_CREDIT_SCORE_MINUS_91")}
                  <InfoTooltip
                    addClass="df mt-1"
                    tooltip="SPECIAL_CREDIT_SCORE_TOOLTIP"
                  />
                </span>
              </span>
            )
          ) : (
            creditRating
          )}
        </div>
      </div>

      {!smallSize && (
        <div className="extra-info-link">
          <a
            href={t("CREDIT_SCORE_EXTRA_INFO_LINK_URL")}
            target="_blank"
            rel="noreferrer"
          >
            {t("CREDIT_SCORE_EXTRA_INFO_LINK_TEXT")} ⧉
          </a>
        </div>
      )}
    </>
  );
};

export default CreditRatingScore;

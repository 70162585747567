import React, {memo} from "react";

export const IconPlane = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8334 0.127004C19.6894 -0.00199597 19.4844 -0.0359959 19.3064 0.0390041L0.306371 8.039C0.114371 8.12 -0.007629 8.311 0.000370998 8.519C0.008371 8.727 0.144371 8.908 0.342371 8.974L6.00037 10.86V16.5C6.00037 16.712 6.13337 16.9 6.33337 16.971C6.38837 16.99 6.44437 17 6.50037 17C6.64837 17 6.79137 16.934 6.88837 16.815L9.65137 13.414L14.1484 17.855C14.2434 17.949 14.3694 17.999 14.4994 17.999C14.5414 17.999 14.5834 17.994 14.6244 17.983C14.7944 17.939 14.9294 17.809 14.9794 17.64L19.9794 0.640004C20.0344 0.455004 19.9764 0.255004 19.8324 0.126004L19.8334 0.127004ZM16.1304 2.461L6.40637 9.941L1.91837 8.445L16.1304 2.461ZM7.00037 10.746L16.4154 3.504L9.22137 12.358C9.22137 12.358 9.22137 12.359 9.22037 12.359L7.00037 15.092V10.746ZM14.2564 16.557L10.2844 12.635L18.3174 2.748L14.2564 16.556V16.557Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

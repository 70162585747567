import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Upload} from "antd";
//local
import {Input} from "components/Input";
import {IconMenuCircle, IconPaperClip} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {beforeUpload} from "helpers/uploader";
import ButtonDelete from "components/buttons/ButtonDelete";

const QualificationQuestion = ({
  question,
  onChange,
  onAddFile,
  onDeleteFile,
  isOwner,
  loading,
  onDelete,
}) => {
  const {t} = useTranslation();
  const [questionLabel, setQuestionLabel] = useState(question.label);

  const handleChange = (e) => {
    setQuestionLabel(e.target.value);
    onChange({...question, label: e.target.value});
  };

  return (
    <div id={question.id} className="qualifications-questions-modal__question-item">
      <div className="full-width">
        <Input
          tiny
          fullWidth
          readOnly={!isOwner || loading}
          placeholder={t("QUALIFICATION_QUESTIONS_INPUT_PLACEHOLDER")}
          className="input"
          value={questionLabel}
          onChange={handleChange}
        />
        <Upload
          customRequest={() => {}}
          beforeUpload={beforeUpload}
          onChange={(e) => onAddFile(e, question.id)}
          showUploadList={false}
          disabled={loading || !isOwner}
          className="tools-upload"
        >
          <ButtonTransparent
            icon={<IconPaperClip />}
            noBorder
            small
            circular
            onClick={isOwner ? () => true : undefined}
            addClass="pa-0 mt-1 mr-1"
          />
          <div className="df-row-center">
            {!!question.files.length &&
              question.files.map((file) => (
                <div className="df-row-center">
                  <a
                    href={file?.url || "/"}
                    key={file.id}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {file.name}
                  </a>
                  {isOwner && (
                    <ButtonDelete
                      activeOnHover
                      onClick={(e) => {
                        e.stopPropagation();
                        onDeleteFile(file.id, question.id);
                      }}
                      addClass="ml-1 mr-4"
                    />
                  )}
                </div>
              ))}
          </div>
        </Upload>
      </div>
      {isOwner && (
        <div className="df-row-center">
          <ButtonDelete
            activeOnHover
            onClick={() => onDelete(question.id)}
            addClass="ml-2 mr-1"
          />
          <ButtonTransparent
            icon={<IconMenuCircle />}
            noBorder
            circular
            onClick={() => true}
            addClass="pa-0 drag-button"
            small
          />
        </div>
      )}
    </div>
  );
};

export default QualificationQuestion;

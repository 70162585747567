import React, {memo} from "react";

export const IconArrowOut = memo(({color, onClick, height, width, addClass}) => {
  return (
    <svg
      width={width || "12"}
      height={height || "12"}
      viewBox="0 0 12 12"
      fill="none"
      onClick={onClick}
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.84836 4H2.15149C1.92063 4 1.79172 4.24375 1.93469 4.41016L5.78313 8.87266C5.89328 9.00039 6.10539 9.00039 6.21672 8.87266L10.0652 4.41016C10.2081 4.24375 10.0792 4 9.84836 4Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconArrowOut;

import React from "react";
import {ErrorMessage, Field} from "formik";
import {useTranslation} from "react-i18next";
import InputMask from "react-input-mask";
//local
import {checkField} from "helpers/helper";
import cn from "classnames";

const CustomInput = (props) => (
  <InputMask {...props}>
    {(inputProps) => <Field {...inputProps} disabled={props.disabled} />}
  </InputMask>
);

export const FieldInput = ({
  name,
  placeholder,
  label,
  type,
  errorName,
  mask,
  small,
  onChange,
  maskChar,
  className,
  addWrapperClass,
  disabled,
  value,
  as,
  ...props
}) => {
  const {t} = useTranslation();

  return (
    <div
      className={cn(`field${small ? " __small" : ""}`, {
        [addWrapperClass]: !!addWrapperClass,
      })}
    >
      {label && <label className="label">{t(label)}</label>}
      <CustomInput
        mask={mask}
        maskChar={maskChar}
        name={name}
        placeholder={t(placeholder)}
        className={`input ${className || ""}`}
        type={type}
        as={as}
        alwaysShowMask={false}
        onChange={onChange}
        disabled={disabled}
        value={value}
        {...props}
      />
      {errorName && (
        <ErrorMessage name={errorName}>
          {(msg) => <p className="form-error">{checkField(msg)}</p>}
        </ErrorMessage>
      )}
    </div>
  );
};

import * as yup from "yup";

export const stringRequired = (text) =>
  yup
    .string()
    .required(text || "Fyll i det här fältet")
    .trim();

export const url = () =>
  yup
    .string()
    .matches(
      /^((ft|htt)ps?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%@_.~+&:]*)*(\?[;&a-z\d%@_.,~+&:=-]*)?(#[-a-z\d_]*)?$/i,
      "Enter correct url!"
    )
    .trim();

export const emailRequired = (text) =>
  yup
    .string()
    .email("Must be a valid email")
    .required(text || "Fyll i det här fältet")
    .trim();

import React, {useState, useEffect, useRef} from "react";
import {Select} from "antd";
import {ButtonTransparent} from "../buttons/ButtonTransparent";
import {useTranslation} from "react-i18next";
import {Tag} from "components/Tag";
import cn from "classnames";

const ButtonSelect = ({
  list,
  onChange,
  value,
  color,
  small,
  buttonIcon,
  buttonTooltip,
  buttonHoverableLink,
  customSelectAllElement,
  buttonName,
  addButtonClass,
  addWrapperClass,
  dropdownClassName,
  dropdownStyle,
}) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const addToFiltersRef = useRef();
  const randomNumber = Math.floor(Math.random() * 100);
  const multiSelectClassName = `id-${randomNumber}`;
  const {Option} = Select;

  const finalList = customSelectAllElement
    ? [
        {value: customSelectAllElement, label: t(customSelectAllElement)},
        ...list.map((item) => ({...item, label: t(item.label)})),
      ]
    : list.map((item) => ({...item, label: t(item.label)}));

  const openSelect = () => setIsOpen(!isOpen);

  const backdropListener = (e) => {
    addToFiltersRef.current && !isOpenMenu && setIsOpenMenu(true);
    if (addToFiltersRef.current && isOpen && isOpenMenu) {
      const select = document.querySelector(
        ".button-select-dropdown.ant-select-dropdown"
      );
      if (select && !select.contains(e.target)) {
        setIsOpen(false);
        setIsOpenMenu(false);
      }
    }
  };

  const changeSelect = (value) =>
    customSelectAllElement && finalList[0].value === value
      ? onChange(finalList)
      : onChange(finalList.find((item) => item.value === value));

  const options = () =>
    finalList.map((item, i) => (
      <Option key={item.value} value={item.value}>
        <Tag
          text={item.label}
          color="blue"
          needTranslation
          uppercase
          bold
          noBackground={!i && !item.value}
          addClass="df"
          suffixIcon={!!i && <span className="mla">{item.rightLabel}</span>}
        />
      </Option>
    ));

  useEffect(() => {
    document.body.addEventListener("click", backdropListener);
    return () => {
      document.body.removeEventListener("click", backdropListener);
    };
  });

  return (
    <div
      className={cn("button-select", {
        [addWrapperClass]: !!addWrapperClass,
      })}
    >
      <ButtonTransparent
        ref={addToFiltersRef}
        additionalName={!!value ? buttonName : null}
        onClick={openSelect}
        icon={!!value ? undefined : buttonIcon}
        small={small}
        color={color}
        bold
        iconButton={!value && !!buttonIcon}
        hoverableLink={buttonHoverableLink}
        nonTransparent={!!value}
        tooltip={buttonTooltip}
        disabled={!finalList.length}
        addClass={addButtonClass}
      />

      <Select
        onChange={(value) => {
          changeSelect(value);
          setIsOpen(false);
        }}
        value={value}
        open={isOpen}
        placement="bottomRight"
        className={cn(
          "button-select __select",
          {[multiSelectClassName]: !!multiSelectClassName},
          {"fucking-first-element": !!customSelectAllElement}
        )}
        popupClassName={cn("button-select-dropdown", {
          [dropdownClassName]: !!dropdownClassName,
        })}
        dropdownStyle={dropdownStyle}
      >
        {options()}
      </Select>
    </div>
  );
};

export default ButtonSelect;

import React from "react";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {isEqual} from "lodash";
// local
import SimpleField from "components/SimpleField";
import EditDatePicker from "components/EditDatePicker";
import EditSelect from "components/selects/EditSelect";
import ButtonDelete from "components/buttons/ButtonDelete";
import UploadMultiple from "components/UploadMultiple";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {ourAgreementsTypes} from "constants/user.consts";
import {schema} from "./formAttrs";

const AgreementCardItemEdit = ({
  item,
  members,
  currentUser,
  onUpdate,
  onDelete,
  onLoadFile,
  onRemoveFile,
  buttonUpdateItemLoading,
}) => {
  const {t} = useTranslation();

  const handleSubmit = (values) => onUpdate(values, ourAgreementsTypes.RAMAVTAL, item.id);

  return (
    <div className="agreement-card-item__edit">
      <Formik
        enableReinitialize
        initialValues={item}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({values}) => (
          <Form>
            <div className="df-row-jsb-start">
              <div className="df-row-start">
                <SimpleField
                  name="name"
                  small
                  label={t("OUR_AGREEMENTS_RAMAVTAL_ITEM_NAME_LABEL")}
                  addClass="agreement-card-item__edit__input"
                  placeholder={t("CONTRACT_NAME_PLACEHOLDER")}
                  className="input"
                />
                <EditDatePicker
                  name="startAt"
                  label={t("OUR_AGREEMENTS_RAMAVTAL_ITEM_DATE_START_LABEL")}
                  small
                />
                <EditDatePicker
                  name="endAt"
                  label={t("OUR_AGREEMENTS_RAMAVTAL_ITEM_DATE_END_LABEL")}
                  small
                />
              </div>
              <div className="df-row-start">
                {!isEqual(item, values) && (
                  <ButtonTransparent
                    name="SAVE_CHANGES"
                    addClass="agreement-card-item__edit__save-button"
                    loading={buttonUpdateItemLoading}
                    type="submit"
                    small
                  />
                )}
                <ButtonDelete
                  onClick={() => onDelete(ourAgreementsTypes.RAMAVTAL, item.id)}
                  activeOnHover
                  type="button"
                  addClass="agreement-card-item__edit__delete-button"
                />
              </div>
            </div>
            <div className="df mt-4">
              <SimpleField
                name="documentNumber"
                small
                label={t("OUR_AGREEMENTS_RAMAVTAL_ITEM_NUMBER_LABEL")}
                addClass="agreement-card-item__edit__input"
                placeholder={t("CONTRACT_NUMBER_PLACEHOLDER")}
                className="input"
              />
              <EditSelect
                name="AssignedUserId"
                list={members}
                label={t("OUR_AGREEMENTS_RAMAVTAL_ITEM_CONTACT_LABEL")}
                ownValue={currentUser.id}
                initials
                small
              />
            </div>
            <div>
              <UploadMultiple
                fieldName="Files"
                uploadRequest={(file) =>
                  onLoadFile(file, ourAgreementsTypes.RAMAVTAL, item.id)
                }
                removeRequest={(id) =>
                  onRemoveFile(ourAgreementsTypes.RAMAVTAL, item.id, id)
                }
                customFileTypes={["PNG", "JPG", "PDF", "XLS"]}
                customFileSize={15360}
                isFilesRowView
                isFileIconDownload
                miniLoadButton
                filesButtonView="delete"
                files={item.Files}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AgreementCardItemEdit;

import React, {memo} from "react";
import cn from "classnames";

export const IconFactory = memo(({color, addClass}) => {
  return (
    <svg
      className={cn({[addClass]: !!addClass})}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M18.4997 20H1.49971C1.09271 20 0.719711 19.839 0.448711 19.547C0.177711 19.255 0.0467107 18.87 0.0777107 18.465L0.923711 7.462C0.943711 7.201 1.16071 7 1.42271 7H3.49971C3.75171 7 3.96471 7.188 3.99571 7.438L4.90071 14.676L9.24271 12.071C9.39671 11.978 9.58971 11.976 9.74671 12.065C9.90371 12.154 10.0007 12.32 10.0007 12.5V14.617L14.2437 12.071C14.3977 11.978 14.5907 11.976 14.7477 12.065C14.9047 12.154 15.0017 12.32 15.0017 12.5V14.617L19.2447 12.071C19.3987 11.978 19.5917 11.976 19.7487 12.065C19.9057 12.154 20.0027 12.32 20.0027 12.5V18.5C20.0027 19.327 19.3297 20 18.5027 20H18.4997ZM1.88571 8L1.07471 18.541C1.06471 18.666 1.10371 18.782 1.18271 18.867C1.26171 18.952 1.37471 19 1.49971 19H18.4997C18.7757 19 18.9997 18.776 18.9997 18.5V13.383L14.7567 15.929C14.6027 16.022 14.4097 16.024 14.2527 15.935C14.0957 15.846 13.9987 15.68 13.9987 15.5V13.383L9.75571 15.929C9.60171 16.022 9.40871 16.024 9.25171 15.935C9.09471 15.846 8.99771 15.68 8.99771 15.5V13.383L4.75471 15.929C4.60971 16.016 4.43071 16.024 4.27871 15.95C4.12671 15.876 4.02271 15.73 4.00171 15.562L3.05671 8H1.88371H1.88571Z"
          fill={color || "#000"}
        />
        <path
          d="M2.5 6C1.673 6 1 5.327 1 4.5C1 3.673 1.673 3 2.5 3C3.327 3 4 3.673 4 4.5C4 5.327 3.327 6 2.5 6ZM2.5 4C2.224 4 2 4.224 2 4.5C2 4.776 2.224 5 2.5 5C2.776 5 3 4.776 3 4.5C3 4.224 2.776 4 2.5 4Z"
          fill={color || "#000"}
        />
        <path
          d="M6 4C4.878 4 4 3.341 4 2.5C4 1.659 4.878 1 6 1C7.122 1 8 1.659 8 2.5C8 3.341 7.122 4 6 4ZM6 2C5.39 2 5 2.296 5 2.5C5 2.704 5.39 3 6 3C6.61 3 7 2.704 7 2.5C7 2.296 6.61 2 6 2Z"
          fill={color || "#000"}
        />
        <path
          d="M12 4C10.318 4 9 3.122 9 2C9 0.878 10.318 0 12 0C13.682 0 15 0.878 15 2C15 3.122 13.682 4 12 4ZM12 1C10.855 1 10 1.528 10 2C10 2.472 10.855 3 12 3C13.145 3 14 2.472 14 2C14 1.528 13.145 1 12 1Z"
          fill={color || "#000"}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

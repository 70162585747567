import React, {memo} from "react";

export const IconWarning = memo(({height, width, color, addClass}) => {
  return (
    <svg
      width={height || "40"}
      height={width || "40"}
      viewBox="0 0 40 40"
      className={addClass}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.9998 38H0.9998C0.6438 38 0.3158 37.812 0.1358 37.504C-0.0442001 37.196 -0.0462001 36.818 0.1278 36.508L18.1278 4.508C18.3058 4.194 18.6378 3.998 18.9998 3.998C19.3618 3.998 19.6938 4.192 19.8718 4.508L37.8718 36.508C38.0458 36.818 38.0418 37.196 37.8638 37.504C37.6858 37.812 37.3558 38 36.9998 38ZM2.7098 36H35.2898L18.9998 7.04L2.7098 36Z"
        fill={color || "#000"}
      />
      <path
        d="M19 28C18.448 28 18 27.552 18 27V17C18 16.448 18.448 16 19 16C19.552 16 20 16.448 20 17V27C20 27.552 19.552 28 19 28Z"
        fill={color || "#000"}
      />
      <path
        d="M19 34C18.448 34 18 33.552 18 33V31C18 30.448 18.448 30 19 30C19.552 30 20 30.448 20 31V33C20 33.552 19.552 34 19 34Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

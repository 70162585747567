import React from "react";
// locale
import PresentationCardWrapper from "../../PresentationCardWrapper";
import SearchPreferences from "./components/SearchPreferences";
import EmptyData from "components/EmptyData";
import SearchPreferencesEdit from "pages/PresentationPage/components/ServicesComponents/ServicesOffered/components/SearchPreferencesEdit/SearchPreferencesEdit";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";

const ServicesOffered = inject("store")(
  observer(({store: {organization}, edit, setEdit}) => {
    const {t} = useTranslation();
    const {organizationDetail} = organization;
    const closeEdit = () => setEdit({...edit, services: false});

    if (edit.services) return <SearchPreferencesEdit closeEdit={closeEdit} />;

    const fields = organizationDetail.SearchPreference ? (
      <SearchPreferences searchPreference={organizationDetail.SearchPreference} />
    ) : (
      <EmptyData />
    );

    return (
      <PresentationCardWrapper
        edit={edit}
        setEdit={setEdit}
        editName="services"
        editPermissions={["EDIT_OUR_PROFILE_MAIN_INFO"]}
        isActive={organizationDetail?.isActive}
        title={t("HOW_WE_WANT_TO_BE_FOUND")}
      >
        {fields}
      </PresentationCardWrapper>
    );
  })
);
export default ServicesOffered;

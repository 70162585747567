import React from "react";
import cn from "classnames";
import {
  IconMedalBronze,
  IconMedalBronzeEmpty,
  IconMedalGold,
  IconMedalGoldEmpty,
  IconMedalSilver,
  IconMedalSilverEmpty,
} from "components/icons/index";
import {medalRating} from "constants/buyer.consts";

const selectedMedal = {
  [medalRating.MEDAL_GOLD]: <IconMedalGold />,
  [medalRating.MEDAL_SILVER]: <IconMedalSilver />,
  [medalRating.MEDAL_BRONZE]: <IconMedalBronze />,
};

export const MedalsRating = ({place, onClick, shortView, addClass}) => {
  const handleMedalClick = (e, place) => {
    // e.stopPropagation();
    // e.preventDefault();
    onClick(e, place);
  };

  return (
    <div className={cn("medal-rating", {[addClass]: !!addClass})}>
      {shortView ? (
        selectedMedal[place]
      ) : (
        <React.Fragment>
          {place === medalRating.MEDAL_GOLD ? (
            <IconMedalGold onClick={handleMedalClick} />
          ) : (
            <IconMedalGoldEmpty
              onClick={(e) => handleMedalClick(e, medalRating.MEDAL_GOLD)}
            />
          )}
          {place === medalRating.MEDAL_SILVER ? (
            <IconMedalSilver onClick={handleMedalClick} />
          ) : (
            <IconMedalSilverEmpty
              onClick={(e) => handleMedalClick(e, medalRating.MEDAL_SILVER)}
            />
          )}
          {place === medalRating.MEDAL_BRONZE ? (
            <IconMedalBronze onClick={handleMedalClick} />
          ) : (
            <IconMedalBronzeEmpty
              onClick={(e) => handleMedalClick(e, medalRating.MEDAL_BRONZE)}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

import React, {memo} from "react";

export const IconFavoriteWaiting = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C7.32842 20 4.81789 18.96 2.92842 17.0716C1.03895 15.1832 0 12.6716 0 10.0011C0 7.32947 1.04 4.81895 2.92842 2.92947C4.81684 1.04 7.32842 0 10 0C12.6716 0 15.1821 1.04 17.0716 2.92947C18.9611 4.81895 20 7.32947 20 10.0011C20 12.6726 18.96 15.1832 17.0716 17.0716C15.1832 18.96 12.6716 20 10 20ZM10 1.05263C5.06632 1.05263 1.05263 5.06632 1.05263 10C1.05263 14.9337 5.06632 18.9474 10 18.9474C14.9337 18.9474 18.9474 14.9337 18.9474 10C18.9474 5.06632 14.9337 1.05263 10 1.05263Z"
        fill={color || "#6BC069"}
      />
      <rect x="9.5" y="5" width="1" height="10" rx="0.5" fill={color || "#6BC069"} />
      <rect
        x="5"
        y="10.5"
        width="1"
        height="10"
        rx="0.5"
        transform="rotate(-90 5 10.5)"
        fill={color || "#6BC069"}
      />
    </svg>
  );
});

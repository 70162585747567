import React, {useState, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
// local
import {Modal} from "components/Modal";
import CommentModal from "components/modals/CommentModal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconSelfTimer, IconEdit} from "components/icons";
import {historyTableStatus} from "constants/table.consts";
import {dateTimeFormat} from "helpers/datetime";
import {InitialsBadge} from "components/InitialsBadge";
import {CustomTooltip} from "components/CustomTooltip";
import {getFullName} from "helpers/helper";
import cn from "classnames";

// modal table column states - historyTableStatus

export const HistoryInfo = inject("store")(
  observer(
    ({
      store: {auth},
      data,
      columns,
      showAmount,
      showOnlyModal,
      onCloseModal,
      onChangeComment,
      addButtonClass,
    }) => {
      const {user, isStaff} = auth;
      const {t} = useTranslation();

      const [isModal, setModal] = useState(showOnlyModal ?? false);
      const [rowCommentModalId, setRowCommentModalId] = useState(null);

      const historyUser = (row) => row.OrganizationMember?.User ?? row.User;
      const historyAmount = data?.length;
      const canEditComment = useCallback(
        (userId) => userId === user.id || isStaff,
        [user, isStaff]
      );

      const DataCell = ({column, row, index}) => (
        <div
          key={row.id}
          className={cn("df-row-center", {
            strikethrough: !!column.strikethrough,
          })}
        >
          {column.view === historyTableStatus.IS_ORDER && historyAmount - index}
          {column.view === historyTableStatus.IS_DATE &&
            dateTimeFormat(row[column.dataKey])}
          {column.view === historyTableStatus.IS_ORGANIZATION && (
            <span className="text-ellipsis">
              {row.OrganizationMember?.Organization?.name}
            </span>
          )}
          {column.view === historyTableStatus.IS_USER &&
            (!!historyUser(row) ? (
              <React.Fragment>
                <InitialsBadge
                  firstName={historyUser(row)?.firstName}
                  lastName={historyUser(row)?.lastName}
                  isCurrentUser={historyUser(row)?.id === user?.id}
                  addClass="mr-2"
                />
                <span className="contact-person__name">
                  {getFullName(historyUser(row)?.firstName, historyUser(row)?.lastName)}
                </span>
              </React.Fragment>
            ) : (
              "-"
            ))}
          {!column.view && (
            <span className={column.overflow && "text-ellipsis"}>
              {column.overflow ? (
                <CustomTooltip text={row[column.dataKey]} addClass="ml-2">
                  {row[column.dataKey]}
                </CustomTooltip>
              ) : column.translate ? (
                t(row[column.dataKey])
              ) : (
                row[column.dataKey]
              )}
            </span>
          )}

          {!!column.edit && canEditComment(historyUser(row).id) && (
            <ButtonTransparent
              addClass="pa-0 mla"
              iconButton
              noBorder
              icon={<IconEdit addClass="ma-0" />}
              onClick={() => setRowCommentModalId(row.id)}
            />
          )}

          {!!column.edit && row.id === rowCommentModalId && (
            <CommentModal
              message={row[column.dataKey]}
              onConfirm={(comment) => onChangeComment(row.id, {comment})}
              onClose={() => setRowCommentModalId(null)}
            />
          )}
        </div>
      );

      return (
        <React.Fragment>
          {!!historyAmount && !showOnlyModal && (
            <ButtonTransparent
              name={showAmount ? `#${historyAmount}` : undefined}
              icon={<IconSelfTimer />}
              iconButton
              color="black"
              noBorder
              small
              addClass={addButtonClass}
              onClick={(e) => {
                e.stopPropagation();
                setModal(true);
              }}
            />
          )}
          {isModal && (
            <Modal
              titleIcon={<IconSelfTimer addClass="mr-2" />}
              titleText={t("HISTORY_INFO_TITLE")}
              percentageWide="85%"
              rounded
              boldTitle
              tightTitle
              noCenterContent
              needAdditionalInnerActions
              onClose={() => {
                setModal(false);
                onCloseModal && onCloseModal(false);
              }}
            >
              <div className="history-info-modal__content">
                <div className="history-info-modal__table">
                  {columns.map((column, index) => (
                    <div
                      key={index}
                      className={cn("history-info-modal__table-column", {
                        "has-overflow": column.overflow,
                      })}
                      style={column.style}
                    >
                      <div className="history-info-modal__table-head">
                        {t(column.name)}
                      </div>
                      {data.map((row, i) => (
                        <DataCell column={column} row={row} index={i} />
                      ))}
                    </div>
                  ))}
                </div>
                <ButtonTransparent
                  name="CLOSE"
                  onClick={() => {
                    setModal(false);
                    onCloseModal && onCloseModal(false);
                  }}
                  addClass="mt-7 mla"
                />
              </div>
            </Modal>
          )}
        </React.Fragment>
      );
    }
  )
);

export default HistoryInfo;

import React from "react";
import {useTranslation} from "react-i18next";
//local
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";

export const OffersSwitchOffModal = ({
  title,
  description,
  needAttention,
  onOk,
  onClose,
}) => {
  const {t} = useTranslation();

  return (
    <Modal
      onClose={onClose}
      width={500}
      noTitle
      boldTitle
      titleText={t(title)}
      addClass="modal-hide-offers-switch__wrapper"
    >
      <div className="pa-8">
        <div>
          <div>{t(description)}</div>
          {needAttention && (
            <div className="__bold mt-4">{t("MODAL_HIDE_OFFERS_SWITCH_ATTENTION")}</div>
          )}
        </div>
        <div className="df-row-jce-center mt-8">
          <ButtonTransparent
            name="CANCEL"
            addClass="mr-4"
            color="red"
            onClick={onClose}
          />
          <ButtonTransparent
            name="MODAL_HIDE_OFFERS_SWITCH_BUTTON_OK"
            onClick={() => {
              onOk();
              onClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

import React, {useState} from "react";
import {GoogleMap, useJsApiLoader, Marker} from "@react-google-maps/api";
import {useTranslation} from "react-i18next";
//local
import {LocalModal} from "components/LocalModal";
import {parentElementIdWidth} from "helpers/helper";
import {googleMapsApiKey} from "constants/auth.const";

const MapsModal = ({onClose, language, initialLocation, onMapClick}) => {
  const [coordinates, setCoordinates] = useState(
    initialLocation ?? {lat: 59.324, lng: 18.1}
  );
  const [center, setCenter] = useState(null);
  const [geocoder, SetGeocoder] = useState(null);
  const [map, setMap] = useState(null);
  const {t} = useTranslation();

  const containerStyle = {
    width: `${parentElementIdWidth("google-maps")}px`,
    height: "300px",
  };

  const {isLoaded} = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsApiKey,
    region: language,
    language: language,
    libraries: ["places"],
  });

  const onLoad = React.useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    SetGeocoder(new window.google.maps.Geocoder());
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback((map) => setMap(null), []);

  const onClick = (...args) => {
    const latLng = {lat: args[0].latLng.lat(), lng: args[0].latLng.lng()};
    setCoordinates(latLng);
    geocoder.geocode({location: latLng}).then(({results}) => {
      onMapClick({coordinates: latLng, location: results[0].formatted_address});
      onClose();
    });
  };

  const onBoundsChanged = () => {
    !center && setCenter(initialLocation ?? {lat: 59.324, lng: 18.1});
  };

  return (
    <LocalModal
      onClose={onClose}
      isChildrenLoaded={!!map}
      className={"modal-maps__wrapper"}
    >
      {isLoaded ? (
        <GoogleMap
          id="google-maps"
          mapContainerStyle={containerStyle}
          center={center}
          zoom={8}
          onLoad={onLoad}
          onClick={onClick}
          onBoundsChanged={onBoundsChanged}
          onUnmount={onUnmount}
        >
          <Marker position={coordinates} />
        </GoogleMap>
      ) : (
        <>{t("LOADING_WITH_DOTS")}</>
      )}
    </LocalModal>
  );
};

export default MapsModal;

import React from "react";
import {formattedNumber} from "helpers/number.formats";
import {useTranslation} from "react-i18next";
import EmptyOrNoCompanyCreditData from "components/EmptyOrNoCompanyCreditData";

const AnnualReportItem = ({annualItemInfo, basicData, hasFirstUpdateData}) => {
  const {t} = useTranslation();
  const annualItemDataFirst = [
    {label: "ANNUAL_REPORT_TURNOVER", value: basicData?.revenue, unit: "kSEK"},
    {
      label: "ANNUAL_REPORT_NUMBER_EMPLOYEES",
      value: annualItemInfo?.nNoEmployees,
      unit: "st",
    },
    {
      label: "ANNUAL_REPORT_TURNOVER_EMPLOYEE",
      value: annualItemInfo?.krTurnoverPerEmployee,
      unit: "kSEK",
    },
    {
      label: "ANNUAL_REPORT_PROFIT_FINANCIAL",
      value: basicData?.profitAfterTax,
      unit: "kSEK",
    },
    {
      label: "ANNUAL_REPORT_TOTAL_ASSETS",
      value: annualItemInfo?.krReturnOnCapEmpPercent,
      unit: "%",
    },
    {
      label: "ANNUAL_REPORT_SHAREHOLDERS",
      value: basicData?.equity,
      unit: "kSEK",
    },
    {
      label: "ANNUAL_REPORT_ASSETS_RATIO",
      value: basicData?.soliditet,
      unit: "%",
    },
    {
      label: "ANNUAL_REPORT_CASH_RATIO",
      value: basicData?.quickRatio,
      unit: "%",
    },
    {
      label: "ANNUAL_REPORT_SKULDSATTINGSGRAD",
      value: basicData?.skuldsattningsgrad,
      unit: "%",
    },
  ];

  const annualItemInfoArray = (data, isEmpty) =>
    data.map((item) => (
      <div key={`${item.label}${item.value}`} className="annual-report_item">
        <span className="annual-report_item__title">{t(item.label)}</span>
        {isEmpty ? (
          <EmptyOrNoCompanyCreditData hasFirstUpdateData={hasFirstUpdateData} />
        ) : (
          <span className="annual-report_item__value">
            {item.value !== null
              ? `${
                  item.unit === "kSEK" || item.unit === "SEK"
                    ? formattedNumber(+item.value)
                    : item.value.replace("%", "")
                }`
              : "-"}
          </span>
        )}
        {!isEmpty && <span>{item.unit}</span>}
      </div>
    ));

  return (
    <>
      <h5 className="finance-section_sub-title">{t("ANNUAL_REPORT_RESULTS")}</h5>
      {annualItemInfoArray(annualItemDataFirst, !annualItemInfo || !basicData)}
    </>
  );
};

export default AnnualReportItem;

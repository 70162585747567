import React from "react";
import {useTranslation} from "react-i18next";

const AvailableIn = ({availableIn}) => {
  const {t} = useTranslation();

  return (
    <div className="edit-our-services_item">
      <p className="edit-our-services_title">{t("FIRST_SETUP_SELECT_AVAILABLE_IN")}</p>
      <div className="edit-our-services_selected__wrapper">
        {availableIn?.length ? (
          availableIn.map((item) => (
            <span key={item} className="edit-our-services_selected__item">
              {t(item)}
            </span>
          ))
        ) : (
          <span className="edit-our-services_selected__item">
            {t("FIRST_SETUP_SELECT_NO_DATA")}
          </span>
        )}
      </div>
    </div>
  );
};

export default AvailableIn;

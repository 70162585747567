import React, {useRef, useState} from "react";
import {Modal} from "antd";
import {Form, Formik} from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import SimpleField from "components/SimpleField";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {showSuccess} from "helpers/notifications.helper";
import {schema, initialValues} from "./formAttrs";

const ReportModal = inject("store")(
  observer(({store: {organization}, visible, setVisible}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [recaptcha, setRecaptcha] = useState(false);
    const formRef = useRef();
    let captcha;

    const setCaptchaRef = (ref) => {
      if (ref) return (captcha = ref);
    };
    // maybe set it till after is submitted
    const resetCaptcha = () => captcha.reset();

    const onChangeReCAPTCHA = (value) => setRecaptcha(true);

    const handleSubmit = (values, {resetForm}) => {
      setLoading(true);
      const data = {
        ...values,
        reportSubjectId: organization.organizationDetail.id,
      };
      organization
        .reportOrganization(data)
        .then(() => showSuccess(t("REPORT_THANKS_TEXT")))
        .finally(() => {
          setLoading(false);
          setVisible(false);
          setRecaptcha(false);
          resetForm();
          resetCaptcha();
        });
    };

    const handleOk = () => {
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
    };

    const handleCancel = () => setVisible(false);

    return (
      <Modal
        open={visible}
        title={t("REPORT_MODAL_TITLE")}
        onOk={handleOk}
        onCancel={handleCancel}
        className="modal-report_wrapper"
        footer={[
          <ButtonTransparent
            key="back"
            onClick={handleCancel}
            addClass="modal-report_btn__close"
            name={t("CANCEL")}
          />,
          <ButtonTransparent
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
            disabled={!recaptcha}
            addClass="modal-report_btn__submit"
            name={t("SUBMIT")}
          />,
        ]}
      >
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          <Form>
            <p>{t("REPORT_MODAL_DESCRIPTION")}</p>
            <SimpleField
              name="description"
              errorName="description"
              placeholder={t("REPORT_MODAL_PLACEHOLDER")}
              as="textarea"
              className="presentation-page-header__input-description presentation-header-edit"
            />
            <ReCAPTCHA
              ref={(r) => setCaptchaRef(r)}
              sitekey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY}
              onChange={onChangeReCAPTCHA}
            />
          </Form>
        </Formik>
      </Modal>
    );
  })
);

export default ReportModal;

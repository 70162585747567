import React, {useState, useEffect} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import {Layout} from "components/UI/layout";
import {OrganizationCard} from "components/organizationCard/OrganizationCard";
import FindOrganizationsFilter from "components/Filters/FindOrganizationsFilter";
import OrganizationCardHead from "components/organizationCard/OrganizationCardHead";
import {IconSkullEmpty} from "components/icons/index";
import {PaginationType} from "components/Pagination";
import SubscriptionUpgrageLocalModal from "components/modals/SubscriptionUpgrageLocalModal";
import {hasPermission} from "helpers/helper";
import {Preloader} from "components/Preloader";
import EmptyData from "components/EmptyData";
import {color} from "constants/color.consts";

const OurBlacklistPage = inject("store")(
  observer(({store: {auth, organization}}) => {
    const {t} = useTranslation();
    const {
      loading,
      suppliers: {totalItems, currentPage, searchPreferences},
    } = organization;

    const {
      user: {permissions},
    } = auth;
    const [externalSorting, setExternalSorting] = useState(null);
    const [externalUpdate, setExternalUpdate] = useState(false);
    const [isHistory, setShowHistory] = useState(false);

    const SubscriptionUpgrageLocalModalPermissions = [
      "READ_TABLE_CREDIT_RATING",
      "READ_TABLE_TURNOVER",
      "READ_TABLE_COLLECTIVE_UNION_AGREEMENTS",
    ];

    useEffect(() => {
      externalUpdate && setExternalUpdate(false);
    }, [externalUpdate]);

    return (
      <Layout>
        <div className="our-blacklist content-block_wrapper">
          <div className="find-suppliers__search-select">
            <FindOrganizationsFilter
              title="BLACKLIST"
              titleIcon={<IconSkullEmpty color={color.black} />}
              customParams={{blackList: true}}
              externalSorting={externalSorting}
              externalUpdate={externalUpdate}
            />
          </div>
          <div className="our-blacklist__organizations-table">
            <OrganizationCardHead
              onChangeSort={(name, value) => setExternalSorting({name, value})}
              isHistory={isHistory}
              onChangeShowHistory={() => setShowHistory(!isHistory)}
            />
            {loading && <Preloader addClass="preloader100" />}
            {!loading && searchPreferences?.length ? (
              searchPreferences.map((s) => {
                if (s.Organization) {
                  return (
                    <OrganizationCard
                      key={s.Organization.id}
                      place="blacklist"
                      organization={s.Organization}
                      isHistory={isHistory}
                      needUpdate={() => setExternalUpdate(true)}
                    />
                  );
                }
                return null;
              })
            ) : (
              <EmptyData />
            )}

            {!hasPermission(SubscriptionUpgrageLocalModalPermissions, permissions) &&
              !loading &&
              !!searchPreferences?.length && (
                <SubscriptionUpgrageLocalModal
                  canPermissions={SubscriptionUpgrageLocalModalPermissions}
                />
              )}
          </div>

          {totalItems > 20 && (
            <PaginationType
              totalItems={totalItems}
              currentPage={currentPage}
              countPerPage={20}
              onPageChange={(pageNumber) => organization.searchSuppliers(pageNumber)}
              addClass="mt-8"
            />
          )}
          <p className="info-chunk">{t("BLACKLIST_INFO")}</p>
        </div>
      </Layout>
    );
  })
);

export default OurBlacklistPage;

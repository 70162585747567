import React, {memo} from "react";

export const IconCamera = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.6 12C5.6152 12 4 10.3848 4 8.4C4 6.4152 5.6152 4.8 7.6 4.8C9.5848 4.8 11.2 6.4152 11.2 8.4C11.2 10.3848 9.5848 12 7.6 12ZM7.6 5.6C6.056 5.6 4.8 6.856 4.8 8.4C4.8 9.944 6.056 11.2 7.6 11.2C9.144 11.2 10.4 9.944 10.4 8.4C10.4 6.856 9.144 5.6 7.6 5.6Z"
        fill={color || "#000"}
      />
      <path
        d="M14 14.4H1.2C0.5384 14.4 0 13.8616 0 13.2V5.2C0 4.5384 0.5384 4 1.2 4H2.4C2.732 4 3.248 3.7864 3.4832 3.5512L3.952 3.0824C4.3416 2.6928 5.0504 2.3992 5.6008 2.3992H9.6008C10.1512 2.3992 10.86 2.6928 11.2496 3.0824L11.7184 3.5512C11.9528 3.7856 12.4696 4 12.8016 4H14.0016C14.6632 4 15.2016 4.5384 15.2016 5.2V13.2C15.2016 13.8616 14.6632 14.4 14.0016 14.4H14ZM1.2 4.8C0.9792 4.8 0.8 4.9792 0.8 5.2V13.2C0.8 13.4208 0.9792 13.6 1.2 13.6H14C14.2208 13.6 14.4 13.4208 14.4 13.2V5.2C14.4 4.9792 14.2208 4.8 14 4.8H12.8C12.2496 4.8 11.5408 4.5064 11.1512 4.1168L10.6824 3.648C10.448 3.4136 9.9312 3.1992 9.5992 3.1992H5.5992C5.2672 3.1992 4.7512 3.4128 4.516 3.648L4.0472 4.1168C3.6576 4.5064 2.9496 4.8 2.3984 4.8H1.1984H1.2Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconCamera;

import React, {memo} from "react";

export const IconSatellite = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      className={addClass ?? ""}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.19997 16C5.13997 16 5.07917 15.9864 5.02157 15.9576C4.82397 15.8592 4.74397 15.6184 4.84237 15.4208L6.44237 12.2208C6.54077 12.0232 6.78157 11.9432 6.97917 12.0416C7.17677 12.14 7.25677 12.3808 7.15837 12.5784L5.55837 15.7784C5.48797 15.9184 5.34717 16 5.19997 16Z"
        fill={color || "#000"}
      />
      <path
        d="M1.19998 14.4C1.12398 14.4 1.04638 14.3784 0.978377 14.3328C0.794377 14.2104 0.744777 13.9616 0.867177 13.7784L2.46718 11.3784C2.58958 11.1944 2.83838 11.1448 3.02158 11.2672C3.20478 11.3896 3.25518 11.6384 3.13278 11.8216L1.53278 14.2216C1.45598 14.3376 1.32878 14.4 1.19918 14.4H1.19998Z"
        fill={color || "#000"}
      />
      <path
        d="M14.3999 1.99999C14.3999 1.33839 13.8615 0.799988 13.1999 0.799988C12.5575 0.799988 12.0319 1.30719 12.0015 1.94159L5.08471 4.01679C4.87351 4.07999 4.75271 4.30319 4.81671 4.51519C4.86871 4.68879 5.02791 4.79999 5.19991 4.79999C5.23831 4.79999 5.27671 4.79439 5.31511 4.78319L12.2319 2.70799C12.2343 2.71199 12.2375 2.71519 12.2399 2.71919L8.06711 8.97839C7.94471 9.16239 7.99431 9.41039 8.17831 9.53279C8.24631 9.57839 8.32391 9.59999 8.39991 9.59999C8.52951 9.59999 8.65591 9.53759 8.73271 9.42159L12.3447 4.00319L11.2095 9.11279C11.1615 9.32879 11.2975 9.54239 11.5135 9.59039C11.5431 9.59679 11.5719 9.59999 11.6007 9.59999C11.7839 9.59999 11.9495 9.47279 11.9911 9.28639L13.3455 3.19039C13.9391 3.11839 14.4007 2.61199 14.4007 1.99919L14.3999 1.99999ZM13.1999 2.39999C12.9791 2.39999 12.7999 2.22079 12.7999 1.99999C12.7999 1.77919 12.9791 1.59999 13.1999 1.59999C13.4207 1.59999 13.5999 1.77919 13.5999 1.99999C13.5999 2.22079 13.4207 2.39999 13.1999 2.39999Z"
        fill={color || "#000"}
      />
      <path
        d="M9.30249 4.40002C9.44569 4.53042 9.58889 4.66322 9.73209 4.80002L9.30249 4.40002Z"
        fill={color || "#000"}
      />
      <path
        d="M9.73125 5.2C9.63205 5.2 9.53205 5.1632 9.45445 5.0888C9.31605 4.956 9.17365 4.824 9.03285 4.696C8.86885 4.548 8.85685 4.2944 9.00485 4.1312C9.15285 3.968 9.40645 3.9552 9.56965 4.1032C9.71605 4.236 9.86325 4.3728 10.0072 4.5104C10.1664 4.6632 10.172 4.916 10.0192 5.076C9.94085 5.1584 9.83525 5.1992 9.73045 5.1992L9.73125 5.2Z"
        fill={color || "#000"}
      />
      <path
        d="M13.5368 9.01278C13.4336 8.81758 13.1912 8.74318 12.996 8.84718C12.8008 8.95118 12.7264 9.19278 12.8304 9.38798C13.612 10.8608 13.8064 12.0672 13.3368 12.5368C12.9232 12.9504 11.9296 12.852 10.6784 12.2744C9.26796 11.624 7.68316 10.4568 6.21596 8.98878C4.74876 7.52078 3.58156 5.93598 2.93036 4.52638C2.35276 3.27518 2.25516 2.28158 2.66796 1.86798C2.99356 1.54238 3.60076 1.58878 4.05276 1.68558C4.77116 1.83918 5.64716 2.23118 6.58716 2.82158C6.77436 2.93918 7.02156 2.88238 7.13836 2.69518C7.25516 2.50798 7.19916 2.26078 7.01196 2.14398C5.99356 1.50478 5.02796 1.07598 4.21916 0.90398C3.04876 0.65438 2.42156 0.98318 2.10156 1.30318C1.54556 1.85918 1.16476 2.56558 0.968759 3.40398C0.783159 4.19918 0.772759 5.07198 0.939159 5.99838C1.27916 7.89918 2.31756 9.79678 3.86236 11.3416C4.21196 11.6912 4.58476 12.0192 4.97116 12.3168C5.04396 12.3728 5.12956 12.4 5.21516 12.4C5.33516 12.4 5.45356 12.3464 5.53196 12.244C5.66716 12.0688 5.63436 11.8176 5.45916 11.6832C5.09996 11.4064 4.75276 11.1008 4.42796 10.776C2.99756 9.34558 2.03836 7.59838 1.72636 5.85758C1.58076 5.04638 1.59116 4.28158 1.74636 3.60718C1.84476 3.99598 1.99676 4.41518 2.20316 4.86158C2.89276 6.35518 4.11596 8.02158 5.64956 9.55518C7.18316 11.0888 8.84956 12.312 10.3432 13.0016C10.796 13.2104 11.2216 13.3648 11.6144 13.4624C10.608 13.6984 9.40236 13.6016 8.13276 13.1552C7.92396 13.0816 7.69596 13.1912 7.62236 13.4C7.54876 13.6088 7.65836 13.8368 7.86716 13.9104C8.33836 14.076 8.80956 14.1984 9.27276 14.2768L9.61036 15.6936C9.65436 15.8776 9.81836 16.0008 9.99916 16.0008C10.0296 16.0008 10.0608 15.9976 10.092 15.9896C10.3072 15.9384 10.4392 15.7224 10.388 15.508L10.1176 14.372C10.24 14.3784 10.3608 14.3824 10.4808 14.3824C10.7256 14.3824 10.9656 14.3688 11.2 14.3408C12.2864 14.212 13.2208 13.784 13.9016 13.1032C14.6624 12.3424 14.5328 10.8896 13.536 9.01358L13.5368 9.01278Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

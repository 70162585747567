import React from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
// local
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import FormikField from "components/FormikField";

export const NotInterestedMessageModal = ({
  title,
  subtitle,
  rejectMessage,
  onConfirm,
  onClose,
}) => {
  const {t} = useTranslation();

  const onSubmit = async (values) => {
    onConfirm(values.rejectMessage);
    onClose();
  };

  return (
    <Modal
      customTitle={
        <div>
          {t(title)}
          {subtitle && (
            <div className="not-interested-message-modal__subtitle">{subtitle}</div>
          )}
        </div>
      }
      noTitle
      boldTitle
      width={420}
      percentageWide="30%"
      rounded
      style={{marginTop: "15vh"}}
      onClose={onClose}
    >
      <div className="not-interested-message-modal__content">
        <Formik initialValues={{rejectMessage}} onSubmit={onSubmit}>
          {({values}) => (
            <Form>
              <FormikField
                name="rejectMessage"
                placeholder={t("NOT_INTERESTED_MESSAGE_MODAL_PLACEHOLDER")}
                errorName="rejectMessage"
                autoSize={{maxRows: 6}}
                maxLength="1000"
                rows={6}
                addClass="mb-8"
                textarea
                textareaValue={values.rejectMessage}
              />
              <div className="df-row-jce-center ">
                <ButtonTransparent
                  name={"CANCEL"}
                  color="red"
                  onClick={onClose}
                  addClass="mr-4"
                />
                <ButtonTransparent type="submit" name={"SEND"} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

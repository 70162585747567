import React, {memo} from "react";

export const IconList = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={height || "20"}
      height={width || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00001 5.99998C1.87201 5.99998 1.74401 5.95098 1.64601 5.85398L0.146006 4.35398C-0.0489941 4.15898 -0.0489941 3.84198 0.146006 3.64698C0.341006 3.45198 0.658006 3.45198 0.853006 3.64698L1.99901 4.79298L6.14501 0.646982C6.34001 0.451982 6.65701 0.451982 6.85201 0.646982C7.04701 0.841982 7.04701 1.15898 6.85201 1.35398L2.35201 5.85398C2.25401 5.95198 2.12601 5.99998 1.99801 5.99998H2.00001Z"
        fill={color || "#000"}
      />
      <path
        d="M19.5 5H7.5C7.224 5 7 4.776 7 4.5C7 4.224 7.224 4 7.5 4H19.5C19.776 4 20 4.224 20 4.5C20 4.776 19.776 5 19.5 5Z"
        fill={color || "#000"}
      />
      <path
        d="M2.00001 13C1.87201 13 1.74401 12.951 1.64601 12.854L0.146006 11.354C-0.0489941 11.159 -0.0489941 10.842 0.146006 10.647C0.341006 10.452 0.658006 10.452 0.853006 10.647L1.99901 11.793L6.14501 7.64698C6.34001 7.45198 6.65701 7.45198 6.85201 7.64698C7.04701 7.84198 7.04701 8.15898 6.85201 8.35398L2.35201 12.854C2.25401 12.952 2.12601 13 1.99801 13H2.00001Z"
        fill={color || "#000"}
      />
      <path
        d="M19.5 12H7.5C7.224 12 7 11.776 7 11.5C7 11.224 7.224 11 7.5 11H19.5C19.776 11 20 11.224 20 11.5C20 11.776 19.776 12 19.5 12Z"
        fill={color || "#000"}
      />
      <path
        d="M2.00001 20C1.87201 20 1.74401 19.951 1.64601 19.854L0.146006 18.354C-0.0489941 18.159 -0.0489941 17.842 0.146006 17.647C0.341006 17.452 0.658006 17.452 0.853006 17.647L1.99901 18.793L6.14501 14.647C6.34001 14.452 6.65701 14.452 6.85201 14.647C7.04701 14.842 7.04701 15.159 6.85201 15.354L2.35201 19.854C2.25401 19.952 2.12601 20 1.99801 20H2.00001Z"
        fill={color || "#000"}
      />
      <path
        d="M19.5 19H7.5C7.224 19 7 18.776 7 18.5C7 18.224 7.224 18 7.5 18H19.5C19.776 18 20 18.224 20 18.5C20 18.776 19.776 19 19.5 19Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import cn from "classnames";

export const ContractNumber = memo(({number, addClass, style}) => {
  const {t} = useTranslation();

  return (
    <div className={cn("df-column-end", {[addClass]: !!addClass})} style={style}>
      <div className="__uppercase __bold fz-12 mid-gray">{t("CONTRACT") + " #"}</div>
      <div className="lh-12">{number}</div>
    </div>
  );
});

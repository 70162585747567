import React from "react";
import {useTranslation} from "react-i18next";
//local
import {IconPlayRound} from "components/icons";
import {color as constColor} from "constants/color.consts";
import cn from "classnames";

export const VideoInstructionLink = ({
  label,
  link,
  color = "blue",
  alignRight,
  uppercase,
  bold,
  addClass,
}) => {
  const {t} = useTranslation();

  return (
    <div
      className={cn("video-instruction-link", {
        [addClass]: !!addClass,
        "align-right": !!alignRight,
      })}
    >
      <IconPlayRound color={constColor[color]} addClass="mr-2" />
      <a
        href={t(link)}
        target="__blank"
        className={cn("", {
          __uppercase: !!uppercase,
          __bold: !!bold,
        })}
      >
        {t(label)}
      </a>
    </div>
  );
};

import React from "react";
import cn from "classnames";
import {Badge} from "antd";
import {color as constColor} from "constants/color.consts";

export const CustomBadge = ({count, addClass, color, onClick}) => {
  return (
    !!+count && (
      <Badge
        count={count}
        onClick={onClick}
        className={cn("badge", {[addClass]: !!addClass, cpointer: !!onClick})}
        style={{backgroundColor: constColor[color]}}
      />
    )
  );
};

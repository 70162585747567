import React, {memo} from "react";

export const IconTags = memo(({color}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4002 6.4C11.7386 6.4 11.2002 5.8616 11.2002 5.2C11.2002 4.5384 11.7386 4 12.4002 4C13.0618 4 13.6002 4.5384 13.6002 5.2C13.6002 5.8616 13.0618 6.4 12.4002 6.4ZM12.4002 4.8C12.1794 4.8 12.0002 4.9792 12.0002 5.2C12.0002 5.4208 12.1794 5.6 12.4002 5.6C12.621 5.6 12.8002 5.4208 12.8002 5.2C12.8002 4.9792 12.621 4.8 12.4002 4.8Z"
        fill={color || "#000"}
      />
      <path
        d="M14 2.40002H11.2C10.6496 2.40002 9.94076 2.69362 9.55116 3.08322L3.48236 9.15202C3.25596 9.37842 3.13196 9.67922 3.13196 10.0008C3.13196 10.3224 3.25676 10.6232 3.48236 10.8496L5.56396 12.9312L4.68796 13.4368C4.59596 13.4896 4.48876 13.5032 4.38476 13.476C4.28076 13.4488 4.19436 13.3824 4.14156 13.2904L1.26476 8.30722C1.15436 8.11602 1.21996 7.87122 1.41116 7.76082L7.79996 4.07202C7.99116 3.96162 8.05676 3.71682 7.94636 3.52562C7.83596 3.33442 7.59116 3.26882 7.39996 3.37922L1.01116 7.06802C0.734363 7.22802 0.535963 7.48642 0.452763 7.79682C0.369563 8.10722 0.411963 8.43042 0.571963 8.70722L3.44876 13.6904C3.60876 13.9672 3.86716 14.1656 4.17756 14.2488C4.28156 14.2768 4.38716 14.2904 4.49116 14.2904C4.69916 14.2904 4.90316 14.236 5.08796 14.1296L6.14956 13.5168L6.75116 14.1184C6.97756 14.3448 7.27836 14.4688 7.59996 14.4688C7.92156 14.4688 8.22236 14.344 8.44876 14.1184L14.5176 8.04962C14.9072 7.66002 15.2 6.95202 15.2 6.40082V3.60082C15.2 2.93922 14.6616 2.40082 14 2.40082V2.40002ZM14.4 6.40002C14.4 6.73202 14.1864 7.24802 13.9512 7.48322L7.88236 13.552C7.80716 13.6272 7.70716 13.668 7.59916 13.668C7.49116 13.668 7.39116 13.6264 7.31596 13.552L4.04716 10.2832C3.97196 10.208 3.93116 10.108 3.93116 10.0008C3.93116 9.89362 3.97276 9.79282 4.04716 9.71762L10.116 3.64882C10.3504 3.41442 10.8672 3.20002 11.1984 3.20002H13.9984C14.2192 3.20002 14.3984 3.37922 14.3984 3.60002V6.40002H14.4Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

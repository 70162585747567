import React, {useState, useEffect, useMemo} from "react";
import {useLocation} from "react-router-dom";
//local
import {settingsTabs} from "constants/tabs.const";
import {Tabs} from "components/Tabs";
import {inject, observer} from "mobx-react";
import {hasUserPermission} from "helpers/helper";
import {roles} from "constants/auth.const";

export const SettingsTabs = inject("store")(
  observer(({store: {auth, organization}, ifDrawer}) => {
    const params = useLocation();
    const {subscriptions} = organization;
    const [active, setActive] = useState(
      settingsTabs.find((item) => item.hash === params?.hash)?.id ?? 0
    );
    const isNotMember = hasUserPermission([roles.ADMIN, roles.OWNER], auth?.clientRole);
    const tabPermissions = useMemo(
      () => [
        undefined,
        undefined,
        isNotMember ? undefined : "SHOW_SETTINGS_ORG_SETTINGS_TAB",
        isNotMember ? "SHOW_SETTINGS_ORG_SETTINGS_TAB" : undefined,
        undefined,
      ],
      [isNotMember]
    );

    useEffect(() => {
      settingsTabs.forEach((item) => {
        if (item.hash === params.hash) {
          setActive(item.id);
        }
      });
    }, [params.hash]);

    useEffect(() => {
      (ifDrawer === true || (ifDrawer && !subscriptions?.length)) &&
        organization.getAvailableSubscriptions();
    }, [ifDrawer, organization, subscriptions?.length]);

    return (
      <div className="modal-settings__content">
        <Tabs
          tabs={settingsTabs.filter(
            (item) => !(item.title === "INVITE_USERS" && !isNotMember)
          )}
          permissions={tabPermissions}
          active={active}
          setActive={setActive}
          addClass="modal-settings__tabs"
        />
      </div>
    );
  })
);

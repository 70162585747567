import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Form, Formik} from "formik";
// local
import {Modal} from "components/Modal";
import {showSuccess} from "helpers/notifications.helper";
import {emptyProcurement} from "constants/buyer.consts";
import EditSelect from "components/selects/EditSelect";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import SimpleField from "components/SimpleField";
import {schema} from "pages/OurProjects/components/ProjecurementEdit/formAttrs";
import {Preloader} from "components/Preloader";

export const ProcurementModal = inject("store")(
  observer(({store: {buyer, auth}, projectId, procurementId, members, onClose}) => {
    const {user} = auth;
    const [procurement, setProcurement] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);
    const {t} = useTranslation();

    const defaultUserIdValue =
      members?.find((item) => item?.value === user.id)?.value ?? members?.[0]?.value;

    const onSubmit = async (values) => {
      setLoadingSave(true);
      if (procurementId === "new-procurement")
        await buyer.createProcurement(projectId, values);
      else await buyer.updateProcurement(projectId, procurementId, values);
      setLoadingSave(false);
      showSuccess(
        t(`SUCCESSFULLY_${procurementId === "new-procurement" ? "CREATED" : "UPDATED"}`)
      );
      onClose(true);
    };

    useEffect(() => {
      setLoading(true);
      if (procurementId === "new-procurement") {
        setProcurement({...emptyProcurement, UserId: defaultUserIdValue});
        setLoading(false);
      } else
        buyer
          .getProcurement(projectId, procurementId)
          .then((data) =>
            setProcurement({
              ...data,
              name: data.rfp?.name ?? data.name,
              UserId: data.Assignee.id,
            })
          )
          .finally(() => setLoading(false));
      return () => setProcurement(null);
    }, [buyer, defaultUserIdValue, procurementId, projectId]);

    return (
      <Modal
        noTitle
        width={320}
        percentageWide="20%"
        rounded
        style={{marginTop: "15vh"}}
        onClose={onClose}
        addClass="procurement-create-edit-modal"
      >
        <div className="procurement-create-edit-modal__content">
          {loading && <Preloader addClass="preloader250" />}
          {!loading && (procurementId === "new-procurement" || !!procurement) && (
            <Formik
              enableReinitialize
              initialValues={procurement}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {({values}) => (
                <Form>
                  <SimpleField
                    name="name"
                    disabled={!!procurement.rfp?.name}
                    placeholder={t("NAME_PROCUREMENT_PLACEHOLDER")}
                    label={t("NAME")}
                    errorName="name"
                    className="input"
                  />
                  <p className="name-info">
                    {t("NAME_PROCUREMENT_PLACEHOLDER_NAME_INFOTEXT")}
                  </p>
                  <EditSelect
                    name="UserId"
                    list={members}
                    ownValue={user.id}
                    initials
                    addClass="mt-6"
                    label={`${t("ASSIGNEE")}:`}
                  />
                  <div className="df-row-jce-center mt-8">
                    <ButtonTransparent
                      name={"CANCEL"}
                      color="red"
                      onClick={onClose}
                      addClass="mr-4"
                    />
                    <ButtonTransparent
                      type="submit"
                      loading={loadingSave}
                      disabled={!values.name}
                      name={"SAVE"}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </Modal>
    );
  })
);

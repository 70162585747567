import React, {useState, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
//local
import {LocalModal} from "components/LocalModal";
import FormikField from "components/FormikField";
import {schema, initialValues} from "./formAttrs";
import {IconLink, IconRightOn, IconTrumbsDown, IconDocumentDalux} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import Divider from "components/Divider";
import {InfoTooltip} from "components/InfoTooltip";
import {extLinkDomains} from "constants/buyer.consts";
import {color} from "constants/color.consts";
import cn from "classnames";

const AddLinkModal = inject("store")(
  observer(({store: {auth, buyer}, revisionId}) => {
    const {user} = auth;
    const {currentRequest} = buyer;
    const [isValidLink, setIsValidLink] = useState(true);
    const {t} = useTranslation();

    const revisionLinks = useMemo(
      () => currentRequest?.documents?.find((item) => item.id === revisionId).links ?? [],
      [currentRequest, revisionId]
    );

    const isDaluxButton =
      !!user.ActiveOrganization?.allowDalux &&
      !!currentRequest?.Project?.allowDalux &&
      !!currentRequest?.Project?.daluxProjectId;

    const handleChange = () => {
      !isValidLink && setIsValidLink(true);
    };

    const handleSubmit = ({link}) => {
      const isValid = extLinkDomains.some((item) => link.includes(item));
      setIsValidLink(isValid);
      if (!isValid) return;
      buyer.updateCurrentRequestInfo({
        documents: currentRequest.documents.map((item) =>
          item.id === revisionId
            ? {...item, links: [{url: link}, ...revisionLinks]}
            : item
        ),
      });
      buyer.setAddLinkModalStatus(false);
    };

    const handleAddDaluxLinks = () => {
      buyer.setAddDaluxLinkModalStatus(true);
      buyer.setAddLinkModalStatus(false);
    };

    return (
      <LocalModal
        onClose={() => buyer.setAddLinkModalStatus(false)}
        className={"addlink-modal__wrapper"}
      >
        <div className="addlink-modal">
          <div className="addlink-modal__description">{t("ALL_LINK_DESCRIPTION")}</div>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            <Form className="addlink-modal__form" onChange={handleChange}>
              {isValidLink ? <IconLink color={color.link} /> : <IconTrumbsDown />}
              <FormikField
                name="link"
                addClass={cn("addlink-modal__field", {__error: !isValidLink})}
                placeholder={t("ADD_LINK_PLACEHOLDER")}
                suffixIcon={
                  <ButtonTransparent
                    type="submit"
                    iconRight={<IconRightOn />}
                    addClass="addlink-modal__submit-button"
                    noBorder
                    tiny
                  />
                }
              />
            </Form>
          </Formik>
          {isDaluxButton && <Divider addClass="mt-4 mb-4" dotted />}
          {isDaluxButton && (
            <div className="df-row-center">
              <ButtonTransparent
                name="ADD_LINK_DALUX_BUTTON"
                iconRight={<IconDocumentDalux />}
                onClick={handleAddDaluxLinks}
                addClass="mr-2"
              />
              <InfoTooltip tooltip="ADD_LINK_DALUX_BUTTON_TOOLTIP" centerText />
            </div>
          )}
        </div>
      </LocalModal>
    );
  })
);

export default AddLinkModal;

import React from "react";
import Pagination from "react-js-pagination";
import {inject, observer} from "mobx-react";
import cn from "classnames";

export const PaginationType = inject("store")(
  observer(
    ({
      store: {organization},
      totalItems,
      currentPage,
      countPerPage,
      onPageChange,
      addClass,
    }) => {
      const handlePageChange = (pageNumber) =>
        pageNumber !== currentPage && onPageChange(pageNumber);

      return (
        <div className={cn("pagination-static_wrapper", {[addClass]: !!addClass})}>
          <Pagination
            activePage={parseInt(currentPage)}
            totalItemsCount={totalItems}
            itemsCountPerPage={countPerPage}
            pageRangeDisplayed={10}
            onChange={handlePageChange.bind(this)}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      );
    }
  )
);

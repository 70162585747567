import React, {memo} from "react";

export const IconClipboardUser = memo(({color, addClass}) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 20.5261H3C2.173 20.5261 1.5 19.8531 1.5 19.0261V5.02612C1.5 4.19912 2.173 3.52612 3 3.52612H4C4.276 3.52612 4.5 3.75012 4.5 4.02612C4.5 4.30212 4.276 4.52612 4 4.52612H3C2.724 4.52612 2.5 4.75012 2.5 5.02612V19.0261C2.5 19.3021 2.724 19.5261 3 19.5261H17C17.276 19.5261 17.5 19.3021 17.5 19.0261V5.02612C17.5 4.75012 17.276 4.52612 17 4.52612H16C15.724 4.52612 15.5 4.30212 15.5 4.02612C15.5 3.75012 15.724 3.52612 16 3.52612H17C17.827 3.52612 18.5 4.19912 18.5 5.02612V19.0261C18.5 19.8531 17.827 20.5261 17 20.5261Z"
        fill={color || "#000"}
      />
      <path
        d="M14.001 5.52612C14.001 5.52612 14.001 5.52612 14 5.52612H6C5.724 5.52612 5.5 5.30212 5.5 5.02612C5.5 4.02112 5.953 3.24012 6.776 2.82912C7.051 2.69112 7.323 2.61612 7.54 2.57512C7.753 1.41112 8.775 0.526123 9.999 0.526123C11.223 0.526123 12.245 1.41112 12.458 2.57512C12.676 2.61612 12.947 2.69112 13.222 2.82912C14.038 3.23712 14.49 4.00712 14.498 4.99912C14.499 5.00812 14.499 5.01712 14.499 5.02612C14.499 5.30212 14.275 5.52612 13.999 5.52612H14.001ZM6.56 4.52612H13.44C13.344 4.17012 13.133 3.90912 12.802 3.73612C12.413 3.53312 12.002 3.52612 11.997 3.52612C11.721 3.52612 11.5 3.30212 11.5 3.02612C11.5 2.19912 10.827 1.52612 10 1.52612C9.173 1.52612 8.5 2.19912 8.5 3.02612C8.5 3.30212 8.276 3.52612 8 3.52612C7.999 3.52612 7.587 3.53312 7.198 3.73612C6.867 3.90912 6.656 4.16912 6.56 4.52612Z"
        fill={color || "#000"}
      />
      <path
        d="M10 3.52612C9.868 3.52612 9.739 3.47312 9.647 3.37912C9.555 3.28512 9.5 3.15712 9.5 3.02612C9.5 2.89512 9.553 2.76512 9.647 2.67312C9.74 2.58012 9.869 2.52612 10 2.52612C10.131 2.52612 10.261 2.57912 10.353 2.67312C10.446 2.76612 10.5 2.89512 10.5 3.02612C10.5 3.15712 10.447 3.28612 10.353 3.37912C10.26 3.47212 10.131 3.52612 10 3.52612Z"
        fill={color || "#000"}
      />
      <path
        d="M13.0003 17.5261H7.00028C6.57528 17.5261 6.20428 17.3491 5.98128 17.0401C5.75828 16.7311 5.70828 16.3231 5.84228 15.9201C5.86428 15.8551 6.07128 15.2711 6.69128 14.6881C7.25528 14.1581 8.28728 13.5271 10.0003 13.5271C11.7133 13.5271 12.7453 14.1581 13.3093 14.6881C13.9293 15.2711 14.1363 15.8551 14.1583 15.9201C14.2923 16.3231 14.2423 16.7311 14.0193 17.0401C13.7963 17.3491 13.4253 17.5261 13.0003 17.5261ZM10.0003 14.5261C7.39128 14.5261 6.79628 16.2181 6.79128 16.2351C6.76028 16.3281 6.76129 16.4101 6.79329 16.4541C6.82529 16.4981 6.90328 16.5251 7.00128 16.5251H13.0013C13.0993 16.5251 13.1773 16.4981 13.2093 16.4541C13.2413 16.4101 13.2423 16.3291 13.2123 16.2371C13.1803 16.1481 12.5613 14.5251 10.0023 14.5251L10.0003 14.5261Z"
        fill={color || "#000"}
      />
      <path
        d="M10 12.5261C8.622 12.5261 7.5 11.4041 7.5 10.0261C7.5 8.64812 8.622 7.52612 10 7.52612C11.378 7.52612 12.5 8.64812 12.5 10.0261C12.5 11.4041 11.378 12.5261 10 12.5261ZM10 8.52612C9.173 8.52612 8.5 9.19912 8.5 10.0261C8.5 10.8531 9.173 11.5261 10 11.5261C10.827 11.5261 11.5 10.8531 11.5 10.0261C11.5 9.19912 10.827 8.52612 10 8.52612Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

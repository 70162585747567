import React from "react";
import cn from "classnames";

const ButtonCloseDrawer = ({closeDrawer, addClass, ifOpen}) => {
  return (
    <button
      onClick={closeDrawer}
      className={cn(`btn-custom_close-modal`, [addClass], {_active: ifOpen})}
    />
  );
};

export default ButtonCloseDrawer;

import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
//local
import MonitoringIllRadarBg from "assets/images/svg/monitoring-radar-bg.svg";
import MonitoringIllRadarRotator from "assets/images/png/monitoring-radar-rotator.png";

const DisabledMonitoringSection = inject("store")(
  observer(({store: {nsi}}) => {
    const {t} = useTranslation();

    const [monitoringCodes, setMonitoringCodes] = useState([]);

    useEffect(() => {
      nsi.getAllMonitorSuppliersFilters().then(setMonitoringCodes);
    }, [nsi]);

    return (
      <div className="monitoring disabled">
        <div className="top">
          <div className="illustration">
            <img src={MonitoringIllRadarBg} alt="" className="illustration-bg" />
            <img src={MonitoringIllRadarRotator} alt="" className="illustration-moving" />
          </div>
          <div>
            <h1>{t("MONITORING_TAB_INACTIVE_CTA_HEADING")}</h1>
            <ul>
              <li>{t("MONITORING_TAB_INACTIVE_CTA_USP_1")}</li>
              <li>{t("MONITORING_TAB_INACTIVE_CTA_USP_2")}</li>
            </ul>
            <a
              href={`${t("URL_TO_PUBLIC_CONTACT_PAGE")}`}
              className="cta"
              target="_BLANK"
              rel="noreferrer"
            >
              {t("MONITORING_TAB_INACTIVE_CTA")}
            </a>
          </div>
        </div>
        <h4>{t("MONITORING_LABEL_HEADER")}</h4>
        <ul className="smaller-text">
          {monitoringCodes.map((code) => (
            <li key={code}>{t(`MONITORING_${code}_LABEL`)}</li>
          ))}
        </ul>
      </div>
    );
  })
);

export default DisabledMonitoringSection;

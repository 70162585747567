import React from "react";
import {useTranslation} from "react-i18next";
//local
import {CustomTooltip} from "components/CustomTooltip";
import cn from "classnames";

export const TextBadge = ({
  text,
  tooltip,
  color,
  centerTooltipText,
  noTooltipTranslate,
  small,
  addClass,
}) => {
  const {t} = useTranslation();
  return tooltip ? (
    <CustomTooltip
      text={tooltip}
      centerText={centerTooltipText}
      noTranslate={noTooltipTranslate}
    >
      <span
        className={cn(
          "text-badge __uppercase",
          {[addClass]: !!addClass, __small: !!small},
          color
        )}
      >
        {t(text)}
      </span>
    </CustomTooltip>
  ) : (
    <span
      className={cn(
        "text-badge __uppercase",
        {[addClass]: !!addClass, __small: !!small},
        color
      )}
    >
      {t(text)}
    </span>
  );
};

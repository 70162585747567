import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ButtonTransparent} from "../../../buttons/ButtonTransparent";

export const Logout = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="settings-account_logout__wrapper">
      <h4 className="__uppercase">{t("LOGOUT_LOG_OUT_OF_HANDYDAY")}?</h4>
      <p>{t("LOGOUT_OF_HANDYDAY_DESCRIPTION")}</p>
      <ButtonTransparent
        name={t("LOGOUT_LOG_OUT_OF_HANDYDAY")}
        onClick={logout}
        addClass="__danger"
      />
    </div>
  );
};

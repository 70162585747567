import React, {memo} from "react";

export const IconPushPin = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9999 6.79301L12.7069 1.50001C12.5189 1.31201 12.2669 1.20801 11.9999 1.20801C11.7329 1.20801 11.4809 1.31201 11.2929 1.50001L10.9999 1.79301C10.7099 2.08301 10.4999 2.59001 10.4999 3.00001V4.00001C10.4999 4.14201 10.3939 4.39901 10.2929 4.50001L7.49995 7.29301C7.39895 7.39401 7.14195 7.50001 6.99995 7.50001H5.99995C5.58995 7.50001 5.08295 7.71001 4.79295 8.00001L4.49995 8.29301C4.10995 8.68301 4.10995 9.31701 4.49995 9.70701L6.05295 11.26L1.10295 17.695C0.949945 17.894 0.967945 18.176 1.14595 18.353C1.24295 18.45 1.37095 18.499 1.49995 18.499C1.60695 18.499 1.71395 18.465 1.80495 18.395L8.23994 13.445L9.79295 14.998C9.98095 15.186 10.2329 15.29 10.4999 15.29C10.7669 15.29 11.0189 15.186 11.2069 14.998L11.4999 14.705C11.7899 14.415 11.9999 13.908 11.9999 13.498V12.498C11.9999 12.356 12.1059 12.099 12.2069 11.998L14.9999 9.20501C15.1009 9.10401 15.3579 8.99801 15.4999 8.99801H16.4999C16.9099 8.99801 17.4169 8.78801 17.7069 8.49801L17.9999 8.20501C18.1879 8.01701 18.2919 7.76501 18.2919 7.49801C18.2919 7.23101 18.1879 6.97901 17.9999 6.79101V6.79301ZM4.23395 15.266L6.76695 11.973L7.52695 12.733L4.23395 15.266ZM16.9999 7.79301C16.8989 7.89401 16.6419 8.00001 16.4999 8.00001H15.4999C15.0899 8.00001 14.5829 8.21001 14.2929 8.50001L11.4999 11.293C11.2099 11.583 10.9999 12.09 10.9999 12.5V13.5C10.9999 13.642 10.8939 13.899 10.7929 14L10.5009 14.292H10.4999V14.293L5.20694 9.00001L5.49995 8.70701C5.60094 8.60601 5.85794 8.50001 5.99995 8.50001H6.99995C7.40995 8.50001 7.91694 8.29001 8.20694 8.00001L10.9999 5.20701C11.2899 4.91701 11.4999 4.41001 11.4999 4.00001V3.00001C11.4999 2.85801 11.6059 2.60101 11.7069 2.50001L11.9999 2.20701L17.2929 7.50001L16.9999 7.79301Z"
        fill={color || "#1BA2CC"}
      />
    </svg>
  );
});

export default IconPushPin;

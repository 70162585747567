import React, {useState} from "react";
import {FieldArray, Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
// locale
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconPlus} from "components/icons/index";
import MemberForm from "./MemberForm";
import {schema, initialValues} from "./formAttrs";

const MemberOfEdit = inject("store")(
  observer(({store: {organization}, closeEdit}) => {
    const {t} = useTranslation();
    const members = (organization.organizationDetail?.Members?.length &&
      organization.organizationDetail?.Members) || [initialValues];
    const [loading, setLoading] = useState(false);

    const membersForms = (members, remove) =>
      members.map((member, i) => <MemberForm key={i} index={i} remove={remove} />);

    const onSubmit = (values) => {
      setLoading(true);
      organization
        .membersEdit(values)
        .then(() => {
          closeEdit();
        })
        .finally(() => setLoading(false));
    };

    return (
      <Formik initialValues={{members}} validationSchema={schema} onSubmit={onSubmit}>
        {({values}) => (
          <Form>
            <FieldArray
              name="members"
              render={(arrayHelpers) => (
                <div className="edit-insurance_wrapper editBlock_wrapper">
                  <div className="editBlock_head">
                    <div className="editBlock_head__title">{t("MEMBER_OF")}</div>
                    <div className="editBlock_head__actions">
                      <ButtonTransparent
                        name={t("SAVE_CHANGES")}
                        type="submit"
                        loading={loading}
                        addClass="editBlock_head__btn"
                      />
                      <ButtonTransparent
                        type="button"
                        name={t("CANCEL")}
                        addClass="editBlock_head__btn"
                        onClick={closeEdit}
                      />
                    </div>
                  </div>

                  <div className="editBlock_body">
                    {membersForms(values.members, arrayHelpers.remove)}
                    <div className="btn-custom_wrap">
                      <ButtonTransparent
                        name={t("ONE_MORE")}
                        type="button"
                        icon={<IconPlus />}
                        className="editBlock_head__btn"
                        onClick={() => {
                          arrayHelpers.push(initialValues);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            />
          </Form>
        )}
      </Formik>
    );
  })
);

export default MemberOfEdit;

import React, {memo} from "react";

export const IconExpand = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "21"}
      viewBox="0 0 20 21"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 18.0886H1.5C0.673 18.0886 0 17.4156 0 16.5886V4.58859C0 3.76159 0.673 3.08859 1.5 3.08859H17.5C18.327 3.08859 19 3.76159 19 4.58859V16.5886C19 17.4156 18.327 18.0886 17.5 18.0886ZM1.5 4.08859C1.224 4.08859 1 4.31259 1 4.58859V16.5886C1 16.8646 1.224 17.0886 1.5 17.0886H17.5C17.776 17.0886 18 16.8646 18 16.5886V4.58859C18 4.31259 17.776 4.08859 17.5 4.08859H1.5Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M16.5 5.08859H13.5C13.224 5.08859 13 5.31259 13 5.58859C13 5.86459 13.224 6.08859 13.5 6.08859H15.293L13.147 8.23459C12.952 8.42959 12.952 8.74659 13.147 8.94159C13.245 9.03959 13.373 9.08759 13.501 9.08759C13.629 9.08759 13.757 9.03859 13.855 8.94159L16.001 6.79559V8.58859C16.001 8.86459 16.225 9.08859 16.501 9.08859C16.777 9.08859 17.001 8.86459 17.001 8.58859V5.58859C17.001 5.31259 16.777 5.08859 16.501 5.08859H16.5Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M5.5 15.0886H3.707L5.853 12.9426C6.048 12.7476 6.048 12.4306 5.853 12.2356C5.658 12.0406 5.341 12.0406 5.146 12.2356L3 14.3816V12.5886C3 12.3126 2.776 12.0886 2.5 12.0886C2.224 12.0886 2 12.3126 2 12.5886V15.5886C2 15.8646 2.224 16.0886 2.5 16.0886H5.5C5.776 16.0886 6 15.8646 6 15.5886C6 15.3126 5.776 15.0886 5.5 15.0886Z"
        fill={color || "#1BA2CC"}
      />
    </svg>
  );
});

import React from "react";
import {
  Account,
  Members,
  Subscriptions,
  OrganizationSettings,
} from "../components/Settings/sections";
import {
  OUR_FAVOURITES_SUPPLIERS,
  OUR_FAVOURITES_MONITORED,
  OUR_FAVOURITES_QUALIFYING,
} from "../constants/routes.const";
import InviteUsers from "../components/Settings/sections/InviteUsers/InviteUsers";
import OverviewSection from "../pages/PresentationPage/sections/OverviewSection";
import FinancialSection from "../pages/PresentationPage/sections/FinancialsSection";
import OurEvaluationSection from "../pages/PresentationPage/sections/OurEvaluationSection";
import OurAgreementsSection from "../pages/PresentationPage/sections/OurAgreementsSection";
import ServicesSection from "../pages/PresentationPage/sections/ServicesSection";
import RequestSection from "../pages/RequestsToUs/components/SingleRequest/sections/RequestSection";
import OurOfferSection from "../pages/RequestsToUs/components/SingleRequest/sections/OurOfferSection";
import ProjectsTab from "pages/OurProjects/components/ProjectsTab";
import {
  IconUser,
  IconPaperPencil,
  IconWallet,
  IconUsers,
  IconEqualizer,
} from "components/icons";
import {
  entrepreneurOffersArray,
  withinArray,
  availableInArray,
  realEstateCompanyTypeArray,
  realEstateServicesArray,
  consultingServicesArray,
  salesRentalTypeArray,
  organizationsTypeArray,
  materialSupplierTypeArray,
} from "./select.consts";
import NoData from "components/NoData";

export const hashes = {
  ORGANIZATIONS: "#my-organisation",
  SUBSCRIPTION: "#your-subscription",
  SETTINGS: "#settings",
  MEMBERS: "#members",
  ACCOUNT: "#your-account",
  INVITE_USERS: "#invite-users",
};

export const tabTitles = {
  OUR_SUPPLIERS_TAB: "OUR_SUPPLIERS_TAB",
  MONITORING_TAB: "MONITORING_TAB",
  QUALIFYING_TAB: "QUALIFYING_TAB",
};

export const settingsTabs = [
  {
    title: "L_MEMBER",
    icon: <IconUsers color="black" />,
    content: Members,
    id: 0,
    hash: hashes.MEMBERS,
    visible: true,
  },
  {
    title: "INVITE_USERS",
    icon: <IconPaperPencil color="black" />,
    content: InviteUsers,
    id: 1,
    hash: hashes.INVITE_USERS,
    visible: true,
  },
  {
    title: "SUBSCRIPTION",
    icon: <IconWallet color="black" />,
    content: Subscriptions,
    id: 2,
    hash: hashes.SUBSCRIPTION,
    visible: true,
  },
  {
    title: "SETTINGS",
    icon: <IconEqualizer />,
    content: OrganizationSettings,
    id: 3,
    hash: hashes.SETTINGS,
    visible: true,
  },
  {
    title: "MY_ACCOUNT",
    icon: <IconUser color="black" />,
    content: Account,
    id: 4,
    hash: hashes.ACCOUNT,
    visible: true,
  },
];

export const presentationPageTabs = [
  {title: "OVERVIEW", content: OverviewSection, id: 0, hash: "#", visible: true},
  {title: "SERVICES", content: ServicesSection, id: 1, hash: "#", visible: true},
  {title: "FINANCIALS", content: FinancialSection, id: 2, hash: "#", visible: true},
  {
    title: "OUR_EVALUATION",
    content: OurEvaluationSection,
    id: 3,
    hash: "#",
    visible: true,
  },
  {
    title: "OUR_AGREEMENTS",
    content: OurAgreementsSection,
    id: 4,
    hash: "#",
    visible: true,
  },
];

export const findSuppliersFilterTabs = [
  {
    title: "ENTREPRENEUR",
    groupTitle: "WE_ARE_AN_ENTREPRENEUR",
    groupName: "entrepreneur",
    filterKey: "CONSTRUCTION",
    content: [
      {
        label: "entrepreneurWithin",
        array: withinArray,
        groupSubTitle: "ENTREPRENEURWITHIN",
        bottomElement: "WITHIN_07",
      },
      {
        label: "entrepreneurOffers",
        array: entrepreneurOffersArray,
        groupSubTitle: "ENTREPRENEUROFFERS",
      },
    ],
    id: 0,
    disabled: false,
    visible: true,
  },
  {
    title: "REAL_ESTATE",
    groupTitle: "WE_ARE_A_REAL_ESTATE_COMPANY",
    groupName: "realEstate",
    filterKey: "REAL_ESTATE",
    content: [
      {
        label: "realEstateCompanyType",
        array: realEstateCompanyTypeArray,
        groupSubTitle: "REALESTATECOMPANYTYPE",
      },
      {
        label: "realEstateServices",
        array: realEstateServicesArray,
        groupSubTitle: "REALESTATESERVICES",
      },
    ],
    id: 1,
    disabled: false,
    visible: true,
  },
  {
    title: "MATERIAL_SUPPLIER",
    groupTitle: "WE_ARE_A_MATERIAL_SUPPLIER",
    groupName: "materialSupplier",
    filterKey: "MATERIAL",
    content: [
      {
        label: "materialSupplierType",
        array: materialSupplierTypeArray,
        groupSubTitle: "MATERIALSUPPLIERTYPE",
      },
    ],
    id: 2,
    disabled: false,
    visible: true,
  },
  {
    title: "CONSULTANT",
    groupTitle: "WE_ARE_CONSULTANTS",
    groupName: "consulting",
    filterKey: "CONSULTING",
    content: [
      {
        label: "consultingServices",
        array: consultingServicesArray,
        groupSubTitle: "CONSULTINGSERVICES",
        bottomElement: "CONSULTING_SERVICES_43",
      },
    ],
    id: 3,
    disabled: false,
    visible: true,
  },
  {
    title: "SALES_RENTAL",
    groupTitle: "WE_ARE_A_SALES_RENTAL_COMPANY",
    groupName: "salesRental",
    filterKey: "SALES_RENTAL",
    content: [
      {
        label: "salesRentalType",
        array: salesRentalTypeArray,
        groupSubTitle: "SALESRENTALTYPE",
      },
    ],
    id: 4,
    disabled: false,
    visible: true,
  },
  {
    title: "ORGANISATIONS",
    groupTitle: "WE_ARE_A_ORGANIZATIONS",
    groupName: "organizations",
    filterKey: "ORGANISATIONS",
    content: [
      {
        label: "organizationsType",
        array: organizationsTypeArray,
        groupSubTitle: "ORGANIZATIONSTYPE",
      },
    ],
    id: 5,
    disabled: false,
    visible: true,
  },
];

export const requestToUsSectionTabs = [
  {title: "REQUEST", content: RequestSection, id: 0, hash: "#", visible: true},
  {title: "OUR_OFFER", content: OurOfferSection, id: 1, hash: "#", visible: true},
];

export const projectsTabs = [
  {
    title: "MY",
    content: ProjectsTab,
    id: 0,
    hash: "#",
    visible: true,
    requestKey: "mine",
  },
  {
    title: "ALL_PROJECTS_TAB",
    content: ProjectsTab,
    id: 1,
    hash: "#",
    visible: true,
    requestKey: "all",
  },
  {
    title: "ARCHIVED",
    content: ProjectsTab,
    id: 2,
    hash: "#",
    visible: true,
    requestKey: "archived",
  },
];

export const procurementTabs = [
  {
    title: "PROCUREMENTS_OVERVIEW_TAB",
    content: undefined,
    id: 0,
    hash: "#",
    visible: true,
  },
  {title: "PROCUREMENTS_COST_TAB", content: undefined, id: 1, hash: "#", visible: true},
  {title: "PROCUREMENTS_DATES_TAB", content: undefined, id: 2, hash: "#", visible: true},
];

export const contractBuyerDocumentTabs = [
  {title: "BUYER_HIS_DOCUMENTS_TAB", content: NoData, id: 0, hash: "#", visible: true},
  {title: "BUYER_SELLER_DOCUMENTS_TAB", content: NoData, id: 1, hash: "#", visible: true},
];

export const contractSellerDocumentTabs = [
  {title: "SELLER_BUYER_DOCUMENTS_TAB", content: NoData, id: 0, hash: "#", visible: true},
  {title: "SELLER_HIS_DOCUMENTS_TAB", content: NoData, id: 1, hash: "#", visible: true},
];

export const rfpRecipientsFilterRow = [
  {
    label: "entrepreneurWithin",
    title: "FILTERROW_GENERAL_CONTRACTORS_BUTTON",
    color: "green",
    isNeedSearch: true,
    array: withinArray,
  },
  {
    label: "entrepreneurOffers",
    title: "FILTERROW_SUBCONTRACTORS_BUTTON",
    color: "green",
    isNeedSearch: true,
    array: entrepreneurOffersArray,
  },
  {
    label: "materialSupplierType",
    title: "FILTERROW_MATERIAL_BUTTON",
    color: "green",
    isNeedSearch: true,
    array: materialSupplierTypeArray,
  },
  {
    label: "consultingServices",
    title: "FILTERROW_CONSULTANT_BUTTON",
    color: "green",
    isNeedSearch: true,
    array: consultingServicesArray,
    bottomElement: "CONSULTING_SERVICES_43",
  },
  {
    label: "availableIn",
    title: "FILTERROW_REGION_BUTTON",
    color: "blue",
    isNeedSearch: false,
    array: availableInArray,
    // selectAll: "FIRST_SETUP_REGION_00",
  },
];

export const publicRfpsFilterRow = [
  {
    label: "entrepreneurWithin",
    title: "FILTERROW_GENERAL_CONTRACTORS_BUTTON",
    color: "green",
    isNeedSearch: true,
    array: withinArray,
  },
  {
    label: "entrepreneurOffers",
    title: "FILTERROW_SUBCONTRACTORS_BUTTON",
    color: "green",
    isNeedSearch: true,
    array: entrepreneurOffersArray,
  },
  {
    label: "materialSupplierType",
    title: "FILTERROW_MATERIAL_BUTTON",
    color: "green",
    isNeedSearch: true,
    array: materialSupplierTypeArray,
  },
  {
    label: "consultingServices",
    title: "FILTERROW_CONSULTANT_BUTTON",
    color: "green",
    isNeedSearch: true,
    array: consultingServicesArray,
    bottomElement: "CONSULTING_SERVICES_43",
  },
  {
    label: "availableIn",
    title: "FILTERROW_REGION_BUTTON",
    color: "blue",
    isNeedSearch: false,
    array: availableInArray,
    // selectAll: "FIRST_SETUP_REGION_00",
  },
];

export const rfpCategoriesFilterRow = [
  {
    label: "entrepreneurWithin",
    title: "RFP_CATEGORIES_ENTREPRENEURWITHIN_BUTTON",
    color: "blue",
    isNeedSearch: true,
    array: withinArray,
  },
  {
    label: "entrepreneurOffers",
    title: "RFP_CATEGORIES_ENTREPRENEUROFFERS_BUTTON",
    color: "blue",
    isNeedSearch: true,
    array: entrepreneurOffersArray,
  },
  {
    label: "materialSupplierType",
    title: "RFP_CATEGORIES_MATERIAL_BUTTON",
    color: "blue",
    isNeedSearch: true,
    array: materialSupplierTypeArray,
  },
  {
    label: "consultingServices",
    title: "RFP_CATEGORIES_CONSULTANT_BUTTON",
    color: "blue",
    isNeedSearch: true,
    array: consultingServicesArray,
    bottomElement: "CONSULTING_SERVICES_43",
  },
];

export const subscriptionTabs = [
  {
    title: "SUBSCRIPTION_TAB_01",
    description: "SUBSCRIPTION_TAB_01_DESCRIPTION",
    subscriptionsCount: 2,
    id: 0,
  },
  {
    title: "SUBSCRIPTION_TAB_02",
    description: "SUBSCRIPTION_TAB_02_DESCRIPTION",
    subscriptionsCount: 3,
    id: 1,
  },
  {
    title: "SUBSCRIPTION_TAB_03",
    description: "SUBSCRIPTION_TAB_03_DESCRIPTION",
    subscriptionsCount: 2,
    id: 2,
  },
];

export const smartTemplateTabs = [
  {
    title: "SMART_TEMPLATE_TAB_01",
    description: "SMART_TEMPLATE_TAB_01_DESCRIPTION",
    id: 0,
  },
  {
    title: "SMART_TEMPLATE_TAB_02",
    description: "SMART_TEMPLATE_TAB_02_DESCRIPTION",
    id: 1,
  },
];

export const ourSuppliersPageTabs = [
  {
    title: tabTitles.OUR_SUPPLIERS_TAB,
    id: 0,
    hash: OUR_FAVOURITES_SUPPLIERS,
    visible: true,
  },
  {title: tabTitles.MONITORING_TAB, id: 1, hash: OUR_FAVOURITES_MONITORED, visible: true},
  {
    title: tabTitles.QUALIFYING_TAB,
    id: 2,
    hash: OUR_FAVOURITES_QUALIFYING,
    visible: true,
  },
];

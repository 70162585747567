import React, {memo} from "react";

export const IconFirstAddKit = memo(({height, width, color, addClass}) => {
  return (
    <svg
      width={height || "20"}
      height={width || "20"}
      viewBox="0 0 20 20"
      className={addClass}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 16H8.5C8.224 16 8 15.776 8 15.5V14H6.5C6.224 14 6 13.776 6 13.5V10.5C6 10.224 6.224 10 6.5 10H8V8.5C8 8.224 8.224 8 8.5 8H11.5C11.776 8 12 8.224 12 8.5V10H13.5C13.776 10 14 10.224 14 10.5V13.5C14 13.776 13.776 14 13.5 14H12V15.5C12 15.776 11.776 16 11.5 16ZM9 15H11V13.5C11 13.224 11.224 13 11.5 13H13V11H11.5C11.224 11 11 10.776 11 10.5V9H9V10.5C9 10.776 8.776 11 8.5 11H7V13H8.5C8.776 13 9 13.224 9 13.5V15Z"
        fill={color || "#000"}
      />
      <path
        d="M18.5 5H14V3.5C14 2.673 13.327 2 12.5 2H7.5C6.673 2 6 2.673 6 3.5V5H1.5C0.673 5 0 5.673 0 6.5V17.5C0 18.327 0.673 19 1.5 19H18.5C19.327 19 20 18.327 20 17.5V6.5C20 5.673 19.327 5 18.5 5ZM7 3.5C7 3.224 7.224 3 7.5 3H12.5C12.776 3 13 3.224 13 3.5V5H7V3.5ZM19 17.5C19 17.776 18.776 18 18.5 18H1.5C1.224 18 1 17.776 1 17.5V6.5C1 6.224 1.224 6 1.5 6H18.5C18.776 6 19 6.224 19 6.5V17.5Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconFirstAddKit;

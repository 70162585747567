import React, {useMemo} from "react";
import WorldFlag from "react-world-flags";
import {useTranslation} from "react-i18next";
// local
import {CustomTooltip} from "components/CustomTooltip";
import countryLookup from "country-code-lookup";
import EmptyOrNoCompanyCreditData from "components/EmptyOrNoCompanyCreditData";
import {dateFormat} from "helpers/datetime";
import {
  IconTag,
  IconCake,
  IconCompanyGroup,
  IconCompanyGroupParent,
  IconQuestionConnection,
  IconIsConnection,
} from "components/icons";
import {LockValue} from "components/LockValue";

const convertToDate = (dateString) => {
  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(4, 6), 10) - 1; // months are 0-indexed in JS
  const day = parseInt(dateString.substring(6, 8), 10);
  return new Date(year, month, day);
};

const CorporateStructure = ({companyData, hasFirstUpdateData}) => {
  const {t} = useTranslation();
  const {
    incorporationDate,
    companyTypeText,
    groupMotherOrgNr,
    groupMotherName,
    immediateGroupMotherOrgNr,
    immediateGroupMotherName,
  } = companyData?.Company?.basic || {};

  const groupMotherCountryCode = useMemo(() => {
    const countryCode =
      companyData?.Company?.basic?.groupMotherCountryCode ||
      companyData?.Company?.basic?.groupmotherCountryCode;
    return countryCode ? countryCode.toLowerCase() : null;
  }, [companyData?.Company?.basic]);

  const countryName = groupMotherCountryCode
    ? countryLookup.byIso(groupMotherCountryCode)?.country
    : "Unknown";

  const groupMotherExists = !!groupMotherName?.length;
  const immediateGroupMotherExists = !!immediateGroupMotherName?.length;
  const sameCompany = groupMotherOrgNr && groupMotherOrgNr === immediateGroupMotherOrgNr;
  const bothExistButSame = groupMotherExists && immediateGroupMotherExists && sameCompany;
  const creditsafeStupidlyPutChildCompanyAsMother =
    companyData?.Company?.companyNumber === groupMotherOrgNr;
  // example of creditsafeStupidlyPutChildCompanyAsMother: https://app.handyday.com/organizations/033adc6a-6159-4843-8835-4f3f15c49864
  const partOfGroup =
    !!groupMotherName?.length && !creditsafeStupidlyPutChildCompanyAsMother;
  // check if group mother exists, and it is not the child company. Also
  // check that CS did not creditsafeStupidlyPutChildCompanyAsMother

  return (
    <LockValue
      canPermissions={["READ_OTHER_ANNUAL_REPORT"]}
      noHideOurInfo
      fullSubcribeButton
      needMiniSibscribeButton
    >
      {companyData?.Company?.updatedCreditSafeCredit != null ? (
        <div className="group-structure df-column">
          <div className="df-row-center">
            {companyTypeText && ( // Show company type if it exists
              <div className="company-type df-row-center mr-4">
                <IconTag addClass="mr-2" />
                {companyTypeText}
              </div>
            )}
            {incorporationDate && ( // Show incorporation date if it exists
              <div className="incorporation-date df-row-center">
                <IconCake addClass="mr-2" />
                {dateFormat(convertToDate(incorporationDate))}
              </div>
            )}

            {partOfGroup ? (
              <div className="part-of-group df-row-center ml-4">
                {t("CORPORATE_GROUP_HEADING")}
              </div>
            ) : (
              <div className="not-part-of-group df-row-center ml-4">
                {t("CORPORATE_GROUP_NO_GROUP_HEADING")}
              </div>
            )}
          </div>

          {partOfGroup && !creditsafeStupidlyPutChildCompanyAsMother && (
            <div className="group mt-4">
              {bothExistButSame && (
                // both exist and are the same AND Creditsafe did not screw up
                // by putting the child company as its own mother
                <React.Fragment>
                  <div className="df-row-center">
                    <IconCompanyGroup addClass="mr-2" />
                    {groupMotherName}
                    <span className="ml-2">{groupMotherOrgNr}</span>
                    {groupMotherCountryCode && (
                      <span className="flag ml-2">
                        <CustomTooltip text={countryName}>
                          <WorldFlag code={groupMotherCountryCode} height="16" />
                        </CustomTooltip>
                      </span>
                    )}
                    <span className="group-type">
                      {t("CORPORATE_STRUCTURE_GROUP_MOTHER")}
                    </span>
                  </div>
                  <div className="df-row-center">
                    <IconIsConnection />
                  </div>
                  <div className="df-row-center">
                    <IconTag addClass="mr-2" />
                    {companyData?.Company?.companyName}
                  </div>
                </React.Fragment>
              )}

              {groupMotherExists && immediateGroupMotherExists && !sameCompany && (
                // both exist and not the same
                <React.Fragment>
                  <div className="df-row-center">
                    <IconCompanyGroup addClass="mr-2" />
                    {groupMotherName}
                    <span className="ml-2">{groupMotherOrgNr}</span>
                    {groupMotherCountryCode && (
                      <span className="flag ml-2">
                        <CustomTooltip text={countryName}>
                          <WorldFlag code={groupMotherCountryCode} height="16" />
                        </CustomTooltip>
                      </span>
                    )}
                    <span className="group-type">
                      {t("CORPORATE_STRUCTURE_GROUP_MOTHER")}
                    </span>
                  </div>
                  <div className="df-row-center">
                    <IconQuestionConnection />
                  </div>
                  <div className="df-row-center">
                    <IconCompanyGroupParent addClass="mr-2" />
                    {immediateGroupMotherName}
                    <span className="ml-2">{immediateGroupMotherOrgNr}</span>
                    <span className="group-type">
                      {t("CORPORATE_STRUCTURE_IMMEDIATE_GROUP_MOTHER")}
                    </span>
                  </div>
                  <div className="df-row-center">
                    <IconIsConnection />
                  </div>
                  <div className="df-row-center">
                    <IconTag addClass="mr-2" />
                    {companyData?.Company?.companyName}
                    <span className="ml-2">{companyData?.Company?.companyNumber}</span>
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="no-group-data df-row-center">
          <h4 className="df-row-center mr-8">
            <IconCompanyGroup addClass="mr-2" />{" "}
            {t("CORPORATE_STRUCTURE_NO_DATA_HEADING")}:
          </h4>
          <EmptyOrNoCompanyCreditData hasFirstUpdateData={hasFirstUpdateData} />
        </div>
      )}
    </LockValue>
  );
};

export default CorporateStructure;

import React from "react";
import {Select} from "antd";
import {useTranslation} from "react-i18next";
//local
import cn from "classnames";
import {IconCheck} from "components/icons";
import EmptyData from "components/EmptyData";
import {color} from "constants/color.consts";

const SelectDocColumn = (props) => {
  const {
    title,
    titleIcon,
    elements,
    selected,
    editable,
    onChangeSelect,
    label,
    className,
  } = props;
  const {Option} = Select;
  const {t} = useTranslation();

  const options = () =>
    elements.length
      ? elements?.map((item, i) => (
          <Option key={`${i}${item}`} value={item}>
            {t(item)}
          </Option>
        ))
      : [];

  return (
    <div
      className={cn(
        "load-document-column__wrapper contract-type",
        {__edit: editable},
        {[className]: !!className}
      )}
    >
      {title && (
        <h4 className="load-document-column__title">
          {titleIcon} {t(title)}
        </h4>
      )}

      {label && <div className="mb-1">{t(label)}</div>}
      {editable ? (
        <Select
          className="load-document-column__select"
          defaultValue={selected}
          bordered={false}
          onChange={onChangeSelect}
        >
          {options()}
        </Select>
      ) : (
        <div className="load-document-column__selected">
          {selected ? <IconCheck color={color.green} /> : <EmptyData />}
          {t(selected)}
        </div>
      )}
    </div>
  );
};

export default SelectDocColumn;

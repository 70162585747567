import React, {useState} from "react";
import {useTranslation} from "react-i18next";
//local
import {Input} from "components/Input";
import {IconMenuCircle} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import ButtonDelete from "components/buttons/ButtonDelete";
import Select from "components/selects/Select";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {smartFormTypesSelect, smartFormNumberTypesSelect} from "constants/select.consts";

const SmartFormQuestionItemEdit = ({question, onChange, onDelete}) => {
  const {t} = useTranslation();
  const [questionLabel, setQuestionLabel] = useState(question.label);

  const handleChange = (e) => {
    setQuestionLabel(e.target.value);
    onChange({...question, label: e.target.value});
  };

  const HeadingRightSide = () => (
    <div className="df">
      <ButtonDelete activeOnHover onClick={() => onDelete(question.id)} addClass="mr-4" />
      <ButtonTransparent
        icon={<IconMenuCircle />}
        noBorder
        circular
        onClick={() => true}
        addClass="pa-0 drag-button"
        small
      />
    </div>
  );

  const RowRightSide = () => (
    <div className="df">
      {question.type === "number" && (
        <Select
          list={smartFormNumberTypesSelect}
          addClass="selection first-setup_selection__item ml-4"
          value={question.measurementUnit}
          small
          showArrow
          allowClear
          placeholder="RFP_SMART_QUESTION_UNIT_PLACEHOLDER"
          transparentSelectedItem
          alignRightDropdownRows
          addDropdownClass="question-select-dropdown"
          color="purple"
          onChange={(value) => onChange({...question, measurementUnit: value})}
        />
      )}
      <Select
        list={smartFormTypesSelect}
        addClass="selection first-setup_selection__item ml-4"
        value={question.type}
        small
        tinyFont
        bold
        showArrow
        transparentSelectedItem
        uppercase
        addDropdownClass="__uppercase question-select-dropdown"
        color="green"
        onChange={(value) =>
          onChange(
            value === "number"
              ? {...question, type: value, measurementUnit: null}
              : {...question, type: value}
          )
        }
      />
      <CustomRadioSwitch
        label={t("REQUIRED")}
        addWrapperClass="ml-2"
        checked={question.required}
        uppercase
        bold
        isActive={(checked) => onChange({...question, required: checked})}
      />
      <ButtonDelete
        activeOnHover
        onClick={() => onDelete(question.id)}
        addClass="ml-2 mr-4"
      />
      <ButtonTransparent
        icon={<IconMenuCircle />}
        noBorder
        circular
        onClick={() => true}
        addClass="pa-0 drag-button"
        small
      />
    </div>
  );

  return (
    <div className="question-answer-item">
      {question.type === "heading" ? (
        <Input
          tiny
          uppercaseText
          boldText
          width="200px"
          placeholder={t("SMART_FORM_QUESTIONS_HEADING_PLACEHOLDER")}
          className="input"
          value={questionLabel}
          onChange={handleChange}
        />
      ) : (
        <Input
          tiny
          fullWidth
          placeholder={t("SMART_FORM_QUESTIONS_ROW_PLACEHOLDER")}
          className="input"
          value={questionLabel}
          onChange={handleChange}
        />
      )}
      {question.type === "heading" ? <HeadingRightSide /> : <RowRightSide />}
    </div>
  );
};

export default SmartFormQuestionItemEdit;

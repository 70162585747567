import React, {memo} from "react";

export const IconMailLetter = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "16"}
      height={height || "10"}
      viewBox="0 0 16 10"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8765 0.5H1.87646C1.25621 0.5 0.751465 1.00475 0.751465 1.625V8.375C0.751465 8.99525 1.25621 9.5 1.87646 9.5H13.8765C14.4967 9.5 15.0015 8.99525 15.0015 8.375V1.625C15.0015 1.00475 14.4967 0.5 13.8765 0.5ZM13.8765 1.25C13.899 1.25 13.92 1.25225 13.9417 1.256L8.29272 5.02175C8.07521 5.1665 7.67846 5.1665 7.46097 5.02175L1.81196 1.256C1.83296 1.25225 1.85471 1.25 1.87721 1.25H13.8772H13.8765ZM13.8765 8.75H1.87646C1.66946 8.75 1.50146 8.582 1.50146 8.375V1.9505L7.04471 5.64575C7.27796 5.801 7.57721 5.879 7.87646 5.879C8.17571 5.879 8.47497 5.801 8.70821 5.64575L14.2515 1.9505V8.375C14.2515 8.582 14.0835 8.75 13.8765 8.75Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

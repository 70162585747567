import React from "react";
import {DatePicker} from "formik-antd";
import {ErrorMessage} from "formik";
import {checkField} from "helpers/helper";
import {useTranslation} from "react-i18next";
import cn from "classnames";

const {MonthPicker, WeekPicker} = DatePicker;

const EditDatePicker = ({
  label,
  name,
  small,
  onChange,
  placeholder,
  addClass,
  months,
  weeks,
  ...props
}) => {
  const {t} = useTranslation();

  const internals = {
    className: cn("editBlock_date-picker__field", {__small: !!small}),
    placeholder: t(placeholder ?? "SELECT_DATE"),
    name,
    onChange,
  };

  return (
    <div className={cn("editBlock_date-picker__item", {[addClass]: !!addClass})}>
      {label ? <label className="editBlock_field__label">{label}</label> : null}
      {months && <MonthPicker {...internals} {...props} />}
      {weeks && <WeekPicker {...internals} {...props} />}
      {!months && !weeks && <DatePicker {...internals} {...props} />}
      {name && (
        <ErrorMessage name={name}>
          {(msg) => <p className="form-error">{checkField(msg)}</p>}
        </ErrorMessage>
      )}
    </div>
  );
};

export default EditDatePicker;

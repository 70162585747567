import React from "react";
import {useTranslation} from "react-i18next";
import CreditUpdate from "./CreditUpdate";
import {InfoTooltip} from "components/InfoTooltip";
import {LockValue} from "components/LockValue";
import CreditRatingScore from "components/CreditRatingScore";

const CreditRating = ({creditRating}) => {
  const {t} = useTranslation();

  return (
    <div className="presentation-page__left-side__cards">
      <LockValue
        canPermissions={[
          "READ_OTHER_CREDIT_RATING",
          "READ_OTHER_NON_PAYMENTS",
          "READ_OTHER_TURNOVER",
          "READ_OTHER_QUICK_FACTS",
        ]}
        noHideOurInfo
        needAllPermissions
        fullSubcribeButton
        fullSubscribeVerticalPosition
        fullSubcribeButtonClassName="mb-6"
      >
        <CreditUpdate />
      </LockValue>
      <LockValue
        canPermissions={["READ_OTHER_CREDIT_RATING"]}
        needLabel
        needRightIcon={false}
        noHideOurInfo
        addClassIcon="mb-2"
      >
        <h5 className="df __uppercase">
          {t("CREDIT_RATING")}
          <InfoTooltip tooltip="CREDIT_RATING_TOOLTIP" centerText addClass="ml-2" />
        </h5>
      </LockValue>

      <CreditRatingScore creditRating={creditRating} addClass="our-profile-card" />
    </div>
  );
};

export default CreditRating;

import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
//local
import {InfoTooltip} from "components/InfoTooltip";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {hasTranslation} from "helpers/helper";
import ComingSoon from "assets/images/svg/coming-soon.svg";
import {
  subscriptionPriceType,
  subscriptionPriceTypeDescription,
} from "constants/user.consts";
import cn from "classnames";

export const Subscription = ({
  subscription,
  subscriptionsCount,
  changeSubscription,
  options,
}) => {
  const {t} = useTranslation();
  const location = useLocation();

  const isActive = useMemo(() => subscription.activeStatus, [subscription]);

  useEffect(() => {
    if (!!location.state?.purchasedSubscription) {
      location.state.purchasedSubscription === subscription.name &&
        !subscription.isContactUs &&
        changeSubscription(subscription.id);
    }
  }, [
    location.state,
    subscription.name,
    subscription.id,
    changeSubscription,
    subscription.isContactUs,
  ]);

  return (
    <div className={`subscription __${subscriptionsCount}`}>
      <div className={cn("df-column-start", {__active: !isActive})}>
        {/* Aleks Lykov, please create a section here where we can add a graphic depending on which
        subscription numer it is. I can then style it relative to this box and place it anywhere within
        the subscription box */}

        {subscription.icon}
        <h3 className={cn("subscription__title", {__active: !isActive})}>
          {t(subscription.name)}
        </h3>
        <span className="subscription__description">
          {t(subscription.descriptionKey)}
        </span>
      </div>

      <ul className={cn("subscription__options", {__active: !isActive})}>
        {!!options?.length &&
          options
            .filter((item) => hasTranslation(item))
            .map((item) => (
              <li className="subscription__option" key={item}>
                <span className="truncate-me">{t(item)}</span>
              </li>
            ))}
      </ul>

      <div className="subscription__price">
        {subscription.isContactUs && (
          <span className="contact-us">{t("SUBSCRIPTION_TAB_CONTACT_DESCRIPTION")}</span>
        )}
        {!subscription.isContactUs && !subscription.isFake && (
          <React.Fragment>
            {subscription?.hasPeriods && (
              <span className="mr-2">{t("SUBSCRIPTION_TAB_PRICE_FROM")}</span>
            )}
            <span className="amount pr-2">
              {subscription.isCampaign && (
                <span className="offer-price">{subscription.price}</span>
              )}

              <span className={`price ${subscription.isCampaign ? "normal-price" : ""}`}>
                {!subscription.isCampaign ? (
                  <>{subscription.price}</>
                ) : (
                  <>{t("INACTIVE_PRICE_SUBSCRIPTION_NAME_06")}</>
                )}
              </span>
            </span>
            <span className="price-info">
              <span className="price-extras">
                <span className="price-type">
                  {t(
                    subscriptionPriceType[subscription.priceType] ??
                      subscriptionPriceType.PRICE_PER_MONTH
                  )}
                </span>

                {subscription.isCampaign && (
                  <>
                    <span className="special-offer">
                      {t("SUBSCRIPTION_PRICETYPE_INTRO")}
                    </span>
                    {/* temporarily hiding this until we need it again */}
                    {/*
                    <span className="special-offer-terms">
                      {t("SUBSCRTIPTION_PRICETYPE_TERMS")}
                    </span>
                    */}
                  </>
                )}
              </span>
              {!subscription.isFree && (
                <InfoTooltip
                  tooltip={
                    subscription.isCampaign
                      ? "INTRO_TOOLTIP_" + subscription.name
                      : subscriptionPriceTypeDescription[subscription.priceType]
                  }
                  centerText
                  addClass="ml-2"
                />
              )}
            </span>
          </React.Fragment>
        )}
        {!subscription.isContactUs && subscription.isFake && (
          <div className="subscription__coming-soon-star">
            <img src={ComingSoon} alt="" />
            <div className="subscription__coming-soon-star__text">
              {`${t("COMING_SOON_SPLASH_01")}\n${t("COMING_SOON_SPLASH_02")}`}
            </div>
          </div>
        )}
      </div>
      <div className={cn("subscription__status", {__active: !!isActive})}>
        {isActive ? (
          <span className="fz-20 __bold green __uppercase">
            {t("SUBSCRIPTION_TAB_ACTIVE_NOTE")}
          </span>
        ) : subscription.isContactUs ? (
          <ButtonTransparent
            name={"SUBSCRIPTION_TAB_CONTACT_BUTTON"}
            medium
            onClick={() => window.open("https://www.handyday.com/contact")}
          />
        ) : (
          <ButtonTransparent
            name={
              subscription.isFake
                ? "SUBSCRIPTION_TAB_FAKE_BUTTON"
                : "SUBSCRIPTION_TAB_GET_BUTTON"
            }
            medium
            nonTransparent
            onClick={() =>
              subscription.isFake
                ? window.open("https://www.handyday.com/contact")
                : changeSubscription(subscription.id)
            }
          />
        )}
      </div>
    </div>
  );
};

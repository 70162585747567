import React, {useState, useRef, useEffect} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import {DatePicker} from "formik-antd";
import {v4 as uuidv4} from "uuid";
//local
import {IconDocumentPen} from "components/icons";
import {getStorage, setStorage, removeStorage} from "helpers/storage";
import SimpleField from "components/SimpleField";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import LoadDocsColumn from "components/LoadDocsColumn";
import {ConfirmModal} from "components/ConfirmModal";
import SmartFormAnswers from "pages/OurRequests/components/SmartForm/SmartFormAnswers";
import {showSuccess} from "helpers/notifications.helper";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";
import {schema} from "./formAttrs";
import cn from "classnames";

const EditOurOffers = inject("store")(
  observer(({store: {seller}, create, edit, setEdit}) => {
    const {request, offerFiles} = seller;
    const formRef = useRef();
    const {t} = useTranslation();

    const [uploadLoading, setUploadLoading] = useState(false);
    const [requiredFilled, setRequiredFilled] = useState(false);
    const [initialOfferFiles] = useState(offerFiles);
    const [loading, setLoading] = useState(false);

    // question without headers (only relevant)
    const questions =
      request?.Request?.smartFormQuestions?.filter((item) => item.type !== "heading") ??
      [];
    // are there any mandatory questions to answer
    const isRequiredQuestions = !!questions?.filter((item) => item.required).length;
    // seller request from draft (localStorage)
    const requestOffer = JSON.parse(
      getStorage(`requestOffer-${request.RequestId ?? request.Request?.id}`)
    );
    // answers from draft (localStorage)
    const draftAnswers = requestOffer?.smartFormAnswers;

    // empty answer object
    const emptyAnswer = (id) => ({
      id: uuidv4(),
      questionId: id,
      value: null,
    });

    // adding answers that are missing in the draft due to new questions
    const answersForNewQuestions = (q, a) =>
      q
        ?.filter((item) => !a.map((x) => x?.questionId)?.includes(item.id))
        .map((item) => emptyAnswer(item.id));

    const initialAnswers = draftAnswers
      ? [...draftAnswers, ...answersForNewQuestions(questions, draftAnswers)]
      : questions?.map(
          (item) =>
            request?.Offer?.smartFormAnswers?.find(
              (answer) => answer.questionId === item.id
            ) ?? emptyAnswer(item.id)
        );

    const [answers, setAnswers] = useState(initialAnswers);

    // getting the initial values of the offer fields from the draft
    const getOfferFieldValue = (field) => requestOffer?.[field];

    const updateAfterSave = (id) => {
      setLoading(false);
      setEdit(false);
      removeStorage(`requestOffer-${request.RequestId ?? request.Request?.id}`);
      seller.getRequest(id ?? request.id);
    };

    const handlerOnSubmit = (values) => {
      if (!hasPermission(["READ_SELLER_RFTS"])) return;
      setLoading(true);
      create
        ? seller
            .createOurOffer({...values, smartFormAnswers: answers})
            .then((data) => {
              updateAfterSave(data.ProposalId);
              showSuccess(t("OFFER_CREATED"));
            })
            .catch(() => updateAfterSave())
        : seller
            .updateOurOffer({...values, smartFormAnswers: answers})
            .then(() => showSuccess(t("OFFER_UPDATED")))
            .finally(updateAfterSave);
    };

    const addFileHandler = (e) => {
      setUploadLoading(true);
      const data = new FormData();
      data.append("file", e.file.originFileObj);
      seller.uploadSellerFile(data).finally(() => setUploadLoading(false));
    };

    const removeFileHandler = (id) => {
      ConfirmModal({
        title: t(id ? "WANT_DELETE_FILE" : "WANT_DELETE_ALL_FILES"),
        text: t(id ? "CANT_DELETE_FILE" : "CANT_DELETE_ALL_FILES"),
        type: "warning",
        onOk: () => removeDocument(id),
      });
    };

    const removeDocument = (id) => {
      seller.removeFromSellerFiles(id);
      showSuccess(t(id ? "FILE_DELETED" : "ALL_FILES_DELETED"));
    };

    // update offer files array from draft
    useEffect(() => {
      if (!!requestOffer) {
        const draftOfferFiles = requestOffer.offerFiles;
        seller.updateOfferDocuments();
        draftOfferFiles?.length &&
          draftOfferFiles.forEach((item) => seller.updateOfferDocuments(item));
      }
      return () => {
        seller.updateOfferDocuments();
        initialOfferFiles?.length &&
          initialOfferFiles.forEach((item) => seller.updateOfferDocuments(item));
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // write offer draft in the localStorage
    useEffect(() => {
      const writeDraft = setInterval(() => {
        setStorage(
          `requestOffer-${request.RequestId ?? request.Request?.id}`,
          JSON.stringify({
            ...formRef?.current?.values,
            smartFormAnswers: answers,
            offerFiles,
          })
        );
      }, 500);
      return () => clearInterval(writeDraft);
    }, [answers, offerFiles, request.RequestId, request.Request]);

    return (
      <div className="request-to-us_single current-our-offer_edit  __wrapper">
        <Formik
          initialValues={{
            price: getOfferFieldValue("price") ?? request?.Offer?.price,
            validUntil: getOfferFieldValue("validUntil") ?? request?.Offer?.validUntil,
            message: getOfferFieldValue("message") ?? request?.Offer?.message,
          }}
          validationSchema={schema}
          onSubmit={handlerOnSubmit}
          innerRef={formRef}
        >
          {({values}) => (
            <Form>
              <div className="current-our-offer_edit __actions">
                <LockValue
                  canPermissions={["READ_SELLER_RFTS"]}
                  needLabel
                  needShowModalToSubscribe
                  addClassIcon="mr-3 ml-0"
                >
                  <ButtonTransparent
                    type="submit"
                    name={create ? "SUBMIT_OFFER" : "UPDATE_OFFER"}
                    disabled={
                      uploadLoading ||
                      !(
                        !!values.price &&
                        !!values.validUntil &&
                        (!isRequiredQuestions || requiredFilled)
                      )
                    }
                    loading={loading}
                    nonTransparent
                  />
                </LockValue>

                {!create && (
                  <ButtonTransparent
                    disabled={uploadLoading || loading}
                    onClick={() => {
                      setEdit(false);
                      removeStorage(
                        `requestOffer-${request.RequestId ?? request.Request?.id}`
                      );
                    }}
                    name="CANCEL"
                  />
                )}
                <span className="current-our-offer_edit __actions__status-draft">
                  {t("OUR_OFFER_EDIT_DRAFT_SAVED")}
                </span>
              </div>
              <div className="flex-wrapper mb-8">
                <div className="current-our-offer_edit __field">
                  <label>
                    {t("TOTAL_PRICE")}
                    {request?.Request?.CurrencyCode}
                  </label>
                  <SimpleField
                    name="price"
                    type="number"
                    placeholder={t("WRITE_YOUR_PRICE_HERE")}
                    errorName="name"
                    addClass={!values.price && "bg-yellow"}
                  />
                </div>
                <div className="current-our-offer_edit __date-picker">
                  <label>{t("VALID_UNTIL")}</label>
                  <DatePicker
                    name="validUntil"
                    className={cn("editBlock_date-picker__field", {
                      "bg-yellow": !values.validUntil,
                    })}
                    placeholder={t("SELECT_DATE")}
                  />
                </div>
              </div>
              <div className="current-our-offer_edit __message">
                <label>{t("MESSAGE_TO_BUYER")}</label>
                <SimpleField
                  name="message"
                  placeholder={t("DESCRIBE_YOUR_OFFER_TO_THE_BUYER")}
                  errorName="message"
                  as="textarea"
                />
              </div>
              {!!questions?.length && (
                <SmartFormAnswers
                  isNew={create}
                  isEdit={edit}
                  isSellerSide
                  isRequiredFilled={!!questions?.length && setRequiredFilled}
                  titleHide={!request?.Request?.smartFormQuestions?.length}
                  questions={request?.Request?.smartFormQuestions}
                  answers={answers}
                  onChange={setAnswers}
                />
              )}
              <div className="load-docs_wrapper current-our-offer_edit __documents">
                <div className="load-docs_column-load">
                  <LoadDocsColumn
                    title={"TENDER_DOCUMENT"}
                    docPlaceholder={"PLACEHOLDER_SELLER_DOCUMENT_COLUMN"}
                    titleIcon={<IconDocumentPen />}
                    button={"ADD_DOCUMENT"}
                    editable={create || edit}
                    docs={offerFiles}
                    fullWidth
                    addFile={addFileHandler}
                    removeFile={removeFileHandler}
                    canRemoveAllFiles
                    loadColumnTitle={"TENDER_DOCUMENT"}
                    loading={uploadLoading || loading}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  })
);

export default EditOurOffers;

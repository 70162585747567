import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
//local
import {Modal} from "components/Modal";
import {IconNotificationCircle} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {OUR_FAVOURITES_SUPPLIERS, OUR_BLACKLIST} from "constants/routes.const";
import {color} from "constants/color.consts";

export const EmailExistsCompanyModal = ({onClose, onAdd, data}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal
      onClose={onClose}
      width={550}
      titleIcon={<IconNotificationCircle color={color.black} className="mr-2" />}
      titleText="MODAL_EMAIL_EXISTS_TITLE"
    >
      <div className="modal-email-exists">
        <div className="modal-email-exists__info">
          <div className="modal-email-exists__description">
            <strong>{data.email}</strong> {t("EMAIL_EXISTS_MODAL_DESCRIPTION")}{" "}
            <strong>{data.organization}</strong>
          </div>
          {data.alreadyFavorite && (
            <React.Fragment>
              <div className="modal-email-exists__description mt-4 mb-6">
                {t("EMAIL_EXISTS_MODAL_NOTIFICATION_5")}
              </div>
              <ButtonTransparent
                name="TO_OUR_SUPPLIERS"
                nonTransparent
                addClass="modal-email-exists__favorites-button"
                large
                uppercase
                fullWidth
                bold
                onClick={() => navigate(OUR_FAVOURITES_SUPPLIERS)}
              />
            </React.Fragment>
          )}
          {data.alreadyBlacklist && (
            <React.Fragment>
              <div className="modal-email-exists__description mt-4 mb-6">
                {t("EMAIL_EXISTS_MODAL_NOTIFICATION_6")}
              </div>
              <ButtonTransparent
                name="TO_OUR_BLACKLIST"
                nonTransparent
                addClass="modal-email-exists__favorites-button"
                large
                uppercase
                fullWidth
                bold
                onClick={() => navigate(OUR_BLACKLIST)}
              />
            </React.Fragment>
          )}
          {!data.alreadyFavorite && !data.alreadyBlacklist && (
            <React.Fragment>
              <div className="modal-email-exists__action">
                {t(data.actionText ?? "EMAIL_EXISTS_MODAL_ACTION")}{" "}
                <em>
                  {data.organization}{" "}
                  {t(data.actionText ? "EMAIL_EXISTS_MODAL_ACTION_4" : "DIRECTLY")}
                </em>
              </div>
              {!data?.actionText && (
                <div className="modal-email-exists__notification">
                  {data.email} {t("EMAIL_EXISTS_MODAL_NOTIFICATION")}
                </div>
              )}
              <ButtonTransparent
                name="ADD"
                additionalName={data.actionText ? t("TO_FAVORITES") : data.organization}
                addClass="modal-email-exists__favorites-button mb-4 mt-6"
                nonTransparent
                large
                uppercase
                fullWidth
                bold
                onClick={() => {
                  onAdd();
                  onClose();
                }}
              />
              <ButtonTransparent
                name={"CANCEL"}
                large
                uppercase
                bold
                color="red"
                fullWidth
                onClick={onClose}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </Modal>
  );
};

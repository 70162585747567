import React, {memo} from "react";

export const IconCreditUpdateNoData = memo(({addClass}) => {
  return (
    <svg
      width="194"
      height="10"
      viewBox="0 0 194 10"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14540_216853)">
        <rect opacity="0.1" width="194" height="10" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_14540_216853">
          <path
            d="M0 3C0 1.34315 1.34315 0 3 0H191C192.657 0 194 1.34315 194 3V10H0V3Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

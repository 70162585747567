import React from "react";
import {useTranslation} from "react-i18next";
//local
import MessagePanel from "components/MessagePanel";

const EvaluationCard = ({comments, projects, authUser, onSend, isStaff, onDelete}) => {
  const {t} = useTranslation();

  const allProjectItem = {id: "no-project", name: t("NO_PROJECT"), isArchived: false};

  return (
    <div className="message-card__wrapper">
      <div className="message-card__messages-wrapper">
        <MessagePanel
          mode="create"
          message={{
            User: {
              id: authUser.id,
              firstName: authUser.firstName,
              lastName: authUser.lastName,
            },
          }}
          projects={projects}
          allProjectItem={allProjectItem}
          noSelectProjectValue={t("EVALUATION_COMMENT_PROJECT_PLACEHOLDER")}
          highlightNoSelectProjectValue
          placeholder={t("EVALUATION_COMMENT_PLACEHOLDER")}
          addClass="mb-4"
          noAttachDocument
          sendMessage={(text, _, projectId) =>
            onSend(text, projectId === "no-project" ? null : projectId)
          }
          isMyMessage
        />

        <div className="message-card__messages-list">
          {!!comments?.length &&
            comments.map((comment, index) => (
              <MessagePanel
                key={comment.id}
                message={comment}
                project={comment.Project}
                canDeleteMessage={isStaff || authUser.id === comment.UserId}
                onDelete={() => onDelete(comment.id)}
                addClass={index < comments.length - 1 ? "mb-4" : "mb-0"}
                isMyMessage={authUser.id === comment.UserId}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
export default EvaluationCard;

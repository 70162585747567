import React, {memo} from "react";

export const IconPlusEmpty = memo(({color}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.718 4.23647C14.924 2.44247 12.538 1.45447 10 1.45447C7.462 1.45447 5.077 2.44247 3.282 4.23647C1.487 6.03047 0.5 8.41647 0.5 10.9535C0.5 13.4905 1.488 15.8765 3.282 17.6715C5.076 19.4665 7.462 20.4535 10 20.4535C12.538 20.4535 14.923 19.4655 16.718 17.6715C18.513 15.8775 19.5 13.4915 19.5 10.9535C19.5 8.41547 18.512 6.03047 16.718 4.23647ZM10 19.4545C5.313 19.4545 1.5 15.6415 1.5 10.9545C1.5 6.26747 5.313 2.45447 10 2.45447C14.687 2.45447 18.5 6.26747 18.5 10.9545C18.5 15.6415 14.687 19.4545 10 19.4545Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M16 10.4545H10.5V4.95447C10.5 4.67847 10.276 4.45447 10 4.45447C9.724 4.45447 9.5 4.67847 9.5 4.95447V10.4545H4C3.724 10.4545 3.5 10.6785 3.5 10.9545C3.5 11.2305 3.724 11.4545 4 11.4545H9.5V16.9545C9.5 17.2305 9.724 17.4545 10 17.4545C10.276 17.4545 10.5 17.2305 10.5 16.9545V11.4545H16C16.276 11.4545 16.5 11.2305 16.5 10.9545C16.5 10.6785 16.276 10.4545 16 10.4545Z"
        fill={color || "#1BA2CC"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconTag = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49999 19.085C7.09899 19.085 6.72199 18.929 6.43899 18.647L1.35299 13.561C1.06999 13.278 0.914993 12.902 0.914993 12.5C0.914993 12.098 1.07099 11.722 1.35299 11.439L9.93899 2.85302C10.426 2.36602 11.311 1.99902 12 1.99902H16.5C17.327 1.99902 18 2.67202 18 3.49902V7.99902C18 8.68702 17.633 9.57302 17.147 10.06L8.56099 18.646C8.27799 18.929 7.90199 19.084 7.49999 19.084V19.085ZM12 3.00002C11.585 3.00002 10.94 3.26702 10.647 3.56102L2.06099 12.147C1.96699 12.241 1.91599 12.366 1.91599 12.501C1.91599 12.636 1.96799 12.761 2.06099 12.854L7.14699 17.94C7.24099 18.034 7.36599 18.085 7.50099 18.085C7.63599 18.085 7.76099 18.033 7.85499 17.94L16.441 9.35402C16.734 9.06102 17.002 8.41502 17.002 8.00002V3.50002C17.002 3.22402 16.778 3.00002 16.502 3.00002H12.002H12Z"
        fill={color || "#000"}
      />
      <path
        d="M14.5 7C13.673 7 13 6.327 13 5.5C13 4.673 13.673 4 14.5 4C15.327 4 16 4.673 16 5.5C16 6.327 15.327 7 14.5 7ZM14.5 5C14.224 5 14 5.224 14 5.5C14 5.776 14.224 6 14.5 6C14.776 6 15 5.776 15 5.5C15 5.224 14.776 5 14.5 5Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export const editInitialStateConst = {
  main: false,
  logo: false,
  services: false,
  contacts: false,
  insurance: false,
  members: false,
  unionAgreements: false,
  licenses: false,
  policies: false,
};

export const CollectiveUnionAgreementsOrganizationTypes = [
  {label: "UNION_AGREEMENT_ORGANIZATION", value: "collective"},
  {label: "LOCAL_AGREEMENT_ORGANIZATION", value: "locale"},
];

import React from "react";
import {useTranslation} from "react-i18next";
// local
import {formattedNumber} from "helpers/number.formats";
import {SkeletonRectValue} from "components/SkeletonRectValue";
import {LockValue} from "components/LockValue";

const Turnover = ({value}) => {
  const {t} = useTranslation();

  return (
    <div className="presentation-page__left-side__cards">
      <LockValue
        canPermissions={["READ_OTHER_TURNOVER"]}
        needLabel
        needRightIcon={false}
        noHideOurInfo
        addClassIcon="mb-2"
      >
        <h5>{t("TURNOVER_SEK")}</h5>
      </LockValue>

      <SkeletonRectValue
        quantity={4}
        canPermissions={["READ_OTHER_TURNOVER"]}
        noHideOurInfo
      >
        {value ? <strong>{formattedNumber(value)}</strong> : <strong>-</strong>}
      </SkeletonRectValue>

      <LockValue canPermissions={["READ_OTHER_TURNOVER"]} justHide noHideOurInfo>
        <span>{t("FROM_LAST_YEARLY_REPORT")}</span>
      </LockValue>
    </div>
  );
};

export default Turnover;

import React from "react";
import {useTranslation} from "react-i18next";
//local
import {Modal} from "components/Modal";
import {IconNotificationCircle} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {color} from "constants/color.consts";

export const EmailExistsCompaniesModal = ({onClose, email, isFromCompanyInvite}) => {
  const {t} = useTranslation();

  return (
    <Modal
      onClose={onClose}
      width={550}
      titleIcon={<IconNotificationCircle color={color.black} className="mr-2" />}
      titleText="MODAL_EMAIL_EXISTS_TITLE"
    >
      <div className="modal-email-exists">
        <div className="modal-email-exists__info">
          <div className="modal-email-exists__description">
            <strong>{email}</strong> {t("EMAIL_EXISTS_MODAL_DESCRIPTION_2")}
          </div>
          <div className="modal-email-exists__action df">
            {isFromCompanyInvite
              ? t("EMAIL_EXISTS_MODAL_ACTION_5")
              : t("EMAIL_EXISTS_MODAL_ACTION_2")}
          </div>
          <div className="modal-email-exists__notification mt-4">
            {t("EMAIL_EXISTS_MODAL_NOTIFICATION_2")} {email}{" "}
            {t("EMAIL_EXISTS_MODAL_NOTIFICATION_3")}
          </div>
          {!isFromCompanyInvite && (
            <div className="modal-email-exists__notification mt-4">
              {email} {t("EMAIL_EXISTS_MODAL_NOTIFICATION_4")}
            </div>
          )}
          <ButtonTransparent
            name={"GOT_IT"}
            large
            uppercase
            bold
            fullWidth
            addClass="mt-8"
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};

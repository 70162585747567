import React from "react";
//local
import {Input} from "components/Input";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";
import {formattedNumber} from "helpers/number.formats";
import cn from "classnames";

const ProjectCostRow = ({project, activeTab, onChangeBudget}) => {
  const isEditBudget = hasPermission(["EDIT_PROCUREMENTS_BUDGET_TAB"]);

  return (
    activeTab === 1 && (
      <div className="project-view-cost-row">
        <div className="project-view-procurements-table_item__name-status">
          <div className="project-view-procurements-table_item__name"></div>
          <div className="project-view-procurements-table_item__status"></div>
        </div>
        <div className="project-view-procurements-table_item__pre-cost"></div>
        <div className="project-view-procurements-table_item__cost">
          <LockValue
            canPermissions={["EDIT_PROCUREMENTS_BUDGET_TAB"]}
            needLabel
            needShowModalToSubscribe
          >
            <Input
              flatView
              boldText
              width={isEditBudget ? "112px" : "91px"}
              alignRight
              fontSize={14}
              placeholder="0"
              realNumbers
              digitsDivider
              customHeight={34}
              defaultValue={project.costBudget}
              readOnly={!isEditBudget}
              onChange={(value) => isEditBudget && onChangeBudget(value)}
            />
          </LockValue>
        </div>
        <div className="project-view-procurements-table_item__cost pr-2">
          {formattedNumber(project.sumSpent)}
        </div>
        <div
          className={cn("project-view-procurements-table_item__cost pr-2", {
            __red: +project.sumResult < 0,
          })}
        >
          {formattedNumber(project.sumResult)}
        </div>
      </div>
    )
  );
};

export default ProjectCostRow;

import React, {useEffect, useState, useCallback} from "react";
import {inject, observer} from "mobx-react";
//local
import AgreementCardUE2021 from "../components/OurAgreements/AgreementCardUE2021";
import AgreementCardRamavtal from "../components/OurAgreements/AgreementCardRamavtal";
import {Preloader} from "components/Preloader";
import {ourAgreementsTypes} from "constants/user.consts";

const OurAgreementsSection = inject("store")(
  observer(({store: {auth, organization}}) => {
    const [agreements, setAgreements] = useState(null);
    const [isCreateAgreementLoading, setIsCreateAgreementLoading] = useState(false);
    const [isUpdateAgreementItemLoading, setIsUpdateAgreementItemLoading] =
      useState(false);
    const {organizationDetail, organizationMembersSelectList} = organization;
    const {user, clientRole} = auth;

    const getAgreements = useCallback(
      () =>
        organization
          .getOrganizationAgreements(organizationDetail?.id)
          .then(setAgreements),
      [organization, organizationDetail.id]
    );

    // data for specific agreement
    const agreementUE2021 = agreements?.find(
      (item) => item.type === ourAgreementsTypes.UE2021
    );
    const agreementRamavtal = agreements?.find(
      (item) => item.type === ourAgreementsTypes.RAMAVTAL
    );

    // ****** WORKING WITH AGREEMENT ******
    const handleUpdateArgeement = (values) =>
      organization
        .updateOrganizationAgreement(organizationDetail.id, values)
        .then(getAgreements);

    const handleLoadAgreementFile = (file, type) =>
      organization
        .loadOrganizationAgreementFile(organizationDetail?.id, type, file)
        .then((data) => {
          getAgreements();
          return data;
        });
    const handleRemoveAgreementFile = (type, _, id) =>
      organization
        .deleteOrganizationAgreementFile(organizationDetail?.id, type, id)
        .then(getAgreements);

    // ****** WORKING WITH AGREEMENT ITEM ******
    const handleCreateArgeementItem = (type) => {
      setIsCreateAgreementLoading(true);
      organization
        .createOrganizationAgreementItem(organizationDetail.id, type, {
          name: "",
          AssignedUserId: user.id,
        })
        .then(getAgreements)
        .finally(() => setIsCreateAgreementLoading(false));
    };

    const handleUpdateArgeementItem = (values, type, agreementItemId) => {
      setIsUpdateAgreementItemLoading(true);
      organization
        .updateOrganizationAgreementItem(
          organizationDetail.id,
          type,
          agreementItemId,
          values
        )
        .then(getAgreements)
        .finally(() => setIsUpdateAgreementItemLoading(false));
    };

    const handleDeleteArgeementItem = (type, agreementItemId) =>
      organization
        .deleteOrganizationAgreementItem(organizationDetail.id, type, agreementItemId)
        .then(getAgreements);

    const handleLoadAgreementItemFile = (file, type, agreementItemId) =>
      organization.loadOrganizationAgreementItemFile(
        organizationDetail?.id,
        type,
        agreementItemId,
        file
      );

    const handleRemoveAgreementItemFile = (type, agreementItemId, fileId) =>
      organization
        .deleteOrganizationAgreementItemFile(
          organizationDetail?.id,
          type,
          agreementItemId,
          fileId
        )
        .then(getAgreements);

    useEffect(() => {
      getAgreements();
    }, [getAgreements]);

    return (
      <div className="our-agreements-section">
        {!agreements && <Preloader addClass="preloader250" />}
        {!!agreements?.length && (
          <React.Fragment>
            <AgreementCardUE2021
              agreement={agreementUE2021}
              role={clientRole}
              ourOrganizationName={user.ActiveOrganization?.name}
              organizationName={organizationDetail.name}
              onUpdate={handleUpdateArgeement}
              onLoadFile={handleLoadAgreementFile}
              onRemoveFile={handleRemoveAgreementFile}
            />
            <AgreementCardRamavtal
              agreement={agreementRamavtal}
              role={clientRole}
              userPermissions={user.permissions}
              members={organizationMembersSelectList}
              currentUser={user}
              ourOrganizationName={user.ActiveOrganization?.name}
              organizationName={organizationDetail.name}
              onUpdate={handleUpdateArgeement}
              buttonCreateLoading={isCreateAgreementLoading}
              buttonUpdateItemLoading={isUpdateAgreementItemLoading}
              onCreateAgreementItem={handleCreateArgeementItem}
              onUpdateAgreementItem={handleUpdateArgeementItem}
              onDeleteAgreementItem={handleDeleteArgeementItem}
              onLoadAgreementItemFile={handleLoadAgreementItemFile}
              onRemoveAgreementItemFile={handleRemoveAgreementItemFile}
            />
          </React.Fragment>
        )}
      </div>
    );
  })
);

export default OurAgreementsSection;

export const historyTableStatus = {
  IS_ORDER: "IS_ORDER",
  IS_USER: "IS_USER",
  IS_ORGANIZATION: "IS_ORGANIZATION",
  IS_DATE: "IS_DATE",
};

export const historyOfferAmountTableColumns = [
  {
    name: "HISTORY_TABLE_COLUMN_NUMBER",
    dataKey: "",
    style: {flexGrow: 0},
    view: historyTableStatus.IS_ORDER,
  },
  {
    name: "HISTORY_TABLE_COLUMN_AMOUNT",
    dataKey: "price",
    strikethrough: true,
  },
  {
    name: "HISTORY_TABLE_COLUMN_BY",
    dataKey: "",
    style: {minWidth: "100px", flexGrow: 3},
    view: historyTableStatus.IS_USER,
  },
  {
    name: "HISTORY_TABLE_COLUMN_COMPANY",
    dataKey: "OrganizationMember.Organization.name",
    style: {flexGrow: 2},
    view: historyTableStatus.IS_ORGANIZATION,
    overflow: true,
  },
  {
    name: "HISTORY_TABLE_COLUMN_TIME",
    dataKey: "createdAt",
    view: historyTableStatus.IS_DATE,
  },
];

export const historyBlacklistTableColumns = [
  {
    name: "HISTORY_TABLE_COLUMN_NUMBER",
    dataKey: "",
    view: historyTableStatus.IS_ORDER,
  },
  {
    name: "HISTORY_TABLE_COLUMN_ACTION",
    dataKey: "action",
    strikethrough: true,
    translate: true,
  },
  {
    name: "HISTORY_TABLE_COLUMN_BY",
    dataKey: "",
    style: {minWidth: "100px", flexGrow: 3},
    view: historyTableStatus.IS_USER,
  },
  {
    name: "HISTORY_TABLE_COLUMN_COMMENT",
    dataKey: "comment",
    edit: true,
    style: {flexGrow: 2},
    overflow: true,
  },
  {
    name: "HISTORY_TABLE_COLUMN_TIME",
    dataKey: "createdAt",
    view: historyTableStatus.IS_DATE,
  },
];

export const rfpProposalsListRowHeight = 32;

import React, {memo} from "react";

export const IconExitUp = memo(({color}) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 20.635H4C3.173 20.635 2.5 19.962 2.5 19.135V9.13501C2.5 8.30801 3.173 7.63501 4 7.63501H8C8.276 7.63501 8.5 7.85901 8.5 8.13501C8.5 8.41101 8.276 8.63501 8 8.63501H4C3.724 8.63501 3.5 8.85901 3.5 9.13501V19.135C3.5 19.411 3.724 19.635 4 19.635H16C16.276 19.635 16.5 19.411 16.5 19.135V9.13501C16.5 8.85901 16.276 8.63501 16 8.63501H12C11.724 8.63501 11.5 8.41101 11.5 8.13501C11.5 7.85901 11.724 7.63501 12 7.63501H16C16.827 7.63501 17.5 8.30801 17.5 9.13501V19.135C17.5 19.962 16.827 20.635 16 20.635Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M13.353 4.28102L10.353 1.28102C10.158 1.08602 9.84101 1.08602 9.64601 1.28102L6.64601 4.28102C6.45101 4.47602 6.45101 4.79302 6.64601 4.98802C6.84101 5.18302 7.15801 5.18302 7.35301 4.98802L9.50001 2.84202V14.135C9.50001 14.411 9.72401 14.635 10 14.635C10.276 14.635 10.5 14.411 10.5 14.135V2.84202L12.647 4.98802C12.745 5.08602 12.873 5.13402 13 5.13402C13.127 5.13402 13.256 5.08502 13.353 4.98802C13.548 4.79302 13.548 4.47602 13.353 4.28102Z"
        fill={color || "#1BA2CC"}
      />
    </svg>
  );
});

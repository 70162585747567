import React, {useEffect, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {Routes, Route, Navigate} from "react-router-dom";
//local
import "./App.css";
import {
  MAIN_PAGE,
  ORGANIZATIONS,
  MAIN_SETTINGS,
  INVITE_COMPANIES,
  SAVE_SEARCH,
  ONBOARDING,
  ID_IDENTIFY,
  OUR_FAVOURITES,
  FRAMEWORK_AGREEMENTS,
  JOINING_ORG,
  OUR_BLACKLIST,
  ORGANIZATION_ID,
  REQUEST,
  REQUEST_OFFER,
  NEW_REQUEST,
  CONTRACTS,
  CONTRACT,
  OUR_QUALIFICATIONS,
  REQUESTS_TO_US,
  REQUEST_TO_US,
  OUR_OFFERS,
  CONTRACTS_TO_US,
  CONTRACT_TO_US,
  PUBLIC_RFTS,
  PUBLIC_RFT,
  PROJECTS,
  PROJECT,
  INVITE_ONBOARDING,
  ORGANIZATION_USER_INVITE,
  ORGANIZATION_USER_INVITE_CHOICE,
  // PUBLIC SIDE
  FEEDS,
  FEED,
  RFP_OFFERS,
} from "./constants/routes.const";

import OnboardingPage from "./pages/OnboardingPage/OnboardingPage";
import IdentifyOwner from "./pages/IdentifyOwnerPage/IdentifyOwner";
import {PrivateRoute} from "./router/PrivateRoute";
import {InviteRoute} from "./router/InviteRoute";
import FindSuppliers from "./pages/FindSuppliersPage/FindSuppliers";
import InviteCompanies from "./pages/InviteCompaniesPage/InviteCompanies";
import SaveSearchPage from "./pages/SaveSearchPreferences.page/SaveSearchPage";
import OurSuppliersPage from "./pages/OurSuppliersPage/OurSuppliersPage";
import FrameworkAgreementsPage from "./pages/FrameworkAgreementsPage/FrameworkAgreementsPage";
import OurBlacklistPage from "./pages/OurBlacklist/OurBlacklistPage";
import {JoinOrganization} from "./pages/JoinOrganizationPage/JoinOrganization";
// import OurRequestsPage from "./pages/OurRequests/OurRequestsPage";
import RequestPage from "./pages/OurRequests/components/RequestPage";
import RequestsToUsPage from "./pages/RequestsToUs/RequestsToUsPage";
import RequestToUsPage from "./pages/RequestsToUs/RequestToUsPage";
import OurOffersPage from "./pages/OurOffers/OurOffersPage";
import ContractsPage from "./pages/Contracts/ContractsPage";
import PublicRfpsPage from "./pages/PublicRfps/PublicRfpsPage";
import OurQualificationsPage from "./pages/OurQualifications/OurQualificationsPage";
import OurProjectsPage from "./pages/OurProjects/OurProjectsPage";
import ProjectPage from "./pages/OurProjects/ProjectPage";
import PresentationPage from "./pages/PresentationPage/PresentationPage";
import InviteOnboarding from "./pages/InviteOnboardingPage/InviteOnboarding";
import OrganizationUserInvite from "./pages/OrganizationUserInvite/OrganizationUserInvite";
import InviteChoicePage from "./pages/OrganizationUserInvite/components/InviteChoicePage/InviteChoicePage";
// PUBLIC SIDE
import FeedPage from "./pages/Public/Feed/FeedPage";
import RFPOffersPage from "./pages/Public/RFPOffers/RFPOffersPage";
import MainPage from "pages/MainPage";

import {Preloader} from "components/Preloader";

// eslint-disable-next-line import/no-anonymous-default-export
export const App = inject("store")(
  observer(({store: {auth}}) => {
    const {organizationSubscription, token, user, loading} = auth;

    // determining the user's preferred business role when logging into the application
    const userSubscriptions = organizationSubscription?.SubscribeProducts?.map(
      (item) => item.name
    );
    const isBuyer = useMemo(
      () =>
        userSubscriptions?.includes("SUBSCRIPTION_NAME_04") ||
        userSubscriptions?.includes("SUBSCRIPTION_NAME_05"),
      [userSubscriptions]
    );

    const isNotLoading = useMemo(
      () =>
        !!token
          ? !!user.id
            ? user.ActiveOrganization
              ? !!organizationSubscription
              : true
            : false
          : true,
      [token, organizationSubscription, user]
    );

    useEffect(() => {
      auth.checkToken().catch(() => console.log());
    }, [auth, token]);

    return (
      <div className="app">
        {isNotLoading ? (
          <Routes>
            <Route
              path={MAIN_PAGE}
              element={
                !!token ? (
                  <Navigate to={isBuyer ? PROJECTS : PUBLIC_RFTS} replace />
                ) : (
                  <MainPage />
                )
              }
            />

            <Route path={MAIN_SETTINGS} element={<PrivateRoute path={MAIN_SETTINGS} />} />
            <Route
              path={ONBOARDING}
              element={<PrivateRoute path={ONBOARDING} component={OnboardingPage} />}
            />
            <Route
              path={ID_IDENTIFY}
              element={<PrivateRoute path={ID_IDENTIFY} component={IdentifyOwner} />}
            />
            <Route
              path={SAVE_SEARCH}
              element={<PrivateRoute path={SAVE_SEARCH} component={SaveSearchPage} />}
            />
            <Route
              path={OUR_FAVOURITES}
              element={
                <PrivateRoute path={OUR_FAVOURITES} component={OurSuppliersPage} />
              }
            />
            <Route
              path={FRAMEWORK_AGREEMENTS}
              element={
                <PrivateRoute
                  path={FRAMEWORK_AGREEMENTS}
                  component={FrameworkAgreementsPage}
                />
              }
            />
            <Route
              path={OUR_BLACKLIST}
              element={<PrivateRoute path={OUR_BLACKLIST} component={OurBlacklistPage} />}
            />
            <Route
              path={JOINING_ORG}
              element={<PrivateRoute path={JOINING_ORG} component={JoinOrganization} />}
            />
            <Route
              path={ORGANIZATION_ID}
              element={
                <PrivateRoute exact path={ORGANIZATION_ID} component={PresentationPage} />
              }
            />
            <Route
              path={REQUEST}
              element={<PrivateRoute exact path={REQUEST} component={RequestPage} />}
            />
            <Route
              path={REQUEST_OFFER}
              element={<PrivateRoute exact path={REQUEST} component={RequestPage} />}
            />
            <Route
              path={NEW_REQUEST}
              element={<PrivateRoute exact path={NEW_REQUEST} component={RequestPage} />}
            />
            <Route
              path={PUBLIC_RFTS}
              element={
                <PrivateRoute exact path={PUBLIC_RFTS} component={PublicRfpsPage} />
              }
            />
            <Route
              path={PUBLIC_RFT}
              element={
                <PrivateRoute exact path={PUBLIC_RFT} component={RequestToUsPage} />
              }
            />
            <Route
              path={OUR_QUALIFICATIONS}
              element={
                <PrivateRoute
                  exact
                  path={OUR_QUALIFICATIONS}
                  component={OurQualificationsPage}
                />
              }
            />
            <Route
              path={REQUESTS_TO_US}
              element={
                <PrivateRoute exact path={REQUESTS_TO_US} component={RequestsToUsPage} />
              }
            />
            <Route
              path={REQUEST_TO_US}
              element={
                <PrivateRoute exact path={REQUEST_TO_US} component={RequestToUsPage} />
              }
            />
            <Route
              path={OUR_OFFERS}
              element={<PrivateRoute exact path={OUR_OFFERS} component={OurOffersPage} />}
            />
            <Route
              path={CONTRACTS}
              element={<PrivateRoute exact path={CONTRACTS} component={ContractsPage} />}
            />
            <Route
              path={CONTRACT}
              element={<PrivateRoute exact path={CONTRACT} component={RequestPage} />}
            />
            <Route
              path={CONTRACTS_TO_US}
              element={
                <PrivateRoute exact path={CONTRACTS_TO_US} component={ContractsPage} />
              }
            />
            <Route
              path={CONTRACT_TO_US}
              element={
                <PrivateRoute exact path={CONTRACT_TO_US} component={RequestToUsPage} />
              }
            />
            <Route
              path={PROJECTS}
              element={<PrivateRoute exact path={PROJECTS} component={OurProjectsPage} />}
            />
            <Route
              path={PROJECT}
              element={<PrivateRoute exact path={PROJECT} component={ProjectPage} />}
            />
            <Route
              path={ORGANIZATIONS}
              element={
                <PrivateRoute exact path={ORGANIZATIONS} component={FindSuppliers} />
              }
            />
            <Route
              path={INVITE_COMPANIES}
              element={
                <PrivateRoute exact path={INVITE_COMPANIES} component={InviteCompanies} />
              }
            />
            {/* PUBLIC SIDE */}
            <Route
              path={FEEDS}
              element={<PrivateRoute exact path={FEEDS} component={FeedPage} />}
            />
            <Route
              path={FEED}
              element={<PrivateRoute exact path={FEED} component={FeedPage} />}
            />
            <Route
              path={RFP_OFFERS}
              element={<PrivateRoute exact path={RFP_OFFERS} component={RFPOffersPage} />}
            />

            <Route path={INVITE_ONBOARDING} element={<InviteOnboarding />} />

            <Route
              path={ORGANIZATION_USER_INVITE}
              element={
                <InviteRoute
                  exact
                  path={ORGANIZATION_USER_INVITE}
                  component={OrganizationUserInvite}
                />
              }
            />
            <Route
              path={ORGANIZATION_USER_INVITE_CHOICE}
              element={
                <InviteRoute
                  exact
                  path={ORGANIZATION_USER_INVITE_CHOICE}
                  component={InviteChoicePage}
                />
              }
            />

            <Route path="*" element={<h1 className="error-page_title">404</h1>} />
          </Routes>
        ) : loading ? (
          <Preloader />
        ) : null}
      </div>
    );
  })
);

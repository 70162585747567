import React, {memo} from "react";

export const IconLink = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.682 12.998C9.739 12.998 8.796 12.639 8.078 11.921C7.883 11.726 7.883 11.409 8.078 11.214C8.273 11.019 8.59 11.019 8.785 11.214C9.831 12.26 11.532 12.26 12.578 11.214L16.214 7.578C17.26 6.532 17.26 4.831 16.214 3.785C15.168 2.739 13.467 2.739 12.421 3.785L9.353 6.853C9.158 7.048 8.841 7.048 8.646 6.853C8.451 6.658 8.451 6.341 8.646 6.146L11.714 3.078C13.15 1.642 15.486 1.642 16.921 3.078C18.356 4.514 18.357 6.85 16.921 8.285L13.285 11.921C12.567 12.639 11.624 12.998 10.681 12.998H10.682Z"
        fill={color || "#000"}
      />
      <path
        d="M4.68201 18.998C3.73901 18.998 2.79601 18.639 2.07801 17.921C0.642007 16.485 0.642007 14.149 2.07801 12.714L5.71401 9.078C7.15001 7.642 9.48601 7.642 10.921 9.078C11.116 9.273 11.116 9.59 10.921 9.785C10.726 9.98 10.409 9.98 10.214 9.785C9.16801 8.739 7.46701 8.739 6.42101 9.785L2.78501 13.421C1.73901 14.467 1.73901 16.168 2.78501 17.214C3.83101 18.26 5.53201 18.26 6.57801 17.214L9.64601 14.146C9.84101 13.951 10.158 13.951 10.353 14.146C10.548 14.341 10.548 14.658 10.353 14.853L7.28501 17.921C6.56701 18.639 5.62401 18.998 4.68101 18.998H4.68201Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

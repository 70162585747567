import React from "react";
import {useTranslation} from "react-i18next";
//local
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";

export const SuggestionSendSuppliersModal = ({onClose, onSend}) => {
  const {t} = useTranslation();

  return (
    <Modal
      onClose={onClose}
      width="min"
      boldTitle
      noTitle
      noCenterContent
      titleText="MODAL_SUGGESTION_SEND_SUPPLIERS_TITLE"
    >
      <div className="pa-8">
        <div className="mb-4">{t("MODAL_SUGGESTION_SEND_SUPPLIERS_DESCRIPTION_01")}</div>
        <div className="mb-8">{t("MODAL_SUGGESTION_SEND_SUPPLIERS_DESCRIPTION_02")}</div>
        <div className="df-row-jsb">
          <ButtonTransparent
            name={"MODAL_SUGGESTION_SEND_SUPPLIERS_CANCEL"}
            color="red"
            addClass="mr-4"
            nowrap
            onClick={onClose}
          />
          <ButtonTransparent
            name={"MODAL_SUGGESTION_SEND_SUPPLIERS_OK"}
            nowrap
            nonTransparent
            onClick={onSend}
          />
        </div>
      </div>
    </Modal>
  );
};

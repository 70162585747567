import React, {useEffect, useState} from "react";
import Animate from "rc-animate";
//local
import ButtonDelete from "components/buttons/ButtonDelete";
import CustomRatingStars from "components/CustomRatingStars";
import {showWarning} from "helpers/notifications.helper";
import {ratingTypes} from "constants/ourEvaluationPage.consts";
import {useTranslation} from "react-i18next";

const RatingObserver = ({
  rating,
  isEditable,
  organizationId,
  setOrganizationRating,
  getListAllRatings,
  color,
}) => {
  const [isDeletable, setIsDeletable] = useState(false);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();

  const handleRemoveBtn = () => showWarning("This method does not implemented yes.");

  const handleOnChangeRatingStar = (value) => {
    setLoading(true);
    const data = {
      ...rating,
      score: value,
      RatedOrganizationId: organizationId,
      RatingId: rating.id,
    };
    setOrganizationRating(data).then(() => {
      getListAllRatings().then(() => {
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    rating.type === ratingTypes.custom && setIsDeletable(true);
  }, [rating.type]);

  return (
    <Animate
      transitionName="fade"
      transitionAppear
      className="rating-observer_head__collapse"
    >
      <div className="rating-observer_item__wrapper">
        <div className="rating-observer_item">
          <div className="rating-observer_content">
            <CustomRatingStars
              value={rating?.score}
              isDisabled={loading || !isEditable}
              color={color || "blue"}
              onChange={(value) => handleOnChangeRatingStar(value)}
            />
            <h5 className="rating-observer_title">{t(rating?.name)}</h5>
            <p className="rating-observer_text">{t(rating?.description)}</p>
          </div>
          {isEditable && (
            <div className="rating-observer_actions">
              {isDeletable && <ButtonDelete onClick={handleRemoveBtn} />}
            </div>
          )}
        </div>
      </div>
    </Animate>
  );
};

export default RatingObserver;

import React from "react";
import {useTranslation} from "react-i18next";
import cn from "classnames";
//local

export const CounterStrip = ({counters, addClass}) => {
  const {t} = useTranslation();

  return (
    <div className={cn("counter-strip_wrapper", {[addClass]: !!addClass})}>
      {counters.map((counter) => (
        <div key={counter.description} className="counter-strip__counter_wrapper">
          {counter.icon}
          <span className="counter-strip__counter_value">{counter.value ?? 0}</span>
          <span className="counter-strip__counter_description">
            {t(counter.description)}
          </span>
        </div>
      ))}
    </div>
  );
};

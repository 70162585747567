import React from "react";
import {useTranslation} from "react-i18next";
// local
import {openNewTab} from "helpers/helper";
import {IconDownload} from "components/icons";
import {IconShieldCheck} from "components/icons";
import cn from "classnames";

const OverviewCardView = ({
  columnTitles,
  columnData,
  isSourceShield,
  isTitle,
  crossed,
  wideColumnIndex,
  downloadFiles,
}) => {
  const {t} = useTranslation();

  return (
    <div className={cn("insurance-card", {"title-row": isTitle})}>
      {isTitle &&
        columnTitles.map((item, index) => (
          <p
            key={index}
            className={cn("insurance-card__table-column __title", {
              "__wide-column": wideColumnIndex === index,
            })}
          >
            {t(item)}
          </p>
        ))}
      {!isTitle &&
        !!columnTitles?.length &&
        columnTitles.map((_, index) => (
          <p
            key={index}
            className={cn("insurance-card__table-column __data", {
              "__unnamed-cell": columnData[index] === t("COMPANY"),
              __strikethrough: crossed && index !== 3,
              "__wide-column": wideColumnIndex === index,
            })}
          >
            {columnData[index]}
            {isSourceShield && index === columnTitles.length - 1 && (
              <IconShieldCheck addClass="ml-2" />
            )}
            {index === 2 &&
              !!downloadFiles &&
              downloadFiles.map((file) => (
                <button
                  key={file?.url}
                  className="insurance-card__download"
                  onClick={() => {
                    openNewTab(file?.url);
                  }}
                >
                  <IconDownload />
                  {file.name}
                </button>
              ))}
          </p>
        ))}
    </div>
  );
};

export default OverviewCardView;

import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
//locale
import {Layout} from "components/UI/layout";
import {toRequestToUsId, REQUESTS_TO_US, CONTRACTS_TO_US} from "constants/routes.const";
import {Preloader} from "components/Preloader";
import SingleRequestContent from "./components/SingleRequest/SingleRequestContent";
import SingleRequestHead from "./components/SingleRequest/components/SingleRequestHead/SingleRequestHead";

const RequestsToUsPage = inject("store")(
  observer(({store: {organization: organizationStore, seller}}) => {
    const navigate = useNavigate();

    const {loading, request} = seller;
    const {requestId, contractId, publicRftId} = useParams();

    useEffect(() => {
      organizationStore.getOrganizationMembers();
    }, [organizationStore]);

    useEffect(() => {
      publicRftId
        ? seller.getPublicRequest(publicRftId).then((data) => {
            if (!data.Proposals?.length) return;
            const path = toRequestToUsId(data.Proposals[0].id);
            navigate(path, {state: {contractId: null}});
          })
        : seller
            .getRequest(requestId ?? contractId, request.Request?.id)
            .catch(() => navigate(requestId ? REQUESTS_TO_US : CONTRACTS_TO_US));
      return () => {
        seller.setActiveRequestTab(0);
        seller.updateRequestInfo({});
        seller.clearContract();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seller, requestId, contractId, publicRftId, navigate]);

    return (
      <Layout>
        <div className="requests-to-us">
          {loading && <Preloader addClass="preloader500" />}
          {!loading && (
            <>
              <SingleRequestHead />
              <div className="requests-to-us content-block_wrapper">
                <SingleRequestContent />
              </div>
            </>
          )}
        </div>
      </Layout>
    );
  })
);

export default RequestsToUsPage;

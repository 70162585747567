import React from "react";

export const IconLinkDox = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M17.8746 17.3593C17.9641 17.3114 18.0197 17.2227 18.0197 17.1037C18.0197 16.9318 17.8873 16.8191 17.7056 16.8191H17.4682C17.4168 16.8191 17.383 16.8522 17.383 16.9043V17.658C17.383 17.7094 17.4175 17.7432 17.4696 17.7432C17.5218 17.7432 17.5577 17.7101 17.5577 17.658V17.3995H17.7028L17.8281 17.6868C17.8422 17.7185 17.8732 17.7425 17.9091 17.7425C17.9563 17.7425 17.9986 17.7037 17.9986 17.6544C17.9986 17.6446 17.9958 17.6333 17.9929 17.627L17.8746 17.3593ZM17.6873 17.2473H17.5577V16.9713H17.6873C17.7838 16.9713 17.8422 17.0241 17.8422 17.1079C17.8422 17.1931 17.7838 17.2473 17.6873 17.2473Z"
          fill="white"
        />
        <path d="M20 0.00100708H0V20.001H20V0.00100708Z" fill="#00A9E0" />
        <path
          d="M6.99943 15.3337C6.99943 16.5557 6.09087 17.3706 4.96257 17.3706H3.32294C3.06164 17.3706 2.82147 17.1297 2.82147 16.8691V10.655C2.82147 10.3937 3.06164 10.1535 3.32294 10.1535H4.96257C6.09087 10.1535 6.99943 10.9684 6.99943 12.1904V15.3337ZM5.9972 12.1904C5.9972 11.6157 5.51686 11.1459 4.96327 11.1459H3.82511V16.3782H4.96327C5.51686 16.3782 5.9972 15.8979 5.9972 15.3337V12.1904Z"
          fill="white"
        />
        <path
          d="M12.6106 15.4289C12.6106 16.567 11.7021 17.4235 10.5738 17.4235H10.4695C9.3518 17.4235 8.43268 16.567 8.43268 15.4289V12.0869C8.43268 10.9593 9.3518 10.0923 10.4801 10.0923H10.5738C11.7021 10.0923 12.6106 10.9593 12.6106 12.0869V15.4289ZM11.6077 12.0862C11.6077 11.501 11.1478 11.084 10.5738 11.084H10.4695C9.89482 11.084 9.43561 11.5122 9.43561 12.0862V15.4282C9.43561 16.0128 9.89553 16.441 10.4695 16.441H10.5738C11.1485 16.441 11.6077 16.0128 11.6077 15.4282V12.0862Z"
          fill="white"
        />
        <path
          d="M17.0556 17.4235C16.8887 17.4235 16.7211 17.3608 16.6168 17.1523L15.4364 14.7816L14.2559 17.1523C14.1616 17.3509 13.9841 17.4235 13.8171 17.4235C13.5143 17.4235 13.2945 17.2037 13.2945 16.9431C13.2945 16.8699 13.3157 16.7868 13.3572 16.703L14.8715 13.6533L13.472 10.8227C13.4305 10.7389 13.4093 10.6558 13.4093 10.5825C13.4093 10.3219 13.6284 10.0916 13.9312 10.0916C14.0982 10.0916 14.2757 10.1754 14.37 10.3734L15.4357 12.5144L16.5013 10.3734C16.6056 10.1649 16.7725 10.0916 16.9401 10.0916C17.243 10.0916 17.462 10.3219 17.462 10.5825C17.462 10.6558 17.4409 10.7389 17.3993 10.8227L15.9998 13.6533L17.5141 16.703C17.5557 16.7868 17.5768 16.8699 17.5768 16.9431C17.5782 17.2037 17.3592 17.4235 17.0556 17.4235Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            d="M0 2.00101C0 0.896438 0.895431 0.00100708 2 0.00100708H18C19.1046 0.00100708 20 0.896438 20 2.00101V18.001C20 19.1056 19.1046 20.001 18 20.001H2C0.895431 20.001 0 19.1056 0 18.001V2.00101Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

import React from "react";
import {useTranslation} from "react-i18next";
// local
import {InitialsBadge} from "components/InitialsBadge";
import UploadMultiple from "components/UploadMultiple";
import {getFullName} from "helpers/helper";
import {dateFormat} from "helpers/datetime";
import {color} from "constants/color.consts";

const AgreementCardItemView = ({item, currentUser}) => {
  const {t} = useTranslation();
  const assignedUserFullName = getFullName(
    item.AssignedUser?.firstName,
    item.AssignedUser?.lastName
  );
  const emptyDate = ["----", "--", "--"];
  const dateStart = item.startAt ? dateFormat(item.startAt).split("-") : emptyDate;
  const dateEnd = item.endAt ? dateFormat(item.endAt).split("-") : emptyDate;
  const greyDash = <span style={{color: color.midGray}}> - </span>;

  return (
    <div className="agreement-card-item__view">
      <div className="df-row-center mb-2">
        {!!item.name ? (
          <span>{item.name}</span>
        ) : (
          <span style={{color: color.midGray}}>{t("NO_NAME")}</span>
        )}
        <InitialsBadge
          firstName={assignedUserFullName}
          isCurrentUser={item.AssignedUserId === currentUser.id}
          addClass="mr-2 ml-4"
        />
        <span className="mr-4">{assignedUserFullName}</span>
      </div>
      <div className="df-row-center">
        <span>{`# ${!!item.documentNumber ? item.documentNumber : " -"}`}</span>
        <span className="pl-4">
          {dateStart[0]}
          {greyDash}
          {dateStart[1]}
          {greyDash}
          {dateStart[2]}
        </span>
        <span className="mrl-1">-</span>
        <span>
          {dateEnd[0]}
          {greyDash}
          {dateEnd[1]}
          {greyDash}
          {dateEnd[2]}
        </span>
      </div>
      <UploadMultiple
        fieldName="Files"
        viewMode
        isFilesRowView
        isFileIconDownload
        miniLoadButton
        filesButtonView="delete"
        files={item.Files}
      />
    </div>
  );
};

export default AgreementCardItemView;

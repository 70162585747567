import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
//local
import {CustomTooltip} from "components/CustomTooltip";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {InitialsBadge} from "components/InitialsBadge";
import {CustomBadge} from "components/CustomBadge";
import {RftProposalInterestedStatusModal} from "components/modals/RftProposalInterestedStatusModal";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";
import {
  IconTrumbsUp,
  IconTrumbsDown,
  IconContract,
  IconCheckMark,
  IconSkullEmpty,
  IconUE2021,
  IconRamavtal,
  IconUreadStatus,
  IconHourglass,
  IconBullHorn,
  IconHandOnRight,
  IconTabletPen,
  IconEdit,
} from "components/icons";
import {toOrganizationId, toRequestId} from "constants/routes.const";
import {ourAgreementsTypes} from "constants/user.consts";
import {color} from "constants/color.consts";
import cn from "classnames";

const OK_ICON = 1;
const NO_ICON = 4;

const ProcurementDetails = ({
  recipient,
  rfpId,
  isFavorite,
  isBlacklisted,
  isContract,
  isPublic,
  contractSellerOrganizationId,
  isSignedContract,
  onClickContract,
  onClickProposal,
  onUpdateProposal,
}) => {
  const navigate = useNavigate();
  const [isRftProposalInterestedStatusModal, setRftProposalInterestedStatusModal] =
    useState(false);

  const organizationId = recipient.Organization?.id;
  const readIcon = recipient?.readed ? OK_ICON : NO_ICON;
  const offerIcon = !!recipient?.Offer;
  const isOfferUnreadIcon = !!recipient?.Offer && !recipient.Offer.readed && !isContract;
  const UserAssignee = recipient?.UserAssignee;
  const unreadMessageCount = recipient?.MessagesStat?.unreadMessageCount ?? 0;
  const totalMessageCount = recipient?.MessagesStat?.totalMessageCount ?? 0;

  const ourAgreements = recipient?.Organization?.OurAgreements;
  const isUE2021 = !!ourAgreements?.find(
    (item) => item?.type === ourAgreementsTypes.UE2021
  );
  const isRamavtal = !!ourAgreements?.find(
    (item) => item?.type === ourAgreementsTypes.RAMAVTAL
  );

  const rowColor = isPublic ? color.green : color.blue;
  const statusIcon = {
    [null]: <IconEdit color={color.blue} />,
    [false]: <IconTrumbsDown />,
    [true]: <IconTrumbsUp />,
  };

  const handleMessageBadgeClick = () => {
    if (!rfpId) return;
    navigate(toRequestId(rfpId), {
      state: {messageProposalId: recipient.Organization.id},
    });
  };

  return (
    <div
      className={cn("project-view-procurements-table_item__recipient", {
        "disable-response": !hasPermission(["EDIT_PROCUREMENT_SUPPLIER_RESPONSE"]),
      })}
    >
      <div className="project-view-procurements-table_item__details__title">
        {isPublic ? (
          <IconBullHorn size="16" addClass="mr-2" />
        ) : (
          <IconHandOnRight height={16} width={15} addClass="mr-2" />
        )}
        <span
          onClick={() => navigate(toOrganizationId(recipient?.Organization?.id))}
          className={`${isOfferUnreadIcon ? "__bold " : ""}${
            isPublic ? "green" : "blue"
          }`}
        >
          {recipient?.Organization?.name}
        </span>
        {isFavorite && <IconCheckMark color={rowColor} addClass="ml-2" />}
        {isBlacklisted && (
          <IconSkullEmpty color={rowColor} height={20} width={20} addClass="ml-2" />
        )}
        {isUE2021 && <IconUE2021 color={rowColor} addClass="ml-2" />}
        {isRamavtal && <IconRamavtal color={rowColor} addClass="ml-2" />}
      </div>
      <div className="project-view-procurements-table_item__details__icon">
        <div className={`indicator_sub-status __${readIcon}`} />
      </div>
      <div className="project-view-procurements-table_item__details__icon">
        <LockValue
          canPermissions={["EDIT_PROCUREMENT_SUPPLIER_RESPONSE"]}
          needLabel
          needShowModalToSubscribe
        >
          <ButtonTransparent
            icon={statusIcon[recipient?.interested]}
            noBorder
            showOnHover={recipient?.interested === null}
            tooltip={
              recipient?.interested === null
                ? "RFT_PROPOSAL_INTEREST_STATUS_TOOLTIP"
                : recipient?.interested === false
                ? recipient?.rejectMessage
                : ""
            }
            onClick={() =>
              hasPermission(["EDIT_PROCUREMENT_SUPPLIER_RESPONSE"]) &&
              setRftProposalInterestedStatusModal(true)
            }
          />
        </LockValue>
      </div>
      <div className="project-view-procurements-table_item__details__icon">
        {UserAssignee && (
          <CustomTooltip
            text={`${UserAssignee.firstName} ${UserAssignee.lastName}\n${UserAssignee.mobilePhone}\n${UserAssignee.email}`}
            addClass="organization-card_body__indicator__user-tooltip"
          >
            <span>
              <InitialsBadge
                firstName={UserAssignee.firstName}
                lastName={UserAssignee.lastName}
              />
            </span>
          </CustomTooltip>
        )}
      </div>
      <div className="project-view-procurements-table_item__details__icon">
        {offerIcon && (
          <ButtonTransparent
            icon={<IconTabletPen color={rowColor} />}
            noBorder
            onClick={onClickProposal}
          />
        )}
        {isOfferUnreadIcon && (
          <IconUreadStatus addClass="project-view-procurements-table_item__details__icon__unread-icon" />
        )}
      </div>
      <div className="project-view-procurements-table_item__details__icon">
        {isContract && contractSellerOrganizationId === organizationId && (
          <ButtonTransparent
            icon={
              isSignedContract ? (
                <IconContract color={rowColor} />
              ) : (
                <IconHourglass color={rowColor} />
              )
            }
            noBorder
            onClick={onClickContract}
          />
        )}
      </div>
      <div className="project-view-procurements-table_item__details__messages">
        <CustomBadge
          count={totalMessageCount}
          color="midGray"
          addClass="mr-1"
          onClick={handleMessageBadgeClick}
        />
        <CustomBadge
          count={unreadMessageCount}
          color="red"
          onClick={handleMessageBadgeClick}
        />
      </div>
      {isRftProposalInterestedStatusModal && (
        <RftProposalInterestedStatusModal
          interested={recipient?.interested}
          onClose={() => setRftProposalInterestedStatusModal(false)}
          onButtonClick={(values) => onUpdateProposal(recipient.id, values)}
        />
      )}
    </div>
  );
};

export default ProcurementDetails;

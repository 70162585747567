import React, {useEffect, useMemo, useState} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
//local
import {JOINING_ORG, toOrganizationId} from "constants/routes.const";
import {Header} from "../Header/Header";
import {Footer} from "../Footer";
import {SideBar} from "../SideBar";
import {InfoStrip} from "components/InfoStrip";
import {identifyModalNames} from "constants/modal.conts";
import {showSuccess} from "helpers/notifications.helper";
import {ModalAbout} from "components/modals/ModalAbout";
import {ModalAdvertisementMain} from "components/modals/ModalAdvertisementMain";
import {ModalEmptyInsuranceAttention} from "components/modals/ModalEmptyInsuranceAttention";
import {getStorage, setStorage} from "helpers/storage";
import {hasPermission} from "helpers/helper";
import {oneDay, fourDays} from "constants/user.consts";
import {color} from "constants/color.consts";

export const Layout = inject("store")(
  observer(
    ({
      store: {auth, organization, buyer, seller, clientStore, publicStore},
      children,
    }) => {
      const navigate = useNavigate();
      const {user, token, activeOrganizationId, isStaff} = auth;
      const {isBankId, isOrgSettings, isOnboardingVideo} = organization;
      const {commonSettings} = publicStore;
      const {t} = useTranslation();
      const location = useLocation();
      const lastNoInsuranceModalDate =
        user.ActiveOrganization?.userLastNoInsuranceNotificationDate;
      const [isAdvertisementVideo, setAdvertisementVideo] = useState(false);
      const [isEmptyInsuranceAttentionModal, setEmptyInsuranceAttentionModal] = useState(
        lastNoInsuranceModalDate
          ? Date.now() - Date.parse(lastNoInsuranceModalDate) > fourDays
          : lastNoInsuranceModalDate
      );

      const isConfirmed = useMemo(() => user.isConfirmed, [user]);
      const advertisementMainData = useMemo(
        () => user?.advertisements?.filter((item) => item.place === "main"),
        [user]
      );

      const showOnboardingVideo = useMemo(
        () => isBankId && isOrgSettings && !isOnboardingVideo,
        [isBankId, isOnboardingVideo, isOrgSettings]
      );

      const showAdvertisementMainModal = useMemo(
        () =>
          isBankId &&
          isOrgSettings &&
          isOnboardingVideo &&
          advertisementMainData &&
          !!advertisementMainData?.length &&
          isAdvertisementVideo,
        [
          advertisementMainData,
          isAdvertisementVideo,
          isBankId,
          isOnboardingVideo,
          isOrgSettings,
        ]
      );

      const isJoiningOrgPage = location.pathname.includes(JOINING_ORG);
      const showFooter = !isJoiningOrgPage;
      const showInfoStrip = commonSettings?.infoStrip?.active && !isJoiningOrgPage;
      const showAttentionStrip = token && !isConfirmed && !isJoiningOrgPage;

      const checkAdvertisementMainDate = () => {
        const advertisementMain = getStorage("advertisementMain");
        if (advertisementMain) {
          if (Date.now() - advertisementMain > oneDay) {
            setAdvertisementVideo(true);
            setStorage("advertisementMain", Date.now());
          }
        } else {
          setAdvertisementVideo(true);
          setStorage("advertisementMain", Date.now());
        }
      };

      const sendEmailAndOpenModal = () => {
        auth.sendConfirmationEmail();
        showSuccess(t("SUCCESSFULLY_SENT_CODE"));
        auth.updateAuthModalName(identifyModalNames.identifyEmailAddress);
      };

      const onClickOnboardingVideo = () => {
        organization
          .setOrganizationOnboardingStatus(activeOrganizationId, {
            onboardingVideo: true,
          })
          .then(() => organization.setOnboardingVideoStatus(true))
          .finally(() => showSuccess(t("ONBOARDING_MODAL_ABOUT_THANKS_TEXT")));
      };

      const setLastNoInsuranceNotificationDate = (isNavigate) =>
        auth
          .setLastNoInsuranceNotificationDate({
            lastNoInsuranceNotificationDate: new Date().toISOString(),
          })
          .then(() => {
            auth.setUserLastNoInsuranceNotificationDate(new Date().toISOString());
            isNavigate &&
              navigate(toOrganizationId(activeOrganizationId), {
                state: {editState: {insurance: true}},
              });
          })
          .finally(() => setEmptyInsuranceAttentionModal(false));

      useEffect(() => {}, [organization.blacklistOrganization]);

      useEffect(() => {
        advertisementMainData &&
          advertisementMainData?.length &&
          checkAdvertisementMainDate();
      }, [advertisementMainData]);

      return (
        <div className="main-content_wrapper">
          {showInfoStrip && (
            <InfoStrip color={color.green} text={commonSettings?.infoStrip?.text} />
          )}
          {showAttentionStrip && (
            <InfoStrip
              onClick={sendEmailAndOpenModal}
              color={color.orange}
              text={t("VERIFY_EMAIL_TEXT")}
            />
          )}
          <Header type={isJoiningOrgPage ? "joining-org" : "profile"} />
          <div className="layout-content_wrapper">
            <>
              <SideBar
                auth={auth}
                buyer={buyer}
                seller={seller}
                organization={organization}
                clientStore={clientStore}
                showSidebar={clientStore.showSidebar ? "showSidebar" : "disabled"}
              />
              <span
                className={`abs-bg_sidebar ${
                  clientStore.showSidebar ? "showSidebar" : "disabled"
                }`}
                onClick={() => clientStore.setShowSidebar(false)}
              />
            </>
            <div className="layout-content_inner-wrap">{children}</div>
          </div>

          {showOnboardingVideo && <ModalAbout onClick={onClickOnboardingVideo} />}

          {showAdvertisementMainModal &&
            !hasPermission(["HIDE_MAIN_ADVERTISEMENT"], user.permissions) && (
              <ModalAdvertisementMain
                links={advertisementMainData}
                timeout={1000}
                type={advertisementMainData[0].type}
                onClose={() => setAdvertisementVideo(false)}
              />
            )}

          {isEmptyInsuranceAttentionModal && isStaff && (
            <ModalEmptyInsuranceAttention
              onOk={() => setLastNoInsuranceNotificationDate(true)}
              onClose={() => setLastNoInsuranceNotificationDate()}
            />
          )}
          {showFooter && <Footer />}
        </div>
      );
    }
  )
);

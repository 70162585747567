import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
//local
import {Layout} from "components/UI/layout";
import TableTitleResult from "components/Table/TableTitleResult";
import OurOffersTableHead from "./components/OurOffersTableHead";
import {Preloader} from "components/Preloader";
import EmptyData from "components/EmptyData";
import OurOfferTableItem from "./components/OurOfferTableItem";
import {color} from "constants/color.consts";
import {IconOutbox} from "components/icons";

const OurOffersPage = inject("store")(
  observer(({store: {seller}}) => {
    const {offers, loading} = seller;
    const {t} = useTranslation();

    const dateNow = new Date();

    useEffect(() => {
      seller.getOffers();
    }, [seller]);

    const offerStatus = (isOpen) =>
      offers.length
        ? offers.filter((item) => {
            const parseDate = Date.parse(item.Proposal.Request.closedAt);
            return isOpen ? parseDate > dateNow : parseDate < dateNow;
          })
        : [];

    return (
      <Layout>
        <div className="our-offers">
          <div className="content-block_header">
            <IconOutbox color={color.black} />
            <h3 className="content-block_title __uppercase">{t("OFFERS_WE_MADE")}</h3>
          </div>
          {loading && <Preloader addClass="preloader500" />}
          {!loading && (
            <div className="our-offers content-block_wrapper">
              <TableTitleResult
                addClass={"mt-0 mb-4"}
                title={"REQUESTS_THAT_ARE_STILL_OPEN"}
                totalItems={offerStatus(true).length}
              />
              <OurOffersTableHead />
              {offerStatus(true).length ? (
                offerStatus(true).map((Offer) => (
                  <OurOfferTableItem key={Offer.id} offer={Offer} />
                ))
              ) : (
                <EmptyData addClass={`empty-data pt-4`} />
              )}
              <TableTitleResult
                addClass={"mt-15 mb-4 not-bold"}
                title={"REQUESTS_THAT_ARE_CLOSED"}
                totalItems={offerStatus().length}
              />
              <OurOffersTableHead />
              {offerStatus().length ? (
                offerStatus().map((Offer) => {
                  return <OurOfferTableItem key={Offer.id} offer={Offer} />;
                })
              ) : (
                <EmptyData addClass={`empty-data pt-4`} />
              )}
            </div>
          )}
        </div>
      </Layout>
    );
  })
);

export default OurOffersPage;

// ======================================
// Working with paid places in the app. Data
// ======================================

import {makeAutoObservable} from "mobx";
//local
import axiosInstance from "../axios";

class StripeStore {
  // STATE. Observable and computed part
  productsWithPrices = []; // list of paid products
  currency = ""; // current currency
  isSubscribeInformationExists = false; // checking for the presence of information in the subscription

  // shows whether any product is selected
  get hasAnySelectedProduct() {
    return this.productsWithPrices.some((product) => product.isSelected);
  }

  constructor() {
    makeAutoObservable(this);
  }

  // ACTIONS
  // ****** WORKING WITH SUBSCRIPTIONS ******

  // creating new stripe customer
  createCustomer() {
    return axiosInstance.post(`stripe/create-customer`).then(() => {
      return undefined;
    });
  }

  // creating new subscription
  createSubscription(values) {
    return axiosInstance.post(`stripe/create-subscription`, values).then((response) => {
      if (response.error) {
        throw response;
      }
      return response;
    });
  }

  // setting up a new invoice attempt
  retryInvoice(values) {
    return axiosInstance.post(`stripe/retry-invoice`, values);
  }

  // getting info about specific subscription
  getSubscriptionInformation(values) {
    return axiosInstance
      .post("stripe/retrieve-subscription-information", values)
      .then(() => (this.isSubscribeInformationExists = true));
  }

  // canceling current subscription
  cancelSubscription(values) {
    return axiosInstance.post("stripe/cancel-subscription", values).then(() => {
      localStorage.removeItem("subscriptionId");
      this.isSubscribeInformationExists = false;
    });
  }

  // ****** WORKING WITH PRODUCTS ******

  // getting last selected products
  getLatestProductWithPrice() {
    return axiosInstance
      .get("stripe/latest-products-with-prices")
      .then(({data: {productsWithPrices}}) => {
        this.setProductsWithPrices(productsWithPrices);
        this.setCurrency(productsWithPrices);
        return undefined;
      });
  }

  // setting last selected products with prices
  setProductsWithPrices(value) {
    this.productsWithPrices = value.map((item) => {
      const product = this.productsWithPrices.find((x) => x.id === item.id);
      return {
        ...item,
        isSelected: !!product?.isSelected,
        hasTiers: this.checkHasTiers(item),
        quantity: 1,
      };
    });
  }

  // checking whether the product has tiers
  checkHasTiers(product) {
    if (product?.prices[0] && product?.prices[0].tiers) return true;
    return false;
  }

  // getting total price of latest products
  getTotalPrice() {
    return this.productsWithPrices.reduce((totalPrice, product) => {
      if (product.isSelected) {
        if (product.hasTiers) {
          return totalPrice + this.getProductPrice(product) * product.quantity;
        }
        return totalPrice + this.getProductPrice(product);
      } else {
        return totalPrice;
      }
    }, 0);
  }

  // getting price of specific product
  getProductPrice(product) {
    const price = product?.prices[0];
    if (price) {
      if (price.tiers)
        return (
          price.tiers.find((x) => {
            if (!!x.up_to) {
              return x.up_to >= product.quantity;
            }
            return x.up_to === null;
          }).unit_amount / 100
        );
      return price.unit_amount / 100;
    }
    return 0;
  }

  // getting total price of specific product for specific tier
  getTierTotalPrice(product) {
    return this.getProductPrice(product) * product.quantity;
  }

  // selecting a specific product and recording the selection in the store
  setSelected(product) {
    this.productsWithPrices = this.productsWithPrices.map((item) => {
      if (item.id === product.id) {
        return {...item, isSelected: !product.isSelected};
      }
      return item;
    });
  }

  // increasing quantity of product
  increaseQuantity(product) {
    this.productsWithPrices = this.productsWithPrices.map((item) => {
      if (item.id === product.id) {
        return {...item, quantity: product.quantity + 1};
      }
      return item;
    });
  }

  // decreasing quantity of product
  decreaseQuantity(product) {
    this.productsWithPrices = this.productsWithPrices.map((item) => {
      if (item.id === product.id) {
        return {...item, quantity: product.quantity - 1};
      }
      return item;
    });
  }

  // setting active (current) currency
  setCurrency(productsWithPrices) {
    const prices = productsWithPrices[0].prices;
    if (prices) {
      this.currency = prices[0].currency;
    } else {
      this.currency = "seu";
    }
  }
}

const stripe = new StripeStore();
export default stripe;

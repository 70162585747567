import React from "react";

export const IconLinkOneDrive = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.961792 2.00101C0.961792 0.896438 1.85722 0.00100708 2.96179 0.00100708H18.9618C20.0664 0.00100708 20.9618 0.896438 20.9618 2.00101V18.001C20.9618 19.1056 20.0664 20.001 18.9618 20.001H2.96179C1.85722 20.001 0.961792 19.1056 0.961792 18.001V2.00101Z"
        fill="#EFEFEF"
      />
      <path
        d="M12.6281 11.8193L15.9307 8.61497C15.2447 5.90109 12.5201 4.26526 9.8451 4.96126C8.64048 5.27468 7.59422 6.0323 6.91051 7.08621C6.96164 7.0849 12.6281 11.8193 12.6281 11.8193Z"
        fill="#0364B8"
      />
      <path
        d="M9.06293 7.68945L9.06278 7.69C8.43172 7.29346 7.70393 7.08386 6.96162 7.08489C6.94448 7.08489 6.92762 7.08599 6.9105 7.0862C4.70253 7.11395 2.93477 8.95241 2.9621 11.1925C2.97202 12.0051 3.22227 12.7959 3.68043 13.4625L8.62825 12.8338L12.4216 9.73053L9.06293 7.68945Z"
        fill="#0078D4"
      />
      <path
        d="M15.9307 8.61498C15.8581 8.61006 15.7851 8.60666 15.7113 8.60666C15.2683 8.60622 14.8298 8.69803 14.4231 8.87642L14.423 8.87607L12.4217 9.73055L14.628 12.4956L18.5725 13.4684C19.4237 11.8652 18.8327 9.86543 17.2525 9.00183C16.8441 8.77867 16.3934 8.64678 15.9307 8.615V8.61498H15.9307Z"
        fill="#1490DF"
      />
      <path
        d="M3.68043 13.4625C4.42751 14.5523 5.65326 15.2017 6.96163 15.201H15.7113C16.9065 15.2014 18.0054 14.5359 18.5725 13.4685L12.4216 9.73053L3.68043 13.4625Z"
        fill="#28A8EA"
      />
    </svg>
  );
};

import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import InviteForm from "./InviteForm/InviteForm";
import {Preloader} from "components/Preloader";
import EmptyData from "components/EmptyData";
import {CounterStrip} from "components/CounterStrip";
import {inviteCounters, inviteTableHead} from "constants/invite.consts";
import {InviteSuppliersTableHead} from "./InviteSuppliersTableHead";
import {InviteSuppliersTableRow} from "./InviteSuppliersTableRow";

export const InviteSuppliers = inject("store")(
  observer(({store: {buyer, organization}}) => {
    const {t} = useTranslation();
    const {invites, loading} = organization;

    useEffect(() => {
      organization.getInvites();
    }, [organization]);

    useEffect(() => {
      if (invites.length) {
        inviteCounters[0].value = invites.length;
        inviteCounters[1].value = invites.filter((item) => item.signedAt)?.length ?? 0;
      }
    }, [invites]);

    const suppliers = () =>
      invites.length
        ? invites.map((invite) => ({
            email: invite.email,
            companyName: invite.companyName,
            organizationName: invite.InvitedOrganization?.name,
            organizationLink: invite.InvitedOrganization
              ? `/organizations/${invite.InvitedOrganization?.id}`
              : "",
            sent: invite.createdAt,
            signedup: invite.signedAt,
            requestName: invite.Request?.name,
            requestLink: `/requests/${invite.Request?.id}`,
            message: invite.message ?? "",
          }))
        : [];

    return (
      <div className="invite-suppliers_wrapper">
        <h6 className="custom-title">{t("SEND_INVITE_TO_SUPPLIERS_TITLE")}</h6>
        <InviteForm organizationStore={organization} buyerStore={buyer} />

        <div className="invite-suppliers_table-title">
          <h6 className="custom-title">{t("SENT_INVITES")}</h6>
          <CounterStrip counters={inviteCounters} />
        </div>
        <InviteSuppliersTableHead list={inviteTableHead} />
        {loading && <Preloader addClass="preloader100" />}
        {!loading && suppliers().length ? (
          suppliers().map((supplier) => (
            <InviteSuppliersTableRow
              key={`${supplier.email}${supplier.sent}`}
              data={supplier}
            />
          ))
        ) : (
          <EmptyData />
        )}
      </div>
    );
  })
);

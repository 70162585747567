import React, {useState} from "react";
import {Select as AntdSelect} from "antd";
import {debounce} from "lodash";
//local
import Select from "components/selects/Select";
import {
  IconCommunity,
  IconFirstAddKit,
  IconTabletPen,
  IconFavoriteDone,
  IconSkullEmpty,
  IconSpeedometer,
  IconUE2021,
  IconRamavtal,
  IconDotFilled,
  IconBinoculars,
} from "components/icons/index";
import {CustomTooltip} from "components/CustomTooltip";
import CreditRatingScore from "components/CreditRatingScore";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";
import {color} from "constants/color.consts";
import {ourAgreementsTypes} from "constants/user.consts";
import cn from "classnames";

const {Option} = AntdSelect;
const OK_ICON = 1;
const X_ICON = 2;

// type: "filter" | "organization" | "email"
export const RecipientsEditSelect = ({
  list,
  favorites,
  blacklist,
  placeholder,
  showArrow,
  add,
  search,
  addPermissions = [],
  userPermissions = [],
  addClass,
  prefixIcon,
}) => {
  const [data, setData] = useState(list ?? []);
  const [value] = useState(null);

  const isUE2021 = (item) =>
    !!item?.OurAgreements?.find((item) => item?.type === ourAgreementsTypes.UE2021);
  const isRamavtal = (item) =>
    !!item?.OurAgreements?.find((item) => item?.type === ourAgreementsTypes.RAMAVTAL);
  const isMonitored = (item) => !!item.Company?.OrgMemberMonitor?.length;

  const handleSearch = debounce(async (value) => {
    const name = value?.trim();
    name.length && setData(await search(name));
  }, 400);

  const items = list ?? data;

  const handleChange = (val) =>
    hasPermission(addPermissions, userPermissions) &&
    add(items.find((item) => item.id === val));

  const stopPropagation = (e) => e.code === "Enter" && e.stopPropagation();

  const topRow = (
    <div className="top-row">
      <CustomTooltip text="CREDIT_RATING">
        <div>
          <IconSpeedometer color={color.link} />
        </div>
      </CustomTooltip>
      <CustomTooltip text="HAS_COLL_AGREEMENT">
        <div>
          <IconCommunity color={color.link} />
        </div>
      </CustomTooltip>
      <CustomTooltip text="HAS_INSURANCE">
        <div>
          <IconFirstAddKit color={color.link} />
        </div>
      </CustomTooltip>
      <CustomTooltip text="HAS_FTAX_VAT_IS_REGISTER">
        <div>
          <IconTabletPen color={color.link} />
        </div>
      </CustomTooltip>
    </div>
  );

  const unionAgreementIcon = (organization) =>
    organization.UnionAgreements?.length > 0 ||
    organization?.Company?.CompanyUnionAgreements?.length > 0
      ? OK_ICON
      : X_ICON;
  const insuranceIcon = (organization) =>
    organization.Insurances?.length > 0 ? OK_ICON : X_ICON;
  const taxVatIcon = (company) =>
    company?.swedish_f_tax &&
    company?.swedish_vat_registration &&
    company?.employersRegistration
      ? OK_ICON
      : X_ICON;

  return (
    <Select
      round
      shadow
      smallFont
      disabled={list ? !list?.length : false}
      customTopRow={topRow}
      addDropdownClass={`recipients-tab_custom-select-dropdown ${
        hasPermission(addPermissions, userPermissions) ? "" : "dn"
      }`}
      addClass={`recipients-tab_custom-select ${addClass ? addClass : ""}`}
      showSearch={!!search}
      onInputKeyDown={stopPropagation}
      placeholder={placeholder}
      defaultActiveFirstOption={false}
      showArrow={!!showArrow}
      filterOption={false}
      value={value}
      prefixIcon={prefixIcon}
      onSearch={handleSearch}
      onChange={handleChange}
      optionLabelProp="label"
      notFoundContent="FIRST_SETUP_SELECT_NO_DATA"
    >
      {items.map((item) => (
        <Option
          key={item.id}
          value={item.id}
          label={item.name}
          disabled={!!blacklist?.some((black) => black.id === item.id)}
          className="recipients-tab_custom-select-dropdown_row"
        >
          <span
            className={cn("row__organization", {
              __strikethrough: !!blacklist?.some((black) => black.id === item.id),
            })}
          >
            {item.name}
          </span>
          <span
            className={cn("", {
              __strikethrough: !!blacklist?.some((black) => black.id === item.id),
            })}
          >
            {item.Company?.companyName}
          </span>
          {favorites?.some((favorite) => favorite.id === item.id) &&
            (!!item.SupplierQualifications?.length ? (
              <IconFavoriteDone color={color.green} />
            ) : (
              <CustomTooltip text="SUPPLIER_QUALIFICATION_NOT_PASSED_QUALIFICATION">
                <div style={{height: "20px", display: "inline"}}>
                  <IconFavoriteDone color={color.gold} />
                </div>
              </CustomTooltip>
            ))}
          {blacklist?.some((black) => black.id === item.id) && (
            <IconSkullEmpty height={20} width={20} />
          )}
          {isUE2021(item) && <IconUE2021 />}
          {isRamavtal(item) && <IconRamavtal />}
          {isMonitored(item) && <IconBinoculars />}
          <div className="row__statuses">
            <div className="organization-card_body__indicator __sub-status__rating">
              <LockValue
                canPermissions={["READ_TABLE_CREDIT_RATING"]}
                customLockIcon={<IconDotFilled color={color.purple} />}
              >
                <CreditRatingScore
                  creditRating={item.Company?.creditRating}
                  noCreditRatingSymbol="?"
                  smallSize
                />
              </LockValue>
            </div>
            <div className="organization-card_body__indicator __sub-status">
              <LockValue
                canPermissions={["READ_TABLE_COLLECTIVE_UNION_AGREEMENTS"]}
                customLockIcon={<IconDotFilled color={color.purple} />}
              >
                <span className={`indicator_sub-status __${unionAgreementIcon(item)}`} />
              </LockValue>
            </div>
            <div className="organization-card_body__indicator __sub-status">
              <LockValue
                canPermissions={["READ_TABLE_INSURANCE"]}
                customLockIcon={<IconDotFilled color={color.purple} />}
              >
                <span
                  className={`indicator_sub-status insurance __${insuranceIcon(item)}`}
                />{" "}
              </LockValue>
            </div>
            <div className="organization-card_body__indicator __sub-status">
              <LockValue
                canPermissions={["READ_TABLE_TAX_VAT_EMPLOYERS"]}
                customLockIcon={<IconDotFilled color={color.purple} />}
              >
                <span className={`indicator_sub-status __${taxVatIcon(item.Company)}`} />
              </LockValue>
            </div>
          </div>
        </Option>
      ))}
    </Select>
  );
};

import * as yup from "yup";
import {stringRequired} from "../../../../yup/defaultValudations";

export const requestSchema = yup.object().shape({
  name: stringRequired(),
  closedAt: yup.date().min(new Date()).required(),
});

export const offerSchema = yup.object().shape({
  price: stringRequired(),
  validUntil: yup.string().required("Field is required"),
});

import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
// local
import {Tabs} from "components/Tabs";
import {presentationPageTabs} from "constants/tabs.const";

const RightSide = ({organization, edit, setEdit}) => {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();

  const filteredTabs = () =>
    organization.isActive
      ? presentationPageTabs.filter(
          (item) => !["OUR_EVALUATION", "OUR_AGREEMENTS"].includes(item.title)
        )
      : presentationPageTabs;

  useEffect(() => {
    location?.state?.from === "FrameworkAgreements" &&
      !organization.isActive &&
      setActiveTab(4);
  }, [location, organization]);

  useEffect(() => {
    return () => setActiveTab(0);
  }, []);

  return (
    <div className="presentation-page__right-side">
      <div className="presentation-page__right-side__tabs-wrapper">
        <Tabs
          tabs={filteredTabs()}
          active={activeTab}
          setActive={setActiveTab}
          edit={edit}
          setEdit={setEdit}
          addClass="presentation-page__right-side__tabs"
        />
      </div>
    </div>
  );
};

export default RightSide;

import React from "react";
import {Select} from "antd";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
//local
import {orderingNameValues} from "constants/select.consts";
import {changeSelectValue, changeFilterParams} from "helpers/helper";

const {Option} = Select;

const RequestsTableHead = inject("store")(
  observer(({store: {seller}}) => {
    const {requestFilterParams} = seller;
    const navigate = useNavigate();
    const {t} = useTranslation();

    const onChangeOrder = (value) => {
      const params = changeFilterParams(value);
      updateUrlParams(`?${new URLSearchParams(params)}`);
      seller.setRequestFilterParams(params);
      seller.getRequests();
    };

    const updateUrlParams = (search) => navigate({search});

    const options = (values) =>
      values.map(({value, label}, i) => (
        <Option key={i} value={value}>
          {t(label)}
        </Option>
      ));

    return (
      <div className="requests-to-us_head">
        <div className="requests-to-us_head__project">{t("PROJECT")}</div>
        <div className="requests-to-us_head__name">
          <div className="requests-card_head__name __select">
            <Select
              onChange={onChangeOrder}
              value={changeSelectValue(orderingNameValues, requestFilterParams)}
            >
              {options(orderingNameValues)}
            </Select>
          </div>
        </div>
        <div className="requests-to-us_head__column __buyer">{t("BUYER")}</div>
        <div className="requests-to-us_head__column __date">{t("CLOSES_FOR_OFFERS")}</div>
        <div className="requests-to-us_head__response">{t("OUR_RESPONSE")}</div>
      </div>
    );
  })
);

export default RequestsTableHead;

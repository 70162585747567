import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
// local
import AgreementCardSwitch from "./components/AgreementCardSwitch";
import AgreementCardItemView from "./components/AgreementCardItemView";
import AgreementCardItemEdit from "./components/AgreementCardItemEdit";
import {roles} from "constants/auth.const";
import {hasPermission} from "helpers/helper";
import {ConfirmModal} from "components/ConfirmModal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";

const AgreementCardRamavtal = ({
  agreement,
  role,
  userPermissions,
  members,
  currentUser,
  ourOrganizationName,
  organizationName,
  onUpdate,
  buttonCreateLoading,
  buttonUpdateItemLoading,
  onCreateAgreementItem,
  onUpdateAgreementItem,
  onDeleteAgreementItem,
  onLoadAgreementItemFile,
  onRemoveAgreementItemFile,
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const baseLabel = `${t("WITH")} ${organizationName}`;
  const activeLabel = `${ourOrganizationName} ${t(
    "OUR_AGREEMENTS_SWITCH_LABEL_4"
  )} ${baseLabel}`;
  const inactiveLabel = `${t("OUR_AGREEMENTS_SWITCH_LABEL_1")} ${ourOrganizationName} ${t(
    "OUR_AGREEMENTS_SWITCH_LABEL_4"
  )} ${baseLabel}`;
  const inactiveMemberLabel = `${ourOrganizationName} ${t(
    "OUR_AGREEMENTS_SWITCH_LABEL_3"
  )} ${baseLabel}`;
  const roleMember = role === roles.MEMBER;
  const isHasItems = !!agreement.OrganizationAgreementItems?.length;
  const isShowItems = agreement.checked && isHasItems;

  const handleClickUpdate = (checked) =>
    hasPermission(["READ_ORGANIZATION_RAMAVTAL"], userPermissions)
      ? onUpdate({type: agreement.type, checked}).then(
          () => !agreement.checked && !isHasItems && onCreateAgreementItem(agreement.type)
        )
      : ConfirmModal({
          title: t("WANT_START_RAMAVTAL"),
          text: t("CANT_START_RAMAVTAL"),
          okText: "READ_AND_UPGRADE",
          type: "warning",
          onOk: () => navigate({hash: "#your-subscription"}),
        });

  const handleDeleteAgreementItem = (type, id) =>
    ConfirmModal({
      title: t("WANT_DELETE_AGREEMENT_ITEM"),
      text: t("CANT_DELETE_AGREEMENT_ITEM"),
      type: "warning",
      onOk: () => onDeleteAgreementItem(type, id),
    });

  return (
    <div className="agreement-card">
      <h3 className="agreement-card__title">{agreement.title}</h3>
      <div className="agreement-card__body">
        <div className={`agreement-card__body__row mb-${isShowItems ? 4 : 2}`}>
          <AgreementCardSwitch
            role={role}
            checked={agreement.checked}
            onClick={handleClickUpdate}
            activeLabel={activeLabel}
            inactiveLabel={inactiveLabel}
            inactiveMemberLabel={inactiveMemberLabel}
          />
        </div>
        {isShowItems &&
          agreement.OrganizationAgreementItems.map((item) =>
            roleMember ? (
              <AgreementCardItemView
                key={item.id}
                item={item}
                currentUser={currentUser}
              />
            ) : (
              <AgreementCardItemEdit
                key={item.id}
                item={item}
                members={members}
                currentUser={currentUser}
                onUpdate={onUpdateAgreementItem}
                onDelete={handleDeleteAgreementItem}
                onLoadFile={onLoadAgreementItemFile}
                onRemoveFile={onRemoveAgreementItemFile}
                buttonUpdateItemLoading={buttonUpdateItemLoading}
              />
            )
          )}
        {agreement.checked && !roleMember && (
          <ButtonTransparent
            name="OUR_AGREEMENTS_RAMAVTAL_ADD_BUTTON"
            onClick={() => onCreateAgreementItem(agreement.type)}
            addClass="ml-2"
            loading={buttonCreateLoading}
            small
          />
        )}
      </div>
    </div>
  );
};

export default AgreementCardRamavtal;

import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {v4 as uuidv4} from "uuid";
import ReactDragListView from "react-drag-listview/lib/index.js";
//local
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {InfoTooltip} from "components/InfoTooltip";
import {ConfirmModal} from "components/ConfirmModal";
import QualificationQuestion from "./QualificationQuestion";
import {IconCheckMark, IconPlus} from "components/icons";
import {VideoInstructionLink} from "components/VideoInstructionLink";

export const QualificationQuestionsModal = inject("store")(
  observer(({store: {clientStore}, initialQuestions, isOwner, onClose}) => {
    const {t} = useTranslation();
    const [questions, setQuestions] = useState(initialQuestions);
    const [saveLoading, setSaveLoading] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);

    const handleAddQuestion = () => {
      const newQuestionId = uuidv4();
      setQuestions((state) => [...state, {id: newQuestionId, label: "", files: []}]);
      setTimeout(() => {
        const lastQuestion = document.getElementById(newQuestionId);
        !!lastQuestion && lastQuestion.scrollIntoView({behavior: "smooth"});
      }, 0);
    };

    const handleChangeQuestion = (question) =>
      setQuestions(questions.map((item) => (item.id === question.id ? question : item)));

    const handleDeleteQuestion = (id) =>
      setQuestions(questions.filter((item) => item.id !== id));

    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = [...questions];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        setQuestions(data);
      },
      nodeSelector: ".qualifications-questions-modal__question-item",
      handleSelector: ".drag-button",
    };

    const handleLoadFile = (file, id) => {
      setFileLoading(true);
      clientStore.qualificationQuestionUploadFile(file).then((data) => {
        setFileLoading(false);
        setQuestions(
          questions.map((item) =>
            item.id === id ? {...item, files: [...item.files, data]} : item
          )
        );
      });
    };

    const handleDeleteFile = (fileId, questionId) =>
      setQuestions(
        questions.map((item) =>
          item.id === questionId
            ? {...item, files: item.files.filter((file) => file.id !== fileId)}
            : item
        )
      );

    const handleSaveQuestions = () => {
      if (!isOwner)
        return ConfirmModal({
          title: t("WANT_SAVE_QUALIFICATION_QUESTIONS"),
          text: t("CANT_SAVE_QUALIFICATION_QUESTIONS"),
          type: "warning",
          noCancelButton: true,
          noQuestionSymbol: true,
          onOk: () => {},
        });
      setSaveLoading(true);
      clientStore
        .saveQualificationQuestions({
          suppliersQualificationQuestions: questions.map((question) => ({
            ...question,
            files: question.files.map((file) => file.id),
          })),
        })
        .then(() => onClose({needUpdateQuestions: true}))
        .finally(() => setSaveLoading(false));
    };

    return (
      <Modal
        onClose={onClose}
        width={720}
        noCloseButton
        whiteTitle
        boldTitle
        noCenterContent
        titleIcon={<IconCheckMark addClass="mr-4" />}
        suffixTitleIcon={
          <InfoTooltip
            tooltip="SETTINGS_ORGANIZATION_OUR_SUPPLIERS_MODAL_TITLE_TOOLTIP"
            centerText
            addClass="ml-2"
          />
        }
        titleText="SETTINGS_ORGANIZATION_OUR_SUPPLIERS_MODAL_TITLE"
        addClass="qualifications-questions-modal__wrapper"
      >
        <div className="pa-8">
          {!!questions.length && (
            <div className="qualifications-questions-modal__questions">
              <ReactDragListView {...dragProps}>
                {questions?.map((question) => (
                  <QualificationQuestion
                    key={question.id}
                    question={question}
                    onChange={handleChangeQuestion}
                    onAddFile={handleLoadFile}
                    onDeleteFile={handleDeleteFile}
                    isOwner={isOwner}
                    loading={fileLoading}
                    onDelete={handleDeleteQuestion}
                  />
                ))}
              </ReactDragListView>
            </div>
          )}
          <div className="button-row mt-4">
            {isOwner && (
              <ButtonTransparent
                addClass="pl-1"
                name="SETTINGS_ORGANIZATION_OUR_SUPPLIERS_MODAL_ADD_BUTTON"
                small
                rounded
                icon={<IconPlus />}
                onClick={handleAddQuestion}
              />
            )}
            <div className="infolink">
              <VideoInstructionLink
                label={`VIDEO_LINK_SUPPLIER_QUALIFICATION_LABEL`}
                link={`VIDEO_LINK_SUPPLIER_QUALIFICATION_LINK`}
                addClass="ml-8 mr-4"
              />
            </div>
          </div>
          <div className="df-row-jce-center mt-4">
            <ButtonTransparent
              name="CANCEL"
              medium
              addClass="mr-4"
              color="red"
              onClick={onClose}
            />
            <ButtonTransparent
              name="SAVE_EXIT"
              medium
              nonTransparent
              disabled={!questions.length || !isOwner}
              loading={saveLoading || fileLoading}
              onClick={handleSaveQuestions}
            />
          </div>
        </div>
      </Modal>
    );
  })
);

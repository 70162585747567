import InviteUserInfo from "../components/Steps/InviteUserInfo/InviteUserInfo";
import InviteCode from "../components/Steps/InviteCode";
import InviteOrganizationInfo from "../components/Steps/InviteOrganizationInfo/InviteOrganizationInfo";
import React from "react";

export const steps = [
  {
    content: <InviteUserInfo />,
  },
  {
    content: <InviteCode />,
  },
  {
    content: <InviteOrganizationInfo />,
  },
];

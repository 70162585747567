import React, {memo} from "react";

export const IconEqualizer = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 20C2.724 20 2.5 19.776 2.5 19.5V11.5C2.5 11.224 2.724 11 3 11C3.276 11 3.5 11.224 3.5 11.5V19.5C3.5 19.776 3.276 20 3 20Z"
        fill={color || "#000"}
      />
      <path
        d="M3 6C2.724 6 2.5 5.776 2.5 5.5V0.5C2.5 0.224 2.724 0 3 0C3.276 0 3.5 0.224 3.5 0.5V5.5C3.5 5.776 3.276 6 3 6Z"
        fill={color || "#000"}
      />
      <path
        d="M4 10H2C1.173 10 0.5 9.327 0.5 8.5C0.5 7.673 1.173 7 2 7H4C4.827 7 5.5 7.673 5.5 8.5C5.5 9.327 4.827 10 4 10ZM2 8C1.724 8 1.5 8.224 1.5 8.5C1.5 8.776 1.724 9 2 9H4C4.276 9 4.5 8.776 4.5 8.5C4.5 8.224 4.276 8 4 8H2Z"
        fill={color || "#000"}
      />
      <path
        d="M10 20C9.724 20 9.5 19.776 9.5 19.5V15.5C9.5 15.224 9.724 15 10 15C10.276 15 10.5 15.224 10.5 15.5V19.5C10.5 19.776 10.276 20 10 20Z"
        fill={color || "#000"}
      />
      <path
        d="M10 10C9.724 10 9.5 9.776 9.5 9.5V0.5C9.5 0.224 9.724 0 10 0C10.276 0 10.5 0.224 10.5 0.5V9.5C10.5 9.776 10.276 10 10 10Z"
        fill={color || "#000"}
      />
      <path
        d="M11 14H9C8.173 14 7.5 13.327 7.5 12.5C7.5 11.673 8.173 11 9 11H11C11.827 11 12.5 11.673 12.5 12.5C12.5 13.327 11.827 14 11 14ZM9 12C8.724 12 8.5 12.224 8.5 12.5C8.5 12.776 8.724 13 9 13H11C11.276 13 11.5 12.776 11.5 12.5C11.5 12.224 11.276 12 11 12H9Z"
        fill={color || "#000"}
      />
      <path
        d="M17 20C16.724 20 16.5 19.776 16.5 19.5V9.5C16.5 9.224 16.724 9 17 9C17.276 9 17.5 9.224 17.5 9.5V19.5C17.5 19.776 17.276 20 17 20Z"
        fill={color || "#000"}
      />
      <path
        d="M17 4C16.724 4 16.5 3.776 16.5 3.5V0.5C16.5 0.224 16.724 0 17 0C17.276 0 17.5 0.224 17.5 0.5V3.5C17.5 3.776 17.276 4 17 4Z"
        fill={color || "#000"}
      />
      <path
        d="M18 8H16C15.173 8 14.5 7.327 14.5 6.5C14.5 5.673 15.173 5 16 5H18C18.827 5 19.5 5.673 19.5 6.5C19.5 7.327 18.827 8 18 8ZM16 6C15.724 6 15.5 6.224 15.5 6.5C15.5 6.776 15.724 7 16 7H18C18.276 7 18.5 6.776 18.5 6.5C18.5 6.224 18.276 6 18 6H16Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconEqualizer;

import React from "react";
import EditDatePicker from "components/EditDatePicker";
import ButtonDelete from "components/buttons/ButtonDelete";
import UploadMultiple from "components/UploadMultiple";
import {useTranslation} from "react-i18next";
import EditSelect from "components/selects/EditSelect";
import {certificationsList} from "constants/select.consts";
import {inject, observer} from "mobx-react";

const LicenceForm = inject("store")(
  observer(({store: {organization}, index, remove, licence, setFieldValue}) => {
    const {t} = useTranslation();
    const fileFieldName = `licenses.${index}.Files`;

    return (
      <div className="editBlock_item">
        <div className="editBlock_flex__wrap">
          <EditSelect
            name={`licenses.${index}.name`}
            label={t("NAME")}
            list={certificationsList}
          />
          <EditDatePicker label={t("VALID_UNTIL")} name={`licenses.${index}.validDate`} />
          <UploadMultiple
            uploadRequest={organization.licensesUploadFile}
            fieldName={fileFieldName}
            files={licence.Files}
            setFieldValue={setFieldValue}
          />
          <ButtonDelete addClass="editBlock_btn__clean" onClick={() => remove(index)} />
        </div>
      </div>
    );
  })
);

export default LicenceForm;

import React, {useState, useCallback, useEffect, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
//local
import {Subscription} from "./components/Subscription";
// import {SubscriptionOptions} from "./components/SubscriptionOptions";
import {SubscriptionModalOwner} from "./components/SubscriptionModalOwner";
import {SubscriptionTabs} from "./components/SubscriptionTabs";
// import {ExtraAndTotalPrice} from "./components/ExtraAndTotalPrice";
import {getFullName, createLanguageKeysArray, subscriptionPrice} from "helpers/helper";
import {showSuccess} from "helpers/notifications.helper";
import {
  subscriptionNameKeys,
  subscriptionsData,
  buyerSubscriptions,
  sellerSubscriptions,
  allSubscriptions,
  subscriptionPricePeriods,
} from "constants/user.consts";
import {subscriptionTabs} from "constants/tabs.const";

//TODO remove ExtraAndTotalPrice, SubscriptionOptions  after June !!!

export const Subscriptions = inject("store")(
  observer(({store: {organization, auth}}) => {
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const {subscriptions} = organization;
    const {user} = auth;
    const [isSubscriptionModalOwner, setIsSubscriptionModalOwner] = useState(null);
    const subscriptionTabNumber = useMemo(
      () => ({
        [subscriptionNameKeys.SUBSCRIPTION_NAME_01]: 0,
        [subscriptionNameKeys.SUBSCRIPTION_NAME_02]: 2,
        [subscriptionNameKeys.SUBSCRIPTION_NAME_03]: 2,
        [subscriptionNameKeys.SUBSCRIPTION_NAME_04]: 1,
        [subscriptionNameKeys.SUBSCRIPTION_NAME_05]: 1,
        [subscriptionNameKeys.SUBSCRIPTION_NAME_06]: 0,
        [subscriptionNameKeys.SUBSCRIPTION_NAME_07]: 1,
      }),
      []
    );

    const [activeTab, setActiveTab] = useState(
      !!location.state?.purchasedSubscription
        ? subscriptionTabNumber[location.state.purchasedSubscription]
        : subscriptionTabs[0].id
    );

    const activeSubcriptions = useMemo(
      () => user?.ActiveOrganization?.SubscribeUnit?.SubscribeProducts,
      [user?.ActiveOrganization?.SubscribeUnit]
    );
    const initialClientEmail = user?.ActiveOrganization?.paymentEmail;
    const initialClientReference = user?.ActiveOrganization?.invoiceReference;

    // enriches api data with static data
    const enrichSubscriptionsData = useCallback(
      (subscriptions) =>
        subscriptions.slice().map((item) => {
          const userSubscription = activeSubcriptions?.find(
            (subscription) => subscription.id === item.id
          );
          const pricePeriods = subscriptionPricePeriods
            .map((period) => +item[period.priceKey])
            .filter((period) => !!period && !Number.isNaN(period))
            .sort();

          return {
            ...item,
            ...subscriptionsData[item.name],
            activeStatus: !!userSubscription,
            price: !!userSubscription
              ? subscriptionPrice(
                  userSubscription,
                  userSubscription?.SubscribeUnitProduct?.pricePeriod
                )
              : pricePeriods[0],
            hasPeriods: !userSubscription && pricePeriods.length > 1,
            pricePeriod: userSubscription?.SubscribeUnitProduct?.pricePeriod,
          };
        }),
      [activeSubcriptions]
    );

    const regularSubscriptions = !!subscriptions.length
      ? enrichSubscriptionsData(subscriptions).filter((item) => item.allow)
      : subscriptions;

    const subscriptionsSellerTab = !!subscriptions.length
      ? sellerSubscriptions.map(
          (subscription) =>
            regularSubscriptions.find((item) => item.name === subscription) ??
            enrichSubscriptionsData([{name: subscription}])[0]
        )
      : [];
    const subscriptionsBuyerTab = !!subscriptions.length
      ? buyerSubscriptions.map(
          (subscription) =>
            regularSubscriptions.find((item) => item.name === subscription) ??
            enrichSubscriptionsData([{name: subscription}])[0]
        )
      : [];
    const subscriptionsAllTab = !!subscriptions.length
      ? allSubscriptions.map((subscription) =>
          regularSubscriptions.find((item) => item.name === subscription)
        )
      : [];

    const selectedTabSubscriptions = {
      0: subscriptionsSellerTab,
      1: subscriptionsBuyerTab,
      2: subscriptionsAllTab,
    };

    const onSubmit = (values) =>
      organization
        .setActiveSubscription({
          subscribeProductId: isSubscriptionModalOwner.id,
          ...values,
        })
        .then(() => {
          auth.checkToken(true);
          showSuccess(
            t("SUBSCRIPTION_TAB_SUCCESS_MESSAGE_TEXT"),
            8,
            t("SUBSCRIPTION_TAB_SUCCESS_MESSAGE_TITLE")
          );
        });

    useEffect(() => {
      if (!!location.state?.purchasedSubscription) {
        const targetTab = subscriptionTabNumber[location.state.purchasedSubscription];
        setActiveTab(targetTab);
        activeTab === targetTab &&
          navigate(
            {hash: "#your-subscription"},
            {state: {...location.state, purchasedSubscription: undefined}}
          );
      }
    }, [location.state, subscriptionTabNumber, navigate, activeTab]);

    return (
      <div className="subscriptions__wrapper">
        <div className="header-and-offer">
          <h2 className="subscriptions__title">{t("SUBSCRIPTION_TAB_TITLE_01")}</h2>
          <p>{t("SUBSCRIPTION_TAB_SUBTITLE_01")}</p>

          <SubscriptionTabs
            tabs={subscriptionTabs}
            active={activeTab}
            setActive={setActiveTab}
          />
          {/* Hide offer until next time */}
          {/*
          <div className="offer">
            <div className="blob">{t("SUBSCRIPTION_OFFER_TITLE")}</div>
            <div className="text">
              <div className="main">{t("SUBSCRIPTION_OFFER_MAIN_TEXT")}</div>
              <div className="info">{t("SUBSCRIPTION_OFFER_SUBTEXT")}</div>
            </div>
          </div>
          */}
        </div>

        {!!subscriptions.length && (
          <React.Fragment>
            <div className="subscriptions__items">
              <div
                className={`subscriptions__items__regular_wrapper __${subscriptionTabs[activeTab].subscriptionsCount}`}
              >
                {selectedTabSubscriptions[activeTab].map((subscription, index) => (
                  <Subscription
                    key={subscription.id}
                    subscription={subscription}
                    changeSubscription={(id) =>
                      setIsSubscriptionModalOwner(
                        regularSubscriptions.find((item) => item.id === id)
                      )
                    }
                    subscriptionsCount={subscriptionTabs[activeTab].subscriptionsCount}
                    options={createLanguageKeysArray(
                      subscription.optionsCount,
                      `${subscription.name}_OPTION`
                    )}
                  />
                ))}
              </div>
            </div>
            {/* Hiding this for now. We'll add them later 
            <div className="subscriptions__items">
              <div className="subscriptions__items__regular_wrapper __options">
                {regularSubscriptions.map((subscription, index) => (
                  <SubscriptionOptions
                    key={subscription.id}
                    colorClass={subscription.colorClass}
                    options={createLanguageKeysArray(
                      subscription.subOptionsCount,
                      `SUBSCRIPTION_NAME_0${index + 1}_SUBOPTION`
                    )}
                  />
                ))}
              </div>

              <SubscriptionOptions
                colorClass={kontactUsSubscription.colorClass}
                isContactUs
                options={createLanguageKeysArray(
                  kontactUsSubscription.subOptionsCount,
                  `${kontactUsSubscription.name}_SUBOPTION`
                )}
              />
            </div>
            */}
          </React.Fragment>
        )}

        {/* Hiding this for now. We may want it back after reworking the price model */}
        {/* 
          <ExtraAndTotalPrice activeSubcription={activeSubcription} />
          */}
        {isSubscriptionModalOwner && (
          <SubscriptionModalOwner
            currentSubscription={isSubscriptionModalOwner}
            organization={user?.ActiveOrganization}
            username={getFullName(user.firstName, user.lastName)}
            email={initialClientEmail}
            reference={initialClientReference}
            onClickSubmit={onSubmit}
            onClose={() => setIsSubscriptionModalOwner(null)}
          />
        )}
      </div>
    );
  })
);

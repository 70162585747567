import React, {useState, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import {Tabs} from "components/Tabs";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconPlus, IconCog} from "components/icons";
import {RevisionModal} from "components/modals/RevisionModal";
import {showSuccess} from "helpers/notifications.helper";
import {ConfirmModal} from "components/ConfirmModal";
import cn from "classnames";

const DocumentRevisionTabs = inject("store")(
  observer(
    ({store: {buyer}, isEditing, revisions, revisionId, onChangeTab, addClass}) => {
      const {currentRequest} = buyer;
      const {t} = useTranslation();
      const [loading, setLoading] = useState(false);
      const [currentRevision, setCurrentRevision] = useState(null);

      const hasRevisions = useMemo(() => revisions?.length > 1, [revisions?.length]);
      const revisionTabs = useMemo(
        () =>
          revisions?.map((item, i) => ({
            title: item.name,
            id: item.id,
            hash: "#",
            visible: true,
            disabled: false,
          })),
        [revisions]
      );

      const SettingsButton = ({id}) => (
        <ButtonTransparent
          icon={<IconCog />}
          noBorder
          small
          addClass="fz-14 pl-0 __rotate-on-hover"
          onClick={() => handleOpenRevisionModal(id)}
        />
      );

      const tabContent = useMemo(
        () =>
          revisions?.reduce(
            (content, item) =>
              !item.isOriginal &&
              Object.assign(content, {
                [item.name.replace(" ", "")]: <SettingsButton id={item.id} />,
              }),
            {}
          ),
        [revisions]
      );

      const handleOpenRevisionModal = (id) =>
        buyer.getRevision(currentRequest?.id, id).then(setCurrentRevision);

      const handleSaveRevision = async (values) => {
        setLoading(true);
        if (currentRevision.id) {
          const revisionData = await buyer.updateRevision(
            currentRequest?.id,
            currentRevision.id,
            values
          );
          handleUpdateRequestInfo(revisionData, false);
        } else {
          const revisionData = await buyer.createRevision(currentRequest?.id, values);
          handleUpdateRequestInfo({
            ...revisionData,
            contracts: [],
            documents: [],
            plans: [],
            links: [],
          });
        }
        showSuccess("REVISION_MODAL_SAVED_SUCCESS");
        setLoading(false);

        setCurrentRevision(null);
      };

      const handleUpdateRequestInfo = (revision, isCreate = true) => {
        buyer.updateCurrentRequestInfo({
          documents: isCreate
            ? [...revisions, revision]
            : revisions.map((item) =>
                item.id === revision.id ? {...item, ...revision} : item
              ),
        });
        isCreate && onChangeTab(revision.id);
      };

      const handleDeleteRevision = () =>
        ConfirmModal({
          title: t("REVISION_MODAL_WANT_DELETE"),
          text: t("REVISION_MODAL_CANT_RETURN"),
          type: "warning",
          onOk: onDeleteRevision,
        });

      const onDeleteRevision = () => {
        setLoading(true);
        buyer.deleteRevision(currentRequest?.id, currentRevision.id);
        buyer.updateCurrentRequestInfo({
          documents: revisions.filter((item) => item.id !== currentRevision.id),
        });
        showSuccess("REVISION_MODAL_DELETED_SUCCESS");
        setLoading(false);
        setCurrentRevision(null);
      };

      return (
        <div className={cn("document-revision-tabs__wrapper", {[addClass]: !!addClass})}>
          {hasRevisions && (
            <Tabs
              tabs={revisionTabs}
              active={revisionId}
              rightContent={isEditing ? tabContent : {}}
              setActive={onChangeTab}
              addClass="document-revision-tabs ml-8"
            />
          )}
          {isEditing && (
            <ButtonTransparent
              name="RFT_DOCUMENT_REVISIONS_NEW_BUTTON"
              small
              icon={<IconPlus addClass="mr-1" />}
              loading={loading}
              onClick={() => setCurrentRevision({name: null})}
              addClass="ml-4"
            />
          )}
          {!!currentRevision && (
            <RevisionModal
              revision={currentRevision}
              loading={loading}
              onSave={handleSaveRevision}
              onDelete={handleDeleteRevision}
              onClose={() => setCurrentRevision(null)}
            />
          )}
        </div>
      );
    }
  )
);

export default DocumentRevisionTabs;

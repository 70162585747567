import React, {memo} from "react";

export const IconCross = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "14"}
      height={height || "13"}
      viewBox="0 0 14 13"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.70723 6.49998L13.3532 0.853983C13.5482 0.658983 13.5482 0.341982 13.3532 0.146982C13.1582 -0.0480176 12.8412 -0.0480176 12.6462 0.146982L7.00023 5.79298L1.35423 0.146982C1.15923 -0.0480176 0.842227 -0.0480176 0.647227 0.146982C0.452227 0.341982 0.452227 0.658983 0.647227 0.853983L6.29323 6.49998L0.647227 12.146C0.452227 12.341 0.452227 12.658 0.647227 12.853C0.745227 12.951 0.873226 12.999 1.00123 12.999C1.12923 12.999 1.25723 12.95 1.35523 12.853L7.00123 7.20698L12.6472 12.853C12.7452 12.951 12.8732 12.999 13.0012 12.999C13.1292 12.999 13.2572 12.95 13.3552 12.853C13.5502 12.658 13.5502 12.341 13.3552 12.146L7.70923 6.49998H7.70723Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

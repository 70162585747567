import React from "react";
import {Select} from "antd";
//local
import {Tag} from "components/Tag";
import {InfoTooltip} from "components/InfoTooltip";
import {color} from "constants/color.consts";
import {IconChevronDownMini} from "components/icons";
import cn from "classnames";

const MiniSelect = ({
  list,
  onChange,
  defaultValue,
  isFilledDropdownValues,
  selectedValue,
  label,
  tinyLabel,
  fullWidthDropdownLabel,
  itemColor = color.black,
  informationText,
  uppercaseLabel,
  boldLabel,
  prefixLabel,
  itemAsObject,
  addClass,
}) => {
  const {Option} = Select;

  const SelectLabel = () => (
    <label
      className={cn("mini-select_label", {
        __uppercase: !!uppercaseLabel,
        __bold: !!boldLabel,
        __tiny: !!tinyLabel,
        "ml-2": !prefixLabel,
        "mr-1": prefixLabel,
      })}
    >
      {label}
    </label>
  );

  const options = () =>
    list.map((item, i) => (
      <Option
        key={itemAsObject ? `${item.value}${i}` : `${item}${i}`}
        value={itemAsObject ? item.value : item}
      >
        <Tag
          text={itemAsObject ? item.label : item}
          color={itemColor}
          suffixIcon={
            <IconChevronDownMini
              color={
                selectedValue === item.value || !isFilledDropdownValues
                  ? itemColor
                  : color.white
              }
            />
          }
          noBackground={!isFilledDropdownValues}
          needTranslation
        />
      </Option>
    ));

  return (
    <div className={cn("mini-select_wrapper", {[addClass]: !!addClass})}>
      {(label || label === 0) && prefixLabel && <SelectLabel />}
      <Select
        className="mini-select_select"
        bordered={false}
        onChange={onChange}
        suffixIcon={null}
        defaultValue={defaultValue}
        value={selectedValue}
        popupClassName={cn("mini-select_dropdown", {
          "full-width-labels": !!fullWidthDropdownLabel,
        })}
      >
        {options()}
      </Select>
      {(label || label === 0) && !prefixLabel && <SelectLabel />}
      {informationText && (
        <InfoTooltip
          tooltip={informationText}
          centerText
          addClass={label ? "ml-2" : "ml-1"}
        />
      )}
    </div>
  );
};

export default MiniSelect;

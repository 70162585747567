import React, {useState} from "react";
import {Radio, Space} from "antd";
import {useTranslation} from "react-i18next";
import cn from "classnames";

// direction: "horizontal" | "vertical"

export const RadioGroup = ({
  values,
  direction,
  boldLabels,
  large,
  small,
  resultValue,
  bottomData,
  addClass,
  addClassRadio,
  addClassDataWrapper,
  addClassItemDataWrapper,
  addClassBottomData,
  markUnfilled,
  redDotIfNoValue,
  noLabelTranslation,
  colorizeDizabled,
}) => {
  const {t} = useTranslation();

  const [value, setValue] = useState(
    values?.find((item) => item.checked)?.value ?? values[0]
  );

  const onChange = (e) => {
    setValue(e.target.value);
    resultValue(e.target.value);
  };

  const RadioItem = ({radio}) => (
    <Radio
      key={`${radio.value}${radio.label}`}
      disabled={radio.disabled}
      value={radio.value}
      className={cn("", {
        __bold: boldLabels,
        [radio.className]: !!radio.className,
        [addClassRadio]: !!addClassRadio,
        "__mark-unfilled": !!markUnfilled,
        "__red-dot": !!redDotIfNoValue && !radio.disabled && radio.value === false,
        "__colorize-dizabled": !!colorizeDizabled,
        "__red-dot-disabled":
          !!colorizeDizabled &&
          !!redDotIfNoValue &&
          radio.disabled &&
          radio.value === false,
      })}
    >
      {noLabelTranslation ? radio.label : t(radio.label)}
    </Radio>
  );

  return (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={addClass}
      size={large ? "large" : small ? "small" : "medium"}
    >
      <Space direction={direction}>
        {values.map((radio, index) =>
          bottomData ? (
            <div className={`${addClassDataWrapper} ${addClassItemDataWrapper[index]}`}>
              <RadioItem radio={radio} />
              <div className={addClassBottomData}>{bottomData[index]}</div>
            </div>
          ) : (
            <RadioItem radio={radio} />
          )
        )}
      </Space>
    </Radio.Group>
  );
};

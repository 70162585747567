import React, {useState, useEffect} from "react";
import {useParams, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {Skeleton} from "antd";

//local
import {Layout} from "components/UI/layout";
import LeftSide from "./components/LeftSide/LeftSide";
import RightSide from "./components/RightSide";
import PresentationPageHeader from "./components/PresentationPageHeader/PresentationPageHeader";
import {editInitialStateConst} from "constants/presentationPage.consts";

const PresentationPage = inject("store")(
  observer(({store: {organization, auth}}) => {
    const {organizationId} = useParams();
    const location = useLocation();
    const [edit, setEdit] = useState({
      ...editInitialStateConst,
      ...location.state?.editState,
    });

    useEffect(() => {
      organization
        .getOrganizationDetail(organizationId)
        .then(() => organization.setOrganizationDetailActive(auth.activeOrganizationId))
        .catch((error) => error);
    }, [organizationId, organization, auth.activeOrganizationId]);

    return (
      <Layout>
        {!!Object.entries(organization.organizationDetail).length ? (
          <div className="organization-page content-block_wrapper">
            <PresentationPageHeader edit={edit} setEdit={setEdit} />
            <div className="presentation-page">
              <LeftSide organization={organization.organizationDetail} />
              <RightSide
                organization={organization.organizationDetail}
                edit={edit}
                setEdit={setEdit}
              />
            </div>
          </div>
        ) : (
          <div className="organization-page content-block_wrapper">
            <Skeleton />
          </div>
        )}
      </Layout>
    );
  })
);

export default PresentationPage;

import React from "react";
import {ButtonTransparent} from "./buttons/ButtonTransparent";
import {IconCalendar} from "./icons";
import {saveDocument} from "helpers/helper";

const ButtonCalendarLink = ({summary, description, location, start, end}) => {
  const formatDateTimeForICal = (date) => {
    // Format date and time in YYYYMMDDTHHMMSS format
    // Adjusts to local timezone implicitly
    return [
      date.getFullYear(),
      ("0" + (date.getMonth() + 1)).slice(-2),
      ("0" + date.getDate()).slice(-2),
      "T",
      ("0" + date.getHours()).slice(-2),
      ("0" + date.getMinutes()).slice(-2),
      ("0" + date.getSeconds()).slice(-2),
    ].join("");
  };

  const generateICalData = () =>
    [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "PRODID:-//Your Company//EN",
      "BEGIN:VEVENT",
      `UID:${Date.now()}@yourdomain.com`,
      `DTSTAMP:${formatDateTimeForICal(new Date())}`,
      `DTSTART:${formatDateTimeForICal(start)}`,
      `DTEND:${formatDateTimeForICal(end)}`,
      `SUMMARY:${summary}`,
      `DESCRIPTION:${description}`,
      `LOCATION:${location}`,
      "END:VEVENT",
      "END:VCALENDAR",
    ].join("\r\n");

  const handleDownload = () =>
    saveDocument(
      generateICalData(),
      "text/calendar;charset=utf-8",
      `${summary.replace(/\s+/g, " ")}.ics`
    );

  return (
    <ButtonTransparent
      addClass="calendar-link-button"
      small
      icon={<IconCalendar />}
      name="ADD_TO_CALENDAR_BUTTON_TEXT"
      tooltip="ADD_TO_CALENDAR_BUTTON_TOOLTIP"
      tooltipPlacement="left"
      onClick={handleDownload}
    />
  );
};

export default ButtonCalendarLink;

import React from "react";
import WorldFlag from "react-world-flags";
import {CustomTooltip} from "components/CustomTooltip";
import {InfoTooltip} from "components/InfoTooltip";
import countryLookup from "country-code-lookup";
import EmptyOrNoCompanyCreditData from "components/EmptyOrNoCompanyCreditData";
import {useTranslation} from "react-i18next";
import {IconBeneficialOwner, IconScale} from "components/icons";
import {LockValue} from "components/LockValue";
import {Tag} from "components/Tag";
import {color} from "constants/color.consts";

const intervalKeyMap = {
  INTERVALL1: "REALBENEFICIARY_OWNERSHIP_EXTENT_TEXT_INTERVAL_1",
  INTERVALL2: "REALBENEFICIARY_OWNERSHIP_EXTENT_TEXT_INTERVAL_2",
  INTERVALL3: "REALBENEFICIARY_OWNERSHIP_EXTENT_TEXT_INTERVAL_3",
  INTERVALL4: "REALBENEFICIARY_OWNERSHIP_EXTENT_TEXT_INTERVAL_4",
  INTERVALL5: "REALBENEFICIARY_OWNERSHIP_EXTENT_TEXT_INTERVAL_5",
};
const distrustKeyMap = {
  0: "BENEFICIAL_OWNER_DISTRUST_CODE_0",
  1: "BENEFICIAL_OWNER_DISTRUST_CODE_1",
  2: "BENEFICIAL_OWNER_DISTRUST_CODE_2",
};
const distrustClassMap = {
  0: "good",
  1: "medium",
  2: "bad",
};

const getBeneficialOwnerCountryCode = (citizenship) => citizenship?.toLowerCase();

const getOwnershipExtentText = (code, t) => t(intervalKeyMap[code]);

const getDistrustText = (distrustCode, t) => t(distrustKeyMap[distrustCode]);

// Mapping distrust codes to class names
const getDistrustClassName = (distrustCode) => distrustClassMap[distrustCode] || "";

// Helper function to filter beneficial owners
// We are doing this because we assume that the same person listed twice
// does not add anything relevant.
// We may be wrong in this assumption.
const filterBeneficialOwners = (beneficialOwners) => {
  const ownerMap = new Map();

  beneficialOwners.forEach((owner) => {
    const {pnr, name, control_orgnr, control_org_name} = owner;
    const key = `${pnr}-${name}`;

    if (control_orgnr || control_org_name) ownerMap.set(key, owner);
    else if (!ownerMap.has(key)) ownerMap.set(key, owner);
  });

  return Array.from(ownerMap.values());
};

const obfuscatePnr = (pnr) => {
  if (!pnr || pnr.length < 4) return pnr;
  return `${pnr.slice(0, -4)}-****`;
};

const BeneficialOwner = ({companyData, hasFirstUpdateData}) => {
  const {t} = useTranslation();
  const {
    beneficialOwners = [],
    beneficial_owner_status_code: statusCode,
    beneficial_owner_distrust_code: distrustCode,
    beneficial_owner_regdate: regDate,
  } = companyData?.Company?.realBeneficiary || {};

  const statusTextMap = {
    1: t("BENEFICIAL_OWNER_STATUS_1"),
    2: t("BENEFICIAL_OWNER_STATUS_2"),
    3: t("BENEFICIAL_OWNER_STATUS_3"),
    4: t("BENEFICIAL_OWNER_STATUS_4"),
    5: t("BENEFICIAL_OWNER_STATUS_5"),
  };

  const distrustText = getDistrustText(distrustCode, t);
  const distrustClassName = getDistrustClassName(distrustCode);

  const filteredBeneficialOwners =
    beneficialOwners.length > 1
      ? filterBeneficialOwners(beneficialOwners)
      : beneficialOwners;

  return (
    <LockValue
      canPermissions={["READ_OTHER_ANNUAL_REPORT"]}
      noHideOurInfo
      fullSubcribeButton
      needMiniSibscribeButton
    >
      {companyData?.Company?.updatedCreditSafeCredit != null ? (
        statusCode?.toString() === "1" ? (
          <div className="beneficial-owners df-column mb-8">
            <div className="df-row-center mb-4">
              <IconBeneficialOwner addClass="mr-2" />
              <div className="df-row-center h2">{t("BENEFICIAL_OWNER_HEADING")}</div>
              <InfoTooltip tooltip={t("BENEFICIAL_OWNER_TOOLTIP")} addClass="ml-2" />
              {distrustText && (
                <span className="df-row-center ml-8 mr-4">
                  <div
                    className={`distrust-level ${distrustClassName} df-row-center smaller __italic`}
                  >
                    {distrustText}
                  </div>
                  <InfoTooltip
                    tooltip={t("BENEFICIAL_OWNER_DISTRUST_TEXT_TOOLTIP")}
                    addClass="ml-2"
                  />
                </span>
              )}

              <Tag
                text="BETA"
                needTranslation
                color={color.red}
                uppercase
                small
                addClass="ml-4"
              />
              <InfoTooltip tooltip={t("REAL_BENEFICIARY_BETA_TOOLTIP")} />
            </div>
            <div className="list">
              {filteredBeneficialOwners.map((beneficialOwner, index) => {
                const {
                  pnr,
                  name,
                  citizenship,
                  owner_extent_code,
                  control_text,
                  control_orgnr,
                  control_org_name,
                } = beneficialOwner;

                const countryCode = getBeneficialOwnerCountryCode(citizenship);
                const countryName = countryCode
                  ? countryLookup.byIso(countryCode)?.country
                  : "Unknown";

                return (
                  <div key={index} className="beneficial-owner mb-4">
                    {name && (
                      <div className="person df-row-wrap-center">
                        <div className="df-row-center mr-4 mb-1">
                          {name}
                          {countryCode && (
                            <CustomTooltip text={countryName}>
                              <WorldFlag
                                code={countryCode.toUpperCase()}
                                height="16"
                                className="ml-2 mr-2"
                              />
                            </CustomTooltip>
                          )}
                          <span className="df-row-center smaller">
                            {obfuscatePnr(pnr)}
                          </span>
                        </div>

                        <div className="df-row-center mr-4 mb-1">
                          {control_orgnr && control_org_name && (
                            <span className="df-row-center">
                              <span className="mr-1 smaller">via</span>
                              {control_org_name && (
                                <span className="df-row-center mr-1 smaller bordered">
                                  {control_org_name}
                                </span>
                              )}
                              <span className="smaller df-row-center">
                                {control_orgnr && <> {control_orgnr}</>}
                              </span>
                            </span>
                          )}
                        </div>

                        <div className="df-row-center mb-1">
                          {owner_extent_code && (
                            <span className="df-row-center">
                              <IconScale size="16" addClass="mr-1" />
                              <span className="smaller">
                                {getOwnershipExtentText(owner_extent_code, t)}
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    {control_text && (
                      <div className="control-text smaller __italic mb-1">
                        {control_text}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {regDate && (
              <div className="df-row-center smaller mt-2">
                {t("BENEFICIAL_OWNER_REGDATE")} {regDate}
              </div>
            )}
          </div>
        ) : (
          <div className="smaller df-row-center mt-2 mb-8">
            <h2 className="h2 df-row-center mr-8 mb-0">
              <IconBeneficialOwner addClass="mr-2" />
              {t("BENEFICIAL_OWNER_HEADING")}:
            </h2>
            {t(statusTextMap[statusCode] || "BENEFICIAL_OWNER_INFO_NOT_AVAILABLE")}
          </div>
        )
      ) : (
        <div className="no-group-data df-row-center">
          <h2 className="h2 df-row-center mr-8 mb-0">
            <IconBeneficialOwner addClass="mr-2" />
            {t("BENEFICIAL_OWNER_HEADING")}:
          </h2>
          <EmptyOrNoCompanyCreditData hasFirstUpdateData={hasFirstUpdateData} />
        </div>
      )}
    </LockValue>
  );
};

export default BeneficialOwner;

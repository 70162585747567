import React from "react";
import {useTranslation} from "react-i18next";
import cn from "classnames";

const EmptyCompanyCreditData = ({addClass, text}) => {
  const {t} = useTranslation();

  return (
    <span className={cn("__italic fz-12", {[addClass]: !!addClass})}>
      {text || t("NO_COMPANY_CREDIT_DATA")}
    </span>
  );
};

export default EmptyCompanyCreditData;

import React from "react";
import {useTranslation} from "react-i18next";
import {Upload} from "antd";
//local
import {IconPaperClip} from "components/icons";
import {RadioGroup} from "components/RadioGroup";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {beforeUpload} from "helpers/uploader";
import ButtonDelete from "components/buttons/ButtonDelete";

const QualificationAnswer = ({
  question,
  number,
  answer,
  onChange,
  onAddFile,
  onDeleteFile,
  isAdminOwner,
  buyerSide,
  loading,
}) => {
  const {t} = useTranslation();

  const radioValues = [
    {
      value: true,
      label: "YES",
      checked: answer?.yes === true,
      disabled: loading || buyerSide,
    },
    {
      value: false,
      label: "NO",
      checked: answer?.yes === false,
      disabled: loading || buyerSide,
    },
  ];

  return (
    <div className="qualifications-answers-modal__answer-item">
      <div className="full-width">
        <div className="df-row">
          <span className="pr-4">{`${number + 1}. ${question.label}`}</span>
          {question.files?.map((file) => (
            <a
              href={file?.url || "/"}
              key={file.id}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              {file.name}
            </a>
          ))}
        </div>
        <div className="df">
          <div className="mt-4">
            <div className="__uppercase __bold fz-10 mb-1">
              {t("QUALIFICATION_ANSWERS_MODAL_OUR_RESPONSE")}
            </div>
            <RadioGroup
              values={radioValues}
              direction="horizontal"
              large
              redDotIfNoValue
              colorizeDizabled
              resultValue={(value) =>
                onChange(
                  {
                    questionId: answer?.questionId ?? question.id,
                    yes: value,
                    files: answer?.files ?? [],
                  },
                  !answer?.questionId
                )
              }
            />
          </div>

          <div className="mt-7 ml-2">
            <Upload
              customRequest={() => {}}
              beforeUpload={beforeUpload}
              onChange={(e) => onAddFile(e, question.id, !answer?.questionId)}
              showUploadList={false}
              disabled={loading || !isAdminOwner}
              className="tools-upload"
            >
              {!buyerSide && (
                <ButtonTransparent
                  icon={<IconPaperClip />}
                  noBorder
                  small
                  circular
                  onClick={isAdminOwner ? () => true : undefined}
                  addClass="pa-0 mt-1 mr-1"
                />
              )}
              <div className="df-row-center">
                {!!answer?.files?.length &&
                  answer.files.map((file) => (
                    <div className="df-row-center">
                      <a
                        href={file?.url || "/"}
                        key={file.id}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {file.name}
                      </a>
                      {isAdminOwner && !buyerSide && (
                        <ButtonDelete
                          activeOnHover
                          onClick={(e) => {
                            e.stopPropagation();
                            onDeleteFile(file.id, question.id);
                          }}
                          addClass="ml-1 mr-4"
                        />
                      )}
                    </div>
                  ))}
              </div>
            </Upload>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QualificationAnswer;

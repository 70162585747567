import React, {useState, useMemo, useCallback} from "react";
import {useTranslation} from "react-i18next";
//local
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {IconLifebuoy, IconChevronDown} from "components/icons";
import {ConfirmModal} from "components/ConfirmModal";
import Select from "components/selects/Select";
import {rolesSelect} from "constants/select.consts";
import {InfoTooltip} from "components/InfoTooltip";
import MultipleSelect from "components/selects/MultipleSelect";
import Divider from "components/Divider";
import {availableInArray} from "constants/select.consts";
import {hasUserPermission} from "helpers/helper";
import {Tag} from "components/Tag";
import {roles} from "constants/auth.const";
import {LockValue} from "components/LockValue";
import {subscriptionRoles as constantSubscriptionRoles} from "constants/auth.const";

export const MemberSettingsModal = ({
  title,
  member,
  clientRole,
  isLastMember,
  ownMember,
  availableInList,
  projectsList,
  subscriptions,
  subscriptionRoles,
  onRemove,
  onOk,
  onClose,
}) => {
  const {t} = useTranslation();
  const [memberRole, setMemberRole] = useState(member.role);
  const [memberSubscriptionRole, setMemberSubscriptionRole] = useState(
    member.SubscribeRoleId
  );
  const [availableInMemberList, setAvailableInMemberList] = useState(
    member.availableIn ?? []
  );
  const [projectsMemberList, setProjectsInMemberList] = useState(
    projectsList.filter((project) => member.limitProjectIds?.includes(project.value)) ??
      []
  );
  const [rfpChecked, setRfpChecked] = useState(member.isRfpEmailNotifications);

  const isNotMember = hasUserPermission([roles.ADMIN, roles.OWNER], clientRole);
  const isOwner = hasUserPermission([roles.OWNER], clientRole);
  const isAdmin = hasUserPermission([roles.ADMIN], clientRole);

  const hasSubscriptionRoles = useMemo(
    () => !!subscriptionRoles.length,
    [subscriptionRoles]
  );

  const selectSubscriptionRoles = useMemo(
    () =>
      subscriptionRoles.map((item) => ({
        value: item.name,
        label: t(item.name).toUpperCase(),
      })),
    [subscriptionRoles, t]
  );
  const isSelectSubscriptionRoles = useMemo(
    () => !!selectSubscriptionRoles?.length,
    [selectSubscriptionRoles]
  );

  const subscriptionRolePermissions = useMemo(
    () =>
      subscriptionRoles.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item.SubscribeRolePermissions.map((perm) => perm.PermissionId),
        }),
        {}
      ),
    [subscriptionRoles]
  );

  const subscriptionPermissions = subscriptions.reduce(
    (acc, item) =>
      acc.union(
        new Set(item.SubscribeProductPermissions.map((perm) => perm.PermissionId))
      ),
    new Set()
  );

  const show = useCallback(
    (permission) =>
      hasSubscriptionRoles
        ? subscriptionRolePermissions[memberSubscriptionRole]?.includes(permission)
        : subscriptionPermissions.has(permission),
    [
      memberSubscriptionRole,
      subscriptionRolePermissions,
      subscriptionPermissions,
      hasSubscriptionRoles,
    ]
  );

  const selectAllRegionsElement =
    availableInList.length === availableInArray.length ? "FIRST_SETUP_REGION_00" : null;

  const ModalText = () => (
    <div className="members_choice-recipients-modal__description">
      <p className="mt-4">{t("ORGANIZATION_TAB_MODAL_CONFIRM_DESCRIPTION_01")}</p>
      <p>{t("ORGANIZATION_TAB_MODAL_CONFIRM_DESCRIPTION_02")}</p>
      <p className="mb-0">{t("ORGANIZATION_TAB_MODAL_CONFIRM_DESCRIPTION_03")}</p>
    </div>
  );

  const handleChange = (checked) => {
    if (isLastMember && !checked)
      ConfirmModal({
        title: t("ORGANIZATION_TAB_MODAL_CONFIRM_TITLE"),
        text: <ModalText />,
        type: "warning",
        noCancelButton: true,
        noQuestionSymbol: true,
        onOk: () => {},
        addClass: "members_choice-recipients-modal",
      });
    else setRfpChecked(checked);
  };

  const clickRemove = () =>
    ConfirmModal({
      title: t("WANT_DELETE_USER"),
      text: t("CANT_RESTORE_USER"),
      type: "warning",
      onOk: () => onRemove(member.UserId),
    });

  return (
    <Modal
      onClose={onClose}
      width={600}
      noTitle
      boldTitle
      noCenterContent
      titleText={t(title)}
      addClass="member-settings-modal__wrapper"
    >
      <div className="pa-8">
        <div>
          {isNotMember && (
            <React.Fragment>
              <Select
                title="ORGANIZATION_TAB_MEMBERS_SETTINGS_MODAL_ROLES_LABEL"
                list={
                  isAdmin && memberRole !== roles.OWNER
                    ? rolesSelect.filter((item) => item.value !== roles.OWNER)
                    : rolesSelect
                }
                showArrow
                shadow
                large
                tinyTitle
                boldTitle
                blackTitle
                smallFont
                bold
                suffixIcon={<IconChevronDown />}
                value={memberRole}
                onChange={(role) => {
                  setMemberRole(role);
                  [roles.ADMIN, roles.OWNER].includes(role) &&
                    setMemberSubscriptionRole(constantSubscriptionRoles.BUYER);
                }}
                disabled={isAdmin && memberRole === roles.OWNER}
              />
            </React.Fragment>
          )}
          {!!memberSubscriptionRole && (
            <React.Fragment>
              {isNotMember && <Divider addClass="mt-4 mb-4" />}
              {memberRole !== roles.MEMBER || !isNotMember ? (
                <React.Fragment>
                  <div className="__uppercase __bold fz-10">
                    {t(
                      "ORGANIZATION_TAB_MEMBERS_SETTINGS_MODAL_SUBSCRIPTION_ROLES_LABEL"
                    )}
                  </div>
                  <div className="df-row-center mt-1">
                    <Tag
                      text={memberSubscriptionRole}
                      color="discreteText"
                      needTranslation
                      noBackground
                      uppercase
                      bold
                      small
                    />
                    {isNotMember && (
                      <InfoTooltip
                        tooltip="ORGANIZATION_TAB_MEMBERS_SETTINGS_MODAL_SUBSCRIPTION_ROLES_TOOLTIP"
                        centerText
                      />
                    )}
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {isSelectSubscriptionRoles && (
                    <Select
                      title="ORGANIZATION_TAB_MEMBERS_SETTINGS_MODAL_SUBSCRIPTION_ROLES_LABEL"
                      list={selectSubscriptionRoles}
                      showArrow
                      shadow
                      large
                      tinyTitle
                      boldTitle
                      blackTitle
                      smallFont
                      bold
                      addDropdownClass="__uppercase"
                      addSelectClass="__uppercase"
                      suffixIcon={<IconChevronDown />}
                      value={memberSubscriptionRole}
                      onChange={setMemberSubscriptionRole}
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          <LockValue
            canPermissions={[""]}
            additionalHideCondition={!show("SHOW_SETTINGS_USER_SETTINGS_MODAL_REGIONS")}
            additionalShowCondition={show("SHOW_SETTINGS_USER_SETTINGS_MODAL_REGIONS")}
            justHide
          >
            <Divider addClass="mt-4 mb-4" />
            <MultipleSelect
              value={availableInMemberList}
              list={availableInList.map((item) => ({label: item, value: item}))}
              customSelectAllElement={selectAllRegionsElement}
              small
              tinyTitle
              blackTitle
              color="green"
              onChange={setAvailableInMemberList}
              suffixTitleContent={
                <InfoTooltip
                  tooltip="ORGANIZATION_TAB_MEMBERS_SETTINGS_MODAL_REGIONS_TOOLTIP"
                  centerText
                  addClass="ml-1 mr-1"
                  addWrapperClass="account_member__item-head__regions-tooltip"
                />
              }
              title="ORGANIZATION_TAB_MEMBERS_HEAD_REGIONS"
            />
          </LockValue>

          {!ownMember && (
            <LockValue
              canPermissions={[""]}
              additionalHideCondition={
                !show("SHOW_SETTINGS_USER_SETTINGS_MODAL_PROJECT_LIMITATIONS")
              }
              additionalShowCondition={show(
                "SHOW_SETTINGS_USER_SETTINGS_MODAL_PROJECT_LIMITATIONS"
              )}
              justHide
            >
              <Divider addClass="mt-4 mb-4" />
              <MultipleSelect
                value={projectsMemberList}
                list={projectsList}
                small
                realLabels
                tinyTitle
                blackTitle
                color="purple"
                onChange={setProjectsInMemberList}
                suffixTitleContent={
                  <InfoTooltip
                    tooltip="ORGANIZATION_TAB_MEMBERS_SETTINGS_MODAL_PROJECTS_TOOLTIP"
                    centerText
                    addClass="ml-1 mr-1"
                    addWrapperClass="account_member__item-head__regions-tooltip"
                  />
                }
                title="ORGANIZATION_TAB_MEMBERS_HEAD_PROJECTS"
              />
            </LockValue>
          )}

          <LockValue
            canPermissions={[""]}
            additionalHideCondition={
              !show("SHOW_SETTINGS_USER_SETTINGS_MODAL_RECIPIENT_SWITCH")
            }
            additionalShowCondition={show(
              "SHOW_SETTINGS_USER_SETTINGS_MODAL_RECIPIENT_SWITCH"
            )}
            justHide
          >
            <Divider addClass="mt-4 mb-4" />
            <CustomRadioSwitch
              checked={rfpChecked}
              prefixLabelContent={<IconLifebuoy addClass="ml-2" />}
              label={t("ORGANIZATION_TAB_MEMBERS_SETTINGS_MODAL_SWITCH")}
              informationText="ORGANIZATION_TAB_MEMBERS_SETTINGS_MODAL_FALLBACK_RECEIVER_TOOLTIP"
              small
              mediumText
              isActive={handleChange}
            />
          </LockValue>

          {isOwner && <Divider addClass="mt-4 mb-4" />}
          {isOwner && (
            <ButtonTransparent
              name="ORGANIZATION_TAB_MEMBERS_SETTINGS_MODAL_DELETE"
              addClass="mt-4"
              color="red"
              small
              onClick={() => clickRemove(member.UserId)}
            />
          )}
        </div>
        <div className="df-row-jce-center mt-8">
          <ButtonTransparent
            name="CANCEL"
            addClass="mr-4"
            color="red"
            onClick={onClose}
          />
          <ButtonTransparent
            name="SAVE"
            onClick={() => {
              onOk(member.UserId, {
                role: memberRole,
                SubscribeRoleId:
                  memberRole === roles.MEMBER
                    ? memberSubscriptionRole
                    : constantSubscriptionRoles.BUYER,
                availableIn: availableInMemberList,
                limitProjectIds: projectsMemberList.map((item) => item.value),
                isRfpEmailNotifications: rfpChecked,
              });
              onClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

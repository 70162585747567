import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
// local
import {OrganizationCard} from "components/organizationCard/OrganizationCard";
import {Preloader} from "components/Preloader";
import OrganizationCardHead from "components/organizationCard/OrganizationCardHead";
import SubscriptionUpgrageLocalModal from "components/modals/SubscriptionUpgrageLocalModal";
import {hasPermission} from "helpers/helper";
import EmptyData from "components/EmptyData";
import {PaginationType} from "components/Pagination";
import HandyPointerBlob from "components/HandyPointerBlob";

const FavoritesSection = inject("store")(
  observer(({store: {auth, organization}, onChangeSort}) => {
    const {
      filterParams,
      suppliers: {totalItems, currentPage, searchPreferences},
      loading,
    } = organization;
    const navigate = useNavigate();

    const {
      user: {permissions},
    } = auth;

    const [isHistory, setShowHistory] = useState(false);

    const SubscriptionUpgrageLocalModalPermissions = [
      "READ_TABLE_CREDIT_RATING",
      "READ_TABLE_TURNOVER",
      "READ_TABLE_COLLECTIVE_UNION_AGREEMENTS",
    ];

    const updateUrlParams = (search) => navigate({search});

    const handlePageChange = (pageNumber) => {
      // Separate conversion for correct search string generation
      const urlParams = Object.entries(filterParams).reduce(
        (obj, item) => ({
          ...obj,
          [`${item[0]}${Array.isArray(item[1]) ? "[]" : ""}`]: item[1],
        }),
        {}
      );
      organization.setFilterParams({...filterParams, page: pageNumber});
      updateUrlParams(`?${new URLSearchParams({...urlParams, page: pageNumber})}`);
      organization.searchSuppliers();
    };

    return (
      <div className="our-suppliers__organizations-table">
        <HandyPointerBlob
          showPointer
          text="NEW_FEATURE_SEARCHFILTERS_TEXT"
          forcePosition="relative"
          forceAlign="right"
          topPos={-9}
          rightPos={32}
        />
        <OrganizationCardHead
          onChangeSort={onChangeSort}
          isHistory={isHistory}
          onChangeShowHistory={() => setShowHistory(!isHistory)}
          showTotalItems
          filledFilters={Object.keys(filterParams).length > 1}
        />
        {loading && <Preloader addClass="preloader100" />}
        {!loading && (
          <React.Fragment>
            {!!searchPreferences?.length ? (
              searchPreferences.map((s) => {
                if (s.Organization) {
                  return (
                    <OrganizationCard
                      key={s.Organization.id}
                      organization={s.Organization}
                      isHistory={isHistory}
                      place="favorites"
                    />
                  );
                }
                return null;
              })
            ) : (
              <EmptyData />
            )}
          </React.Fragment>
        )}

        {totalItems > 20 && (
          <PaginationType
            totalItems={totalItems}
            currentPage={currentPage}
            countPerPage={20}
            onPageChange={handlePageChange}
            addClass="mt-8"
          />
        )}

        {!hasPermission(SubscriptionUpgrageLocalModalPermissions, permissions) &&
          !loading &&
          !!searchPreferences?.length && (
            <SubscriptionUpgrageLocalModal
              canPermissions={SubscriptionUpgrageLocalModalPermissions}
            />
          )}
      </div>
    );
  })
);

export default FavoritesSection;

import React from "react";
import {IconHandOnDown} from "components/icons";
import {IconHandOnUp} from "components/icons";
import {useTranslation} from "react-i18next";

const HandyPointerBlob = ({
  showNewsBadge, // Show or hide red "news" badge
  showPointer, // Show or hide pointy hand icon
  pointerAfterText, // Show the pointer after the text instead of (default) before
  noAnimation, // Include to stop wiggly animation
  pointyAnimation, //Include to change to up/down movement
  pointerDirection, // point up or down? Default is down
  text, // Translation key for text
  forceAlign, //Force flex alignment right or left. Default is center.
  forcePosition, // "absolute" or "relative" to force positioning
  topPos, // Pixel value for top positioning
  rightPos, // Pixel value for right positioning
  bottomPos, // Pixel value for bottom positioning
  leftPos, // Pixel value for left positioning
  fontSize, // allow override of font size
}) => {
  const {t} = useTranslation();

  const styleValues = {
    "--top": topPos ? `${topPos}px` : undefined,
    "--right": rightPos ? `${rightPos}px` : undefined,
    "--bottom": bottomPos ? `${bottomPos}px` : undefined,
    "--left": leftPos ? `${leftPos}px` : undefined,
    fontSize: fontSize || "initial",
  };

  const forceAlignClass =
    forceAlign === "right" ? "align-right" : forceAlign === "left" ? "align-left" : "";
  const animationClass = pointyAnimation
    ? "pointy-animation"
    : noAnimation
    ? "no-animation"
    : "wiggly-animation";
  const iconHandClass = `${animationClass}${pointerAfterText ? " show-last" : ""}`;

  return (
    <div
      className={`handy-pointer-blob ${forcePosition || ""} ${forceAlignClass || ""}`}
      style={styleValues}
    >
      {showNewsBadge && <div className="newbadge">{t("NEW_FEATURE_NEWS")}</div>}
      {showPointer &&
        (pointerDirection === "up" ? (
          <IconHandOnUp addClass={iconHandClass} />
        ) : (
          <IconHandOnDown addClass={iconHandClass} />
        ))}

      <div className="scribble">{t(text)}</div>
    </div>
  );
};

export default HandyPointerBlob;

import React, {memo} from "react";

export const IconSheet = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.854 4.646L13.354 0.146C13.26 0.052 13.133 0 13 0H3.5C2.673 0 2 0.673 2 1.5V18.5C2 19.327 2.673 20 3.5 20H16.5C17.327 20 18 19.327 18 18.5V5C18 4.867 17.947 4.74 17.854 4.646ZM16.793 5H13.5C13.224 5 13 4.776 13 4.5V1.207L16.793 5ZM16.5 19H3.5C3.224 19 3 18.776 3 18.5V1.5C3 1.224 3.224 1 3.5 1H12V4.5C12 5.327 12.673 6 13.5 6H17V18.5C17 18.776 16.776 19 16.5 19Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

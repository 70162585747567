import React from "react";
import {useTranslation} from "react-i18next";
// local
import cn from "classnames";

const TableTitleResult = ({title, totalItems, addClass, children}) => {
  const {t} = useTranslation();

  return (
    <div className={cn("table-title-results", {[addClass]: !!addClass})}>
      <div className="df">
        <h3 className="title">{t(title)}</h3>
        <span className="total-items">{totalItems}</span>
      </div>
      <div className="df">{children}</div>
    </div>
  );
};

export default TableTitleResult;

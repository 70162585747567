import React, {memo} from "react";

export const IconBell = memo(({color, size, addClass}) => {
  return (
    <svg
      width={size || "20"}
      height={size || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8 15.101C15.656 14.242 15 12.929 15 11.5V8.5C15 5.987 13.306 3.862 11 3.208V2.5C11 1.673 10.327 1 9.5 1C8.673 1 8 1.673 8 2.5V3.208C5.694 3.863 4 5.987 4 8.5V11.5C4 12.929 3.344 14.241 2.2 15.101C2.028 15.23 1.958 15.455 2.026 15.659C2.094 15.863 2.285 16.001 2.5 16.001H7.05C7.017 16.165 6.999 16.332 6.999 16.501C6.999 17.879 8.121 19.001 9.499 19.001C10.877 19.001 11.999 17.879 11.999 16.501C11.999 16.333 11.982 16.165 11.949 16.001H16.499C16.714 16.001 16.905 15.863 16.973 15.659C17.041 15.455 16.971 15.23 16.799 15.101H16.8ZM9 2.5C9 2.224 9.224 2 9.5 2C9.776 2 10 2.224 10 2.5V3.023C9.835 3.008 9.669 3 9.5 3C9.331 3 9.165 3.008 9 3.023V2.5ZM11 16.5C11 17.327 10.327 18 9.5 18C8.673 18 8 17.327 8 16.5C8 16.329 8.03 16.16 8.086 16H10.914C10.97 16.16 11 16.329 11 16.5ZM3.742 15C3.997 14.691 4.219 14.354 4.401 13.999C4.799 13.221 5 12.38 5 11.5V8.5C5 6.019 7.019 4 9.5 4C11.981 4 14 6.019 14 8.5V11.5C14 12.38 14.202 13.221 14.599 13.999C14.781 14.355 15.003 14.691 15.258 15H3.741H3.742Z"
        fill={color || "#000"}
      />
      <path
        d="M17.248 6.99994C17.025 6.99994 16.822 6.84994 16.764 6.62394C16.389 5.16694 15.59 3.86794 14.453 2.86694C14.246 2.68494 14.226 2.36894 14.408 2.16094C14.59 1.95294 14.906 1.93394 15.114 2.11594C16.402 3.24994 17.308 4.72194 17.733 6.37394C17.802 6.64094 17.641 6.91394 17.373 6.98294C17.331 6.99394 17.289 6.99894 17.248 6.99894V6.99994Z"
        fill={color || "#000"}
      />
      <path
        d="M18.501 4.63601C18.316 4.63601 18.137 4.53201 18.051 4.35401C17.786 3.80901 17.467 3.28601 17.101 2.80001C16.935 2.57901 16.98 2.26601 17.2 2.10001C17.42 1.93401 17.734 1.97901 17.9 2.19901C18.304 2.73601 18.657 3.31401 18.949 3.91701C19.07 4.16501 18.966 4.46501 18.718 4.58501C18.648 4.61901 18.573 4.63501 18.5 4.63501L18.501 4.63601Z"
        fill={color || "#000"}
      />
      <path
        d="M1.75201 6.99995C1.71101 6.99995 1.66901 6.99495 1.62701 6.98395C1.36001 6.91495 1.19901 6.64295 1.26701 6.37495C1.69201 4.72295 2.59801 3.24995 3.88601 2.11695C4.09301 1.93495 4.40901 1.95495 4.59201 2.16195C4.77501 2.36895 4.75401 2.68495 4.54701 2.86795C3.41001 3.86895 2.61101 5.16795 2.23601 6.62495C2.17801 6.85095 1.97501 7.00095 1.75201 7.00095V6.99995Z"
        fill={color || "#000"}
      />
      <path
        d="M0.498986 4.63604C0.425986 4.63604 0.350986 4.62004 0.280986 4.58604C0.0329861 4.46504 -0.0710139 4.16604 0.0499861 3.91804C0.342986 3.31504 0.695986 2.73704 1.09899 2.20004C1.26499 1.97904 1.57799 1.93504 1.79899 2.10104C2.01999 2.26704 2.06399 2.58004 1.89799 2.80104C1.53299 3.28704 1.21299 3.81004 0.947986 4.35504C0.861986 4.53304 0.682986 4.63704 0.497986 4.63704L0.498986 4.63604Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconDownload = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8532 9.647C14.6582 9.452 14.3412 9.452 14.1462 9.647L10.0002 13.793V2.5C10.0002 2.224 9.77623 2 9.50023 2C9.22423 2 9.00023 2.224 9.00023 2.5V13.793L4.85423 9.647C4.65923 9.452 4.34223 9.452 4.14723 9.647C3.95223 9.842 3.95223 10.159 4.14723 10.354L9.14723 15.354C9.24523 15.452 9.37323 15.5 9.50123 15.5C9.62923 15.5 9.75723 15.451 9.85523 15.353L14.8552 10.353C15.0502 10.158 15.0502 9.841 14.8552 9.646L14.8532 9.647Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M17.5 19H1.5C0.673 19 0 18.327 0 17.5V15.5C0 15.224 0.224 15 0.5 15C0.776 15 1 15.224 1 15.5V17.5C1 17.776 1.224 18 1.5 18H17.5C17.776 18 18 17.776 18 17.5V15.5C18 15.224 18.224 15 18.5 15C18.776 15 19 15.224 19 15.5V17.5C19 18.327 18.327 19 17.5 19Z"
        fill={color || "#1BA2CC"}
      />
    </svg>
  );
});

import React from "react";
import Countdown from "react-countdown";

const CountdownComponent = ({targetDate}) => {
  return (
    <Countdown
      date={new Date(targetDate)}
      renderer={({days, hours, minutes, seconds}) => (
        <span style={{whiteSpace: "nowrap"}}>
          {days}d {hours}h {minutes}m {seconds}s
        </span>
      )}
    />
  );
};

const CampaignCountdown = ({targetDate, addClass}) => {
  return (
    <span className={`countdown ${addClass}`}>
      <CountdownComponent targetDate={targetDate} />
    </span>
  );
};

export default CampaignCountdown;

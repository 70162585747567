import React from "react";

export const IconLinkIbinder = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11243_188405)">
        <path
          d="M17.9126 17.3593C18.002 17.3114 18.0577 17.2227 18.0577 17.1036C18.0577 16.9318 17.9253 16.8191 17.7436 16.8191H17.5062C17.4548 16.8191 17.421 16.8522 17.421 16.9043V17.6579C17.421 17.7093 17.4555 17.7431 17.5076 17.7431C17.5597 17.7431 17.5956 17.71 17.5956 17.6579V17.3994H17.7407L17.8661 17.6868C17.8802 17.7185 17.9112 17.7424 17.9471 17.7424C17.9943 17.7424 18.0365 17.7037 18.0365 17.6544C18.0365 17.6445 18.0337 17.6333 18.0309 17.6269L17.9126 17.3593ZM17.7252 17.2473H17.5956V16.9712H17.7252C17.8217 16.9712 17.8802 17.024 17.8802 17.1079C17.8802 17.1931 17.8217 17.2473 17.7252 17.2473Z"
          fill="white"
        />
        <path
          d="M20.038 0.000976562H0.0379639V20.001H20.038V0.000976562Z"
          fill="#314F6F"
        />
        <path
          d="M15.1579 3.59318L12.3638 4.83216V1.77179C12.3638 1.57594 12.1621 1.44508 11.9833 1.52463L4.80687 4.71787C4.64328 4.7907 4.53796 4.95294 4.53796 5.13199V16.1295C4.53796 16.283 4.61572 16.4262 4.74457 16.5098L7.81434 18.4983C7.94297 18.5815 8.10499 18.5943 8.24482 18.532L15.3775 15.3553C15.4752 15.3118 15.538 15.215 15.538 15.1081V3.84035C15.538 3.64472 15.3367 3.51385 15.1579 3.59318V3.59318Z"
          fill="white"
        />
        <path
          d="M6.54938 4.44165V5.96836L5.15466 5.05968L6.54938 4.44165V4.44165Z"
          fill="#314F6F"
        />
        <path
          d="M9.71637 17.3793L8.19011 18.0556V7.18622L9.71637 6.5097V17.3793Z"
          fill="#314F6F"
        />
        <path
          d="M6.62737 14.688V13.14L7.29403 13.5742V15.1222L6.62737 14.688ZM6.00463 8.53765L5.42021 8.15693V6.60893L6.00463 6.98988V8.53765V8.53765ZM6.00463 11.4105L5.42021 11.0297V9.48151L6.00463 9.86245V11.4105V11.4105ZM6.00463 14.2833L5.42021 13.9025V12.3545L6.00463 12.7353V14.2833ZM6.62737 10.2672L7.29403 10.7014V12.2494L6.62737 11.8152V10.2672ZM6.62737 7.39436L7.29403 7.82864V9.37663L6.62737 8.94258V7.39436ZM7.91296 18.0087L7.91273 7.16848L5.0229 5.28592V16.1259L5.98625 16.7525V15.4967L6.65291 15.9307V17.1868L7.91296 18.0087V18.0087Z"
          fill="#314F6F"
        />
      </g>
      <defs>
        <clipPath id="clip0_11243_188405">
          <path
            d="M0.0379639 2.00098C0.0379639 0.896408 0.933394 0.000976562 2.03796 0.000976562H18.038C19.1425 0.000976562 20.038 0.896407 20.038 2.00098V18.001C20.038 19.1055 19.1425 20.001 18.038 20.001H2.03796C0.933395 20.001 0.0379639 19.1055 0.0379639 18.001V2.00098Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

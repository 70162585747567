import React from "react";
import PresentationPageHeaderMainEdit from "./PresentationPageHeaderMainEdit/PresentationPageHeaderMainEdit";
import PresentationPageHeaderMain from "./PresentationPageHeaderMain";

const PresentationPageHeaderRight = ({edit, setEdit}) => {
  if (edit.main) return <PresentationPageHeaderMainEdit edit={edit} setEdit={setEdit} />;
  return <PresentationPageHeaderMain edit={edit} setEdit={setEdit} />;
};

export default PresentationPageHeaderRight;

import React, {useState} from "react";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {Upload} from "antd";
import {showSuccess} from "helpers/notifications.helper";
import {trimString} from "helpers/helper";
import {beforeUpload} from "helpers/uploader";

const EditUploadImage = ({item, uploadFile, setFieldValue, fieldName}) => {
  const fileName = item?.name;
  const [loading, setLoading] = useState(false);

  const customRequest = ({file, onError}) => {
    setLoading(true);

    uploadFile(file)
      .then(({url, id, name}) => {
        showSuccess(`${file.name} file uploaded successfully`);
        setFieldValue(fieldName, [{url, id, name}]);
      })
      .catch(onError)
      .finally(() => setLoading(false));
  };

  const uploadProps = {
    name: "file",
    showUploadList: false,
    beforeUpload,
    customRequest,
  };

  return (
    <div className="editBlock_upload__item mr-2">
      {item && (
        <a className="editBlock_upload__link" href={item.url || "#"}>
          {trimString(fileName)}
        </a>
      )}

      <Upload {...uploadProps}>
        <ButtonTransparent
          name={loading ? "LOADING" : "UPLOAD"}
          type="button"
          addClass="editBlock_upload__btn"
          disable={loading}
        />
      </Upload>
    </div>
  );
};

export default EditUploadImage;

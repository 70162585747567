import React from "react";
import cn from "classnames";
import {useTranslation} from "react-i18next";
//local

// color: "blue" | "red" | "green" | "purple" | "orange"
// border: "top" | "bottom" | "topbottom"
export const ColorRow = ({title, filled, color, border, className, children}) => {
  const {t} = useTranslation();

  return (
    <div className={cn("color-row_wrapper")}>
      {title && (
        <div
          className={cn(
            "color-row",
            {[className]: !!className},
            {filled: !!filled},
            {[border]: !!border}
          )}
        >
          {!filled && <div className={cn("color-row_strip", {[color]: !!color})}></div>}
          <div className="color-row_title">{t(title)}</div>
        </div>
      )}
      <div
        className={cn(
          "color-row",
          {[className]: !!className},
          {filled: !!filled},
          {[border]: !!border}
        )}
      >
        {!filled && <div className={cn("color-row_strip", {[color]: !!color})}></div>}
        {children}
      </div>
    </div>
  );
};

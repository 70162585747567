import React from "react";
import {useTranslation} from "react-i18next";
//locale
import {IconInbox} from "components/icons";
import {VideoInstructionLink} from "components/VideoInstructionLink";

const RequestsToUsHead = () => {
  const {t} = useTranslation();
  return (
    <div className="content-block_header">
      <IconInbox width="40" height="40" className="mt-0" />
      <h3 className="content-block_title __uppercase">{t("REQUESTS_TO_US_TITLE")}</h3>
      <VideoInstructionLink
        label="VIDEO_LINK_REQUESTS_TO_US_LABEL"
        link="VIDEO_LINK_REQUESTS_TO_US_LINK"
        addClass="ml-8"
      />
    </div>
  );
};

export default RequestsToUsHead;

import React, {memo} from "react";

export const IconCommunity = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 6.46701C12.996 6.30101 12.962 5.55801 12.649 4.80801C12.435 4.29401 12.135 3.88001 11.758 3.57901C11.662 3.50201 11.56 3.43301 11.455 3.37101C11.793 3.01301 12 2.53101 12 2.00101C12 0.898007 11.103 0.00100708 10 0.00100708C8.897 0.00100708 8 0.898007 8 2.00101C8 2.53101 8.208 3.01301 8.545 3.37101C8.44 3.43301 8.339 3.50201 8.242 3.57901C7.865 3.88101 7.565 4.29501 7.351 4.80801C7.004 5.64101 7 6.46601 7 6.50001C7 6.77601 7.224 7.00001 7.5 7.00001H12.5C12.5 7.00001 12.5 7.00001 12.501 7.00001C12.777 7.00001 13.001 6.77601 13.001 6.50001C13.001 6.48901 13.001 6.47801 13 6.46701ZM9 2.00001C9 1.44901 9.449 1.00001 10 1.00001C10.551 1.00001 11 1.44901 11 2.00001C11 2.55101 10.551 3.00001 10 3.00001C9.449 3.00001 9 2.55101 9 2.00001ZM8.05 6.00001C8.088 5.76601 8.156 5.47701 8.274 5.19201C8.608 4.39001 9.173 4.00001 10 4.00001C11.429 4.00001 11.837 5.26801 11.953 6.00001H8.049H8.05Z"
        fill={color || "#000"}
      />
      <path
        d="M6 17.467C5.996 17.301 5.962 16.558 5.649 15.808C5.435 15.294 5.135 14.881 4.758 14.579C4.662 14.502 4.56 14.433 4.455 14.371C4.793 14.013 5 13.531 5 13.001C5 11.898 4.103 11.001 3 11.001C1.897 11.001 1 11.898 1 13.001C1 13.531 1.208 14.013 1.545 14.371C1.44 14.433 1.339 14.502 1.242 14.579C0.865 14.881 0.565 15.295 0.351 15.808C0.00400001 16.641 0 17.466 0 17.5C0 17.776 0.224 18 0.5 18H5.5C5.5 18 5.5 18 5.501 18C5.777 18 6.001 17.776 6.001 17.5C6.001 17.489 6.001 17.478 6 17.467ZM2 13C2 12.449 2.449 12 3 12C3.551 12 4 12.449 4 13C4 13.551 3.551 14 3 14C2.449 14 2 13.551 2 13ZM1.05 17C1.088 16.766 1.156 16.477 1.274 16.192C1.608 15.39 2.173 15 3 15C4.429 15 4.837 16.268 4.953 17H1.049H1.05Z"
        fill={color || "#000"}
      />
      <path
        d="M20 17.467C19.996 17.301 19.962 16.558 19.649 15.808C19.435 15.294 19.135 14.881 18.758 14.579C18.662 14.502 18.56 14.433 18.455 14.371C18.793 14.013 19 13.531 19 13.001C19 11.898 18.103 11.001 17 11.001C15.897 11.001 15 11.898 15 13.001C15 13.531 15.208 14.013 15.545 14.371C15.44 14.433 15.339 14.502 15.242 14.579C14.865 14.881 14.565 15.295 14.351 15.808C14.004 16.641 14 17.466 14 17.5C14 17.776 14.224 18 14.5 18H19.5C19.5 18 19.5 18 19.501 18C19.777 18 20.001 17.776 20.001 17.5C20.001 17.489 20.001 17.478 20 17.467ZM16 13C16 12.449 16.449 12 17 12C17.551 12 18 12.449 18 13C18 13.551 17.551 14 17 14C16.449 14 16 13.551 16 13ZM15.05 17C15.088 16.766 15.156 16.477 15.274 16.192C15.608 15.39 16.173 15 17 15C18.429 15 18.837 16.268 18.953 17H15.049H15.05Z"
        fill={color || "#000"}
      />
      <path
        d="M10.0001 20C8.70009 20 7.40909 19.681 6.26609 19.077C6.02209 18.948 5.9291 18.645 6.05809 18.401C6.18709 18.157 6.4901 18.064 6.7341 18.193C7.7331 18.721 8.86209 19 10.0001 19C11.1381 19 12.2671 18.721 13.2661 18.193C13.5101 18.064 13.8131 18.157 13.9421 18.401C14.0711 18.645 13.9781 18.948 13.7341 19.077C12.5921 19.681 11.3011 20 10.0001 20Z"
        fill={color || "#000"}
      />
      <path
        d="M17.2299 10.5C17.0109 10.5 16.8089 10.355 16.7479 10.133C16.2839 8.45301 15.2129 7.01301 13.7329 6.07701C13.4999 5.92901 13.4299 5.62101 13.5769 5.38701C13.7239 5.15301 14.0329 5.08401 14.2669 5.23101C15.9589 6.30001 17.1819 7.94601 17.7119 9.86601C17.7849 10.132 17.6289 10.408 17.3629 10.481C17.3189 10.493 17.2739 10.499 17.2299 10.499V10.5Z"
        fill={color || "#000"}
      />
      <path
        d="M2.77048 10.5C2.72648 10.5 2.68148 10.494 2.63748 10.482C2.37148 10.409 2.21548 10.133 2.28848 9.86699C2.81848 7.94699 4.04248 6.30099 5.73348 5.23199C5.96648 5.08499 6.27548 5.15399 6.42348 5.38799C6.57148 5.62199 6.50148 5.92999 6.26748 6.07799C4.78648 7.01299 3.71548 8.45399 3.25248 10.134C3.19148 10.356 2.99048 10.501 2.77048 10.501V10.5Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconCommunity;

import React, {useEffect, useState, useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
//local
import {ProfileSettings} from "../ProfileSettings";
import {Language} from "../Language";
import DrawerComponent from "../Drawer";
import Settings from "../Settings";
import {hashes} from "../../constants/tabs.const";
import {updateLocationHash} from "helpers/helper";
import {inject, observer} from "mobx-react";
import SaveSearch from "./components/SaveSearch";
import JoiningOrg from "./components/JoiningOrg";
import Suppliers from "./components/Suppliers";
import Logo from "./components/Logo";
import {PROJECTS, PUBLIC_RFTS} from "constants/routes.const";

export const Header = inject("store")(
  observer(({store: {auth, clientStore}, type, addClass}) => {
    const {
      myOrganizations,
      user,
      token,
      activeOrganizationId,
      joinRequest,
      organizationSubscription,
    } = auth;
    const {rfpMessages} = clientStore;
    const [ifDrawer, setDrawer] = useState(false);
    const [tabDrawer, setTabDrawer] = useState("");
    const params = useLocation();
    const navigate = useNavigate();

    const userSubscriptions = useMemo(
      () => organizationSubscription?.SubscribeProducts?.map((item) => item.name),
      [organizationSubscription]
    );
    const isBuyer =
      userSubscriptions?.includes("SUBSCRIPTION_NAME_04") ||
      userSubscriptions?.includes("SUBSCRIPTION_NAME_05");

    useEffect(() => {
      token && activeOrganizationId && clientStore.getMessages();
    }, [token, activeOrganizationId, clientStore]);

    useEffect(() => {
      if (!!params.hash) {
        setDrawer(true);
        setTabDrawer(params.hash);
      }
    }, [params]);

    const changeDrawer = (status) => {
      setDrawer(!!status);
      updateLocationHash(status ? hashes.MEMBERS : "");
      setTabDrawer(status ? hashes.MEMBERS : "");
    };

    const subscribes = useMemo(
      () => user?.ActiveOrganization?.SubscribeUnit?.SubscribeProducts,
      [user?.ActiveOrganization?.SubscribeUnit]
    );

    const subscriptionName = useMemo(
      () =>
        subscribes?.length === 1 && subscribes[0].name.includes("01")
          ? subscribes[0].name
          : undefined,
      [subscribes]
    );

    return (
      <div className={`header ${addClass ? addClass : ""}`}>
        <DrawerComponent
          addClass="header__profile-settings__wrapper"
          ifDrawer={ifDrawer}
          closeDrawer={() => changeDrawer(false)}
        >
          <Settings closeDrawer={() => changeDrawer(false)} ifDrawer={tabDrawer} />
        </DrawerComponent>

        <button
          className="header_btn__showSidebar btn-custom_showSidebar"
          onClick={() => clientStore.setShowSidebar(!clientStore.showSidebar)}
        >
          <span />
        </button>

        <Logo
          isBuyer={isBuyer}
          activeLogo={user?.ActiveOrganization?.OrganizationMainLogoFile?.File?.url}
        />

        {type === "language" && <Language />}

        {type === "profileSettings" && <ProfileSettings />}

        {token && type === "profile" && (
          <Suppliers
            myOrganizations={myOrganizations}
            messages={rfpMessages}
            subscriptionName={subscriptionName}
            onClick={() => changeDrawer(true)}
            sendSetActiveOrganization={(values) =>
              auth
                .sendSetActiveOrganization(values)
                .then(() => navigate(isBuyer ? PROJECTS : PUBLIC_RFTS))
            }
            activeOrganizationId={activeOrganizationId}
          />
        )}

        {type === "saveSearch" && <SaveSearch user={user} />}

        {type === "joining-org" && <JoiningOrg organizatioName={joinRequest[0]?.name} />}
      </div>
    );
  })
);

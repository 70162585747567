import React, {memo} from "react";

export const IconUser = memo(({color}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 11.875C6.967 11.875 4.5 9.408 4.5 6.375C4.5 3.342 6.967 0.875 10 0.875C13.033 0.875 15.5 3.342 15.5 6.375C15.5 9.408 13.033 11.875 10 11.875ZM10 1.875C7.519 1.875 5.5 3.894 5.5 6.375C5.5 8.856 7.519 10.875 10 10.875C12.481 10.875 14.5 8.856 14.5 6.375C14.5 3.894 12.481 1.875 10 1.875Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M18 20.875H2C1.173 20.875 0.5 20.202 0.5 19.375C0.5 19.307 0.514 17.69 1.725 16.075C2.43 15.135 3.395 14.388 4.594 13.856C6.058 13.205 7.877 12.875 10 12.875C12.123 12.875 13.942 13.205 15.406 13.856C16.605 14.389 17.57 15.135 18.275 16.075C19.486 17.69 19.5 19.307 19.5 19.375C19.5 20.202 18.827 20.875 18 20.875ZM10 13.875C6.513 13.875 3.94 14.828 2.559 16.631C1.524 17.982 1.501 19.363 1.5 19.377C1.5 19.651 1.724 19.875 2 19.875H18C18.276 19.875 18.5 19.651 18.5 19.375C18.5 19.363 18.477 17.982 17.441 16.631C16.059 14.828 13.486 13.875 10 13.875Z"
        fill={color || "#1BA2CC"}
      />
    </svg>
  );
});

import * as yup from "yup";
import {stringRequired, emailRequired} from "./../../yup/defaultValudations";

export const schema = yup.object().shape({
  firstName: stringRequired(),
  lastName: stringRequired(),
  email: emailRequired(),
  companyNumber: stringRequired()
    // eslint-disable-next-line
    .matches(/^[\d]{6}\-[\d]{4}$/, "The field is not completely filled."),
  companyName: stringRequired(),
  // subscription: stringRequired(),
});

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  companyNumber: "",
  companyName: "",
  // subscription: "SUBSCRIPTION_NAME_PRICE_01",
};

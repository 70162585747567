import React, {useEffect, useState, useMemo, useCallback} from "react";
import {usePostHog} from "posthog-js/react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
//local
// import {VideoInstructionLink} from "components/VideoInstructionLink";
import {Layout} from "components/UI/layout";
import {
  IconBinoculars,
  IconFunnel,
  IconCog,
  IconCross,
  IconMailLetter,
  IconPlusCircle,
} from "components/icons";
import {InfoTooltip} from "components/InfoTooltip";
import PublicRfpsTableHead from "./components/PublicRfpsTableHead";
import {Preloader} from "components/Preloader";
import {PaginationType} from "components/Pagination";
import PublicRfpsTableItem from "./components/PublicRfpsTableItem";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import MultipleSelect from "components/selects/MultipleSelect";
import SelectionRow from "components/selects/SelectionRow";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import EmptyData from "components/EmptyData";
import NotifierDot from "components/NotifierDot";
import {LockValue} from "components/LockValue";
import SubscriptionUpgrageCustomLocalModal from "components/modals/SubscriptionUpgradeCustomLocalModal";
import {PublicRfpFilterSettingsModal} from "components/modals/PublicRfpFilterSettingsModal";
import {hasPermission} from "helpers/helper";
import {publicRfpsFilterRow} from "constants/tabs.const";
import Star from "assets/images/svg/star.svg";
import {color} from "constants/color.consts";
import {ShareButton} from "components/buttons/ShareButton";
import {copyToClipboard} from "helpers/helper";
import CustomCampaign from "components/CustomCampaign";

const PublicRfpsPage = inject("store")(
  observer(({store: {auth, seller}}) => {
    const {loading, publicRfpsFilterParams} = seller;
    const {
      user: {ActiveOrganization, permissions, email},
    } = auth;
    const {t} = useTranslation();
    const [publicRfpsData, setPublicRfpsData] = useState(null);
    const [filterSettings, setFilterSettings] = useState(null);
    const [isPublicPFPFilterSettingsModal, setPublicPFPFilterSettingsModal] =
      useState(false);
    const [saveFilterSettingsLoading, setSaveFilterSettingsLoading] = useState(false);
    const [enableMonitoringToggle, setEnableMonitoringToggle] = useState(false);
    const maxCategories =
      permissions.find(
        (item) => item.PermissionId === "LIMIT_OPEN_RFT_MONITOR_MAX_CATEGORIES"
      )?.quantity ?? 0;
    const maxRegions =
      permissions.find(
        (item) => item.PermissionId === "LIMIT_OPEN_RFT_MONITOR_MAX_REGIONS"
      )?.quantity ?? 0;

    const filterRowItems = useMemo(
      () =>
        Object.entries(publicRfpsFilterParams)
          .filter((item) => Array.isArray(item[1]))
          .map((item) => item[1].map((value) => [item[0], value]))
          .flat()
          .sort((a, b) => (a[0] > b[0] ? -1 : 1)),
      [publicRfpsFilterParams]
    );

    const showInfoAndClick = hasPermission(["READ_OTHER_OPEN_RFT"]);
    const canEnableFilterMonitoringToggle = hasPermission(
      ["ENABLE_SWITCH_ON_OPEN_RFT_MONITORING_TOGGLE"],
      permissions
    );

    const fetchPublicRequests = useCallback(
      async (noNeedUpdateRequests) => {
        await auth.getPublicRequestsMonitoringSetting().then(({data}) => {
          if (!data) return;
          const {id, createdAt, updatedAt, ...filterData} = data;
          setFilterSettings(filterData);
          if (noNeedUpdateRequests) return;
          const {enableMonitoring} = filterData;
          setEnableMonitoringToggle(enableMonitoring);
          // const {enableMonitoring, ...filters} = filterData;
          // setEnableMonitoringToggle(enableMonitoring);
          // seller.setPublicRfpsFilterParams(
          //   Object.entries(filters)
          //     .filter((item) => Array.isArray(item[1]) && !!item[1].length)
          //     .reduce((acc, item) => Object.assign(acc, {[item[0]]: item[1]}), {})
          // );
        });
        await seller.getPublicRequests().then(setPublicRfpsData);
      },
      [auth, seller]
    );

    const clearFilters = useCallback(() => {
      seller.setPublicRfpsFilterParams({});
      setPublicRfpsData(null);
      seller.getPublicRequests().then(setPublicRfpsData);
    }, [seller]);

    const onSelectChange = (name, value) => {
      const params = {...publicRfpsFilterParams, [name]: value};
      if (!value || (Array.isArray(value) && !value.length)) delete params[name];
      seller.setPublicRfpsFilterParams(params);
      setPublicRfpsData(null);
      seller.getPublicRequests().then(setPublicRfpsData);
    };

    const handleRemoveFilterValue = (value) => {
      const deletedName = filterRowItems.find((item) => item[1] === value)?.[0];
      const deletedValue = filterRowItems
        .filter((item) => item[0] === deletedName && item[1] !== value)
        .map((item) => item[1]);
      deletedName && onSelectChange(deletedName, deletedValue);
    };

    const handleEnableFilterSettings = useCallback(
      (checked) => {
        if (!canEnableFilterMonitoringToggle) return;
        const enableMonitoring = filterSettings?.enableMonitoring;
        checked &&
          !enableMonitoring &&
          !enableMonitoringToggle &&
          setPublicPFPFilterSettingsModal(true);
        setEnableMonitoringToggle(checked);
        if (
          (!checked && enableMonitoring) ||
          (checked && enableMonitoringToggle && !enableMonitoring)
        )
          auth
            .setPublicRequestsMonitoringSetting({enableMonitoring: checked})
            .then(({data}) => {
              const {id, createdAt, updatedAt, ...filterData} = data;
              setFilterSettings(filterData);
            });
      },
      [auth, canEnableFilterMonitoringToggle, filterSettings, enableMonitoringToggle]
    );

    // disabling monitoring in the absence of selected values
    const needChangeMonitoring = useCallback(
      (values) => {
        if (!filterSettings) return handleEnableFilterSettings(false);
        const {enableMonitoring, ...filters} = filterSettings;
        const selectedFilters = Object.entries(values ?? filters).filter(
          (item) => !!item[1]?.length
        );
        const hasEnoughSelectedFilters =
          selectedFilters.length > 1 &&
          selectedFilters.some((item) => item[0] === "availableIn");
        if (hasEnoughSelectedFilters)
          !enableMonitoring && handleEnableFilterSettings(true);
        else handleEnableFilterSettings(false);
      },
      [filterSettings, handleEnableFilterSettings]
    );

    const handleSaveFilterSettings = async (values) => {
      setSaveFilterSettingsLoading(true);
      const payload = Object.entries(values)
        .filter((item) => !!item[1])
        .reduce((filters, item) => Object.assign(filters, {[item[0]]: item[1]}), {});
      await auth
        .setPublicRequestsMonitoringSetting(payload)
        .then(() => setPublicPFPFilterSettingsModal(false))
        .finally(() => {
          enableMonitoringToggle && needChangeMonitoring(values);
          setSaveFilterSettingsLoading(false);
        });
      await fetchPublicRequests(true);
    };

    const handleClosePublicRfpFilterSettingsModal = () => {
      enableMonitoringToggle && needChangeMonitoring();
      setPublicPFPFilterSettingsModal(false);
    };

    useEffect(() => {
      fetchPublicRequests();
      return () => seller.setPublicRfpsFilterParams({});
    }, [seller, fetchPublicRequests]);

    const posthog = usePostHog();
    const copyLink = () => {
      posthog?.capture("Clicked share button on public RFT listing page");
      copyToClipboard(window.location, true);
    };

    return (
      <Layout>
        <div className="public-rfps">
          <div className="content-block_header">
            <div className="heading">
              <IconBinoculars />
              <h3 className="content-block_title __uppercase mr-2">{t("PUBLIC_RFP")}</h3>
              <span className="live">{t("MAIN_MENU_BADGE_LIVE")}</span>
              <InfoTooltip tooltip="PUBLIC_RFPS_PAGE_BETA_INFO_LABEL" centerText />
            </div>
            <div className="notifyer df-row-center mla">
              {!!publicRfpsData && !filterSettings?.enableMonitoring && (
                <React.Fragment>
                  <NotifierDot addClass="mr-1" />
                  <span>{t("PUBLIC_RFPS_PAGE_FILTER_SETTINGS_DESCRIPTION")}</span>
                </React.Fragment>
              )}

              <LockValue
                canPermissions={
                  !enableMonitoringToggle
                    ? ["ENABLE_SWITCH_ON_OPEN_RFT_MONITORING_TOGGLE"]
                    : []
                }
                needLabel
                addClassIcon="open-rft-switch-locked-icon"
                needShowModalToSubscribe
              >
                <CustomRadioSwitch
                  checked={enableMonitoringToggle}
                  uppercase
                  small
                  addClass="ml-2"
                  isActive={handleEnableFilterSettings}
                />
              </LockValue>
              <IconMailLetter height={15} width={15} addClass="ml-1" />
              <LockValue
                canPermissions={["READ_PUBLIC_RFTS"]}
                needLabel
                needShowModalToSubscribe
                addClassIcon="mr-3 ml-0"
              >
                <ButtonTransparent
                  icon={<IconCog height="16" width="16" />}
                  noBorder
                  small
                  addClass="__rotate-on-hover pr-0"
                  onClick={() =>
                    hasPermission(["READ_PUBLIC_RFTS"]) &&
                    setPublicPFPFilterSettingsModal(true)
                  }
                />
              </LockValue>

              <InfoTooltip
                addWrapperClass="ml-2"
                tooltip="PUBLIC_RFPS_PAGE_FILTER_STAT_TOOLTIP"
                centerText
              />
            </div>
            <div className="share-feature ml-12">
              <ShareButton
                addClass="feed-post-view__share-button"
                name="SHARE_THIS"
                onClickCopy={copyLink}
              />
            </div>
          </div>

          <div className="public-rfps__filters pt-4 pl-8 pr-8 pb-8">
            <div className="df-row-start">
              <IconFunnel height="30" width="30" color={color.green} addClass="mr-4" />
              <div className="df-row-wrap-center">
                <LockValue
                  canPermissions={["READ_OTHER_OPEN_RFT"]}
                  needLabel
                  smallLockIcon={true}
                  needShowModalToSubscribe
                  addClassIcon={"mb-2"}
                >
                  {publicRfpsFilterRow.map((selection) => (
                    <MultipleSelect
                      key={selection.label}
                      value={publicRfpsFilterParams?.[selection.label]}
                      list={selection.array}
                      customSelectAllElement={selection.selectAll}
                      tiny
                      addClass="mr-2 mb-2"
                      uppercaseButton
                      notShowingSelections
                      needPermissions={["READ_OTHER_OPEN_RFT"]}
                      needSearch={selection.isNeedSearch}
                      color={selection.color}
                      bottomElement={selection.bottomElement}
                      onChange={(value) => onSelectChange(selection.label, value)}
                      title={selection.title}
                      titleInButton
                    />
                  ))}
                </LockValue>
              </div>

              {!!Object.keys(publicRfpsFilterParams).length && !!publicRfpsData && (
                <div className="df-row-wrap-jce-start mla">
                  <IconFunnel addClass="mr-1 mb-2" />
                  <span>
                    {t("PUBLIC_RFPS_PAGE_FILTER_STAT")?.format(
                      publicRfpsData.totalItems,
                      publicRfpsData.totalUnfilteredItems
                    )}
                  </span>
                  <ButtonTransparent
                    name={t("CLEAR_FILTERS_BUTTON")}
                    onClick={clearFilters}
                    icon={<IconCross color={color.link} width="7" height="7" />}
                    addClass="__uppercase ml-4"
                    tiny
                  />
                </div>
              )}
            </div>
            {!!Object.keys(publicRfpsFilterParams).length && (
              <SelectionRow
                values={filterRowItems.map((item) => ({
                  value: item[1],
                  label: item[1],
                  color: item[1].includes("REGION") ? "blue" : "green",
                }))}
                tiny
                addClass="ml-10"
                onRemove={handleRemoveFilterValue}
              />
            )}
          </div>
        </div>
        {loading && <Preloader addClass="preloader500" />}
        {!loading && (
          <div className="public-rfps content-block_wrapper">
            {!hasPermission(["READ_OTHER_OPEN_RFT"], permissions) && (
              <>
                <SubscriptionUpgrageCustomLocalModal
                  canPermissions={["READ_OTHER_OPEN_RFT"]}
                  needDirectPurchase
                  addClass="subscription-custom-local-modal"
                >
                  {/* Note to future Johan: this is the custom upgrade box clickable content */}
                  <div className="custom-upgrade-box">
                    <div className="offer df-column-center">
                      <p className="mb-4">{t("UPGRADE_TO_GET_SUBSCRIPTION_06")}</p>
                      <div className="cta">
                        <span className="action">
                          <span className="pulser">&nbsp;</span>
                          <IconPlusCircle width="20" height="20" addClass="icon mr-2" />
                          <span className="text">
                            <span>{t("GET_THE_SERVICE")}</span>
                            <span className="name">{t("SUBSCRIPTION_NAME_06")}</span>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="price-blob">
                      <img
                        className="star"
                        src={Star}
                        alt=""
                        width="100%"
                        height="100%"
                      />
                      <div className="star-content">
                        <span className="price-type">
                          {t("SUBSCRIPTION_TAB_PRICE_FROM")}
                        </span>
                        <span className="price">
                          {t("CURRENT_PRICE_SUBSCRIPTION_06")}
                        </span>
                        <span className="price-type">
                          {t("PRICE_MESSAGE_OFFER_SUB_06")}
                        </span>
                      </div>
                    </div>
                  </div>
                </SubscriptionUpgrageCustomLocalModal>
                {/* 
                This is a timed campaign widget with a a countdown.
                It sits fixed as a bottom strip on the screen on the public RFPs page.
                It disappears when the countdown (endDate) is over.
                */}
                <CustomCampaign
                  endDate="2024-06-30T23:59:59"
                  tooltipTextKey="CUSTOM_CAMPAIGN_TOOLTIP"
                  textKey="CUSTOM_CAMPAIGN_TEXT"
                  detailsKey="CUSTOM_CAMPAIGN_DETAILS"
                  backgroundColor="#5fa95d"
                  textColor="white"
                />
              </>
            )}
            <PublicRfpsTableHead showInfoAndClick={showInfoAndClick} />
            {publicRfpsData?.requests?.length ? (
              publicRfpsData.requests.map((publicRfp) => (
                <PublicRfpsTableItem
                  key={publicRfp.id}
                  publicRfp={publicRfp}
                  organizationTurnover={Math.round(
                    ActiveOrganization?.Company?.turnover / 1000
                  )}
                  showInfoAndClick={showInfoAndClick}
                />
              ))
            ) : (
              <EmptyData addClass={`empty-data pt-4`} />
            )}
            {publicRfpsData?.totalItems > 20 && (
              <PaginationType
                totalItems={publicRfpsData?.totalItems}
                currentPage={publicRfpsData?.currentPage ?? 1}
                countPerPage={20}
                onPageChange={(pageNumber) =>
                  seller.getPublicRequests(pageNumber).then(setPublicRfpsData)
                }
                addClass="mt-8"
              />
            )}
          </div>
        )}

        {isPublicPFPFilterSettingsModal && (
          <PublicRfpFilterSettingsModal
            initialFilterSettings={() => {
              if (!filterSettings) return {};
              const {enableMonitoring, ...rest} = filterSettings;
              return rest;
            }}
            saveLoading={saveFilterSettingsLoading}
            onSave={handleSaveFilterSettings}
            maxCategories={maxCategories}
            maxRegions={maxRegions}
            email={email}
            onClose={handleClosePublicRfpFilterSettingsModal}
          />
        )}
      </Layout>
    );
  })
);

export default PublicRfpsPage;

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {inject, observer} from "mobx-react";
//local
import {Layout} from "components/UI/layout";
import {Preloader} from "components/Preloader";
import {IconMapMarker} from "components/icons";
import {Proposals} from "./components/Proposals";
import {saveDocument} from "helpers/helper";
import {color} from "constants/color.consts";

const RFPOffersPage = inject("store")(
  observer(({store: {publicStore}}) => {
    const {currentRequest} = publicStore;
    const {requestId} = useParams();
    const [proposals, setProposals] = useState([]);
    const [manualProposals, setManualProposals] = useState([]);
    const [isDownload, setIsDownload] = useState(false);
    const [loading, setLoading] = useState(true);

    const downloadXLSX = async () => {
      setIsDownload(true);
      const fileName = `offers-${currentRequest.id}.xlsx`;
      publicStore
        .downloadXLSX(currentRequest.id)
        .then((data) =>
          saveDocument(
            data,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            fileName
          )
        )
        .finally(() => setIsDownload(false));
    };

    useEffect(() => {
      publicStore
        .getCurrentRequestInfo(requestId)
        .then(() =>
          publicStore.getCombinedOffers(requestId).then((data) => {
            setProposals(data.buyerOffers);
            setManualProposals(data.manualOffers);
          })
        )
        .finally(() => setLoading(false));
    }, [publicStore, requestId]);

    return (
      <Layout>
        <div className="request">
          {loading && <Preloader addClass="preloader500" />}
          {!loading && (
            <React.Fragment>
              <div className="content-block_header pb-5">
                <div className="view-header _wrapper">
                  <div className="view-header_left-side">
                    <div className="df-row-center">
                      {currentRequest.location && (
                        <React.Fragment>
                          <IconMapMarker addClass="mr-1 mt-1" color={color.blue} />
                          <span className="view-header__address">
                            {currentRequest.location}
                          </span>
                        </React.Fragment>
                      )}
                    </div>
                    <h3 className="view-header__title">{currentRequest.name}</h3>
                  </div>
                </div>
              </div>
              <div className="request content-block_wrapper">
                <Proposals
                  proposals={proposals}
                  manualProposals={manualProposals}
                  currentRequest={currentRequest}
                  onDownload={downloadXLSX}
                  downloadLoading={isDownload}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </Layout>
    );
  })
);

export default RFPOffersPage;

import React from "react";
//local
import {IconEqualizer} from "components/icons";
import {inject, observer} from "mobx-react";

export const ProfileSettings = inject("store")(
  observer(({store: {auth}}) => {
    const {user} = auth;
    return (
      <div>
        <div className="profile-settings">
          <div>
            <span>{user?.ActiveOrganization?.name}</span>
            {user?.email}
          </div>

          <button>
            <IconEqualizer />
          </button>
        </div>
      </div>
    );
  })
);

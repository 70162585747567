import React from "react";
import {useTranslation} from "react-i18next";
//local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {CustomTooltip} from "components/CustomTooltip";
import {IconBubbleText} from "components/icons";
import {hasPermission} from "helpers/helper";
import {LockValue} from "components/LockValue";
import {qualificationStatus} from "constants/user.consts";

const QualificationsTableItem = ({
  qualification,
  showQualificationAnswers,
  loadingId,
}) => {
  const {t} = useTranslation();

  const nameOrganization = (text) =>
    text.replace(",", "").split(" ").slice(0, 1).join(" ");

  const permissions = ["READ_QUALIFICATIONS"];
  const canClick = hasPermission(permissions);

  return (
    <div className="df-row-jsb-center">
      <div className="organization-card_body__name __wrapper">
        {qualification?.Buyer?.logotype ? (
          <div className={`organization-card_body__name __logo`}>
            <img
              src={qualification?.Buyer.logotype?.Location}
              alt={nameOrganization(qualification?.Buyer.name)}
            />
          </div>
        ) : (
          <span className="empty"></span>
        )}
        <div className="organization-card_body__name __title">
          {qualification?.Buyer?.name}
        </div>
      </div>

      <div className="df-row-center">
        {qualification.status === qualificationStatus.WAITING && (
          <LockValue
            canPermissions={permissions}
            needLabel
            needShowModalToSubscribe
            addClassIcon="mr-3 ml-0"
          >
            <ButtonTransparent
              name="QUALIFICATION_ANSWERS_WAITING_STATUS_BUTTON"
              small
              nonTransparent
              color="green"
              loading={loadingId === qualification.id}
              disabled={!!loadingId && loadingId !== qualification.id}
              onClick={() => canClick && showQualificationAnswers(qualification?.id)}
            />
          </LockValue>
        )}
        {qualification.status === qualificationStatus.SENT && (
          <React.Fragment>
            <LockValue
              canPermissions={permissions}
              needLabel
              needShowModalToSubscribe
              addClassIcon="mr-3 ml-0"
            >
              <ButtonTransparent
                name="QUALIFICATION_ANSWERS_SENT_STATUS_BUTTON"
                small
                noBorder
                loading={loadingId === qualification.id}
                disabled={!!loadingId && loadingId !== qualification.id}
                onClick={() => canClick && showQualificationAnswers(qualification?.id)}
              />
            </LockValue>

            <span className="fz-12">
              {t("QUALIFICATION_ANSWERS_SENT_STATUS_MESSAGE")}
            </span>
          </React.Fragment>
        )}
        {qualification.status === qualificationStatus.CONFIRMED && (
          <span className="fz-12">
            {t("QUALIFICATION_ANSWERS_CONFIRMED_STATUS_MESSAGE")}
          </span>
        )}
        {qualification.status === qualificationStatus.REMOVED && (
          <React.Fragment>
            <span className="fz-12 pr-2">
              {t("QUALIFICATION_ANSWERS_REMOVED_STATUS_MESSAGE")}
            </span>
            <CustomTooltip
              text={qualification.rejectMessage}
              centerText
              noTranslate
              placement="topRight"
            >
              <div className="pa-0 df">
                <IconBubbleText />
              </div>
            </CustomTooltip>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default QualificationsTableItem;

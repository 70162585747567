import React, {memo} from "react";

export const IconMapMarker = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      className={addClass}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.001 20C9.84795 20 9.70295 19.93 9.60795 19.809C9.55095 19.736 8.18995 17.995 6.81095 15.424C5.99895 13.911 5.35095 12.425 4.88595 11.008C4.29895 9.221 4.00195 7.536 4.00195 6C4.00195 2.692 6.69395 0 10.002 0C13.31 0 16.002 2.692 16.002 6C16.002 7.536 15.704 9.22 15.118 11.008C14.653 12.425 14.005 13.911 13.193 15.424C11.813 17.995 10.453 19.736 10.396 19.809C10.301 19.93 10.156 20 10.003 20H10.001ZM10.001 1C7.24395 1 5.00095 3.243 5.00095 6C5.00095 9.254 6.46395 12.664 7.69195 14.951C8.59395 16.632 9.50095 17.965 10.001 18.661C10.503 17.962 11.416 16.621 12.319 14.935C13.542 12.652 15.001 9.248 15.001 6C15.001 3.243 12.758 1 10.001 1Z"
        fill={color || "#000"}
      />
      <path
        d="M10 9C8.346 9 7 7.654 7 6C7 4.346 8.346 3 10 3C11.654 3 13 4.346 13 6C13 7.654 11.654 9 10 9ZM10 4C8.897 4 8 4.897 8 6C8 7.103 8.897 8 10 8C11.103 8 12 7.103 12 6C12 4.897 11.103 4 10 4Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconQuestionConnection = memo(({color, height, width, className}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 13.5C9.724 13.5 9.5 13.276 9.5 13V11C9.5 10.724 9.724 10.5 10 10.5C10.827 10.5 11.5 9.827 11.5 9C11.5 8.173 10.827 7.5 10 7.5C9.173 7.5 8.5 8.173 8.5 9C8.5 9.276 8.276 9.5 8 9.5C7.724 9.5 7.5 9.276 7.5 9C7.5 7.622 8.622 6.5 10 6.5C11.378 6.5 12.5 7.622 12.5 9C12.5 10.207 11.64 11.217 10.5 11.45V13C10.5 13.276 10.276 13.5 10 13.5Z"
        fill={color || "#000"}
      />
      <path
        d="M10 15.5C9.868 15.5 9.739 15.447 9.647 15.353C9.555 15.259 9.5 15.131 9.5 15C9.5 14.868 9.553 14.739 9.647 14.647C9.741 14.555 9.869 14.5 10 14.5C10.131 14.5 10.261 14.553 10.353 14.647C10.446 14.74 10.5 14.869 10.5 15C10.5 15.131 10.447 15.26 10.353 15.353C10.26 15.446 10.131 15.5 10 15.5Z"
        fill={color || "#000"}
      />
      <path
        d="M10 17.5C9.868 17.5 9.739 17.447 9.647 17.353C9.555 17.259 9.5 17.131 9.5 17C9.5 16.868 9.553 16.739 9.647 16.647C9.741 16.555 9.869 16.5 10 16.5C10.131 16.5 10.261 16.553 10.353 16.647C10.446 16.74 10.5 16.869 10.5 17C10.5 17.131 10.447 17.26 10.353 17.353C10.26 17.446 10.131 17.5 10 17.5Z"
        fill={color || "#000"}
      />
      <path
        d="M10 5.5C9.868 5.5 9.739 5.447 9.647 5.353C9.555 5.259 9.5 5.131 9.5 5C9.5 4.868 9.553 4.739 9.647 4.647C9.741 4.555 9.869 4.5 10 4.5C10.131 4.5 10.261 4.553 10.353 4.647C10.446 4.74 10.5 4.869 10.5 5C10.5 5.131 10.447 5.26 10.353 5.353C10.26 5.446 10.131 5.5 10 5.5Z"
        fill={color || "#000"}
      />
      <path
        d="M10 3.5C9.868 3.5 9.739 3.447 9.647 3.353C9.555 3.259 9.5 3.131 9.5 3C9.5 2.868 9.553 2.739 9.647 2.647C9.741 2.555 9.869 2.5 10 2.5C10.131 2.5 10.261 2.553 10.353 2.647C10.446 2.74 10.5 2.869 10.5 3C10.5 3.131 10.447 3.26 10.353 3.353C10.26 3.446 10.131 3.5 10 3.5Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconQuestionConnection;

import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FieldArray, Form, Formik} from "formik";
import {inject, observer} from "mobx-react";
// locale
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconPlus} from "components/icons/index";
import {showSuccess} from "helpers/notifications.helper";
import CollectiveUnionAgreementsForm from "./CollectiveUnionAgreementsForm";
import {schema, initialValues} from "./formAttrs";

const CollectiveUnionAgreementsEdit = inject("store")(
  observer(({store: {organization}, closeEdit}) => {
    const {t} = useTranslation();
    const {organizationDetail} = organization;
    const [loading, setLoading] = useState(false);
    const organizationName = t("LOCAL_AGREEMENT_ORGANIZATION_NAME_DEFAULT");
    const unionAgreements = (organizationDetail?.UnionAgreements?.length &&
      organizationDetail?.UnionAgreements) || [{...initialValues, organizationName}];

    const unionAgreementsForms = ({unionAgreements, remove, setFieldValue}) =>
      unionAgreements.map((unionAgreement, i) => (
        <CollectiveUnionAgreementsForm
          key={i}
          index={i}
          remove={remove}
          unionAgreement={unionAgreement}
          setFieldValue={setFieldValue}
        />
      ));

    const onSubmit = (values) => {
      setLoading(true);
      organization
        .unionAgreementEdit(values)
        .then(() => {
          showSuccess("Organization Union Agreements successfully updated.");
          organization
            .getOrganizationDetail(organizationDetail?.id)
            .then(() => closeEdit());
        })
        .finally(() => setLoading(false));
    };

    return (
      <Formik
        initialValues={{unionAgreements}}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({values, setFieldValue}) => (
          <Form>
            <FieldArray
              name="unionAgreements"
              render={(arrayHelpers) => (
                <div className="edit-insurance_wrapper editBlock_wrapper">
                  <div className="editBlock_head">
                    <div className="editBlock_head__title">
                      {t("COLLECTIVE_UNION_AGREEMENTS")}
                    </div>
                    <div className="editBlock_head__actions">
                      <ButtonTransparent
                        name={t("SAVE_CHANGES")}
                        type="submit"
                        loading={loading}
                        addClass="editBlock_head__btn"
                      />
                      <ButtonTransparent
                        name={t("CANCEL")}
                        addClass="editBlock_head__btn"
                        onClick={closeEdit}
                      />
                    </div>
                  </div>
                  <div className="editBlock_body">
                    {unionAgreementsForms({
                      unionAgreements: values.unionAgreements,
                      remove: arrayHelpers.remove,
                      setFieldValue: setFieldValue,
                    })}

                    <div className="btn-custom_wrap">
                      <ButtonTransparent
                        name={t("ONE_MORE")}
                        type="button"
                        icon={<IconPlus />}
                        className="editBlock_head__btn"
                        onClick={() => {
                          arrayHelpers.push({...initialValues, organizationName});
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            />
          </Form>
        )}
      </Formik>
    );
  })
);

export default CollectiveUnionAgreementsEdit;

import React from "react";

export const IconLangEnglish = () => {
  return (
    <svg
      width="30"
      height="20"
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7376 0.283283H11.4334V6.24995L1.01673 1.04162C0.532759 1.50914 0.236383 2.13746 0.183398 2.80828L8.1834 6.80828H5.26673L0.170898 4.26245V4.99995L3.8084 6.81662H0.170898V8.12078H12.7501L12.7376 0.283283Z"
        fill="white"
      />
      <path
        d="M28.4292 19.1208C28.9271 18.6664 29.2414 18.0453 29.3126 17.375L21.2209 13.3333H24.1376L29.3292 15.9292V15.2L25.5792 13.3333H29.3292V12.0292H16.6667V19.8583H17.9709V13.8917L28.4292 19.1208Z"
        fill="white"
      />
      <path
        d="M17.9584 0.283283H16.6667V8.11245H29.3417V6.80828H25.5917L29.3417 4.94162V3.03745C29.3417 2.94162 29.3417 2.84995 29.3417 2.75828L21.2459 6.80412H18.3292L28.9001 1.51662C28.782 1.32985 28.6422 1.15781 28.4834 1.00412L17.9584 6.24995V0.283283Z"
        fill="white"
      />
      <path
        d="M0.162598 12.025V13.3333H3.8001L0.162598 15.15V17.0833C0.162598 17.1667 0.162598 17.2458 0.162598 17.3292L8.1626 13.3292H11.0793L0.587598 18.575C0.705316 18.7633 0.845205 18.9367 1.00426 19.0917L11.4209 13.8875V19.8542H12.7251V12.025H0.162598Z"
        fill="white"
      />
      <path
        d="M29.3293 12.025V8.11245H16.6543V0.283283H12.7376V8.11245H0.162598V12.025H12.7376V19.8541H16.6543V12.025H29.3293Z"
        fill="#EC5565"
      />
      <path
        d="M8.1709 6.80825L0.170898 2.80825C0.170898 2.88325 0.170898 2.96242 0.170898 3.03742V4.25825L5.26673 6.80408L8.1709 6.80825Z"
        fill="#EC5565"
      />
      <path
        d="M21.2209 6.80831L29.3167 2.76248C29.2736 2.31603 29.122 1.88689 28.875 1.51248L18.3042 6.79998L21.2209 6.80831Z"
        fill="#EC5565"
      />
      <path
        d="M21.2209 13.3332L29.3126 17.3791C29.3177 17.2819 29.3177 17.1846 29.3126 17.0874V15.9291L24.1376 13.3332H21.2209Z"
        fill="#EC5565"
      />
      <path
        d="M8.1709 13.3332L0.170898 17.3332C0.206441 17.7775 0.349434 18.2065 0.587565 18.5832L11.0917 13.3332H8.1709Z"
        fill="#EC5565"
      />
      <path
        d="M17.9585 6.24995L28.4418 1.00828C28.0019 0.598457 27.44 0.343834 26.8418 0.283283H17.9585V6.24995Z"
        fill="#4658A9"
      />
      <path d="M29.3293 15.1957V13.3291H25.5959L29.3293 15.1957Z" fill="#4658A9" />
      <path d="M29.3293 6.80832V4.94165L25.5959 6.80832H29.3293Z" fill="#4658A9" />
      <path
        d="M1.02515 19.0917C1.53802 19.5815 2.22009 19.8547 2.92931 19.8542H11.4335V13.8875L1.02515 19.0917Z"
        fill="#4658A9"
      />
      <path
        d="M17.9585 13.8875V19.8542H26.5627C27.2544 19.8542 27.9208 19.5938 28.4293 19.125L17.9585 13.8875Z"
        fill="#4658A9"
      />
      <path
        d="M11.4335 0.283283H2.65018C2.03614 0.345901 1.46094 0.612961 1.01685 1.04162L11.4335 6.24995V0.283283Z"
        fill="#4658A9"
      />
      <path d="M0.162598 13.3291V15.1457L3.79593 13.3291H0.162598Z" fill="#4658A9" />
      <path d="M0.162598 4.98743V6.80826H3.79593L0.162598 4.98743Z" fill="#4658A9" />
    </svg>
  );
};

import React from "react";
import {Drawer} from "antd";

const DrawerComponent = ({children, ifDrawer, closeDrawer, addClass}) => {
  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={closeDrawer}
      open={ifDrawer}
      className={`drawer-component-content ${addClass ?? ""}`}
    >
      {children}
    </Drawer>
  );
};

export default DrawerComponent;

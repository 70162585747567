import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
//local
import {dateFormat} from "helpers/datetime";
import {Tag} from "components/Tag";
import {showSuccess} from "helpers/notifications.helper";
import ButtonDelete from "components/buttons/ButtonDelete";
import {ConfirmModal} from "components/ConfirmModal";

const InviteUserList = ({organizationStore, projectsList}) => {
  const {t} = useTranslation();

  const deleteInviteToUser = (invitationId) => {
    organizationStore
      .deleteInviteUsers(invitationId)
      .then(() => showSuccess(t("DELETING_INVITE_SUCCESSFULLY_COMPLETED")));
  };

  const limitProjectNames = useCallback(
    (limitProjectIds) =>
      projectsList
        .filter((project) => limitProjectIds.includes(project.value))
        .map((project) => project.label),
    [projectsList]
  );

  const confirmDelete = (id) => {
    ConfirmModal({
      title: t("WANT_DELETE_INVITATION"),
      text: t("CANT_RETURN"),
      type: "warning",
      onOk: () => deleteInviteToUser(id),
    });
  };

  return organizationStore?.inviteUsers?.map((item) => {
    const projectNames = limitProjectNames(item.limitProjectIds);
    return (
      <div key={item.id} className="invite-user__table __item">
        <div className="invite-user__table __value __to">{item.email}</div>
        <Tag
          text={item.SubscribeRoleId}
          color="discreteText"
          needTranslation
          uppercase
          bold
          small
          noBackground
          addClass="ml-2 mr-0"
        />
        {projectNames.slice(0, 2).map((name, i) => (
          <Tag
            key={`${name}_${i}`}
            text={name}
            color="purple"
            uppercase
            bold
            small
            noBackground
            addClass={i ? "" : "ml-4"}
          />
        ))}
        {projectNames.length > 2 && (
          <span className="fz-12">{`+${projectNames.length - 2}`}</span>
        )}
        <div className="invite-user__table __value __sent">
          <span>{t("INVITE_SENT_ON")}</span>
          {dateFormat(item.updatedAt)}
        </div>
        <div className="invite-user__table __actions">
          <ButtonDelete onClick={() => confirmDelete(item.id)} />
        </div>
      </div>
    );
  });
};

export default InviteUserList;

import React from "react";
import {useTranslation} from "react-i18next";
// local
import ButtonLink from "./buttons/ButtonLink";
import {color as constColor} from "constants/color.consts";

export const InfoStrip = ({text, color = constColor.green, onClick}) => {
  const {t} = useTranslation();

  return (
    <div className="info-strip__wrapper" style={{backgroundColor: color}}>
      <span>{text}</span>
      {onClick && <ButtonLink text={t("VERIFY_EMAIL_LINK")} onClick={onClick} />}
    </div>
  );
};

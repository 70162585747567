import React from "react";
import {useTranslation} from "react-i18next";
// local
import {
  IconStandPhone,
  IconMailLetter,
  IconUserPerson,
  IconDelete,
  IconMapMarker,
  IconTags,
  IconLicense,
} from "components/icons";
import {FieldInput} from "components/Field";

const ContactFields = ({index, isShowDeleteButton, remove}) => {
  const {t} = useTranslation();

  return (
    <div className="services-offered_item">
      <div className="services-offered_item__inner">
        <div className="services-field_wrap">
          <div className="field_wrapper">
            <IconUserPerson />
            <FieldInput
              name={`contacts.${index}.name`}
              small
              disabled={true}
              placeholder={t("FULL_NAME")}
              errorName={`contacts.${index}.name`}
            />
          </div>
          <div className="field_wrapper">
            <IconMailLetter />
            <FieldInput
              name={`contacts.${index}.email`}
              placeholder={t("ADD_EMAIL")}
              small
              errorName={`contacts.${index}.email`}
            />
          </div>
          <div className="field_wrapper">
            <IconStandPhone />
            <FieldInput
              name={`contacts.${index}.phone`}
              placeholder={t("ADD_PHONE")}
              small
              errorName={`contacts.${index}.phone`}
            />
          </div>
          <div className="field_wrapper">
            <IconLicense />
            <FieldInput
              name={`contacts.${index}.position`}
              placeholder={t("ADD_POSITION")}
              small
              errorName={`contacts.${index}.position`}
            />
          </div>
          <div className="field_wrapper">
            <IconTags />
            <FieldInput
              name={`contacts.${index}.workKinds`}
              placeholder={t("ADD_WORK_KINDS")}
              small
              errorName={`contacts.${index}.workKinds`}
            />
          </div>
          <div className="field_wrapper">
            <IconMapMarker />
            <FieldInput
              name={`contacts.${index}.location`}
              placeholder={t("ADD_LOCATION")}
              small
              errorName={`contacts.${index}.location`}
            />
          </div>
        </div>
        {isShowDeleteButton && (
          <div className="services-action_wrap">
            <button
              type="button"
              className="btn-custom__delete"
              onClick={() => remove(index)}
            >
              <IconDelete />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactFields;

import React, {useState, useMemo} from "react";
import {Select} from "antd";
import {useTranslation} from "react-i18next";
//local
import PresentationCardWrapper from "../../PresentationCardWrapper";
import {IconPouch} from "components/icons";
import AnnualReportItem from "./AnnualReportItem";
import {LockValue} from "components/LockValue";

const {Option} = Select;

const AnnualReport = ({companyData, hasFirstUpdateData}) => {
  const {t} = useTranslation();
  const accountNotes = useMemo(
    () => companyData?.Company?.companyCreditReport?.accountNotes || [],
    [companyData]
  );
  const accountKeyValues = useMemo(
    () => companyData?.Company?.companyCreditReport?.accountKeyValues || [],
    [companyData]
  );

  const accountNotesKeyValues = accountNotes.map((item, i) => ({
    ...item,
    ...accountKeyValues[i],
  }));
  const [annualReportInfo, setAnnualReportInfo] = useState(
    accountNotesKeyValues.slice(0, 1)
  );

  const options = accountNotes.slice(0, 1).map((item) => (
    <Option key={item.dateFrom} value={item.dateFrom}>
      {item.dateFrom} {t("PERIOD_UNTIL")} {item.dateTo}
    </Option>
  ));

  const onChangePeriod = (value) =>
    setAnnualReportInfo(
      accountNotesKeyValues?.filter((x) => value === x.dateFrom || value === "all")
    );

  return (
    <PresentationCardWrapper
      icon={<IconPouch />}
      title={t("ANNUAL_REPORT")}
      select
      selectOptions={options}
      defaultValue={options[0]?.props.children}
      selectOnChange={onChangePeriod}
      addClass="annual-report"
    >
      <LockValue
        canPermissions={["READ_OTHER_ANNUAL_REPORT"]}
        noHideOurInfo
        fullSubcribeButton
      >
        {accountNotes.length ? (
          annualReportInfo?.map((item, i) => (
            <div
              key={`${i}-${item.dateFrom}`}
              className="annual-report_wrapper finance-table_wrapper"
            >
              <AnnualReportItem
                annualItemInfo={item}
                basicData={companyData?.Company?.basic}
                hasFirstUpdateData={hasFirstUpdateData}
              />
            </div>
          ))
        ) : (
          <AnnualReportItem />
        )}
      </LockValue>
    </PresentationCardWrapper>
  );
};

export default AnnualReport;

import React, {memo} from "react";
import {Select as AntSelect} from "antd";
import {useTranslation} from "react-i18next";
import cn from "classnames";
//local
import {InitialsBadge} from "components/InitialsBadge";
import {IconCloseRound} from "components/icons/index";
import {color as constColor} from "constants/color.consts";

const {Option} = AntSelect;

const Select = memo(
  ({
    placeholder, // placeholder
    title,
    list,
    ownValue,
    initials, // show initials icon
    shadow, // inner select shadow
    round,
    small, // heigth 32px, font-size 12px
    tinyTitle, // font-size 10px
    boldTitle,
    blackTitle,
    large, // heigth 36px
    huge, // heigth 40px
    smallFont, // font-size 12px
    tinyFont, // font-size 10px
    bold,
    uppercase,
    minWidth, // minimize left and right paddings
    color, // select color: green, purple, black, red
    showSearch,
    onChange,
    addClass,
    addSelectClass,
    addDropdownClass,
    alignRightDropdownRows,
    noMaxHeightDropdown,
    showArrow,
    disabled,
    fullWidth,
    value,
    fillSelectedItem, // fill selected item in Select
    transparentSelectedItem,
    children, // custom Option data
    customTopRow, // custom top dropdown area
    customBottomRow, // custom bottom dropdown area
    notFoundContent,
    prefixIcon,
    highlightIfEmpty,
    ...props
  }) => {
    const {t} = useTranslation();

    const options =
      list?.length &&
      list.map(({value, label, icon}) => (
        <Option key={value} value={value}>
          {icon}
          {initials && (
            <InitialsBadge
              firstName={label}
              isCurrentUser={value === ownValue}
              addClass="mr-3 ml-1"
            />
          )}
          {t(label)}
        </Option>
      ));

    return (
      <div className={addClass}>
        {title && (
          <p
            className={cn("selection__title", {
              __small: small,
              __large: large,
              __huge: huge,
              __tiny: tinyTitle,
              __bold: boldTitle,
              __black: blackTitle,
            })}
          >
            {t(title)}
          </p>
        )}
        <div className="select">
          <AntSelect
            {...props}
            onChange={onChange}
            showAction={"focus"}
            placeholder={
              <React.Fragment>
                {prefixIcon}
                {t(placeholder)}
              </React.Fragment>
            }
            showSearch={!!showSearch}
            showArrow={!!showArrow}
            disabled={disabled}
            name="some Name"
            value={value}
            clearIcon={
              <IconCloseRound width={20} height={21} color={constColor[color]} />
            }
            notFoundContent={t(notFoundContent)}
            popupClassName={cn("editBlock_select_dropdown", {
              [addDropdownClass]: addDropdownClass,
              initials: !!initials,
              "align-rigth-row": alignRightDropdownRows,
              "no-max-height": noMaxHeightDropdown,
            })}
            dropdownRender={(menu) => (
              <React.Fragment>
                {customTopRow}
                {menu}
                {customBottomRow}
              </React.Fragment>
            )}
            className={cn({
              __small: small,
              __large: large,
              __huge: huge,
              __round: round,
              "__small-font": smallFont,
              "__tiny-font": tinyFont,
              __bold: bold,
              __uppercase: uppercase,
              [`__${color}`]: !!color,
              [addSelectClass]: !!addSelectClass,
              __shadow: shadow,
              "__show-arrow": showArrow,
              "__full-width": fullWidth,
              "__min-width": minWidth,
              "__filled-selected-item": fillSelectedItem,
              "__transparent-selected-item": transparentSelectedItem,
              "__prefix-icon": prefixIcon,
              "bg-yellow": !!highlightIfEmpty,
            })}
          >
            {children ?? options}
          </AntSelect>
        </div>
      </div>
    );
  }
);

export default Select;

import React, {useState} from "react";
import {Form} from "formik";
import {useTranslation} from "react-i18next";
import {Switch} from "antd";
//local
import {FieldInput} from "../../../components/Field";
import {Button} from "components/buttons/Button";
import {TERMS_AND_CONDITIONS} from "../../../constants/documents.consts";
// import EditSelect from "components/selects/EditSelect";

export const SettingsForm = ({values, setFieldValue}) => {
  const [ifChecked, setIfChecked] = useState(false);
  const {t} = useTranslation();

  const soldCheckbox = (value) => {
    setIfChecked(value);
  };

  return (
    <Form className="settings-form">
      <FieldInput
        type="text"
        name="firstName"
        placeholder={"LOGIN_PLACEHOLDER_FIRST_NAME"}
        label={"LOGIN_FIRST_NAME"}
        errorName="firstName"
      />
      <FieldInput
        name="lastName"
        placeholder={"LOGIN_PLACEHOLDER_LAST_NAME"}
        label={"LOGIN_LAST_NAME"}
        errorName="lastName"
      />
      <FieldInput
        type="email"
        name="email"
        placeholder={"LOGIN_PLACEHOLDER_EMAIL"}
        label={"E_MAIL"}
        errorName="email"
      />
      <FieldInput
        name="companyNumber"
        mask="999999-9999"
        maskChar="x"
        placeholder="xxxxxx-xxxx"
        label={"LOGIN_ORG_NUMBER"}
        errorName="companyNumber"
        onChange={(e) => {
          const value = e.target.value || "";
          setFieldValue("companyNumber", value);
        }}
      />

      <FieldInput
        name="companyName"
        placeholder={"LOGIN_PLACEHOLDER_COMPANY_NAME"}
        label={"LOGIN_COMPANY_NAME"}
        errorName="companyName"
      />

      {/*<EditSelect
        name="subscription"
        list={subscriptionArray}
        label={t("LOGIN_SUBSCRIPTION")}
      />*/}

      <div className="custom-control custom-switch">
        <Switch className="custom-switch-check" onChange={soldCheckbox} />
        <p>
          {t("LOGIN_AGREE_FIRST")}
          <a href={TERMS_AND_CONDITIONS} target="__blank">
            {t("LOGIN_AGREE_LINK")}
          </a>
          {t("LOGIN_AGREE_LAST")}
        </p>
      </div>

      <Button
        type="submit"
        name={"LOGIN_BUTTON"}
        addClass="settings-form__btn"
        disabled={!ifChecked}
      />
    </Form>
  );
};

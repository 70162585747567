import React from "react";
import {useTranslation} from "react-i18next";

const Employees = ({employees}) => {
  const {t} = useTranslation();

  return (
    <div className="edit-our-services_item">
      <p className="edit-our-services_title">{t("FIRST_SETUP_SELECT_EMPLOYEES")}</p>
      <div className="edit-our-services_selected__wrapper">
        {employees ? (
          <span className="edit-our-services_selected__item">{t(employees)}</span>
        ) : (
          <span className="edit-our-services_selected__item">
            {t("FIRST_SETUP_SELECT_TYPES")}
          </span>
        )}
      </div>
    </div>
  );
};

export default Employees;

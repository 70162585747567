import React, {memo} from "react";

export const IconCake = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.01 8.90099C17.377 7.63399 16.563 6.60699 15.778 6.08399C15.633 5.98699 15.447 5.97299 15.289 6.04699L14 6.64799V4.99999C14 4.81099 13.893 4.63699 13.724 4.55299L12.724 4.05299C12.583 3.98299 12.418 3.98299 12.277 4.05299L11.277 4.55299C11.108 4.63799 11.001 4.81099 11.001 4.99999V8.04799L0.3 13.042C0.25 13.064 0.204 13.094 0.165 13.13C0.146 13.147 0.129 13.165 0.113 13.185C0.0420001 13.272 0.00100009 13.383 0.00200009 13.503V19.5C0.00200009 19.637 0.0590001 19.769 0.158 19.863C0.251 19.951 0.374 20 0.502 20C0.511 20 0.52 20 0.53 19.999L18.53 18.999C18.795 18.984 19.002 18.765 19.002 18.5V12.5C19.002 11.472 18.641 10.16 18.012 8.90099H18.01ZM12 5.30899L12.5 5.05899L13 5.30899V9.90999C12.85 9.95799 12.668 9.99999 12.5 9.99999C12.332 9.99999 12.149 9.95799 12 9.90999V5.30899ZM11 9.15199V10.25C11 10.439 11.107 10.613 11.276 10.697C11.338 10.728 11.899 11 12.5 11C13.101 11 13.662 10.728 13.724 10.697C13.893 10.612 14 10.439 14 10.25V7.75199L15.442 7.07899C16.017 7.54899 16.628 8.37399 17.115 9.34899C17.574 10.267 17.879 11.233 17.971 12.029L3.06 12.857L11 9.15199ZM18 13.029V16.027L1 16.971V13.973L18 13.029ZM1 18.971V17.973L18 17.029V18.027L1 18.971Z"
        fill={color || "#000"}
      />
      <path
        d="M12.5 4C11.729 4 11 3.271 11 2.5C11 2.049 11.234 1.671 11.53 1.192C11.69 0.932 11.872 0.638 12.053 0.276C12.138 0.107 12.311 0 12.5 0C12.689 0 12.863 0.107 12.947 0.276C13.128 0.638 13.31 0.932 13.47 1.192C13.766 1.671 14 2.049 14 2.5C14 3.271 13.271 4 12.5 4ZM12.5 1.523C12.458 1.591 12.418 1.656 12.38 1.718C12.168 2.062 12 2.333 12 2.5C12 2.724 12.276 3 12.5 3C12.724 3 13 2.724 13 2.5C13 2.333 12.832 2.062 12.62 1.718C12.582 1.657 12.542 1.592 12.5 1.523Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {useEffect, useMemo, useState} from "react";
import {inject, observer} from "mobx-react";
import {useNavigate, useLocation} from "react-router-dom";

//local
import {
  JOINING_ORG,
  ONBOARDING,
  PROJECTS,
  PUBLIC_RFTS,
  MAIN_SETTINGS,
  INVITE_COMPANIES,
  FEEDS,
  FEED,
  RFP_OFFERS,
  ID_IDENTIFY,
  SAVE_SEARCH,
  ORGANIZATIONS,
} from "constants/routes.const";
import {hasPermission} from "helpers/helper";
import {Preloader} from "components/Preloader";
import MainPage from "pages/MainPage";
import FeedPage from "pages/Public/Feed/FeedPage";
import RFPOffersPage from "pages/Public/RFPOffers/RFPOffersPage";
import Modals from "components/modals";
import {pathPermissions} from "constants/user.consts";
import {contractModalNames} from "constants/modal.conts";

export const PrivateRoute = inject("store")(
  observer(({store: {auth, organization, publicStore}, component: Component, path}) => {
    const {
      joinRequest,
      organizationId,
      token,
      loading,
      modalName,
      activeOrganizationId,
      hasActiveOrganization,
      organizationSubscription,
    } = auth;

    const navigate = useNavigate();
    const location = useLocation();

    // hash for substitution, if necessary, a direct link to any settings tab
    const [currentHash] = useState(location.hash);

    const {isBankId, isOrgSettings} = organization;
    const {commonSettings} = publicStore;
    const [statusGetting, setStatusGetting] = useState(false);

    // is need read the statuses of mandatory one-time redirects
    const isNeedStatus = useMemo(
      () =>
        path !== FEEDS &&
        path !== FEED &&
        path !== RFP_OFFERS &&
        path !== ID_IDENTIFY &&
        path !== SAVE_SEARCH &&
        activeOrganizationId &&
        (!isBankId || !isOrgSettings) &&
        !joinRequest?.length,
      [activeOrganizationId, isBankId, isOrgSettings, joinRequest, path]
    );

    const hasNoPermission = useMemo(
      () => (pathPermissions[path] ? !hasPermission([pathPermissions[path]]) : false),
      [path]
    );

    const isNeedOnboarding =
      !organizationId && !joinRequest?.length && path !== ONBOARDING;
    const noNeedOnboarding = hasActiveOrganization && path === ONBOARDING;
    const hasTokenAfterJoining =
      !joinRequest?.length && organizationId && path === JOINING_ORG;
    const defaultBuyerPath = hasPermission([pathPermissions[PROJECTS]])
      ? PROJECTS
      : ORGANIZATIONS;
    const defaultSellerPath = hasPermission([pathPermissions[PUBLIC_RFTS]])
      ? PUBLIC_RFTS
      : ORGANIZATIONS;

    const handleNavigate = (path) => {
      navigate(path);
      return null;
    };

    const onCloseModal = () => {
      // if the modal window with BankID signing is closed
      auth.modalName === contractModalNames.identifyContractPerson &&
        auth.getBankIdCancelOnContract();
      auth.updateAuthModalName();
    };

    // getting common settings of the application
    useEffect(() => {
      !commonSettings && publicStore.getCommonSettings();
    }, [commonSettings, publicStore]);

    // we read the statuses of mandatory one-time redirects
    useEffect(() => {
      if (isNeedStatus && !statusGetting) {
        setStatusGetting(true);
        organization
          .getOrganizationOnboardingStatus(activeOrganizationId)
          .then((data) => {
            organization.setMandatoryStatuses(data);
            path !== ID_IDENTIFY && !data.bankId && navigate(ID_IDENTIFY);
            path !== SAVE_SEARCH &&
              isBankId &&
              !data.orgSettings &&
              navigate(SAVE_SEARCH);
            setStatusGetting(false);
            return null;
          });
      }
    }, [
      path,
      isNeedStatus,
      organization,
      activeOrganizationId,
      isBankId,
      statusGetting,
      navigate,
    ]);

    if (loading) return <Preloader />;

    if (modalName) return <Modals onClick={onCloseModal} />;

    if (token) {
      // determining the user's preferred business role when logging into the application
      const userSubscriptions = organizationSubscription?.SubscribeProducts?.map(
        (item) => item.name
      );
      const isBuyer =
        userSubscriptions?.includes("SUBSCRIPTION_NAME_04") ||
        userSubscriptions?.includes("SUBSCRIPTION_NAME_05");

      if (path === MAIN_SETTINGS) handleNavigate(`${INVITE_COMPANIES}${currentHash}`);
      else if (joinRequest?.length && path !== JOINING_ORG) handleNavigate(JOINING_ORG);
      else if (isNeedOnboarding) handleNavigate(ONBOARDING);
      else if (hasNoPermission || noNeedOnboarding || hasTokenAfterJoining)
        handleNavigate(isBuyer ? defaultBuyerPath : defaultSellerPath);
      else return <Component />;
    } else {
      if (path === FEEDS || path === FEED) return <FeedPage />;
      if (path === RFP_OFFERS) return <RFPOffersPage />;
      return <MainPage />;
    }
  })
);

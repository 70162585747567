import React, {memo} from "react";

export const IconSkatteverket = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "19"}
      viewBox="0 0 20 19"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9593 10.4908C14.2 11.1646 16.0915 12.9359 16.5744 15.3441C16.792 16.4767 16.6248 17.7099 16.1959 18.8267L14.2431 18.8211C14.701 18.0184 15.0569 17.1606 15.1474 16.2313C15.3039 14.5913 14.7029 12.6597 13.3147 11.5956C12.8787 11.2492 12.4181 10.9392 11.8906 10.7121L11.9593 10.4908Z"
        fill={color || "#000"}
      />
      <path
        d="M17.9772 18.8212C18.7143 16.7008 18.3978 14.2911 16.8016 12.5582C15.7587 11.3785 14.2751 10.6312 12.7676 10.2044L12.8134 9.96242C16.0946 10.7212 19.3785 12.7549 19.9222 16.3618C20.0478 17.1689 20.0288 18.023 19.8121 18.823L17.9772 18.8212Z"
        fill={color || "#000"}
      />
      <path
        d="M11.2166 10.9845C11.788 11.2889 12.2983 11.7074 12.7084 12.2511C13.9274 13.9691 13.7131 15.7793 13.3371 16.8835C12.9562 18.1468 12.2489 18.8212 12.2489 18.8212L9.75391 18.8291C11.4074 17.757 12.4084 16.061 12.5215 14.0805C12.54 13.0422 12.0568 11.7637 11.1238 11.1836L11.2166 10.9845Z"
        fill={color || "#000"}
      />
      <path
        d="M8.02809 11.4385C7.49417 9.1618 8.08593 6.63776 9.9325 5.01845C10.8054 4.26465 11.9574 3.79448 13.1394 3.6088L14.1088 5.30388C13.1841 5.30757 12.264 5.42713 11.4129 5.81231C9.91311 6.49498 8.5395 7.97907 8.30983 9.71332C8.22692 10.2638 8.1879 10.8177 8.25445 11.3881L8.02809 11.4385Z"
        fill={color || "#000"}
      />
      <path
        d="M12.2289 2.07682C10.0236 2.49619 8.10954 3.96311 7.40323 6.21113C6.90184 7.70388 6.99421 9.36292 7.37703 10.8816L7.14386 10.9629C6.16413 7.7412 6.28936 3.87959 9.14357 1.60939C9.7806 1.09765 10.519 0.693935 11.3205 0.483328L12.2289 2.07682Z"
        fill={color || "#000"}
      />
      <path
        d="M8.82682 11.8374C8.80558 11.1902 8.91365 10.539 9.18064 9.91119C10.061 7.99812 11.7366 7.28131 12.8817 7.05646C14.1665 6.75533 15.1138 7.02633 15.1138 7.02633L16.355 9.19087C14.6017 8.29206 12.6317 8.27116 10.8591 9.16169C9.95104 9.66326 9.08363 10.7195 9.04632 11.8165L8.82682 11.8374Z"
        fill={color || "#000"}
      />
      <path
        d="M0.896955 15.4831C2.39287 17.1562 4.63757 18.0387 6.92774 17.4854C8.46519 17.1449 9.83847 16.211 10.9421 15.0994L11.1321 15.2573C8.87685 17.7579 5.50423 19.6418 2.08674 18.3676C1.32003 18.0853 0.593415 17.6609 0 17.0836L0.896955 15.4831Z"
        fill={color || "#000"}
      />
      <path
        d="M11.0135 13.3646C10.4705 13.7171 9.85659 13.9604 9.18135 14.0542C7.08851 14.2868 5.60976 13.2214 4.82626 12.3562C3.9064 11.4103 3.64941 10.4588 3.64941 10.4588L4.86433 8.28028C4.99865 10.246 5.99626 11.9442 7.67323 13.0034C8.57166 13.5229 9.92404 13.7206 10.8832 13.1872L11.0135 13.3646Z"
        fill={color || "#000"}
      />
      <path
        d="M11.0583 14.3511C9.36967 15.9698 6.89625 16.7449 4.56248 15.98C3.46914 15.6114 2.47796 14.8595 1.7168 13.9363L2.68304 12.239C3.15599 13.0338 3.72724 13.7649 4.49265 14.3012C5.84316 15.2443 7.82055 15.672 9.42912 14.9862C9.9455 14.7778 10.4425 14.5297 10.8992 14.1824L11.0583 14.3511Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {v4 as uuidv4} from "uuid";
import ReactDragListView from "react-drag-listview/lib/index.js";
//local
import {IconList, IconPlus} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import ButtonDelete from "components/buttons/ButtonDelete";
import {InfoTooltip} from "components/InfoTooltip";
import {
  emptySmartFormQuestion,
  emptySmartFormHeadingQuestion,
} from "constants/buyer.consts";
import {hasPermission} from "helpers/helper";
import {LockValue} from "components/LockValue";
import {TitleIcon} from "components/TitleIcon";
import SmartFormQuestionItemEdit from "./SmartFormQuestionItemEdit";
import SmartFormItemView from "./SmartFormItemView";
import {SmartTemplatesModal} from "components/modals/SmartTemplatesModal";
import {ConfirmModal} from "components/ConfirmModal";
import cn from "classnames";

const SmartFormQuestions = ({
  isNew,
  isEdit,
  titleHide,
  questions,
  isHaveAnswers,
  isAdminOwner,
  topBorder,
  permissions,
  addClass,
  onChange,
}) => {
  const {t} = useTranslation();

  const [isSmartTemplatesModalTab, setSmartTemplatesModalTab] = useState(null);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...questions];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      onChange(data);
    },
    nodeSelector: ".question-answer-item",
    handleSelector: ".drag-button",
  };

  const onChangeRowValues = (question) =>
    onChange(questions.map((item) => (item.id === question.id ? question : item)));

  const onRowDelete = (id) =>
    isHaveAnswers?.includes(id)
      ? ConfirmModal({
          title: t("SMART_FORM_QUESTIONS_WANT_DELETE_QUESTION"),
          text: t("SMART_FORM_QUESTIONS_CANT_RETURN"),
          type: "warning",
          onOk: () => onChange(questions.filter((item) => item.id !== id)),
        })
      : onChange(questions.filter((item) => item.id !== id));

  const onFormDelete = () =>
    isHaveAnswers.length
      ? ConfirmModal({
          title: t("SMART_FORM_QUESTIONS_WANT_DELETE_FORM"),
          text: t("SMART_FORM_QUESTIONS_CANT_RETURN"),
          type: "warning",
          onOk: () => onChange([]),
        })
      : onChange([]);

  return (
    <div
      className={cn("request-smart-form-block", {
        "__top-border": topBorder,
        [addClass]: addClass,
      })}
    >
      {!titleHide && (
        <div className="request-smart-form-block__heading">
          <TitleIcon
            title={t("SMART_FORM_QUESTIONS_TITLE")}
            addClass="ml-4 mt-4"
            icon={<IconList />}
            tooltip={!(isEdit || isNew) && "RFP_VIEW_SMART_FORM_TOOLTIP"}
          />
          {(isEdit || isNew) && (
            <React.Fragment>
              {!!questions?.length ? (
                <ButtonDelete activeOnHover onClick={onFormDelete} addClass="ml-4" />
              ) : (
                <React.Fragment>
                  <ButtonTransparent
                    name={t("SMART_FORM_QUESTIONS_CREATE_BUTTON")}
                    icon={<IconPlus />}
                    onClick={() =>
                      onChange([
                        {id: uuidv4(), ...emptySmartFormHeadingQuestion},
                        {id: uuidv4(), ...emptySmartFormQuestion},
                      ])
                    }
                    addClass="ml-4"
                    medium
                  />
                  <LockValue
                    canPermissions={["READ_ORGANIZATION_SMART_TEMPLATES_IN_RFT"]}
                    needLabel
                    smallLockIcon={false}
                    needShowModalToSubscribe
                  >
                    <ButtonTransparent
                      name={t("SMART_FORM_QUESTIONS_OUR_TEMPLATES_BUTTON")}
                      addClass="ml-4"
                      small
                      onClick={() =>
                        hasPermission(
                          ["READ_ORGANIZATION_SMART_TEMPLATES_IN_RFT"],
                          permissions
                        ) && setSmartTemplatesModalTab(1)
                      }
                    />
                  </LockValue>
                  <LockValue
                    canPermissions={["READ_MY_SMART_TEMPLATES_IN_RFT"]}
                    needLabel
                    smallLockIcon={false}
                    needShowModalToSubscribe
                  >
                    <ButtonTransparent
                      name={t("SMART_FORM_QUESTIONS_MY_TEMPLATES_BUTTON")}
                      addClass="ml-3"
                      small
                      onClick={() =>
                        hasPermission(["READ_MY_SMART_TEMPLATES_IN_RFT"], permissions) &&
                        setSmartTemplatesModalTab(0)
                      }
                    />
                  </LockValue>
                </React.Fragment>
              )}
              <InfoTooltip
                tooltip="CREATE_RFP_SMART_FORM_TOOLTIP"
                centerText
                addClass="ml-4 mr-4"
              />
            </React.Fragment>
          )}
        </div>
      )}
      {isEdit || isNew ? (
        <div className="smart-form-edit">
          <ReactDragListView {...dragProps}>
            {questions?.map((item) => (
              <SmartFormQuestionItemEdit
                key={item.id}
                question={item}
                onChange={onChangeRowValues}
                onDelete={onRowDelete}
              />
            ))}
          </ReactDragListView>

          {!!questions.length && (
            <div className="df mt-2 ml-2">
              <ButtonTransparent
                icon={<IconPlus />}
                name={t("SMART_FORM_QUESTIONS_ADD_ITEM_BUTTON")}
                small
                rounded
                onClick={() =>
                  onChange([...questions, {id: uuidv4(), ...emptySmartFormQuestion}])
                }
                addClass="pl-1 pr-2 mr-4"
              />
              <ButtonTransparent
                icon={<IconPlus />}
                name={t("SMART_FORM_QUESTIONS_ADD_HEAD_BUTTON")}
                rounded
                small
                onClick={() =>
                  onChange([
                    ...questions,
                    {id: uuidv4(), ...emptySmartFormHeadingQuestion},
                  ])
                }
                addClass="pl-1 pr-2"
              />
            </div>
          )}

          {isSmartTemplatesModalTab !== null && (
            <SmartTemplatesModal
              defaultTab={isSmartTemplatesModalTab}
              disabledTabs={[
                !hasPermission(["READ_MY_SMART_TEMPLATES_IN_RFT"], permissions),
                !hasPermission(["READ_ORGANIZATION_SMART_TEMPLATES_IN_RFT"], permissions),
              ]}
              isAdminOwner={isAdminOwner}
              onChangeTemplate={onChange}
              onClose={() => setSmartTemplatesModalTab(null)}
            />
          )}
        </div>
      ) : (
        <div className="smart-form-view">
          {questions?.map((item) => (
            <SmartFormItemView key={item.id} question={item} />
          ))}
        </div>
      )}
    </div>
  );
};

export default SmartFormQuestions;

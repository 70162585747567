import React, {memo} from "react";

export const IconNotificationCircle = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.718 3.282C14.924 1.488 12.538 0.5 10 0.5C7.462 0.5 5.077 1.488 3.282 3.282C1.487 5.076 0.5 7.462 0.5 9.999C0.5 12.536 1.488 14.922 3.282 16.717C5.076 18.512 7.462 19.499 10 19.499C12.538 19.499 14.923 18.511 16.718 16.717C18.513 14.923 19.5 12.537 19.5 9.999C19.5 7.461 18.512 5.076 16.718 3.282V3.282ZM10 18.5C5.313 18.5 1.5 14.687 1.5 10C1.5 5.313 5.313 1.5 10 1.5C14.687 1.5 18.5 5.313 18.5 10C18.5 14.687 14.687 18.5 10 18.5Z"
        fill={color || "#fff"}
      />
      <circle cx="10" cy="5.5" r="0.5" fill={color || "#fff"} />
      <line
        x1="10"
        y1="7.5"
        x2="10"
        y2="14.5"
        stroke={color || "#fff"}
        strokeLinecap="round"
      />
    </svg>
  );
});

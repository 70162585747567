import React, {memo} from "react";

export const IconLabel = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill={color || "none"}
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.25 17.2134H2.25C1.423 17.2134 0.75 16.5404 0.75 15.7134V5.71339C0.75 4.88639 1.423 4.21339 2.25 4.21339H13.25C13.954 4.21339 14.824 4.62139 15.274 5.16139L18.994 9.62539C19.503 10.2354 19.503 11.1914 18.994 11.8024L15.274 16.2664C14.824 16.8074 13.953 17.2144 13.25 17.2144V17.2134ZM2.25 5.21339C1.974 5.21339 1.75 5.43739 1.75 5.71339V15.7134C1.75 15.9894 1.974 16.2134 2.25 16.2134H13.25C13.651 16.2134 14.249 15.9334 14.506 15.6254L18.226 11.1614C18.425 10.9224 18.425 10.5034 18.226 10.2654L14.506 5.80139C14.249 5.49339 13.651 5.21339 13.25 5.21339H2.25Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {Suspense} from "react";
import {PostHogProvider} from "posthog-js/react";
import {configure} from "mobx";
import {Provider} from "mobx-react";
import {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import {BrowserRouter} from "react-router-dom";
//local
import "./assets/styles/app.scss";
import {App} from "./App";
import store from "./store/index";
import {Preloader} from "components/Preloader";
import "./index.css";
import "./locale";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

configure({
  enforceActions: "never",
});

root.render(
  <StrictMode>
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
      <Suspense fallback={<Preloader />}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </Suspense>
    </PostHogProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

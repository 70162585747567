import React from "react";
import CampaignCountdown from "./CampaignCountdown";
import {CustomTooltip} from "components/CustomTooltip";
import {IconNotificationCircle} from "components/icons";
import {t} from "i18next";

const CustomCampaign = ({
  endDate,
  tooltipTextKey,
  textKey,
  detailsKey,
  backgroundColor,
  textColor,
  iconColor,
}) => {
  const currentDate = new Date();
  const endDateTime = new Date(endDate);

  if (currentDate > endDateTime) {
    return null;
  }

  const campaignStyle = {
    backgroundColor: backgroundColor,
    color: textColor,
  };

  return (
    <div className="custom-campaign" style={campaignStyle}>
      {t(textKey)}
      <CampaignCountdown targetDate={endDate} addClass="ml-4" />
      <CustomTooltip text={t(tooltipTextKey)}>
        <span className="info ml-4 df-row-center">
          <IconNotificationCircle color={iconColor || textColor} />{" "}
          <span className="ml-1">{t(detailsKey)}</span>
        </span>
      </CustomTooltip>
    </div>
  );
};

export default CustomCampaign;

import React from "react";
import {Select} from "antd";
//local
import {ButtonTransparent} from "../buttons/ButtonTransparent";
import {ProjectLabel} from "components/ProjectLabel";
import {IconPlus, IconDownOn} from "components/icons";
import cn from "classnames";

const ProjectsSelect = ({
  list,
  onChange,
  defaultValue,
  onClickAdd,
  selectedValue,
  customIcon,
  noBorder,
  highlightIfNoSelectValue,
  highlightMyProjectsId,
  fullWidth,
  noSelectValue,
  large,
  addClass,
}) => {
  const {Option} = Select;

  const options = () =>
    list.length
      ? list
          .filter((item) => !item.isArchived && item.name)
          .map((project) => (
            <Option key={project.id} value={project.id}>
              <ProjectLabel
                name={project.name}
                code={project.reference}
                fullWidth={fullWidth}
                color={
                  highlightMyProjectsId &&
                  !!project.Members &&
                  !project.Members?.map((member) => member.id).includes(
                    highlightMyProjectsId
                  ) &&
                  "discreteText"
                }
                large={large}
                width="220"
              />
            </Option>
          ))
      : [];

  return (
    <div
      className={cn(
        "projects-select_wrapper",
        {"no-border": !!noBorder},
        {"__full-width": fullWidth},
        {__large: !!large},
        {[addClass]: !!addClass}
      )}
    >
      <Select
        className="projects-select_select"
        bordered={false}
        onChange={onChange}
        suffixIcon={customIcon ?? <IconDownOn />}
        defaultValue={defaultValue ?? null}
        value={selectedValue}
        placeholder={
          noSelectValue && (
            <ProjectLabel
              name={noSelectValue}
              fullWidth={fullWidth}
              highlight={highlightIfNoSelectValue}
              large={large}
              width="220"
            />
          )
        }
        popupClassName="projects-select_dropdown"
      >
        {options()}
      </Select>
      {onClickAdd && (
        <ButtonTransparent
          icon={<IconPlus />}
          noBorder
          addClass="ml-1 mr-0"
          onClick={onClickAdd}
        />
      )}
    </div>
  );
};

export default ProjectsSelect;

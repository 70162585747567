import React, {memo} from "react";
//local
import {InfoTooltip} from "components/InfoTooltip";
import cn from "classnames";

export const TitleIcon = memo(
  ({title, icon, prefixTooltipContent, suffixTooltipContent, tooltip, addClass}) => {
    return (
      <div className={cn("title-icon_wrapper", {[addClass]: !!addClass})}>
        {icon}
        <span>{title}</span>
        {prefixTooltipContent}
        {tooltip && <InfoTooltip tooltip={tooltip} centerText addClass="ml-2 mr-2" />}
        {suffixTooltipContent}
      </div>
    );
  }
);

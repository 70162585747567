import React, {memo} from "react";

export const IconBubblesMessage = memo(({color, addClass}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" className={addClass}>
      <g clipPath="url(#clip0_10427_4513)">
        <path
          d="M15.6008 14.4C15.6008 14.4 15.6008 14.4 15.6 14.4C14.5272 14.4 13.4128 13.7152 13.0024 13.4352C12.552 13.544 12.0816 13.6 11.6 13.6C10.4504 13.6 9.36401 13.2832 8.54241 12.7072C7.67681 12.1016 7.20001 11.2816 7.20001 10.4C7.20001 9.51841 7.67681 8.69841 8.54241 8.09281C9.36481 7.51761 10.4504 7.20001 11.6 7.20001C12.7496 7.20001 13.836 7.51681 14.6576 8.09281C15.5232 8.69841 16 9.51841 16 10.4C16 11.1712 15.6312 11.9016 14.9552 12.4776C15.0208 12.6624 15.2264 13.0592 15.8712 13.7056C15.9504 13.7784 16 13.8832 16 14C16 14.2208 15.8208 14.4 15.6 14.4H15.6008ZM13.0824 12.6016C13.1688 12.6016 13.2536 12.6296 13.324 12.6824C13.332 12.688 13.864 13.0856 14.5264 13.356C14.0912 12.72 14.1024 12.3576 14.1416 12.2056C14.1648 12.1184 14.216 12.0416 14.288 11.9872C14.876 11.5456 15.1992 10.9824 15.1992 10.4C15.1992 9.78641 14.844 9.20001 14.1984 8.74801C13.5096 8.26561 12.5864 8.00001 11.5992 8.00001C10.612 8.00001 9.68881 8.26561 9.00001 8.74801C8.35441 9.20001 7.99921 9.78641 7.99921 10.4C7.99921 11.0136 8.35441 11.6 9.00001 12.052C9.68881 12.5344 10.612 12.8 11.5992 12.8C12.0752 12.8 12.5376 12.7384 12.9736 12.616C13.0088 12.6064 13.0456 12.6016 13.0816 12.6016H13.0824Z"
          fill={color || "#000"}
        />
        <path
          d="M0.399988 15.2C0.219988 15.2 0.0623883 15.08 0.0143883 14.9064C-0.0336117 14.7328 0.0399883 14.5488 0.194388 14.4568C1.54719 13.6456 2.10799 12.4656 2.30719 11.9136C0.836788 10.788 -1.1678e-05 9.23041 -1.1678e-05 7.60001C-1.1678e-05 6.77681 0.206388 5.97921 0.614388 5.22881C1.00319 4.51361 1.55839 3.87281 2.26319 3.32401C3.69279 2.21201 5.58799 1.59921 7.59999 1.59921C9.42239 1.59921 11.1848 2.11441 12.5616 3.05041C13.9496 3.99361 14.8584 5.29921 15.1208 6.72641C15.1608 6.94401 15.0168 7.15201 14.8 7.19201C14.5832 7.23201 14.3744 7.08801 14.3344 6.87121C14.1112 5.65601 13.3216 4.53441 12.112 3.71201C10.8664 2.86561 9.26399 2.39921 7.59999 2.39921C3.85039 2.39921 0.799988 4.73201 0.799988 7.59921C0.799988 9.05121 1.60399 10.4488 3.00479 11.4328C3.14239 11.5296 3.20479 11.7016 3.16159 11.864C3.06799 12.2128 2.74959 13.1704 1.89759 14.0944C2.93919 13.7296 4.06079 13.0968 4.88879 12.5272C4.98719 12.4592 5.11039 12.4392 5.22559 12.472C5.98399 12.6888 6.78319 12.7992 7.59999 12.7992C7.82079 12.7992 7.99999 12.9784 7.99999 13.1992C7.99999 13.42 7.82079 13.5992 7.59999 13.5992C6.77359 13.5992 5.96239 13.496 5.18719 13.2912C4.84399 13.52 4.20239 13.9256 3.43599 14.3072C2.24559 14.8992 1.22479 15.1992 0.400788 15.1992L0.399988 15.2Z"
          fill={color || "#000"}
        />
      </g>
    </svg>
  );
});

import React, {memo} from "react";

export const IconMessage = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "18"}
      viewBox="0 0 20 18"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.29022 17.5C1.06522 17.5 0.868215 17.35 0.808215 17.133C0.748215 16.916 0.840215 16.686 1.03322 16.571C2.72422 15.557 3.42522 14.082 3.67422 13.392C1.83622 11.985 0.790215 10.038 0.790215 8.00002C0.790215 6.97102 1.04822 5.97402 1.55822 5.03602C2.04422 4.14202 2.73822 3.34102 3.61922 2.65502C5.40622 1.26502 7.77522 0.499023 10.2902 0.499023C12.8052 0.499023 15.1742 1.26502 16.9612 2.65502C17.8422 3.34002 18.5362 4.14102 19.0222 5.03602C19.5322 5.97302 19.7902 6.97002 19.7902 8.00002C19.7902 9.03002 19.5322 10.026 19.0222 10.964C18.5362 11.858 17.8422 12.659 16.9612 13.345C15.1742 14.735 12.8052 15.501 10.2902 15.501C9.25722 15.501 8.24322 15.372 7.27422 15.116C6.84522 15.402 6.04322 15.909 5.08522 16.386C3.59722 17.126 2.32122 17.501 1.29122 17.501L1.29022 17.5ZM10.2902 1.50002C5.60322 1.50002 1.79022 4.41602 1.79022 8.00002C1.79022 9.81502 2.79522 11.562 4.54622 12.792C4.71822 12.913 4.79622 13.128 4.74222 13.331C4.62522 13.767 4.22722 14.964 3.16222 16.119C4.46422 15.663 5.86622 14.872 6.90122 14.16C7.02422 14.075 7.17822 14.05 7.32222 14.091C8.27022 14.362 9.26922 14.5 10.2902 14.5C14.9772 14.5 18.7902 11.584 18.7902 8.00002C18.7902 4.41602 14.9772 1.50002 10.2902 1.50002Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconPlayRound = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 20C6.962 20 4.577 19.012 2.782 17.218C0.987 15.424 0 13.038 0 10.501C0 7.963 0.988 5.578 2.782 3.783C4.576 1.988 6.962 1 9.5 1C12.038 1 14.423 1.988 16.218 3.783C18.013 5.578 19 7.963 19 10.501C19 13.039 18.012 15.424 16.218 17.218C14.424 19.012 12.038 20 9.5 20Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M6.94444 15C6.87067 15 6.796 14.9828 6.72933 14.9485C6.588 14.8765 6.5 14.739 6.5 14.5909V6.40883C6.5 6.25992 6.588 6.12328 6.72933 6.05127C6.87067 5.97927 7.04311 5.98336 7.18 6.06191L14.2911 10.1529C14.4209 10.2274 14.5 10.3591 14.5 10.4999C14.5 10.6406 14.4209 10.7723 14.2911 10.8468L7.18 14.9378C7.108 14.9795 7.02622 15 6.94444 15Z"
        fill="white"
      />
    </svg>
  );
});

export const onboardingModalNames = {
  exists: "existsModal",
  joining: "joiningModal",
  orgName: "organizationName",
  identify: "identifyModal",
};

export const contractModalNames = {
  identifyContractPerson: "identifyContractPersonModal",
};

export const identifyModalNames = {
  identifyEmailAddress: "identifyEmailAddressModal",
};

import React, {useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
//local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {hasPermission, hasPermissions} from "helpers/helper";
import {subscriptionsData} from "constants/user.consts";
import {SubscriptionUpgrageModal} from "components/modals/SubscriptionUpgrageModal";
import {CustomBadge} from "./CustomBadge";
import {IconLockFilled, IconLock, IconPlusCircle} from "components/icons/index";
import {color} from "constants/color.consts";

export const LockValue = inject("store")(
  observer(
    ({
      store: {auth, organization},
      canPermissions,
      needAllPermissions,
      additionalHideCondition = false,
      justHide,
      revertLogic,
      additionalShowCondition,
      noHideOurInfo,
      fullSubcribeButton,
      fullSubscribeVerticalPosition,
      fullSubcribeButtonClassName,
      needMiniSibscribeButton,
      needLabel,
      largeDescription,
      needRightIcon = true,
      filledLock = true,
      smallLockIcon = true,
      customLockIcon,
      addClassIcon,
      addClassWrapper,
      addClassDescription,
      needShowModalToSubscribe,
      badgeCount,
      children,
    }) => {
      const {activeOrganizationId, subscribedPermissions} = auth;
      const navigate = useNavigate();
      const {t} = useTranslation();
      const [showSubscribeModal, setShowSubscribeModal] = useState(false);
      const {organizationDetail, loading} = organization;
      const myOrganization = organizationDetail?.id === activeOrganizationId;
      const missingCanPermissions = useMemo(
        () => canPermissions?.filter((perm) => !hasPermission([perm])),
        [canPermissions]
      );
      const permittedSubscription = useMemo(
        () =>
          !!canPermissions && subscribedPermissions[missingCanPermissions[0]]
            ? subscribedPermissions[missingCanPermissions[0]][0]
            : null,
        [canPermissions, subscribedPermissions, missingCanPermissions]
      );
      const resultShowLogic =
        (!loading && noHideOurInfo && myOrganization) ||
        additionalShowCondition ||
        !canPermissions?.length ||
        (needAllPermissions
          ? hasPermissions(canPermissions) && !additionalHideCondition
          : hasPermission(canPermissions) && !additionalHideCondition);

      const correctIcon = customLockIcon ? (
        customLockIcon
      ) : filledLock ? (
        <IconLockFilled
          addClass={`lock-icon${needLabel ? " with-label" : ""} ${addClassIcon ?? ""}`}
          color={color.purple}
          width={smallLockIcon && "16"}
          height={smallLockIcon && "16"}
        />
      ) : (
        <IconLock
          addClass={`lock-icon${needLabel ? " with-label" : ""} ${addClassIcon ?? ""}`}
          color={color.purple}
          width={smallLockIcon && "16"}
          height={smallLockIcon && "16"}
        />
      );

      return (
        <React.Fragment>
          {(revertLogic ? !resultShowLogic : resultShowLogic) ? (
            children
          ) : (
            <React.Fragment>
              {fullSubcribeButton ? (
                <div
                  className={`lock-value ${
                    fullSubscribeVerticalPosition ? "df-column-center" : "df-row-center"
                  } ${fullSubcribeButtonClassName ?? ""}`}
                >
                  <span
                    className={`purple fz-12 ${
                      fullSubscribeVerticalPosition ? "pb-2" : "pr-2"
                    } ${addClassDescription ?? ""}`}
                  >
                    {t(`${canPermissions?.[0]}_DESCRIPTION`)}
                  </span>
                  {!!permittedSubscription && (
                    <ButtonTransparent
                      name={permittedSubscription}
                      icon={
                        <React.Fragment>
                          <IconPlusCircle />
                          {subscriptionsData[permittedSubscription].icon}
                        </React.Fragment>
                      }
                      hoverableLink
                      uppercase
                      bold
                      style={{fontSize: "10px"}}
                      color="purple"
                      nonTransparent={!needMiniSibscribeButton}
                      noBorder={needMiniSibscribeButton}
                      onClick={() =>
                        needMiniSibscribeButton
                          ? navigate({hash: "#your-subscription"})
                          : navigate(
                              {hash: "#your-subscription"},
                              {state: {purchasedSubscription: permittedSubscription}}
                            )
                      }
                    />
                  )}
                </div>
              ) : (
                <React.Fragment>
                  {!justHide && (
                    <div
                      className={`lock-value df-row-center${
                        !!+badgeCount ? " __badge-count" : ""
                      } ${addClassWrapper ?? ""}`}
                      onClick={(e) => {
                        needShowModalToSubscribe && e.stopPropagation();
                        needShowModalToSubscribe && setShowSubscribeModal(true);
                      }}
                    >
                      {needLabel && children}
                      {needRightIcon && correctIcon}
                      <CustomBadge count={badgeCount} />
                    </div>
                  )}
                  {showSubscribeModal && (
                    <SubscriptionUpgrageModal
                      canPermissions={missingCanPermissions}
                      subscriptionName={t(permittedSubscription)}
                      subscriptionAction={t(`${missingCanPermissions[0]}_ACTION`)}
                      onClose={(e) => {
                        e.stopPropagation();
                        setShowSubscribeModal(false);
                      }}
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
  )
);

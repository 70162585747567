import React from "react";
import {useTranslation} from "react-i18next";
// local
import {IconStandPhone, IconMailLetter, IconUserPerson} from "components/icons";
import {CustomTooltip} from "components/CustomTooltip";
import {InitialsBadge} from "components/InitialsBadge";
import {getFullName} from "helpers/helper";
import {dateTimeFormat} from "helpers/datetime";
import {color} from "constants/color.consts";
import cn from "classnames";

const ContactPerson = ({
  person,
  hasInitials = true,
  needPhoneEmailTooltip,
  needPhoneRow,
  needEmailRow,
  actionDate,
  hasBackground,
  blackContent,
  addClass,
}) => {
  const {t} = useTranslation();
  return (
    <div
      className={cn("contact-person__wrapper", {
        "with-background": hasBackground,
        [addClass]: !!addClass,
      })}
    >
      <CustomTooltip
        text={
          needPhoneEmailTooltip
            ? `${t("PHONE")}: ${
                person.mobilePhone ? `+${person.mobilePhone}` : t("NONE")
              }\n${t("EMAIL")}: ${person.email}`
            : undefined
        }
      >
        <div className="df-row-center">
          {hasInitials ? (
            <InitialsBadge
              firstName={person?.firstName}
              lastName={person?.lastName}
              addClass="mr-2"
            />
          ) : (
            <IconUserPerson color={blackContent ? color.black : color.darkGray} />
          )}
          <span className="contact-person__name">
            {getFullName(person?.firstName, person?.lastName)}
          </span>
        </div>
      </CustomTooltip>
      {!!actionDate && (
        <div className={hasInitials ? "ml-6" : ""}>{dateTimeFormat(actionDate, "-")}</div>
      )}
      {needEmailRow && person.email && (
        <div className="">
          <IconMailLetter color={blackContent ? color.black : color.darkGray} />
          <span>{person.email}</span>
        </div>
      )}
      {needPhoneRow && (person.phone || person.mobilePhone) && (
        <div className="">
          <IconStandPhone color={blackContent ? color.black : color.darkGray} />
          <span>{person.phone || person.mobilePhone}</span>
        </div>
      )}
    </div>
  );
};

export default ContactPerson;

import React from "react";
import {Input} from "./Input";

export const InputsCode = ({code, setCode, disabled}) => {
  const references = {};
  let previousValue = null;

  const changeInput = (e) => {
    const target = e.target;
    const name = parseInt(target.name);
    const nextIndex = (name + 1).toString();
    const prevIndex = (name - 1).toString();
    const codeStr = code.join("");
    const backspace = "Backspace";
    if (e.key !== backspace && target.value.length) {
      if (name + 1 !== code.length) references[nextIndex].focus();
    } else if (codeStr && e.key === backspace && prevIndex !== "-1") {
      references[prevIndex].focus();
    }
  };

  const changePreviousValue = (e) => {
    previousValue = e.target.value;
  };

  const inputValue = (e) => {
    const target = e.target;
    let changeCode = [...code];

    if (target.value.length < 2) changeCode[target.name] = target.value;
    if (target.value.length === 2) {
      changeCode[target.name] = target.value.startsWith(previousValue)
        ? target.value.substring(1, 2)
        : target.value.substring(0, 1);
    }
    if (target.value.length === 4) {
      changeCode = target.value.split("");
      references[3].focus();
    }
    if (target.value.length === 5) {
      changeCode = target.value.substring(1, 5).split("");
      references[3].focus();
    }
    setCode(changeCode);
  };

  return (
    <div className="inputs-code">
      <Input
        value={code[0]}
        onChange={inputValue}
        onKeyUp={changeInput}
        onKeyDown={changePreviousValue}
        disabled={disabled}
        focus={(ref) => (references["0"] = ref)}
        name="0"
        type="number"
        autoFocus
      />
      <Input
        value={code[1]}
        onChange={inputValue}
        onKeyUp={changeInput}
        onKeyDown={changePreviousValue}
        disabled={disabled}
        focus={(ref) => (references["1"] = ref)}
        name="1"
        type="number"
      />
      <Input
        value={code[2]}
        onChange={inputValue}
        onKeyUp={changeInput}
        onKeyDown={changePreviousValue}
        disabled={disabled}
        focus={(ref) => (references["2"] = ref)}
        name="2"
        type="number"
      />
      <Input
        value={code[3]}
        onChange={inputValue}
        onKeyUp={changeInput}
        onKeyDown={changePreviousValue}
        disabled={disabled}
        focus={(ref) => (references["3"] = ref)}
        name="3"
        type="number"
      />
    </div>
  );
};

import React from "react";
import {memberSsnFormat} from "../../../../../helpers/number.formats";

const TableManagement = ({tableClass, members}) => {
  return (
    <div className={`custom-table_wrap ${tableClass || ""}`}>
      <div className="custom-table_body">
        {members.map((item, index) => (
          <div key={`rowTable_${index}`} className="custom-table_body__item">
            <div className="custom-table_body__value __big">{item.function}</div>
            <div className="custom-table_body__value __big" style={{width: "auto"}}>
              {item.name}, {memberSsnFormat(item.socSecurityNr)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableManagement;

import React from "react";
import PresentationPageHeaderLeft from "./components/PresentationPageHeaderLeft";
import PresentationPageHeaderRight from "./components/PresentationPageHeaderRight";

const PresentationPageHeader = ({edit, setEdit}) => {
  return (
    <div className="presentation-page-header">
      <PresentationPageHeaderLeft edit={edit} setEdit={setEdit} />
      <PresentationPageHeaderRight edit={edit} setEdit={setEdit} />
    </div>
  );
};

export default PresentationPageHeader;

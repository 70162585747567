import React from "react";
import {useTranslation} from "react-i18next";
//locale
import {IconPhone} from "components/icons";

const RequestContacts = ({request}) => {
  const {t} = useTranslation();
  const firstName = request?.Request?.ContactPerson?.firstName;
  const lastName = request?.Request?.ContactPerson?.lastName;

  return (
    <div className="current-request_contacts __wrapper">
      <span className="current-request_contacts __name">
        {firstName} {lastName}
      </span>
      <span className="current-request_contacts __phone">
        <IconPhone />
        {request?.Request?.ContactPerson?.mobilePhone ?? t("NONE")}
      </span>
    </div>
  );
};

export default RequestContacts;

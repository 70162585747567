import React, {memo} from "react";

export const IconCheckMark = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={height || "20"}
      height={width || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
    >
      <path
        fill={color || "#000"}
        d="M10 20a9.937 9.937 0 01-7.072-2.928A9.93 9.93 0 010 10.002a9.937 9.937 0 012.928-7.073A9.932 9.932 0 0110 0a9.934 9.934 0 017.072 2.93A9.933 9.933 0 0120 10a9.933 9.933 0 01-2.928 7.07A9.935 9.935 0 0110 20zm0-18.947c-4.934 0-8.947 4.013-8.947 8.947 0 4.934 4.013 8.947 8.947 8.947 4.934 0 8.947-4.013 8.947-8.947 0-4.934-4.013-8.947-8.947-8.947z"
      />
      <path
        fill={color || "#000"}
        d="M7.819 14a.575.575 0 01-.386-.146l-3.273-3a.471.471 0 010-.707.582.582 0 01.771 0l2.887 2.646 7.251-6.647a.582.582 0 01.771 0 .471.471 0 010 .707l-7.637 7.001a.57.57 0 01-.386.146h.002z"
      />
    </svg>
  );
});

export default IconCheckMark;

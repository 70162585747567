import React, {useState} from "react";
import {useTranslation} from "react-i18next";
//local
import {Modal} from "components/Modal";
import {dateFormat} from "helpers/datetime";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import CustomRadioSwitch from "components/CustomRadioSwitch";

export const RemindersModal = ({reminderDays, closedAt, onOk, onClose}) => {
  const {t} = useTranslation();
  const [days, setDays] = useState(reminderDays);

  const isSwitchDisabled = (date, day) =>
    date && Date.now() > Date.parse(date) - 86400000 * (day + 1);

  const daysSwitch = (day) => (
    <div className="df-row-jsb-center mt-4">
      <CustomRadioSwitch
        disabled={isSwitchDisabled(closedAt, day)}
        checked={days?.includes(day)}
        label={`${day} ${t(day === 1 ? "DAY_LEFT" : "DAYS_LEFT")}`}
        mediumText
        isActive={(checked) =>
          checked ? setDays([...days, day]) : setDays(days.filter((item) => item !== day))
        }
      />
      {!isSwitchDisabled(closedAt, day) && (
        <div className="mid-gray">
          {dateFormat(Date.parse(closedAt) - 86400000 * day)}
        </div>
      )}
    </div>
  );

  return (
    <Modal
      onClose={onClose}
      width={450}
      noTitle
      boldTitle
      titleText={"MODAL_REMINDERS_TITLE"}
      addClass="modal-hide-offers-switch__wrapper"
    >
      <div className="pa-8">
        <div className="mid-gray">{t("MODAL_REMINDERS_DESCRIPTION")}</div>
        {daysSwitch(30)}
        {daysSwitch(14)}
        {daysSwitch(7)}
        {daysSwitch(3)}
        {daysSwitch(1)}
        <div className="df-row-jce-center mt-8">
          <ButtonTransparent
            name="CANCEL"
            addClass="mr-4"
            color="red"
            onClick={onClose}
          />
          <ButtonTransparent
            name="SAVE"
            onClick={() => {
              onOk(days);
              onClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

import React, {useState, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
// local
import {IconPlus} from "components/icons";
import ProjectItem from "./ProjectItem";
import {ProjectModal} from "components/modals/ProjectModal";
import {Preloader} from "components/Preloader";
import {hasPermission} from "helpers/helper";
import {ConfirmModal} from "components/ConfirmModal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {projectsTabs} from "constants/tabs.const";
import {ProjectStartGuide} from "./ProjectStartGuide";

export const ProjectsTab = inject("store")(
  observer(({store: {auth, buyer}}) => {
    const {user} = auth;
    const {loading, activeProjectsTab, filterCurrentTabProjects} = buyer;
    const [isProjectModal, setIsProjectModal] = useState(null);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const activeTabRequestKey = useMemo(
      () => projectsTabs.find((tab) => tab.id === activeProjectsTab).requestKey,
      [activeProjectsTab]
    );

    const handleClickNewProject = () =>
      hasPermission(["CREATE_PROJECT"])
        ? setIsProjectModal("new-project")
        : ConfirmModal({
            title: t("WANT_START_PROJECT"),
            text: t("CANT_START_PROJECT"),
            okText: "READ_AND_UPGRADE",
            type: "warning",
            onOk: () => navigate({hash: "#your-subscription"}),
          });

    const handleClose = (needUpdateProject) => {
      setIsProjectModal(null);
      if (typeof needUpdateProject === "number") {
        buyer.getProjects(true, activeTabRequestKey);
        buyer.setActiveProjectsTab(needUpdateProject);
      }
    };

    return (
      <div className="projects-tab">
        {loading && <Preloader addClass="preloader100" />}
        {!loading && (
          <div>
            {filterCurrentTabProjects?.length ? (
              filterCurrentTabProjects.map((project) => (
                <ProjectItem
                  key={project.id}
                  project={project}
                  ownUser={project.Members?.map((member) => member.id).includes(user.id)}
                  editProject={setIsProjectModal}
                />
              ))
            ) : (
              <React.Fragment>
                <div className="empty-projects-description">
                  {activeProjectsTab === 2 && t("EMPTY_PROJECTS_ARCHIVED_DESCRIPTION")}
                </div>
                {activeProjectsTab !== 2 && (
                  <ButtonTransparent
                    addClass="mt-8"
                    name="CREATE_PROJECT"
                    type="button"
                    icon={<IconPlus />}
                    onClick={handleClickNewProject}
                  />
                )}
                {activeProjectsTab !== 2 && <ProjectStartGuide step={1} />}
              </React.Fragment>
            )}
          </div>
        )}
        {isProjectModal && (
          <ProjectModal projectId={isProjectModal} onClose={handleClose} />
        )}
      </div>
    );
  })
);

export default ProjectsTab;

import React, {useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Form, Formik} from "formik";
import * as yup from "yup";
import {v4 as uuidv4} from "uuid";
//local
import {LocalModal} from "components/LocalModal";
import MessagePanel from "components/MessagePanel";
import EditDatePicker from "components/EditDatePicker";
import {IconNotificationCircle} from "components/icons";
import {InfoTooltip} from "components/InfoTooltip";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {bulkProcurementOperations} from "constants/buyer.consts";
import {color} from "constants/color.consts";

export const setOfferDateSchema = yup.object().shape({
  closedAt: yup.date().min(new Date()),
});

const modalWidth = {
  [bulkProcurementOperations.PROCUREMENT_BULK_OPERATION_MESSAGE_TO_ALL]: 500,
  [bulkProcurementOperations.PROCUREMENT_BULK_OPERATION_UPDATE_CLOSED_AT]: 500,
};

export const BulkRfpOperationModal = inject("store")(
  observer(({store: {buyer}, action, counters, currentUser, onSubmit, onClose}) => {
    const {t} = useTranslation();

    const [innerLoading, setInnerLoading] = useState(false);
    const [confirmationStep, setConfirmationStep] = useState(null);
    const [isNotificationStep, setNotificationStep] = useState(false);
    const [values, setValues] = useState(null);

    const isAdditionalSteps = useMemo(
      () => confirmationStep || isNotificationStep,
      [confirmationStep, isNotificationStep]
    );
    const additionalStepsTitle = useMemo(
      () =>
        confirmationStep
          ? t("BULK_OPERATION_MODAL_CONFIRMATION_TITLE")
          : isNotificationStep
          ? t("BULK_OPERATION_MODAL_NOTIFICATION_TITLE")
          : null,
      [confirmationStep, isNotificationStep, t]
    );

    const ConfirmationPart = () => (
      <div className="df-row-jce-center">
        <ButtonTransparent
          onClick={onClose}
          medium
          color="red"
          name="CANCEL"
          type="button"
        />
        <ButtonTransparent
          name="BULK_OPERATION_MODAL_PROCEED_BUTTON"
          onClick={handleStartBulkOperationUpdate}
          nonTransparent
          medium
          addClass="ml-4"
        />
      </div>
    );

    const NotificationPart = () => (
      <ButtonTransparent
        name="BULK_OPERATION_MODAL_DONE_BUTTON"
        onClick={onClose}
        nonTransparent
        medium
        uppercase
        addClass="mla"
      />
    );

    const MessageToAll = () => (
      <React.Fragment>
        <MessagePanel
          mode="create"
          loading={innerLoading}
          message={{User: currentUser}}
          placeholder={t("BULK_OPERATION_MODAL_PROCUREMENT_MESSAGE_PLACEHOLDER")}
          addClass="mt-4 mb-8 fz-14 hght-115"
          sendMessage={handleSendMessageToAll}
          permissionsMessage={["CREATE_RFT_MESSAGES_MESSAGE"]}
          permissionsFile={["CREATE_RFT_MESSAGES_FILE"]}
          isMyMessage
        />

        <div className="df-row-center __normal fz-12 mla" style={{width: "fit-content"}}>
          <span className="mr-1">
            {t("BULK_OPERATION_MODAL_PROCUREMENT_DESCRIPTION")}
          </span>
          <InfoTooltip tooltip="BULK_OPERATION_MODAL_PROCUREMENT_DESCRIPTION_TOOLTIP" />
        </div>
      </React.Fragment>
    );

    const UpdateClosetAt = () => (
      <React.Fragment>
        <Formik
          initialValues={{closedAt: new Date()}}
          validationSchema={setOfferDateSchema}
          onSubmit={handleSetOfferDate}
        >
          {() => (
            <Form>
              <EditDatePicker
                name="closedAt"
                small
                allowClear={false}
                disabledDate={(date) => date < new Date(new Date().setHours(0, 0, 0, 0))}
                addClass="mt-4 mb-8 wdth-150"
              />
              <div className="df-row-jsb-center">
                <div className="df-row-center __normal fz-12">
                  <span className="mr-1">
                    {t("BULK_OPERATION_MODAL_PROCUREMENT_DESCRIPTION")}
                  </span>
                  <InfoTooltip tooltip="BULK_OPERATION_MODAL_PROCUREMENT_DESCRIPTION_TOOLTIP" />
                </div>
                <div className="df">
                  <ButtonTransparent
                    onClick={onClose}
                    color="red"
                    name="CANCEL"
                    type="button"
                    medium
                  />
                  <ButtonTransparent
                    type="submit"
                    medium
                    addClass="ml-4"
                    name="BULK_OPERATION_MODAL_SAVE_BUTTON"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );

    const handleSendMessageToAll = async (message, file) => {
      setInnerLoading(true);
      const rfpId = uuidv4();
      const data = file
        ? await buyer.messageUploadFile({
            rfpId,
            file,
          })
        : null;
      const values = {
        actions: [bulkProcurementOperations.PROCUREMENT_BULK_OPERATION_MESSAGE_TO_ALL],
        [bulkProcurementOperations.PROCUREMENT_BULK_OPERATION_MESSAGE_TO_ALL]: {
          message,
          fileId: data?.id ?? data,
        },
      };
      setValues(values);
      setConfirmationStep(
        bulkProcurementOperations.PROCUREMENT_BULK_OPERATION_MESSAGE_TO_ALL
      );
      setInnerLoading(false);
    };

    const handleSetOfferDate = ({closedAt}) => {
      const values = {
        actions: [bulkProcurementOperations.PROCUREMENT_BULK_OPERATION_UPDATE_CLOSED_AT],
        [bulkProcurementOperations.PROCUREMENT_BULK_OPERATION_UPDATE_CLOSED_AT]: {
          closedAt,
        },
      };
      setValues(values);
      setConfirmationStep(
        bulkProcurementOperations.PROCUREMENT_BULK_OPERATION_UPDATE_CLOSED_AT
      );
    };

    const handleStartBulkOperationUpdate = () => {
      setConfirmationStep(null);
      setNotificationStep(true);
      onSubmit(values);
    };

    return (
      <LocalModal
        title={additionalStepsTitle}
        titleIcon={
          isAdditionalSteps ? (
            <IconNotificationCircle color={color.orange} addClass="mr-2" />
          ) : null
        }
        onClose={onClose}
        className={`mt-7 zi-2 __nonecase pa-${isAdditionalSteps ? 8 : 4} wdth-${
          modalWidth[action]
        }`}
        style={{alignSelf: "flex-start"}}
      >
        {isAdditionalSteps ? (
          <React.Fragment>
            <div className="__normal fz-16 mt-4 mb-8">
              {t(
                isNotificationStep
                  ? "BULK_OPERATION_MODAL_NOTIFICATION"
                  : `BULK_OPERATION_MODAL_CONFIRMATION_${t(confirmationStep)}`
              )}
            </div>
            {confirmationStep && <ConfirmationPart />}
            {isNotificationStep && <NotificationPart />}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="df-row-jsb-center">
              <span className="__bold fz-12 __uppercase">
                {t(`BULK_OPERATION_MODAL_TITLE_${action}`)}
              </span>
              <span className="__normal fz-12">
                {t("BULK_OPERATION_MODAL_TITLE_PROCUREMENT_COUNTS")?.format(
                  counters[0],
                  counters[1]
                )}
              </span>
            </div>

            {bulkProcurementOperations.PROCUREMENT_BULK_OPERATION_MESSAGE_TO_ALL ===
              action && <MessageToAll />}
            {bulkProcurementOperations.PROCUREMENT_BULK_OPERATION_UPDATE_CLOSED_AT ===
              action && <UpdateClosetAt />}
          </React.Fragment>
        )}
      </LocalModal>
    );
  })
);

import React, {memo} from "react";

export const IconBriefcase = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "21"}
      viewBox="0 0 20 21"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 5H14V3.5C14 2.673 13.327 2 12.5 2H7.5C6.673 2 6 2.673 6 3.5V5H1.5C0.673 5 0 5.673 0 6.5V17.5C0 18.327 0.673 19 1.5 19H18.5C19.327 19 20 18.327 20 17.5V6.5C20 5.673 19.327 5 18.5 5ZM7 3.5C7 3.224 7.224 3 7.5 3H12.5C12.776 3 13 3.224 13 3.5V5H7V3.5ZM1.5 6H18.5C18.776 6 19 6.224 19 6.5V14H17V13.5C17 13.224 16.776 13 16.5 13H14.5C14.224 13 14 13.224 14 13.5V14H6V13.5C6 13.224 5.776 13 5.5 13H3.5C3.224 13 3 13.224 3 13.5V14H1V6.5C1 6.224 1.224 6 1.5 6ZM16 14V15H15V14H16ZM5 14V15H4V14H5ZM18.5 18H1.5C1.224 18 1 17.776 1 17.5V15H3V15.5C3 15.776 3.224 16 3.5 16H5.5C5.776 16 6 15.776 6 15.5V15H14V15.5C14 15.776 14.224 16 14.5 16H16.5C16.776 16 17 15.776 17 15.5V15H19V17.5C19 17.776 18.776 18 18.5 18Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

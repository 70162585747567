import React from "react";

export const IconLangSwedish = () => {
  return (
    <svg
      width="30"
      height="20"
      viewBox="0 0 71 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.27 0.840088H6.99C3.47747 0.840088 0.630005 3.68756 0.630005 7.20009V41.4801C0.630005 44.9926 3.47747 47.8401 6.99 47.8401H64.27C67.7825 47.8401 70.63 44.9926 70.63 41.4801V7.20009C70.63 3.68756 67.7825 0.840088 64.27 0.840088Z"
        fill="#4A89DC"
      />
      <path d="M24.63 0.840088H16.63V47.8401H24.63V0.840088Z" fill="#FFCE54" />
      <path d="M0.630005 28.8401V20.8401H70.63V28.8401H0.630005Z" fill="#FFCE54" />
    </svg>
  );
};

import * as yup from "yup";
import {stringRequired} from "yup/defaultValudations";

export const schema = yup.object().shape({
  organizationName: stringRequired(),
  price: yup.number().required().positive().round("trunc"),
  validUntil: stringRequired().nullable(),
  companyNumber: yup.string().nullable(),
  // .matches(/^[\d]{6}\-[\d]{4}$/, "The field is not completely filled."),
});

import React, {useState} from "react";
import {FieldArray, Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
// locale
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconPlus} from "components/icons/index";
import {showSuccess} from "helpers/notifications.helper";
import LicenceForm from "./LicenceForm";
import {schema, initialValues} from "./formAttrs";

const LicensesAndCertificatesEdit = inject("store")(
  observer(({store: {organization}, closeEdit}) => {
    const {t} = useTranslation();
    const {organizationDetail} = organization;
    const licenses = (organizationDetail?.Licenses?.length &&
      organizationDetail?.Licenses) || [initialValues];
    const [loading, setLoading] = useState(false);

    const licensesForms = ({licenses, remove, setFieldValue}) =>
      licenses.map((licence, i) => (
        <LicenceForm
          key={i}
          index={i}
          remove={remove}
          licence={licence}
          setFieldValue={setFieldValue}
        />
      ));

    const onSubmit = (values) => {
      setLoading(true);
      const {licenses} = values;

      organization
        .licensesEdit(values)
        .then(() => {
          showSuccess("Policies successfully updated.");
          organization.setOrganizationDetail({
            Licenses: licenses,
          });
          closeEdit();
        })
        .finally(() => setLoading(false));
    };

    return (
      <Formik initialValues={{licenses}} validationSchema={schema} onSubmit={onSubmit}>
        {({values, setFieldValue}) => (
          <Form>
            <FieldArray
              name="licenses"
              render={(arrayHelpers) => (
                <div className="edit-insurance_wrapper editBlock_wrapper">
                  <div className="editBlock_head">
                    <div className="editBlock_head__title">
                      {t("LICENSES_&_CERTIFICATES")}
                    </div>
                    <div className="editBlock_head__actions">
                      <ButtonTransparent
                        name={t("SAVE_CHANGES")}
                        type="submit"
                        loading={loading}
                        addClass="editBlock_head__btn"
                      />
                      <ButtonTransparent
                        name={t("CANCEL")}
                        addClass="editBlock_head__btn"
                        type="button"
                        onClick={closeEdit}
                      />
                    </div>
                  </div>
                  <div className="editBlock_body">
                    {licensesForms({
                      licenses: values.licenses,
                      remove: arrayHelpers.remove,
                      setFieldValue: setFieldValue,
                    })}

                    <div className="btn-custom_wrap">
                      <ButtonTransparent
                        name={t("ONE_MORE")}
                        icon={<IconPlus />}
                        className="editBlock_head__btn"
                        onClick={() => {
                          arrayHelpers.push(initialValues);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            />
          </Form>
        )}
      </Formik>
    );
  })
);

export default LicensesAndCertificatesEdit;

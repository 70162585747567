import React, {useMemo, useCallback} from "react";
import {useTranslation} from "react-i18next";
// local
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconCheck, IconCross} from "components/icons";
import {color} from "constants/color.consts";

export const UserExistsOrganizationModal = ({info, onClose}) => {
  const {t} = useTranslation();

  const isStrikethroughPhone = useMemo(
    () => (info.mobilePhoneExist ? "__strikethrough" : ""),
    [info]
  );
  const isStrikethroughEmail = useMemo(
    () => (info.emailExist ? "__strikethrough" : ""),
    [info]
  );
  const computedIcon = useCallback(
    (option) =>
      option ? (
        <React.Fragment>
          <IconCross color={color.red} addClass="ml-4 mr-1" />
          <span className="__uppercase red __bold fz-10">
            {t("USER_EXISTS_ORGANIZATION_MODAL_ALREADY_REGISTERED")}
          </span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <IconCheck color={color.green} addClass="ml-4 mr-1" />
          <span className="__uppercase green __bold fz-10">
            {t("USER_EXISTS_ORGANIZATION_MODAL_AVAILABLE")}
          </span>
        </React.Fragment>
      ),
    [t]
  );

  return (
    <Modal
      titleText="USER_EXISTS_ORGANIZATION_MODAL_TITLE"
      noTitle
      boldTitle
      width={720}
      percentageWide="30%"
      rounded
      noCloseButton
      noCenterContent
      style={{marginTop: "15vh"}}
      onClose={onClose}
    >
      <div className="user-exists-organization-modal__content">
        {info?.memberOfOrganization ? (
          <React.Fragment>
            <div className="mb-2 df-row-center">
              <span className={isStrikethroughPhone}>{`+${info.mobilePhone}`}</span>
              {computedIcon(isStrikethroughPhone)}
            </div>
            <div className="mb-8 df-row-center">
              <span className={isStrikethroughEmail}>{info.email}</span>
              {computedIcon(isStrikethroughEmail)}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="mb-8">
              {t("USER_EXISTS_ORGANIZATION_MODAL_DESCRIPTION_01")}
            </div>
            <div className="mb-8">
              <span>{t("USER_EXISTS_ORGANIZATION_MODAL_DESCRIPTION_02_01")}</span>
              <a href={t("MAIL_TO_SUPPORT_LINK")}>{t("MAIL_TO_SUPPORT")}</a>
              <span>
                {t("USER_EXISTS_ORGANIZATION_MODAL_DESCRIPTION_02_02")?.format(
                  t("SUPPORT_PHONE_NUMBER")
                )}
              </span>
            </div>
          </React.Fragment>
        )}
        <ButtonTransparent
          name="USER_EXISTS_ORGANIZATION_MODAL_CLOSE_BUTTON"
          color="red"
          addClass="fz-14"
          onClick={onClose}
        />
        {info?.memberOfOrganization && (
          <React.Fragment>
            <div className="__bold mb-4 mt-8">
              {t("USER_EXISTS_ORGANIZATION_MODAL_SUBTITLE_01")}
            </div>
            <ul>
              <li>{t("USER_EXISTS_ORGANIZATION_MODAL_DESCRIPTION_03")}</li>
              <li>{t("USER_EXISTS_ORGANIZATION_MODAL_DESCRIPTION_04")}</li>
            </ul>
            <div className="__bold mb-4 mt-4">
              {t("USER_EXISTS_ORGANIZATION_MODAL_SUBTITLE_02")}
            </div>
            <div>{t("USER_EXISTS_ORGANIZATION_MODAL_DESCRIPTION_05")}</div>
            <div className="__bold mb-4 mt-4">
              {t("USER_EXISTS_ORGANIZATION_MODAL_SUBTITLE_03")}
            </div>
            <div>
              {t("USER_EXISTS_ORGANIZATION_MODAL_DESCRIPTION_06")?.format(
                t("SUPPORT_PHONE_NUMBER")
              )}
              <a href={t("MAIL_TO_SUPPORT_LINK")}>{t("MAIL_TO_SUPPORT")}</a>
            </div>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
};

import {notification} from "antd";

import i18next from "i18next";

export const showError = (text, duration, message, status, additionalText) => {
  notification[status || "error"]({
    message: message || "Error",
    duration: duration || 4.5,
    description: `${i18next.t(text)} ${additionalText ?? ""}` || "Something went wrong!",
  });
};

export const showWarning = (text, duration, message, status) => {
  notification[status || "warning"]({
    message: message || "Warning",
    duration: duration || 4.5,
    description: i18next.t(text) || "Something went wrong!",
  });
};

export const showSuccess = (text, duration, message, status) => {
  notification[status || "success"]({
    message: message || "Success",
    duration: duration || 4,
    description: text || "Updated successfully!",
  });
};

export const showInfo = (text, duration, message, status) => {
  notification[status || "info"]({
    message: message || "Info",
    duration: duration || 4,
    description: text || "Info",
  });
};

import React from "react";
import {Field} from "formik-antd";
import {useTranslation} from "react-i18next";
import {ErrorMessage} from "formik";
import {checkField} from "helpers/helper";

const SectionFormField = ({label, errorName, ...props}) => {
  const {t} = useTranslation();

  return (
    <div className="field">
      {label && <label className="label">{t(label)}</label>}
      <Field {...props} />
      {errorName && (
        <ErrorMessage name={errorName}>
          {(msg) => <p className="form-error">{checkField(msg)}</p>}
        </ErrorMessage>
      )}
    </div>
  );
};

export default SectionFormField;

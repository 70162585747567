import React, {useEffect} from "react";
import {Steps} from "antd";
import {steps} from "./constants/inviteOrganizationConsts";
import {inject, observer} from "mobx-react";
const {Step} = Steps;

const InviteOnboardingSteps = inject("store")(
  observer(({store: {organization}}) => {
    let current = organization.invitationStep;

    useEffect(() => {}, [organization.invitationStep]);

    return (
      <>
        <Steps current={current} className="inviteOnboarding_points__wrapper">
          {steps.map((item, i) => (
            <Step key={i} />
          ))}
        </Steps>
        {steps[current].content}
      </>
    );
  })
);

export default InviteOnboardingSteps;

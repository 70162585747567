import * as yup from "yup";
import {stringRequired, emailRequired} from "yup/defaultValudations";

export const schema = yup.object().shape({
  companyName: stringRequired(),
  email: emailRequired(),
  message: yup.string(),
});

export const initialValues = {companyName: "", email: "", message: ""};

import React from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import debounce from "lodash.debounce";
//local
import WaitSaveButton from "../WaitSaveButton";
import {RichField} from "components/RichField";
import {TitleIcon} from "components/TitleIcon";
import {Preloader} from "components/Preloader";
import {IconDocumentList} from "components/icons";
import {rfpStatuses} from "constants/buyer.consts";

const RequestEdit = inject("store")(
  observer(({store: {buyer}}) => {
    const {currentRequest, loading} = buyer;
    const {t} = useTranslation();

    const isDraft = currentRequest.status === rfpStatuses.DRAFT;

    const handleChange = debounce(
      (data) => buyer.updateCurrentRequestInfo({description: data}),
      2000
    );

    return (
      <div className={`request-edit-block${isDraft ? " pt-4" : ""}`}>
        {loading && <Preloader addClass="preloader250" />}
        {!loading && (
          <React.Fragment>
            <WaitSaveButton />
            <TitleIcon
              title={t("DESCRIPTION")}
              addClass="ml-4 mt-4"
              icon={<IconDocumentList />}
              tooltip="CREATE_RFP_DESCRIPTION_TOOLTIP"
            />
            <RichField
              data={currentRequest?.description}
              placeholder="RFP_DESCRIPTION"
              importType="html"
              exportType="html"
              onChange={handleChange}
            />
          </React.Fragment>
        )}
      </div>
    );
  })
);

export default RequestEdit;

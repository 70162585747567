import {useEffect} from "react";
import confetti from "canvas-confetti";

const Confetti = ({trigger}) =>
  useEffect(() => {
    trigger &&
      confetti({
        particleCount: 100,
        spread: 70,
        origin: {y: 0.5},
      });
  }, [trigger]);

export default Confetti;

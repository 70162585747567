import React, {memo} from "react";

export const IconRamavtal = memo(({height, width, color, addClass}) => {
  return (
    <svg
      width={height || "20"}
      height={width || "20"}
      viewBox="0 0 20 20"
      className={addClass}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.854 5.146L13.354 0.646C13.26 0.552 13.133 0.5 13 0.5H3.5C2.673 0.5 2 1.173 2 2V18C2 18.827 2.673 19.5 3.5 19.5H16.5C17.327 19.5 18 18.827 18 18V5.5C18 5.367 17.947 5.24 17.854 5.146ZM16.793 5.5H13.5C13.224 5.5 13 5.276 13 5V1.707L16.793 5.5ZM16.5 18.5H3.5C3.224 18.5 3 18.276 3 18V2C3 1.724 3.224 1.5 3.5 1.5H12V5C12 5.827 12.673 6.5 13.5 6.5H17V18C17 18.276 16.776 18.5 16.5 18.5Z"
        fill={color || "#000"}
      />
      <path
        d="M8.953 10.456V12.409C8.953 12.457 8.98 12.481 9.034 12.481H9.484C10.552 12.481 11.086 12.1 11.086 11.338C11.086 10.66 10.591 10.321 9.601 10.321C9.421 10.321 9.232 10.336 9.034 10.366C8.98 10.378 8.953 10.408 8.953 10.456ZM8.224 16C8.086 16 7.966 15.949 7.864 15.847C7.762 15.745 7.711 15.625 7.711 15.487V9.98797C7.711 9.84397 7.759 9.71497 7.855 9.60097C7.951 9.48697 8.071 9.42397 8.215 9.41197C8.725 9.36397 9.217 9.33997 9.691 9.33997C10.573 9.33997 11.233 9.50497 11.671 9.83497C12.109 10.159 12.328 10.618 12.328 11.212C12.328 11.638 12.211 12.013 11.977 12.337C11.749 12.661 11.44 12.889 11.05 13.021C11.044 13.021 11.041 13.024 11.041 13.03C11.041 13.042 11.044 13.048 11.05 13.048C11.338 13.228 11.584 13.582 11.788 14.11L12.328 15.523C12.37 15.637 12.355 15.745 12.283 15.847C12.217 15.949 12.124 16 12.004 16H11.725C11.569 16 11.428 15.958 11.302 15.874C11.176 15.784 11.086 15.667 11.032 15.523L10.528 14.137C10.42 13.849 10.297 13.66 10.159 13.57C10.021 13.48 9.784 13.435 9.448 13.435H9.034C8.98 13.435 8.953 13.462 8.953 13.516V15.487C8.953 15.625 8.902 15.745 8.8 15.847C8.704 15.949 8.587 16 8.449 16H8.224Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

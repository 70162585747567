import React from "react";
//local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconEdit} from "components/icons";
import {toExternalLink} from "helpers/helper";

const FeedCreateAccount = () => {
  return (
    <div className="feed-post__wrapper">
      <ButtonTransparent
        name="CREATE_FREE_ACCOUNT"
        icon={<IconEdit />}
        onClick={() => toExternalLink("https://handyday.com")}
      />
    </div>
  );
};

export default FeedCreateAccount;

import React, {memo} from "react";
//local
import {IconHandyDay, IconFeedback} from "components/icons";
import {IconMailLetter} from "components/icons";
import {Language} from "./Language";
import {VideoInstructionLink} from "components/VideoInstructionLink";
import {useTranslation} from "react-i18next";

export const Footer = memo(() => {
  const {t} = useTranslation();
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="footer__info">
        <div className="footer__links">
          <IconHandyDay />
          <span className="mt-1">© HandyDay AB {currentYear}</span>
        </div>
        <div className="footer__links">
          <div className="chunk">
            <span className="support-title">{t("NEED_SUPPORT")}</span>
            <span className="a-link email mt-1">
              <IconMailLetter />
              <span>
                <a href={t("MAIL_TO_SUPPORT_LINK")}>{t("MAIL_TO_SUPPORT")}</a>
              </span>
            </span>
          </div>
          <div className="chunk">
            <span className="support-title">{t("FEEDBACK_OR_IDEAS")}</span>
            <span className="a-link feedback mt-1">
              <IconFeedback />
              <span>
                <a href={t("FEEDBACK_OR_IDEAS_LINK_URL")}>
                  {t("FEEDBACK_OR_IDEAS_LINK")}
                </a>
              </span>
            </span>
          </div>
        </div>
        <div className="footer__links">
          <span className="a-link support-title">{t("GET_INSTRUCTIONS")}</span>
          <VideoInstructionLink
            color="black"
            label="VIDEO_LINK_FOOTER_LABEL"
            link="VIDEO_LINK_FOOTER_LINK"
            addClass="mt-1"
          />
        </div>

        <div className="footer__links">
          <Language />
        </div>
      </div>
    </div>
  );
});

import React from "react";
import {useTranslation} from "react-i18next";
//local
import PresentationCardWrapper from "../../PresentationCardWrapper";
import {IconClipboardUser} from "components/icons";
import {LockValue} from "components/LockValue";

const EstimatedEmployees = ({organization}) => {
  const {t} = useTranslation();

  const employerTaxList = organization.Company?.companyCreditReport?.payrollData;
  const lastFee = !!employerTaxList?.length ? employerTaxList[0].fee : null;
  const fulltimeEmployees = !!lastFee ? Math.round(lastFee / 0.3142 / 38000) : null;

  return (
    <PresentationCardWrapper
      icon={<IconClipboardUser />}
      title={t("OVERVIEW_TAB_ESTIMATED_EMPLOYEES")}
    >
      <LockValue
        canPermissions={["READ_OTHER_NUMBER_EMPLOYEES"]}
        noHideOurInfo
        fullSubcribeButton
        needMiniSibscribeButton
      >
        {fulltimeEmployees ? (
          <React.Fragment>
            <span className="mr-3 fz-12 discrete-text">
              {t("ANNUAL_REPORT_NUMBER_FULLTIME_EMPLOYEES")}
            </span>
            <span>{fulltimeEmployees}</span>
            <div className="mt-2 fz-12 mid-gray ws-br-spaces">
              {t("ANNUAL_REPORT_NUMBER_FULLTIME_EMPLOYEES_DESCRIPTION")}
            </div>
          </React.Fragment>
        ) : (
          <span className="__italic">
            {t("ANNUAL_REPORT_NUMBER_FULLTIME_EMPLOYEES_EMPTY")}
          </span>
        )}
      </LockValue>
    </PresentationCardWrapper>
  );
};

export default EstimatedEmployees;

import React, {memo} from "react";
import cn from "classnames";

export const IconShieldCheck = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      className={cn({[addClass]: !!addClass})}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 20C9.447 20 9.393 19.991 9.342 19.974C7.503 19.361 5.195 17.323 3.462 14.781C1.883 12.465 0 8.62699 0 3.49899C0 3.22299 0.224 2.99899 0.5 2.99899C3.314 2.99899 7.738 1.07299 9.223 0.0829929C9.391 -0.0290071 9.61 -0.0290071 9.778 0.0829929C11.263 1.07299 15.687 2.99899 18.501 2.99899C18.777 2.99899 19.001 3.22299 19.001 3.49899C19.001 8.62699 17.118 12.464 15.539 14.781C13.806 17.322 11.498 19.361 9.659 19.974C9.608 19.991 9.554 20 9.501 20H9.5ZM1.006 3.98399C1.119 8.61499 2.838 12.092 4.288 14.219C6.057 16.813 8.144 18.449 9.5 18.97C10.856 18.449 12.943 16.814 14.712 14.219C16.162 12.092 17.881 8.61499 17.994 3.98399C16.366 3.88199 14.552 3.32199 13.264 2.84399C11.846 2.31899 10.415 1.65099 9.5 1.09299C8.585 1.65099 7.154 2.31899 5.736 2.84399C4.447 3.32099 2.634 3.88199 1.006 3.98399Z"
        fill={color || "#000"}
      />
      <path
        d="M8.5 12.5C8.372 12.5 8.244 12.451 8.146 12.354L6.146 10.354C5.951 10.159 5.951 9.842 6.146 9.647C6.341 9.452 6.658 9.452 6.853 9.647L8.499 11.293L13.145 6.647C13.34 6.452 13.657 6.452 13.852 6.647C14.047 6.842 14.047 7.159 13.852 7.354L8.852 12.354C8.754 12.452 8.626 12.5 8.498 12.5H8.5Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconDocumentDalux = memo(({addClass}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.0426941" width="20" height="20" rx="2" fill="transparent" />
      <rect x="4" y="9.04269" width="12" height="8" fill="#8DC63F" />
      <path d="M3.43947 9.02186H1L10 2.04269V3.95936L3.43947 9.02186Z" fill="#3E5867" />
      <path d="M16.5605 9.04269H19L10 2.06353V3.98019L16.5605 9.04269Z" fill="#3E5867" />
    </svg>
  );
});

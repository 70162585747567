import React from "react";
import {IconCross, IconCheck} from "components/icons";
import {SkeletonRectValue} from "components/SkeletonRectValue";
import {color} from "constants/color.consts";

const QuickFact = ({title, isFact, canPermissions}) => {
  return (
    <div className={`presentation-page_quick-fact __item`}>
      <SkeletonRectValue
        quantity={1}
        addClassItem="mr-3"
        canPermissions={canPermissions}
        noHideOurInfo
      >
        {isFact ? (
          <IconCheck color={color.green} addClass="mr-3" />
        ) : (
          <IconCross color={color.red} addClass="mr-3" />
        )}
      </SkeletonRectValue>

      <span
        className="presentation-page_quick-fact __title"
        style={{color: isFact ? color.green : color.lightGray}}
      >
        {title}
      </span>
    </div>
  );
};

export default QuickFact;

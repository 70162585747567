import React, {useState} from "react";
//local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconLink, IconCheck, IconExitUp} from "components/icons";
import {color} from "constants/color.consts";

export const ShareButton = ({name, addClass, onClickCopy}) => {
  const [stateNumber, setStateNumber] = useState(0);
  const buttons = [
    {
      name: name ?? "SHARE",
      icon: <IconExitUp />,
    },
    {name: "COPY_LINK", icon: <IconLink color={color.link} />, noBorder: false},
    {name: "LINK_COPIED", icon: <IconCheck color={color.link} />, noBorder: true},
  ];

  const handleClick = () => {
    stateNumber === 1 && onClickCopy && onClickCopy();
    setStateNumber(stateNumber < 2 ? stateNumber + 1 : stateNumber);
  };

  return (
    <ButtonTransparent
      name={buttons[stateNumber].name}
      icon={buttons[stateNumber].icon}
      noBorder={buttons[stateNumber].noBorder}
      style={buttons[stateNumber].style}
      addClass={addClass}
      small
      onClick={handleClick}
    />
  );
};

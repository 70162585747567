import React, {useState} from "react";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
// locale
import CustomRatingStars from "components/CustomRatingStars";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconPlus} from "components/icons/index";
import FormikField from "components/FormikField";
import {ratingTypes} from "constants/ourEvaluationPage.consts";
import {schema} from "./formAttrs";

const AddRatingObserverItem = ({
  organizationId,
  setOrganizationRating,
  getListAllRatings,
}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showRating, setShowRating] = useState(false);

  const onSubmit = (values, {resetForm}) => {
    setLoading(true);
    setOrganizationRating(values).then((data) => {
      getListAllRatings().then(() => {
        setLoading(false);
      });
      resetForm(initialValues);
      setShowRating(false);
    });
  };

  const initialValues = {
    RatingId: "",
    type: ratingTypes.custom,
    score: "",
    name: "",
    description: "",
    RatedOrganizationId: organizationId || "",
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
      {({values}) => (
        <Form>
          <div className="add-rating_wrapper">
            {!showRating && (
              <div>
                <CustomRatingStars value={0} color="blue" isDisabled />
                <ButtonTransparent
                  name={t("ADD_YOUR_EVALUATION_POINT")}
                  icon={<IconPlus />}
                  className="add-rating_btn-add"
                  onClick={() => setShowRating(!showRating)}
                />
              </div>
            )}
            {showRating && (
              <div>
                <CustomRatingStars
                  className="add-rating_stars"
                  color="blue"
                  value={values.score}
                  onChange={(value) => {
                    values.score = value;
                  }}
                  inForm
                />

                <div className="add-rating_field-wrap">
                  <FormikField
                    name={"name"}
                    errorName={"name"}
                    placeholder="Skriv en titel här"
                  />
                </div>
                <div className="add-rating_field-wrap">
                  <FormikField
                    placeholder="Beskriv vad som utvärderas här"
                    name={"description"}
                    errorName={"description"}
                  />
                </div>
                <div className="rating-observer_actions">
                  <ButtonTransparent
                    name="Save"
                    className="add-rating_btn-save"
                    type="submit"
                    loading={loading}
                  />
                  <ButtonTransparent
                    name={t("CANCEL")}
                    onClick={() => {
                      setShowRating(false);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddRatingObserverItem;

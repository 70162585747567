import React, {memo} from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const ReactNumberInput = memo(
  ({phone, setPhone, onEnterKeyPress, setIsValid, autoFocus}) => {
    const handleValid = (value, country) => {
      const regexp = /^0/;
      const phoneWithOutCountryCode = value.substring(country.countryCode.length);
      if (phoneWithOutCountryCode.match(regexp)) {
        setIsValid(false);
        return "Phone number is invalid: " + value + ", " + country.name;
      }
      setTimeout(() => setIsValid(true), 0);
      return true;
    };

    return (
      <PhoneInput
        country={"se"}
        // preferredCountries={["se"]}
        onlyCountries={[
          "se",
          "dk",
          "at",
          "be",
          "ch",
          "cn",
          "cz",
          "de",
          "ee",
          "es",
          "fi",
          "fr",
          "gb",
          "ge",
          "gr",
          "hr",
          "hu",
          "in",
          "is",
          "it",
          "li",
          "lt",
          "lu",
          "lv",
          "mk",
          "mt",
          "nl",
          "no",
          "pl",
          "pt",
          "rs",
          "sk",
          "tr",
          "us",
        ]}
        value={phone}
        onChange={setPhone}
        containerClass="react-number-input"
        inputClass="react-number-input-search"
        buttonClass="react-number-btn"
        inputProps={{autoFocus}}
        onEnterKeyPress={onEnterKeyPress}
        autocompleteSearch={true}
        masks={{
          se: "(..) ... .. ..",
          fi: "(...) ...-.......",
        }}
        preserveOrder={["onlyCountries", "preferredCountries"]}
        isValid={(value, country) => handleValid(value, country)}
        enableAreaCodeStretch
      />
    );
  }
);

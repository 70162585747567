import React, {memo} from "react";
import cn from "classnames";
import {IconRatingDelete, IconDeleteClose} from "../icons";

const ButtonDelete = memo(
  ({addClass, name, onClick, isWhite, disabled, color, activeOnHover}) => {
    return (
      <button
        name={name}
        className={cn(
          "btn-custom__delete",
          {[addClass]: !!addClass},
          {"active-on-hover": !!activeOnHover}
        )}
        onClick={onClick}
        type="button"
        disabled={disabled}
      >
        {isWhite ? <IconDeleteClose /> : <IconRatingDelete color={color} />}
      </button>
    );
  }
);

export default ButtonDelete;

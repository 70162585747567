import React, {memo} from "react";

export const IconNumberHash = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      className={addClass}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5789 13H2.42105C2.18863 13 2 12.776 2 12.5C2 12.224 2.18863 12 2.42105 12H17.5789C17.8114 12 18 12.224 18 12.5C18 12.776 17.8114 13 17.5789 13Z"
        fill={color || "#6BC069"}
      />
      <path
        d="M17.5789 8H2.42105C2.18863 8 2 7.776 2 7.5C2 7.224 2.18863 7 2.42105 7H17.5789C17.8114 7 18 7.224 18 7.5C18 7.776 17.8114 8 17.5789 8Z"
        fill={color || "#6BC069"}
      />
      <path
        d="M13.6587 2.49346L12.3376 17.5937C12.3173 17.8252 12.0777 17.9936 11.8028 17.9695C11.5278 17.9455 11.3211 17.7381 11.3414 17.5065L12.6625 2.4063C12.6827 2.17476 12.9223 2.00637 13.1973 2.03043C13.4722 2.05448 13.6789 2.26192 13.6587 2.49346Z"
        fill={color || "#6BC069"}
      />
      <path
        d="M8.65868 2.49346L7.33758 17.5937C7.31732 17.8252 7.07774 17.9936 6.80279 17.9695C6.52784 17.9455 6.32113 17.7381 6.34138 17.5065L7.66248 2.4063C7.68274 2.17476 7.92233 2.00637 8.19728 2.03043C8.47223 2.05448 8.67893 2.26192 8.65868 2.49346Z"
        fill={color || "#6BC069"}
      />
    </svg>
  );
});

import React, {useState} from "react";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {showSuccess} from "helpers/notifications.helper";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Form, Formik} from "formik";
// locale
import SearchPreferenceFirstSetupSelections from "components/SearchPrefereces/SearchPreferenceFirstSetupSelections";
import {catchFieldErrors} from "helpers/formik.helpers";
import {initialValues, schema} from "components/SearchPrefereces/formAttrs";
import SearchPreferenceGroupSelections from "components/SearchPrefereces/SearchPreferenceGroupSelections";
import {ConfirmModal} from "components/ConfirmModal";

const SearchPreferencesEdit = inject("store")(
  observer(({store: {organization}, closeEdit}) => {
    const {organizationDetail, organizationMembers} = organization;
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    const ModalText = () => (
      <React.Fragment>
        <p className="mt-4">{t("ORGANIZATION_SERVICES_REGIONS_MODAL_DESCRIPTION_01")}</p>
        <p>{t("ORGANIZATION_SERVICES_REGIONS_MODAL_DESCRIPTION_02")}</p>
      </React.Fragment>
    );

    const handleUpdate = (values, setFieldError) => {
      setLoading(true);
      organization
        .saveSearch({...values, notSearchable: false})
        .then(() => {
          showSuccess();
          closeEdit();
        })
        .catch((e) => catchFieldErrors(e, setFieldError))
        .finally(() => setLoading(false));
    };

    const onSubmit = (values, {setFieldError}) => {
      const membersAvailableIn = Object.values(organizationMembers)
        .flat()
        .map((member) => member.availableIn)
        .flat()
        .filter((n) => n);
      const isNeedConfirm = !!membersAvailableIn.filter(
        (item) => !values.availableIn.includes(item)
      ).length;
      // if the regions already assigned to the user are removed from the settings
      isNeedConfirm && !values.availableIn.includes("FIRST_SETUP_REGION_00")
        ? ConfirmModal({
            title: t("ORGANIZATION_SERVICES_REGIONS_MODAL_TITLE"),
            text: <ModalText />,
            type: "warning",
            noQuestionSymbol: true,
            okText: "ORGANIZATION_SERVICES_REGIONS_MODAL_OK",
            onOk: () => handleUpdate(values, setFieldError),
            addClass: "members_choice-recipients-modal",
          })
        : handleUpdate(values, setFieldError);
    };

    const initialData = () => {
      if (!organizationDetail?.SearchPreference) return initialValues;
      let data = {};
      for (let key in organizationDetail?.SearchPreference)
        data = {
          ...data,
          [key]: organizationDetail?.SearchPreference[key] ?? initialValues[key],
        };
      return data;
    };

    return (
      <Formik initialValues={initialData()} validationSchema={schema} onSubmit={onSubmit}>
        {({values, setFieldValue}) => {
          return (
            <Form>
              <div className="edit-insurance_wrapper editBlock_wrapper">
                <div className="editBlock_head">
                  <div className="editBlock_head__title">
                    {t("HOW_WE_WANT_TO_BE_FOUND")}
                  </div>
                  <div className="editBlock_head__actions">
                    <ButtonTransparent
                      loading={loading}
                      type="submit"
                      name={t("SAVE_CHANGES")}
                      addClass="editBlock_head__btn"
                    />
                    <ButtonTransparent
                      name={t("CANCEL")}
                      addClass="editBlock_head__btn"
                      onClick={closeEdit}
                    />
                  </div>
                </div>
                <div className="editBlock_body">
                  <div className="organization-filter_selections__wrapper">
                    <SearchPreferenceFirstSetupSelections
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                    <SearchPreferenceGroupSelections
                      values={values}
                      setFieldValue={setFieldValue}
                      isEdit
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  })
);

export default SearchPreferencesEdit;

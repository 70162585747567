import React from "react";
import {useTranslation} from "react-i18next";
//local
import {Select} from "antd";
import {orderingNameValues} from "constants/select.consts";
import {changeSelectValue} from "helpers/helper";

const {Option} = Select;

const QualificationsTableHead = ({onChangeOrder, qualificationFilterParams}) => {
  const {t} = useTranslation();

  const options = (values) =>
    values.map(({value, label}, i) => (
      <Option key={i} value={value}>
        {t(label)}
      </Option>
    ));

  return (
    <div className="requests-card_head">
      <div className="requests-card_head__name __wrapper">
        <div className="requests-card_head__name __select mr-2">
          <Select
            onChange={onChangeOrder}
            value={changeSelectValue(orderingNameValues, qualificationFilterParams)}
          >
            {options(orderingNameValues)}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default QualificationsTableHead;

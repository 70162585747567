import React, {useCallback, useState} from "react";
import {inject, observer} from "mobx-react";
import {steps} from "./../../constants/inviteOrganizationConsts";
import {InputsCode} from "components/InputsCode";
import {Button} from "components/buttons/Button";
import {useTranslation} from "react-i18next";

const InviteCode = inject("store")(
  observer(({store: {organization}}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState(["", "", "", ""]);

    const current = organization.invitationStep;

    const next = () => submitForm();

    const prev = () => organization.setInvitationStep(current - 1);

    const submitForm = () => {
      setLoading(true);
      const codeStr = code.join("");

      organization
        .invitationCheckSmsCode(codeStr)
        .then(() => organization.setInvitationStep(current + 1))
        .finally(() => {
          setLoading(false);
          setCode(["", "", "", ""]);
        });
    };

    const isValid = useCallback(() => code.every((x) => x.length === 1), [code])();

    return (
      <div className="inviteOnboarding_form__wrapper">
        <h6 className="custom-title __uppercase">
          {t("INVITE_ON_BOARDING_CODE_CONFIRM_TEXT")}
        </h6>

        <InputsCode code={code} setCode={setCode} />
        <div className="inviteOnboarding_btn__wrapper">
          {current > 0 && (
            <Button
              onClick={() => prev()}
              disabled={loading}
              addClass="inviteOnboarding_btn"
              name={t("PREVIOUS")}
            />
          )}
          {current < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => next()}
              loading={loading}
              disabled={!isValid}
              addClass="inviteOnboarding_btn"
              name={t("NEXT")}
            />
          )}
        </div>
      </div>
    );
  })
);

export default InviteCode;

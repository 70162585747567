import React, {useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
//local
import TableTitleResult from "components/Table/TableTitleResult";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import ProposalsHeadProposals from "components/requestCard/ProposalsHeadProposals";
import ProposalItem from "components/requestCard/ProposalItem";
import ManualProposalItem from "components/requestCard/ManualProposalItem";
import {IconDownload} from "components/icons";

export const Proposals = ({
  proposals,
  manualProposals,
  currentRequest,
  onDownload,
  downloadLoading,
}) => {
  const {t} = useTranslation();

  const [isAdjustments, setAdjustments] = useState(false);
  const [isAnswers, setAnswers] = useState(false);
  const [isChecks, setChecks] = useState(false);

  const adjustmentsTitlesIds = useMemo(
    () => currentRequest.adjustmentTitles?.map((item) => item.id) ?? [],
    [currentRequest.adjustmentTitles]
  );
  const checkTitlesIds = useMemo(
    () => currentRequest?.checkTitles?.map((check) => check.id) ?? [],
    [currentRequest.checkTitles]
  );

  const smartFormQuestions = currentRequest?.smartFormQuestions?.filter(
    (item) => item.type !== "heading"
  );

  const totalProposals = proposals.length + manualProposals.length;

  const handleChangeCollapse = (type) => {
    if (type === "adjustments") setAdjustments((prevState) => !prevState);
    if (type === "answers") setAnswers((prevState) => !prevState);
    if (type === "checks") setChecks((prevState) => !prevState);
  };

  return (
    <div className="proposals-tab_wrapper">
      {!!totalProposals && (
        <>
          <TableTitleResult addClass="mb-6" title={"OFFERS"} totalItems={totalProposals}>
            <ButtonTransparent
              name={t("DOWNLOAD_EXCEL")}
              addClass="ml-8"
              small
              icon={<IconDownload />}
              loading={downloadLoading}
              disabled={currentRequest.isOffersHidden}
              onClick={onDownload}
            />
          </TableTitleResult>
          <div className="df">
            <ProposalsHeadProposals
              adjustmentsTitles={currentRequest.adjustmentTitles}
              checkTitles={currentRequest.checkTitles}
              questions={smartFormQuestions}
              currencyCode={currentRequest.CurrencyCode}
              isShowAdjustments={isAdjustments}
              isShowAnswers={isAnswers}
              isShowChecks={isChecks}
              onChangeCollapse={handleChangeCollapse}
              publicPageMode
            />
            <div className="proposals-tab_items">
              {proposals.map((proposal) => (
                <ProposalItem
                  key={proposal.id}
                  proposal={proposal}
                  isShowAdjustments={isAdjustments}
                  isShowAnswers={isAnswers}
                  isShowChecks={isChecks}
                  language="sv"
                  isHidedInfo={currentRequest.isOffersHidden}
                  offersClosedAt={currentRequest.closedAt}
                  adjustmentsIds={adjustmentsTitlesIds}
                  budgetValue={currentRequest?.Project?.ProjectItem?.costBudget}
                  questions={smartFormQuestions}
                  checkIds={checkTitlesIds}
                  publicPageMode
                />
              ))}
              {manualProposals.map((proposal) => (
                <ManualProposalItem
                  key={proposal.id}
                  proposal={proposal}
                  language="sv"
                  isShowAdjustments={isAdjustments}
                  isShowAnswers={isAnswers}
                  isShowChecks={isChecks}
                  adjustmentsIds={adjustmentsTitlesIds}
                  budgetValue={currentRequest?.Project?.ProjectItem?.costBudget}
                  answersCount={smartFormQuestions.length}
                  checkIds={checkTitlesIds}
                  publicPageMode
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

import React, {useRef, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {color} from "constants/color.consts";

const EndArrowIcon = ({isLastStep, lineColor}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    {isLastStep ? (
      <circle cx="6" cy="6" r="6" fill={lineColor} />
    ) : (
      <path
        d="M11.0328 5.09204C11.8073 5.44954 11.8073 6.55046 11.0328 6.90796L1.41906 11.3451C0.756439 11.6509 6.81901e-08 11.1669 1.0009e-07 10.4371L4.87993e-07 1.56291C5.19893e-07 0.833118 0.756438 0.349125 1.41906 0.65495L11.0328 5.09204Z"
        fill={lineColor}
      />
    )}
  </svg>
);

const GuidedTimeline = ({
  offerSubmitted,
  offerRead,
  evaluatingOffer,
  offerDeclined,
  isContract,
  isFinishedContract,
  addClass,
}) => {
  const {t} = useTranslation();
  const timelineRef = useRef(null);
  const [baseZIndex, setBaseZIndex] = useState(0);

  const timelineSteps = [
    {key: "OFFER_TIMELINE_HEADER_SUBMITTED", active: offerSubmitted},
    {key: "OFFER_TIMELINE_HEADER_READ", active: offerRead},
    {
      key: "OFFER_TIMELINE_HEADER_EVALUATING",
      active: evaluatingOffer || offerDeclined || isContract || isFinishedContract,
    },
    {key: "OFFER_TIMELINE_HEADER_DECLINED", active: offerDeclined, show: offerDeclined},
    {
      key: "OFFER_TIMELINE_HEADER_CONTRACT_PREPARATION",
      active: isContract || isFinishedContract,
      show: !offerDeclined,
    },
    {
      key: "OFFER_TIMELINE_HEADER_FINISHED_CONTRACT",
      active: isFinishedContract,
      show: !offerDeclined,
    },
  ].filter((step) => step.show !== false);

  const lastGreenStepIndex = timelineSteps.map((step) => step.active).lastIndexOf(true);

  const TimelineStep = ({step, index}) => {
    const shouldColorTextGrey =
      !step.active && (index > lastGreenStepIndex || lastGreenStepIndex === -1);

    const lineColor =
      step.key === "OFFER_TIMELINE_HEADER_DECLINED" && step.active
        ? color.red
        : step.active
        ? color.green
        : color.lightGray;

    const isLastStep = index === timelineSteps.length - 1;

    // Calculate z-index: baseZIndex (which includes parent z-index + number of steps) minus the step index
    const zIndex = baseZIndex - index;

    return (
      <div
        key={step.key}
        className={`timeline-step ${step.active ? "active" : ""}`}
        style={{zIndex: zIndex}}
      >
        <div
          className="step-label"
          style={{color: shouldColorTextGrey ? color.midGray : "inherit"}}
        >
          {t(step.key)}
        </div>
        <div className="line-container">
          <div className="line" style={{backgroundColor: lineColor}}></div>
          <div className="dot">
            <EndArrowIcon isLastStep={isLastStep} lineColor={lineColor} />
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (timelineRef.current) {
      const computedStyle = window.getComputedStyle(timelineRef.current);
      const parentZIndex = parseInt(computedStyle.zIndex) || 0;
      setBaseZIndex(parentZIndex + timelineSteps.length);
    }
  }, [timelineSteps.length]);

  return (
    <div className={`guided-timeline ${addClass || ""}`} ref={timelineRef}>
      <div className="__bold mr-1 mt-3 green">{t("OFFER_TIMELINE_HEADER_MAIN")}</div>
      {timelineSteps.map((step, index) => (
        <TimelineStep step={step} index={index} />
      ))}
    </div>
  );
};

export default GuidedTimeline;

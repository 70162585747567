import React, {useState, useEffect, useRef} from "react";
import {inject, observer} from "mobx-react";
import debounce from "lodash.debounce";
import {useTranslation} from "react-i18next";
import {useNavigate, useLocation} from "react-router-dom";
// local
import {DecorativeSearch} from "components/Filters/DecorativeSearch";
import FilterTabs from "./FilterTabs";
import {findSuppliersFilterTabs} from "constants/tabs.const";
import {FirstSetupSelections} from "../../pages/SaveSearchPreferences.page/components/FirstSetupSelections";

const FindOrganizationsFilter = inject("store")(
  observer(
    ({
      store: {organization},
      title,
      titleIcon,
      customParams,
      externalSorting,
      externalUpdate,
      customUrlSearch,
      initiallyShowFilters,
      needFavorites,
      hideOnLoading,
      hideEntireFilter,
      videoLinkPrefix,
    }) => {
      const {t} = useTranslation();
      const {filterParams, activeFilterTab} = organization;
      const [hideFilters, setHideFilters] = useState(!!initiallyShowFilters);
      const [isInitialFilter, setIsInitialFilter] = useState(false);
      const inputRef = useRef("");
      const navigate = useNavigate();
      const location = useLocation();

      const isFilterVisible =
        (Object.keys(filterParams).length > 2 || filterParams.name) && hideFilters;

      const organizationSearch = () =>
        organization.searchSuppliers(undefined, undefined, customUrlSearch);

      const onSelectChangeHandler = (name, value) => {
        const params =
          name === "direction"
            ? {...filterParams, ...value}
            : name === "tab"
            ? {availableIn: filterParams.availableIn, [name]: value}
            : {...filterParams, [name]: value};

        if (!params?.availableIn?.length) {
          delete params["availableIn"];
          delete params["localSuppliers"];
        }
        if (params?.page) delete params["page"];
        if (!value || (Array.isArray(value) && !value.length)) delete params[name];
        // Separate conversion for correct search string generation
        const urlParams = Object.entries(params).reduce(
          (obj, item) => ({
            ...obj,
            [`${item[0]}${Array.isArray(item[1]) ? "[]" : ""}`]: item[1],
          }),
          {}
        );
        updateUrlParams(`?${new URLSearchParams(urlParams)}`);
        organization.setFilterParams({...params, ...customParams});
        organizationSearch();
      };

      const inputDebounce = debounce(() => {
        const name = inputRef.current.value?.trim();
        if (name.length) onSelectChangeHandler("name", name);
        else onSelectChangeHandler("name", undefined);
      }, 400);

      const updateUrlParams = (search) => navigate({search});

      const resetSearch = (checked) => {
        updateUrlParams(
          `?${new URLSearchParams(
            checked ? {tab: findSuppliersFilterTabs[0].filterKey} : {}
          )}`
        );
        organization.setFilterParams(
          checked
            ? {...customParams, tab: findSuppliersFilterTabs[0].filterKey}
            : customParams
        );
        organizationSearch();
        if (!!inputRef.current) inputRef.current.value = "";
      };

      const getOrganizations = () => {
        organization.setFilterParams({
          ...customParams,
          ...filterParams,
        });
        organizationSearch();
        needFavorites && organization.getFavorites();
        if (hideFilters && inputRef.current)
          inputRef.current.value = filterParams?.name || "";
      };

      useEffect(() => {
        if (isInitialFilter) getOrganizations();
        else {
          const initialParams = Object.fromEntries(new URLSearchParams(location.search));
          const params =
            initialParams.hasOwnProperty("tab") || !hideFilters
              ? {
                  ...initialParams,
                  ...(initialParams.page ? {page: initialParams.page} : {}),
                }
              : {
                  ...initialParams,
                  tab: findSuppliersFilterTabs[0].filterKey,
                  ...(initialParams.page ? {page: initialParams.page} : {}),
                };
          updateUrlParams(`?${new URLSearchParams(params)}`);

          const initialFilterParams = Object.entries(params).reduce(
            (obj, item) => ({
              ...obj,
              [item[0].replace("[]", "")]: item[0].includes("[]")
                ? item[1].split(",")
                : item[1],
            }),
            {}
          );
          organization.setFilterParams(initialFilterParams);
          setIsInitialFilter(true);
        }
        // eslint-disable-next-line
      }, [isInitialFilter]);

      useEffect(() => {
        externalSorting &&
          onSelectChangeHandler(externalSorting.name, externalSorting.value);
        // eslint-disable-next-line
      }, [externalSorting]);

      useEffect(() => {
        externalUpdate && getOrganizations();
        // eslint-disable-next-line
      }, [externalUpdate]);

      return (
        <div className="find-suppliers_selections__wrapper">
          {!hideOnLoading && (
            <DecorativeSearch
              params={filterParams}
              resetEvent={() => resetSearch(hideFilters)}
              hideEntireFilter={hideEntireFilter}
              defaultChecked={hideFilters}
              isFilterVisible={isFilterVisible}
              changeCheck={(checked) => {
                !checked && resetSearch();
                checked &&
                  onSelectChangeHandler("tab", findSuppliersFilterTabs[0].filterKey);
                setHideFilters(checked);
              }}
              name={title}
              icon={titleIcon}
              videoLinkPrefix={videoLinkPrefix}
              searchFocus={inputRef}
              searchOnChange={inputDebounce}
              searchPlaceholder={t("FIND_SUPPLIERS_INPUT")}
              searchLabel={t("FIND_SUPPLIERS_INPUT")}
              showSearch={hideFilters}
            />
          )}

          {!hideEntireFilter && hideFilters && (
            <FilterTabs
              params={filterParams}
              onChange={onSelectChangeHandler}
              onSearch={organizationSearch}
            />
          )}

          {!hideEntireFilter && hideFilters && (
            <FirstSetupSelections
              params={filterParams}
              onChange={onSelectChangeHandler}
              tab={activeFilterTab}
            />
          )}
        </div>
      );
    }
  )
);

export default FindOrganizationsFilter;

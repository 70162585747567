import React from "react";
import Rating from "react-rating";
import RatingStarBlueFull from "assets/images/svg/icon-rating/rating-star-blue-full.svg";
import RatingStarBlueEmpty from "assets/images/svg/icon-rating/rating-star-blue-empty.svg";
import RatingStarBlackFull from "assets/images/svg/icon-rating/rating-star-black-full.svg";
import RatingStarBlackEmpty from "assets/images/svg/icon-rating/rating-star-black-empty.svg";
import {checkField} from "helpers/helper";
import {ErrorMessage} from "formik";

const CustomRatingStars = ({value, isDisabled, color, onChange, inForm}) => {
  return (
    <div className="rating-stars_wrapper">
      <Rating
        onChange={onChange}
        initialRating={value}
        readonly={isDisabled}
        emptySymbol={
          color === "blue" ? (
            <img src={RatingStarBlueEmpty} alt="" />
          ) : (
            <img src={RatingStarBlackEmpty} alt="" />
          )
        }
        fullSymbol={
          color === "blue" ? (
            <img src={RatingStarBlueFull} alt="" />
          ) : (
            <img src={RatingStarBlackFull} alt="" />
          )
        }
      />
      {inForm && (
        <ErrorMessage name={"score"}>
          {(msg) => <p className="form-error">{checkField(msg)}</p>}
        </ErrorMessage>
      )}
    </div>
  );
};

export default CustomRatingStars;

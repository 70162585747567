import React, {memo} from "react";

export const IconCalendarEmpty = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      className={addClass ?? ""}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8 1.59999H12.8V1.19999C12.8 0.979188 12.6208 0.799988 12.4 0.799988C12.1792 0.799988 12 0.979188 12 1.19999V1.59999H4V1.19999C4 0.979188 3.8208 0.799988 3.6 0.799988C3.3792 0.799988 3.2 0.979188 3.2 1.19999V1.59999H1.2C0.5384 1.59999 0 2.13839 0 2.79999V14C0 14.6616 0.5384 15.2 1.2 15.2H14.8C15.4616 15.2 16 14.6616 16 14V2.79999C16 2.13839 15.4616 1.59999 14.8 1.59999ZM1.2 2.39999H3.2V3.59999C3.2 3.82079 3.3792 3.99999 3.6 3.99999C3.8208 3.99999 4 3.82079 4 3.59999V2.39999H12V3.59999C12 3.82079 12.1792 3.99999 12.4 3.99999C12.6208 3.99999 12.8 3.82079 12.8 3.59999V2.39999H14.8C15.0208 2.39999 15.2 2.57919 15.2 2.79999V4.79999H0.8V2.79999C0.8 2.57919 0.9792 2.39999 1.2 2.39999ZM14.8 14.4H1.2C0.9792 14.4 0.8 14.2208 0.8 14V5.59999H15.2V14C15.2 14.2208 15.0208 14.4 14.8 14.4Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

import React, {memo} from "react";

export const IconPrint = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 4H17V1.5C17 0.673 16.327 0 15.5 0H4.5C3.673 0 3 0.673 3 1.5V4H1.5C0.673 4 0 4.673 0 5.5V14.5C0 15.327 0.673 16 1.5 16H3V18.5C3 19.327 3.673 20 4.5 20H15.5C16.327 20 17 19.327 17 18.5V16H18.5C19.327 16 20 15.327 20 14.5V5.5C20 4.673 19.327 4 18.5 4ZM4 1.5C4 1.224 4.224 1 4.5 1H15.5C15.776 1 16 1.224 16 1.5V4H4V1.5ZM15.5 19H4.5C4.224 19 4 18.776 4 18.5V12H16V18.5C16 18.776 15.776 19 15.5 19ZM19 14.5C19 14.776 18.776 15 18.5 15H17V12H17.5C17.776 12 18 11.776 18 11.5C18 11.224 17.776 11 17.5 11H2.5C2.224 11 2 11.224 2 11.5C2 11.776 2.224 12 2.5 12H3V15H1.5C1.224 15 1 14.776 1 14.5V5.5C1 5.224 1.224 5 1.5 5H18.5C18.776 5 19 5.224 19 5.5V14.5Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M14.5 14H5.5C5.224 14 5 13.776 5 13.5C5 13.224 5.224 13 5.5 13H14.5C14.776 13 15 13.224 15 13.5C15 13.776 14.776 14 14.5 14Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M14.5 16H5.5C5.224 16 5 15.776 5 15.5C5 15.224 5.224 15 5.5 15H14.5C14.776 15 15 15.224 15 15.5C15 15.776 14.776 16 14.5 16Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M14.5 18H5.5C5.224 18 5 17.776 5 17.5C5 17.224 5.224 17 5.5 17H14.5C14.776 17 15 17.224 15 17.5C15 17.776 14.776 18 14.5 18Z"
        fill={color || "#1BA2CC"}
      />
      <path
        d="M16.5 9C15.673 9 15 8.327 15 7.5C15 6.673 15.673 6 16.5 6C17.327 6 18 6.673 18 7.5C18 8.327 17.327 9 16.5 9ZM16.5 7C16.224 7 16 7.224 16 7.5C16 7.776 16.224 8 16.5 8C16.776 8 17 7.776 17 7.5C17 7.224 16.776 7 16.5 7Z"
        fill={color || "#1BA2CC"}
      />
    </svg>
  );
});

export default IconPrint;

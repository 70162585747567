import React, {memo} from "react";

export const IconWallet = memo(({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 9H17V6.5C17 5.673 16.327 5 15.5 5H13V0.5C13 0.224 12.776 0 12.5 0H2.5C2.224 0 2 0.224 2 0.5V5.05C0.86 5.282 0 6.292 0 7.5V18.5C0 19.327 0.673 20 1.5 20H17.5C18.327 20 19 19.327 19 18.5V10.5C19 9.673 18.327 9 17.5 9ZM3 1H12V7H3V1ZM2 6.086V7.5C2 7.776 2.224 8 2.5 8H14.5C14.776 8 15 7.776 15 7.5C15 7.224 14.776 7 14.5 7H13V6H15.5C15.776 6 16 6.224 16 6.5V9H2.5C1.673 9 1 8.327 1 7.5C1 6.848 1.418 6.292 2 6.086ZM18 18.5C18 18.776 17.776 19 17.5 19H1.5C1.224 19 1 18.776 1 18.5V9.499C1.418 9.814 1.938 10 2.5 10H17.5C17.776 10 18 10.224 18 10.5V18.5Z"
        fill={color || "#000"}
      />
      <path
        d="M10.5 3H10V2.5C10 2.224 9.776 2 9.5 2H7.5C7.224 2 7 2.224 7 2.5V4H6V2.5C6 2.224 5.776 2 5.5 2C5.224 2 5 2.224 5 2.5V3H4.5C4.224 3 4 3.224 4 3.5C4 3.776 4.224 4 4.5 4H5V4.5C5 4.776 5.224 5 5.5 5H7.5C7.776 5 8 4.776 8 4.5V3H9V4.5C9 4.776 9.224 5 9.5 5C9.776 5 10 4.776 10 4.5V4H10.5C10.776 4 11 3.776 11 3.5C11 3.224 10.776 3 10.5 3Z"
        fill={color || "#000"}
      />
      <path
        d="M16.5 18H12.5C12.224 18 12 17.776 12 17.5C12 17.224 12.224 17 12.5 17H16.5C16.776 17 17 17.224 17 17.5C17 17.776 16.776 18 16.5 18Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

export default IconWallet;

import React, {memo} from "react";

export const IconFile = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 12 12"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5883 3.00804L7.70618 0.125893C7.62582 0.0455357 7.51734 0 7.4035 0H1.71422C1.47716 0 1.28564 0.191518 1.28564 0.428571V11.5714C1.28564 11.8085 1.47716 12 1.71422 12H10.2856C10.5227 12 10.7142 11.8085 10.7142 11.5714V3.31205C10.7142 3.19821 10.6687 3.08839 10.5883 3.00804ZM9.72582 3.50893H7.20529V0.988393L9.72582 3.50893ZM9.74993 11.0357H2.24993V0.964286H6.29457V3.85714C6.29457 4.00633 6.35384 4.1494 6.45933 4.25489C6.56481 4.36038 6.70789 4.41964 6.85707 4.41964H9.74993V11.0357Z"
        fill={color || "#000"}
      />
    </svg>
  );
});

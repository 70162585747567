import React, {memo} from "react";
import {useNavigate} from "react-router-dom";
//local
import cn from "classnames";

export const ProjectLabel = memo(
  ({
    name,
    code,
    id,
    isCreating,
    isEditing,
    width,
    fullWidth,
    wideName,
    large,
    huge,
    color,
    highlight,
    addClass,
  }) => {
    const navigate = useNavigate();
    const styles = {
      width: fullWidth && "100%",
      maxWidth: fullWidth ? "unset" : `${width}px`,
    };

    return (
      <div
        className={cn(
          "project-label_wrapper",
          {__full: !!name && !!code},
          {__large: !!large},
          {__huge: !!huge},
          {"__wide-name": !!wideName},
          {"__wide-name": !!fullWidth},
          {[`__${color}`]: !!color},
          {__highlight: !!highlight},
          {__clickable: !!id},
          {[addClass]: !!addClass}
        )}
        onClick={() => (!!id ? navigate(`/projects/${id}`) : undefined)}
        style={styles}
      >
        <div className={cn("project-label_name", {__empty: !name})}>{name}</div>
        {(code || isCreating || isEditing) && (
          <div className={cn("project-label_code", {__empty: !code})}>{code}</div>
        )}
      </div>
    );
  }
);

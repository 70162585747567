import React from "react";
import {useTranslation} from "react-i18next";
//local
import PresentationCardWrapper from "../../PresentationCardWrapper";
import {IconSkatteverket} from "components/icons";
import {LockValue} from "components/LockValue";
import {formattedNumber} from "helpers/number.formats";
import EmptyOrNoCompanyCreditData from "components/EmptyOrNoCompanyCreditData";

const Payroll = ({organization, hasFirstUpdateData}) => {
  const {t} = useTranslation();

  const employerTaxDetails = organization.Company?.companyCreditReport?.payrollData;

  return (
    <PresentationCardWrapper
      icon={<IconSkatteverket />}
      title={t("OVERVIEW_TAB_PAYROLL")}
    >
      <LockValue
        canPermissions={["READ_OTHER_PAYROLL"]}
        noHideOurInfo
        fullSubcribeButton
        needMiniSibscribeButton
      >
        {!!employerTaxDetails?.length ? (
          employerTaxDetails.slice(0, 3).map((item, i) => (
            <div key={`${item.fee}_${i}`}>
              <span className="pr-10 fz-12">{item.payrollDate}</span>
              <span className="fz-12">{`${formattedNumber(item.fee)} SEK`}</span>
            </div>
          ))
        ) : (
          <EmptyOrNoCompanyCreditData hasFirstUpdateData={hasFirstUpdateData} />
        )}
      </LockValue>
    </PresentationCardWrapper>
  );
};

export default Payroll;

import React, {useState, useMemo, useCallback} from "react";
import {inject, observer} from "mobx-react";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
//local
import {toOrganizationId} from "constants/routes.const";
import {OrganizationStats} from "./OrganizationStats";
import {CustomTooltip} from "components/CustomTooltip";
import CreditRatingScore from "components/CreditRatingScore";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import CommentModal from "components/modals/CommentModal";
import {InitialsBadge} from "components/InitialsBadge";
import HistoryInfo from "components/HistoryInfo";
import {
  IconUE2021,
  IconRamavtal,
  IconSkullEmpty,
  IconMessage,
  IconDotFilled,
  IconList,
  IconHourglass,
  IconBinoculars,
} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import ButtonDelete from "components/buttons/ButtonDelete";
import {showWarning} from "helpers/notifications.helper";
import CustomRatingStars from "../CustomRatingStars";
import {LockValue} from "components/LockValue";
import {ourAgreementsTypes, qualificationStatus} from "constants/user.consts";
import {color} from "constants/color.consts";
import {hasNoLegacySupplierQualification} from "helpers/helper";
import {historyBlacklistTableColumns} from "constants/table.consts";
import {toThousands} from "helpers/number.formats";
import cn from "classnames";

const OK_ICON = 1;
const X_ICON = 2;

// place: common, favorites, blacklist, monitoring, qualifying

export const OrganizationCard = inject("store")(
  observer(
    ({
      store: {organization: organizationStore, auth},
      organization,
      needUpdate,
      showTitleIcon,
      place,
      isHistory,
      showQualificationAnswers,
      removeQualificationAnswers,
      confirmQualificationAnswers,
      changeMonitorSuppliers,
    }) => {
      const navigate = useNavigate();
      const {t} = useTranslation();

      const {user, activeOrganization, isStaff} = auth;
      const {favoritesOrganization, blacklistOrganization} = organizationStore;

      const [commentModalOrganizationId, setCommentModalOrganizationId] = useState(null);

      const nameOrganization = (text) =>
        text.replace(",", "").split(" ").slice(0, 1).join(" ");
      const isUE2021 = !!organization.OurAgreements?.find(
        (item) => item?.type === ourAgreementsTypes.UE2021
      );
      const isRamavtal = !!organization.OurAgreements?.find(
        (item) => item?.type === ourAgreementsTypes.RAMAVTAL
      );

      const stats = organization.OrganizationStat;
      const commentsCount = stats?.commentsCount;

      const company = organization?.Company;
      const companyTurnover = useMemo(
        () =>
          company?.turnover
            ? toThousands(company?.turnover) < 1
              ? "<1"
              : toThousands(company?.turnover)
            : "-",
        [company?.turnover]
      );
      const unionAgreementIcon =
        !!organization.UnionAgreements?.length ||
        !!company?.CompanyUnionAgreements?.length
          ? OK_ICON
          : X_ICON;
      const insuranceIcon = !!organization.Insurances?.length ? OK_ICON : X_ICON;
      const taxVatIcon =
        company?.swedish_f_tax &&
        company?.swedish_vat_registration &&
        company?.employersRegistration
          ? OK_ICON
          : X_ICON;

      const isFavorite = useMemo(
        () =>
          !!favoritesOrganization?.length &&
          favoritesOrganization.some((x) => x.id === organization.id),
        [favoritesOrganization, organization.id]
      );
      const isBlackList = useMemo(
        () =>
          !!blacklistOrganization?.length &&
          blacklistOrganization.some((x) => x.id === organization.id),
        [blacklistOrganization, organization.id]
      );

      const hasUnqualifiedFavoriteButton = useCallback(
        () =>
          hasNoLegacySupplierQualification(
            showTitleIcon,
            isFavorite,
            !!organization.SupplierQualifications?.length,
            user.ActiveOrganization?.enableSuppliersQualification
          ),
        [showTitleIcon, isFavorite, organization, user]
      );

      const currentQualification = useMemo(
        () =>
          place === "qualifying" && !!organization.SupplierQualifications
            ? organization.SupplierQualifications[0]
            : null,
        [place, organization]
      );
      const qualifyingStatus = useMemo(
        () => currentQualification?.status,
        [currentQualification]
      );
      const qualificationId = useMemo(
        () => currentQualification?.id,
        [currentQualification]
      );

      const isMonitoringSuppliersEnabled = useMemo(
        () => activeOrganization?.allowMonitorSuppliers,
        [activeOrganization]
      );
      const isMonitoringSuppliersIcon = useMemo(
        () =>
          isMonitoringSuppliersEnabled &&
          !(place === "qualifying" || place === "monitoring"),
        [isMonitoringSuppliersEnabled, place]
      );
      const isMonitoredCompany = !!organization.Company?.OrgMemberMonitor?.length;
      const monitoredUsers = useMemo(
        () =>
          isMonitoringSuppliersIcon
            ? null
            : organization.Company?.OrgMemberMonitor?.map((member) => member.User) ?? [],
        [isMonitoringSuppliersIcon, organization.Company?.OrgMemberMonitor]
      );
      const colleagues = useMemo(
        () => monitoredUsers?.filter((item) => item?.id !== user.id) ?? [],
        [monitoredUsers, user]
      );
      const loggedMonitoredUser = useMemo(
        () => monitoredUsers?.find((monitoredUser) => monitoredUser?.id === user.id),
        [monitoredUsers, user]
      );
      const [isMonitoredByMe, setMonitoredByMe] = useState(!!loggedMonitoredUser);

      const handleRedirectOnClick = () => navigate(toOrganizationId(organization.id));

      const handleChangeBlacklistHistoryComment = (historyId, values) =>
        organizationStore
          .changeBlacklistHistoryComment(
            historyId,
            values,
            organization?.BlacklistHistoryEvents
          )
          .then((BlacklistHistoryEvents) =>
            organizationStore.updateSupplierOrganization(organization.id, {
              BlacklistHistoryEvents,
            })
          );

      const handleDelete = (e) => {
        e.stopPropagation();
        if (!isStaff) return showWarning(t("BLACKLIST_PERMISSION_TEXT"));
        setCommentModalOrganizationId(organization.id);
      };

      return (
        <div className="organization-card_body" onClick={handleRedirectOnClick}>
          <div className="organization-card_body__name __wrapper">
            {organization.logotype ? (
              <div className={`organization-card_body__name __logo`}>
                <Link className="ape" to={toOrganizationId(organization.id)}>
                  <img
                    src={organization.logotype?.Location}
                    alt={nameOrganization(organization.name)}
                  />
                </Link>
              </div>
            ) : (
              <span className="empty"></span>
            )}

            <div>
              <div className="df-row-center">
                <div className="organization-card_body__name __title">
                  {hasUnqualifiedFavoriteButton()}
                  <Link className="ml-2" to={toOrganizationId(organization.id)}>
                    {organization.name}
                  </Link>
                  {showTitleIcon && isBlackList && (
                    <IconSkullEmpty width="20" height="20" addClass="ml-2" />
                  )}
                  {isUE2021 && <IconUE2021 addClass="ml-2" />}
                  {isRamavtal && <IconRamavtal addClass="ml-2" />}
                  {isMonitoringSuppliersIcon && isMonitoredCompany && (
                    <IconBinoculars addClass="ml-2" />
                  )}
                </div>
                <div className="organization-card_body__name __action">
                  {place === "blacklist" && (
                    <ButtonDelete addClass={"custom__delete"} onClick={handleDelete} />
                  )}
                  {organization.id === commentModalOrganizationId && (
                    <CommentModal
                      onConfirm={(comment) =>
                        organizationStore
                          .addToBlackList(organization.id, {comment})
                          .then(needUpdate)
                      }
                      onClose={() => setCommentModalOrganizationId(null)}
                      placeholder={`${t("BLACKLIST_COMMENT_PLACEHOLDER_REMOVE")}\n\n${t(
                        "BLACKLIST_COMMENT_PLACEHOLDER_COMMON"
                      )}`}
                    />
                  )}
                </div>
                {place === "blacklist" && (
                  <HistoryInfo
                    data={organization?.BlacklistHistoryEvents}
                    columns={historyBlacklistTableColumns}
                    onChangeComment={handleChangeBlacklistHistoryComment}
                    showAmount
                  />
                )}
              </div>
              {isHistory && (
                <OrganizationStats
                  projectsCount={stats?.projectsCount}
                  rfpsCount={stats?.rfpsCount}
                  receivedOffersCount={stats?.receivedOffersCount}
                  contractsCount={stats?.contractsCount}
                  contractsSum={stats?.contractsSum}
                  addClass="mt-1"
                />
              )}
            </div>
            {!(place === "qualifying" || place === "monitoring") && !!commentsCount && (
              <CustomTooltip text="ORGANIZATION_STATS_COMMENTS_TOOLTIP" noWrapText>
                <div
                  className={cn("organization-card_body__name  __comments-stats", {
                    "__no-rating-stars": !organization.overallScore,
                  })}
                >
                  <IconMessage height={12} width={11} color={color.black} />
                  <span className="fz-10 __bold">{commentsCount}</span>
                </div>
              </CustomTooltip>
            )}
          </div>
          <div className="organization-card_body__indicator __wrapper">
            {!(place === "qualifying" || place === "monitoring") &&
              organization.overallScore && (
                <div className="organization-card_body__indicator __rating">
                  <CustomRatingStars value={organization.overallScore} isDisabled />
                </div>
              )}
            {place !== "monitoring" && (
              <React.Fragment>
                <div className="organization-card_body__indicator __loading turnover">
                  <LockValue
                    canPermissions={["READ_TABLE_TURNOVER"]}
                    addClassIcon="mr-4"
                    customLockIcon={<IconDotFilled color={color.purple} />}
                  >
                    {companyTurnover}
                  </LockValue>
                </div>
                <div className="organization-card_body__indicator __status">
                  <LockValue
                    canPermissions={["READ_TABLE_CREDIT_RATING"]}
                    customLockIcon={<IconDotFilled color={color.purple} />}
                  >
                    <CreditRatingScore
                      creditRating={company?.creditRating}
                      noCreditRatingSymbol="?"
                      smallSize
                    />
                  </LockValue>
                </div>
                <div className="organization-card_body__indicator __sub-status">
                  <LockValue
                    canPermissions={["READ_TABLE_COLLECTIVE_UNION_AGREEMENTS"]}
                    customLockIcon={<IconDotFilled color={color.purple} />}
                  >
                    <span className={`indicator_sub-status __${unionAgreementIcon}`} />
                  </LockValue>
                </div>
                <div className="organization-card_body__indicator __sub-status">
                  <LockValue
                    canPermissions={["READ_TABLE_INSURANCE"]}
                    customLockIcon={<IconDotFilled color={color.purple} />}
                  >
                    <span
                      className={`indicator_sub-status insurance __${insuranceIcon}`}
                    />
                  </LockValue>
                </div>
                <div className="organization-card_body__indicator __sub-status">
                  <LockValue
                    canPermissions={["READ_TABLE_TAX_VAT_EMPLOYERS"]}
                    customLockIcon={<IconDotFilled color={color.purple} />}
                  >
                    <span className={`indicator_sub-status __${taxVatIcon}`} />
                  </LockValue>
                </div>
              </React.Fragment>
            )}

            {place === "monitoring" && (
              <React.Fragment>
                <div className="organization-card_body__indicator __colleagues">
                  {!!colleagues?.length &&
                    colleagues.map(
                      (member, index) =>
                        index < 3 && (
                          <InitialsBadge
                            key={member?.id}
                            firstName={member?.firstName}
                            lastName={member?.lastName}
                            addClass="mr-2"
                          />
                        )
                    )}
                  {!!colleagues?.length && colleagues.length > 3 && (
                    <span className="fz-12">{`+${colleagues.length - 3}`}</span>
                  )}
                </div>
                <div className="organization-card_body__indicator __my">
                  {isMonitoredByMe && (
                    <InitialsBadge
                      firstName={loggedMonitoredUser?.firstName}
                      lastName={loggedMonitoredUser?.lastName}
                      isCurrentUser
                      addClass="mr-2"
                    />
                  )}
                  <CustomRadioSwitch
                    checked={isMonitoredByMe}
                    small
                    isActive={(checked, event) => {
                      event.stopPropagation();
                      setMonitoredByMe(checked);
                      changeMonitorSuppliers(checked);
                    }}
                  />
                </div>
              </React.Fragment>
            )}

            {place === "qualifying" && (
              <div className="organization-card_body__indicator __answers __uppercase">
                {qualifyingStatus === qualificationStatus.WAITING ? (
                  <IconHourglass color={color.black} />
                ) : (
                  <ButtonTransparent
                    icon={<IconList color={color.green} />}
                    noBorder
                    small
                    onClick={(e) => {
                      e.stopPropagation();
                      showQualificationAnswers(qualificationId);
                    }}
                    addClass="icon-button pa-0"
                  />
                )}
                <ButtonTransparent
                  name={
                    qualifyingStatus === qualificationStatus.REMOVED
                      ? "QUALIFYING_TAB_REMOVED_BUTTON"
                      : "QUALIFYING_TAB_REMOVE_BUTTON"
                  }
                  small
                  addClass="mr-4"
                  color="red"
                  disabled={qualifyingStatus === qualificationStatus.REMOVED}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeQualificationAnswers(
                      qualificationId,
                      qualifyingStatus !== qualificationStatus.WAITING
                    );
                  }}
                />
                <ButtonTransparent
                  name={
                    qualifyingStatus === qualificationStatus.CONFIRMED
                      ? "QUALIFYING_TAB_CONFIRMED_BUTTON"
                      : "QUALIFYING_TAB_CONFIRM_BUTTON"
                  }
                  small
                  color="green"
                  nonTransparent
                  disabled={[
                    qualificationStatus.WAITING,
                    qualificationStatus.CONFIRMED,
                  ].includes(qualifyingStatus)}
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmQualificationAnswers(qualificationId);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      );
    }
  )
);

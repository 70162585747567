export const memberSsnFormat = (value) => {
  const replacedElements = value.slice(0, 6) + new Array(value.length - 6).join("*");
  const splitedValue = ("" + replacedElements).match(/.{1,6}/g);
  return splitedValue.join("-");
};

export const exactlyDigitPlace = (labelValue) => {
  let place = "SEK";
  let value = 0;
  if (!labelValue) return {value, place};

  if (Math.abs(Number(labelValue)) >= 1.0e9) {
    value = Math.abs(Number(labelValue)) / 1.0e9;
    place = "B" + place;
  } else if (Math.abs(Number(labelValue)) >= 1.0e6) {
    value = Math.abs(Number(labelValue)) / 1.0e6;
    place = "M" + place;
  } else if (Math.abs(Number(labelValue)) >= 1.0e3) {
    value = Math.abs(Number(labelValue)) / 1.0e3;
    place = "K" + place;
  } else value = Math.abs(Number(labelValue));

  return {value: typeof value.toFixed === "function" ? value.toFixed() : 0, place};
};

export const formattedNumber = (value) => {
  return value !== null
    ? !isNaN(value)
      ? `${+value}`.length > 3
        ? `${+value}`
            .split("")
            .reverse()
            .map((item, i) => ((i + 1) % 3 === 0 ? ` ${item}` : item))
            .reverse()
            .join("")
        : +value
      : "-"
    : "-";
};

export const toThousands = (value) => Math.round(value / 1000);

export const toMillions = (value) => Math.round(value / 1000000);
